import React, {
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';
import connect from "react-redux/es/connect/connect";
import ViewModeConstants from "js/constants/ViewModeConstants";
import MapNote from "js/components/MapObjects/MapNote";
import {selectNote, setEditNoteLocation, updateNote} from "js/reducers/NoteReducer";
import moment from "moment";

const mapStateToProps = (store) => {
  return {
    notes: store.note.notes,
    viewMode: store.control.viewMode,
    selectedField: store.field.selectedField,
    date: store.field.date,
    editNoteLocation: store.note.editNoteLocation
  };
};

const NotesContainer = ({dispatch, showNotes, notes, viewMode, selectedField, date, editNoteLocation}) => {

  const [visibleNotes, setVisibleNotes] = useState([]);

  const handleSelectNote = useCallback((note) => dispatch(selectNote(note)), []);

  const handleDragEnd = useCallback((event) => {
    let lat = event.latLng.lat();
    let lng = event.latLng.lng();

    let payload = {...editNoteLocation, polygon: [[lat, lng]]};

    dispatch(updateNote(payload));
    dispatch(setEditNoteLocation(null));
  }, [editNoteLocation]);

  useEffect(() => {
    let notesToShow = [];

    if (notes && selectedField) {
      const fieldNotes = notes[selectedField.fieldId];

      if (Array.isArray(fieldNotes)) {
        const dateFilter = (note) => (note.date === null || note.date === undefined || note.date === "") || moment(note.date).isSame(moment(date), 'date');
        notesToShow = fieldNotes.filter(dateFilter);
      }
    }

    setVisibleNotes(notesToShow);
  }, [notes, selectedField, date]);

  if (!showNotes || viewMode !== ViewModeConstants.ANALYSIS) {
    return null;
  }

  return (
    <>
      {visibleNotes.filter((n) => !editNoteLocation || editNoteLocation.id === n.id).map((note, idx) => (
          <MapNote
            note={note}
            key={idx}
            draggable={editNoteLocation && editNoteLocation.id === note.id}
            onDragEnd={handleDragEnd}
            onClick={handleSelectNote}/>
        )
      )}
    </>
  );

};

export default memo(
  connect(mapStateToProps)(
    NotesContainer
  )
);