import React, {
  memo,
  useCallback
} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import {grey} from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import TimeWindowSliderTicks from "js/components/WeatherNetwork/TimeWindowSlider/TimeWindowSliderTicks";

import Grid from "@material-ui/core/Grid";
import {useLangFile} from "js/context/LanguageContext";
import NetworkSensor from "../../../model/network/NetworkSensor";

const styles = (theme) => ({
  root: {
    backgroundColor: 'transparent',
    zIndex: 2,
  },
  paper: {
    position: 'relative',
    boxSizing: 'border-box',
    display: 'block',
    padding: 32,
    paddingBottom: 0,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: [[32, theme.spacing(1)]],
      paddingBottom: 0,
    },
  },
  title: {
    userSelect: 'none',
  },
  description: {
    padding: [[8, 0]],
    userSelect: 'none',
    fontSize: 12,
    fontColor: grey[800],
    textAlign: 'center',
    width: '100%',
  },
  wrapper: {
    position: 'relative',
    boxSizing: 'content-box',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  anchor: {
    width: '100%',
    height: '50%',
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    backgroundColor: 'black'
  }
});

const TimeWindowSlider = ({classes, dates, networkSensor, temporalEndIndex, temporalStartIndex, ticksPerHour, onChanged, minIndex, maxIndex}) => {

  const LangFile = useLangFile();

  const selectEndIndex = useCallback((endIndex) => {
    if (endIndex !== temporalEndIndex) {
      onChanged(endIndex);
    }
  }, [temporalEndIndex]);

  return (
    <Grid container direction={"column"} className={classes.root}>
      <Paper className={classes.paper} elevation={2} square>
        <div className={classes.wrapper}>
          <TimeWindowSliderTicks
            maxIndex={maxIndex}
            minIndex={minIndex}
            temporalStartIndex={temporalStartIndex}
            temporalEndIndex={temporalEndIndex}
            ticksPerHour={ticksPerHour}
            selectEndIndex={selectEndIndex}
            dates={dates}/>
        </div>

        <div className={classes.description}>
          {networkSensor === NetworkSensor.RAIN_24H ? LangFile.TimeWindowSlider.label : LangFile.TimeWindowSlider.labelTemp}
        </div>
      </Paper>
    </Grid>
  );
};

TimeWindowSlider.propTypes = {
  onChanged: PropTypes.func,
  region: PropTypes.object,
  temporalEndIndex: PropTypes.number,
  ticksPerHour: PropTypes.number,
  minIndex: PropTypes.number,
  maxIndex: PropTypes.number,
  dates: PropTypes.array,
  networkSensor: PropTypes.string,
};

export default withStyles(styles)(
  memo(
    TimeWindowSlider
  )
);