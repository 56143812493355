"use strict";

import React, {
  useRef,
  useCallback,
  useState,
  memo,
  useEffect,
} from 'react';

import Paper from '@material-ui/core/Paper';
import * as d3 from "d3";
import IntervalLabel from 'js/components/Prescription/PrescriptionScale/IntervalLabel';
import useEvent from "js/hooks/useEvent";
import {useLangFile} from "js/context/LanguageContext";
import PropTypes from "prop-types";
import useWindowDimensions from "js/hooks/useWindowDimensions";

import style from "js/components/Prescription/DraggableIntervals/PrescriptionScale/PrescriptionScale.module.less";
import Box from "@material-ui/core/Box";
import {
  isSurveyLayer,
} from "js/components/Prescription/PrescriptionJob";
import {getLayerConfig} from "../../PrescriptionUtils";
import {renderCustomDemandLegend} from "../../../DemandScale/DemandScale";
import {SURVEY_LAYER_CLASSIFICATION_COLORS} from '../../../../constants/SurveyLayers';

const generateSurveyGradient = (context, width, intervals) => {
  let gradient = context.createLinearGradient(0, 0, width, 0);
  let steps = intervals.length;

  for (let i = 0; i < steps; i++) {
    let classification = intervals[i].classification;
    let pos = i > 0 ? i/steps : 0;
    let next = (i+1)/steps;
    let col = SURVEY_LAYER_CLASSIFICATION_COLORS[classification];
    if (col) {
      gradient.addColorStop(pos, col);
      gradient.addColorStop(next, col);
    }
  }
  return gradient;
};

const padding = 12;

const PrescriptionClassificationScale = ({layer, intervals, overrideAreas}) => {

  const LangFile = useLangFile();
  const dimensions = useWindowDimensions();
  const ctxRef = useRef(null);
  const canvasRef = useRef(null);
  const xMap = useRef(null);
  const forceRedraw = useState({});
  const layerConfig = getLayerConfig(layer);

  // Determine overridden area
  let overriddenArea = overrideAreas ?
    Object.values(overrideAreas)
      .reduce((sum, cur) => sum + cur.area, 0) : 0;

  let overriddenAreaString = Number(overriddenArea).toFixed(2);

  // Handlers
  const generateSeparators = useCallback(() => {
    if (!intervals || !xMap.current) {
      return [];
    }

    return intervals
      .filter((interval, idx) => idx !== intervals.length - 1)
      .map((interval, idx) => {
        let nextInterval = intervals[idx + 1];
        let upperBound = nextInterval ? nextInterval.min : interval.max;

        let xPos = xMap.current(upperBound) - 2;

        return [
          <Box left={xPos} key={"handle " + idx + 1} className={style.Handle}/>,
        ];
      });
  }, [intervals, xMap]);

  const generateIntervalSizeLabels = useCallback(() => {
    if (!intervals || !xMap.current) {
      return null;
    }

    return intervals.map((interval, idx, array) => {
      let nextInterval = array[idx + 1];
      let lowerBound = interval.min;
      let upperBound = nextInterval ? nextInterval.min : interval.max;

      let xPos = lowerBound + ((upperBound - lowerBound) / 2);
      let area = Number(interval.areaFinal).toFixed(2);

      let icon = null;
      let classification = interval.classification;
      if (classification) {
        icon = renderCustomDemandLegend(classification, LangFile, layer);
      }

      return (
        <IntervalLabel
          key={idx}
          labelText={area + " ha"}
          left={xMap.current(xPos)}
          top={"25px"}>
          {Boolean(icon) && (
            <Box className={style.DemandIcon}>
              {icon}
            </Box>
          )}
        </IntervalLabel>
      );
    });
  }, [intervals, layer, xMap, layerConfig, LangFile]);

  const redrawCanvas = useCallback(() => {
    let context = ctxRef.current;
    let canvas = canvasRef.current;
    let width = canvas.width;
    let height = canvas.height;

    context.clearRect(0, 0, width, height);

    if (isSurveyLayer(layer)) {
      context.fillStyle = generateSurveyGradient(context, width, intervals);
    }
    else {
      console.error("Unexpected source layer selected. Raw MEAN should only be possible.", layer);
    }
    context.fillRect(0, 0, width, height);

  }, [ctxRef, layer, intervals]);

  const updateDimensions = useCallback(() => {
    if (intervals) {
      let intervalValues = intervals.length > 1 ? intervals.map((
        interval,
        index
      ) => index === 0 ? interval.min : interval.max) : [intervals[0].min, intervals[0].max];

      // Get the current height and width of the svg parent
      let currentWidth = canvasRef.current.offsetWidth;

      // Calculate the new mappings from domain to pixel space
      xMap.current = d3.scaleLinear()
        .domain(d3.extent(intervalValues, (d) => d))
        .range([0, currentWidth]);

      forceRedraw[1]({});
      redrawCanvas();
    }
  }, [canvasRef, intervals, redrawCanvas]);

  useEvent("resize", updateDimensions);

  useEffect(() => {
    if (canvasRef) {
      ctxRef.current = canvasRef.current.getContext("2d");
    }
  }, [canvasRef]);

  useEffect(() => {
    if (ctxRef.current) {
      updateDimensions();
    }
  }, [updateDimensions]);

  let canvasWidth = dimensions.width - (2 * padding);

  return (
    <Paper className={style.Root} elevation={0}>
      <div className={style.Spacer}/>
      <div className={style.Title}>
        {isSurveyLayer(layer) && (
          LangFile.PrescriptionScale.distributionOfArea + layerConfig.getName(LangFile) + LangFile.PrescriptionScale.distributionOfArea2 + overriddenAreaString + LangFile.PrescriptionScale.distributionOfArea3
        )}
      </div>
      <canvas
        width={canvasWidth}
        height={25}
        ref={canvasRef}>
      </canvas>
      {generateSeparators()}
      {generateIntervalSizeLabels()}
    </Paper>
  );
};

PrescriptionClassificationScale.propTypes = {
  layer: PropTypes.string,
  intervals: PropTypes.array,
  overrideAreas: PropTypes.object,
};

export default memo(PrescriptionClassificationScale);