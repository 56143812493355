import {
  fetchFarmSettings,
  fetchUserSettings
} from "js/reducers/SettingsReducer";
import {getPermissions} from "js/reducers/PermissionReducer";
import {fetchNews} from "js/reducers/NewsReducer";
import {
  fetchDates,
  resetDate,
  selectField
} from "js/reducers/FieldReducer";
import FeatureConstants from "js/constants/FeatureConstants";
import {fetchWeatherStations} from "js/reducers/WeatherReducer";
import {fetchOverlays} from "js/reducers/OverlayReducer";
import {
  hideProgress,
  showProgress
} from "js/reducers/ProgressReducer";
import {setAppLanguage} from "js/reducers/LanguageReducer";
import {
  setShowUserMenu,
  setViewMode
} from "js/reducers/ControlReducer";
import ViewModeConstants from "js/constants/ViewModeConstants";
import {
  fetchFarms,
  fetchFarmUsers
} from "js/reducers/FarmReducer";
import {logout} from "js/reducers/UserReducer";
import ManualFeatureConstants from "js/constants/ManualFeatureConstants";
import {getAllSurveys} from "js/reducers/SurveyReducer";
import {getAllSoilSamples} from "./SoilSampleReducer";
import {goToFieldsEvent} from "./ControlReducer";
import {getUserInformation} from "./UserReducer";
import {getAllNotes} from "js/reducers/NoteReducer";

/* ============== //
||      TYPES     ||
// ============== */

const SYNC_DATA = "fieldsense/SyncReducer/SYNC_DATA";
const START_BACKGROUND_SYNC = "fieldsense/SyncReducer/START_BACKGROUND_SYNC";
const START_SYNC_SERVICE = "fieldsense/SyncReducer/START_SYNC_SERVICE";
const STOP_SYNC_SERVICE = "fieldsense/SyncReducer/STOP_SYNC_SERVICE";
export const SET_SYNCING = "fieldsense/SyncReducer/SET_SYNCING";

/* ============== //
||     ACTIONS    ||
// ============== */

export function startSync(userId, farmId, language, progress, shouldResetDate, accumulation, measureSettings) {
  return (dispatch) => {
    dispatch(setSyncing(true));
    dispatch(fetchOverlays());

    dispatch(setAppLanguage(language));

    progress && dispatch(showProgress());

    shouldResetDate && dispatch(selectField(null));
    shouldResetDate && dispatch(resetDate());
    shouldResetDate && dispatch(setShowUserMenu(false));

    shouldResetDate && dispatch(setViewMode(ViewModeConstants.OVERVIEW));

    return dispatch({
      type: SYNC_DATA,
      payload: dispatch(fetchFarms(userId)).then(() => {

        Promise.all([
          dispatch(getPermissions(farmId)).then((result) => {
            let promises = [];

            if (result.value) {
              let features = result.value.features;

              if (features[FeatureConstants.LEGACY_WEATHER]) {
                promises.push(dispatch(fetchWeatherStations(farmId, accumulation, measureSettings)));
              }
              if (ManualFeatureConstants.DA_SOILOPTIX_SURVEYS) {
                promises.push(dispatch(getAllSurveys(farmId)));
              }
              if (ManualFeatureConstants.LEGACY_SOIL_SAMPLES) {
                promises.push(dispatch(getAllSoilSamples(farmId)));
              }
            }
            return Promise.all(promises);
          }),
          dispatch(getUserInformation()),
          dispatch(getAllNotes(farmId)),
          dispatch(fetchNews(farmId, language)),
          dispatch(fetchDates(farmId)),
          dispatch(fetchFarmUsers(farmId)),
          dispatch(fetchFarmSettings(farmId)),
          dispatch(fetchUserSettings(userId)),
        ]).then(() => {
          dispatch(hideProgress());
          dispatch(setSyncing(false));

          if (progress) {
            dispatch(goToFieldsEvent());
          }
        }).catch(() => {
          dispatch(hideProgress());
          dispatch(setSyncing(false));
        });
      }).catch(() => dispatch(logout()))
    });
  };
}

export function setSyncing(syncing) {
  return {
    type: SET_SYNCING,
    payload: syncing
  };
}

export function startSyncService() {
  return (dispatch) => {

    const backgroundPartialSyncInterval = 1000 /* ms */ * 60 /* seconds */ * 5 /* minutes */;

    return dispatch({
      type: START_SYNC_SERVICE,
      payload: setInterval(() => dispatch({type: START_BACKGROUND_SYNC}), backgroundPartialSyncInterval)
    });
  };
}

export function stopSyncService(service) {
  return {
    type: STOP_SYNC_SERVICE,
    payload: stopBackgroundSyncService(service)
  };
}

/* ============== //
||     HELPERS    ||
// ============== */

function stopBackgroundSyncService(existingService) {
  if (existingService) {
    clearInterval(existingService);
  }
  return null;
}

/* ============== //
||     REDUCER    ||
// ============== */

const initState = {
  syncing: false,
  syncService: null,
  backgroundSyncing: false
};

export default function reducer(state = initState, action) {
  switch (action.type) {

    case SET_SYNCING: {
      state = {...state, syncing: action.payload};
      break;
    }

    case SYNC_DATA + "_FULFILLED": {
      state = {...state, backgroundSyncing: false};
      break;
    }

    case SYNC_DATA + "_REJECTED": {
      state = {...state, backgroundSyncing: false};
      break;
    }

    case START_BACKGROUND_SYNC: {
      state = {...state, backgroundSyncing: true};
      break;
    }

    case START_SYNC_SERVICE: {
      state = {
        ...state, syncService: action.payload,
      };
      break;
    }

    default:
      break;
  }

  return state;
}
