// @flow

import React, {useContext,} from "react";
import {useHookRef} from "js/hooks/useHookRef";
import type {LanguageFile} from "../helpers/LanguageUtils";
import D3Locales from "../constants/D3Locales";
import LanguageConstants from "../constants/LanguageConstants";

const LanguageContext = React.createContext({});

export const LanguageProvider = LanguageContext.Provider;
export const LanguageConsumer = LanguageContext.Consumer;

export function useLangFile(): LanguageFile {
  return useContext(LanguageContext).LangFile;
}

export function useLangFileRef(): {current: LanguageFile} {
  return useHookRef(useLangFile());
}

export function useLanguage(): string {
  return useContext(LanguageContext).language;
}

export function useD3locale() {
  let language = useLanguage();
  return D3Locales[language] || D3Locales[LanguageConstants.ENGLISH];
}