import React, {
  Fragment,
  memo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import withStyles from "@material-ui/core/styles/withStyles";
import {useLangFile} from "js/context/LanguageContext";
import debounce from 'lodash.debounce';

const styles = (theme) => ({
  info: {
    marginBottom: theme.spacing(1)
  },
  question: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold'
  }
});

const PrescriptionAutoAdjustWarningDialog = ({classes, onCancel, onProceed, pendingMetaType}) => {
  const LangFile = useLangFile();

  const handleOnProceed = useCallback(debounce(() => {
    onProceed(pendingMetaType);
    onCancel();
  }, 200), [pendingMetaType]);

  return (
    <Fragment>
      <Dialog open={Boolean(pendingMetaType)} fullWidth maxWidth={"md"}>
        <DialogTitle>
          {LangFile.PrescriptionAutoAdjustWarningDialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.info}>
            {LangFile.PrescriptionAutoAdjustWarningDialog.info}
          </DialogContentText>
          <DialogContentText className={classes.question}>
            {LangFile.PrescriptionAutoAdjustWarningDialog.proceedQuestion}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary" variant={"outlined"}>
            {LangFile.PrescriptionAutoAdjustWarningDialog.cancel}
          </Button>
          <Button onClick={handleOnProceed} color="secondary" variant={"contained"}>
            {LangFile.PrescriptionAutoAdjustWarningDialog.proceed}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default memo(withStyles(styles)(PrescriptionAutoAdjustWarningDialog));

PrescriptionAutoAdjustWarningDialog.propTypes = {
  pendingMetaType: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  infoPieces: PropTypes.array
};

PrescriptionAutoAdjustWarningDialog.defaultProps = {
  infoPieces: []
};