import {Box, Button, Checkbox, Divider, FormControlLabel, Typography} from '@material-ui/core';
import React, {Fragment, memo, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {voidFunc} from '../../constants/PropTypeUtils';
import Styles from './DataDisconnectDialogContent.module.less';
import {DeleteForever} from '@material-ui/icons';
import {useLangFile} from "../../context/LanguageContext";

const DataDisconnectDialogContent = (props: DataDisconnectDialogContent.propTypes) => {
  const {integration, onBack, onDisconnect} = props;

  const LangFile = useLangFile();
  const [agree, setAgree] = useState(false);

  const onToggleAgree = useCallback(() => {
    setAgree(!agree);
  }, [agree]);

  if (integration === null) {
    return null;
  }

  return (
    <Fragment>
      <Box
        pt={3}
        pb={1}
        display={"flex"}
        flexDirection={"column"}>
        <Box bgcolor={'#CE1A3C'}>
          <Typography align={'center'} className={Styles.title}>
            {`${LangFile.DataDisconnectDialog.title} ${integration.name}`}
          </Typography>
        </Box>
        <Box my={1}/>
        <Typography align={'center'} className={Styles.content}>
          {LangFile.DataDisconnectDialog.description}
        </Typography>
      </Box>
      <Box py={1}>
        <Divider/>
        <Box my={1}>
          <Typography align={'center'} className={Styles.content}>
            {LangFile.DataDisconnectDialog.consequenceYield}
          </Typography>
        </Box>
        <Divider/>
      </Box>
      <Box align={'center'}>
        <FormControlLabel
          control={
            <Checkbox checked={agree} onClick={onToggleAgree}/>
          }
          label={<Typography className={Styles.content}>{LangFile.DataDisconnectDialog.understand}</Typography>}/>
      </Box>
      <Box mt={2} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <Box>
          <Button
            onClick={onBack}
            disableRipple
            color={'primary'}
            className={Styles.button}>
            {LangFile.DataDisconnectDialog.back}
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => {
              onDisconnect(integration);
            }}
            disableRipple
            disabled={!agree}
            color={'secondary'}
            className={Styles.button}>
            <Box display={'flex'} flexDirection={'row'}>
              <Typography>
                {LangFile.DataDisconnectDialog.disconnect}
              </Typography>
              <Box display={'flex'} alignItems={'center'}>
                <DeleteForever style={{height: 20}}/>
              </Box>
            </Box>
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

DataDisconnectDialogContent.propTypes = {
  integration: PropTypes.any,
  onBack: PropTypes.func,
  onDisconnect: PropTypes.func
};

DataDisconnectDialogContent.defaultProps = {
  onBack: voidFunc,
  onDisconnect: voidFunc,
};

export default memo(DataDisconnectDialogContent);