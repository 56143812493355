"use strict";

import React, {memo} from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from "prop-types";

const DemandOk = (props: DemandOk.propTypes) => {
  return (
    <SvgIcon
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12">

      <g transform="translate(0 -996)">
        <g transform="translate(0 996)">
          <g fill="#fff" stroke={props.htmlColor} strokeWidth="2">
            <rect width="12" height="12" stroke="none"/>
            <rect x="1" y="1" width="10" height="10" fill="none"/>
          </g>
        </g>
      </g>

    </SvgIcon>
  );
};


DemandOk.propTypes = {
  htmlColor: PropTypes.string,
};

DemandOk.defaultProps = {
  htmlColor: "#91DB8E",
};

export default memo(
  DemandOk
);