import {useGoogleMap} from "../../context/GoogleMapContext";
import React, {Fragment, memo, useCallback, useState} from "react";
import {saveViewport, setShowCreateSeasonDialog, setViewMode} from "../../reducers/ControlReducer";
import connect from "react-redux/lib/connect/connect";
import ActionToast from "../Toasts/ActionToast";
import {useLangFile} from "../../context/LanguageContext";
import {createSeason, selectSeason} from "../../reducers/SeasonReducer";
import {useFarm} from "../../context/AccountContext";
import ViewModeConstants from "../../constants/ViewModeConstants";
import {resetFilters, updateFieldSeasons} from "../../reducers/FieldReducer";
import FieldSeason from "../../model/FieldSeason";
import CreateUpdateSeason from "./CreateUpdateSeason";
import {CropType} from "../../constants/CropConstants";

const mapStateToProps = (store) => {
  return {
    fields: store.field.fields,
    showCreateSeasonDialog: store.control.showCreateSeasonDialog,
  };
};

const parseDateFormat = "YYYY-MM-DD";

const CreateSeasonContainer = ({dispatch, fields, showCreateSeasonDialog}) => {
  const googleMap = useGoogleMap();
  const LangFile = useLangFile();
  const farm = useFarm();
  const farmId = farm && farm.farmId;
  const [showSwitchSeasonDialog, setShowSwitchSeasonDialog] = useState(false);
  const [showAssignCropsDialog, setShowAssignCropsDialog] = useState(false);
  const [newSeasonId, setNewSeasonId] = useState(null);

  const onCreateSeasonClose = useCallback(() => {
    dispatch(setShowCreateSeasonDialog(false));
  }, []);

  const onCreateSeason = useCallback((name, startDate, endDate) => {
    onCreateSeasonClose();
    if (farmId) {
      const startDateString = startDate.format(parseDateFormat);
      const endDateString = endDate.format(parseDateFormat);
      dispatch(createSeason(farmId, name, startDateString, endDateString)).then( (result) => {
          if (result.value.newSeasonId) {
            setNewSeasonId(result.value.newSeasonId);
            const fieldSeasons = Array.from(fields.values()).map((field) => {
              return new FieldSeason(field.fieldId, CropType.NONE, null);
            });
            dispatch(updateFieldSeasons(result.value.newSeasonId, fieldSeasons));
            setShowSwitchSeasonDialog(true);
          }
        });
    }
  }, [fields]);

  const onSwitchSeasonClose = useCallback(() => {
    setShowSwitchSeasonDialog(false);
  }, []);

  const onSwitchSeason = useCallback(() => {
    onSwitchSeasonClose();
    if (newSeasonId) {
      dispatch(selectSeason(newSeasonId));
      dispatch(resetFilters());
      setShowAssignCropsDialog(true);
    }
  }, [newSeasonId]);

  const onAssignCropsClose = useCallback(() => {
    setShowAssignCropsDialog(false);
  }, []);

  const onAssignCrops = useCallback(() => {
    onAssignCropsClose();
    dispatch(saveViewport(googleMap.getCenter(), googleMap.getZoom()));
    dispatch(setViewMode(ViewModeConstants.MANAGE_CROPS));
  }, []);

  return (
    <Fragment>
      <CreateUpdateSeason
        showDialog={showCreateSeasonDialog}
        onDismiss={onCreateSeasonClose}
        onPrimary={onCreateSeason}/>

      <ActionToast
        open={showSwitchSeasonDialog}
        title={LangFile.CreateSeasonContainer.switch.title}
        message={LangFile.CreateSeasonContainer.switch.message}
        onPrimaryAction={onSwitchSeason}
        onSecondaryAction={onSwitchSeasonClose}
        actionTextPrimary={LangFile.CreateSeasonContainer.switch.switch}
        actionTextSecondary={LangFile.FieldsEmptyState.later}/>

      <ActionToast
        open={showAssignCropsDialog}
        title={LangFile.CreateSeasonContainer.assign.title}
        message={LangFile.CreateSeasonContainer.assign.message}
        onPrimaryAction={onAssignCrops}
        onSecondaryAction={onAssignCropsClose}
        actionTextPrimary={LangFile.CreateSeasonContainer.assign.assign}
        actionTextSecondary={LangFile.FieldsEmptyState.later}/>
    </Fragment>
  );
};

export default memo(
  connect(mapStateToProps)(
    CreateSeasonContainer
  )
);
