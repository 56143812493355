import React, {memo, useCallback, useEffect} from 'react';
import { connect } from 'react-redux';
import FeatureConstants from "js/constants/FeatureConstants";
import {useHasFeatures} from "js/context/PermissionContext";
import ViewModeConstants from "js/constants/ViewModeConstants";
import {useGoogleMap} from "js/context/GoogleMapContext";
import {getWeatherRegion} from "../../reducers/WeatherNetworkReducer";
import {LEVEL_ZOOM_THRESHOLD} from "../../constants/WeatherNetworkConstants";
import {useHookRef} from "../../hooks/useHookRef";
import {useMeasureSettings} from "../../context/AppSettings/AppSettingsContext";
import useMapEvent from "../DataLayer/hooks/useMapEvent";
import WeatherNetworkMarkersCanvas from "./WeatherNetworkMarkersCanvas";

const getExtendedBounds = (bounds) => {
  let {n, s, e, w} = bounds;

  let height = n > s ? n - s : s - n;
  let width = w > e ? w - e : e - w;

  n = n + height / 2;
  s = s - height / 2;
  e = e + width / 2;
  w = w - width / 2;

  return {n, s, e, w};
};

const getBoundsComponents = (bounds) => {
  let ne = bounds.getNorthEast();
  let sw = bounds.getSouthWest();
  let n = ne.lat(), s = sw.lat(), e = ne.lng(), w = sw.lng();

  return {n, s, e, w};
};

const isOutsideBounds = (bounds, currentBounds) => {
  if (!currentBounds) {
    return true;
  }

  let b = bounds;
  let c = currentBounds;

  if (b.n > c.n) {
    return true;
  }

  if (b.s < c.s) {
    return true;
  }

  if (b.e > c.e) {
    return true;
  }

  if (b.w < c.w) {
    return true;
  }

  return false;
};

const getMapState = (googleMap) => {
  if (!googleMap) {
    return {bounds: null, newLevel: null};
  }

  let z = googleMap.getZoom();
  let b = googleMap.getBounds();

  if (b) {
    b = getBoundsComponents(b);
  }

  let newLevel = z >= LEVEL_ZOOM_THRESHOLD ? 2 : 1;

  return {bounds: b, newLevel: newLevel};
};

const mapStateToProps = (store) => {
  return {
    farm: store.farm.farm,
    region: store.weatherNetwork.region,
    level: store.weatherNetwork.level,
    currentBounds: store.weatherNetwork.currentBounds,
    temporalEndIndex: store.weatherNetwork.temporalEndIndex,
    ticksPerHour: store.weatherNetwork.ticksPerHour,
    networkSensor: store.weatherNetwork.networkSensor,
    showWeatherNetwork: store.weatherNetwork.showWeatherNetwork,
    viewMode: store.control.viewMode,
    syncing: store.sync.syncing,
    loadingRegion: store.weatherNetwork.loadingRegion,
    regionError: store.weatherNetwork.regionError,
  };
};

const WeatherNetworkMapContainer = ({dispatch, showWeatherNetwork, farm, level, region, networkSensor, temporalEndIndex, viewMode, ticksPerHour, loadingRegion, syncing, currentBounds}) => {

  const googleMap = useHookRef(useGoogleMap());
  const levelRef = useHookRef(level);
  const boundsRef = useHookRef(currentBounds);
  const syncingRef = useHookRef(syncing);
  const loadingRef = useHookRef(loadingRegion);
  const enabled = useHasFeatures([FeatureConstants.LEGACY_WEATHER]);
  const measureSettings = useMeasureSettings();

  // Handles changes of networkSensor and measureSettings.
  useEffect(() => {
    if (networkSensor && levelRef.current && boundsRef.current && measureSettings) {
      dispatch(getWeatherRegion(farm.farmId, networkSensor, levelRef.current, boundsRef.current, measureSettings));
    }
  }, [networkSensor, farm, measureSettings]);

  const idleHandler = useCallback(() => {
    if (syncingRef.current || !googleMap.current || !showWeatherNetwork || loadingRef.current) {
      return;
    }

    let {bounds, newLevel} = getMapState(googleMap.current);
    if (!bounds || !newLevel) {
      return;
    }

    if (newLevel !== levelRef.current || (bounds && isOutsideBounds(bounds, boundsRef.current))) {
      let extendedBounds = getExtendedBounds(bounds);
      dispatch(getWeatherRegion(farm.farmId, networkSensor, newLevel, extendedBounds, measureSettings));
    }

  }, [showWeatherNetwork, farm, networkSensor, measureSettings]);

  useMapEvent(googleMap.current, "idle", idleHandler);

  if (!enabled) {
    return null;
  }

  const hide = viewMode !== ViewModeConstants.OVERVIEW;

  return region && (
    <WeatherNetworkMarkersCanvas
      map={googleMap.current}
      level={level}
      networkSensor={networkSensor}
      ticksPerHour={ticksPerHour}
      region={region}
      shown={showWeatherNetwork && !hide}
      temporalEndIndex={temporalEndIndex}/>
  );
};

export default memo(connect(mapStateToProps)(WeatherNetworkMapContainer));
