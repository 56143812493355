// @flow

import React, {Fragment, useCallback, memo} from "react";

import PropTypes from 'prop-types';

import StyleConstants from 'js/StyleConstants';
import MagicWand from "js/components/Icons/MagicWand";
import {
  Visibility,
  VisibilityOff,
  AddLocation,
  GraphicEq,
} from "@material-ui/icons";
import Fab from "@material-ui/core/Fab";
import PolygonIcon from "js/components/Icons/PolygonIcon";
import Tooltip from "@material-ui/core/Tooltip";
import {useLangFile} from "../../context/LanguageContext";
import {voidFunc} from "../../constants/PropTypeUtils";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../hooks/useFirebaseAnalytics";

const NotesMenu = (props: NotesMenu.propTypes) => {

  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();

  const toggleNotes = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.NOTES_VISIBILITY, {shown: !props.showNotes});
    props.setShowNotes(!props.showNotes);
  }, [props.showNotes]);

  const initNoteCreate = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.NOTES_POLYGON);
    props.setIsDrawingNote(true);
  }, [props.setIsDrawingNote]);

  const initMarkerNoteCreate = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.NOTES_MARKER);
    props.setIsPlacingMarker(true);
  }, [props.setIsPlacingMarker]);

  const startMagicWand = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.NOTES_WAND);
    props.setIsMagicSelecting(true);
  }, []);

  return (
    <Fragment>

      {props.renderSurveyReportContainer()}

      <Tooltip title={LangFile.NotesMenu.prescription}>
        <Fab size="small" onClick={props.onCreatePrescription}>
          <GraphicEq/>
        </Fab>
      </Tooltip>

      <Tooltip title={LangFile.NotesMenu.magicWand}>
        <Fab size="small" onClick={startMagicWand}>
          <MagicWand/>
        </Fab>
      </Tooltip>

      <Tooltip title={LangFile.NotesMenu.createPolygon}>
        <Fab size="small" onClick={initNoteCreate}>
          <PolygonIcon/>
        </Fab>
      </Tooltip>

      <Tooltip title={LangFile.NotesMenu.createMarker}>
        <Fab size="small" onClick={initMarkerNoteCreate}>
          <AddLocation/>
        </Fab>
      </Tooltip>

      <Tooltip title={props.showNotes ? LangFile.NotesMenu.hide : LangFile.NotesMenu.show}>
        <Fab size="small" onClick={toggleNotes}>
          {props.showNotes ?
            <VisibilityOff style={{backgroundColor: StyleConstants.colors.white, color: "#000"}}/> :
            <Visibility style={{backgroundColor: StyleConstants.colors.white, color: "#000"}}/>}
        </Fab>
      </Tooltip>
    </Fragment>
  );
};

export default memo(NotesMenu);

NotesMenu.propTypes = {
  googleMap: PropTypes.object,
  shown: PropTypes.bool,
  showNotes: PropTypes.bool,
  enableMagicWand: PropTypes.bool,
  setShowNotes: PropTypes.func,
  setIsDrawingNote: PropTypes.func,
  setIsPlacingMarker: PropTypes.func,
  setIsMagicSelecting: PropTypes.func,
  selectNote: PropTypes.func,
  createDefaultNote: PropTypes.func,
  onCreatePrescription: PropTypes.func,
  renderSurveyReportContainer: PropTypes.func,
};

NotesMenu.defaultProps = {
  renderSurveyReportContainer: voidFunc
};
