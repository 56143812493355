import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import {useLangFile} from "js/context/LanguageContext";

const PrescriptionArchiveConfirmDeleteDialog = ({open, onClose, onConfirm}) => {

  let LangFile = useLangFile();

  const handleClose = useCallback(() => {
    onClose();
  });

  return (
    <Dialog fullWidth maxWidth={"sm"} open={open} onClose={handleClose}>
      <DialogTitle>
        {LangFile.PrescriptionArchiveConfirmDeleteDialog.title}
      </DialogTitle>
      <DialogContent>
        <p>{LangFile.PrescriptionArchiveConfirmDeleteDialog.message}</p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}>
          {LangFile.PrescriptionArchiveConfirmDeleteDialog.abort}</Button>
        <Button
          color="secondary"
          onClick={onConfirm}>
          {LangFile.PrescriptionArchiveConfirmDeleteDialog.deleteMap}</Button>
      </DialogActions>
    </Dialog>
  );
};

PrescriptionArchiveConfirmDeleteDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default PrescriptionArchiveConfirmDeleteDialog;