import React, {
  memo,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import NumberUtils from "js/helpers/NumberUtils";
import {usePrescriptionJob} from "js/components/Prescription/PrescriptionJobContext";
import useEvent from "js/hooks/useEvent";
import DragHandle from "js/components/Prescription/DragHandle/DragHandle";
import {PRESCRIPTION_UNIT} from '../../../../constants/PrescriptionConstants';
import {SQUARE_METER_COVERSION_VALUE} from '../../PrescriptionUtils';

const HorizontalHandle = ({index, wrapperRect, interval, onDragValue}) => {

  let {prescriptionJob} = usePrescriptionJob();
  let {maxPrescription, fieldSize, intervals} = prescriptionJob;
  let centerValue = (maxPrescription / fieldSize);
  if (prescriptionJob.unit === PRESCRIPTION_UNIT.PIECES_M2) {
    centerValue = maxPrescription / (fieldSize * SQUARE_METER_COVERSION_VALUE);
  }

  let maxValue = centerValue * 2;

  let currentPrescription = interval.prescription;

  const [mouseDown, setMouseDown] = useState(false);

  const onMousemove = useCallback((event) => {
    if (!wrapperRect || !mouseDown || !onDragValue) {
      return;
    }

    if (typeof event.persist === "function") {
      event.persist();
    }

    let wrapperMax = wrapperRect.top;
    let wrapperMin = wrapperRect.bottom;
    let value = NumberUtils.map(event.clientY, wrapperMin, wrapperMax, 0, maxValue);
    value = NumberUtils.limit(value, 0, maxValue);
    if (!Number.isNaN(value)) {
      onDragValue(index, value);
    }

  }, [wrapperRect, mouseDown, onDragValue, centerValue]);

  useEvent("mousemove", onMousemove);

  const onStartDrag = useCallback((event) => {
    setMouseDown(true);
  });

  useEvent("mouseup", useCallback(() => {
    setMouseDown(false);
  }));

  let intervalsMin = intervals[0].min;
  let intervalsMax = intervals[intervals.length - 1].max;
  let intervalMiddle = interval.min + (interval.max - interval.min)/2;

  let movementPercent = NumberUtils.limitMap(currentPrescription, 0, maxValue, 0, 100);
  let left = NumberUtils.limitMap(intervalMiddle, intervalsMin, intervalsMax, 0, 100);

  if (intervalsMin === intervalsMax) {
    left = 50;
  }

  let start = NumberUtils.limitMap(interval.min, intervalsMin, intervalsMax, 0, 1) * wrapperRect.width;
  let end = NumberUtils.limitMap(interval.max, intervalsMin, intervalsMax, 0, 1) * wrapperRect.width;
  let width = (end-start) * 0.7;

  return (
    <DragHandle
      width={width}
      axisPositionPercent={`${left}%`}
      movementDirection={"vertical"}
      movementPercent={`${100-movementPercent}%`}
      onStartDrag={onStartDrag}/>
  );
};

HorizontalHandle.propTypes = {
  onDragValue: PropTypes.func,
  index: PropTypes.number,
  interval: PropTypes.object,
  wrapperRect: PropTypes.any,
};

export default memo(HorizontalHandle);
