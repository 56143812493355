export function saveData(data, fileName) {
  downloadBlob(getBlob(data), fileName);
}

export function getBlob (data) {
  return new Blob([data], {type: "octet/stream"});
}

export function downloadBlob(blob, fileName) {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;

  if (window.navigator.msSaveOrOpenBlob) {
    a.onclick = () => {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    };
  }

  a.click();
}