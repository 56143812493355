import WebAPIUtils from 'js/WebAPIUtils';
import moment from "moment-timezone";

import {SATELLITE_LAYERS, TIFF_NDRE, TIFF_NDVI} from "js/constants/SatelliteLayers";

import {ADD_FIELDS} from 'js/reducers/AddFieldReducer';
import {getFieldByBundle} from 'js/helpers/StateInterpreters';

// Models
import Field from 'js/model/Field';
import APIConstants from "../APIConstants";
import {REMOVE_FIELD_FROM_SEASON} from "./SeasonReducer";

/* ============== //
||  ACTIONTYPES   ||
// ============== */

export const SELECT_FIELD = "fieldsense/Field/SELECT_FIELD";
export const SELECT_FIELD_BY_ID = "fieldsense/Field/SELECT_FIELD_BY_ID";
const DELETE_FIELD = "fieldsense/Field/DELETE_FIELD";
const CHANGE_IMAGE_TYPE = "fieldsense/Field/CHANGE_IMAGE_TYPE";
const SELECT_FIELD_AND_DATE = "fieldsense/Field/SELECT_FIELD_AND_DATE";
const SET_DATE = "fieldsense/Field/SET_DATE";
const RESET_DATE = "fieldsense/Field/RESET_DATE";
const UPDATE_FIELD_NAME = "fieldsense/Field/UPDATE_FIELD_NAME";
const FETCH_CROP_HEALTH = "fieldsense/Field/FETCH_CROP_HEALTH";
const SET_SATELLITE_IMAGERY_SHOWN = "fieldsense/Field/SET_SATELLITE_IMAGERY_SHOWN";
const FETCH_STATISTICS = "fieldsense/Field/FETCH_STATISTICS";
const FETCH_FIELDS = "fieldsense/FieldReducer/FETCH_FIELDS";
const FETCH_DATES = "fieldsense/FieldReducer/FETCH_DATES";
const CONNECT_FIELDS_TO_WEATHER_STATION = "fieldsense/FieldReducer/CONNECT_FIELDS_TO_WEATHER_STATION";
const CONNECT_FIELD_TO_WEATHER_STATION = "fieldsense/FieldReducer/CONNECT_FIELD_TO_WEATHER_STATION";
const CREATE_UPDATE_FIELD_SEASON = "fieldsense/FieldReducer/CREATE_UPDATE_FIELD_SEASON";
const UPDATE_FIELD_SEASONS = "FieldReducer/FieldReducer/UPDATE_FIELD_SEASONS";
const GET_FARM_FIELDS = "fieldsense/FieldReducer/GET_FARM_FIELDS";
const FILTERED_FIELDS = "fieldsense/Field/FILTERED_FIELDS";
const FILTERED_CROP_TYPES = "fieldsense/Field/FILTERED_CROP_TYPES";
const RESET_FILTERS = "fieldsense/Field/RESET_FILTERS";
const SET_SELECTED_FIELD_DATES = "fieldsense/Field/SET_SELECTED_FIELD_DATES";

/* ============== //
||    HELPERS     ||
// ============== */

/**
 * Convert an array of field data from the server into a Map of fields.
 * @param {Array} fields holding the fields from the server.
 * @returns {Map} mapping from fieldId to a field
 */
export function createFieldsMap(fields): Map<number, Field> {
  let fieldsMap = new Map();

  if (fields != null && Array.isArray(fields)) {
    for (let f of fields) {
      let field = new Field();
      field = {...field, ...f, fieldId: f.fieldId || f.id};

      fieldsMap.set(field.fieldId, field);
    }
  }

  return fieldsMap;
}

/**
 * Convert an array of dates into a sorted map of dates
 * @param {Array} dates a list of all field images.
 * @returns {Map} The map containing all dates with images. This map is sorted by date!
 */
function createDateMap(dates) {
  let dateMap = new Map();

  if (dates != null && Array.isArray(dates)) {
    for (let date of dates) {
      dateMap.set(date, null);
    }
  }

  return dateMap;
}
// MARK: The vitality key should be changed
async function fetchCropHealthAsListWidthBounds(farm, date, fields) {
  if (!date) {
    return [];
  }

  let cropHealthData = await WebAPIUtils.getCropHealthForDate(farm, date);

  let result = [];
  for (let i in cropHealthData) {
    let id = Number(i);
    let healthObj = cropHealthData[id];
    let field = getFieldByBundle([...fields.values()], id);

    if (field == null) {
      continue;
    }

    const object = {
      ...healthObj,
      fieldId: field.fieldId,
      bounds: field.bounds,
      [TIFF_NDVI]: APIConstants.imagesBaseURL + healthObj["tiff"],
      [SATELLITE_LAYERS.VITALITY_NDVI]: APIConstants.imagesBaseURL + healthObj["vitality"],
      [SATELLITE_LAYERS.VARIATIONS_NDVI]: APIConstants.imagesBaseURL + healthObj["variations"],
      [SATELLITE_LAYERS.VISIBLE]: APIConstants.imagesBaseURL + healthObj[SATELLITE_LAYERS.VISIBLE.toLowerCase()]
    };

    if (healthObj["ndre"] != null) {
      object[TIFF_NDRE] = APIConstants.imagesBaseURL + healthObj["ndre"]["tif"];
      object[SATELLITE_LAYERS.VITALITY_NDRE] = APIConstants.imagesBaseURL + healthObj["ndre"]["png"];
      object[SATELLITE_LAYERS.VARIATIONS_NDRE] = APIConstants.imagesBaseURL + healthObj["ndre"]["variations"];
    }

    result.push(object);
  }
  return result;
}

function getUnprocessedFields(fields) {
  let unprocessed = [];
  // Look for any fields that are n ot ready
  fields.forEach((field) => {
    if (!field.ready) {
      unprocessed.push(field);
    }
  });

  return unprocessed;
}

function getSelectedFieldDates(stats) {
  let dates = [];

  stats.forEach((item) => {
    if (dates.indexOf(item.date) === -1) {
      dates.push(item.date);
    }
  });

  dates.sort((a, b) => {
    return moment(a).diff(moment(b));
  });

  return dates;
}

function createFilteredFieldMap(state, payload) {
  let filteredFields = new Map(state.filteredFields);

  if (payload.hasOwnProperty("field")) {
    if (payload.checked) {
      if (!filteredFields.has(payload.field.fieldId)) {
        filteredFields.set(payload.field.fieldId, payload.field);
      }
    }
    else {
      if (filteredFields.has(payload.field.fieldId)) {
        filteredFields.delete(payload.field.fieldId);
      }
    }
  }
  else {
    if (payload.checked) {
      const fields = Array.from(state.fields.values()).filter((field) => field.crop === payload.cropType);
      fields.forEach((field) =>
        filteredFields.set(field.fieldId, field)
      );
    }
    else {
      const fields = Array.from(state.filteredFields.values()).filter((field) => field.crop === payload.cropType);
      fields.forEach((field) =>
        filteredFields.delete(field.fieldId)
      );
    }
  }

  return filteredFields;
}

function createFilteredCropTypes(state, payload) {
  let filteredCropTypes = new Array(...state.filteredCropTypes);
  if (payload.hasOwnProperty("cropType")) {
    if (payload.checked) {
      if (!filteredCropTypes.includes(payload.cropType)) {
        filteredCropTypes.push(payload.cropType);
      }
    }
    else {
      if (filteredCropTypes.includes(payload.cropType)) {
        filteredCropTypes = filteredCropTypes.filter((cropType) => cropType !== payload.cropType);
      }
    }
  }
  else {
    const cropType = payload.field.crop;
    if (payload.checked) {
      const fields = Array.from(state.fields.values()).filter((field) => field.crop === cropType);
      const filteredFields = Array.from(state.filteredFields.values()).filter((field) => field.crop === cropType);
      if (fields.length === filteredFields.length + 1) {
        if (!filteredCropTypes.includes(cropType)) {
          filteredCropTypes.push(cropType);
        }
      }
    }
    else {
      if (filteredCropTypes.includes(cropType)) {
        filteredCropTypes = filteredCropTypes.filter((c) => c !== cropType);
      }
    }
  }

  return filteredCropTypes;
}

/* ============== //
||    ACTIONS    ||
// ============== */


export function getFarmFields(farmId) {
  return {
    type: GET_FARM_FIELDS,
    payload: WebAPIUtils.getFarmFields(farmId)
  };
}

export function fetchFields(seasonId, silent) {
  return {
    type: FETCH_FIELDS,
    payload: WebAPIUtils.getSeasonFields(seasonId),
    meta: {
      silent: silent,
      seasonId: seasonId
    }
  };
}

export function fetchDates(farmId) {
  return {
    type: FETCH_DATES,
    payload: WebAPIUtils.getDates(farmId)
  };
}

export function fetchCropHealth(farmId, date, fields) {
  return {
    type: FETCH_CROP_HEALTH,
    payload: fetchCropHealthAsListWidthBounds(farmId, date, fields),
    meta: date
  };
}

export function setDate(date) {
  return {
    type: SET_DATE,
    payload: date
  };
}

export function resetDate() {
  return {
    type: RESET_DATE
  };
}

export function selectField(field) {
  return {
    type: SELECT_FIELD,
    payload: field
  };
}

export function selectFieldById(fieldId) {
  return {
    type: SELECT_FIELD_BY_ID,
    payload: fieldId
  };
}

export function changeImageType(type) {
  return {
    type: CHANGE_IMAGE_TYPE,
    payload: type
  };
}

export function selectFieldAndDate(field, date) {
  return {
    type: SELECT_FIELD_AND_DATE,
    payload: {date: date, field: field}
  };
}

export function setFilteredField(field, checked) {
  return {
    type: FILTERED_FIELDS,
    payload: {checked: checked, field: field}
  };
}

export function setFilteredCropType(cropType, checked) {
  return {
    type: FILTERED_CROP_TYPES,
    payload: {checked: checked, cropType: cropType}
  };
}

export function resetFilters() {
  return {
    type: RESET_FILTERS
  };
}

export function updateFieldName(name, fieldId) {
  return {
    type: UPDATE_FIELD_NAME,
    payload: WebAPIUtils.putFieldName(name, fieldId),
    meta: {name, fieldId}
  };
}

export function deleteField(field) {
  return {
    type: DELETE_FIELD,
    payload: WebAPIUtils.deleteField(field.fieldId),
    meta: {field: field}
  };
}

export function setShowSatelliteImagery(shown) {
  return {
    type: SET_SATELLITE_IMAGERY_SHOWN,
    payload: shown
  };
}

export function fetchStatisticsForField(bundleId, imageType) {
  const type = imageType === SATELLITE_LAYERS.VITALITY_NDRE || imageType === SATELLITE_LAYERS.VARIATIONS_NDRE ? "ndre" : "ndvi";
  return {
    type: FETCH_STATISTICS,
    payload: WebAPIUtils.getBundleStats(bundleId, type)
  };
}

export function setSelectedFieldDates(dates) {
  return {
    type: SET_SELECTED_FIELD_DATES,
    payload: dates
  };
}

export function createUpdateFieldSeason(fieldId, seasonId, crop, sowing, cropVariation) {

  let body = {
    crop: crop,
    sowing: sowing,
    cropVariation: cropVariation,
  };

  return {
    type: CREATE_UPDATE_FIELD_SEASON,
    payload: WebAPIUtils.createUpdateFieldSeason(fieldId, seasonId, body),
    meta: {
      fieldId, seasonId, crop, sowing, cropVariation
    }
  };
}

export function updateFieldSeasons(seasonId, fieldSeasons) {
  return async (dispatch) => {

    let body = {
      seasonId: seasonId,
      seasons: fieldSeasons
    };

    let payload = WebAPIUtils.updateFieldSeasons(body);

    let result = await dispatch({
      type: UPDATE_FIELD_SEASONS,
      payload: payload,
      meta: body
    });

    if (result) {
      return dispatch(fetchFields(seasonId, false));
    }
  };
}

export function connectFieldsToWeatherStation(fieldIds, deviceId) {
  return {
    type: CONNECT_FIELDS_TO_WEATHER_STATION,
    payload: WebAPIUtils.connectFieldsToDevice(fieldIds, deviceId),
    meta: {
      fieldIds, deviceId
    }
  };
}

export function connectFieldToWeatherStation(fieldId, deviceId) {
  return {
    type: CONNECT_FIELD_TO_WEATHER_STATION,
    payload: WebAPIUtils.updateFieldDevice(fieldId, deviceId),
    meta: {
      fieldId, deviceId
    }
  };
}

/* ============== //
||     REDUCER    ||
// ============== */

const initState = {
  farmFields: [],
  fields: new Map(),
  images: new Map(),
  imageType: SATELLITE_LAYERS.VITALITY_NDVI,
  date: null,
  dates: [],
  selectedFieldStatistics: [],
  selectedFieldDates: [],
  selectedField: null,
  filteredFields: new Map(),
  filteredCropTypes: [],
  fieldsLoaded: false,
  showSatelliteImagery: true,
  showVariations: false,
  unprocessedFields: [],
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case FETCH_STATISTICS + "_FULFILLED": {
      state = {
        ...state,
        selectedFieldStatistics: action.payload,
        selectedFieldDates: getSelectedFieldDates(action.payload)
      };
      break;
    }

    case SET_SELECTED_FIELD_DATES: {
      state = {...state, selectedFieldDates: action.payload};
      break;
    }

    case GET_FARM_FIELDS + "_FULFILLED": {
      state = {...state, farmFields: action.payload.map((f) => ({...new Field(), ...f, fieldId: f.fieldId || f.id}))};
      break;
    }

    case FETCH_FIELDS + "_PENDING":
      if (!action.meta.silent) {
        state = {...state, fieldsLoaded: false};
      }
      break;

    case FETCH_FIELDS + "_FULFILLED": {
      let fields = action.payload && action.payload.fields;
      let silent = action.meta && action.meta.silent;

      if (fields) {
        let fieldsMap = createFieldsMap(fields);

        if (silent) {
          // only update state if the number of unprocessed fields changed
          let unprocessedFields = getUnprocessedFields(fieldsMap);

          if (unprocessedFields < state.unprocessedFields) {
            state = {...state, fields: fieldsMap, unprocessedFields: unprocessedFields};
          }
          else {
            state = {...state, unprocessedFields: unprocessedFields};
          }
        }
        else {
          state = {
            ...state,
            fields: fieldsMap,
            fieldsLoaded: true,
            unprocessedFields: getUnprocessedFields(fieldsMap)
          };
        }
      }
      break;
    }

    case FETCH_FIELDS + "_REJECTED":
      state = {...state, fieldsLoaded: true};
      break;

    case FETCH_DATES + "_FULFILLED": {
      let dates = action.payload;
      let images = createDateMap(dates);

      state = {...state, images: images};
      state.dates = dates || [];
      state.date = state.date ? state.date : state.dates[state.dates.length - 1];
      // state.showSatelliteImagery = state.dates.length !== 0;

      break;
    }

    case DELETE_FIELD + "_FULFILLED": {
      let fields = new Map([...state.fields]);
      fields.delete(action.meta.field.fieldId);
      state = {
        ...state,
        fields: fields,
        unprocessedFields: getUnprocessedFields(fields)
      };
      break;
    }

    case FETCH_CROP_HEALTH + "_FULFILLED": {
      if (!action.meta) {
        break;
      }

      let images = new Map([...state.images]);
      images.set(action.meta, action.payload);

      state = {
        ...state,
        images: images,
      };
      break;
    }

    case SET_DATE: {
      state = {...state, date: action.payload};
      break;
    }

    case RESET_DATE:
      state = {...state, date: null};
      break;

    case SELECT_FIELD: {
      state = {...state, selectedField: action.payload};
      break;
    }

    case SELECT_FIELD_BY_ID: {
      let fieldId = action.payload;
      if (fieldId) {
        let field = state.fields.get(fieldId);
        if (field) {
          state = {...state, selectedField: field};
        }
      }
      break;
    }

    case SELECT_FIELD_AND_DATE:
      state = {...state, selectedField: {...action.payload.field}, date: action.payload.date};
      break;

    case FILTERED_FIELDS: {
      state = {
        ...state,
        filteredCropTypes: createFilteredCropTypes(state, action.payload),
        filteredFields: createFilteredFieldMap(state, action.payload)
      };
      break;
    }

    case FILTERED_CROP_TYPES: {
      state = {
        ...state,
        filteredCropTypes: createFilteredCropTypes(state, action.payload),
        filteredFields: createFilteredFieldMap(state, action.payload)
      };
      break;
    }

    case RESET_FILTERS: {
      state = {
        ...state,
        filteredCropTypes: [],
        filteredFields: new Map()
      };
      break;
    }

    case CHANGE_IMAGE_TYPE:
      state = {...state, imageType: action.payload};
      break;

    case ADD_FIELDS + "_FULFILLED": {
      let fields = new Map([...state.fields, ...createFieldsMap(action.payload)]);
      state = {...state, fields: fields};
      state.unprocessedFields = getUnprocessedFields(fields);
      break;
    }

    case SET_SATELLITE_IMAGERY_SHOWN:
      state = {...state, showSatelliteImagery: action.payload};
      break;

    case UPDATE_FIELD_NAME + "_FULFILLED":
      state = {
        ...state,
        selectedField: {...state.selectedField},
        fields: new Map(state.fields)
      };
      state.fields.get(action.meta.fieldId).name = action.meta.name;
      if (state.selectedField.fieldId === action.meta.fieldId) {
        state.selectedField.name = action.meta.name;
      }
      break;

    case CREATE_UPDATE_FIELD_SEASON + "_FULFILLED": {
      let {fieldId, crop, sowing, cropVariation} = action.meta;

      let fields = new Map([...state.fields]);
      let field: Field = fields.get(fieldId);

      field.crop = crop;
      field.sowing = sowing;
      field.cropVariation = cropVariation;

      state = {...state, fields: fields, selectedField: field};

      break;
    }

    case CONNECT_FIELDS_TO_WEATHER_STATION + "_FULFILLED": {
      let {fieldIds, deviceId} = action.meta;

      state = {...state, fields: new Map(state.fields)};

      state.fields.forEach((field) => {
        if (fieldIds.includes(field.fieldId)) {
          field.deviceId = deviceId;
        }
        else {
          if (field.deviceId === deviceId) {
            field.deviceId = null;
          }
        }
      });

      break;
    }

    case CONNECT_FIELD_TO_WEATHER_STATION + "_FULFILLED": {
      let {fieldId, deviceId} = action.meta;

      state = {...state, fields: new Map(state.fields)};

      let field = state.fields.get(fieldId);
      field.deviceId = deviceId;

      if (state.selectedField && state.selectedField.fieldId === fieldId) {
        state.selectedField = {...field};
      }

      break;
    }

    case REMOVE_FIELD_FROM_SEASON + "_FULFILLED": {
      return {...state, fields: createFieldsMap([...state.fields.values()].filter((f) => f.fieldId !== action.meta.fieldId))};
    }

    default:
      break;
  }

  return state;
}
