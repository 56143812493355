import {
  useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';
import {useHookRef} from "./useHookRef";

const useEvent = (eventName, handler, element = window) => {
  // Create a ref that stores handler
  const savedHandler = useHookRef(handler);

  useLayoutEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );

};

useEvent.PropTypes = {
  element: PropTypes.node.isRequired,
  eventName: PropTypes.string.isRequired,
  handler: PropTypes.func,
};

export default useEvent;