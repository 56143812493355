import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import {useLangFile} from "js/context/LanguageContext";
import Styles from './PrescriptionTableRow.module.less';
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import {parseLayerName, parseMetaType, PrescriptionJob} from "../Prescription/PrescriptionJob";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {IconButton} from "@material-ui/core";
import {CloudDownload, DeleteForever, Edit} from "@material-ui/icons";
import {voidFunc} from "../../constants/PropTypeUtils";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../hooks/useFirebaseAnalytics";
import Box from "@material-ui/core/Box";
import SelectSeasonFormControl from "../UI-Elements/SelectSeasonFormControl";
import PrescriptionDocumentation from "../PrescriptionDocumentation/PrescriptionDocumentation";

const PrescriptionTableRow = (props: PrescriptionTableRow.propTypes) => {
  const LangFile = useLangFile();
  const analytics = useFirebaseAnalytics();

  const handleOnDelete = (jobId) => () => {
    props.onDelete(jobId);
    analytics.logEvent(FIREBASE_EVENTS.VRM_ARCHIVE_DELETE);
  };

  const handleOnEdit = (job) => () => {
    props.onEdit(job);
    analytics.logEvent(FIREBASE_EVENTS.VRM_ARCHIVE_RESUME);
  };

  const handleOnDownload = (job) => () => {
    props.onDownload(job, false);
    analytics.logEvent(FIREBASE_EVENTS.VRM_ARCHIVE_DOWNLOAD);
  };

  const handleOnSetSeason = (seasonId) => {
    props.onSetSeason(props.job, seasonId);
  };

  let legacy = props.job.legacy;
  let unassigned = props.job.seasonId == null;

  let editTooltip = LangFile.PrescriptionArchiveTable.edit;

  if (legacy) {
    editTooltip = LangFile.PrescriptionArchiveTable.deprecated;
  }
  else if (unassigned) {
    editTooltip = LangFile.PrescriptionArchiveTable.setSeasonBeforeEdit;
  }

  return (
    <TableRow
      hover={false}
      tabIndex={-1}
      key={props.job.jobId}>
      <TableCell className={Styles.bodyCell1} align={props.alignText} padding="none">{props.job.jobName}</TableCell>
      <TableCell className={Styles.bodyCell} align={props.alignText} padding="none">{parseMetaType(props.job.metaType, LangFile)}</TableCell>
      <TableCell className={Styles.bodyCell} align={props.alignText} padding="none">{moment(props.job.createdDate).format("LLL")}</TableCell>
      <TableCell className={Styles.bodyCell} align={props.alignText} padding="none">{parseLayerName(props.job.layer, LangFile)}</TableCell>
      <TableCell className={Styles.bodyCell} align="right" padding="none">
        <Box paddingLeft={2} display={"inline-flex"} justifyContent={"flex-end"} alignItems={"center"}>

          {props.showDocumentation && (
            <PrescriptionDocumentation
              documentation={props.documentation}
              prescriptionJob={props.job}
              onDeleteDocumentation={props.onDeleteDocumentation}
              onDocumentationUploaded={props.onDocumentationUploaded}/>
          )}

          {props.showSeasonSelector && (
            <SelectSeasonFormControl value={props.job.seasonId} onChange={handleOnSetSeason} fieldId={props.job.fieldId}/>
          )}

          <Box px={1}/>

          <Tooltip title={legacy ? LangFile.PrescriptionArchiveTable.deprecated : LangFile.PrescriptionTableToolbar.delete}>
            <span>
              <IconButton className={Styles.action} aria-label={LangFile.PrescriptionTableToolbar.delete} onClick={handleOnDelete(props.job.jobId)} disabled={legacy}>
                <DeleteForever style={{transform: 'scale(0.8,0.8)'}} color={"secondary"}/>
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={LangFile.PrescriptionArchiveTable.download}>
            <IconButton className={Styles.action} aria-label={LangFile.PrescriptionArchiveTable.download} onClick={handleOnDownload(props.job)}>
              <CloudDownload style={{transform: 'scale(0.8,0.8)'}}/>
            </IconButton>
          </Tooltip>
          <Tooltip title={editTooltip}>
            <span>
              <IconButton className={Styles.action} aria-label={LangFile.PrescriptionArchiveTable.edit} onClick={handleOnEdit(props.job)} disabled={legacy || unassigned}>
                <Edit style={{transform: 'scale(0.8,0.8)'}}/>
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
};

PrescriptionTableRow.propTypes = {
  job: PropTypes.instanceOf(PrescriptionJob),
  showSeasonSelector: PropTypes.bool,
  showDocumentation: PropTypes.bool,
  documentation: PropTypes.object,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onEdit: PropTypes.func,
  onSetSeason: PropTypes.func,
  onDocumentationUploaded: PropTypes.func,
  onDeleteDocumentation: PropTypes.func,
  alignText: PropTypes.oneOf(["center", "left", "right"])
};

PrescriptionTableRow.defaultProps = {
  onDelete: voidFunc,
  onDownload: voidFunc,
  onEdit: voidFunc,
  onDocumentationUploaded: voidFunc,
  onDeleteDocumentation: voidFunc,
  alignText: "left"
};

export default memo(PrescriptionTableRow);