import {array} from 'prop-types';

export default class FieldOverviewSection {
  id: number;
  header: String;
  fields: array;
  cropType: String;

  constructor(id: number, header: String, fields: array, cropType: String) {
    this.id = id;
    this.header = header;
    this.fields = fields;
    this.cropType = cropType;
  }
}