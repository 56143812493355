import {memo, useEffect, useRef} from 'react';
import useFirebaseAnalytics from "../../hooks/useFirebaseAnalytics";
import {useFarm, useUser} from "../../context/AccountContext";
import {useLanguage} from "../../context/LanguageContext";
import {usePermissions} from "../../context/PermissionContext";
import APIConstants from "../../APIConstants";

const AnalyticsContainer = (props: AnalyticsContainer.propTypes) => {

  const bootstrapped = useRef(false);

  const analytics = useFirebaseAnalytics();
  const farm = useFarm();
  const user = useUser();
  const language = useLanguage();
  const permissions = usePermissions();

  useEffect(() => {
    if (bootstrapped.current || !APIConstants.enableAnalytics) {
      return;
    }

    bootstrapped.current = true;

    analytics.setAnalyticsCollectionEnabled(true); //
    // analytics.setUserId(user.id);
    analytics.setUserProperties({
      'user_id': user.id,
      'email': user.email,
      'language': user.language,
      'app_language': language,
      'name': `${user.firstName} ${user.lastName}`,
      'farm': farm.name,
      'role': farm.role,
      ...permissions.farm,
      ...permissions.user,
    }, {global: true});

  }, [farm, user, language, permissions]);


  return null;
};

AnalyticsContainer.propTypes = {};

export default memo(
  AnalyticsContainer
);
