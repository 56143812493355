"use strict";

import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";

import {
  setViewMode
} from "js/reducers/ControlReducer";
import {getFieldName} from "js/helpers/StateInterpreters";
import {
  getLatLngValues,
  goToField
} from "js/helpers/MapsUtils";
import {
  selectField,
  selectFieldAndDate
} from "js/reducers/FieldReducer";
import SearchDialog from "js/components/SearchDialog/SearchDialog";
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocationIcon from '@material-ui/icons/LocationOn';

import moment from "moment/moment";
import StyleConstants from "js/StyleConstants";
import ViewModeConstants from "js/constants/ViewModeConstants";
import areaCalculation from "js/algorithms/geometry/AreaCalculation";
import {fuzzyFilter} from "js/algorithms/filtering";
import useEvent from "js/hooks/useEvent";
import {
  useLangFileRef,
  useLanguage
} from "js/context/LanguageContext";
import SearchMapTarget from "js/components/SearchDialog/SearchMapTarget";
import {LAT_LNG_REGEX} from "js/constants/RegexConstants";
import {getNoteTypeName} from "../../constants/NoteTypes";
import {useGoogleMap} from "js/context/GoogleMapContext";

const geocodeByAddress = (address) => {
  let geocoder = new google.maps.Geocoder();
  let OK = google.maps.GeocoderStatus.OK;

  return new Promise(function (resolve, reject) {
    geocoder.geocode({address: address}, function (results, status) {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};

const getLatLng = (result) => {
  return new Promise(function (resolve, reject) {
    try {
      let latLng = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng()
      };
      resolve(latLng);
    }
    catch (e) {
      reject(e);
    }
  });
};

const mapStoreToProps = (store) => {
  return {
    fields: store.field.fields,
    date: store.field.date,
    dates: [...store.field.images.keys()],
    fieldsLoaded: store.field.fieldsLoaded,
    notes: store.note.notes,
    viewMode: store.control.viewMode,
  };
};

const SearchDialogContainer = ({dispatch, open, fieldsLoaded, notes, fields, dates, dismissDialog, viewMode}) => {

  const LangFileRef = useLangFileRef();
  const language = useLanguage();
  const googleMap = useGoogleMap();
  const [searchText, setSearchText] = useState("");
  const [fieldSource, setFieldSource] = useState([]);
  const [noteSource, setNoteSource] = useState([]);
  const [addressSource, setAddressSource] = useState([]);
  const [focusTextField, setFocusTextField] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [targetLatLng, setTargetLatLng] = useState(null);
  const [autocompleteService, setAutocompleteService] = useState(null);

  useEffect(() => {
    if (open) {
      onReset();
    }
  }, [open]);

  const getNotePredictions = useCallback((filterText) => {
    if (!fieldsLoaded || !notes || notes.size === 0 || filterText === "") {
      return [];
    }

    let result = [];

    Object.values(notes).flatten().forEach((note) => {
      result.push(getNotePrediction(note));
    });

    result = result.filter((item) => item && item.description && fuzzyFilter(filterText, item.description));

    return result;
  }, [notes, fieldsLoaded]);

  const getNotePrediction = useCallback((note) => {
    let {date, type, text, fieldId, id, polygon} = note;

    if (!polygon) {
      return null;
    }

    let dateString = date ? `(${moment(date).locale(language).format("DD-MM-YYYY")})` : "";
    let field = fields.get(fieldId);
    let fieldName = getFieldName(field, LangFileRef.current);
    let typeName = getNoteTypeName(LangFileRef.current, type);

    let size = polygon.length < 3 ? 0 : areaCalculation({
      coords: polygon.map((p) => {
        return {
          lat: p[0],
          lng: p[1]
        };
      })
    }).size.toFixed(2);

    let description = `${LangFileRef.current.SearchDialogContainer.note}: ${fieldName}, ${typeName}, ${size} ha ${text !== "" ? ", " + text : ""} ${dateString} `;

    return {
      key: id,
      description: description,
      note: note,
      field: field
    };
  }, [language, fields]);

  const getFieldPredictions = useCallback((filterText) => {
    if (!fieldsLoaded || !fields || filterText === "") {
      return [];
    }

    let result = [];

    fields.forEach((f) => {
      result.push(getFieldPrediction(f));
    });

    return result.filter((item) => fuzzyFilter(filterText, item.description) || Number(filterText) === item.field.fieldId);
  }, [fieldsLoaded, fields]);

  const getFieldPrediction = useCallback((field) => {
    let description = "";
    let name = getFieldName(field, LangFileRef.current);
    let mef = field.mef;

    if (mef && mef !== name) {
      description = `${LangFileRef.current.SearchDialogContainer.field}: ${mef}, ${name} (${field.size.toFixed(2)} ha)`;
    }
    else {
      description = `${LangFileRef.current.SearchDialogContainer.field}: ${name} (${field.size.toFixed(2)} ha)`;
    }

    return {
      key: field.fieldId,
      description: description,
      field: field
    };
  }, []);

  const onChange = useCallback((value) => {
    setSearchText(value);

    if (value === "" || !autocompleteService) {
      return;
    }

    let request = {
      input: value,
      componentRestrictions: {
        country: LangFileRef.current.SearchDialogContainer.countryRestriction
      }
    };

    autocompleteService.getPlacePredictions(request, (predictions, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        setAddressSource(predictions);
      }

      setFieldSource(getFieldPredictions(value));
      setNoteSource(getNotePredictions(value));
    });
  }, [autocompleteService, getFieldPredictions, getNotePredictions]);

  const onReset = useCallback(() => {
    setSearchText("");
    setFocusTextField(true);
    setActiveIndex(0);
  }, []);

  const onSelectAddress = useCallback((addressObject) => {
    dismissDialog();

    geocodeByAddress(addressObject.description)
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        googleMap.setCenter(latLng);
        googleMap.setZoom(15);
        setTargetLatLng(latLng);
      })
      .then(() => {
        onReset();
      })
      .catch((error) => {
        console.error(error);
      });
  }, [googleMap]);

  const onSelectLatLng = useCallback((latLng) => {
    dismissDialog();
    setTargetLatLng(latLng);
    googleMap.setCenter(latLng);
    googleMap.setZoom(15);
    onReset();
  }, [googleMap, dismissDialog]);

  const onSelectField = useCallback((field) => {
    dismissDialog();
    goToField(googleMap, field);
    dispatch(selectField(field));
    onReset();
  }, [googleMap, dismissDialog]);

  const onSelectNote = useCallback((note, field) => {
    dismissDialog();

    let date = note.date !== null ? moment(note.date).format("YYYY-MM-DD") : dates[dates.length - 1];

    dispatch(selectFieldAndDate(field, date));
    dispatch(setViewMode(ViewModeConstants.ANALYSIS, "analysis"));

    onReset();
  }, [dates, dismissDialog]);

  const onEscKeyDown = useCallback(() => {
    setActiveIndex(0);
    setFocusTextField(true);
  }, []);

  const getSource = useCallback(() => {
    let latLng = searchText.match(LAT_LNG_REGEX);

    if (latLng) {
      latLng = latLng[0];

      let coords = latLng.includes(';') ? latLng.split(';') : latLng.split(',');
      let lat = coords[0];
      let lng = coords[1];

      return [{
        text: `${LangFileRef.current.SearchDialogContainer.coordinates}: ${lat}, ${lng} (${LangFileRef.current.SearchDialogContainer.lat}/${LangFileRef.current.SearchDialogContainer.lng})`,
        value: new google.maps.LatLng(lat, lng),
        type: 'latLng',
        prediction: latLng,
        icon: <LocationIcon/>
      }];
    }

    let fields = fieldSource ? fieldSource.map((f) => {
      return {
        text: f.description,
        value: f.key,
        type: 'field',
        prediction: f,
        field: f.field,
        icon: <DashboardIcon style={{fill: StyleConstants.colors.greenA700}}/>
      };
    }) : [];

    if (searchText.startsWith(LangFileRef.current.SearchDialogContainer.field + ":")) {
      return fields;
    }

    let addresses = addressSource ? addressSource.map((a) => {
      return {
        text: a.description,
        value: a.placeId,
        type: 'address',
        address: a,
        icon: <LocationIcon/>
      };
    }) : [];

    let notes = noteSource ? noteSource.map((a) => {
      return {
        text: a.description,
        secondaryText: a.secondaryText,
        value: a.key,
        type: 'note',
        note: a.note,
        field: a.field,
        icon: <AssignmentIcon style={{fill: StyleConstants.colors.amberA700}}/>
      };
    }) : [];

    if (searchText.startsWith(LangFileRef.current.SearchDialogContainer.note + ":") || searchText.startsWith(LangFileRef.current.SearchDialogContainer.notes + ":")) {
      return notes;
    }

    return [...fields, ...notes, ...addresses];
  }, [fieldSource, addressSource, noteSource, searchText]);

  const onSelect = useCallback((clickedIndex) => {
    let source = getSource();

    let index = clickedIndex === -1 ? 0 : clickedIndex;

    if (source.length === 0) {
      return;
    }

    if (source.length === 1 || index > source.length - 1) {
      index = 0;
    }

    let item = source[index];

    if (item.type === "field") {
      onSelectField(item.field);
    }
    else if (item.type === "latLng") {
      onSelectLatLng(item.value);
    }
    else if (item.type === "note") {
      onSelectNote(item.note, item.field);
    }
    else if (item.type === "address") {
      onSelectAddress(item.address);
    }
  }, [getSource, onSelectField, onSelectLatLng, onSelectNote, onSelectAddress]);

  const handleKeyboardShortcut = useCallback((e) => {
    if (viewMode !== ViewModeConstants.OVERVIEW) {
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();

      onSelect(activeIndex);
      setFocusTextField(false);
    }

    if (e.key === "ArrowDown" || e.key === "Down") {
      e.preventDefault();

      let index = activeIndex + 1;

      if (index > getSource().length - 1) {
        index = 0;
      }

      setActiveIndex(index);
      setFocusTextField(false);
    }

    if (e.key === "ArrowUp" || e.key === "Up") {
      e.preventDefault();

      let index = activeIndex - 1;

      if (index < 0) {
        index = getSource().length - 1;
      }

      setActiveIndex(index);
      setFocusTextField(false);
    }
  }, [viewMode, activeIndex, getSource, onSelect]);

  useEvent("keydown", handleKeyboardShortcut);

  useEffect(() => {
    setAutocompleteService(new google.maps.places.AutocompleteService());
  }, [google]);

  let {lat, lng} = getLatLngValues(targetLatLng);
  let mapTargetText = targetLatLng && `${Number(lat).toFixed(4)}, ${Number(lng).toFixed(4)}`;

  return (
    <Fragment>

      <SearchMapTarget latLng={targetLatLng} onRemove={() => setTargetLatLng(null)} text={mapTargetText}/>

      <SearchDialog
        open={open}
        googleMap={googleMap}
        value={searchText}
        source={getSource()}
        onChange={onChange}
        activeIndex={activeIndex}
        onEscKeyDown={onEscKeyDown}
        focusTextField={focusTextField}
        onSelectAddress={onSelectAddress}
        onSelectField={onSelectField}
        dismissDialog={dismissDialog}
        onSelect={onSelect}/>
    </Fragment>
  );
};

SearchDialogContainer.propTypes = {
  open: PropTypes.bool,
  dismissDialog: PropTypes.func,
};

export default memo(connect(mapStoreToProps)(SearchDialogContainer));