import React, {memo, useCallback, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {
    Button,
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItemIcon,
    ListItemText
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxOutlined from "@material-ui/icons/CheckBoxOutlined";
import List from "@material-ui/core/List";
import {useLangFile} from "../../context/LanguageContext";
import SubList from '../SatelliteLayerList/SubList';
import {
    SURVEY_NUTRIENT_LAYERS,
    SURVEY_TEXTURE_LAYERS
} from '../../constants/SurveyLayers';
import {
    FIELD_STATISTICS_FIELD_COLUMNS,
    FIELD_STATISTICS_SEASON_COLUMNS,
    FIELD_STATISTICS_SECTIONS,
    getColumnName
} from './FieldStatisticsColumns';
import Styles from './FieldStatisticsEditContainer.module.less';
import {connect} from "react-redux";

const mapStateToProps = (store) => ({
    savedColumns: store.statistics.savedColumns,
});

const FieldStatisticsEditContainer = (props: FieldStatisticsEditContainer.propTypes) => {
    const { onClose, onSave, savedColumns } = props;

    const LangFile = useLangFile();
    const [selectedColumns, setSelectedColumns] = useState(savedColumns);

    useEffect(() => {
        setSelectedColumns(savedColumns);
    }, [savedColumns]);

    const handleOnColumnClick = (column) => useCallback(() => {
        const array = Array.from(selectedColumns);
        const index = array.indexOf(column);
        if (index !== -1) {
            array.splice(index, 1);
        }
        else {
            array.push(column);
        }
        setSelectedColumns(array);
    }, [selectedColumns]);

    const handleOnSave = () => {
        onSave(selectedColumns);
    };

    const renderListItem = () => (column) => {
            return (
              <ListItem
                dense
                button
                key={column}
                onClick={handleOnColumnClick(column)}>
                  <ListItemIcon style={{minWidth: 36}}>
                      <Checkbox
                        style={{padding: 0}}
                        checked={selectedColumns.includes(column)}
                        icon={<CheckBoxOutlineBlank fontSize="small"/>}
                        checkedIcon={<CheckBoxOutlined fontSize="small"/>}
                        color="primary"/>
                  </ListItemIcon>
                  <ListItemText primary={getColumnName(column, LangFile)}/>
              </ListItem>
            );
    };

    return (
        <Dialog
            maxWidth={'md'}
            disableBackdropClick={true}
            open={true}>
            <DialogTitle>{LangFile.FieldStatistics.selectColumns}</DialogTitle>
            <DialogContent className={Styles.dialogContent} dividers>
                <Box display={'flex'} flexDirection={'row'}>

                    {Object.keys(FIELD_STATISTICS_SECTIONS).map((key) => {
                        let section = FIELD_STATISTICS_SECTIONS[key];
                        let items = Object.values(section);

                        let title;
                        switch (section) {
                            case FIELD_STATISTICS_FIELD_COLUMNS: {
                                title = LangFile.FieldStatisticsColumn.FIELD;
                                break;
                            }
                            case FIELD_STATISTICS_SEASON_COLUMNS: {
                                title = LangFile.SeasonUtils.season;
                                break;
                            }
                            case SURVEY_NUTRIENT_LAYERS: {
                                title = LangFile.SurveyLayerList.nutrients;
                                break;
                            }
                            case SURVEY_TEXTURE_LAYERS: {
                                title = LangFile.SurveyLayerList.texture;
                                break;
                            }
                            default: {
                                title = "";
                            }
                        }

                        if (!title || title.length === 0) {
                            return null;
                        }

                        let allSelected = true, allDeselected = true, indeterminate = false;
                        items.forEach((item) => {
                            let selected = selectedColumns.includes(item);
                            allSelected = allSelected && selected;
                            allDeselected = allDeselected && !selected;
                        });
                        indeterminate = !allSelected && !allDeselected;

                        let onCheckAll = () => {
                            if (allSelected) {
                                // select none
                                let toExclude = items;
                                setSelectedColumns((current) => current.filter((item) => !toExclude.includes(item)));
                            }
                            else {
                                // indeterminate or none selected
                                // select all
                                setSelectedColumns((current) => (Array.from(new Set([...current, ...items]))));
                            }
                        };

                        let checkAllJSX = (
                            <Checkbox
                              checked={allSelected}
                              onClick={onCheckAll}
                              indeterminate={indeterminate}/>
                        );

                        return (
                          <List disablePadding key={key}>
                              <SubList title={title} items={items} renderItem={renderListItem()} subheaderCheckbox={checkAllJSX}/>
                          </List>
                        );
                    })}

                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose}>
                    {LangFile.FieldStatistics.cancel}
                </Button>
                <Button onClick={handleOnSave}>{LangFile.FieldStatistics.save}</Button>
            </DialogActions>
        </Dialog>
    );
};

FieldStatisticsEditContainer.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

FieldStatisticsEditContainer.defaultProps = {
    savedColumns: []
};

export default memo(connect(mapStateToProps)(FieldStatisticsEditContainer));
