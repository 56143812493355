import React, {
  memo,
  useCallback,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {useLangFile} from "../../context/LanguageContext";
import Dialog from "@material-ui/core/Dialog";
import {Box, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {voidFunc} from "../../constants/PropTypeUtils";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {
  RAIN_CONSTANTS,
  TEMPERATURE_CONSTANTS,
  WIND_CONSTANTS
} from "../../constants/MeasureConstants";

import Styles from "./AppSettingsDialog.module.less";
import {
  RAIN,
  TEMPERATURE,
  WIND
} from "js/constants/MeasureConstants";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../hooks/useFirebaseAnalytics";

const AppSettingsDialog = (props: AppSettingsDialog.propTypes) => {

  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();

  const [windState, setWindState] = useState(props.windUnit);
  const [tempState, setTempState] = useState(props.temperatureUnit);
  const [rainState, setRainState] = useState(props.rainUnit);

  const dirtyState = windState !== props.windUnit || tempState !== props.temperatureUnit || rainState !== props.rainUnit;

  const handleDismiss = useCallback(() => {
    props.onDismiss();
  }, []);

  const handleSave = useCallback(() => {
    if (dirtyState) {
      if (tempState !== props.temperatureUnit) {
        props.onTemperatureUnitChanged(tempState);
        analytics.logEvent(FIREBASE_EVENTS.MM_SETTINGS_TOGGLE_TEMP, {tempUnit: tempState});
      }

      if (windState !== props.windUnit) {
        props.onWindUnitChanged(windState);
        analytics.logEvent(FIREBASE_EVENTS.MM_SETTINGS_TOGGLE_WIND, {windUnit: windState});
      }

      if (rainState !== props.rainUnit) {
        props.onRainUnitChanged(rainState);
        analytics.logEvent(FIREBASE_EVENTS.MM_SETTINGS_TOGGLE_RAIN, {rainUnit: rainState});
      }

      props.onDismiss();
      props.onSave();
    }
    else {
      props.onDismiss();
    }
  }, [dirtyState, tempState, windState, rainState, props.onDismiss, props.onTemperatureUnitChanged, props.onWindUnitChanged, props.onRainUnitChanged, props.onSave]);

  return (
    <Dialog open={Boolean(props.open)} maxWidth={'sm'} fullWidth>
      <DialogTitle>
        {LangFile.AppSettings.title}
      </DialogTitle>
      <DialogContent className={Styles.root}>

        <Box my={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{LangFile.AppSettings.temperatureUnit}</FormLabel>

            <Box display={"flex"} justifyContent={"space-between"} mt={1}>
            {Object.keys(TEMPERATURE_CONSTANTS).map((key) => {
                let {unit} = TEMPERATURE_CONSTANTS[key];
                let selected = tempState === key;

                return (
                  <Button key={key} variant={selected ? "contained" : "outlined"} color={selected ? "primary" : "default"} value={key} onClick={() => setTempState(key)}>
                    {LangFile.MeasureConstants.temperatureUnits[key]} ({unit})
                  </Button>
                );
              })}
            </Box>
          </FormControl>
        </Box>

        <Box my={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{LangFile.AppSettings.windUnit}</FormLabel>

            <Box display={"flex"} justifyContent={"space-between"} mt={1}>
              {Object.keys(WIND_CONSTANTS).map((key) => {
                let {unit} = WIND_CONSTANTS[key];

                let selected = windState === key;

                return (
                  <Button key={key} variant={selected ? "contained" : "outlined"} color={selected ? "primary" : "default"} value={key} onClick={() => setWindState(key)}>
                    {LangFile.MeasureConstants.windUnits[key]} ({unit})
                  </Button>
                );
              })}
            </Box>
          </FormControl>
        </Box>

        <Box my={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{LangFile.AppSettings.rainUnit}</FormLabel>

            <Box display={"flex"} justifyContent={"space-between"} mt={1}>
              {Object.keys(RAIN_CONSTANTS).map((key) => {
                let {unit} = RAIN_CONSTANTS[key];

                let selected = rainState === key;

                return (
                  <Button key={key} variant={selected ? "contained" : "outlined"} color={selected ? "primary" : "default"} value={key} onClick={() => setRainState(key)}>
                    {LangFile.MeasureConstants.rainUnits[key]} ({unit})
                  </Button>
                );
              })}
            </Box>
          </FormControl>
        </Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleDismiss}>{LangFile.AppSettings.close}</Button>
        <Button variant={"contained"} color={"primary"} onClick={handleSave}>{LangFile.AppSettings.save}</Button>
      </DialogActions>
    </Dialog>
  );
};

AppSettingsDialog.propTypes = {
  open: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSave: PropTypes.func,
  onTemperatureUnitChanged: PropTypes.func,
  onWindUnitChanged: PropTypes.func,
  onRainUnitChanged: PropTypes.func,
  temperatureUnit: PropTypes.oneOf([...Object.keys(TEMPERATURE_CONSTANTS)]),
  windUnit: PropTypes.oneOf([...Object.keys(WIND_CONSTANTS)]),
  rainUnit: PropTypes.oneOf([...Object.keys(RAIN_CONSTANTS)]),
};

AppSettingsDialog.defaultProps = {
  onDismiss: voidFunc,
  onSave: voidFunc,
  onTemperatureUnitChanged: voidFunc,
  onWindUnitChanged: voidFunc,
  onRainUnitChanged: voidFunc,
  open: false,
  temperatureUnit: TEMPERATURE.CELCIUS,
  windUnit: WIND.METERS_PER_SECOND,
  rainUnit: RAIN.MILLIMETERS,
};

export default memo(
    AppSettingsDialog
);
