import React, {
  memo
} from 'react';

import PropTypes from 'prop-types';

import {
  CircularProgress,
  Box
} from "@material-ui/core";

const LoaderBox = ({loading, error, success, errorMessage, successMessage}) => {
  return (
    <Box m={1}>
      {loading && (
        <CircularProgress size={60} thickness={5}/>
      )}

      {!loading && (
        error && (
          <Box color={"secondary.main"}>{errorMessage}</Box>
        ) ||
        success && (
          <Box color={"primary.main"}>{successMessage}</Box>
        )
      )}
    </Box>
  );
};

LoaderBox.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  success: PropTypes.bool,
  errorMessage: PropTypes.any,
  successMessage: PropTypes.any,
};

LoaderBox.defaultProps = {};

export default memo(LoaderBox);