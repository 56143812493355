import WebAPIUtils from "js/WebAPIUtils";

export const REPLACE_TYPE = {
  NEW_WEATHER_STATION: "NEW_WEATHER_STATION",
  PICK_WEATHER_STATION: "PICK_WEATHER_STATION",
};

/* ============== //
||     ACTIONS    ||
// ============== */

const SET_SHOW_SUSPEND_DIALOG = "fieldsense/ReplaceStationReducer/SET_SHOW_SUSPEND_DIALOG";

export function setShowSuspendDialog(shown) {
  return {
    type: SET_SHOW_SUSPEND_DIALOG,
    payload: shown,
  };
}

const SET_SHOW_REPLACE_DIALOG = "fieldsense/ReplaceStationReducer/SET_SHOW_REPLACE_DIALOG";

export function setShowReplaceDialog(shown) {
  return {
    type: SET_SHOW_REPLACE_DIALOG,
    payload: shown
  };
}

const SET_REPLACE_TYPE = "fieldsense/ReplaceStationReducer/SET_REPLACE_TYPE";

export function setReplaceType(replaceType) {
  return {
    type: SET_REPLACE_TYPE,
    payload: replaceType
  };
}

const REPLACE_WEATHER_STATION = "fieldsense/ReplaceStationReducer/REPLACE_WEATHER_STATION";

export function replaceWeatherStation(id, farmId, replacementId, dismountDate, mountDate) {
  return {
    type: REPLACE_WEATHER_STATION,
    payload: WebAPIUtils.replaceWeatherStation(id, farmId, replacementId, dismountDate, mountDate),
  };
}

const RETIRE_WEATHER_STATION = "fieldsense/ReplaceStationReducer/RETIRE_WEATHER_STATION";

export function retireWeatherStation(label, farmId, dismountDate) {
  return {
    type: RETIRE_WEATHER_STATION,
    payload: WebAPIUtils.retireWeatherStation(label, farmId, dismountDate),
  };
}

const RESET_REQUEST_STATE = "fieldsense/ReplaceStationReducer/RESET_REQUEST_STATE";

export function resetRequestState() {
  return {
    type: RESET_REQUEST_STATE,
  };
}

/* ============== //
||     REDUCER    ||
// ============== */

const initState = {
  loading: false,
  success: false,
  error: false,
  showReplaceDialog: false,
  showSuspendDialog: false,
  replaceType: null,
};

export default function reducer(state = initState, action) {
  switch (action.type) {

    case RESET_REQUEST_STATE: {
      state = {...state, success: false, error: false, loading: false};
      break;
    }

    case SET_SHOW_REPLACE_DIALOG: {
      state = {...state, showReplaceDialog: action.payload};
      break;
    }

    case SET_SHOW_SUSPEND_DIALOG: {
      state = {...state, showSuspendDialog: action.payload};
      break;
    }

    case SET_REPLACE_TYPE: {
      state = {...state, replaceType: action.payload};
      break;
    }

    case REPLACE_WEATHER_STATION + "_PENDING": {
      state = {...state, success: false, error: false, loading: true};
      break;
    }

    case REPLACE_WEATHER_STATION + "_FULFILLED": {
      state = {...state, success: true, error: false, loading: false};
      break;
    }

    case REPLACE_WEATHER_STATION + "_REJECTED": {
      state = {...state, success: false, error: true, loading: false};
      break;
    }

    default:
      break;
  }

  return state;
}
