import {Box, CircularProgress, FormControlLabel, Switch, Typography} from '@material-ui/core';
import React, {memo, useContext} from 'react';
import FieldStatisticsToolbar from './FieldStatisticsToolbar';
import PropTypes from 'prop-types';
import {voidFunc} from '../../constants/PropTypeUtils';
import SeasonSelector from '../Seasons/SeasonSelector';
import DataGrid from 'react-data-grid';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import Styles from './FieldStatistics.module.less';
import {useLangFile} from "../../context/LanguageContext";
import clsx from "clsx";
import {FilterContext} from "./FieldStatisticsContainer";
import {DATA_INTEGRATION_CLAAS_TELEMATICS, isDataIntegrationEnabled} from "../../reducers/DataIntegrationsReducer";

const FieldStatistics = (props: FieldStatistics.propTypes) => {
  const {loading, onBackButtonClicked, onEditButtonClicked, onGoToClaasButtonClicked, onGoToIntegrationButtonClicked, onLinkButtonClicked, onSeasonChange, columns, rows, sortColumns, onSortColumnsChange, direction, dataIntegrations} = props;

  const LangFile = useLangFile();
  const {filters, setFilters} = useContext(FilterContext);

  const enableImportYield = isDataIntegrationEnabled(dataIntegrations, DATA_INTEGRATION_CLAAS_TELEMATICS);
  return (
    <Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} maxHeight={"100vh"}>
      <FieldStatisticsToolbar
        enableImportYield={enableImportYield}
        onBackButtonClicked={onBackButtonClicked}
        onGoToClaasButtonClicked={onGoToClaasButtonClicked}
        onGoToIntegrationButtonClicked={onGoToIntegrationButtonClicked}
        onEditButtonClicked={onEditButtonClicked}
        onLinkButtonClicked={onLinkButtonClicked}/>
      <Box m={2} display={"flex"} justifyContent={"space-between"} alignItems={"flex-end"}>
        <Box maxWidth={250}>
          <Typography variant='h5'>
              {LangFile.FieldStatistics.title}
          </Typography>
          <SeasonSelector
            color={"white"}
            selectOnly={true}
            onChange={onSeasonChange}/>
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={filters.enabled}
                onChange={() => setFilters((cur) => ({...cur, enabled: !cur.enabled}))}
                name="toggle-filters"
                size={"small"}
                color="primary"/>
            }
            labelPlacement={"start"}
            label={LangFile.FieldStatistics.showFilters}/>
        </Box>
      </Box>
      {!loading && rows.length > 0 && (
        <Box className={Styles.gridContainer}>
          <DndProvider backend={HTML5Backend}>
            <DataGrid
              className={clsx({'rdg-light': true, [Styles.grid]: true})}
              headerRowHeight={filters.enabled ? 80 : undefined}
              rowHeight={50}
              columns={columns}
              rows={rows}
              sortColumns={sortColumns}
              onSortColumnsChange={onSortColumnsChange}
              direction={direction}/>
          </DndProvider>
        </Box>
      )}
      {loading && (
        <Box
          width={"100%"}
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}>
          <CircularProgress
            variant={"indeterminate"}
            color={"primary"}
            size={80}
            thickness={4}/>
        </Box>
      )}
    </Box>
  );
};

FieldStatistics.propTypes = {
  loading: PropTypes.bool,
  onBackButtonClicked: PropTypes.func,
  onEditButtonClicked: PropTypes.func,
  onGoToClaasButtonClicked: PropTypes.func,
  onGoToIntegrationButtonClicked: PropTypes.func,
  onLinkButtonClicked: PropTypes.func,
  onSeasonChange: PropTypes.func,
  columns: PropTypes.array,
  rows: PropTypes.array,
  sortColumns: PropTypes.array,
  onSortColumnsChange: PropTypes.func,
  direction: PropTypes.string
};

FieldStatistics.defaultProps = {
  onBackButtonClicked: voidFunc,
  onEditButtonClicked: voidFunc,
  onGoToClaasButtonClicked: voidFunc,
  onGoToIntegrationButtonClicked: voidFunc,
  onLinkButtonClicked: voidFunc,
  onSeasonChange: voidFunc,
  direction: "DESC"
};

export default memo(FieldStatistics);
