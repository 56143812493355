import {
  memo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {useGoogleMap} from "js/context/GoogleMapContext";
import {useHookRef} from "js/hooks/useHookRef";
import useMapEvent from "js/components/DataLayer/hooks/useMapEvent";
import {voidFunc} from "js/constants/PropTypeUtils";

const MapMarker = (props) => {

  const googleMap = useGoogleMap();
  const marker = useRef(new google.maps.Marker());
  const listeners = useRef({
    click: null,
    mouseEnter: null,
    mouseLeave: null,
    dragEnd: null
  });

  const onClick = useHookRef(useCallback((e) => {
    props.onClick(e);
  }, [props.onClick]));

  const onMouseEnter = useHookRef(useCallback((e) => {
    props.onMouseEnter(e);
  }, [props.onMouseEnter]));

  const onMouseLeave = useHookRef(useCallback((e) => {
    props.onMouseLeave(e);
  }, [props.onMouseLeave]));

  const onDragEnd = useHookRef(useCallback((e) => {
    props.onDragEnd(e);
  }, [props.onDragEnd]));

  // Don't show Markers off-screen
  useMapEvent(googleMap, 'idle', useCallback(() => {
    if (marker.current) {

      if (googleMap.getBounds().contains(marker.current.getPosition())) {
        marker.current.setMap(googleMap);
      }
      else {
        marker.current.setMap(null);
      }
    }
  }, [googleMap]));

  // Attach listeners on mount
  useEffect(() => {
    Object.values(listeners.current).forEach((listener) => {
      if (listener) {
        google.maps.event.removeListener(listener);
      }
    });

    listeners.current.click = marker.current.addListener('click', onClick.current);
    listeners.current.mouseEnter = marker.current.addListener('mouseover', onMouseEnter.current);
    listeners.current.mouseLeave = marker.current.addListener('mouseout', onMouseLeave.current);
    listeners.current.dragEnd = marker.current.addListener('dragend', onDragEnd.current);
  });

  useEffect(() => {
    return () => {
      if (marker.current) {
        marker.current.setMap(null);
        marker.current = null;

        Object.values(listeners.current).forEach((listener) => {
          google.maps.event.removeListener(listener);
        });
      }
    };
  }, []);

  // Update marker options when props change
  useEffect(() => {
    if (!props.position) {
      return;
    }

    let options = {
      position: props.position,
      icon: props.icon,
      animate: props.animate ? google.maps.Animation.DROP : null,
      draggable: props.draggable,
      optimized: props.optimized,
      clickable: props.clickable,
      map: googleMap,
      zIndex: props.zIndex
    };

    marker.current.setOptions(options);

  }, [props.position, props.icon, props.animate, props.optimized, props.clickable, props.zIndex, googleMap]);

  return null;
};

MapMarker.propTypes = {
  position: PropTypes.object,
  animate: PropTypes.bool,
  icon: PropTypes.object,
  draggable: PropTypes.bool,
  clickable: PropTypes.bool,
  zIndex: PropTypes.number,

  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onDragEnd: PropTypes.func,
};

MapMarker.defaultProps = {
  onClick: voidFunc,
  onMouseEnter: voidFunc,
  onMouseLeave: voidFunc,
  onDragEnd: voidFunc,
};

export default memo(MapMarker);