import WebAPIUtils from 'js/WebAPIUtils';
import SettingsConstants, {isGdprValid} from "js/constants/SettingsConstants";

/* ============== //
||  ACTIONTYPES   ||
// ============== */

const FETCH_FARM_SETTINGS = "fieldsense/Settings/FETCH_FARM_SETTINGS";
const FETCH_USER_SETTINGS = "fieldsense/Settings/FETCH_USER_SETTINGS";
export const UPDATE_PERSONAL_FARM_SETTING = "fieldsense/Settings/UPDATE_PERSONAL_FARM_SETTING";
export const UPDATE_GLOBAL_FARM_SETTING = "fieldsense/Settings/UPDATE_GLOBAL_FARM_SETTING";
const UPDATE_USER_SETTING = "fieldsense/Settings/UPDATE_USER_SETTING";

/* ============== //
||    HELPERS    ||
// ============== */


/* ============== //
||    ACTIONS    ||
// ============== */

export function fetchFarmSettings(farmId) {
  return {
    type: FETCH_FARM_SETTINGS,
    payload: WebAPIUtils.getFarmSettings(farmId)
  };
}


export function fetchUserSettings(userId) {
  return {
    type: FETCH_USER_SETTINGS,
    payload: WebAPIUtils.getUserSettings(userId)
  };
}

export function updateGlobalFarmSetting(farmId, entry, changedValue) {
  let globalObject = {};
  globalObject[entry] = changedValue;

  let reqObject = {global: globalObject};

  return {
    type: UPDATE_GLOBAL_FARM_SETTING,
    payload: WebAPIUtils.putFarmSetting(farmId, JSON.stringify(reqObject)),
    meta: {entry: entry, value: changedValue}
  };
}

export function updatePersonalFarmSetting(farmId, entry, changedValue) {
  let personalObject = {};
  personalObject[entry] = changedValue;

  let reqObject = {personal: personalObject};

  return {
    type: UPDATE_PERSONAL_FARM_SETTING,
    payload: WebAPIUtils.putFarmSetting(farmId, JSON.stringify(reqObject)),
    meta: {entry: entry, value: changedValue}
  };
}

export function updateUserSetting(userId, entry, value) {
  let reqObject = {
    settings: {}
  };

  reqObject.settings[entry] = value;

  return {
    type: UPDATE_USER_SETTING,
    payload: WebAPIUtils.putUserSetting(userId, JSON.stringify(reqObject)),
    meta: {entry: entry, value: value}
  };
}

export function updateLanguage(language) {
  let reqObject = {
    settings: {}
  };

  reqObject.settings["language"] = language;

  return {
    type: UPDATE_USER_SETTING,
    payload: WebAPIUtils.putLanguage(language),
    meta: {entry: "language", value: language}
  };
}

/* ============== //
||     REDUCER    ||
// ============== */

const initState = {
  farmSettings: {},
  userSettings: {},
  userSettingsLoaded: false
};

export default function reducer(state = initState, action) {
  switch (action.type) {

    case FETCH_FARM_SETTINGS + "_FULFILLED":
      if (action.payload) {
        state = {...state, farmSettings: action.payload};
      }
      break;

    case FETCH_USER_SETTINGS + "_FULFILLED":
      let settings = action.payload;
      if (settings) {

        let gdpr = settings[SettingsConstants.USER_GDPR_CONSENT];

        if (gdpr && !isGdprValid(gdpr)) {
          console.error("Invalid GDPR - Clearing");
          delete settings[SettingsConstants.USER_GDPR_CONSENT];
        }

        state = {...state, userSettings: settings, userSettingsLoaded: true};
      }
      break;

    case UPDATE_PERSONAL_FARM_SETTING + "_FULFILLED":
      // Deep Clone Farm Settings, assuming we don't store dates or functions in settings
      state = {...state, farmSettings: JSON.parse(JSON.stringify(state.farmSettings))};
      state.farmSettings.personal[action.meta.entry] = action.meta.value;
      break;

    case UPDATE_GLOBAL_FARM_SETTING + "_FULFILLED":
      // Deep Clone Farm Settings, assuming we don't store dates or functions in settings
      state = {...state, farmSettings: JSON.parse(JSON.stringify(state.farmSettings))};
      state.farmSettings.global[action.meta.entry] = action.meta.value;
      break;

    case UPDATE_USER_SETTING + "_FULFILLED":
      // Deep Clone Farm Settings, assuming we don't store dates or functions in settings
      state = {...state, userSettings: JSON.parse(JSON.stringify(state.userSettings))};
      state.userSettings[action.meta.entry] = action.meta.value;
      break;


    default:
      break;
  }

  return state;
}
