"use strict";

import React, {Fragment} from 'react';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import {withStyles} from "@material-ui/core/styles";
import {green, red} from "@material-ui/core/colors";
import {Grid} from "@material-ui/core";
import clsx from "clsx";

const styles = (theme) => ({
  root: {
    height: 'auto',
    maxWidth: 260,
    position: 'fixed',
    left: 16,
    top: 16,
    transform: 'translate(0, 37px)',
    overflow: 'visible',
    '&.shown': {
      transition: "transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 1000ms",
      transform: 'translateY(37px)',

    },
    '&.not-shown': {
      transition: "transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 450ms",
      transform: 'translateY(-2000%)',
    },
    '& h1': {
      fontSize: "18px",
      color: 'black',
      fontWeight: "bold",
      textAlign: "center",
    },
    '& h2': {
      margin: "0 0 15px",
      padding: [[0, 16]],
      fontSize: "14px",
      color: 'black',
      textAlign: "left",
      fontWeight: 300,
    },
  },
  diamond: {
    position: 'absolute',
    left: 0,
    top: '50%',
    width: 16,
    height: 16,
    transform: "translate(-50%, -50%) rotate(45deg)",
    background: 'white',
    zIndex: 2,
  },
  disabledReason: {
    position: 'absolute',
    left: "100%",
    bottom: 0,
    background: 'white',
    borderRadius: 2,
    transform: "translate(24px, 10px)",
  },
  wrapper: {
    position: 'relative',
    width: 260,
    height: 48,
    padding: 4,
    lineHeight: "24px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    zIndex: 4,
    position: 'absolute',
    padding: theme.spacing(1),
  },
  content: {
    padding: 16,
    '&$disableContentPadding': {
      padding: 0
    }
  },
  disableContentPadding:{},
  valid: {
    color: green['A400'],
  },
  invalid: {
    color: red['A400'],
  },
  buttonHolder: {
    marginTop: 10,
  },
  actionButton: {
    height: '100%',
    display: 'inline-flex',
  }
});

const ConfirmActionPopover = ({classes, style, shown, disabled, action, title, message, status, children, actionText, cancelText, cancelAction, specialAction, specialText, disabledReason, disableContentPadding, enableSpecialAction}) => {

  let confirmButtonJSX = null;

  if (actionText && typeof action === "function") {
    confirmButtonJSX =
      <Button
        style={{width: '50%'}}
        className={classes.actionButton}
        disabled={disabled}
        onClick={action}
        color={"primary"}
        variant={"contained"}>{actionText}</Button>;
  }

  let cancelButtonJSX = "";
  if (typeof cancelAction === "function") {
    cancelButtonJSX =
      <Button
        style={{width: confirmButtonJSX ? '50%' : '100%'}}
        className={classes.actionButton}
        variant={"text"}
        color={"default"}
        fullWidth={!confirmButtonJSX}
        onClick={cancelAction}>{cancelText}</Button>;
  }

  let specialActionJSX = "";

  if (typeof specialAction === "function" && enableSpecialAction) {
    specialActionJSX = (
      <Button
        className={classes.actionButton}
        style={{margin: 8, marginBottom: 24}}
        variant={"outlined"}
        color={"inherit"}
        onClick={specialAction}>{specialText}</Button>
    );
  }

  let disabledReasonJSX = "";
  if (disabledReason) {
    disabledReasonJSX =
      <div className={classes.disabledReason}>
        <div className={classes.wrapper}>
          <div className={classes.diamond}/>
          <div className={classes.text}>
            {disabledReason}
          </div>
        </div>
      </div>;
  }

  return (
    <Paper
      className={`${classes.root} ${shown ? 'shown' : 'not-shown'}`}
      elevation={1}
      style={style || null}>
      <Fragment>
        <h1>{title}</h1>
        <h2>{message}</h2>

        <Grid container direction={"column"} className={clsx({[classes.content]: true, [classes.disableContentPadding]: disableContentPadding})}>
          <div>{status}</div>

          {specialActionJSX}

          {disabledReasonJSX}

          {children}
        </Grid>

        <Grid container direction={"row"}>
          {cancelButtonJSX}

          {confirmButtonJSX}
        </Grid>
      </Fragment>
    </Paper>
  );
};

export default withStyles(styles)(ConfirmActionPopover);