import PropTypes from 'prop-types';
import React, {memo, useCallback, useEffect, useState} from 'react';
import {Box, Fab, Typography} from '@material-ui/core';
import Styles from './FieldIntegrationMappingCell.module.less';
import {Link, LinkOff} from '@material-ui/icons';
import {voidFunc} from '../../constants/PropTypeUtils';
import {fieldToTurfPolygon} from '../../helpers/MapsUtils';
import * as turf from '@turf/turf';
import {MAPPING_ACTION} from './FieldIntegrationMappingContainer';
import {getFieldName} from "../../helpers/StateInterpreters";
import {useLangFile} from "../../context/LanguageContext";
import FieldIntegrationBrokenLinksSelector from './FieldIntegrationBrokenLinksSelector';

const FieldIntegrationBrokenLinksCell = (props: FieldIntegrationBrokenLinksCell.propTypes) => {
    const {field, workbooks, link, onSelectLink} = props;
    const [workbookItems, setWorkbookItems] = useState([]);
    const LangFile = useLangFile();

    const handleOnSelect = useCallback((workbook) => {
        onSelectLink(link, workbook);
    }, [link, onSelectLink]);

    const color = link.type ? '#000' : 'red';

    const calculateConfidence = (workbookPolygon, fieldPolygon) => {
        try {
            const intersection = turf.intersect(workbookPolygon, fieldPolygon);
            if (intersection) {
                const intersectionPolygon = turf.polygon(intersection.geometry.coordinates);
                const intersectionArea = turf.area(intersectionPolygon);
                const workbookPolygonArea = turf.area(workbookPolygon);
                return (intersectionArea / workbookPolygonArea) * 100;
            }
        }
        catch (error) {
            // console.log('Intersect error: ', error);
        }
        return 0;
    };

    useEffect(() => {
        let items = workbooks.map((workbook) => {
            if (workbook.location) {
                let workbookPolygon = turf.polygon(workbook.location.coordinates);
                return {
                    workbook: workbook,
                    confidence: calculateConfidence(workbookPolygon, fieldToTurfPolygon(field)).toFixed(0)
                };
            }
            else {
                return {
                    workbook: workbook,
                    confidence: 0
                };
            }
        }).sort((lhs, rhs) => {
            return rhs.confidence - lhs.confidence;
        });
        setWorkbookItems(items);
    }, [field, workbooks]);

    return (
        <Box flex={1} display={'flex'} flexDirection={'row'}>
            <Box className={Styles.box} flex={3} height={'80px'} display={'flex'} flexDirection={'row'} width={210}>
                <Box display={'flex'} flexDirection={'column'} p={1}>
                    <Typography className={Styles.text} style={{fontSize: '10px'}}>{field.mef}</Typography>
                    <Typography className={Styles.text} style={{fontWeight: 500}}>{getFieldName(field, LangFile)}</Typography>
                    <Typography className={Styles.text}>{LangFile.CropType[field.crop]}</Typography>
                    <Typography className={Styles.text}>{field.size.toFixed(2) + " ha"}</Typography>
                </Box>
            </Box>
            <Box flex={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                <Box bgcolor={color} width={'33%'} height={'1px'}/>
                <Fab variant={"extended"} size={"small"} className={Styles.center} style={{border: `1px solid ${color}`}} disabled={true}>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                        {(link.type && link.type === MAPPING_ACTION.UNLINK) && (
                          <LinkOff style={{ color: color }}/>
                        )}
                        {(link.type && link.type !== MAPPING_ACTION.UNLINK) && (
                            <Link style={{ color: color }}/>
                        )}
                        {!link.type && (
                            <Typography className={Styles.text} style={{fontSize: '20px', color: color}}>!</Typography>
                        )}
                    </Box>
                </Fab>
                <Box bgcolor={color} width={'33%'} height={'1px'}/>
            </Box>
            <Box className={Styles.box} style={{border: `1px solid ${color}`}} flex={3} height={'80px'} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                <FieldIntegrationBrokenLinksSelector workbookItems={workbookItems} link={link} onSelect={handleOnSelect}/>
            </Box>
        </Box>
    );
};

FieldIntegrationBrokenLinksCell.propTypes = {
    onSelectLink: PropTypes.func,
    field: PropTypes.object,
    workbooks: PropTypes.array,
    link: PropTypes.object,
};

FieldIntegrationBrokenLinksCell.defaultProps = {
    onSelectLink: voidFunc,
};

export default memo(FieldIntegrationBrokenLinksCell);
