/*
  This is the basic google maps configuration.
  It is used to return the required URL we should use to fetch google maps
  Given the options object users can provide different configurations.
*/

const GoogleMapsApi = (options) => {
  let opts = options || {};

  // Initialize parameters based on given options.
  const apiKey = opts.apiKey;
  const libraries = opts.libraries || [];
  const googleVersion = opts.version || null;
  const URL = 'https://maps.googleapis.com/maps/api/js';

  // Generate the url
  const url = () => {
    let url = URL;
    let params = {
      key: apiKey,
      libraries: libraries.join(','),
      v: googleVersion,
    };

    // Convert the parameters into the url string format
    let paramStr = Object.keys(params)
        .filter((k) => !!params[k])
        .map((k) => `${k}=${params[k]}`).join('&');

    // Combine the url and parameters into the final url
    return `${url}?${paramStr}`;
  };

  return url();
};

export default GoogleMapsApi;
