import {
  AUTHENTICATE_USER,
  IMPERSONATION_FARM_AUTH,
  IMPERSONATION_LOG_IN,
} from "js/reducers/UserReducer";
import {
  AUTHENTICATE_FARM,
  CHANGE_FARM
} from "js/reducers/FarmReducer";
import WebAPIUtils from 'js/WebAPIUtils.js';

/* ============== //
||  ACTIONTYPES   ||
// ============== */

const USER_PASSWORD_UPDATE = "fieldsense/Credential/USER_PASSWORD_UPDATE";
export const IMPERSONATE_USER = "fieldsense/Credential/IMPERSONATE_USER";
export const SET_ACCESS_TOKEN = "fieldsense/Credential/SET_ACCESS_TOKEN";
const RESTORE_PASSWORD = "fieldsense/Credential/RESTORE_PASSWORD";
const FORCE_REFRESH_TOKEN = "fieldsense/CredentialReducer/FORCE_REFRESH_TOKEN";

/* ============== //
||    ACTIONS    ||
// ============== */

export function setAccessToken(token) {
  return {
    type: SET_ACCESS_TOKEN,
    payload: token ? token : null
  };
}

export function forceRefreshToken() {
  return {
    type: FORCE_REFRESH_TOKEN,
    payload: WebAPIUtils.forceRefreshToken(),
  };
}

export function changePassword(userId, pswd, doneCallback) {
  return (dispatch) => {
    return dispatch({
      type: USER_PASSWORD_UPDATE,
      payload: WebAPIUtils.putPassword(userId, pswd),
    })
      .then(() => doneCallback(true))
      .catch(() => doneCallback(false));
  };
}

export function impersonateUser(userId) {
  return (dispatch) => {
    return dispatch({
      type: IMPERSONATE_USER,
      payload: WebAPIUtils.impersonateUser(userId)
    });
  };
}


export function restorePassword(payload) {
  return {
    type: RESTORE_PASSWORD,
    payload: WebAPIUtils.passwordResetRequest(payload)
  };
}


/* ============== //
||     REDUCER    ||
// ============== */

const initState = {
  loggedIn: false,
  loggingIn: false,
  rememberMe: false,
  accessToken: null,
  refreshToken: null
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case AUTHENTICATE_USER + "_PENDING": {
      state = {
        ...state,
        loggedIn: false,
        loggingIn: true,
        rememberMe: action.meta.rememberMe,
      };
      break;
    }
    case AUTHENTICATE_USER + "_REJECTED": {
      state = {
        ...state,
        loggedIn: false,
        loggingIn: false,
      };
      break;
    }

    case AUTHENTICATE_USER + "_FULFILLED": {
      state = {
        ...state,
        accessToken: action.payload.token,
        refreshToken: action.payload.refreshToken,
      };
      break;
    }

    case AUTHENTICATE_FARM + "_FULFILLED": {
      state = {
        ...state,
        loggingIn: false,
        accessToken: action.payload.token,
        refreshToken: action.payload.refreshToken,
        loggedIn: true,
      };
      break;
    }

    case AUTHENTICATE_FARM + "_REJECTED": {
      state = {
        ...state,
        loggedIn: false,
        loggingIn: false,
      };
      break;
    }

    case RESTORE_PASSWORD + "_FULFILLED": {
      break;
    }

    case SET_ACCESS_TOKEN: {
      // Get the new access token from the response body
      let accessToken = "Bearer " + action.payload.accessToken;
      // Get the new refresh token from the response body
      let refreshToken = action.payload.refreshToken;

      state = {...state, accessToken: accessToken, refreshToken: refreshToken};
      break;
    }

    case USER_PASSWORD_UPDATE + "_FULFILLED":
      break;

    case CHANGE_FARM + "_FULFILLED":
      state = {...state, accessToken: action.payload.token, refreshToken: action.payload.refreshToken};
      break;

    case IMPERSONATION_LOG_IN + "_FULFILLED":
      state = {...state, accessToken: action.payload.token, refreshToken: action.payload.refreshToken, loggedIn: true};
      break;

    case IMPERSONATE_USER + "_FULFILLED":
      state = {
        ...state,
        accessToken: "Bearer " + action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        loggedIn: true
      };
      break;

    case IMPERSONATION_FARM_AUTH + "_FULFILLED":
      state = {...state, accessToken: action.payload.token, refreshToken: action.payload.refreshToken, loggedIn: true};
      break;

    default:
      break;
  }

  return state;
}
