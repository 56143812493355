import {FvmField} from "js/model/FvmField";
import {Outline} from "js/model/Outline";
import {multiPolygon} from "@turf/turf";
import type {
  GeoJSONFeature,
} from "@mapbox/geojson-types";

export const mapFvmFieldsToOutlines = (featureCollection /** Feature Collection **/, existingFields): Array<Outline> => {
  let features: Array<FvmField> = featureCollection.features;

  // Figure out which outlines represents fields which are already added
  let existingOutlineIds = new Set();
  existingFields.forEach((field) => {
    existingOutlineIds.add(field.mef + field.size.toFixed(0));
  });

  return features.map((field: FvmField) => {
    let poly: GeoJSONFeature = multiPolygon(field.geometry.coordinates);
    let coords = poly.geometry.coordinates[0][0]; // select the outer ring and exclude holes

    coords = coords.map((c) => ({lat: c[1], lng: c[0]}));

    let outline = new Outline();
    outline.id = field.id;
    outline.cropType = field.properties.Afgroede;
    outline.mef = field.properties.Marknr;
    outline.size = field.properties.IMK_areal;
    outline.coordinates = coords;

    return outline;
  }).filter((outline: Outline) => {
    return outline.coordinates.length > 3 && outline.size >= 0.3;
  }).filter((outline: Outline) => {
    return !existingOutlineIds.has(outline.mef + outline.size.toFixed(0));
  });
};
