import type {LanguageFile} from "../../helpers/LanguageUtils";
import {
  SurveyLayer,
  SURVEY_LAYER_CLASSIFICATION_ICONS,
  SURVEY_LAYER_CLASSIFICATION_KEYS,
  SURVEY_LAYER_CLASSIFICATIONS
} from "../../constants/SurveyLayers";
import {Box} from "@material-ui/core";
import React, {memo} from "react";
import {useLangFile} from "../../context/LanguageContext";
import PropTypes from "prop-types";

const getTooltip = (classification, LangFile: LanguageFile) => {
  switch (classification) {
    case SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_LARGE: return LangFile.DemandScale.tooltips.demandHigh;
    case SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_MEDIUM: return LangFile.DemandScale.tooltips.demandMedium;
    case SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_SMALL: return LangFile.DemandScale.tooltips.demandSmall;
    case SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK: return LangFile.DemandScale.tooltips.demandOk;
    case SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SMALL: return LangFile.DemandScale.tooltips.surplusSmall;
    case SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_LARGE: return LangFile.DemandScale.tooltips.surplusLarge;
    case SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SUSPICIOUS: return LangFile.DemandScale.tooltips.surplusSuspicious;
    default: return null;
  }
};

export const renderDemandIcon = (classification, LangFile: LanguageFile, layer: SurveyLayer) => {
  let icon = SURVEY_LAYER_CLASSIFICATION_ICONS[classification];
  let tooltip = getTooltip(classification, LangFile);

  let range = SURVEY_LAYER_CLASSIFICATIONS[layer][classification];

  if (!range) {
    return null;
  }

  let rangeText;
  let min = Number(range[0]);
  let max = Number(range[1]);

  if (max === Number.MAX_VALUE) {
    rangeText = `${min}+`;
  }
  else {
    rangeText = `${min} - ${max}`;
  }

  return (
    <div>
      <Box mt={1} display={"flex"} flexDirection={"column"} alignItems={"center"}>
        {React.cloneElement(icon, {
          style: {
            fontSize: "16px"
          }
        })}
      </Box>
      <Box mt={1} mb={1} display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box style={{fontSize: "0.75rem"}}>{tooltip}</Box>
        <Box style={{fontSize: "0.75rem"}}>{rangeText}</Box>
      </Box>
    </div>
  );
};

export const renderDemandIconWithoutRange = (classification, LangFile: LanguageFile) => {
  let icon = SURVEY_LAYER_CLASSIFICATION_ICONS[classification];
  let tooltip = getTooltip(classification, LangFile);
  return (
    <div>
      <Box mt={1} display={"flex"} flexDirection={"column"} alignItems={"center"}>
        {React.cloneElement(icon, {
          style: {
            fontSize: "16px"
          }
        })}
      </Box>
      <Box mt={1} mb={1} display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box style={{fontSize: "0.75rem"}}>{tooltip}</Box>
      </Box>
    </div>
  );
};

const ReportLegendDemandScale = (props: DemandScale.propTypes) => {
  const LangFile = useLangFile();
  if (props.classificationValues) {
    return (
      <Box display={"inline-flex"} width={"100%"} justifyContent={"space-between"} boxSizing={"border-box"} px={props.showAll ? 0 : 2}>
        {Object.keys(props.classificationValues).map((key) => {
          return renderDemandIconWithoutRange(key, LangFile);
        })}
      </Box>
    );
  }

  return (
    <Box display={"inline-flex"} width={"100%"} justifyContent={"space-between"} boxSizing={"border-box"} px={props.showAll ? 0 : 2}>
      {renderDemandIcon(SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_LARGE, LangFile, props.layer)}
      {props.showAll && renderDemandIcon(SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_MEDIUM, LangFile, props.layer)}
      {props.showAll && renderDemandIcon(SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_SMALL, LangFile, props.layer)}
      {renderDemandIcon(SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK, LangFile, props.layer)}
      {props.showAll && renderDemandIcon(SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SMALL, LangFile, props.layer)}
      {props.showAll && renderDemandIcon(SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_LARGE, LangFile, props.layer)}
      {renderDemandIcon(SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SUSPICIOUS, LangFile, props.layer)}
    </Box>
  );
};

ReportLegendDemandScale.propTypes = {
  showAll: PropTypes.bool,
  layer: PropTypes.string,
  classificationValues: PropTypes.object
};

export default memo(
  ReportLegendDemandScale
);