import React, {
  memo, useEffect,
  useState
} from 'react';
import PropTypes from "prop-types";
import {useGoogleMap} from "../../context/GoogleMapContext";
import useScreenPosition from "./hooks/useScreenPosition";

const FeatureDOM = ({zIndex, children, lat, lng, visible, style}) => {

  const map = useGoogleMap();
  const [anchor, setAnchor] = useState(new google.maps.LatLng(lat, lng));
  const position = useScreenPosition(map, anchor);

  useEffect(() => {
    if (lat && lng) {
      setAnchor(new google.maps.LatLng(lat, lng));
    }
  }, [lat, lng]);

  if (!map) {
    return null;
  }

  if (map && !map.getBounds().contains(anchor)) {
    return null;
  }

  if (!position) {
    return null;
  }

  let {x, y} = position;

  return (
    <div
      style={{
        ...style,
        top: y,
        left: x,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        zIndex: zIndex,
        display: visible ? "inherit" : "hidden"
      }}>
      {children}
    </div>
  );
};

FeatureDOM.propTypes = {
  visible: PropTypes.bool,
};

FeatureDOM.defaultProps = {
  visible: true,
};

/** @component */
export default memo(FeatureDOM);