import React, {
  Fragment,
  memo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';
import {Today} from "@material-ui/icons";
import {voidFunc} from "js/constants/PropTypeUtils";
import Box from "@material-ui/core/Box";
import {DatePicker} from "@material-ui/pickers";
import Styles from './CreateUpdateSeasonDateInput.module.less';
import Typography from "@material-ui/core/Typography";

const CreateUpdateSeasonDateInput = (props: CreateUpdateSeasonDateInput.propTypes) => {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [value, setValue] = useState(props.date);

  useEffect(() => {
    setValue(props.date);
  }, [props.date]);

  const setDate = useCallback((value) => {
    setValue(value);
    props.onChanged(value);
  }, []);

  const handleSetCalendarOpen = (val) => () => {
    setCalendarOpen(val);
  };

  return (
    <Fragment>
      <Box className={Styles.root}>
        <Typography variant={"caption"} className={Styles.title}>
          {props.title}
        </Typography>
        <Box className={Styles.border}>
          <DatePicker
            disableToolbar
            classes={{root: Styles.datePicker}}
            InputLabelProps={{shrink: true}}
            open={calendarOpen}
            onOpen={handleSetCalendarOpen(true)}
            onClose={handleSetCalendarOpen(false)}
            InputProps={{
              onClick: handleSetCalendarOpen(true),
              classes: {
                input: Styles.input,
                root: Styles.inputRoot
              },
              endAdornment: <Today />
            }}
            readOnly={true}
            inputProps={{readOnly: true, "data-lpignore": true, autoComplete: "off"}}
            autoOk={true}
            disableFuture={false}
            variant="inline"
            inputVariant="outlined"
            initialFocusedDate={value}
            value={value}
            onChange={(date) => setDate(date)}
            minDate={props.minDate}
            maxDate={props.maxDate}
            format={props.format}/>
        </Box>
      </Box>
    </Fragment>
  );
};

CreateUpdateSeasonDateInput.propTypes = {
  title: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChanged: PropTypes.func,
  format: PropTypes.string
};

CreateUpdateSeasonDateInput.defaultProps = {
  title: "",
  onChanged: voidFunc,
  variant: "outlined",
  format: 'YYYY-MM-DD'
};

export default memo(CreateUpdateSeasonDateInput);