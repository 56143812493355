import React, {memo} from 'react';
import PropTypes from 'prop-types';

import Style from "./InfoSettingsButton.module.less";

import Fab from "@material-ui/core/Fab";
import {Settings} from "@material-ui/icons";

const InfoSettingsButton = (props: InfoSettingsButton.propTypes) => {
  return (
    <div className={Style.Root}>
      <Fab size="small" onClick={props.onClick}>
        <Settings/>
      </Fab>
    </div>
  );
};

InfoSettingsButton.propTypes = {
  onClick: PropTypes.func
};

export default memo(InfoSettingsButton);