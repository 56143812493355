import {useTheme} from '@material-ui/core/styles';
import {useLangFile} from '../../context/LanguageContext';
import React, {Fragment, memo} from 'react';
import {Box, Button, Divider, ListItemIcon, ListItemText, Typography} from '@material-ui/core';
import Styles from '../FieldOverviewList/FieldOverview.module.less';
import {Close} from '@material-ui/icons';
import PropTypes from 'prop-types';
import {voidFunc} from '../../constants/PropTypeUtils';
import CloudIcon from '@material-ui/icons/Cloud';
import List from '@material-ui/core/List';
import LayerDrawerHeaderItem from '../LayerDrawer/LayerDrawerHeaderItem';
import FeatureConstants from '../../constants/FeatureConstants';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlined';
import HelpItemContainer from '../Help/HelpItemContainer';
import {WEATHER_NETWORK_HELP_ARTICLE} from '../../reducers/HelpReducer';
import Grid from '@material-ui/core/Grid';
import {WeatherSensor} from '../../constants/WeatherConstants';

const ENABLED_WEATHER_SENSORS = [
  WeatherSensor.AIR_TEMP,
  WeatherSensor.SOIL_TEMP,
  WeatherSensor.WIND,
  WeatherSensor.WIND_MAX,
  WeatherSensor.RAIN,
  WeatherSensor.HUMIDITY,
  WeatherSensor.PRESSURE,
  WeatherSensor.UV,
];

const WeatherOverview = (props: WeatherOverview.propTypes) => {
  const {showWeatherStations, showWeatherNetwork, displayData, onCloseClicked, onToggleWeatherStations, onToggleWeatherNetwork, onToggleWeatherStationSensor} = props;

  const theme = useTheme();
  const LangFile = useLangFile();

  return (
    <Fragment>
      <Box className={Styles.box}>
        <Box mx={1.5} mt={1} mb={0.5} display={'flex'}>
          <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
            <Button
              className={Styles.button}
              onClick={onCloseClicked}>
              <Close/>
            </Button>
            <Box mx={1.5}>
              <Typography>{LangFile.WeatherLayerDrawerContainer.title}</Typography>
            </Box>
            <CloudIcon style={{color: theme.palette.primary.main}}/>
          </Box>
        </Box>
        <Divider/>
        <Grid
          container
          direction={"column"}
          wrap={"nowrap"}
          spacing={0}>
          <List disablePadding>
            <LayerDrawerHeaderItem
              featureRule={"all"}
              onToggle={onToggleWeatherStations}
              primaryText={LangFile.WeatherLayerList.weather}
              tooltip={LangFile.WeatherLayerList.weatherTooltip}
              requiresFeatures={[FeatureConstants.LEGACY_WEATHER]}
              toggled={Boolean(showWeatherStations)}/>
            <Collapse
              in={showWeatherStations}
              style={{overflow: 'hidden'}}>
              <List disablePadding>
                {ENABLED_WEATHER_SENSORS.map((sensor) => (
                  <ListItem
                    dense
                    button
                    key={sensor}
                    onClick={onToggleWeatherStationSensor(sensor)}>
                    <ListItemIcon style={{minWidth: 36}}>
                      <Checkbox
                        style={{padding: 0}}
                        checked={Boolean(displayData[sensor])}
                        icon={<CheckBoxOutlineBlank fontSize="small"/>}
                        checkedIcon={<CheckBoxOutlined fontSize="small"/>}
                        color="primary"/>
                    </ListItemIcon>
                    <ListItemText primary={LangFile.WeatherLayerList.weatherAttrs[sensor]}/>
                  </ListItem>
                ))}
                <Divider/>
              </List>
            </Collapse>
            <LayerDrawerHeaderItem
              tooltip={LangFile.WeatherNetworkMenu.networkTooltip}
              featureRule={"either"}
              onToggle={onToggleWeatherNetwork}
              primaryText={LangFile.WeatherNetworkMenu.network}
              requiresFeatures={[FeatureConstants.LEGACY_WEATHER]}
              toggled={showWeatherNetwork}/>
            {showWeatherNetwork && (
              <List dense disablePadding>
                <HelpItemContainer
                  articleId={WEATHER_NETWORK_HELP_ARTICLE}/>
              </List>
            )}
          </List>
        </Grid>
      </Box>
    </Fragment>
  );
};

WeatherOverview.propTypes = {
  showWeatherStations: PropTypes.bool,
  showWeatherNetwork: PropTypes.bool,
  displayData: PropTypes.object,

  onCloseClicked: PropTypes.func,
  onToggleWeatherStations: PropTypes.func,
  onToggleWeatherNetwork: PropTypes.func,
  onToggleWeatherStationSensor: PropTypes.func
};

WeatherOverview.defaultProps = {
  onCloseClicked: voidFunc,
  onToggleWeatherStations: voidFunc,
  onToggleWeatherNetwork: voidFunc,
  onToggleWeatherStationSensor: voidFunc
};

export default memo(WeatherOverview);