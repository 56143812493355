import React, {
  memo,
  useEffect,
  useState
} from 'react';

import {
  getLineSegments,
} from "js/components/visualization/Graph/ColoredLineUtils";
import {useGraph} from "js/context/GraphContext";
import {useMeasureSettings} from "js/context/AppSettings/AppSettingsContext";
import {getPointSegments} from "./GraphLineUtils";
import ManualFeatureConstants from "../../../constants/ManualFeatureConstants";
import {getDatasetMaxGapMinutes} from "../../../helpers/WeatherUtils";

const GraphColoredLine = ({limitlines, datasetName, getColor, displayDots}) => {

  const {xMap, yLeftMap, yRightMap, datasets} = useGraph();
  const [paths, setPaths] = useState([]);
  const measureSettings = useMeasureSettings();

  const dataset = datasets[datasetName];
  const points = dataset.points;
  const yMap = dataset.axis === "left" ? yLeftMap : yRightMap;

  useEffect(() => {
    if (points && limitlines) {
      let result = [];

      let maxDiff = getDatasetMaxGapMinutes(dataset);

        getLineSegments(points, limitlines)
        .forEach((path) => result.push(
          ...getPointSegments(path, dataset.resolution, maxDiff)
            .filter((segment) => {
              if (ManualFeatureConstants.ENABLE_WEATHER_MAKEUP) {
                return segment.valid;
              }
              return true;
            })
        ));

      setPaths(result);
    }
  }, [points, limitlines, dataset]);

  return (
    <g>
      {paths.map((path, idx) => {
        let pathPoints = path.points.map((p) => `${xMap(p.x)},${yMap(p.y)}`).join(" ");
        let midY = path.points.reduce((acc, p) => acc + p.y, 0) / path.points.length;
        let firstLast = [path.points[0], path.points[path.points.length - 1]];

        return (
          <g key={idx}>
            <polyline points={pathPoints} strokeWidth={2} stroke={getColor(midY, measureSettings)} fill={'none'}/>

            {ManualFeatureConstants.ENABLE_WEATHER_MAKEUP === false && firstLast.map((point, idx) => (
              <circle
                cx={xMap(point.x)}
                cy={yMap(point.y)}
                r={point.selected ? 5 : 3}
                key={`point-${idx}-${point.x + "-" + point.y}`}
                fill={getColor(point.y, dataset)}>
              </circle>
            ))}
          </g>
        );
      })}

      {displayDots && paths.filter(Boolean).map((path) => {
        return path.points.map((p) => (
          <circle
            cx={xMap(p.x)}
            cy={yMap(p.y)}
            r={3}
            style={{zIndex: 2}}
            key={"colored-dot" + p.x + "-" + p.y}
            fill={getColor(p.y, dataset)}>
          </circle>
        ));
      })}

    </g>
  );
};

GraphColoredLine.propTypes = {};

GraphColoredLine.defaultProps = {
  displayDots: false,
};

export default memo(GraphColoredLine);