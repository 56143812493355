import * as SoilSampleColorMapper from "js/components/SoilSamples/SoilSampleColorMapper";
import type {LanguageFile} from "../../helpers/LanguageUtils";

export const PROPERTIES = {
  ID: "ID",
  DATE: "DATE",
  FIELD: "FIELD",
  PH: "PH",
  SOILTYPE: "SOILTYPE",
  PHOSPHORUS: "PHOSPHORUS",
  POTASSIUM: "POTASSIUM",
  MAGNESIUM: "MAGNESIUM",
  COPPER: "COPPER",
  BORON: "BORON",
  SODIUM: "SODIUM",
  ZINC: "ZINC",
  CLAY: "CLAY",
  MOLYBDENUM: "MOLYBDENUM",
  MANGANESE: "MANGANESE",
  HUMUS: "HUMUS",
};

export const SOURCE = {
  LMO: "LMO",
  GPS_AGRO: "GPS_AGRO",
};

export const GPS_AGRO_PROPERTIES = {
  [PROPERTIES.ID]: "NR",
  [PROPERTIES.DATE]: "DATO",
  [PROPERTIES.FIELD]: "MARKNR",
  [PROPERTIES.PH]: "RT",
  [PROPERTIES.SOILTYPE]: "JB",
  [PROPERTIES.PHOSPHORUS]: "PT",
  [PROPERTIES.POTASSIUM]: "KT",
  [PROPERTIES.MAGNESIUM]: "MGT",
  [PROPERTIES.COPPER]: null,
  [PROPERTIES.BORON]: null,
  [PROPERTIES.SODIUM]: null,
  [PROPERTIES.ZINC]: null,
  [PROPERTIES.CLAY]: null,
  [PROPERTIES.MOLYBDENUM]: null,
  [PROPERTIES.MANGANESE]: null,
  [PROPERTIES.HUMUS]: "HUMUS",
};

export const LMO_PROPERTIES = {
  [PROPERTIES.ID]: "ProveId",
  [PROPERTIES.DATE]: "ProveDato",
  [PROPERTIES.FIELD]: "Marknr",
  [PROPERTIES.PH]: "Rt",
  [PROPERTIES.SOILTYPE]: "Jordtype",
  [PROPERTIES.PHOSPHORUS]: "Pt",
  [PROPERTIES.POTASSIUM]: "Kt",
  [PROPERTIES.MAGNESIUM]: "Mgt",
  [PROPERTIES.COPPER]: null,
  [PROPERTIES.BORON]: null,
  [PROPERTIES.SODIUM]: null,
  [PROPERTIES.ZINC]: null,
  [PROPERTIES.CLAY]: "Ler",
  [PROPERTIES.MOLYBDENUM]: null,
  [PROPERTIES.MANGANESE]: null,
  [PROPERTIES.HUMUS]: null,
};

export const MENU_OPTIONS = [
  {
    property: PROPERTIES.SOILTYPE,
    getText: (LangFile: LanguageFile) => LangFile.SoilSampleConstants.soiltype,
    decimals: 0,
    min: 1,
    max: 11,
    getColor: (value) => SoilSampleColorMapper.getSoilTypeColor(value, 1, 11),
  },
  {
    property: PROPERTIES.PH,
    getText: (LangFile: LanguageFile) => LangFile.SoilSampleConstants.ph,
    decimals: 1,
    min: 0,
    max: 14,
    getColor: SoilSampleColorMapper.getPhColor,
  },
  {
    property: PROPERTIES.POTASSIUM,
    getText: (LangFile: LanguageFile) => LangFile.SoilSampleConstants.potassium,
    decimals: 1,
    min: 0,
    max: 17,
    getColor: (value) => SoilSampleColorMapper.getPotassiumColor(value, 0, 17),
  },
  {
    property: PROPERTIES.MAGNESIUM,
    getText: (LangFile: LanguageFile) => LangFile.SoilSampleConstants.magnesium,
    decimals: 1,
    min: 0,
    max: 14,
    getColor: (value) => SoilSampleColorMapper.getMagnesiumColor(value, 0, 14),
  },
  {
    property: PROPERTIES.PHOSPHORUS,
    getText: (LangFile: LanguageFile) => LangFile.SoilSampleConstants.phosphorus,
    decimals: 1,
    min: 0,
    max: 10,
    getColor: (value) => SoilSampleColorMapper.getPhosphorusColor(value, 0, 10),
  },
];

export function getMenuOption(property) {
  return MENU_OPTIONS.filter((option) => option.property === property)[0];
}

export const UPLOAD_ERROR = {
  SERVER_ERROR: "SERVER_ERROR",
  FILE_TOO_BIG: "FILE_TOO_BIG",
  INVALID_FILE_FORMAT: "INVALID_FILE_FORMAT",
  UNEXPECTED: "UNEXPECTED",
};