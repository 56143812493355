import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useLangFile} from "../../context/LanguageContext";
import type {LAYER_CONFIG} from "../../model/surveys/SurveyLayerConfig";
import ListItem from "@material-ui/core/ListItem";
import {Box, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedOutlined from "@material-ui/icons/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlined from "@material-ui/icons/RadioButtonCheckedOutlined";
import {getLayerConfig} from "../Prescription/PrescriptionUtils";
import {CheckBoxOutlineBlankOutlined, CheckBoxOutlined} from "@material-ui/icons";
import {voidFunc} from "../../constants/PropTypeUtils";
import useShiftKeyDown from "../../hooks/useShiftKeyDown";
import {DefaultColoringStrategy} from "../../model/surveys/SurveyColoringStrategies";
import {grey} from "@material-ui/core/colors";

const SurveyLayerListItem = (props: SurveyLayerListItem.propTypes) => {

  const LangFile = useLangFile();
  const config: LAYER_CONFIG = getLayerConfig(props.layer);
  const shiftDown = useShiftKeyDown();
  const colorStrategy = new DefaultColoringStrategy(props.layer);

  let handleOnClick = useCallback(() => {
    if (shiftDown && props.enableMultipleSelect) {
      props.onCheckboxClick(props.layer);
    }
    else {
      props.onClick(props.layer);
    }
  }, [props.layer, props.onClick, shiftDown, props.enableMultipleSelect, props.onCheckboxClick]);

  let handleOnCheckboxClicked = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (props.enableMultipleSelect) {
      props.onCheckboxClick(props.layer);
    }
    else {
      props.onClick(props.layer);
    }
  }, [props.layer, props.enableMultipleSelect, props.onClick, props.onCheckboxClick]);

  if (!config) {
    return null;
  }

  let icon = props.enableMultipleSelect ? <CheckBoxOutlineBlankOutlined fontSize="small"/> : <RadioButtonUncheckedOutlined fontSize="small"/>;
  let checkedIcon = props.enableMultipleSelect ? <CheckBoxOutlined fontSize="small"/> : <RadioButtonCheckedOutlined fontSize="small"/>;

  return (
    <Box key={props.layer} my={props.elevated ? 1 : 0}>
      <Paper elevation={props.elevated ? 3 : 0}>
        <Box borderLeft={"4px solid " + colorStrategy.getColor(config.maxValue)}>
          <ListItem
            dense={true}
            button={true}
            disabled={props.disabled}
            onClick={handleOnClick}>
            {!props.disableCheckbox && (
              <ListItemIcon style={{minWidth: 36}}>
                <Checkbox
                  style={{padding: 0}}
                  checked={props.isSelected}
                  onClick={handleOnCheckboxClicked}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  color="primary"/>
              </ListItemIcon>
            )}
            <ListItemText
              primary={config.getName(LangFile)}/>
            <ListItemSecondaryAction style={{pointerEvents: "none"}}>
              <Box color={props.disabled ? grey["400"] : "black"} fontSize={"0.8rem"}>
                {config.getShorthand(LangFile)}
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
        </Box>
      </Paper>
    </Box>
  );
};

SurveyLayerListItem.propTypes = {
  layer: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onCheckboxClick: PropTypes.func,
  disabled: PropTypes.bool,
  disableCheckbox: PropTypes.bool,
  enableMultipleSelect: PropTypes.bool,
  elevated: PropTypes.bool
};

SurveyLayerListItem.defaultProps = {
  onClick: voidFunc,
  onCheckboxClick: voidFunc,
};

export default memo(SurveyLayerListItem);
