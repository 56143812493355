import {useRef} from "react";
import firebase from "firebase/app";

export const FIREBASE_SCREENS = {
  LOGIN: "Login",
  RESTORE: "Restore",
};

export const FIREBASE_EVENTS = {
  // Login Screen
  LOGIN_SUBMIT: "LoginSubmit",
  LOGIN_RESTORE: "LoginRestore",
  LOGIN_RESTORE_SUBMIT: "LoginRestoreSubmit",
  LOGIN_RESTORE_BACK: "LoginRestoreBack",

  // Overview Screen
  OVERVIEW_FD_OPEN: "OverviewFDOpen",
  OVERVIEW_WD_OPEN: "OverviewWDOpen",
  OVERVIEW_ML_OPEN: "OverviewMLOpen",
  OVERVIEW_ML_LAYER: "OverviewMLLayer",
  OVERVIEW_SEASON_SELECT: "OverviewSeasonSelect",

  // Field  Drawer
  FD_HEIGHT_MAP: "FDHeightMap",
  FD_ARCHIVE: "FDArchive",
  FD_EDIT_CROPS: "FDEditCrops",
  FD_ADD_FIELDS: "FDAddFields",

  // Weather Drawer
  WEATHER_DRAWER_MY_STATIONS: "WeatherDrawerMyStations",
  WEATHER_DRAWER_NETWORK: "WeatherDrawerNetwork",

  // Field Popup
  FPO_ANALYSIS: "FPOAnalysis",
  FPO_VRM: "FPOVRM",
  FPO_NOTES: "FPONotes",
  FPO_SETTINGS: "FPOSettings",
  FPO_DOWNLOAD: "FPODownload",

  // Main Menu / Topbar / Settings Drawer / Navigation Menu
  MM_SUPPORT: "MMSupport",
  MM_SEARCH_SHORTCUT: "MMSearchShortcut",
  MM_SEARCH_CLICK: "MMSearchClick",
  MM_LANGUAGE: "MMLanguage",
  MM_OPEN: "MMOpen",
  MM_ACCOUNT: "MMAccount",
  MM_SETTINGS: "MMSettings",
  MM_SELECT_FARM: "MMSelectFarm",
  MM_REFRESH: "MMRefresh",
  MM_ADD_FIELDS: "MMAddFields",
  MM_ACCOUNT_REMOVE_USER: "MMAccountRemoveUser",
  MM_ACCOUNT_ADD_USER: "MMAccountAddUser",
  MM_SETTINGS_TOGGLE_TEMP: "MMSettingsToggleTemp",
  MM_SETTINGS_TOGGLE_WIND: "MMSettingsToggleWind",
  MM_SETTINGS_TOGGLE_RAIN: "MMSettingsToggleRain",
  MM_ADD_FIELDS_SHAPEFILE: "MMAddFieldsShapefile",
  MM_ADD_FIELDS_IMPORT: "MMAddFieldsImport",
  MM_ADD_FIELDS_DRAW: "MMAddFieldsDraw",
  MM_ADD_FIELDS_OUTLINES: "MMAddFieldsOutlines",
  MM_ADD_FIELDS_SHAPEFILE_DIALOG_SUBMIT: "MMAddFieldsShapefileDialogSubmit",
  MM_ADD_FIELDS_SHAPEFILE_DIALOG_CANCEL: "MMAddFieldsShapefileDialogCancel",

  // VRM Archive
  VRM_ARCHIVE_SORT: "VRMArchiveSort",
  VRM_ARCHIVE_RESUME: "VRMArchiveResume",
  VRM_ARCHIVE_DOWNLOAD: "VRMArchiveDownload",
  VRM_ARCHIVE_DOWNLOAD_SHAPEFILE: "VRMArchiveDownloadShapefile",
  VRM_ARCHIVE_DOWNLOAD_ISOXML: "VRMArchiveDownloadISOXML",
  VRM_ARCHIVE_DELETE: "VRMArchiveDelete",

  // Creating new prescription maps (VRM)
  VRM_NEW_CATEGORY_SATELLITE: "VRMNewCategorySatellite",
  VRM_NEW_CATEGORY_NUTRIENTS: "VRMNewCategoryNutrients",
  VRM_NEW_CATEGORY_TEXTURE: "VRMNewCategoryTexture",
  VRM_NEW_CATEGORY_TOPOGRAPHY: "VRMNewCategoryTopography",
  VRM_NEW_CATEGORY_CANCEL: "VRMNewCategoryCancel",
  VRM_NEW_LAYER_SELECTED: "VRMNewLayerSelected",
  VRM_NEW_LAYER_BACK: "VRMNewLayerBack",

  // VRM Editor Screen
  VRM_EDITOR_OVERRIDES_OPENED: "VRMEditorOverridesOpen",
  VRM_EDITOR_OVERRIDES_BACK: "VRMEditorOverridesBack",
  VRM_EDITOR_OVERRIDES_BRUSH_SIZE: "VRMEditorOverridesBrushSize",
  VRM_EDITOR_OVERRIDES_BRUSH_VALUE: "VRMEditorOverridesBrushValue",
  VRM_EDITOR_OVERRIDES_TOOL: "VRMEditorOverridesTool",
  VRM_EDITOR_SPLIT_INIT: "VRMEditorSplitInit",
  VRM_EDITOR_SPLIT_CANCEL: "VRMEditorSplitCancel",
  VRM_EDITOR_SPLIT_SUBMIT: "VRMEditorSplitSubmit",
  VRM_EDITOR_MERGE_INIT: "VRMEditorMergeInit",
  VRM_EDITOR_MERGE_CANCEL: "VRMEditorMergeCancel",
  VRM_EDITOR_MERGE_SUBMIT: "VRMEditorMergeSubmit",
  VRM_EDITOR_AUTO_ADJUST_INIT: "VRMEditorAutoAdjustInit",
  VRM_EDITOR_AUTO_ADJUST_CLOSE: "VRMEditorAutoAdjustClose",
  VRM_EDITOR_AUTO_ADJUST_SUBMIT: "VRMEditorAutoAdjustSubmit",
  VRM_EDITOR_DOWNLOAD: "VRMEditorDownload",
  VRM_EDITOR_DOWNLOAD_CLOSE: "VRMEditorDownloadClose",
  VRM_EDITOR_DOWNLOAD_SHAPEFILE: "VRMEditorDownloadShapefile",
  VRM_EDITOR_DOWNLOAD_ISOXML: "VRMEditorDownloadISOXML",
  VRM_EDITOR_HEIGHT_MAP_LEFT: "VRMEditorHeightMapLeft",
  VRM_EDITOR_HEIGHT_MAP_RIGHT: "VRMEditorHeightMapRight",
  VRM_EDITOR_SHOW_DETAILS: "VRMEditorShowDetails",
  VRM_EDITOR_SHOW_DEMAND: "VRMEditorShowDemand",
  VRM_EDITOR_MULTIPLE_SELECT: "VRMEditorMultipleSelect",
  VRM_EDITOR_ML_LAYER: "VRMEditorMLLayer",

  // Analysis Screen
  ANALYSIS_BACK: "AnalysisBack",
  ANALYSIS_CLOSE: "AnalysisClose",
  ANALYSIS_VRM: "AnalysisVRM",
  ANALYSIS_PRINT_OPEN: "AnalysisPrintOpen",
  ANALYSIS_PRINT_CLOSE: "AnalysisPrintClose",
  ANALYSIS_WD_OPEN: "AnalysisWDOpen",
  ANALYSIS_FD_OPEN: "AnalysisFDOpen",
  ANALYSIS_ML_OPEN: "AnalysisMLOpen",
  ANALYSIS_ML_LAYER: "AnalysisMLLayer",

  // Notes
  NOTES_POLYGON: "NotesPolygon",
  NOTES_MARKER: "NotesMarker",
  NOTES_WAND: "NotesWand",
  NOTES_VISIBILITY: "NotesVisibility",

  // Bottom Drawer
  BOTTOM_DRAWER_MINIMIZE: "BottomDrawerMinimize",
  BOTTOM_DRAWER_MAXIMIZE: "BottomDrawerMaximize",

  // Weather Station List
  WEATHER_LIST_CARD_CLICK: "WLCard",
  WEATHER_LIST_ACCUMULATION: "WLAccumulation",

  // Weather History
  WEATHER_HISTORY_SENSOR: "WHSensor",
  WEATHER_HISTORY_DOWNLOAD: "WHDownload",
  WEATHER_HISTORY_DATE_RANGE: "WHDateRange",
  WEATHER_HISTORY_PRESET: "WHPreset", // WHPreset24H, WHPreset7D, WHPreset4W

  // Weather History Settings
  WEATHER_HISTORY_SETTINGS_GUSTS: "WHSGust",
  WEATHER_HISTORY_SETTINGS_SPRAYING_CONDITIONS: "WHSSprayingConditions",

  // Weather Station Settings
  WEATHER_STATION_SETTINGS: "WeatherStationSettings",
  WEATHER_STATION_SETTINGS_NAME: "WSSName",
  WEATHER_STATION_SETTINGS_CONNECT_FIELDS: "WSSConnectFields",
  WEATHER_STATION_SETTINGS_COPY_COORDS: "WSSCopyCoords",
  WEATHER_STATION_SETTINGS_FIX_GPS: "WSSFixGPS",
  WEATHER_STATION_SETTINGS_REPLACE: "WSSReplace",
  WEATHER_STATION_SETTINGS_SUSPEND: "WSSSuspend",

  // Weather Station (on the map)
  WEATHER_STATION_LAYER: "WSL",
  WEATHER_STATION_MARKER_CLICK: "WSMarkerClick",

  WEATHER_NETWORK_LAYER_SELECTOR: "WNLLayerSelector",
  WEATHER_NETWORK_LAYER: "WNL", // WNLAirTemp, WNLSoilTemp, WNLRain, WNLAccRain, WNLAvgWind

  // FIELD STATISTICS
  FIELD_STATISTICS_SHORTCUT_TO_SOILOPTIX: "FIELD_STATISTICS_SHORTCUT_TO_SOILOPTIX"
};

const useFirebaseAnalytics = (): firebase.analytics.Analytics => {
  const analytics = typeof firebase.analytics === "function" ? firebase.analytics : () => {};
  const ref = useRef(analytics());
  return ref.current;
};


export default useFirebaseAnalytics;
