// @flow

import React, {memo, useCallback, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {useLangFile} from "js/context/LanguageContext";
import Styles from './FieldIntegrationMappingSelector.module.less';
import Typography from "@material-ui/core/Typography";
import {ArrowDropDown, ArrowDropUp} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import {
  ClickAwayListener,
  List,
  Paper,
  Popper,
  Button
} from "@material-ui/core";
import FieldIntegrationMappingSelectorListItem from './FieldIntegrationMappingSelectorListItem';
import {voidFunc} from '../../constants/PropTypeUtils';
import {getFieldName} from "js/helpers/StateInterpreters";

const FieldIntegrationMappingSelector = (props: FieldIntegrationMappingSelector.propTypes) => {
  const LangFile = useLangFile();
  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = useRef();
  const open = Boolean(anchorEl);
  const {fieldItems, selectedField, onSelectField} = props;

  const handleClick = useCallback(() => {
    setAnchorEl(divRef.current);
  }, [anchorEl]);

  const handleListItemClick = useCallback((field) => {
    onSelectField(field);
    handleClickAway();
  }, [onSelectField]);

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box ref={divRef} display="flex" justifyContent={'space-between'} alignItems={'center'} width={'100%'} height={'100%'} onClick={handleClick} className={Styles.root}>
        {!selectedField && (
          <Box pl={1}><Typography style={{color: 'red'}}>{LangFile.FieldIntegrationMapping.select}</Typography></Box>
        )}
        {selectedField && (
          <Box display={'flex'} flexDirection={'column'} p={1}>
            <Typography className={Styles.text} style={{fontSize: '10px'}}>{selectedField.mef}</Typography>
            <Typography className={Styles.text} style={{fontWeight: 500}}>{getFieldName(selectedField, LangFile)}</Typography>
            <Typography className={Styles.text}>{LangFile.CropType[selectedField.crop]}</Typography>
            <Typography className={Styles.text}>{selectedField.size.toFixed(2) + " ha"}</Typography>
          </Box>
        )}
        {open ? <ArrowDropUp/> : <ArrowDropDown/>}
      </Box>
      {open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            style={{ zIndex: 1300, minWidth: 220 }}
            open={true}
            anchorEl={anchorEl}
            placement='bottom-start'>
            <Paper>
              <Box className={Styles.PopoverContainer}>
                <Box width={'100%'} height={'30px'} display={'flex'} flexDirection={'row'}>
                  <Box display={'flex'} alignItems={'center'} width={'25%'} height={'100%'} className={Styles.header}>{LangFile.FieldIntegrationMapping.match}</Box>
                  <Box display={'flex'} alignItems={'center'} height={'100%'} style={{marginLeft: '6px'}} className={Styles.header}>{LangFile.FieldSettingsDialog.fieldName}</Box>
                </Box>
                <List
                  className={Styles.list}
                  dense={true}>
                  {fieldItems.map((fieldItem) => {
                    return (<FieldIntegrationMappingSelectorListItem key={fieldItem.field.fieldId} fieldItem={fieldItem} isSelected={selectedField ? fieldItem.field.fieldId === selectedField.fieldId : false} onClick={handleListItemClick}/>);
                  })}
                </List>
                <Box height={'40px'} display={'flex'} justifyContent={'center'}>
                  <Button
                    disabled={!selectedField}
                    onClick={() => {
                      handleListItemClick(null);
                    }}>{LangFile.FieldIntegrationMapping.clearSelection}</Button>
                </Box>
              </Box>
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

FieldIntegrationMappingSelector.propTypes = {
  fieldItems: PropTypes.array,
  selectedField: PropTypes.object,
  onSelectField: PropTypes.func
};

FieldIntegrationMappingSelector.defaultProps = {
  fieldItems: [],
  onSelectField: voidFunc
};

export default memo(FieldIntegrationMappingSelector);
