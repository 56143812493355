import React, {
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';

import PropTypes from 'prop-types';

import {
  useLangFile,
  useLanguage
} from "js/context/LanguageContext";

import {
  Container,
  Dialog,
  Stepper,
  Button,
  Step,
  StepLabel,
  StepContent,
  FormControlLabel,
  Checkbox,
  Box
} from "@material-ui/core";

import moment from "moment/moment";

import {REPLACE_TYPE} from "js/reducers/ReplaceStationReducer";

import ReplaceStationAppBar from "js/components/ReplaceStation/Replace/ReplaceStationAppBar";
import StepQuestionBox from "js/components/ReplaceStation/Parts/StepQuestionBox";
import StepActions from "js/components/ReplaceStation/Parts/StepActions";
import WeatherStationSerialInput from "js/components/ReplaceStation/Parts/WeatherStationSerialInput";
import DateTimePicker from "js/components/ReplaceStation/Parts/DateTimePicker";
import SelectStationGrid from "js/components/ReplaceStation/Parts/SelectStationGrid";
import SummaryTable from "js/components/ReplaceStation/Parts/SummaryTable";
import LoaderBox from "js/components/ReplaceStation/Parts/LoaderBox";
import {ERRORS} from "../../../constants/ReplaceStationConstants";

const ReplaceStationStepsDialog = ({replaceType, station, stations, onCancel, onReplace, onFinish, loading, error, success, onResetError}) => {

  const LangFile = useLangFile();
  const language = useLanguage();

  const [activeStep, setActiveStep] = useState(0);
  const [retiredSince, setRetiredSince] = useState(moment());
  const [claimedSince, setClaimedSince] = useState(moment());
  const [serialInput, setSerialInput] = useState("");
  const [newStation, setNewStation] = useState(null);
  const [confirmed, setConfirmed] = useState(false);

  const newStationSerial = replaceType === REPLACE_TYPE.NEW_WEATHER_STATION ? serialInput : (newStation && newStation.label);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    if (activeStep === 0 && error === ERRORS.CLAIMING_UNKNOWN_DEVICE) {
      onResetError(error);
    }
  }, [error, activeStep]);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 2){
        setConfirmed(false);
      }
      return prevActiveStep - 1;
    });
  }, []);

  const handleReplace = useCallback(() => {
    handleNext();
    onReplace(newStationSerial, retiredSince, claimedSince);
  }, [newStationSerial, retiredSince, claimedSince]);

  const handleConfirmedChanged = useCallback((e) => setConfirmed(e.target.checked), []);

  useEffect(() => {
    if (newStation) {
      let claimed = moment(newStation.claimedSince);

      setRetiredSince(claimed);
      setClaimedSince(claimed);

    }
  }, [newStation]);

  return (
    <Dialog open={Boolean(station && replaceType)} fullScreen>
      <ReplaceStationAppBar onClose={onCancel}/>

      <Container maxWidth={"md"}>
        <Stepper activeStep={activeStep} orientation="vertical">

          {
            /*
              ##########################################################################################################

              STEP ONE

              ##########################################################################################################
             */
          }

          {replaceType === REPLACE_TYPE.NEW_WEATHER_STATION && (
            <Step>
              <StepLabel error={error === ERRORS.CLAIMING_UNKNOWN_DEVICE}>{LangFile.ReplaceStationStepsDialog.stepTwo.label}</StepLabel>
              <StepContent>

                <StepQuestionBox
                  title={LangFile.ReplaceStationStepsDialog.stepTwo.question1}
                  label={LangFile.ReplaceStationStepsDialog.stepTwo.question1label}/>

                <WeatherStationSerialInput onEntry={setSerialInput} initialValue={serialInput}/>

                <StepQuestionBox
                  title={LangFile.ReplaceStationStepsDialog.stepTwo.question2}
                  label={LangFile.ReplaceStationStepsDialog.stepTwo.question2label}/>

                <DateTimePicker
                  date={claimedSince}
                  setDate={setClaimedSince}
                  maxDate={moment()}
                  minDate={station && moment(station.claimedSince)}
                  minDateMessage={LangFile.ReplaceStationStepsDialog.stepTwo.maxDateMessage}/>

                <StepActions>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    disabled={!newStationSerial || !claimedSince}
                    onClick={handleNext}>
                    {LangFile.ReplaceStationStepsDialog.stepTwo.register}
                  </Button>
                </StepActions>
              </StepContent>
            </Step>
          )}

          {replaceType === REPLACE_TYPE.PICK_WEATHER_STATION && (
            <Step>
              <StepLabel>{LangFile.ReplaceStationStepsDialog.stepThree.label}</StepLabel>
              <StepContent>

                <StepQuestionBox
                  title={LangFile.ReplaceStationStepsDialog.stepThree.question1}/>

                <SelectStationGrid
                  stations={stations
                    .filter((s) => s.label !== station.label)
                    .filter((s) => moment(s.claimedSince).isAfter(moment(station.claimedSince)))
                  }
                  selected={newStation}
                  setSelected={setNewStation}/>

                <StepActions>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    disabled={!newStation}
                    onClick={handleNext}>
                    {LangFile.ReplaceStationStepsDialog.stepThree.next}
                  </Button>
                </StepActions>
              </StepContent>
            </Step>
          )}


          {
            /*
              ##########################################################################################################

              STEP TWO

              ##########################################################################################################
             */
          }
          <Step>
            <StepLabel>{LangFile.ReplaceStationStepsDialog.stepOne.label}</StepLabel>
            <StepContent>

              <StepQuestionBox
                title={LangFile.ReplaceStationStepsDialog.stepOne.question1start + station.label + LangFile.ReplaceStationStepsDialog.stepOne.question1end}
                label={LangFile.ReplaceStationStepsDialog.stepOne.question1label}/>

              <DateTimePicker
                date={retiredSince}
                setDate={setRetiredSince}
                minDate={station && moment(station.claimedSince)}
                maxDate={newStation ? moment(newStation.claimedSince) : claimedSince}
                maxDateMessage={LangFile.ReplaceStationStepsDialog.stepOne.maxDateMessage}/>

              <StepActions>
                <Button onClick={handleBack}>
                  {LangFile.ReplaceStationStepsDialog.stepThree.back}
                </Button>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  disabled={!retiredSince}
                  onClick={handleNext}>
                  {LangFile.ReplaceStationStepsDialog.stepOne.next}
                </Button>
              </StepActions>
            </StepContent>
          </Step>

          {
            /*
              ##########################################################################################################

              STEP THREE

              ##########################################################################################################
             */
          }
          <Step>
            <StepLabel>{LangFile.ReplaceStationStepsDialog.stepFour.label}</StepLabel>
            <StepContent>

              <SummaryTable rows={[
                {
                  left: LangFile.ReplaceStationStepsDialog.stepFour.oldStation,
                  right: station.label
                },
                {
                  left: LangFile.ReplaceStationStepsDialog.stepFour.newStation,
                  right: newStationSerial
                },
                {
                  left: LangFile.ReplaceStationStepsDialog.stepFour.dismount,
                  right: moment(retiredSince).locale(language).calendar()
                },
                {
                  left: LangFile.ReplaceStationStepsDialog.stepFour.mount,
                  right: moment(claimedSince).locale(language).calendar()
                }
              ]}/>

              <Box my={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmed}
                      onChange={handleConfirmedChanged}
                      color={"primary"}/>
                  }
                  label={LangFile.ReplaceStationStepsDialog.stepFour.confirmLabel}/>
              </Box>

              <StepActions>
                <Button onClick={handleBack}>
                  {LangFile.ReplaceStationStepsDialog.stepFour.back}
                </Button>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={handleReplace}
                  disabled={!confirmed}>
                  {LangFile.ReplaceStationStepsDialog.stepFour.replace}
                </Button>
              </StepActions>
            </StepContent>
          </Step>

          {
            /*
              ##########################################################################################################

              STEP FOUR

              ##########################################################################################################
             */
          }
          <Step>
            <StepLabel error={Boolean(error)}>{error ? LangFile.ReplaceStationStepsDialog.labelError : LangFile.ReplaceStationStepsDialog.label}</StepLabel>
            <StepContent>

              <LoaderBox
                loading={loading}
                success={success}
                error={error}
                errorMessage={LangFile.ReplaceStationStepsDialog.error}
                successMessage={LangFile.ReplaceStationStepsDialog.label}/>

              <StepActions>
                <Button onClick={handleBack} disabled={loading || success}>
                  {LangFile.ReplaceStationStepsDialog.stepFour.back}
                </Button>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={onFinish}
                  disabled={!confirmed || loading}>
                  {LangFile.ReplaceStationStepsDialog.finish}
                </Button>
              </StepActions>

            </StepContent>
          </Step>

        </Stepper>
      </Container>
    </Dialog>
  );
};

ReplaceStationStepsDialog.propTypes = {
  replaceType: PropTypes.string,
  station: PropTypes.object,
  stations: PropTypes.array,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  onReplace: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  success: PropTypes.bool,
};

export default memo(ReplaceStationStepsDialog);