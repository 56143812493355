"use strict";
import WebAPIUtils from 'js/WebAPIUtils.js';
import {
  WeatherPeriod,
  WeatherSensor, WeatherSensorColor
} from "js/constants/WeatherConstants";
import moment from "moment-timezone";
import {RAIN_ACCUMULATION} from "../constants/WeatherConstants";
import {
  mapCurrentMeasurements,
  mapMeasurements,
  mapRainMeasurement
} from "../helpers/MeasureUtils";
import {getWeatherSensorConstants} from "js/context/AppSettings/AppSettingsContext";
import {RAIN} from "js/constants/MeasureConstants";

/* ============== //
 ||     TYPES     ||
 // ============== */
const SELECT_WEATHER_STATION = "fieldsense/weather/SELECT_WEATHER_STATION";
const FETCH_WEATHER_STATIONS = "fieldsense/weather/FETCH_WEATHER_STATIONS";
const FETCH_STATION_READINGS = "fieldsense/weather/FETCH_STATION_READINGS";
const TOGGLE_DISPLAY_DATA_PROPERTY = "fieldsense/weather/TOGGLE_DISPLAY_DATA_PROPERTY";
const SET_SHOW_WEATHER_STATIONS = "fieldsense/WeatherReducer/SET_SHOW_WEATHER_STATIONS";
const SET_SHOW_WEATHER_DRAWER = "fieldsense/WeatherReducer/SET_SHOW_WEATHER_DRAWER";
const SET_PERIOD_PRESET = "fieldsense/WeatherReducer/SET_PERIOD_PRESET";
const SET_DATE_RANGE = "fieldsense/WeatherReducer/SET_DATE_RANGE";
const SET_WEATHER_SENSOR = "fieldsense/WeatherReducer/SET_WEATHER_SENSOR";
const SET_ACCUMULATION = "fieldsense/WeatherReducer/SET_ACCUMULATION";
const SET_ENABLE_WIND_GUST = "fieldsense/WeatherReducer/SET_ENABLE_WINDGUSTS";
const SET_ENABLE_SPRAY_CONDITIONS = "fieldsense/WeatherReducer/SET_ENABLE_SPRAY_CONDITIONS";

const CLAIM_DEVICE = "fieldsense/WeatherReducer/CLAIM_DEVICE";

/* ============== //
 ||    HELPERS    ||
 // ============== */

const fetchAllStations = async (farmId, accumulation, measureSettings) => {
  // Fetch the weather stations
  let stations = await WebAPIUtils.fetchWeatherStations(farmId, accumulation);

  stations = stations.devices.map((station) => ({
    ...station,
    identifier: JSON.stringify(station.label).replace('"', '').replace('"', ''),
    valid: Boolean(station.status === "operational"),
    current: mapCurrentMeasurements(station.current, measureSettings)
  }));

  return stations;
};

export const getGddData = (airTemp, baseTemp, maxTemp) => {
  let gddValues = [];

  airTemp.values
    .filter((entry) => entry.value !== null)
    .forEach((entry, idx) => {
      let cumSum = idx > 0 ? gddValues[idx - 1].value : 0;
      let temp = Math.min(entry.value, maxTemp);
      let gdd = Math.max(0, temp - baseTemp);
      cumSum += gdd;

      gddValues.push({...entry, value: cumSum});
    });

  return {...airTemp, values: gddValues, sensor: WeatherSensor.GDD};
};

/* ============== //
 ||    ACTIONS    ||
 // ============== */

export function setShowWeatherDrawer(show) {
  return {
    type: SET_SHOW_WEATHER_DRAWER,
    payload: show
  };
}

export function fetchWeatherStations(farmId, accumulation, measureSettings) {
  return {
    type: FETCH_WEATHER_STATIONS,
    payload: fetchAllStations(farmId, accumulation, measureSettings)
  };
}

export function selectStation(station) {
  return {
    type: SELECT_WEATHER_STATION,
    payload: station
  };
}

export function fetchStationReadings(id, farmId, dateRangeStart, dateRangeEnd, weatherSensors, measureSettings) {
  let request = async () => {

    let data = await Promise.all(weatherSensors.map(({sensor, axis, resolution, color}) => {
      // In the case of growing degree days, we need to request the WeatherSensor.AIR_TEMP measurements and calculate the GDD ourselves.
      if (sensor === WeatherSensor.GDD) {
        return WebAPIUtils.fetchReadings(id, farmId, dateRangeStart, dateRangeEnd, "1d", WeatherSensor.AIR_TEMP)
          .then((result) => getGddData(result, 5.0, 30.0))
          .then((result) => ({...result, axis, sensor, color}));
      }

      // In the case of rain measurements, we need to make all the requests with the WeatherSensor.RAIN as sensor, and the rewrite the sensor-value afterwards.
      if ([WeatherSensor.RAIN_DAILY, WeatherSensor.RAIN_HOURLY, WeatherSensor.RAIN_WEEKLY].includes(sensor)) {
        return WebAPIUtils.fetchReadings(id, farmId, dateRangeStart, dateRangeEnd, resolution, WeatherSensor.RAIN)
          .then((result) => ({...result, axis, sensor, color}));
      }

      if (sensor === WeatherSensor.SOLAR_RADIATION) {
        return WebAPIUtils.fetchForecastReadings(id, farmId, dateRangeStart, dateRangeEnd, resolution, sensor).then((result) => {
          let values = [];
          if (result.histories.length > 0) {
            values = result.histories[0].values;
          }
          return {values, axis, sensor, color, resolution, incompleteBuckets: []};
        });
      }

      if (sensor === WeatherSensor.UV) {
        const forecastSensor = 'UV_INDEX';
        return WebAPIUtils.fetchReadings(id, farmId, dateRangeStart, dateRangeEnd, resolution, sensor)
          .then((result) => WebAPIUtils.fetchForecastReadings(id, farmId, dateRangeStart, dateRangeEnd, resolution, forecastSensor).then((forecastResult) => {
              let values = result.values;
              if (forecastResult.histories.length > 0) {
                const forecastValues = forecastResult.histories[0].values;
                if (values.length === 0) {
                  values = forecastValues;
                }
                else {
                  values = values.map((hardwareValue) => {
                    if (hardwareValue.value === null) {
                      const forecastValue = forecastValues.find(({timestamp}) => timestamp === hardwareValue.timestamp);
                      if (forecastValue) {
                        return forecastValue;
                      }
                    }
                    return hardwareValue;
                  });
                }
              }
              return {...result, values, axis, sensor, color, resolution , incompleteBuckets: []};
            })
          );
      }

      // Always rewrite (or add) the sensor and axis keys.
      return WebAPIUtils.fetchReadings(id, farmId, dateRangeStart, dateRangeEnd, resolution, sensor)
        .then((result) => ({...result, axis, sensor, color}));
    }));

    // Map the data array to a key-value object, where they keys are the WeatherSensor keys.
    data = data.reduce((acc, cur) => ({...acc, [cur.sensor]: cur}), {});

    // Use our locally selected since and until values
    // Also make sure the color exists
    Object.values(data).forEach((dataset) => {
      dataset.since = moment(dateRangeStart);
      dataset.until = moment(dateRangeEnd);
      dataset.color = dataset.color || WeatherSensorColor[dataset.sensor];
      dataset.values = dataset.values && mapMeasurements(dataset.values, dataset.sensor, measureSettings);

      if (dataset.accumulated) {
        let constants = getWeatherSensorConstants(measureSettings, WeatherSensor.RAIN);
        let key = constants && constants.key;
        if (key) {
          dataset.accumulated = mapRainMeasurement(dataset.accumulated, RAIN.MILLIMETERS, key);
        }
      }
    });

    return data;
  };

  return {
    type: FETCH_STATION_READINGS,
    payload: request,
    meta: {
      dateRangeStart: dateRangeStart,
      dateRangeEnd: dateRangeEnd,
    }
  };
}

export function toggleDisplayDataProperty(key, shown) {
  return {
    type: TOGGLE_DISPLAY_DATA_PROPERTY,
    payload: key,
    meta: {
      shown: shown
    }
  };
}

export function setShowWeatherStations(shown) {
  return {
    type: SET_SHOW_WEATHER_STATIONS,
    payload: shown
  };
}

export function setWeatherSensor(sensor) {
  return {
    type: SET_WEATHER_SENSOR,
    payload: sensor
  };
}

export function setDateRange(dateRangeStart, dateRangeEnd, preset) {
  return {
    type: SET_DATE_RANGE,
    payload: {dateRangeStart, dateRangeEnd},
    meta: {
      preset: preset,
    }
  };
}

export function setAccumulation(value) {
  return {
    type: SET_ACCUMULATION,
    payload: value
  };
}

export function setEnableWindGust(val) {
  return {
    type: SET_ENABLE_WIND_GUST,
    payload: val
  };
}

export function setEnableSprayConditions(val) {
  return {
    type: SET_ENABLE_SPRAY_CONDITIONS,
    payload: val
  };
}

export function claimDevice(farmId, label, since) {
  return {
    type: CLAIM_DEVICE,
    payload: WebAPIUtils.claimDevice(farmId, label, since)
  };
}

/* ============== //
 ||    REDUCER    ||
 // ============== */

const initState = {
  stations: [],
  selectedStation: null,
  weatherSensor: WeatherSensor.COMBINED_TEMP,
  weatherData: null,
  resolution: "1h",
  dateRangeStart: null,
  dateRangeEnd: null,
  period: WeatherPeriod.DAY,
  showWeatherStations: false,
  displayData: {[WeatherSensor.RAIN]: true, [WeatherSensor.AIR_TEMP]: true},
  loadingStations: true,
  loadingReadings: true,
  showWeatherDrawer: true,
  error: false,
  accumulation: RAIN_ACCUMULATION.LAST_24_HOURS,
  enableWindGust: true,
  enableSprayConditions: true,
};

export default function reducer(state = initState, action) {
  switch (action.type) {

    case SELECT_WEATHER_STATION:
      let station = state.stations.find((element) => element.identifier === action.payload);
      state = {...state, selectedStation: station};
      break;

    case FETCH_WEATHER_STATIONS + "_PENDING": {
      state = {...state, loadingStations: true};
      break;
    }

    case FETCH_WEATHER_STATIONS + "_FULFILLED": {
      state = {...state, stations: action.payload, loadingStations: false};

      if (state.selectedStation) {
        let selectedStation = state.stations.find((element) => element.identifier === state.selectedStation.identifier);
        state.selectedStation = selectedStation;
      }

      break;
    }
    case FETCH_WEATHER_STATIONS + "_REJECTED":
      state = {...state, loadingStations: false, error: true};
      break;

    case FETCH_STATION_READINGS + "_PENDING":
      state = {...state, loadingReadings: true};
      break;

    case FETCH_STATION_READINGS + "_REJECTED":
      state = {...state, loadingReadings: false, error: true};
      break;

    case FETCH_STATION_READINGS + "_FULFILLED":
      let data = action.payload;

      state = {
        ...state,
        weatherData: data,
        loadingReadings: false,
        resolution: Object.values(data)[0].resolution
      };
      break;

    case TOGGLE_DISPLAY_DATA_PROPERTY:
      let key = action.payload;
      state = {...state, displayData: {...state.displayData}};
      state.displayData[key] = action.meta.shown;
      break;

    case SET_SHOW_WEATHER_STATIONS:
      state = {...state, showWeatherStations: action.payload};
      break;

    case SET_SHOW_WEATHER_DRAWER: {
      state = {...state, showWeatherDrawer: action.payload};
      break;
    }

    case SET_PERIOD_PRESET: {
      state = {...state, period: action.payload, loadingReadings: true};
      break;
    }

    case SET_DATE_RANGE: {
      state = {...state, ...action.payload, loadingReadings: true, period: action.meta.preset};
      break;
    }

    case SET_WEATHER_SENSOR: {
      state = {...state, weatherSensor: action.payload};
      break;
    }

    case SET_ACCUMULATION: {
      state = {...state, accumulation: action.payload};
      break;
    }

    case SET_ENABLE_WIND_GUST: {
      state = {...state, enableWindGust: action.payload};
      break;
    }

    case SET_ENABLE_SPRAY_CONDITIONS: {
      state = {...state, enableSprayConditions: action.payload};
      break;
    }

    default:
      break;
  }
  return state;
}
