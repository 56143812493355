import React, {memo} from 'react';
import SortableHeaderCell from './SortableHeaderCell';

const HeaderRenderer = ({
                          column,
                          sortDirection,
                          priority,
                          onSort,
                          isCellSelected,
                        }) => {

  if (!column.sortable) {
    return <>{column.name}</>;
  }

  return (
    <SortableHeaderCell
      onSort={onSort}
      sortDirection={sortDirection}
      priority={priority}
      isCellSelected={isCellSelected}
      column={column}/>
  );
};

export default memo(HeaderRenderer);
