import React, {
  memo,
  useEffect,
  useState
} from 'react';
import GraphSystem from 'js/components/visualization/Graph/GraphSystem';
import GraphBars from 'js/components/visualization/Graph/GraphBars';
import GraphHoverDisplay from 'js/components/visualization/Graph/GraphHoverDisplay';
import {
  WeatherSensorName,
  WeatherChartType,
  ChartType,
  WeatherBarWidth, WeatherSensor,
} from 'js/constants/WeatherConstants';

import moment from "moment-timezone";

// A workaround to fix a moment bug, where locale does not get loaded.
// http://momentjs.com/docs/#/use-it/browserify/
import 'moment/locale/da';

import './WeatherGraphs.css';
import {
  getWeatherDataPeriod,
  getXDomain,
  periodTicks
} from "js/helpers/WeatherUtils";
import {
  useLangFileRef
} from "js/context/LanguageContext";
import PropTypes from "prop-types";
import GraphLineSegmented from "../visualization/Graph/GraphLineSegmented";
import {getWeatherSensorUnit, useMeasureSettings, useWeatherSensorPreferences} from "js/context/AppSettings/AppSettingsContext";
import {
  RAIN,
} from "js/constants/MeasureConstants";
import {mapRainMeasurement} from "js/helpers/MeasureUtils";

export const WeatherSensorSpaceTop = {
  [WeatherSensor.HUMIDITY]: 0,
};

export const WeatherSensorValueDomain = {
  [WeatherSensor.HUMIDITY]: [0, 110],
};

const getDataset = (LangFile, measureSettings, weatherData) => {
  let {sensor, values, resolution} = weatherData;

  return {
    ...weatherData,
    points: values.map((point) => ({x: moment(point.timestamp).valueOf(), y: point.value})),
    unit: getWeatherSensorUnit(measureSettings, sensor),
    showCircle: WeatherChartType[sensor] === ChartType.LINE,
    name: WeatherSensorName(LangFile)[sensor],
    chartType: WeatherChartType[sensor],
    pointWidth: WeatherBarWidth(resolution),
    showTooltip: true,
  };
};

const WeatherGraphs = ({weatherData, onTickClicked}) => {

  const LangFile = useLangFileRef();
  const measureSettings = useMeasureSettings();
  const [data, setData] = useState(null);
  const primarySensor = weatherData && Object.keys(weatherData)[0];
  const preferences = useWeatherSensorPreferences(primarySensor);

  useEffect(() => {
    if (weatherData) {

      let sensors = Object.keys(weatherData);

      if (sensors.length > 0) {
        let data = sensors.reduce((acc, sensor) => ({...acc, [sensor]: getDataset(LangFile.current, measureSettings, weatherData[sensor])}), {});
        setData(data);
      }
    }
  }, [weatherData, measureSettings]);

  if (!data) {
    return null;
  }

  let {since, until} = getWeatherDataPeriod(data);

  let minExtentLeftY = 5, spaceTopLeftY = WeatherSensorSpaceTop[primarySensor];

  if (preferences && preferences.key === RAIN.INCHES) {
    minExtentLeftY = mapRainMeasurement(5, RAIN.MILLIMETERS, RAIN.INCHES);
    spaceTopLeftY = mapRainMeasurement(2, RAIN.MILLIMETERS, RAIN.INCHES);
  }

  return (
    <GraphSystem
      datasets={data}
      minExtentLeftY={minExtentLeftY}
      spaceTopLeftY={spaceTopLeftY}
      onTickClicked={onTickClicked}
      domainLeftY={WeatherSensorValueDomain[primarySensor]}
      margin={{left: 30, top: 10, right: 30, bottom: 20}}
      xTicks={periodTicks(since, until)}
      domainX={getXDomain(since, until)}>

      {Object.keys(data).map((sensor) => {
        let dataset = data[sensor];

        if (dataset.chartType === ChartType.BAR) {
          return <GraphBars key={sensor} datasetName={sensor}/>;
        }
        else {
          return <GraphLineSegmented key={sensor} datasetName={sensor}/>;
        }

      })}

      <GraphHoverDisplay/>
    </GraphSystem>
  );
};

GraphSystem.propTypes = {
  weatherSensor: PropTypes.string,
  onTickClicked: PropTypes.func,
};

export default memo(WeatherGraphs);
