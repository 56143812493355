import React, {
  memo,
  useState,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import StyleConstants from 'js/StyleConstants';

import MapPolygon from 'js/components/MapObjects/MapPolygon';
import MapOverlay from 'js/components/MapObjects/MapOverlay';
import MapPopup from "js/components/MapObjects/MapPopup";
import FieldPopupContent from 'js/components/MapView/FieldPopupContent';
import ViewModeConstants from "js/constants/ViewModeConstants";

import {voidFunc} from "js/constants/PropTypeUtils";
import {getFieldCenter} from "../../helpers/MapsUtils";

const MapField = (props: MapField.propTypes) => {

  const coordinates = useRef(props.field.polygon.coordinates[0].map((coord) => {
    return {
      lng: coord[0],
      lat: coord[1]
    };
  }));

  const [hovered, setHovered] = useState(false);

  const onClick = useCallback((event) => {
    props.onClick(props.field, event);
  }, [props.onClick, props.field]);

  const onMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    if (!props.disabled) {
      setHovered(false);
    }
  }, []);


  if (!props.field) {
    return;
  }

  let strokeWeight = 2;
  let strokeColor = 'rgb(224,210,61)';
  let fillColor = 'white';

  if (hovered && !props.selected) {
    strokeColor = StyleConstants.palette.primary.main;
    strokeWeight = 4;
  }

  else if (props.selected) {
    strokeColor = 'white';
    strokeWeight = 6;
  }
  let validImage = props.fieldImage && (props.fieldImage[props.imageType] || props.fieldImage.url);

  return (
    <MapPolygon
      fillOpacity={0.0}
      fillColor={fillColor}
      strokeColor={strokeColor}
      strokeWeight={strokeWeight}
      disabled={props.disabled}
      coordinates={coordinates.current}
      selected={props.selected}
      onClick={onClick}
      zIndex={validImage ? 100 : 0}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>

      {validImage && (
        <MapOverlay
          zIndex={validImage ? 100 : 0}
          fieldImage={props.fieldImage}
          imageType={props.imageType}/>
      )}

      {(props.selected && props.viewMode === ViewModeConstants.OVERVIEW) && (
        <MapPopup
          clickable={true}
          hasPin={true}
          selected={true}
          shown={true}
          onClickAway={props.onClickAway}
          position={getFieldCenter(props.field)}
          style={{zIndex: 9}}>
          <FieldPopupContent
            fieldImage={props.fieldImage}
            date={props.date}
            survey={props.survey}
            imageType={props.imageType}
            field={props.field}
            onSettings={props.onSettings}
            onAnalyse={props.onAnalyse}
            onPrescribe={props.onPrescribe}
            onNotes={props.onNotes}/>
        </MapPopup>
      )}

    </MapPolygon>
  );
};

MapField.propTypes = {
  fieldImage: PropTypes.object,
  survey: PropTypes.object,
  imageType: PropTypes.string,
  date: PropTypes.string,
  coordinates: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  field: PropTypes.object,
  strokeWeight: PropTypes.number,
  onClick: PropTypes.func,
  onClickAway: PropTypes.func,
  onAnalyse: PropTypes.func,
  onPrescribe: PropTypes.func,
  onSettings: PropTypes.func,
  onNotes: PropTypes.func,
  selected: PropTypes.bool,
  onlyOutline: PropTypes.bool,
};

MapField.defaultProps = {
  onClick: voidFunc,
  onClose: voidFunc,
  onSettings: voidFunc,
  onAnalyse: voidFunc,
  onPrescribe: voidFunc,
  onNotes: voidFunc,
  selected: false,
  disabled: false,
  viewMode: ViewModeConstants.OVERVIEW,
  imageType: null,
  fieldImage: null,
  onlyOutline: false,
  coordinates: []
};

export default memo(MapField);