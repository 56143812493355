import React, {
  Fragment,
  memo, useCallback, useEffect, useState
} from "react";

import DetailsView from "js/components/visualization/DetailsView";
import AnalysisGraph from "js/components/AnalysisDrawer/AnalysisGraph";
import {withStyles} from '@material-ui/core/styles';
import SatelliteSource from "js/model/SatelliteSource";
import moment from "moment-timezone";

import {
  MenuItem,
  FormControl,
  Typography,
  Box,
  Select,
  OutlinedInput,
  ListItemText,
  Tooltip,
  InputLabel,
} from '@material-ui/core';

import {
  grey,
} from "@material-ui/core/colors";

import {
  WEATHER_DRAWER_HEIGHT,
  WEATHER_DRAWER_HEIGHT_SMALL
} from "js/constants/WeatherConstants";
import {Today} from "@material-ui/icons";
import {useLangFile} from '../../context/LanguageContext';
import PropTypes from 'prop-types';

const styles = (theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: grey["900"],
      boxShadow: theme.shadows[1],
      fontSize: 16,
    },
    tooltipPopper: {
      opacity: 1,
      padding: theme.spacing(1),
    },
    formControl: {
      margin: [[theme.spacing(0.5), 0]],
      minWidth: 120,
      width: "100%",
      '& > div': {
        minHeight: 48,
      }
    },
    select: {
      padding: [[theme.spacing(1), theme.spacing(3)]],
      display: 'flex',
      '& span': {
        textAlign: "left",
      },
    },
    header: {
      minHeight: 32,
      '& p': {
        fontWeight: 500,
      }
    },
    inputLabel: {
      paddingLeft: 2,
      paddingRight: 2,
      backgroundColor: "white"
    },
  }
);

const AnalysisTab = (props: AnalysisTab.propTypes) => {
  const LangFile = useLangFile();

  const onChangeYear = useCallback((event) => {
    props.setYear(event.target.value.toString());
  });

  const onChangeSource = useCallback((event) => {
    props.selectSatelliteSource(event.target.value);
  });

  const renderYearPicker = (years, fieldSeasons) => {
    let {date, classes} = props;

    let year = moment(date).format("YYYY");
    let items = renderYearMenuItems(years, fieldSeasons);
    if (items == null) {
      return;
    }

    return (
      <FormControl
        variant='outlined'
        className={classes.formControl}>
        <InputLabel className={classes.inputLabel}>{LangFile.AnalysisTab.yearTitle}</InputLabel>
        <Select
          value={items.length > 0 ? year : ''}
          displayEmpty
          autoWidth={true}
          onChange={onChangeYear}
          classes={{select: classes.select}}
          input={
            <OutlinedInput
              labelWidth={0}
              name={LangFile.AnalysisTab.yearTitle}
              id='outlined-year-simple'/>
          }>
          {items}
        </Select>
      </FormControl>
    );
  };

  const renderSatellitePicker = () => {
    let {satelliteSource, classes} = props;

    return (
      <Tooltip
        placement={"right-end"}
        title={
          <Fragment>
            <b>Sentinel</b>
            <Typography color='inherit'>{LangFile.AnalysisTab.sentinelTooltip}</Typography>
            <b>Landsat</b>
            <Typography color='inherit'>{LangFile.AnalysisTab.landsatTooltip}</Typography>
          </Fragment>
        }
        classes={{tooltip: classes.tooltip, popper: classes.tooltipPopper}}>
        <FormControl
          variant='outlined'
          className={classes.formControl}>
          <InputLabel className={classes.inputLabel}>{LangFile.AnalysisTab.sourceTitle}</InputLabel>
          <Select
            value={satelliteSource}
            onChange={onChangeSource}
            autoWidth={true}
            displayEmpty
            classes={{select: classes.select}}>
            <MenuItem value={SatelliteSource.BOTH}>
              <ListItemText primary={LangFile.AnalysisTab.sourceBoth}/>
            </MenuItem>
            <MenuItem value={SatelliteSource.SENTINEL}>
              <ListItemText primary={"Sentinel"}/>
            </MenuItem>
            <MenuItem value={SatelliteSource.LANDSAT}>
              <ListItemText primary={"Landsat"}/>
            </MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
    );
  };

  const createYearDataSet = (dates) => {
    let years = new Set();
    if (dates) {
      dates.forEach((value) => {
          let date = value.split('-')[0];
          years.add(date);
      });
    }
    return years;
  };

  const createFieldSeasonDataSet = (years) => {
    let map = new Map();
    if (props.fieldSeasons && years) {
      const fieldSeasons = Object.values(props.fieldSeasons).filter((fieldSeason) =>
        fieldSeason.sowing
      ).sort((lhs, rhs) => moment(lhs.sowing) - moment(rhs.sowing));

      let overlappingFieldSeason = null;
      years.forEach((year) => {
        let array = [];
        const attachedFieldSeasons = fieldSeasons.filter((fieldSeason) =>
          moment(fieldSeason.sowing).year() === Number(year)
        );

        if (overlappingFieldSeason != null) {
          array.push(overlappingFieldSeason);
          overlappingFieldSeason = null;
        }

        attachedFieldSeasons.forEach((fieldSeason) => {
          array.push(fieldSeason);
          if (moment(`${year}-07-01`) - moment(fieldSeason.sowing) < 0) {
            overlappingFieldSeason = fieldSeason;
          }
        });

        map[year] = array;
      });
      return map;
    }
  };

  const renderYearMenuItems = (years, fieldSeasonMap) => {
    if (years.size === 0) {
      return null;
    }

    let menuItems = [];
    years.forEach((year) => {
      const fieldSeasons = fieldSeasonMap[year];
      if (fieldSeasons) {
        let title = year;
        fieldSeasons.forEach((fieldSeason) => {
          if (title === year) {
            title = title + " - " + LangFile.CropType[fieldSeason.crop];
          }
          else {
            title = title + " / " + LangFile.CropType[fieldSeason.crop];
          }
        });

        menuItems.push(
          <MenuItem
            value={year}
            key={year}>{title}</MenuItem>
        );
      }
    });
    return menuItems;
  };

  const formatDate = useCallback(() => {
    let date = moment(props.date).format("LL");
    date = date.substring(0, date.length - 5).trimEnd();

    if (date.endsWith(",") || date.endsWith(".")) {
      date = date.substring(0, date.length - 1);
    }

    return date;
  }, [props.date]);

  const [years, setYears] = useState(new Set());
  const [fieldSeasons, setFieldSeasons] = useState(new Map());
  const {classes} = props;

  useEffect(() => {
    if (props.selectedFieldDates !== null) {
      setYears(createYearDataSet(props.selectedFieldDates));
    }
  }, [props.selectedFieldDates]);

  useEffect(() => {
    if (props.fieldSeasons !== null) {
      setFieldSeasons(createFieldSeasonDataSet(years));
    }
  }, [props.fieldSeasons, years]);

  const year = moment(props.date).format("YYYY");
  const low = window.innerHeight <= 800;
  const height = low ? WEATHER_DRAWER_HEIGHT_SMALL : WEATHER_DRAWER_HEIGHT;

  return (
    <Box
      display={"flex"}
      width={"100%"}
      height={height}
      maxHeight={height}
      mt={0}
      flex={"1 1 auto"}>
      <Box
        flex={1}
        display={"flex"}
        flexWrap={"nowrap"}
        flexDirection={low ? "row-reverse" : "column-reverse"}>

        <Box
          maxWidth={"200px"}
          width={"200px"}
          display={"flex"}
          flexDirection={"column"}
          height={low ? "auto" : "50%"}
          py={low ? 2 : 0}
          pl={low ? 2 : 1}>

          <DetailsView
            date={props.date}
            selectedFieldStatistics={props.selectedFieldStatistics}
            LangFile={LangFile}/>
        </Box>

        <Box
          maxWidth={"200px"}
          width={"200px"}
          height={low ? "auto" : "50%"}
          pl={1}>

          <Box
            py={1}
            fontSize={low ? "1rem" : "2rem"}
            className={classes.header}
            width={1}
            fontWeight={"bold"}>
            <Typography noWrap>{LangFile.AnalysisTab.historyTitle}</Typography>
          </Box>

          <Box
            pb={2}
            fontSize={low ? "0.75rem" : "1.25rem"}
            width={1}
            fontWeight={"light"}
            display={"flex"}
            alignItems={"center"}>
            <Today fontSize={"small"}/><Box pl={0.5}/><Typography noWrap>{formatDate()}</Typography>
          </Box>

          {renderYearPicker(years, fieldSeasons)}
          {renderSatellitePicker()}
        </Box>
      </Box>

      <AnalysisGraph
        satelliteSource={props.satelliteSource}
        field={props.field}
        date={props.date}
        setDate={props.setDate}
        selectedFieldStatistics={props.selectedFieldStatistics}
        year={year}
        imageType={props.imageType}
        fieldSeasonsMap={fieldSeasons}/>
    </Box>
  );
};

AnalysisTab.propTypes = {
  satelliteSource: PropTypes.any,
  selectSatelliteSource: PropTypes.func,
  field: PropTypes.object,
  dateFormatLong: PropTypes.object,
  setDate: PropTypes.func,
  selectedFieldStatistics: PropTypes.array,
  date: PropTypes.string,
  selectedFieldDates: PropTypes.array,
  setYear: PropTypes.func,
  fieldSeasons: PropTypes.array,
  imageType: PropTypes.string
};

export default memo(withStyles(styles)(AnalysisTab));
