import React, {
  Fragment,
  memo,
  useCallback,
  useState
} from 'react';
import WindInfoSettingsDialog from "./WindInfoSettingsDialog";
import InfoSettingsButton from "../InfoSettingsButton/InfoSettingsButton";
import PropTypes from "prop-types";

const WindInfoSettings = (props: WindInfoSettings.propTypes) => {

  const [shown, setShown] = useState(false);

  const toggleShown = useCallback(() => {
    setShown(!shown);
  }, [shown]);

  return (
    <Fragment>
      <InfoSettingsButton onClick={toggleShown}/>
      <WindInfoSettingsDialog
        shown={shown}
        onClose={toggleShown}
        enableWindgusts={props.enableWindgusts}
        onEnableWindgustsChanged={props.onEnableWindgustsChanged}
        enableSprayConditions={props.enableSprayConditions}
        onEnableSprayConditionsChanged={props.onEnableSprayConditionsChanged}/>
    </Fragment>
  );
};

WindInfoSettings.propTypes = {
  enableWindgusts: PropTypes.bool,
  onEnableWindgustsChanged: PropTypes.func,
  enableSprayConditions: PropTypes.bool,
  onEnableSprayConditionsChanged: PropTypes.func,
};

export default memo(WindInfoSettings);