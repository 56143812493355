import Bowser from "bowser";
import UUID from "js/algorithms/UUID";

export const HARDWARE_ID_KEY = "com/ceptu/fieldsense/web/hardware/id";

export function detectBrowser() {
  // source: https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome

  // please note,
  // that IE11 now returns undefined again for window.chrome
  // and new Opera 30 outputs true for window.chrome
  // but needs to check if window.opr is not undefined
  // and new IE Edge outputs to true now for window.chrome
  // and if not iOS Chrome check
  // so use the below updated condition

  let isChromium = window.chrome;
  let winNav = window.navigator;
  let ua = winNav.userAgent;
  let vendorName = winNav.vendor;
  let isOpera = typeof window.opr !== "undefined";
  let isIEedge = winNav.userAgent.indexOf("Edge") > -1;
  let isIOSChrome = winNav.userAgent.match("CriOS");
  let isIE = ua.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./) ;

  let isChrome = (
    isChromium !== null &&
    typeof isChromium !== "undefined" &&
    vendorName === "Google Inc." &&
    isOpera === false &&
    isIEedge === false
  );

  return {
    isChrome: Boolean(isChrome),
    isIOSChrome: Boolean(isIOSChrome),
    isIEedge: Boolean(isIEedge),
    isIE: Boolean(isIE)
  };
}

export function getPlatformInfo() {
  const browser = Bowser.getParser(window.navigator.userAgent);

  let hardwareId = localStorage.getItem(HARDWARE_ID_KEY);

  let invalid = !hardwareId || hardwareId === "null";

  if (invalid) {
    hardwareId = UUID.uuidv4();
    localStorage.setItem(HARDWARE_ID_KEY, hardwareId);
  }

  return {
    id: hardwareId,
    platform: browser.getPlatform().type,
    manufacturer: browser.getPlatform().vendor,
    os: `${browser.getOSName()} ${browser.getOSVersion()}`,
    browser: `${browser.getBrowserName()} ${browser.getBrowserVersion()}`,
  };
}