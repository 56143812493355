import {SURVEY_LAYERS, type SurveyLayer,} from "../../constants/SurveyLayers";
import ViewModeConstants from "../../constants/ViewModeConstants";
import {Survey} from "../../components/Prescription/PrescriptionJob";
import {SurveyLayerViewCapabilities, SurveyViewCapabilities} from "../../helpers/SurveyUtils";

export const LEGACY_IMAGES_FOLDER = "images";
export const CURRENT_IMAGES_FOLDER = "newImages";

export const NORMALIZED_IMAGES_KEY = "NORMALIZED";
export const RAW_IMAGES_KEY = "RAW";
export const COARSE_CLASSIFICATION_IMAGES_KEY = "COARSE_CLASSIFICATION";
export const FINE_CLASSIFICATION_IMAGES_KEY = "FINE_CLASSIFICATION";

export const getImageUrl = (images: Object, layer: SurveyLayer, classificationsEnabled: Boolean, variationsEnabled: Boolean, viewMode: string) => {

  if (!images) {
    return null;
  }

  let folder = images[layer];

  let image;
  if (folder) {
    if (variationsEnabled || layer === SURVEY_LAYERS.ALTITUDE) {
      image = folder[NORMALIZED_IMAGES_KEY];
    }
    else if (classificationsEnabled) {
      image = getClassificationURL(folder, viewMode);
    }
    else {
      image = folder[RAW_IMAGES_KEY];
    }
  }

  return image;
};

const getClassificationURL = (images: Object, viewMode: string) => {
  if (viewMode === ViewModeConstants.OVERVIEW) {
    return images["FI_DEMAND_COARSE"] != null ? images["FI_DEMAND_COARSE"] : images[COARSE_CLASSIFICATION_IMAGES_KEY];
  }
  else {
    return images["FI_DEMAND_FINE"] != null ? images["FI_DEMAND_FINE"] : images[FINE_CLASSIFICATION_IMAGES_KEY];
  }
};

export const extractSurveyCapabilities = (surveys: Array<Survey>): SurveyViewCapabilities => {
  let result = new SurveyViewCapabilities();
  Object.keys(SURVEY_LAYERS).forEach((layer) => {
    let overviewLayerCapabilities = new SurveyLayerViewCapabilities();
    surveys.filter(Boolean).forEach((survey) => {
      let surveyCapabilities = result.singleField.get(survey.surveyId) || new Map();
      let singleFieldLayerCapabilities = surveyCapabilities.get(layer) || new SurveyLayerViewCapabilities();

      let images = survey[CURRENT_IMAGES_FOLDER];
      let imagesLegacy = survey[LEGACY_IMAGES_FOLDER];
      let folder = (images && images[layer]) || (imagesLegacy && imagesLegacy[layer]);

      if (folder) {
        overviewLayerCapabilities.enableRaw = overviewLayerCapabilities.enableRaw || folder["RAW"] != null;
        overviewLayerCapabilities.enableVariations = false;
        overviewLayerCapabilities.enableClassifications = overviewLayerCapabilities.enableClassifications || folder["COARSE_CLASSIFICATION"] != null || folder["FI_DEMAND_COARSE"] != null;
        overviewLayerCapabilities.enableCustomClassification = overviewLayerCapabilities.enableCustomClassification || (folder["FI_DEMAND_COARSE"] != null && folder["FI_DEMAND_FINE"] != null);

        singleFieldLayerCapabilities.enableRaw = folder["RAW"] != null;
        singleFieldLayerCapabilities.enableVariations = folder["NORMALIZED"] != null;
        singleFieldLayerCapabilities.enableClassifications = folder["FINE_CLASSIFICATION"] != null || folder["FI_DEMAND_FINE"] != null;
        singleFieldLayerCapabilities.enableCustomClassification = folder["FI_DEMAND_COARSE"] != null && folder["FI_DEMAND_FINE"] != null;

        surveyCapabilities.set(layer, singleFieldLayerCapabilities);
        result.singleField.set(survey.surveyId, surveyCapabilities);
      }
    });

    result.overview.set(layer, overviewLayerCapabilities);
  });
  return result;
};