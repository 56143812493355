import React, {
  memo,
  useEffect,
  useState,
  useCallback
} from 'react';
import GraphSystem from 'js/components/visualization/Graph/GraphSystem';
import GraphLine from 'js/components/visualization/Graph/GraphLine';

import moment from "moment-timezone";
import {
  green,
  orange,
  red
} from "@material-ui/core/colors";
import PropTypes from "prop-types";
import {useLangFile} from "js/context/LanguageContext";
import GraphHoverDisplay from "js/components/visualization/Graph/GraphHoverDisplay";
import {
  getWeatherDataPeriod,
  getXDomain,
  periodTicks
} from "js/helpers/WeatherUtils";
import GraphLegend from "js/components/visualization/Graph/GraphLegend";
import GraphColoredLine from "js/components/visualization/Graph/GraphColoredLine";
import {
  WeatherSensor,
  WeatherSensorColor,
  WeatherSensorName
} from "js/constants/WeatherConstants";
import {
  getWeatherSensorUnit,
  useMeasureSettings
} from "js/context/AppSettings/AppSettingsContext";
import {mapWindMeasurement} from "js/helpers/MeasureUtils";
import {KEYS} from "js/reducers/AppSettingsReducer";
import {WIND} from "js/constants/MeasureConstants";
import GraphLineSegmented from "../visualization/Graph/GraphLineSegmented";

export const WindLimitLines = {
  GOOD: "GOOD",
  POOR: "POOR",
  BAD: "BAD",
};

const BAD_THRESHOLD_METRIC = 5;
const POOR_THRESHOLD_METRIC = 3;

const getWindValueColor = (value, measureSettings) => {
  let windUnit = measureSettings[KEYS.MEASURE_UNIT_WIND];
  let poorThreshold = mapWindMeasurement(POOR_THRESHOLD_METRIC, WIND.METERS_PER_SECOND, windUnit);
  let badThreshold = mapWindMeasurement(BAD_THRESHOLD_METRIC, WIND.METERS_PER_SECOND, windUnit);

  if (value >= badThreshold) {
    return red["A700"];
  }
  else if (value >= poorThreshold) {
    return orange["A400"];
  }
  else {
    return green["A700"];
  }
};

const createLimitLines = (xDomain, measureSettings) => {
  let windUnit = measureSettings[KEYS.MEASURE_UNIT_WIND];
  let poorThreshold = mapWindMeasurement(POOR_THRESHOLD_METRIC, WIND.METERS_PER_SECOND, windUnit);
  let badThreshold = mapWindMeasurement(BAD_THRESHOLD_METRIC, WIND.METERS_PER_SECOND, windUnit);

  let limitLines = {};

  limitLines[WindLimitLines.BAD] = createLimitLine(WindLimitLines.BAD, badThreshold, getWindValueColor(badThreshold, measureSettings), xDomain, measureSettings);
  limitLines[WindLimitLines.POOR] = createLimitLine(WindLimitLines.POOR, poorThreshold, getWindValueColor(poorThreshold, measureSettings), xDomain, measureSettings);

  return limitLines;
};

const createLimitLine = (name, value, color, xDomain, measureSettings) => {
  let first = {x: xDomain[0], y: value};
  let last = {x: xDomain[1], y: value};

  let line = {x1: first.x, x2: last.x, y1: first.y, y2: last.y};

  return {
    name: name,
    unit: getWeatherSensorUnit(measureSettings, WeatherSensor.WIND),
    points: [first, last],
    showCircle: false,
    color: color,
    opacity: 0.5,
    dashed: true,
    type: "line",
    line: line,
    axis: "left"
  };
};

const WindGraph = ({weatherData, onTickClicked, enableWindGust, enableSprayConditions}) => {

  const LangFile = useLangFile();
  const [state, setState] = useState(null);
  const measureSettings = useMeasureSettings();

  useEffect(() => {
    if (!measureSettings) {
      return;
    }

    let averageWind = weatherData && weatherData[WeatherSensor.WIND];
    let maxWind = weatherData && weatherData[WeatherSensor.WIND_MAX];

    if (averageWind) {
      let datasets = {};

      let windPoints = averageWind.values.map((reading) => ({
        x: moment(reading.timestamp).valueOf(),
        y: reading.value
      })).filter((reading) => Boolean(reading.y));

      let {since, until} = getWeatherDataPeriod(weatherData);

      let xDomain = getXDomain(since, until);
      let limitlines = createLimitLines(xDomain, measureSettings);
      let ticks = periodTicks(since, until);
      let resolution = averageWind.resolution;

      datasets[WeatherSensor.WIND] = {
        name: WeatherSensorName(LangFile)[WeatherSensor.WIND],
          unit: getWeatherSensorUnit(measureSettings, WeatherSensor.WIND),
          points: windPoints,
          color: WeatherSensorColor[WeatherSensor.WIND],
          opacity: 1.0,
          type: "line",
          axis: "left",
          showCircle: true,
          dataPropName: WeatherSensor.WIND,
          weatherData: averageWind,
          since: since,
          until: until,
          resolution: resolution,
          showTooltip: true,
      };

      if (maxWind) {
        let gustPoints = maxWind.values.map((reading) => ({
          x: moment(reading.timestamp).valueOf(),
          y: reading.value
        })).filter((reading) => Boolean(reading.y));

        datasets[WeatherSensor.WIND_MAX] = {
          name: WeatherSensorName(LangFile)[WeatherSensor.WIND_MAX],
          unit: getWeatherSensorUnit(measureSettings, WeatherSensor.WIND),
          points: gustPoints,
          color: WeatherSensorColor[WeatherSensor.WIND_MAX],
          opacity: 1.0,
          type: "line",
          axis: "left",
          showCircle: true,
          dataPropName: WeatherSensor.WIND_MAX,
          weatherData: maxWind,
          since: since,
          until: until,
          resolution: resolution,
          showTooltip: true,
        };
      }

      setState({
        datasets,
        xDomain,
        limitlines,
        ticks,
        since,
        until,
        resolution: averageWind.resolution,
      });
    }

  }, [weatherData, measureSettings]);

  const getColor = useCallback((pointY, dataset) => {
    if (!Number.isNaN(pointY)) {
      if (!enableSprayConditions || dataset && dataset.dataPropName === WeatherSensor.WIND_MAX) {
        return dataset.color;
      }
      else {
        return getWindValueColor(pointY, measureSettings);
      }
    }
  }, [measureSettings, enableSprayConditions]);

  if (!state) {
    return null;
  }

  let margins = {left: 30, top: 20, right: 20, bottom: 40};
  let legendEntries = [];

  if (enableSprayConditions) {
    legendEntries.push({
      text: LangFile.WindGraph.windConditions.goodConditions,
      color: green["A700"],
    });
    legendEntries.push({
      text: LangFile.WindGraph.windConditions.poorConditions,
      color: orange["A400"],
    });
    legendEntries.push({
      text: LangFile.WindGraph.windConditions.badConditions,
      color: red["A700"],
    });
  }

  if (enableWindGust) {
    legendEntries.push({
      text: WeatherSensorName(LangFile)[WeatherSensor.WIND_MAX],
      color: WeatherSensorColor[WeatherSensor.WIND_MAX],
    });
  }

  if (legendEntries.length === 0) {
    margins.bottom = 20;
  }

  return (
    <GraphSystem
      backdropLines={true}
      datasets={state.datasets}
      unit={getWeatherSensorUnit(measureSettings, WeatherSensor.WIND)}
      margin={margins}
      onTickClicked={onTickClicked}
      xTicks={state.ticks}
      domainX={state.xDomain}>

      {enableWindGust && <GraphLineSegmented datasetName={WeatherSensor.WIND_MAX}/>}

      {enableSprayConditions ? <GraphColoredLine datasetName={WeatherSensor.WIND} limitlines={state.limitlines} getColor={getColor}/> : <GraphLineSegmented datasetName={WeatherSensor.WIND}/>}

      <GraphLegend entries={legendEntries}/>

      {Object.values(state.limitlines).map((limit, idx) => (
        <GraphLine overrideDatasets={{[limit.name]: limit}} datasetName={limit.name} key={`limitline-${idx}`}/>
      ))}

      <GraphHoverDisplay getColor={getColor}/>

    </GraphSystem>
  );
};

WindGraph.propTypes = {
  weatherData: PropTypes.object,
  onTickClicked: PropTypes.func,
  enableWindGust: PropTypes.bool,
  enableSprayConditions: PropTypes.bool,
};

export default memo(WindGraph);
