"use strict";

export class VariableRateMap {
  constructor(vrmName, metaType, maxAllocation, totalAllocation, allocationUnit, satellite, sourceTiffs, intervals, overrides, seasonId) {
    this.vrmName = vrmName;
    this.metaType = metaType;
    this.maxAllocation = Number(maxAllocation);
    this.totalAllocation = Number(totalAllocation);
    this.allocationUnit = allocationUnit;
    this.satellite = satellite;
    this.sourceTiffs = sourceTiffs;
    this.ndviIntervals = intervals;
    this.overrides = overrides;
    this.seasonId = seasonId;
  }
}

export class VariableRateMapUpdate {
  constructor(vrmName, metaType, maxAllocation, totalAllocation, allocationUnit, intervals, overrides, seasonId, fieldId) {
    this.vrmName = vrmName;
    this.metaType = metaType;
    this.maxAllocation = Number(maxAllocation);
    this.totalAllocation = Number(totalAllocation);
    this.allocationUnit = allocationUnit;
    this.ndviIntervals = intervals;
    this.overrides = overrides;
    this.seasonId = seasonId;
    this.fieldId = fieldId;
  }
}
