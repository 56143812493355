import React from 'react';

import {withStyles} from "@material-ui/core/styles";

import PropTypes from 'prop-types';

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography
} from "@material-ui/core";

import {Close} from "@material-ui/icons";

import {useLangFile} from "js/context/LanguageContext";

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  }
});

const ReplaceStationAppBar = ({classes, onClose}) => {
  const LangFile = useLangFile();

  return (
    <AppBar color={"primary"} position="static">
      <Toolbar variant="dense">
        <IconButton edge="start" color="inherit" onClick={onClose}>
          <Close />
        </IconButton>

        <Typography variant="h6" className={classes.title}>
          {LangFile.ReplaceStationAppBar.title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

ReplaceStationAppBar.propTypes = {
  onClose: PropTypes.func,
};

export default withStyles(styles)(ReplaceStationAppBar);