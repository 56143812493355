import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useGraph} from "js/context/GraphContext";
import GraphBar from "./GraphBar";
import {useGraphHover} from "../../../context/GraphHoverContext";

const GraphBars = ({datasetName, axis}) => {

  const {datasets} = useGraph();

  let dataset = datasets[datasetName];
  let {hoveredItems} = useGraphHover();

  if (!dataset) {
    return null;
  }

  return (
    <g className="graph-bars">
      {dataset.points.map((bar, idx) => {

        let target = hoveredItems[datasetName];
        let hovered = false;

        if (target) {
          hovered = bar.x === target.x;
        }

        return (
          <GraphBar
            opacity={hovered ? 0.6 : 1.0}
            bar={bar}
            axis={axis}
            dataset={dataset}
            key={idx}/>
        );
      })}
    </g>
  );
};

GraphBars.propTypes = {
  /**
   * Select which data entry to display. Simply write the property name of the data entry to display.
   *
   * If this is not provided, the graphline will select a property based on which child index this has.
   */
  dataPropName: PropTypes.string,
  axis: PropTypes.oneOf(["left", "right"]),
};

GraphBars.defaultProps = {
  axis: "left",
};

export default memo(GraphBars);
