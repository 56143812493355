import {useTheme} from '@material-ui/core/styles';
import React, {Fragment, memo} from 'react';
import {Box, Button, Paper, Tooltip, Typography} from '@material-ui/core';
import Styles from './MenuButton.module.less';
import {OpenInNew} from '@material-ui/icons';
import PropTypes from 'prop-types';
import {voidFunc} from '../../constants/PropTypeUtils';

const MenuButton = (props: MenuButton.propTypes) => {
  const {tooltip, icon, buttonText, disabled, onClick} = props;

  const theme = useTheme();

  return (
    <Fragment>
      <Tooltip title={tooltip || ""}>
        <Box className={Styles.box}>
          <Paper elevation={2} className={Styles.paper}>
              <Button
                disabled={disabled}
                className={Styles.button}
                onClick={onClick}>
                {
                  React.cloneElement(icon, {
                    style: { fill: theme.palette.primary.main }
                  })
                }
                <Typography className={Styles.text}>{buttonText}</Typography>
                <Box my={0.5} flex={1} display={"flex"} justifyContent={"flex-end"}>
                  <OpenInNew style={{height: 16}}/>
                </Box>
              </Button>
          </Paper>
        </Box>
      </Tooltip>
    </Fragment>
  );
};

MenuButton.propTypes = {
  tooltip: PropTypes.string,
  buttonText: PropTypes.string,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

MenuButton.defaultProps = {
  onClick: voidFunc
};

export default memo(MenuButton);