import {
  memo,
  useCallback
} from 'react';

import PropTypes from 'prop-types';
import useEvent from "js/hooks/useEvent";
import ViewModeConstants from "js/constants/ViewModeConstants";
import {useHookRef} from "js/hooks/useHookRef";

const DateKeyboardHandler = (props: DateKeyboardHandler.propTypes) => {

  const availableDates = useHookRef(props.viewMode === ViewModeConstants.ANALYSIS ? props.selectedFieldDates : props.dates);

  const handleDateChange = useCallback((indexChange) => {
    let dates = availableDates.current;

    let newIndex = dates.indexOf(props.date) + indexChange;
    let newDate = dates[newIndex];

    if (newDate) {
      props.onDateChanged(newDate);
    }

  }, [props.date, props.onDateChanged]);

  const handleKeyboardEvent = useCallback((e) => {
    if (!props.enabled || !e) {
      return;
    }

    if (e.key === "ArrowLeft" || e.key === "Left") {
      handleDateChange(-1);
    }

    else if (e.key === "ArrowRight" || e.key === "Right") {
      handleDateChange(1);
    }
  }, [handleDateChange, props.enabled]);

  useEvent("keyup", handleKeyboardEvent);

  return null;
};

DateKeyboardHandler.propTypes = {
  enabled: PropTypes.bool,
  dates: PropTypes.array,
  selectedFieldDates: PropTypes.array,
  date: PropTypes.string,
  viewMode: PropTypes.string,
  onDateChanged: PropTypes.func,
};

export default memo(DateKeyboardHandler);