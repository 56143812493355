import React, {
  memo,
  useCallback
} from 'react';
import connect from "react-redux/es/connect/connect";
import FullScreenPortalPaper from "js/components/Prescription/FullScreenPortalPaper";
import ManageCrops from "js/components/ManageCrops/ManageCrops";
import ViewModeConstants from "js/constants/ViewModeConstants";
import {setViewMode} from "js/reducers/ControlReducer";
import {
  updateFieldSeasons
} from "js/reducers/FieldReducer";
import {useSelectedSeason} from "js/context/SeasonContext";

const mapStateToProps = (store) => {
  return {
    fields: store.field.fields,
    previousViewport: store.control.previousViewport
  };
};

const ManageCropsContainer = ({dispatch, fields, previousViewport}) => {

  const selectedSeason = useSelectedSeason();

  const handleOnClose = useCallback(() => {
    dispatch(setViewMode(ViewModeConstants.OVERVIEW));
  }, []);

  const handleOnSave = useCallback((fieldSeasons) => {
    return dispatch(updateFieldSeasons(selectedSeason.id, fieldSeasons));
  }, [selectedSeason]);

  return (
    <FullScreenPortalPaper>
      <ManageCrops
        fields={fields}
        previousViewport={previousViewport}
        onSave={handleOnSave}
        onClose={handleOnClose}/>
    </FullScreenPortalPaper>
  );
};

export default memo(
  connect(mapStateToProps)(
    ManageCropsContainer
  )
);