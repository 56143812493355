import WebAPIUtils from "js/WebAPIUtils";
import {getNewestSeason, getSelectedSeason, saveSelectedSeason} from "js/helpers/SeasonUtils";
import Season from "js/model/Season";

export const FETCH_SEASONS = "fieldsense/SeasonReducer/FETCH_SEASONS";
export const DISABLE_SEASON = "fieldsense/SeasonReducer/DISABLE_SEASON";
export const ENABLE_SEASON = "fieldsense/SeasonReducer/ENABLE_SEASON";
export const SELECT_SEASON = "fieldsense/SeasonReducer/SELECT_SEASON";
export const CREATE_SEASON = "fieldsense/SeasonReducer/CREATE_SEASON";
export const UPDATE_SEASON = "fieldsense/SeasonReducer/UPDATE_SEASON";
export const GET_FIELD_SEASONS = "fieldsense/SeasonReducer/GET_FIELD_SEASONS";
export const REMOVE_FIELD_FROM_SEASON = "fieldsense/SeasonReducer/REMOVE_FIELD_FROM_SEASON";

export function fetchSeasons(farmId) {
  return {
    type: FETCH_SEASONS,
    payload: WebAPIUtils.getSeasons(farmId)
  };
}

export function selectSeason(seasonId) {
  return {
    type: SELECT_SEASON,
    payload: seasonId
  };
}

export function createSeason(farmId, name, startDate, endDate) {
  return {
    type: CREATE_SEASON,
    payload: WebAPIUtils.createSeason(farmId, name, startDate, endDate)
      .then(async (id) => {
        let result = await WebAPIUtils.getSeasons(farmId);
        return {seasons: result.seasons, newSeasonId: id};
      })
      .catch((e) => {
        return new Error(e);
      })
  };
}

export function updateSeason(farmId, seasonId, name, startDate, endDate) {
  let body = {
    name: name,
    startDate: startDate,
    endDate: endDate
  };

  return {
    type: UPDATE_SEASON,
    payload: WebAPIUtils.updateSeason(farmId, seasonId, body)
      .then(() => {
      return { seasonId: seasonId, name: name, startDate: startDate, endDate: endDate };
    })
  };
}

export function disableSeason(farmId, seasonId) {
  return {
    type: DISABLE_SEASON,
    payload: WebAPIUtils.disableSeason(farmId, seasonId)
      .then(() => {
        return { seasonId: seasonId };
      })
  };
}

export function enableSeason(farmId, seasonId) {
  return {
    type: ENABLE_SEASON,
    payload: WebAPIUtils.enableSeason(farmId, seasonId)
      .then(() => {
        return { seasonId: seasonId };
      })
  };
}

export function removeFieldFromSeason(fieldId, seasonId) {
  return {
    type: REMOVE_FIELD_FROM_SEASON,
    payload: WebAPIUtils.removeFieldFromSeason(fieldId, seasonId),
    meta: {
      fieldId, seasonId
    }
  };
}

export class SeasonState {
  seasons: Array;
  selectedSeason: Number;
  fieldSeasons: Object
}

const initialState: SeasonState = {
  seasons: [],
  selectedSeason: null,
};

export default function reducer(state: SeasonState = initialState, action) {
  switch (action.type) {
    case FETCH_SEASONS + "_FULFILLED": {
      let seasons = (action.payload && action.payload.seasons) || [];
      return {...state, seasons: seasons.reverse(), selectedSeason: getSelectedSeason(seasons)};
    }

    case SELECT_SEASON: {
      const season = state.seasons.find((s: Season) => s.id === action.payload);
      saveSelectedSeason(season);
      return {...state, selectedSeason: season};
    }

    case CREATE_SEASON + "_FULFILLED": {
      if (action.payload.seasons) {
        return {...state, seasons: action.payload.seasons.reverse()};
      }
      return {...state};
    }

    case DISABLE_SEASON + "_FULFILLED": {
      let newState = {...state, seasons: [...state.seasons.map((season) => ({...season}))]};

      let season = newState.seasons.find((s: Season) => s.id === action.payload.seasonId);
      season.enabled = false;

      let disabledCurrentSeason = newState.selectedSeason && newState.selectedSeason.id === action.payload.seasonId;

      if (disabledCurrentSeason) {
        newState.selectedSeason = getNewestSeason(newState.seasons);
        saveSelectedSeason(newState.selectedSeason);
      }

      return newState;
    }

    case ENABLE_SEASON + "_FULFILLED": {
      let newState = {...state, seasons: [...state.seasons.map((season) => ({...season}))]};
      let season = newState.seasons.find((s: Season) => s.id === action.payload.seasonId);
      season.enabled = true;
      return {...newState};
    }

    case UPDATE_SEASON + "_FULFILLED": {
      let newState = {...state, seasons: [...state.seasons.map((season) => ({...season}))]};
      let season = newState.seasons.find((s: Season) => s.id === action.payload.seasonId);
      season.name = action.payload.name;
      season.startDate = action.payload.startDate;
      season.endDate = action.payload.endDate;

      return {...newState, selectedSeason: getSelectedSeason(newState.seasons)};
    }

    default: {
      return state;
    }
  }
}
