import React, {
  useCallback,
  useState
} from 'react';
import {withStyles} from "@material-ui/core/styles";

import Dialog from '@material-ui/core/Dialog';
import {
  DialogContent,
  DialogActions,
  DialogTitle
} from '@material-ui/core';
import Button from "@material-ui/core/Button/Button";
import {useLangFile} from "js/context/LanguageContext";
import PrescriptionSettings from "js/components/Prescription/Dialogs/PrescriptionSettingsDialog/PrescriptionSettings";
import {usePrescriptionJob} from "js/components/Prescription/PrescriptionJobContext";
import PropTypes from "prop-types";
import {
  MetaTypes
} from "js/components/Prescription/PrescriptionJob";
import Box from "@material-ui/core/Box";
import SelectSeasonFormControl from "../../../UI-Elements/SelectSeasonFormControl";
import {PRESCRIPTION_METERING, PRESCRIPTION_UNIT} from '../../../../constants/PrescriptionConstants';

const styles = (theme) => ({
  content: {
    overflowY: "visible",
  },
  actions: {
    justifyContent: 'space-between'
  },
  '@media (max-width: 1024px), (max-height: 800px)': {
    root: {
      padding: theme.spacing(1),
    },
    content: {
      padding: theme.spacing(1),
    },
    title: {
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    actions: {
      padding: theme.spacing(1),
      margin: 0,
    },
  }
});

const PrescriptionSettingsDialog = ({classes, open, onSetOpen, onExit, exitEnabled}) => {

  const LangFile = useLangFile();
  const {prescriptionJob, setPrescriptionJob} = usePrescriptionJob();

  const updateSetting = (key) => useCallback((value) => {
    setPrescriptionJob({[key]: value});
    if (key === "metaType" && value === MetaTypes.SPOT_SPRAYING) {
      if (prescriptionJob.intervals) {
        let changes = {
          intervals: [...prescriptionJob.intervals.map((interval) => ({...interval, prescription: 0}))],
        };
        setPrescriptionJob(changes, true);
      }
    }
    else if (key === 'unit' && prescriptionJob.metering !== PRESCRIPTION_METERING.TOTAL) {
      if (value === PRESCRIPTION_UNIT.PIECES_M2) {
        setPrescriptionJob({['metering']: PRESCRIPTION_METERING.SQUARE_METER});
      }
      else {
        setPrescriptionJob({['metering']: PRESCRIPTION_METERING.HA});
      }
    }
  }, [prescriptionJob]);

  const [errors, setErrors] = useState({});

  // If there are any errors, disable the submit button
  let disabled = Object
    .keys(errors)
    .map((key) => errors[key])
    .reduce((result, current) => result || current, false);

  const handleOnClose = useCallback(() => {
    onSetOpen(false);
  }, []);

  const handleOnError = useCallback((key, error) => {
    setErrors((current) => ({...current, [key]: error}));
  }, []);

  return (
    <Dialog open={open} fullWidth maxWidth={"lg"} className={classes.root}>
      <DialogTitle className={classes.title}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <span>{LangFile.PrescriptionSettingsDialog.title}</span>
          <SelectSeasonFormControl value={prescriptionJob.seasonId} onChange={updateSetting("seasonId")} fieldId={prescriptionJob.fieldId}/>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <PrescriptionSettings
          maxPrescription={prescriptionJob.maxPrescription}
          metering={prescriptionJob.metering}
          fieldSize={prescriptionJob.fieldSize}
          unit={prescriptionJob.unit}
          jobName={prescriptionJob.jobName}
          metaType={prescriptionJob.metaType}
          onError={handleOnError}
          onUpdateMetering={updateSetting('metering')}
          onUpdateMaxPrescription={updateSetting('maxPrescription')}
          onUpdateJobName={updateSetting('jobName')}
          onUpdateUnit={updateSetting('unit')}
          onUpdateMetaType={updateSetting('metaType')}/>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {exitEnabled ? (
          <Button onClick={onExit} variant={"outlined"}>
            {LangFile.PrescriptionSettingsDialog.exit}
          </Button>
        ) : <span/>}
        <Button onClick={handleOnClose} color="primary" disabled={disabled} variant={"contained"}>
          {LangFile.PrescriptionSettingsDialog.done}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PrescriptionSettingsDialog.propTypes = {
  open: PropTypes.bool,
  exitEnabled: PropTypes.bool,
  onSetOpen: PropTypes.func,
  onExit: PropTypes.func,
};


export default withStyles(styles)(PrescriptionSettingsDialog);
