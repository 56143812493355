// @flow

import React, {memo, useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import Box from "@material-ui/core/Box";
import {usePrescriptionJob} from "js/components/Prescription/PrescriptionJobContext";
import PrescriptionEditor from "js/components/Prescription/PrescriptionEditor";
import {useFarm} from "js/context/AccountContext";
import {
  calculateClassificationIntervalAreas,
  calculateClassificationIntervals,
  calculateIntervalAreas,
  calculateIntervals,
  calculateOverridesArea,
  initializeOverrides
} from "js/components/Prescription/PrescriptionUtils";
import PrescriptionSourceDialog
  from "js/components/Prescription/Dialogs/PrescriptionSourceDialog/PrescriptionSourceDialog";
import PrescriptionSettingsDialog
  from "js/components/Prescription/Dialogs/PrescriptionSettingsDialog/PrescriptionSettingsDialog";
import PrescriptionAutoAdjustDialog
  from "js/components/Prescription/Dialogs/PrescriptionAutoAdjustDialog/PrescriptionAutoAdjustDialog";
import PrescriptionLayerDialog from "./Dialogs/PrescriptionLayerDialog/PrescriptionLayerDialog";
import {useSurveyContext, useSurveyLayerViewCapabilities} from "../../context/SurveyContext";
import {PrescriptionJob} from "./PrescriptionJob";
import {Action, useActionSnackbarContext} from "../ActionSnackbarHandler/ActionSnackbarHandler";
import {useLangFile} from "../../context/LanguageContext";
import {connect} from 'react-redux';
import {getReferenceValues} from '../../reducers/SurveyReducer';
import ViewModeConstants from '../../constants/ViewModeConstants';
import {SATELLITE_LAYERS} from '../../constants/SatelliteLayers';

const validJob = (job: PrescriptionJob) => {
  let valid = true;

  let invalidIntervals = job.intervals.filter((interval) => {
    return Number.isNaN(interval.min) || Number.isNaN(interval.max) || !Number.isFinite(interval.min) || !Number.isFinite(interval.max);
  });

  if (invalidIntervals.length > 0) {
    valid = false;
  }

  return valid;
};

const mapStateToProps = (store) => ({
  selectedSurveyReferenceValues: store.survey.selectedSurveyReferenceValues,
  selectedSurveyClassificationValues: store.survey.selectedSurveyClassificationValues,
});

const PrescriptionEditorContainer = ({dispatch, onExit, onExitSafe, onDownloadJob, selectedField, selectedSurveyReferenceValues, selectedSurveyClassificationValues}) => {

  const farm = useFarm();
  const LangFile = useLangFile();
  const {addAction} = useActionSnackbarContext();
  const {surveys, selectedSurvey} = useSurveyContext();
  const {prescriptionJob, setPrescriptionJob} = usePrescriptionJob();
  const layerCapabilities = useSurveyLayerViewCapabilities(prescriptionJob.layer, ViewModeConstants.PRESCRIPTION);

  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [showSourceDialog, setShowSourceDialog] = useState(false);
  const [showLayerDialog, setShowLayerDialog] = useState(false);
  const [showAutoAdjustDialog, setShowAutoAdjustDialog] = useState(false);
  const [classificationsEnabled, setClassificationsEnabled] = useState(false);
  const [classificationReset, setClassificationReset] = useState(false);

  const handleSelectSourceCategory = useCallback((category) => {
    setPrescriptionJob({category: category});
  }, []);

  const handleSelectLayer = useCallback((layer) => {
    setPrescriptionJob({layer: layer});
  }, []);

  const handleSelectLayerBack = useCallback(() => {
    setPrescriptionJob({layer: null, category: null});
  }, []);

  // When a new job is started or settings are modified.
  useEffect(() => {
    if (prescriptionJob) {
      // We need to select a source layer.
      if (!prescriptionJob.category) {
        setShowSourceDialog(true);
        setShowLayerDialog(false);
        setShowSettingsDialog(false);
      }
      else if (!prescriptionJob.layer) {
        setShowSourceDialog(false);
        setShowLayerDialog(true);
        setShowSettingsDialog(false);
      }
      else if (!prescriptionJob.maxPrescription) {
        setShowSourceDialog(false);
        setShowLayerDialog(false);
        setShowSettingsDialog(true);
      }
    }
  }, [prescriptionJob, showSettingsDialog, showSourceDialog]);

  // Load Values when Layer and Max Prescription is Selected
  useEffect(() => {
    if (prescriptionJob.layer && prescriptionJob.maxPrescription && !prescriptionJob.values) {
      dispatch(getReferenceValues(farm.farmId, prescriptionJob.fieldId, prescriptionJob.date, prescriptionJob.layer, selectedSurvey, prescriptionJob.jobId));
    }
  }, [prescriptionJob, farm, selectedSurvey]);

  useEffect(() => {
    if (!prescriptionJob.values && selectedSurveyReferenceValues[prescriptionJob.layer]) {
      const values = selectedSurveyReferenceValues[prescriptionJob.layer];

      if (values) {
        let newJob = {...prescriptionJob};
        newJob.values = values;
        
        // Here we manually calculate the job from the ground instead of having the reducer recalculate partially
        if (!newJob.overrides) {
          newJob.overrides = initializeOverrides(newJob);
          newJob.overrideAreas = calculateOverridesArea(newJob);
        }

        if (!newJob.intervals) {
          newJob.intervals = calculateIntervals(newJob);
          newJob = calculateIntervalAreas(newJob);
        }

        if (validJob(newJob)) {
          setPrescriptionJob(newJob, false);
        }
        else {
          // this layer cannot produce a valid prescription map
          addAction(new Action("invalid-layer", LangFile.PrescriptionEditorContainer.invalidValues, "error", "filled"));
          setPrescriptionJob({layer: null});
        }
      }
      else {
        // invalid choice
        setPrescriptionJob({source: null, layer: null});
      }
    }
  }, [selectedSurveyReferenceValues]);

  useEffect(() => {
    if (classificationReset && prescriptionJob.values && layerCapabilities && layerCapabilities.enableCustomClassification && selectedSurveyClassificationValues[prescriptionJob.layer]) {
      if (classificationsEnabled) {
        let newJob = {...prescriptionJob, intervals: [], classifications: selectedSurveyClassificationValues[prescriptionJob.layer], layerType: 'FI_DEMAND'};
        newJob.intervals = calculateClassificationIntervals(newJob);
        newJob = calculateClassificationIntervalAreas(newJob);
        if (validJob(newJob)) {
          setPrescriptionJob(newJob, false);
        }
      }
      else {
        let newJob = {...prescriptionJob, intervals: [], classifications: undefined, layerType: undefined};
        newJob.intervals = calculateIntervals(newJob);
        newJob = calculateIntervalAreas(newJob);
        if (validJob(newJob)) {
          setPrescriptionJob(newJob, false);
        }
      }
      setClassificationReset(false);
    }
  }, [classificationsEnabled, classificationReset, selectedSurveyClassificationValues]);

  const enableNdvi = prescriptionJob.assets != null && (prescriptionJob.assets[SATELLITE_LAYERS.VITALITY_NDVI] !== null || prescriptionJob.assets[SATELLITE_LAYERS.VITALITY_NDVI] !== undefined);
  const enableSurveys = Boolean(surveys.find((survey) => survey.fieldId === prescriptionJob.fieldId));

  return (
    <Box
      style={{overflow: "hidden"}}
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      alignItems={"center"}>

      <PrescriptionEditor
        onDownloadJob={onDownloadJob}
        onSetShowSettingsDialog={setShowSettingsDialog}
        onSetShowAutoAdjustDialog={setShowAutoAdjustDialog}
        enableSurveys={enableSurveys}
        selectedField={selectedField}
        enableNdvi={enableNdvi}
        onExitSafe={onExitSafe}
        classificationsEnabled={classificationsEnabled}
        setClassificationsEnabled={setClassificationsEnabled}
        setClassificationsReset={setClassificationReset}/>

      <PrescriptionSourceDialog
        enableSurveys={enableSurveys}
        enableNdvi={enableNdvi}
        open={showSourceDialog}
        onSelectSourceCategory={handleSelectSourceCategory}
        onExit={onExit}/>

      <PrescriptionLayerDialog
        onBack={handleSelectLayerBack}
        open={showLayerDialog}
        selectedCategory={prescriptionJob.category}
        onSelectLayer={handleSelectLayer}/>

      <PrescriptionSettingsDialog
        open={showSettingsDialog}
        onExit={onExit}
        exitEnabled={!(prescriptionJob && prescriptionJob.intervals)}
        onSetOpen={setShowSettingsDialog}/>

      <PrescriptionAutoAdjustDialog
        open={showAutoAdjustDialog}
        onSetOpen={setShowAutoAdjustDialog}/>

    </Box>
  );
};

PrescriptionEditorContainer.propTypes = {
  selectedField: PropTypes.object,
  onExit: PropTypes.func,
  onExitSafe: PropTypes.func,
  onDownloadJob: PropTypes.func,
  selectedSurveyReferenceValues: PropTypes.object,
  selectedSurveyClassificationValues: PropTypes.object
};

export default memo(connect(mapStateToProps)(PrescriptionEditorContainer));
