import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Box,
} from "@material-ui/core";

import {useLangFile} from "js/context/LanguageContext";

const buttonStyle = {
  textTransform: 'initial'
};

const ReplaceStationDialog = ({shown, onClose, onYes, onNo}) => {

  const LangFile = useLangFile();

  return (
    <Dialog open={shown} maxWidth={"sm"} fullWidth onClose={onClose}>
      <DialogTitle>
        <Box textAlign={"center"} width={"100%"}>
          {LangFile.ReplaceStationDialog.title}
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box display={"flex"} flexDirection={"column"} p={4}>
          <Box my={1}>
            <Button variant={"contained"} color={"primary"} size={"large"} fullWidth onClick={onYes} style={buttonStyle}>
              <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                <Box fontWeight={600} fontSize={20}>{LangFile.ReplaceStationDialog.yes}</Box>
                <Box fontWeight={400} fontSize={14}>{LangFile.ReplaceStationDialog.yesDescription}</Box>
              </Grid>
            </Button>
          </Box>

          <Box my={1}>
            <Button variant={"contained"} color={"primary"} size={"large"} fullWidth onClick={onNo} style={buttonStyle}>
              <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                <Box fontWeight={600} fontSize={20}>{LangFile.ReplaceStationDialog.no}</Box>
                <Box fontWeight={400} fontSize={14}>{LangFile.ReplaceStationDialog.noDescription}</Box>
              </Grid>
            </Button>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box display={"flex"} justifyContent={"center"} width={"100%"}>
          <Button variant={"outlined"} onClick={onClose}>
            {LangFile.ReplaceStationDialog.back}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

ReplaceStationDialog.propTypes = {
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
};

export default ReplaceStationDialog;