import {SURVEY_LAYER_CLASSIFICATION_ICONS} from "../../../constants/SurveyLayers";
import React, {memo, useCallback, useState} from "react";
import {Box, Typography} from "@material-ui/core";
import Styles from "../FieldStatisticsContainer.module.less";
import PropTypes from 'prop-types';

const SoilOptixColumnCell = ({stats, onHoverChildren}) => {
  let primary = '-';
  let secondary = '-';
  let icon;
  if (stats.raw) {
    const raw = stats.raw;
    if (raw.mean) {
      primary = `${raw.mean.toFixed(1)}`;
    }

    if (raw.std) {
      secondary = `± ${raw.std.toFixed(1)}`;
    }
  }
  else if (stats.mode) {
    primary = stats.mode.replaceAll("_", " ");
    secondary = null;
  }

  const demand = stats.demand;
  if (demand && demand.mode) {
    icon = SURVEY_LAYER_CLASSIFICATION_ICONS[demand.mode];
  }

  let [showHoverChildren, setShowHoverChildren] = useState(false);

  let onEnter = useCallback(() => setShowHoverChildren(true));
  let onLeave = useCallback(() => setShowHoverChildren(false));

  return (
    <Box display={'flex'} flexDirection={'row'} width={'100%'} alignItems={'center'} position={"relative"} onMouseEnter={onEnter} onMouseLeave={onLeave}>
      <Box flexBasis={'auto'} flexGrow={1.5}/>
      <Box flexBasis={'auto'} flexGrow={7} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        {primary && (
          <Typography className={Styles.title}>{primary}</Typography>
        )}
        {secondary && (
          <Typography className={Styles.subtitle}>{secondary}</Typography>
        )}
      </Box>
      <Box flexBasis={'auto'} flexGrow={1.5}>
        {icon && (
          React.cloneElement(icon, {
            style: {
              fontSize: "16px"
            }
          })
        )}
      </Box>

      {showHoverChildren && onHoverChildren && (
        <Box position={"absolute"} top={0} left={-8} right={-8} bottom={0} className={Styles.shortcutWrapper}>
          {onHoverChildren}
        </Box>
      )}

    </Box>
  );
};

SoilOptixColumnCell.propTypes = {
  stats: PropTypes.object,
  onHoverChildren: PropTypes.object
};

export default memo(
  SoilOptixColumnCell
);
