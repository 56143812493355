import React, {useContext} from "react";

const AccountContext = React.createContext({});

export const AccountProvider = AccountContext.Provider;
export const AccountConsumer = AccountContext.Consumer;

export function useAccount() {
  return useContext(AccountContext);
}

export function useUser() {
  return useAccount().user;
}

export function useIsLoggedIn() {
  return useAccount().loggedIn;
}

export function useFarm() {
  return useAccount().farm;
}

export function useIsAdmin() {
  let {farm} = useAccount();

  if (!farm) {
    return false;
  }

  return farm.role === "admin" || farm.role === "owner";
}