"use strict";

import React, {memo} from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from "prop-types";

const SurplusSuspicious = (props: SurplusSuspicious) => {
  return (
    <SvgIcon
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12">

      <g transform="translate(-0.471 -1015)">
        <g transform="translate(0.471 1015)">
          <g fill="#fff" stroke={props.htmlColor} strokeWidth="1">
            <rect width="12" height="12" stroke="none"/>
            <rect x="0.5" y="0.5" width="11" height="11" fill="none"/>
          </g>
          <g transform="translate(6.115 0.686) rotate(45)" fill={props.htmlColor} stroke={props.htmlColor} strokeWidth="1">
            <rect width="7.515" height="7.515" stroke="none"/>
            <rect x="0.5" y="0.5" width="6.515" height="6.515" fill="none"/>
          </g>
        </g>
      </g>
      
    </SvgIcon>
  );
};

SurplusSuspicious.propTypes = {
  htmlColor: PropTypes.string,
};

SurplusSuspicious.defaultProps = {
  htmlColor: "#09BF01",
};

export default memo(
  SurplusSuspicious
);