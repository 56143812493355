"use strict";

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  let prop = {...props, viewBox: "0 0 1035.74 1042.02"};

  let circleColor = props.backgroundColor || '#2179d0';
  let iconColor = props.color || '#ffffff';

  return (
    <SvgIcon {...prop}>
      <circle style={{fill: circleColor}} cx="526.74" cy="521.34" r="450.49"/>
      <path style={{fill: iconColor}} d="M935.34,665.17H511.79v86H465.94v-86H349.22V434.46H258.88v17h23l24.73,24.73v7.51H281.89l24.73,24.72V516H281.89l24.73,24.73v7.52H281.89L306.62,573v7.52H281.89l24.73,24.73h-161l24.72-24.73H145.64V573l24.72-24.72H145.64v-7.52L170.36,516H145.64v-7.52l24.72-24.72H145.64v-7.51l24.72-24.73h23v-17H118.14V406.52h165.5V376.06h22.55v-63.4H281.53a16,16,0,1,1,0-10.19h69.68a16,16,0,1,1,0,10.19H326.58v63.4h22.55v30.46H545.55V376.06H720.39v30.46h50.26Z"/>
    </SvgIcon>
  );
};
