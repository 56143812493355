"use strict";

import PropTypes from "prop-types";

import React, {
  memo, useCallback, useEffect, useState
} from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import CheckIcon from "@material-ui/icons/Check";
import {Divider, List, Paper, Typography} from "@material-ui/core";
import Styles from "./SelectFarmMenu.module.less";
import SearchBar from "material-ui-search-bar";
import {fuzzyFilter} from "../algorithms/filtering";
import Box from "@material-ui/core/Box";
import {BusinessRounded} from "@material-ui/icons";
import {useLangFile} from "../context/LanguageContext";

const SelectFarmMenu = (props) => {

  const LangFile = useLangFile();

  const [searchText, setSearchText] = useState("");

  const onSearchChange = useCallback((text) => {
    setSearchText(text);
  }, []);

  const onSearchCancel = useCallback(() => {
    setSearchText("");
  }, []);

  useEffect(() => {
    setSearchText("");
  }, [props.anchorEl]);

  if (!props.farm) {
    return null;
  }

  if (!props.otherFarms) {
    return null;
  }

  let renderFarmText = (farm) => {
    let color = props.farm.farmId === farm.farmId ? "primary" : "initial";
    return (
      <Box display={"flex"} flexDirection={"column"}>
        <Typography color={color}>{farm.name || (`${farm.owner.firstName} ${farm.owner.lastName}`)}</Typography>
        <Typography color={color} variant={"caption"}>{farm.owner.firstName} {farm.owner.lastName}</Typography>
        <Typography color={color} variant={"caption"}>{farm.owner.email}</Typography>
      </Box>
    );
  };

  let farmsToShow = props.otherFarms;

  // Filtering by search text
  if (searchText.length > 0) {
    farmsToShow = farmsToShow.filter((farm) => {
      let farmName = farm.name || (`${farm.owner.firstName} ${farm.owner.lastName}`);

      return (
        fuzzyFilter(searchText, `${farmName}`) ||
        fuzzyFilter(searchText, `${farm.owner.email}`) ||
        fuzzyFilter(searchText, `${farm.owner.cvr}`) ||
        fuzzyFilter(searchText, `${farm.farmId}`)
      );
    });
  }

  farmsToShow.sort((a, b) => {
    let farmNameA = a.name || (`${a.owner.firstName} ${a.owner.lastName}`);
    let farmNameB = b.name || (`${b.owner.firstName} ${b.owner.lastName}`);

    if (!farmNameA) {
      return 1;
    }
    if (!farmNameB) {
      return -1;
    }
    if (farmNameA < farmNameB) {
      return -1;
    }
    if (farmNameA > farmNameB) {
      return 1;
    }
    return 0;
  });

  farmsToShow = farmsToShow.map((farm) => (
    <MenuItem
      button
      dense
      value={farm.farmId}
      divider={true}
      key={'farm-' + farm.farmId}
      onClick={() => props.onFarmSelected(farm)}>
      <BusinessRounded style={{marginRight: 16}}/>

      {renderFarmText(farm)}
    </MenuItem>
  ));

  return (
    <List dense>
      <Box position={"relative"} overflow={"visible"}>
        {props.otherFarms.length > 0 && (
          <Paper variant={"outlined"}>
            <SearchBar
              className={Styles.Search}
              cancelOnEscape={true}
              value={searchText}
              placeholder={LangFile.SelectFarmMenu.search}
              onChange={onSearchChange}
              onCancelSearch={onSearchCancel}/>
          </Paper>
        )}

        <MenuItem
          button={true}
          disabled={true}
          dense={true}
          value={props.farm.farmId}
          key={'farm-' + props.farm.farmId}>
          <CheckIcon style={{marginRight: 16}} color={"primary"}/>
          {renderFarmText(props.farm)}
        </MenuItem>

        {props.otherFarms.length > 0 && <Divider light/>}

        {farmsToShow}
      </Box>
    </List>
  );
};

SelectFarmMenu.propTypes = {
  /**
   * Farms to select from.
   */
  otherFarms: PropTypes.array,
  /**
   * The currently selected props.farm.
   */
  farm: PropTypes.object,
  /**
   * Callback function to handle selection of a props.farm.
   * @param {object} props.farm The selected props.farm
   */
  onFarmSelected: PropTypes.func,
};

export default memo(SelectFarmMenu);