import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Dialog, DialogActions,
  DialogContent,
  DialogTitle, IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon, ListItemSecondaryAction,
  ListItemText,
  Paper
} from "@material-ui/core";
import {blue, grey} from "@material-ui/core/colors";
import {getFieldName} from "../../helpers/StateInterpreters";
import {Settings} from "@material-ui/icons";
import {useLangFile} from "../../context/LanguageContext";

const DuplicateFieldsDialog = (props: DuplicateFieldsDialog.propTypes) => {

  const LangFile = useLangFile();

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={props.onClose}>
      <DialogTitle>{LangFile.DuplicateFieldsDialog.title}</DialogTitle>
      <DialogContent>

        <Box mb={2}>{LangFile.DuplicateFieldsDialog.subtitle}</Box>

        <Box bgcolor={grey["300"]} borderRadius={16} padding={2} fontSize={12} color={grey["800"]}>
          <Box>{LangFile.DuplicateFieldsDialog.info1}</Box>
          <Box>{LangFile.DuplicateFieldsDialog.info2}</Box>
        </Box>

        <Box my={2} bgcolor={blue["100"]} borderRadius={16} padding={2} fontSize={12} color={grey["800"]}>
          <Box>{LangFile.DuplicateFieldsDialog.info3}</Box>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"row"}>
          <List style={{width: "100%"}}>
            {props.duplicateFields.map((f, idx) => {

              let fieldSurveys = props.surveys.filter((s) => s.fieldId === f.fieldId);

              let secondaryText = "";
              if (fieldSurveys.length > 0) {
                secondaryText = LangFile.DuplicateFieldsDialog.soilSurveys + ": " + fieldSurveys.length;
              }
              else {
                secondaryText = LangFile.DuplicateFieldsDialog.noSoilSurveys;
              }

              return (
                <Paper key={idx} elevation={2} style={{marginTop: 8}}>
                  <ListItem button key={idx} onClick={() => props.onFieldSelect(f)}>
                    <ListItemIcon>
                      <ListItemAvatar>
                        <span>{idx+1}</span>
                      </ListItemAvatar>
                    </ListItemIcon>
                    <ListItemText primary={getFieldName(f, LangFile)} secondary={secondaryText}/>
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => props.onFieldSettings(f)}>
                        <Settings/>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Paper>

              );
            })}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{LangFile.DuplicateFieldsDialog.close}</Button>
      </DialogActions>
    </Dialog>
  );
};

DuplicateFieldsDialog.propTypes = {
    onClose: PropTypes.func,
    onFieldSelect: PropTypes.func,
    onFieldSettings: PropTypes.func,
    surveys: PropTypes.array,
};

export default memo(
    DuplicateFieldsDialog
);
