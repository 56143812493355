import React, {
  useCallback,
  useState
} from 'react';

import PropTypes from 'prop-types';
import moment from "moment/moment";

import {
  Container,
  Dialog,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  StepContent,
  Stepper,
  Step,
  StepLabel
} from "@material-ui/core";

import SuspendStationAppBar from "js/components/ReplaceStation/Suspend/SuspendStationAppBar";
import WeatherStationSerialInput from "js/components/ReplaceStation/Parts/WeatherStationSerialInput";
import StepQuestionBox from "js/components/ReplaceStation/Parts/StepQuestionBox";
import StepActions from "js/components/ReplaceStation/Parts/StepActions";
import DateTimePicker from "js/components/ReplaceStation/Parts/DateTimePicker";
import LoaderBox from "js/components/ReplaceStation/Parts/LoaderBox";

import {
  useLangFile,
} from "js/context/LanguageContext";

import {grey} from "@material-ui/core/colors";


const SuspendStationStepsDialog = ({station, onCancel, onSuspend, onFinish, loading, error, success}) => {

  const LangFile = useLangFile();

  const [serialInput, setSerialInput] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [dismountDate, setDismountDate] = useState(moment());

  const handleConfirmedChanged = useCallback((e) => setConfirmed(e.target.checked), []);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const handleSuspend = useCallback(() => {
    handleNext();
    onSuspend(station.id, dismountDate);
  }, [station, dismountDate]);

  const validSerial = station.label === serialInput;
  const serialError = serialInput && !validSerial;

  return (
    <Dialog open fullScreen>
      <SuspendStationAppBar onClose={onCancel}/>

      <Container maxWidth={"md"}>
        <Stepper activeStep={activeStep} orientation="vertical">

          {
            /*
              ##########################################################################################################

              STEP ONE

              ##########################################################################################################
             */
          }
          <Step>
            <StepLabel>{LangFile.SuspendStationStepsDialog.stepOne.label}</StepLabel>
            <StepContent>

              <Box color={grey[700]} mt={1}>
                {LangFile.SuspendStationStepsDialog.stepOne.notice}{station.label}
              </Box>

              <Box mt={4} display={"flex"} flexDirection={"column"}>
                <Box color={"secondary.main"} fontWeight={500} fontSize={27}>
                  {LangFile.SuspendStationStepsDialog.stepOne.title}
                </Box>
              </Box>

              <StepQuestionBox
                title={LangFile.SuspendStationStepsDialog.stepOne.question1}/>

              <Box p={2} mt={1}>
                <WeatherStationSerialInput onEntry={setSerialInput} initialValue={serialInput}/>
              </Box>

              <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} p={2} mt={1} color={serialError ? "secondary.main" : "white"}>
                <span>{LangFile.SuspendStationStepsDialog.stepOne.invalidSerial1}</span>
                <span>{LangFile.SuspendStationStepsDialog.stepOne.invalidSerial2}</span>
              </Box>

              <StepActions>
                <Button disabled onClick={handleBack}>
                  {LangFile.SuspendStationStepsDialog.stepOne.back}
                </Button>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  onClick={handleNext}
                  disabled={!validSerial}>
                  {LangFile.SuspendStationStepsDialog.stepOne.next}
                </Button>
              </StepActions>

            </StepContent>
          </Step>

          {
            /*
              ##########################################################################################################

              STEP THREE

              ##########################################################################################################
             */
          }
          <Step>
            <StepLabel>{LangFile.SuspendStationStepsDialog.stepTwo.label}</StepLabel>
            <StepContent>

              <StepQuestionBox
                title={LangFile.SuspendStationStepsDialog.stepTwo.question1}
                label={LangFile.SuspendStationStepsDialog.stepTwo.question1label}/>

              <DateTimePicker
                date={dismountDate}
                setDate={setDismountDate}
                minDate={moment(station.dataStart)}
                maxDate={moment()}
                maxDateMessage={LangFile.SuspendStationStepsDialog.stepTwo.maxDateMessage}/>

              <StepActions>
                <Button onClick={handleBack}>
                  {LangFile.SuspendStationStepsDialog.stepTwo.back}
                </Button>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  onClick={handleNext}>
                  {LangFile.SuspendStationStepsDialog.stepTwo.next}
                </Button>
              </StepActions>

            </StepContent>
          </Step>

          {
            /*
              ##########################################################################################################

              STEP THREE

              ##########################################################################################################
             */
          }
          <Step>
            <StepLabel>{LangFile.SuspendStationStepsDialog.stepThree.label}</StepLabel>
            <StepContent>

              <Box display={"flex"} alignItems={"center"} justifyContent={"center"} p={2} mt={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmed}
                      onChange={handleConfirmedChanged}
                      color={"secondary"}/>
                  }
                  label={LangFile.SuspendStationStepsDialog.stepThree.confirmLabel}/>
              </Box>

              <StepActions>
                <Button onClick={handleBack}>
                  {LangFile.SuspendStationStepsDialog.stepThree.back}
                </Button>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  onClick={handleSuspend}
                  disabled={!confirmed || !validSerial}>
                  {LangFile.SuspendStationStepsDialog.stepThree.suspend}
                </Button>
              </StepActions>

            </StepContent>
          </Step>

          {
            /*
              ##########################################################################################################

              STEP FOUR

              ##########################################################################################################
             */
          }
          <Step>
            <StepLabel error={error}>{error ? LangFile.SuspendStationStepsDialog.stepFour.labelError : LangFile.SuspendStationStepsDialog.stepFour.label}</StepLabel>
            <StepContent>

              <Box display={"flex"} alignItems={"center"} justifyContent={"center"} p={2} mt={1}>
                <LoaderBox
                  loading={loading}
                  success={success}
                  error={error}
                  errorMessage={LangFile.SuspendStationStepsDialog.stepFour.error}
                  successMessage={LangFile.SuspendStationStepsDialog.stepFour.label}/>
              </Box>

              <StepActions>
                <Button onClick={handleBack} disabled={loading || success}>
                  {LangFile.SuspendStationStepsDialog.stepFour.back}
                </Button>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  onClick={onFinish}
                  disabled={!confirmed || !validSerial}>
                  {LangFile.SuspendStationStepsDialog.stepFour.finish}
                </Button>
              </StepActions>

            </StepContent>
          </Step>

        </Stepper>
      </Container>
    </Dialog>
  );
};

SuspendStationStepsDialog.propTypes = {
  replaceType: PropTypes.string,
  station: PropTypes.object,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
};

export default SuspendStationStepsDialog;