"use strict";

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  return (
    <SvgIcon
      {...props}
      width="82.116"
      height="82.116"
      viewBox="0 0 82.116 82.116">

      <g transform="translate(-16 -16)">
        <path
          d="M97.59,16.29a1.369,1.369,0,0,0-1.174-.249L85.958,18.655l-5.022-2.511a1.369,1.369,0,0,0-1.224,0l-4.967,2.484L67.072,16.07a1.37,1.37,0,0,0-.433-.07H61.164a1.369,1.369,0,0,0-.612.145l-7.6,3.8-7.6-3.8A1.368,1.368,0,0,0,44.741,16H39.267a1.368,1.368,0,0,0-.481.087l-10.33,3.874-6.277-3.766a1.369,1.369,0,0,0-1.137-.125l-4.106,1.369a1.369,1.369,0,0,0-.936,1.3V96.748a1.369,1.369,0,0,0,1.369,1.369H96.748a1.369,1.369,0,0,0,1.369-1.369V17.368a1.368,1.368,0,0,0-.526-1.079ZM21.31,18.866l6.3,3.782a1.369,1.369,0,0,0,1.185.108l10.716-4.019h4.9L52.34,22.7a1.369,1.369,0,0,0,1.224,0l7.923-3.961h4.929l8,2.667a1.369,1.369,0,0,0,1.045-.074L80.325,18.9l4.862,2.431a1.37,1.37,0,0,0,.944.1l9.248-2.312V37.9H82.108L78.347,35.39a1.369,1.369,0,0,0-.759-.23H65.27a1.369,1.369,0,0,0-.968.4L61.966,37.9H59.8a1.369,1.369,0,0,0-.968.4l-2.336,2.336H53.519l-5.073-5.074a1.369,1.369,0,0,0-.968-.4H44.741a1.369,1.369,0,0,0-.759.23l-7.453,4.968-3.347-2.231A1.368,1.368,0,0,0,31.915,38L25.91,40.4,22.442,36.93a1.369,1.369,0,0,0-.968-.4H18.737V19.724ZM95.379,40.635v26H88.758L76.652,62.6a1.37,1.37,0,0,0-.433-.07H69.376a1.368,1.368,0,0,0-.508.1l-6.6,2.639H52.151l-2.336-2.336a1.369,1.369,0,0,0-.968-.4H44.741a1.369,1.369,0,0,0-.968.4L40.305,66.4,27.457,61.262a1.368,1.368,0,0,0-.508-.1H21.475a1.369,1.369,0,0,0-.968.4l-1.769,1.769V39.266h2.17l3.7,3.7a1.368,1.368,0,0,0,1.476.3l6.175-2.47,3.507,2.338a1.368,1.368,0,0,0,1.518,0L45.155,37.9h1.756l5.073,5.074a1.369,1.369,0,0,0,.968.4h4.106a1.369,1.369,0,0,0,.968-.4l2.336-2.336h2.17a1.369,1.369,0,0,0,.968-.4L65.837,37.9H77.173L80.934,40.4a1.369,1.369,0,0,0,.759.23ZM18.737,95.379V67.205l3.3-3.3h4.644l13.441,5.377a1.368,1.368,0,0,0,1.476-.3l3.7-3.7H48.28l2.336,2.336a1.369,1.369,0,0,0,.968.4H62.533a1.368,1.368,0,0,0,.508-.1l6.6-2.639H76L88.1,69.305a1.37,1.37,0,0,0,.433.07h6.843v26Z"/>
        <path
          d="M71.054,370.737H67.516L65.179,368.4a1.369,1.369,0,0,0-.968-.4H61.474a1.369,1.369,0,0,0-.968.4L56.4,372.507a1.369,1.369,0,0,0-.256,1.58L60.25,382.3a1.368,1.368,0,0,0,1.224.757h2.737a1.369,1.369,0,0,0,.855-.3l6.843-5.474a1.369,1.369,0,0,0,.514-1.069v-4.106a1.369,1.369,0,0,0-1.369-1.369Zm-1.369,4.817-5.955,4.764H62.32l-3.286-6.573,3.007-3.007h1.6l2.336,2.336a1.369,1.369,0,0,0,.968.4h2.737Z"
          transform="translate(-33.157 -291.781)"/>
        <path
          d="M176.695,338.771a1.369,1.369,0,0,0,1.378-.017l6.843-4.106a1.369,1.369,0,0,0,.264-2.141l-4.106-4.106a1.369,1.369,0,0,0-1.936,0l-2.737,2.737a1.369,1.369,0,0,0-.4.968v5.474a1.369,1.369,0,0,0,.694,1.191Zm2.043-6.1,1.369-1.369,1.9,1.9-3.267,1.96Z"
          transform="translate(-132.628 -258.624)"/>
        <path
          d="M221.541,392.756a1.37,1.37,0,0,0-1.394-.746L209.2,393.379a1.369,1.369,0,0,0-1.129,1.791l2.737,8.212a1.369,1.369,0,0,0,1.779.849l10.949-4.106a1.368,1.368,0,0,0,.744-1.893Zm-8.587,8.412-1.76-5.28,8.331-1.042,1.624,3.249Z"
          transform="translate(-159.153 -311.676)"/>
        <path
          d="M302.234,353.349l4.106-2.737a1.369,1.369,0,0,0,.609-1.139V348.1a1.369,1.369,0,0,0-.861-1.271l-6.843-2.737a1.368,1.368,0,0,0-1.732.659l-1.369,2.737a1.369,1.369,0,0,0,.256,1.58l4.106,4.106a1.369,1.369,0,0,0,1.727.171Zm-3.2-5.515.364-.729,4.541,1.817-2.29,1.527Z"
          transform="translate(-232.099 -271.886)"/>
        <path
          d="M410.3,384.2a1.369,1.369,0,0,0-1.332-.06l-8.212,4.106a1.369,1.369,0,0,0-.483,2.045l4.106,5.474a1.368,1.368,0,0,0,1.528.477l4.106-1.369a1.369,1.369,0,0,0,.936-1.3v-8.212a1.369,1.369,0,0,0-.649-1.164Zm-2.088,8.389-2.234.745-2.53-3.373,4.764-2.382Z"
          transform="translate(-318.307 -305.045)"/>
        <path
          d="M72,328h2.737v2.737H72Z"
          transform="translate(-46.42 -258.624)"/>
        <path
          d="M136,448h2.737v2.737H136Z"
          transform="translate(-99.471 -358.095)"/>
        <path
          d="M176,432h2.737v2.737H176Z"
          transform="translate(-132.628 -344.833)"/>
        <path
          d="M248,352h2.737v2.737H248Z"
          transform="translate(-192.31 -278.519)"/>
        <path
          d="M352,416h2.737v2.737H352Z"
          transform="translate(-278.518 -331.57)"/>
        <path
          d="M392,336h2.737v2.737H392Z"
          transform="translate(-311.675 -265.256)"/>
        <path
          d="M424,352h2.737v2.737H424Z"
          transform="translate(-338.201 -278.519)"/>
        <path
          d="M328,440h2.737v2.737H328Z"
          transform="translate(-258.624 -351.464)"/>
        <path
          d="M64,240h4.106v2.737H64Z"
          transform="translate(-39.788 -185.679)"/>
        <path
          d="M96,192h4.106v2.737H96Z"
          transform="translate(-66.314 -145.891)"/>
        <path
          d="M48,104h4.106v2.737H48Z"
          transform="translate(-26.525 -72.945)"/>
        <path
          d="M280,200h4.106v2.737H280Z"
          transform="translate(-218.836 -152.522)"/>
        <path
          d="M304,232h4.106v2.737H304Z"
          transform="translate(-238.73 -179.048)"/>
        <path
          d="M384,192h4.106v2.737H384Z"
          transform="translate(-305.044 -145.891)"/>
        <path
          d="M400,240h4.106v2.737H400Z"
          transform="translate(-318.307 -185.679)"/>
        <path
          d="M128,240h2.737v2.737H128Z"
          transform="translate(-92.839 -185.679)"/>
        <path
          d="M216,248h2.737v2.737H216Z"
          transform="translate(-165.785 -192.311)"/>
        <path
          d="M344,88h2.737v2.737H344Z"
          transform="translate(-271.887 -59.683)"/>
        <path
          d="M424,64h2.737v2.737H424Z"
          transform="translate(-338.201 -39.788)"/>
        <path
          d="M432,112h2.737v2.737H432Z"
          transform="translate(-344.832 -79.577)"/>
        <path
          d="M208,72h2.737v2.737H208Z"
          transform="translate(-159.153 -46.42)"/>
        <path
          d="M224,112h2.737v2.737H224Z"
          transform="translate(-172.416 -79.577)"/>
        <path
          d="M440,256h2.737v2.737H440Z"
          transform="translate(-351.464 -198.942)"/>
        <path
          d="M168,200h4.106v2.737H168Z"
          transform="translate(-125.996 -152.522)"/>
        <path
          d="M304,64h4.106v2.737H304Z"
          transform="translate(-238.73 -39.788)"/>
        <path
          d="M104.8,70.8h2.737v2.737H104.8Z"
          transform="translate(-73.612 -45.423)"/>
      </g>

    </SvgIcon>
  );
};
