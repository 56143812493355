// @flow

import React, {
  memo, useEffect, useState
} from 'react';

import PropTypes from 'prop-types';
import Box from "@material-ui/core/Box";
import FloatingTempButton from "../../UI-Elements/FloatingTempButton/FloatingTempButton";
import {PRESCRIPTION_UNIT} from "../../../constants/PrescriptionConstants";
import {usePrescriptionJob} from "../PrescriptionJobContext";
import {useLangFile} from '../../../context/LanguageContext';
import {SQUARE_METER_COVERSION_VALUE} from '../PrescriptionUtils';

const PercentageIncrementer = (props: PercentageIncrementer.propTypes) => {
  const LangFile = useLangFile();
  const [basisPrescription, setBasisPrescription] = useState(0);
  const {prescriptionJob} = usePrescriptionJob();
  const {intervals, maxPrescription, fieldSize, unit} = prescriptionJob;

  let tooltipPlacement = props.idx < intervals.length-1 ? "right" : "left";
  let unitString;
  switch (unit) {
    case PRESCRIPTION_UNIT.LITER:
      unitString = `${LangFile.PrescriptionSettings.unit.liters.short}`;
      break;
    case PRESCRIPTION_UNIT.PIECES_M2:
      unitString = `${LangFile.PrescriptionSettings.unit.pieces.short}`;
      break;
    default:
      unitString = `${LangFile.PrescriptionSettings.unit.kilogram.short}`;
      break;
  }

  let percentageChanged = props.interval.prescription / basisPrescription * 100;

  useEffect(() => {
    if (prescriptionJob.unit === PRESCRIPTION_UNIT.PIECES_M2) {
      setBasisPrescription(prescriptionJob.maxPrescription / (prescriptionJob.fieldSize * SQUARE_METER_COVERSION_VALUE));
    }
    else {
      setBasisPrescription(prescriptionJob.maxPrescription / prescriptionJob.fieldSize);
    }
  }, [maxPrescription, fieldSize]);

  return (
    <Box position={"relative"} width={"100%"} height={"100%"}>
      <Box position={"absolute"} top={"8px"} left={"8px"} zIndex={3} style={{pointerEvents: "none"}}>{percentageChanged.toFixed(0)}%</Box>
      <FloatingTempButton icon={"+5%"} onClick={() => props.onIncrementInterval(props.interval, props.idx)} iconColor="green" position="top" tooltip={`+ ${props.stepSize.toFixed(2)} ${unitString}`} tooltipPosition={tooltipPlacement}/>
      <FloatingTempButton icon={"-5%"} onClick={() => props.onDecrementInterval(props.interval, props.idx)} iconColor="red" position="bottom" tooltip={`- ${props.stepSize.toFixed(2)} ${unitString}`} tooltipPosition={tooltipPlacement}/>
    </Box>
  );
};

PercentageIncrementer.propTypes = {
  idx: PropTypes.number,
  interval: PropTypes.object,
  stepSize: PropTypes.number,
  onIncrementInterval: PropTypes.func,
  onDecrementInterval: PropTypes.func,
};

export default memo(PercentageIncrementer);
