import React, {memo, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {useFarm} from "../../context/AccountContext";
import {useHookRef} from "../../hooks/useHookRef";
import AnalysisMapView from "./AnalysisMapView";
import {getLayerConfig} from "../Prescription/PrescriptionUtils";
import {getImagesForDate} from "../../helpers/StateInterpreters";
import {SATELLITE_LAYERS, TIFF_NDRE, TIFF_NDVI} from "../../constants/SatelliteLayers";
import ViewModeConstants from "../../constants/ViewModeConstants";
import {getClassificationValues, getReferenceValues} from '../../reducers/SurveyReducer';
import {useSurveyLayerViewCapabilities} from '../../context/SurveyContext';
import {fromArrayBuffer} from 'geotiff';

const mapStoreToProps = (store) => ({
  // fieldReducer
  fields: store.field.fields,
  showSatelliteImagery: store.field.showSatelliteImagery,
  date: store.field.date,
  images: store.field.images,
  imageType: store.field.imageType,
  selectedField: store.field.selectedField,

  // controlReducer
  viewMode: store.control.viewMode,

  // surveyReducer
  showSoilSurveys: store.survey.showSoilSurveys,
  variationsEnabled: store.survey.variationsEnabled,
  classificationsEnabled: store.survey.classificationsEnabled,
  surveys: store.survey.surveys,
  selectedLayer: store.survey.selectedLayer,
  selectedSurveyReferenceValues: store.survey.selectedSurveyReferenceValues,
  selectedSurveyClassificationValues: store.survey.selectedSurveyClassificationValues,

  // notes
  isDrawingField: store.addField.isDrawing,
  isDrawingNote: store.note.isDrawing,
  isPlacingMarker: store.note.isPlacingMarker,
  isMagicSelecting: store.note.magicWand.isSelecting,
  editNoteLocation: store.note.editNoteLocation
});

const AnalysisMapViewContainer = ({dispatch, ...props}) => {
  const farm = useFarm();
  const farmId = useHookRef(farm.farmId);

  const layer = props.showSatelliteImagery ? props.imageType : props.selectedLayer;
  const surveyLayerViewCapabilities = useSurveyLayerViewCapabilities(props.selectedLayer, props.viewMode);
  const [imageURL, setImageURL] = useState(null);
  const [referenceValues, setReferenceValues] = useState({});

  const getRawValuesFromTiff = (imagePack) => {
    const key = (layer === SATELLITE_LAYERS.VARIATIONS_NDRE || layer === SATELLITE_LAYERS.VITALITY_NDRE) ? TIFF_NDRE : TIFF_NDVI;
    const url = imagePack[key];
    if (url) {
      (async function() {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const tiff = await fromArrayBuffer(arrayBuffer);
        const data = await tiff.readRasters();
        const width = data['width'];
        const values = Array.prototype.slice.call(data[0]);
        const twoDimensionalArray = [];
        while (values.length > 0) {
          twoDimensionalArray.push(values.splice(0, width));
        }
        setReferenceValues({[layer]: twoDimensionalArray});
      })();
    }
  };

  // MARK: This works but because selectedSurveyReferenceValues is being used in other containers it doesn't provide the desired result in the ui.
  // const getSurveyLayerValues = (fieldId, layer, survey) => {
  //   if (isSurveyLayer(layer) && survey) {
  //     WebAPIUtils.getSurveyLayer(farmId.current, survey.surveyId, layer).then((result) => {
  //         setReferenceValues({[layer]: result.values});
  //     });
  //   }
  // };

  useEffect(() => {
    if (props.viewMode !== ViewModeConstants.ANALYSIS) {
      return;
    }
    // Handles initialization and change of satellite imagery.
    if (props.showSatelliteImagery && props.selectedField) {
      let images = getImagesForDate(props.date, props.images, props.imageType);
      let imageMap = new Map(images.map((image) => [image.fieldId, image]));
      let imagePack = imageMap.get(props.selectedField.fieldId);
      if (imagePack) {
        let url = imagePack[props.imageType];
        setImageURL(url);
        getRawValuesFromTiff(imagePack);
      }
      // dispatch(getReferenceValues(farmId.current, props.selectedField.fieldId, props.date, layer));
    }
    // Handles initialization and change of survey based on the available surveys and the selected field.
    else if (props.showSoilSurveys && props.surveys && props.selectedField) {
      let targetSurvey = props.surveys.find((survey) => survey.fieldId === props.selectedField.fieldId);
      if (targetSurvey) {
        let config = getLayerConfig(props.selectedLayer);
        let images = Object.assign(targetSurvey.images, targetSurvey.newImages);
        let url = config.getImageUrl(images, props.classificationsEnabled, props.variationsEnabled, props.viewMode);
        setImageURL(url);
        // getSurveyLayerValues(props.selectedField.fieldId, layer, targetSurvey);
        dispatch(getReferenceValues(farmId.current, props.selectedField.fieldId, props.date, layer, targetSurvey));
        if (props.classificationsEnabled && surveyLayerViewCapabilities.enableCustomClassification) {
          dispatch(getClassificationValues(farmId.current, props.selectedField.fieldId, props.date, layer, targetSurvey));
        }
      }
    }
    else {
      setImageURL(null);
    }
  }, [props.date, props.images, props.imageType, props.showSatelliteImagery, props.selectedField, props.showSoilSurveys, props.surveys, props.selectedField, props.selectedLayer, props.classificationsEnabled, props.variationsEnabled, props.viewMode]);

  useEffect(() => {
      setReferenceValues(props.selectedSurveyReferenceValues);
  }, [props.selectedSurveyReferenceValues]);

  if (props.viewMode !== ViewModeConstants.ANALYSIS) {
    return null;
  }

  let drawingOnMap = Boolean(props.isDrawingNote || props.isDrawingField || props.isMagicSelecting || props.isPlacingMarker || props.editNoteLocation);
  let enableTooltip = Boolean(!drawingOnMap && (props.showSoilSurveys || (props.showSatelliteImagery && props.imageType !== SATELLITE_LAYERS.VISIBLE)));

  return (
    <AnalysisMapView
      clickable={drawingOnMap}
      imageURL={imageURL}
      viewMode={props.viewMode}
      selectedField={props.selectedField}
      selectedLayer={layer}
      onFieldClicked={props.onFieldClicked}
      classificationsEnabled={props.classificationsEnabled}
      variationsEnabled={props.variationsEnabled}
      enableTooltip={enableTooltip}
      values={referenceValues}
      classificationValues={props.selectedSurveyClassificationValues}
      primaryValues={referenceValues[layer]}/>
  );
};

AnalysisMapViewContainer.propTypes = {
  surveys: PropTypes.array,
  selectedLayer: PropTypes.string,
  date: PropTypes.string,
  images: PropTypes.object,
  imageType: PropTypes.string,
  viewMode: PropTypes.string,
  selectedField: PropTypes.object,
  variationsEnabled: PropTypes.bool,
  classificationsEnabled: PropTypes.bool,
  showSatelliteImagery: PropTypes.bool,
  showSoilSurveys: PropTypes.bool,
  onFieldClicked: PropTypes.func,
  isDrawingField: PropTypes.bool,
  isDrawingNote: PropTypes.bool,
  isPlacingMarker: PropTypes.bool,
  isMagicSelecting: PropTypes.bool,
  editNoteLocation: PropTypes.bool,
  selectedSurveyReferenceValues: PropTypes.object,
  selectedSurveyClassificationValues: PropTypes.object
};

export default memo(
  connect(mapStoreToProps)(
    AnalysisMapViewContainer
  )
);
