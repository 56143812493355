import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import {voidFunc} from "../../constants/PropTypeUtils";
import Styles from "./ActionToast.module.less";
import {SnackbarContent} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";

const ActionToast = (props: ActionToast.propTypes) => {
  const hasActions = props.actionTextPrimary || props.actionTextSecondary;
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{root: Styles.Root}}
      open={props.open}
      autoHideDuration={props.autoHideDuration}
      onClose={props.onClose}
      color={"white"}
      ClickAwayListenerProps={{
        mouseEvent: false
      }}>
      <SnackbarContent
        message={
          <Box>
            {props.title && (
              <Typography
                style={{fontWeight:'bold'}}
                variant="body1">
                {props.title}
              </Typography>
            )}
            {props.message && (
              <Typography
                variant="body2">
                {props.message}
              </Typography>
            )}
          </Box>
        }
        classes={{root: Styles.SnackbarContentRoot, action: clsx({[Styles.ActionsHidden]: !hasActions})}}
        action={
          <Box>
            {props.actionTextSecondary && (
              <Button
                classes={{label: Styles.SecondaryAction}}
                size={'small'}
                onClick={props.onSecondaryAction}>
                {props.actionTextSecondary}
              </Button>
            )}
            {props.actionTextPrimary && (
              <Button
                classes={{label: Styles.PrimaryAction}}
                color={'primary'}
                size={'small'}
                onClick={props.onPrimaryAction}>
                {props.actionTextPrimary}
              </Button>
            )}
          </Box>
        }/>
    </Snackbar>
  );
};

ActionToast.propTypes = {
  open: PropTypes.bool,
  onPrimaryAction: PropTypes.func,
  onSecondaryAction: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  actionTextPrimary: PropTypes.string,
  actionTextSecondary: PropTypes.string,
  autoHideDuration: PropTypes.number,
};

ActionToast.defaultProps = {
  onAction: voidFunc,
  onClose: voidFunc,
  autoHideDuration: null,
};

export default memo(
  ActionToast
);
