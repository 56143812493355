"use strict";

export default {
  DANISH: "da",
  GERMAN: "de",
  ENGLISH: "en",
  FINNISH: "fi",
  FRENCH: "fr",
  ESTONIAN: "et",
  SWEDISH: "sv",
  LATVIAN: "lv",
  LITHUANIAN: "lt",
};