// Constants
const a = 6378137.0; // Semi-major axis in meters
const e2 = 0.006694380022900787;
const e = 0.08181919104281579;
const phi0 = 0.9075712110370513; // Math.toRadians(52.0); //52 degrees north of equator = lat 52.0
const lambda0 = 0.17453292519943295; // Math.toRadians(10.0); // 10 degrees east of greenwich = lon 10.0
const X0 = 4321000.0; // Is m - false easting, the eastings value assigned to the natural origin
const Y0 = 3210000.0; // Is m - false northing, the northings value assigned to the natural origin

const sinphi0 = Math.sin(phi0);

const oneE2 = 0.9933056199770992; // 1.0 - e2;
const qp = qsfn(1.0);
const rq = Math.sqrt(0.5 * qp);
const sinb1 = qsfn(sinphi0) / qp;
const cosb1 = Math.sqrt(1.0 - sinb1 * sinb1);
const d = Math.cos(phi0) / (Math.sqrt(1.0 - e2 * sinphi0 * sinphi0) * rq * cosb1);
const ymf = rq / d;
const xmf = rq * d;

/*
 * Optimized implementation of lambert azimuth equal area forward projection,
 * see paper http://www.ec-gis.org/sdi/publist/pdfs/annoni-etal2003eur.pdf, page 125 for details of algorithm.
 *
 * See org.osgeo.proj4j.proj.LambertAzimuthalEqualAreaProjection as origin, which states:
 *      "This file was semi-automatically converted from the public-domain USGS PROJ source."
 */
function lambertAzimuthEqualAreaForwardProjectionRadians(phi, lambda) {
    let lambdaDelta = lambda - lambda0;
    let coslam = Math.cos(lambdaDelta);
    let sinlam = Math.sin(lambdaDelta);
    let sinphi = Math.sin(phi);

    let q = qsfn(sinphi);
    let sinb = q / qp;
    let cosb = Math.sqrt(1.0 - sinb * sinb);
    let b = 1.0 + sinb1 * sinb + cosb1 * cosb * coslam;

    let y = ymf * (b = Math.sqrt(2.0 / b)) * (cosb1 * sinb - sinb1 * cosb * coslam);
    let x = xmf * b * cosb * sinlam;

    // Account for semi-major axis and false easting and northing from origin
    let yNorthing = a * y + Y0;
    let xEasting = a * x + X0;

    return {
        x: xEasting,
        y: yNorthing
    };
}

function qsfn(sinphi) {
    let con = e * sinphi;
    return oneE2 * (sinphi / (1.0 - con * con) - 0.5 / e * Math.log((1.0 - con) / (1.0 + con)));
}

/*
Calculate the unsigned area of a polygon.

Note the result is always a signed value, regardless of the orientation of the coordinates.

Calculation based on the shoelace formula
https://en.wikipedia.org/wiki/Shoelace_formula
*/
function shoelaceFormula(projectedCoords) {
    let n = projectedCoords.length;
    let area = 0.0;

    if (n < 3) {
        throw Error("Polygon size is too small, expected at least 3 coordinates, but got " + n);
    }

    for (let i = 0; i < n; i++) {
        let j = (i +1 ) % n;

        area += projectedCoords[i].x * projectedCoords[j].y;
        area -= projectedCoords[j].x * projectedCoords[i].y;
    }

    return Math.abs(area) / 2.0;
}

/*
 Usage
     var polygon = {
         coords: [
             {lon: 9.664281973895035, lat: 55.77057664225256},
             {lon: 9.665819631730093, lat: 55.77105411642306},
             {lon: 9.665914772126472, lat: 55.77109905847397},
             {lon: 9.66591915520696, lat: 55.77110172983299},
             {lon: 9.664281973895035, lat: 55.77057664225256}
         ]
     }

     var area = calcArea(polygon);
*/
export default (polygon) => {
    let projectedCoords = polygon.coords.map((coord) => {
        let lat = typeof coord.lat === "function" ? coord.lat() : (coord.lat || coord.latitude);
        let lng = typeof coord.lng === "function" ? coord.lng() : (coord.lng || coord.longitude);

        return {
            lat: degreesToRadians(lat),
            lng: degreesToRadians(lng)
        };
    })
    .map((coord) => {
        return lambertAzimuthEqualAreaForwardProjectionRadians(coord.lat, coord.lng);
    });

    let sizeHa = shoelaceFormula(projectedCoords) / 10000;

    return {
        size: sizeHa,
        unit: "ha"
    };
};

function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}
