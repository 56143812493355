import React, {Fragment, memo, useCallback, useState} from 'react';
import {connect} from "react-redux";
import {setAppLanguage} from "../../reducers/LanguageReducer";
import IconButton from "@material-ui/core/IconButton";
import {getLanguageIcon} from "../../helpers/LanguageUtils";
import Menu from "@material-ui/core/Menu";
import LanguageConstants from "../../constants/LanguageConstants";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import {useLanguage} from "../../context/LanguageContext";
import PropTypes from "prop-types";
import {useIsLoggedIn, useUser} from "../../context/AccountContext";
import {updateLanguage} from "../../reducers/SettingsReducer";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../hooks/useFirebaseAnalytics";

const mapStoreToProps = (store) => ({

});

const LANGUAGES = {
  [LanguageConstants.DANISH]: "Dansk",
  [LanguageConstants.GERMAN]: "Deutsch",
  [LanguageConstants.ENGLISH]: "English",
  [LanguageConstants.FRENCH]: "Français",
  [LanguageConstants.ESTONIAN]: "Eestlane",
  [LanguageConstants.FINNISH]: "Suomalainen",
  [LanguageConstants.LITHUANIAN]: "Lietuvis",
  [LanguageConstants.LATVIAN]: "Latvijas",
  [LanguageConstants.SWEDISH]: "Svenska"
};

const LanguageSelectorContainer = ({dispatch, size}) => {

  const analytics = useFirebaseAnalytics();
  const language = useLanguage();
  const loggedIn = useIsLoggedIn();
  const user = useUser();
  const [anchor, setAnchor] = useState(null);

  const handleClick = useCallback((event) => setAnchor(event.currentTarget), []);
  const handleClose = useCallback(() => setAnchor(null), []);

  const handleChangeLanguage = (lang) => useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.MM_LANGUAGE+lang.toUpperCase()); // E.g. MMLanguageEN

    dispatch(setAppLanguage(lang));

    if (loggedIn && user) {
      dispatch(updateLanguage(lang));
    }

    handleClose();
  }, [loggedIn, user]);

  return (
    <Fragment>
      <IconButton onClick={handleClick} size={size}>
        <img src={getLanguageIcon(language)} width={16} height={16}/>
      </IconButton>
      <Menu
        anchorEl={anchor}
        keepMounted={true}
        open={Boolean(anchor)}
        onClose={handleClose}>
        {Object.values(LanguageConstants).map((lang) => (
          <MenuItem key={lang} onClick={handleChangeLanguage(lang)}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <img src={getLanguageIcon(lang)} width={16} height={16}/>
              <Box mr={1}/>
              {LANGUAGES[lang]}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
};

LanguageSelectorContainer.propTypes = {
  size: PropTypes.oneOf(["small", "medium"])
};

LanguageSelectorContainer.defaultProps = {
  size: "small",
};

export default memo(
  connect(mapStoreToProps)(
    LanguageSelectorContainer
  )
);