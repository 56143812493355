import {useEffect, useState} from "react";
import {type ColorStrategy, getColoringStrategy} from "./SurveyColoringStrategies";
import type {FieldLayer} from "../../constants/FieldLayers";

export const useColoringStrategy = (layer: FieldLayer, viewMode: string, classificationsEnabled: boolean, variationsEnabled: boolean, values: Array<Array<number>>): ColorStrategy => {
  const [coloringStrategy, setColoringStrategy] = useState(getColoringStrategy(layer, viewMode, classificationsEnabled, variationsEnabled, values));

  useEffect(() => {
    if (layer) {
      setColoringStrategy(getColoringStrategy(layer, viewMode, classificationsEnabled, variationsEnabled, values));
    }
  }, [layer, viewMode, classificationsEnabled, variationsEnabled, values]);

  return coloringStrategy;
};
