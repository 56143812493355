import React, {
  memo,
  useCallback,
} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import {grey} from "@material-ui/core/colors";

let tinycolor = require("tinycolor2");

const styles = (theme) => ({
  tick: {
    flex: 1,
    width: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: [['auto', 0]],
    position: 'relative',
    boxSizing: 'border-box',
    borderRight: `2px solid white`,
    height: 64,
    pointerEvents: 'all',
    userSelect: 'none',
    cursor: 'pointer',
    '&.hour': {
      borderRightColor: grey[600],
    },
    '&.hovered': {
      backgroundColor: tinycolor(theme.palette.primary.main).lighten(20),
    },
    [theme.breakpoints.down('md')]: {
      borderRight: `1px solid white`,
    }
  },
  tickLabel: {
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translate(50%, -100%) translateY(-4px)',
    userSelect: 'none',
    pointerEvents: 'none',
    fontSize: 12,
    padding: 4,
  },
});

const TimeWindowSliderTick = ({classes, date, index, selected, hovered, onHover, smallScreen, labelEveryHour, onSetRef}) => {

  let isHour = date.minutes() === 0;

  if (smallScreen) {
    isHour = isHour && date.hours() % labelEveryHour === 0;
  }

  const handleHover = useCallback(() => {
    onHover(index);
  }, [index]);

  const handleRef = useCallback((r) => {
    onSetRef(r, index);
  }, [index]);

  return (
    <div
      ref={handleRef}
      onMouseEnter={handleHover}
      className={`
        ${classes.tick}
        ${selected ? 'selected' : ''} 
        ${isHour ? 'hour' : ''} 
        ${hovered ? 'hovered' : ''}`
      }>

      <div className={`${classes.tickLabel}`}>
        {isHour && date.format('HH:mm')}
      </div>
    </div>
  );
};

TimeWindowSliderTick.propTypes = {
  date: PropTypes.object,
  index: PropTypes.number,
  max: PropTypes.number,
  onHover: PropTypes.func,
  onSetRef: PropTypes.func,
  smallScreen: PropTypes.bool,
  isLast: PropTypes.bool,
  hovered: PropTypes.bool,
  selected: PropTypes.bool,
  selectedStart: PropTypes.bool,
  selectedEnd: PropTypes.bool,
  inProximity: PropTypes.bool,
  labelEveryHour: PropTypes.number,
};

export default memo(withStyles(styles)(TimeWindowSliderTick));