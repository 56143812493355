import PropTypes from 'prop-types';
import {voidFunc} from '../../constants/PropTypeUtils';
import React, {memo, useCallback, useRef, useState} from 'react';
import DataIntegrationDialogContent from './DataIntegrationDialogContent';
import WebAPIUtils from '../../WebAPIUtils';
import {Action, useActionSnackbarContext} from '../ActionSnackbarHandler/ActionSnackbarHandler';
import {Dialog, DialogActions, DialogContent} from '@material-ui/core';
import DataDisconnectDialogContent from './DataDisconnectDialogContent';
import {useLangFile} from "../../context/LanguageContext";
import {connect} from "react-redux";
import {getDataIntegrations} from "../../reducers/DataIntegrationsReducer";

const CLAAS_OAUTH_STATE_KEY = 'claas-oauth2-state-key';
const POPUP_HEIGHT = 700;
const POPUP_WIDTH = 600;
const CLAAS_OAUTH_RESPONSE = 'claas-oauth2-response';

const generateState = () => {
  const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let array = new Uint8Array(40);
  window.crypto.getRandomValues(array);
  array = array.map((x: number) => validChars.codePointAt(x % validChars.length));
  return String.fromCharCode.apply(null, array);
};

const saveState = (state) => {
  localStorage.setItem(CLAAS_OAUTH_STATE_KEY, state);
};

const removeState = () => {
  localStorage.removeItem(CLAAS_OAUTH_STATE_KEY);
};

const openPopup = (url) => {
  // To fix issues with window.screen in multi-monitor setups, the easier option is to
  // center the pop-up over the parent window.
  const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
  const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
  return window.open(
    url,
    'OAuth2 Popup',
    `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`
  );
};

const closePopup = (popupRef) => {
  popupRef.current?.close();
};

const cleanup = (
  intervalRef,
  popupRef,
  handleMessageListener
) => {
  clearInterval(intervalRef.current);
  closePopup(popupRef);
  removeState();
  window.removeEventListener('message', handleMessageListener);
};

const enhanceAuthorizeUrl = (
  authorizeUrl,
  clientId,
  redirectUri,
  scope,
  state
) => {
  return `${authorizeUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
};


const mapStateToProps = (store) => {
  return {
    farm: store.farm.farm,
    dataIntegrations: store.integrations.dataIntegrations
  };
};


const DataIntegrationDialogContainer = (props: DataIntegrationDialogContainer.propTypes) => {
  const {open, onClose, dispatch, farm, dataIntegrations} = props;

  const {addAction} = useActionSnackbarContext();
  const LangFile = useLangFile();
  const popupRef = useRef();
  const intervalRef = useRef();
  const [uiState, setUiState] = useState({ loading: false, error: null });
  const [disconnectIntegration, setDisconnectIntegration] = useState(null);

  const onConnect = useCallback((integration) => {
    const state = generateState();
    saveState(state);
    popupRef.current = openPopup(enhanceAuthorizeUrl( integration.baseUrl + '/auth/realms/RestServices/protocol/openid-connect/auth', 'cropline', window.location.origin + '/claasconnect', 'telemetry', state));

    async function handleMessageListener(message) {
      try {
        const type = message && message.data && message.data.type;
        if (type === CLAAS_OAUTH_RESPONSE) {
          const errorMaybe = message && message.data && message.data.error;
          if (errorMaybe) {
            setUiState({
              loading: false,
              error: errorMaybe || LangFile.DataIntegrationDialog.errorUnknown,
            });
          }
          else {
            const code = message && message.data && message.data.payload && message.data.payload.code;
            cleanup(intervalRef, popupRef, handleMessageListener);
            await WebAPIUtils.connectToClaas(farm.farmId, code, window.location.origin + '/claasconnect').then(() => {
              setUiState({
                loading: false,
                error: null,
              });
              addAction(new Action("data-integration-success", `${LangFile.DataIntegrationDialog.connectSuccessTitle} ${integration.name}.`,"success", "success", "filled"));
              dispatch(getDataIntegrations(farm.farmId));
            }).catch((error) => {
              console.log(error);
              setUiState({
                loading: false,
                error: LangFile.DataIntegrationDialog.errorExchangeCode,
              });
            });
          }
        }
      }
      catch (genericError) {
        console.error(genericError);
        setUiState({
          loading: false,
          error: genericError.toString(),
        });
        cleanup(intervalRef, popupRef, handleMessageListener);
      }
    }
    window.addEventListener('message', handleMessageListener);

    // 4. Begin interval to check if popup was closed forcefully by the user
    intervalRef.current = setInterval(() => {
      const popupClosed = !popupRef.current || !popupRef.current.window || popupRef.current.window.closed;
      if (popupClosed) {
        // Popup was closed before completing auth...
        setUiState((state) => ({
          ...state,
          loading: false,
        }));
        console.warn('Warning: Popup was closed before completing authentication.');
        clearInterval(intervalRef.current);
        removeState();
        window.removeEventListener('message', handleMessageListener);
      }
    }, 250);

    // Remove listener(s) on unmount
    return () => {
      window.removeEventListener('message', handleMessageListener);
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [farm]);

  const onDisconnect = useCallback((integration) => {
    async function handleDisconnect() {
      return await WebAPIUtils.disconnectFromClaas(farm.farmId);
    }

    handleDisconnect().then(() => {
      addAction(new Action("data-integration-success", `${LangFile.DataDisconnectDialog.disconnectSuccessTitle} ${integration.name}.`,"success", "success", "filled"));
      setDisconnectIntegration(null);
      dispatch(getDataIntegrations(farm.farmId));
    }).catch((error) => {
      console.log(error);
    });
  }, [farm]);

  const backgroundColor = disconnectIntegration !== null ? '#EFE5E5' : 'white';

  if (uiState.error) {
    console.error(uiState.error);
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"xs"}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: backgroundColor
        },
      }}>
      <DialogContent>
      {disconnectIntegration === null && (
        <DataIntegrationDialogContent
          dataIntegrations={dataIntegrations}
          onConnect={onConnect}
          onDelete={(integration) => {
            setDisconnectIntegration(integration);
          }}
          onClose={onClose}/>
      )}
      {disconnectIntegration && (
        <DataDisconnectDialogContent
          integration={disconnectIntegration}
          onBack={() => {
            setDisconnectIntegration(null);
          }}
          onDisconnect={onDisconnect}/>
      )}
      </DialogContent>
      <DialogActions/>
    </Dialog>
  );
};

DataIntegrationDialogContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

DataIntegrationDialogContainer.defaultProps = {
  onClose: voidFunc
};

export default memo(
  connect(mapStateToProps)(
    DataIntegrationDialogContainer
  )
);
