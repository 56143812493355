"use strict";

import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import withStyles from '@material-ui/core/styles/withStyles';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from "@material-ui/icons/Close";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import {useLangFile} from "js/context/LanguageContext";
import PropTypes from "prop-types";

const styles = (theme) => ({
  root: {
    zIndex: 10,
  },
  icon: {
    fontSize: 20,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    paddingLeft: theme.spacing(2),
  },
});

const Toast = ({classes, shown, onClose, title}) => {

  const LangFile = useLangFile();

  return (
    <Snackbar
      className={clsx({[classes.root]: true})}
      open={shown}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
      <SnackbarContent
        className={classes.content}
        message={
          <div className={classes.message}>
            <CircularProgress variant={"indeterminate"} size={30} color={"primary"}/>
            <div className={classes.text}>
              {title}
            </div>
          </div>
        }
        action={
          <Tooltip title={LangFile.Toast.hide}>
            <IconButton
              key="close"
              aria-label={LangFile.Toast.hide}
              color="inherit"
              className={classes.close}
              onClick={onClose}>
              <CloseIcon className={classes.icon}/>
            </IconButton>
          </Tooltip>
        }>
      </SnackbarContent>
    </Snackbar>
  );
};

Toast.propTypes = {
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.any,
};

export default withStyles(styles)(Toast);