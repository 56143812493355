import React, {memo} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import {DragIndicator} from "@material-ui/icons";
import moment from "moment-timezone";

const styles = (theme) => ({
  root: {
    position: 'absolute',
    zIndex: 10,
    fontSize: 12,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.down('lg')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('md')]: {
      borderLeft: `1px solid white`,
      fontSize: 10,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  date: {
    opacity: 0.7,
    color: 'white',
  },
  drag: {
    top: '50%',
    left: '50%',
  }
});

const TimeWindowSliderThumb = ({classes, wrapper, startTick, endTick, startDate, endDate}) => {

  if (!startTick || !endTick || !wrapper) {
    return null;
  }

  let startRect = startTick.getBoundingClientRect();
  let endRect = endTick.getBoundingClientRect();
  let wrapperRect = wrapper.getBoundingClientRect();

  let height = startRect.height;
  let width = endRect.right - startRect.left;

  let thumbStyle = {
    left: startRect.left - wrapperRect.left,
    top: 0,
    width: width,
    height: height,
  };

  let showDates = width > 40;

  return (
    <div className={classes.root} style={thumbStyle}>
      <div className={classes.wrapper}>
        {showDates && <div className={classes.date}>{moment(startDate).subtract(10, "minutes").format('HH:mm')}</div>}
        <div className={classes.drag}>
          <DragIndicator/>
        </div>
        {showDates && <div className={classes.date}>{moment(endDate).format('HH:mm')}</div>}
      </div>
    </div>
  );
};

TimeWindowSliderThumb.propTypes = {
  startTick: PropTypes.object,
  endTick: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
};

export default memo(withStyles(styles)(TimeWindowSliderThumb));