import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Dropzone from "react-dropzone";
import {green, grey, red} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import {CloudUpload} from "@material-ui/icons";
import {useLangFile} from "../../context/LanguageContext";
import {Box} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: '1 1 auto',
  },
  body: {
    flex: '1 1 auto',
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    margin: [[10, 0]],
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${grey["400"]}`,
    flex: 1,
    height: 250,
  },
  warning: {
    color: red["400"],
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  }
});

const UploadFile = (props) => {

  const {classes, file, fileError, maxSizeKb} = props;
  const LangFile = useLangFile();

  let dropZoneStyle = {
    backgroundColor: grey["100"]
  };

  let fileSize = file ? file.size / 1000 : 0;
  let fileName = file ? file.name : "";

  let noticeText = LangFile.UploadFile.dropZoneHint;

  if (props.file) {
    if (Array.isArray(props.file)) {
      noticeText = (
        <Box display={"flex"} flexDirection={"column"} height={"100%"} width={"100%"} justifyItems={"center"} alignItems={"center"}>
          {props.file.map((file) => {
            let progress = 0;

            if (props.fileProgress) {
              progress = props.fileProgress[file.preview] || 0;
            }

            return (
              <Box key={file.name} display={"flex"} flexDirection={"row"} >
                <Box>{file.name + " (" + Number(file.size/1000).toFixed(2) + " kb)"}</Box>
                <Box color={"primary.main"} pl={2}>{Number(progress).toFixed(0)}%</Box>
              </Box>
            );
          })}
        </Box>
      );
    }
    else {
      noticeText = fileName + " (" + fileSize.toFixed(2) + " kb)";
    }
  }
  let isFileTooBig = fileSize > maxSizeKb; // Kilobytes --> 1 Gb

  let warningText = !isFileTooBig ? null : (
    <div className={classes.warning}>
      {LangFile.UploadFile.fileTooLarge}
    </div>
  );

  let fileErrorText = !fileError ? null : (
    <div className={classes.warning}>
      {fileError}
    </div>
  );

  let valid = file && !isFileTooBig;

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Dropzone
          className={classes.dropzone}
          style={dropZoneStyle}
          accept={props.accept}
          disabled={props.loading}
          disableClick={props.loading}
          onDropRejected={props.onFileRejected}
          activeStyle={{
            backgroundColor: grey["400"]
          }}
          acceptStyle={{
            backgroundColor: green["200"]
          }}
          rejectStyle={{
            backgroundColor: red["200"]
          }}
          onDropAccepted={props.onFileAccepted}
          multiple={props.multiple}
          onDrop={props.onFileChanged}>

          <div>{warningText ? warningText : noticeText}</div>
          <div>{fileErrorText}</div>
        </Dropzone>

        <div className={classes.actions}>
          <div>
            {props.onCancelText ? (
              <Button
                variant={"outlined"}
                disabled={props.loading}
                style={{marginRight: 8, float: 'left'}}
                onClick={props.onCancel}>{props.onCancelText}
              </Button>
            ) : null}
          </div>
          <div>
            {props.onImportText ? (
              <Button
                variant="contained"
                onClick={props.onImportFileSubmit}
                disabled={!valid || props.loading}
                color="primary">
                <CloudUpload style={{marginRight: 5}}/>
                {props.onImportText}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

UploadFile.propTypes = {
  onImportFileSubmit: PropTypes.func,
  onFileChanged: PropTypes.func,
  onFileRejected: PropTypes.func,
  onFileAccepted: PropTypes.func,
  onCancel: PropTypes.func,
  maxSizeKb: PropTypes.number,
  accept: PropTypes.string,
  fileError: PropTypes.string,
  onCancelText: PropTypes.string,
  onImportText: PropTypes.string,
  file: PropTypes.any,
  fileProgress: PropTypes.any,
  multiple: PropTypes.bool,
  loading: PropTypes.bool,
};

UploadFile.defaultProps = {
  maxSizeKb: 100000,
};

export default withStyles(styles)(UploadFile);