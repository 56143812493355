import PropTypes from "prop-types";
import Styles from "./SeasonSelector.module.less";
import {Edit, RadioButtonChecked, RadioButtonUnchecked} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import {formatSeason} from "../../helpers/SeasonUtils";
import IconButton from "@material-ui/core/IconButton";
import {useLangFile} from "../../context/LanguageContext";
import React, {memo, useCallback} from "react";
import moment from "moment";

const SeasonSelectorListItem = (props: SeasonSelectorListItem.propTypes) => {
  const LangFile = useLangFile();

  const handleListItemClick = useCallback(() => {
    props.onClick(props.season.id);
  }, [props.onClick, props.season]);

  const handleEditClick = useCallback((event) => {
    event.stopPropagation();
    props.onEditClick(props.season);
  }, [props.onEditClick, props.season]);


  return (
    <ListItem
      key={props.season.id}
      dense={true}
      button={true}
      className={Styles.ListItem}
      onClick={() => handleListItemClick()}>
      {props.isSelected && (
        <RadioButtonChecked/>
      )}
      {!props.isSelected && (
        <RadioButtonUnchecked/>
      )}
      <Box width={"8px"}/>
      <ListItemText
        classes={{
          root: Styles.ListItemText
        }}
        disableTypography={true}
        secondary={moment(props.season.startDate).format("L") + " - " + moment(props.season.endDate).format("L")}
        primary={<Box fontSize={"0.9rem"}>{formatSeason(props.season, LangFile)}</Box>}/>
      <ListItemSecondaryAction className={Styles.ListItemSecondaryAction}>
        <IconButton
          size={"medium"}
          edge={"end"}
          onClick={handleEditClick}
          disabled={props.disableEdit}
          value={props.season.id}>
          <Edit />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

SeasonSelectorListItem.propTypes = {
  season: PropTypes.object,
  isSelected: PropTypes.bool,
  disableEdit: PropTypes.bool,
  onClick: PropTypes.func,
  onEditClick: PropTypes.func,
};

export default memo(SeasonSelectorListItem);