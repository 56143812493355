"use strict";

import React, {
  memo,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react';

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import MapViewContainer from 'js/components/MapView/MapViewContainer';
import TopBarContainer from './Topbar/TopBarContainer';
import ProgressLoadingModal from './Modals/ProgressLoadingModal';

import NoteDialogContainer from 'js/components/FieldInfo/NoteDialogContainer';
import FarmSettingsContainer from 'js/components/FarmSettings/FarmSettingsContainer';

import GoogleMapsAPIComponent from 'js/components/MapObjects/Utils/GoogleMapsAPIComponent';

import {
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import {connect} from "react-redux";
import {
  authenticateFarm,
  setChangingFarm
} from 'js/reducers/FarmReducer';

import {showProgress} from "js/reducers/ProgressReducer";

import NewsContainer from "js/components/News/NewsContainer";
import MapFloatPanel from "js/components/MapView/MapFloatPanel";
import {PermissionProvider} from "js/context/PermissionContext";
import LoginContainer from "js/components/Login/LoginContainer";
import {startSync} from "js/reducers/SyncReducer";
import CrophealthLoaderContainer from "js/components/Crophealth/CrophealthLoaderContainer";
import {AccountProvider} from "js/context/AccountContext";
import {SettingsProvider} from "js/context/SettingsContext";
import ReplaceStationStepsDialogContainer
  from "js/components/ReplaceStation/Replace/ReplaceStationStepsDialogContainer";
import SuspendStationStepsDialogContainer
  from "js/components/ReplaceStation/Suspend/SuspendStationStepsDialogContainer";
import ViewModeConstants from "js/constants/ViewModeConstants";

import Style from './AppContainer.module.less';
import {useHookRef} from "../hooks/useHookRef";
import ManageCropsContainer from "js/components/ManageCrops/ManageCropsContainer";
import ManageWeatherStationFieldConnectionsContainer from "./ManageWeatherStationFieldConnections/ManageWeatherStationFieldConnectionsContainer";
import {getLanguageFile} from "js/helpers/LanguageUtils";
import SeasonContainer from "js/datacontainers/SeasonContainer";
import Box from "@material-ui/core/Box";
import {DrawerPortalProvider} from "js/context/DrawerPortalContext";
import AppSettingsContainer from "./AppSettings/AppSettingsContainer";
import LanguageContainer from "js/components/LanguageContainer/LanguageContainer";
import AnalyticsContainer from "./Analytics/AnalyticsContainer";
import PrescriptionContainer from "./PrescriptionArchive/PrescriptionContainer";
import ActionSnackbarHandler from "./ActionSnackbarHandler/ActionSnackbarHandler";
import FieldContainer from "../datacontainers/FieldContainer";
import SurveyContainer from "../datacontainers/SurveyContainer";
import SurveyDataDumper from "./SurveyDataDumper/SurveyDataDumper";
import FieldStatisticsContainer from './FieldStatistics/FieldStatisticsContainer';
import DataIntegrationsContainer from "./DataIntegration/DataIntegrationsContainer";

const mapStoreToProps = (store) => ({
  user: store.user.user,
  userIsLoggedIn: store.credential.loggedIn,
  language: store.language.language,
  date: store.field.date,
  viewMode: store.control.viewMode,
  fields: store.field.fields,
  weatherStations: store.weather.stations,
  farm: store.farm.farm,
  otherFarms: store.farm.otherFarms,
  progressLoader: store.progress,
  permissions: store.permissions,
  settings: store.settings,
  keyboardShortcutsEnabled: store.control.keyboardShortcutsEnabled,
  accumulation: store.weather.accumulation,
  appSettings: store.appSettings
});

const AppContainer = ({dispatch, ...props}) => {

  const userRef = useHookRef(props.user);
  const farmRef = useHookRef(props.farm);
  const accumulation = useHookRef(props.accumulation);
  const localLangRef = useHookRef(props.language);
  const measureSettings = props.appSettings.measures; // cant use the hook here, since AppContainer is a parent of AppSettingsContainer

  const drawerContainer = useRef();
  const [drawerPinned, setDrawerPinned] = useState(null);

  useEffect(() => {
    let user = userRef.current;
    let farm = farmRef.current;
    let lang = userRef.current.language || localLangRef.current;

    if (props.userIsLoggedIn) {
      dispatch(startSync(user.id, farm.farmId, lang, true, true, accumulation.current, measureSettings));
    }
  }, [props.userIsLoggedIn, measureSettings]);

  const handleChangeFarm = useCallback((newFarm) => {
    let lang = userRef.current.language || localLangRef.current;
    let LangFile = getLanguageFile(lang);

    dispatch(setChangingFarm(true));
    dispatch(showProgress(LangFile.AppContainer.changingAccount));
    dispatch(authenticateFarm(newFarm)).then(() => {
      dispatch(startSync(userRef.current.id, newFarm.farmId, lang, true, true, accumulation.current, measureSettings)).then(() => {
        dispatch(setChangingFarm(false));
      });
    });
  }, [measureSettings]);

  let appContent = props.userIsLoggedIn && (
    <div className={Style.AbsoluteWrapper}>
        <CrophealthLoaderContainer/>

        <div className={Style.Content}>

          <MapFloatPanel zIndex={2} top={0} left={0} right={0}>
            <TopBarContainer changeFarm={handleChangeFarm}/>
          </MapFloatPanel>

          <FarmSettingsContainer changeFarm={handleChangeFarm}/>

          <NoteDialogContainer/>
          <NewsContainer />
          <ReplaceStationStepsDialogContainer/>
          <SuspendStationStepsDialogContainer/>

          {(props.viewMode === ViewModeConstants.PRESCRIPTION || props.viewMode === ViewModeConstants.PRESCRIPTION_ARCHIVE) && (
            <PrescriptionContainer/>
          )}

          {props.viewMode === ViewModeConstants.FIELD_STATISTICS && (
            <FieldStatisticsContainer/>
          )}

          {props.viewMode === ViewModeConstants.MANAGE_CROPS && (
            <ManageCropsContainer/>
          )}

          {props.viewMode === ViewModeConstants.MANAGE_WEATHER_STATION_FIELD_CONNECTIONS && (
            <ManageWeatherStationFieldConnectionsContainer/>
          )}


        </div>
    </div>
  );
  return (
    <LanguageContainer>
      <AppSettingsContainer>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={props.language}>
          <ActionSnackbarHandler>
            <PermissionProvider value={props.permissions}>
              <SettingsProvider value={props.settings}>
                <AccountProvider value={{farm: props.farm, user: props.user, loggedIn: props.userIsLoggedIn}}>
                  <SeasonContainer>
                    <DataIntegrationsContainer/>

                    <FieldContainer>
                      <SurveyContainer>
                        <div className={Style.Root}>

                          <AnalyticsContainer />

                          <SurveyDataDumper />

                          <ProgressLoadingModal progress={props.progressLoader}/>

                          <Box width={'100vw'} height={"100vh"} display={"flex"} flexDirection={"row"}>
                            <Box ref={drawerContainer} width={drawerPinned ? 280 : 0} height={"100vh"}>

                            </Box>
                            <Box display={"relative"} flex={1} height={"100vh"}>
                              <DrawerPortalProvider value={{container: drawerContainer.current, drawerPinned, setDrawerPinned}}>
                                <MapViewContainer/>

                                {appContent}

                              </DrawerPortalProvider>
                            </Box>
                          </Box>

                          <Switch>
                            <Route
                              path="/dashboard/login"
                              component={() => props.userIsLoggedIn ? <Redirect to="/dashboard"/> : <LoginContainer/>}/>
                            {!props.userIsLoggedIn && <Redirect to="/dashboard/login"/>}
                          </Switch>
                        </div>
                      </SurveyContainer>
                    </FieldContainer>
                  </SeasonContainer>
                </AccountProvider>
              </SettingsProvider>
            </PermissionProvider>
          </ActionSnackbarHandler>
        </MuiPickersUtilsProvider>
      </AppSettingsContainer>
    </LanguageContainer>
  );
};

export default memo(
  GoogleMapsAPIComponent({})(
    connect(mapStoreToProps)(
      AppContainer
    )
  )
);
