import React, {useRef} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import {useGraph} from "js/context/GraphContext";

const styles = (theme) => ({
  root: {}
});

const GraphLegend = ({classes, entries, iconSize, fontSize}) => {

  const {margin, height} = useGraph();
  const group = useRef(null);

  const getPreviousText = (index) => {
    return entries.slice(0, index).reduce((acc, e) => {
      let length = e.text.length;

      // this is a manual buffer for an incorrect assumption that one text character is {fontSize}px wide.
      if (length >= fontSize) {
        length += 4;
      }

      return acc + length;
    }, 0);
  };

  let iconY = height + margin.bottom/1.5;
  let textY = height + margin.bottom/1.5 + iconSize / 2;
  let marginX = margin.left - 30;

  return (
    <g className={classes.root} ref={group}>
      {entries.map((entry, i) => {

        let previousText = getPreviousText(i);
        let textWidth = previousText * fontSize / 2;
        let iconOffset = i * iconSize;
        let iconPadding = i === 0 ? 0 : (i * 2 * 8);

        let textIconOffset = (1 + i) * iconSize;
        let textPadding = i === 0 ? 8 : 8 + (i * 2 * 8);

        let iconX = marginX + textWidth + iconOffset + iconPadding;
        let textX = marginX + textIconOffset + textWidth + textPadding;

        return (
          <g key={`legend-${i}`}>
            <rect
              x={iconX}
              y={iconY}
              fill={entry.color}
              width={iconSize}
              height={iconSize}/>

            <text
              x={textX}
              y={textY}
              textAnchor={"left"}
              dy={".4em"}
              fontSize={fontSize}
              fontFamily={"Roboto, Sans-Serif"}
              fill={entry.color}>{entry.text}</text>
          </g>
        );
      })}
    </g>
  );
};

GraphLegend.propTypes = {
  entries: PropTypes.array
};

GraphLegend.defaultProps = {
  iconSize: 10,
  fontSize: 12,
};

export default withStyles(styles)(GraphLegend);