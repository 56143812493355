import {
  SURVEY_CLAY_COLOR_INTERVALS,
  SURVEY_HUMUS_COLOR_INTERVALS,
  SURVEY_LAYER_CLASSIFICATION_COLORS,
  SURVEY_LAYER_CLASSIFICATION_KEYS,
  SURVEY_LAYER_CLASSIFICATIONS,
  SURVEY_LAYER_COLORS,
  SURVEY_LAYER_SOIL_TYPE_COLORS,
  SURVEY_LAYERS,
} from "../../constants/SurveyLayers";
import ViewModeConstants from "../../constants/ViewModeConstants";
import {getLayerConfig} from "../../components/Prescription/PrescriptionUtils";
import NumberUtils from "../../helpers/NumberUtils";

import lerp from "@sunify/lerp-color";
import {getMinMaxValues, getSourceCategory} from "../../helpers/SurveyUtils";
import {SOURCE_CATEGORY} from "../../components/Prescription/PrescriptionJob";
import type {FieldLayer} from "../../constants/FieldLayers";

const tinycolor = require("tinycolor2");
const soilTypesFI = require("./locales/soil-types-intervals-fi.json");
/**
 * Constant defining how many buckets we will pre-generate
 * for the color scales used in the "raw" and the "normalized" images.
 * @type {number}
 */
export const BUCKET_COUNT = 7;

export interface ColorStrategy {
  isDiscrete: boolean;
  tooltipDecimals: number;
  getColor(val: number): string;
  getBucket(val: number): Object;
  scale: Array<{ normVal: number, val: number, color: string, index: number }>;
}

export function RainbowColoringStrategy(layer: FieldLayer): ColorStrategy {
  this.config = getLayerConfig(layer);

  this.getColor = (val) => {
    // Normalize the raw value to percentages between 0 and 1, since this is how the color scale is defined
    let normVal = this.config.mapValue(val, 0, 1);
    // Find the bucket after the one actually containing the value to use the upper-bound color.
    let bucket = this.scale.find((bucket) => normVal <= bucket.min/100);
    // Return the bucket color
    return bucket.color;
  };

  this.tooltipDecimals = 0;
  this.scale = [
    {min: 0, max: 10, color: "#020058", contrast: "#FFFFFF", index: 0},
    {min: 10, max: 20, color: "#0F0B9C", contrast: "#FFFFFF", index: 1},
    {min: 20, max: 30, color: "#0029FF", contrast: "#000000", index: 2},
    {min: 30, max: 40, color: "#00FFFF", contrast: "#000000", index: 3},
    {min: 40, max: 50, color: "#00C445", contrast: "#000000", index: 4},
    {min: 50, max: 60, color: "#0DB300", contrast: "#000000", index: 5},
    {min: 60, max: 70, color: "#D5F200", contrast: "#000000", index: 6},
    {min: 70, max: 80, color: "#FFEE00", contrast: "#000000", index: 7},
    {min: 80, max: 90, color: "#FF6100", contrast: "#000000", index: 8},
    {min: 90, max: 100, color: "#FF0E00", contrast: "#000000", index: 9},
    {min: 100, max: Number.MAX_VALUE, color: "#840000", contrast: "#FFFFFF", index: 10}
  ];
}

export function SoilTypeColoringStrategy(): ColorStrategy {
  this.getColor = (val) => {
    let jb = Number(val).toFixed(0);
    let col = SURVEY_LAYER_SOIL_TYPE_COLORS[jb];
    return col || "black";
  };

  this.tooltipDecimals = 0;
  this.scale = [...Object.keys(SURVEY_LAYER_SOIL_TYPE_COLORS)].map((jb, idx) => {
    let color = this.getColor(jb);
    return {
      min: jb,
      max: jb,
      color: color,
      contrast: tinycolor(color).isDark() ? 'white' : 'black',
      index: idx
    };
  });
}


export function SoilTypeColoringStrategyFI(): ColorStrategy {
  this.soilTypes = soilTypesFI;
  this.classifications = this.soilTypes.reduce((acc, cur) => ({...acc, [cur.soilType]: cur}), {});
  this.getColor = (val) => {
    let st = Number(val).toFixed(0);
    let classification = this.classifications[st];
    if (classification) {
      return classification.color;
    }
    return "black";
  };
  this.getLabel = (val, LangFile) => {
    let st = Number(val).toFixed(0);
    let classification = this.classifications[st];

    if (classification) {
      return classification.label;
    }
  };

  this.tooltipDecimals = 0;
  this.scale = this.soilTypes.map((classification, idx) => {
    let color = classification.color;
    return {
      min: classification.soilType,
      max: classification.soilType,
      color: color,
      label: classification.label,
      getLabel: (LangFile) => classification.label,
      contrast: tinycolor(color).isDark() ? 'white' : 'black',
      index: idx
    };
  });
}

export function HumusColoringStrategy(): ColorStrategy {
  return new DiscreteColoringStrategy(SURVEY_HUMUS_COLOR_INTERVALS);
}

export function ClayColoringStrategy(): ColorStrategy {
  return new DiscreteColoringStrategy(SURVEY_CLAY_COLOR_INTERVALS);
}

export function DiscreteColoringStrategy(colorIntervals): ColorStrategy {
  this.isDiscrete = true;
  this.tooltipDecimals = 1;

  this.getBucket = (val: Number) => {
    return this.scale.find((bucket) => val >= bucket.min && val < bucket.max);
  };
  this.getColor = (val: Number) => {
    return this.getBucket(val).color;
  };

  this.scale = Object.keys(colorIntervals).map((color, idx) => {
    let interval = colorIntervals[color];

    return {
      min: interval[0],
      max: interval[1],
      color: color,
      contrast: tinycolor(color).isDark() ? 'white' : 'black',
      index: idx
    };
  });
}


export function ClassificationColoringStrategy(layer: FieldLayer, viewMode: string): ColorStrategy {
  this.config = getLayerConfig(layer);
  this.tooltipDecimals = 2;

  this.classifier = (value) => {
    let classifications = SURVEY_LAYER_CLASSIFICATIONS[layer];

    if (!classifications) {
      return null;
    }

    if (viewMode === ViewModeConstants.OVERVIEW) {
      // Use 3 classes
      let middle = classifications[SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK];
      if (value < middle[0]) {
        return SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_LARGE;
      }
      else if (value >= middle[1]) {
        return SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SUSPICIOUS;
      }
      else {
        return SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK;
      }
    }
    else {
      // Use all classes
      let result = null;
      Object.keys(classifications).forEach((key) => {
        let minMax = classifications[key];
        if (minMax.length > 0) {
          if (value >= minMax[0] && value < minMax[1]) {
            result = key;
          }
        }
      });

      return result;
    }
  };

  this.getColor = (val) => {
    let classification = this.classifier(val);
    return SURVEY_LAYER_CLASSIFICATION_COLORS[classification];
  };
}

export function VariationsColoringStrategy(layer: FieldLayer, min: number, max: number, defaultStrategy: ColorStrategy): ColorStrategy {
  this.config = getLayerConfig(layer);
  this.mapper = (val) => NumberUtils.map(val, min, max, 0, 1);
  this.tooltipDecimals = 2;

  // divide the field span into buckets with equal size
  let buckets = defaultStrategy.scale.length;
  let span = max - min;
  let bucketSize = span / buckets;

  // map the default scale and keep everything but the min and max values (colors etc).
  this.scale = defaultStrategy.scale.map((bucket, idx) => {
    let bucketMin = Math.max(min + idx * bucketSize, min);
    let bucketMax = Math.min(bucketMin + bucketSize, max);
    
    return {
      ...bucket,
      min: bucketMin,
      max: bucketMax
    };
  });

  this.getColor = (val) => {
    if (val >= min && val <= max) {
      // Normalize the raw value to the field min-max range (local variation).
      let fieldNormVal = this.mapper(val);
      // Then map the normalized value to the default value range.
      let normVal = this.config.reverseMapValue(fieldNormVal, 0, 1);
      // Delegate coloring to the default coloring strategy.
      return defaultStrategy.getColor(normVal);
    }
  };
}

export function DefaultColoringStrategy(layer: FieldLayer): ColorStrategy {
  this.config = getLayerConfig(layer);
  this.baseColor = tinycolor("#FFFFFF");
  this.primaryColor = tinycolor(SURVEY_LAYER_COLORS[layer]);
  this.tooltipDecimals = 2;

  // Pre-generate the color scale buckets
  this.scale = [...Array(BUCKET_COUNT - 1)].map((_, i, arr) => {
    let isNotLast = i < arr.length;
    // Determine interval
    let val = this.config.reverseMapValue(i, 0, BUCKET_COUNT);
    let nextVal = isNotLast ? this.config.reverseMapValue(i+1, 0, BUCKET_COUNT) : val;
    // Normalize interval
    let normVal = this.config.mapValue(val, 0, 1);
    let normNextVal = this.config.mapValue(nextVal, 0, 1);
    // Determine color and contrast color
    let col = lerp(this.baseColor.toString(), this.primaryColor.toString(), normVal);
    let contrast = tinycolor(col).isDark() ? 'white' : 'black';
    // Pack it up
    return {
      normVal: normVal,
      normNextVal: normNextVal,
      min: val,
      max: nextVal,
      color: col,
      contrast,
      index: i
    };
  }).concat({
    normVal: 1.0,
    normNextVal: 1.0,
    min: this.config.maxValue,
    max: Number.MAX_VALUE,
    color: this.primaryColor.toString(),
    contrast: this.primaryColor.isDark() ? 'white' : 'black'
  });
  this.getBucket = (val: Number) => {
    return this.scale.find((bucket) => val <= bucket.normVal);
  };
  this.getColor = (val: number) => {
    // Normalize the raw value to percentages between 0 and 1, since this is how the color scale is defined
    let normVal = this.config.mapValue(val, 0, 1);
    // Find the bucket after the one actually containing the value to use the upper-bound color.
    let bucket = this.getBucket(normVal);
    // Return the bucket color
    return bucket.color;
  };
}

export const getColoringStrategy = (layer: FieldLayer, viewMode: string, classificationsEnabled: boolean, variationsEnabled: boolean, values: Array<Array<number>>): ColorStrategy => {
  let category = getSourceCategory(layer);
  let defaultStrategy = new DefaultColoringStrategy(layer);

  try {
    if (layer === SURVEY_LAYERS.JB) {
      return new SoilTypeColoringStrategy();
    }
    if (layer === SURVEY_LAYERS.FI_SOIL_CLASS) {
      return new SoilTypeColoringStrategyFI();
    }

    if (category === SOURCE_CATEGORY.NUTRIENT) {
      if (classificationsEnabled) {
        return new ClassificationColoringStrategy(layer, viewMode);
      }
    }

    if (category === SOURCE_CATEGORY.TEXTURE) {
      if (layer === SURVEY_LAYERS.HUMUS) {
        defaultStrategy = new HumusColoringStrategy(layer);
      }
      else if (layer === SURVEY_LAYERS.CLAY) {
        defaultStrategy = new ClayColoringStrategy(layer);
      }
      else {
        defaultStrategy = new RainbowColoringStrategy(layer);
      }
    }

    if (category === SOURCE_CATEGORY.TOPOGRAPHY) {
      defaultStrategy = new RainbowColoringStrategy(layer);
    }

    if (variationsEnabled) {
      if (!values) {
        return defaultStrategy;
      }

      let {min, max} = getMinMaxValues(values);
      return new VariationsColoringStrategy(layer, min, max, defaultStrategy);

    }
    else return defaultStrategy;
  }
  catch (e) {
    console.error("Failed to create color strategy for layer", layer);
    console.error(e);
  }

  return defaultStrategy;
};