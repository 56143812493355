import React, {
  Fragment,
  memo,
  useCallback,
  useState
} from 'react';
import RainInfoSettingsDialog from "./RainInfoSettingsDialog";
import InfoSettingsButton from "../InfoSettingsButton/InfoSettingsButton";
import PropTypes from "prop-types";

const RainInfoSettings = (props: RainInfoSettings.propTypes) => {

  const [shown, setShown] = useState(false);

  const toggleShown = useCallback(() => {
    setShown(!shown);
  }, [shown]);

  return (
    <Fragment>
      <InfoSettingsButton onClick={toggleShown}/>
      <RainInfoSettingsDialog
        shown={shown}
        onClose={toggleShown}
        accumulation={props.accumulation}
        onChangeAccumulation={props.onAccumulationChanged}/>
    </Fragment>
  );
};

RainInfoSettings.propTypes = {
  accumulation: PropTypes.string,
  onAccumulationChanged: PropTypes.func,
};

export default memo(RainInfoSettings);