"use strict";

import React from "react";
import Cloudy from "js/components/Icons/Weather/LightConditions/Cloudy";
import Overcast from "js/components/Icons/Weather/LightConditions/Overcast";
import Unknown from "@material-ui/icons/HelpOutline";
import MostlyCloudy from '../components/Icons/Weather/LightConditions/MostlyCloudy';
import Clear from '../components/Icons/Weather/LightConditions/Clear';
import MostlyClear from '../components/Icons/Weather/LightConditions/MostlyClear';
import PartlyClear from '../components/Icons/Weather/LightConditions/PartlyClear';

export const LIGHT_CONDITION = {
  CLEAR: {
    icon: <Clear />,
    text: (langFile) => langFile.LightCondition.clear
  },
  MOSTLY_CLEAR: {
    icon: <MostlyClear />,
    text: (langFile) => langFile.LightCondition.mostly_clear
  },
  PARTLY_CLEAR: {
    icon: <PartlyClear />,
    text: (langFile) => langFile.LightCondition.partly_clear
  },
  MOSTLY_CLOUDY: {
    icon: <MostlyCloudy />,
    text: (langFile) => langFile.LightCondition.mostly_cloudy
  },
  CLOUDY: {
    icon: <Cloudy />,
    text: (langFile) => langFile.LightCondition.cloudy
  },
  OVERCAST: {
    icon: <Overcast />,
    text: (langFile) => langFile.LightCondition.overcast
  },
  UNKNOWN: {
    icon: <Unknown />,
    text: (langFile) => langFile.LightCondition.unknown
  }
};

export default class LightCondition {
  static fromCloudCover = (cloudCover) => {
    if (cloudCover === null || cloudCover === undefined) {
      return LIGHT_CONDITION.UNKNOWN;
    }

    if (cloudCover >= 0 && cloudCover < 20) {
      return LIGHT_CONDITION.MOSTLY_CLOUDY;
    }
    else if (cloudCover >= 20 && cloudCover < 30) {
      return LIGHT_CONDITION.MOSTLY_CLEAR;
    }
    else if (cloudCover >= 30 && cloudCover < 60) {
      return LIGHT_CONDITION.PARTLY_CLEAR;
    }
    else if (cloudCover >= 60 && cloudCover < 70) {
      return LIGHT_CONDITION.MOSTLY_CLOUDY;
    }
    else if (cloudCover >= 70 && cloudCover < 90) {
      return LIGHT_CONDITION.CLOUDY;
    }
    else if (cloudCover >= 90 && cloudCover <= 100) {
      return LIGHT_CONDITION.OVERCAST;
    }
    return null;
  }
}