import React, {useContext} from "react";
import Season from "js/model/Season";

const SeasonContext = React.createContext({});

export const SeasonProvider = SeasonContext.Provider;
export const SeasonConsumer = SeasonContext.Consumer;

export function useSeasonContext(): {seasons: Array<Season>, selectedSeason: Season} {
  return useContext(SeasonContext);
}

export function useSelectedSeason(): Season {
  let state = useSeasonContext();
  return state && state.selectedSeason;
}