import React, {
  memo,
  useCallback,
  useState
} from 'react';
import {connect} from 'react-redux';

import ManageWeatherStationFieldConnections from "./ManageWeatherStationFieldConnections";
import FullScreenPortalPaper from "../Prescription/FullScreenPortalPaper";
import {saveViewport, setViewMode} from "../../reducers/ControlReducer";
import ViewModeConstants from "../../constants/ViewModeConstants";
import {connectFieldsToWeatherStation} from "../../reducers/FieldReducer";

const mapStateToProps = (store) => {
  return {
    selectedStation: store.weather.selectedStation,
    fields: store.field.fields,
    previousViewport: store.control.previousViewport
  };
};

const ManageWeatherStationFieldConnectionsContainer = ({dispatch, selectedStation, fields, previousViewport}) => {

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(undefined);

  const onClose = useCallback(() => {
    dispatch(setViewMode(ViewModeConstants.OVERVIEW));
    dispatch(saveViewport(null, null));
  });

  const onConnectFields = useCallback((connectedIds) => {
    setSaving(true);
    setSaved(false);
    setError(undefined);

    dispatch(connectFieldsToWeatherStation(connectedIds, selectedStation.id))
      .then(() => {
        setSaving(false);
        setSaved(true);
      })
      .catch((e) => {
        console.error(e);

        setError(e);
        setSaving(false);
        setSaved(false);
      });
  }, [selectedStation]);

  return (
    <FullScreenPortalPaper>
      <ManageWeatherStationFieldConnections
        saved={saved}
        saving={saving}
        setSaved={setSaved}
        onClose={onClose}
        onConnectFields={onConnectFields}
        weatherStation={selectedStation}
        connectError={error}
        previousViewport={previousViewport}
        fields={fields}/>
    </FullScreenPortalPaper>
  );
};


export default memo(connect(mapStateToProps)(ManageWeatherStationFieldConnectionsContainer));
