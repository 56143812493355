import React, {
  useCallback,
} from 'react';
import {withStyles} from "@material-ui/core/styles";

import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import {
  Link, Error,
} from "@material-ui/icons";
import moment from "moment-timezone";
import {
  useLangFile,
  useLanguage
} from "js/context/LanguageContext";
import {getDeviceLabel} from "js/helpers/StateInterpreters";
import {
  useFarmSettings,
  useGpsFixLocation
} from "js/context/SettingsContext";
import PropTypes from "prop-types";
import {grey} from "@material-ui/core/colors";
import WeatherStationMenu from "js/components/WeatherDrawer/WeatherStationSelector/WeatherStationMenu";
import WeatherStationIconButton from "js/components/WeatherDrawer/WeatherStationSelector/WeatherStationIconButton";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../../hooks/useFirebaseAnalytics";
import {stationHasBrokenGps, stationHasData} from "../../../helpers/WeatherUtils";

const styles = (theme) => ({
  deviceName: {
    fontSize: 11,
  },
  listItemContainer: {
    display: "block"
  },
  stationRoot: {
    marginTop: theme.spacing(1),
    padding: [[0, theme.spacing(1)]],
    borderRadius: 4,
    border: `1px solid ${grey[300]}`,
    '&.Mui-selected': {
      backgroundColor: grey[300],
    }
  },
  '@media (max-height: 900px)': {
    stationRoot: {
      marginTop: theme.spacing(3),
    },
    namePrimary: {
      fontSize: 12,
    },
    secondaryAction: {
      position: "absolute",
      border: `1px solid ${grey[300]}`,
      backgroundColor: 'white',
      top: 0,
      right: 0,
      borderRadius: 24,
      '&.selected': {
        backgroundColor: grey[300],
      },
    },
  },
});

const WeatherStationSettingsListItem = ({classes, station, selectStation, selectedStation, onRename, onShowGpsError, onSetLocation, onManageConnections}) => {

  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();
  const language = useLanguage();
  const farmSettings = useFarmSettings();

  // VARIABLES

  let label = station.label;
  let initializing = station.status === "initializing";

  let hasData = stationHasData(station);
  let selected = selectedStation && selectedStation.identifier === station.identifier;
  let gpsFixLocation = useGpsFixLocation(label);
  let brokenGps = stationHasBrokenGps(station);

  let secondaryText;

  if (initializing) {
    secondaryText = LangFile.WeatherStationSettingsListItem.initializing;
  }
  else if (hasData) {
    secondaryText = moment(station.current.timestamp).locale(language).calendar();
  }
  else {
    secondaryText = LangFile.WeatherStationSettingsListItem.noRecentData;
  }

  let primaryText = getDeviceLabel(station.identifier, farmSettings);

  const handleOnShowGpsError = useCallback(() => {
    onShowGpsError(station);
  }, [station]);

  const handleClick = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.WEATHER_LIST_CARD_CLICK, {stationIdentifier: station.identifier});
    selectStation(station.identifier);
  }, [selectStation, station]);

  return (
    <ListItem
      button
      classes={{root: classes.stationRoot, container: classes.listItemContainer} }
      onClick={handleClick}
      selected={selected}
      disabled={initializing}>
      <ListItemText
        classes={{
          secondary: classes.updatedText,
          primary: classes.namePrimary
        }}
        primary={primaryText}
        secondary={secondaryText}/>

      <ListItemSecondaryAction className={`${classes.secondaryAction} ${selected && 'selected'}`}>
        {!station.owner && (
          <WeatherStationIconButton aria-controls="station-menu" aria-haspopup="true">
            <Link/>
          </WeatherStationIconButton>
        )}

        {(brokenGps && !gpsFixLocation) && (
          <WeatherStationIconButton aria-controls="station-menu" aria-haspopup="true" onClick={handleOnShowGpsError}>
            <Error color={"secondary"}/>
          </WeatherStationIconButton>
        )}

        <WeatherStationMenu
          station={station}
          brokenGps={brokenGps}
          gpsFixLocation={gpsFixLocation}
          onSetLocation={onSetLocation}
          onSelectStation={selectStation}
          onManageConnections={onManageConnections}
          onRename={onRename}/>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

WeatherStationSettingsListItem.propTypes = {
  onRename: PropTypes.func,
  onShowGpsError: PropTypes.func,
  onSetLocation: PropTypes.func,
};

export default withStyles(styles)(WeatherStationSettingsListItem);