import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Paper from "@material-ui/core/Paper";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SplitIcon from "js/components/Icons/SplitIcon";
import grey from "@material-ui/core/colors/grey";

const styles = (theme) => ({
  root: {
    overflow: "visible",
  },
  paper: {
    overflow: "visible"
  },
  title: {
    paddingTop: 6,
    paddingBottom: 6,
    borderBottom: "1px solid " + grey["200"]
  },
  bottomNav: {
    width: 150,
    overflow: "visible"
  },
  splitIcon: {
    zIndex: 200,
    position: "absolute",
    top: -50,
    left: "50%",
    transform: "translate(-50%, 0)",
    fill: grey["600"]
  }
});

class PrescriptionSplitPopover extends Component {

  state = {
    highlightedOption: null
  };

  handleMouseEnter = (option) => {
    this.setState({highlightedOption: option});
  };

  handleMouseLeave = () => {
    this.setState({highlightedOption: null});
  };

  render() {
    let {classes, LangFile, anchorEl, onCancel, onSplitInterval, splittingIndex} = this.props;

    if (splittingIndex === undefined) {
      return null;
    }

    return (
      <Popover
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onCancel}
        classes={{paper: classes.root}}
        placement="top">
        <Paper className={classes.paper}>
          <SplitIcon className={classes.splitIcon}/>
            <BottomNavigation
              showLabels={true}
              value={this.state.highlightedOption}
              className={classes.bottomNav}
              onMouseLeave={this.handleMouseLeave}>
              <BottomNavigationAction
                label={LangFile.PrescriptionSplitPopover.cancel}
                onMouseUp={onCancel}
                icon={<ClearIcon />}
                onMouseEnter={() => this.handleMouseEnter(0)}/>
              <BottomNavigationAction
                onMouseUp={() => onSplitInterval(splittingIndex)}
                label={LangFile.PrescriptionSplitPopover.split}
                icon={<CheckIcon />}
                onMouseEnter={() => this.handleMouseEnter(1)}/>
            </BottomNavigation>
        </Paper>
      </Popover>
    );
  }
}

export default withStyles(styles)(PrescriptionSplitPopover);

PrescriptionSplitPopover.propTypes = {
  LangFile: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  open: PropTypes.any,
  onCancel: PropTypes.func,
  onSplitInterval: PropTypes.func,
  splittingIndex: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number
  ]),
  intervals: PropTypes.array,
};

PrescriptionSplitPopover.defaultProps = {
  onCancel: () => {},
  onSplitInterval: () => {},
  intervals: []
};
