import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';

import Dialog from "@material-ui/core/Dialog";
import {DialogTitle} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {useLangFile} from "../../../context/LanguageContext";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const WindInfoSettingsDialog = (props:WindInfoSettingsDialog.propTypes) => {

  const LangFile = useLangFile();

  const handleEnableWindgustsChanged = useCallback((event) => {
    props.onEnableWindgustsChanged(event.target.checked);
  }, [props.onEnableWindgustsChanged]);

  const handleEnableSprayConditionsChanged = useCallback((event) => {
    props.onEnableSprayConditionsChanged(event.target.checked);
  }, [props.onEnableSprayConditionsChanged]);

  return (
    <Dialog
      maxWidth={"xs"}
      fullWidth={true}
      open={props.shown}
      onClose={props.onClose}>
      <DialogTitle>
        {LangFile.WindInfoSettingsDialog.title}
      </DialogTitle>
      <DialogContent>
        {LangFile.WindInfoSettingsDialog.description}

        <Box display={"flex"} flexDirection={"column"} pt={2} pb={2}>
          <FormControlLabel
            control={
              <Switch
                checked={props.enableWindgusts}
                onChange={handleEnableWindgustsChanged}
                color="primary"/>
            }
            label={LangFile.WindInfoSettingsDialog.showWindgusts}/>
          <FormControlLabel
            control={
              <Switch
                checked={props.enableSprayConditions}
                onChange={handleEnableSprayConditionsChanged}
                color="primary"/>
            }
            label={LangFile.WindInfoSettingsDialog.showSprayingConditions}/>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} onClick={props.onClose}>
          {LangFile.WindInfoSettingsDialog.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WindInfoSettingsDialog.propTypes = {
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  enableWindgusts: PropTypes.bool,
  onEnableWindgustsChanged: PropTypes.func,
  enableSprayConditions: PropTypes.bool,
  onEnableSprayConditionsChanged: PropTypes.func,
};

export default memo(WindInfoSettingsDialog);