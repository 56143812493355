// @flow

import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Box, CardMedia, Typography} from "@material-ui/core";
import {useLangFile} from "js/context/LanguageContext";
import ViewModeConstants from "../../constants/ViewModeConstants";
import {getImageTypeColorScale} from "../../constants/ColorMaps";
import {getMinMaxValues} from "../../helpers/SurveyUtils";
import type {LAYER_CONFIG} from "../../model/surveys/SurveyLayerConfig";
import {getLayerConfig} from "../Prescription/PrescriptionUtils";
import {useColoringStrategy} from "../../model/surveys/useColoringStrategy";
import ReportLegendColorScale from "./ReportLegendColorScale";
import ReportLegendDemandScale from "./ReportLegendDemandScale";
import GoogleMap from "../MapObjects/GoogleMap/GoogleMap";
import {grey, yellow} from "@material-ui/core/colors";
import FeaturePolygon from "../DataLayer/FeaturePolygon";
import DataLayer from "../DataLayer/DataLayer";
import MapOverlay from "../MapObjects/MapOverlay";
import logo from "../../../style/images/brand/cropline_logo.png";
import {DefaultColoringStrategy} from "../../model/surveys/SurveyColoringStrategies";
import {isSatelliteLayer, isSurveyLayer} from "../Prescription/PrescriptionJob";
import {getFieldName} from "../../helpers/StateInterpreters";
import MapFloatPanel from "../MapView/MapFloatPanel";
import moment from "moment-timezone";

const ReportToPrint = React.forwardRef((props, ref) => {
  const LangFile = useLangFile();
  const layer = props.showSatelliteImagery ? props.imageType : props.selectedLayer;
  const layerConfig: LAYER_CONFIG = getLayerConfig(layer);
  const isSingleField = props.viewMode === ViewModeConstants.ANALYSIS || props.viewMode === ViewModeConstants.PRESCRIPTION;
  const coloringStrategy = useColoringStrategy(layer, props.viewMode, props.classificationsEnabled, props.variationsEnabled, props.values);

  let scale = coloringStrategy.scale;
  let minPercent = 0;
  let maxPercent = 100;

  let layerColor = grey.A400;
  if (isSurveyLayer(layer)) {
    let defaultColorStrategy = new DefaultColoringStrategy(layer);
    layerColor = defaultColorStrategy.getColor(layerConfig.maxValue);
  }

  if (layerConfig && props.values) {
    if (!props.variationsEnabled) {
      let {min, max} = getMinMaxValues(props.values);

      if (coloringStrategy.isDiscrete) {
        let length = coloringStrategy.scale.length;
        let minBucket = coloringStrategy.getBucket(min);
        let maxBucket = coloringStrategy.getBucket(max);

        if (minBucket && maxBucket) {
          minPercent = minBucket.index/length*100;
          maxPercent = Math.min(maxBucket.index+1, length)/length*100;
        }
      }
      else {
        minPercent = layerConfig.mapValue(min, 0, 100);
        maxPercent = layerConfig.mapValue(max, 0, 100);
      }
    }
  }

  let showMinMax = isSingleField && (props.selectedFieldHasImage || props.selectedFieldHasSurvey);
  let title = LangFile.MapLegend.mapLayers.none;
  if (layerConfig) {
    title = layerConfig.getName(LangFile);
    const shorthand = layerConfig.getShorthand(LangFile);
    if (shorthand) {
      title = title + ` (${shorthand})`;
    }
  }

  let mapStyle: String = null;
  if (!props.toggleMap) {
    mapStyle = "empty";
  }

  const fieldName = getFieldName(props.selectedField, LangFile);

  let classificationMappings;
  if (props.classificationValues && props.classificationValues[props.selectedLayer]) {
    classificationMappings = props.classificationValues[props.selectedLayer]['mappings']['FINE'];
  }

  return (
    <Box ref={ref}>
      <Box pt={3} pb={2} pl={3}>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent="flex-start">
          <Box height={40} width={140} mr={1}>
            <CardMedia
              component="img"
              image={logo}/>
          </Box>
        </Box>
        <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography variant="h6">
              { LangFile.SurveyReport.fieldReport + ": " + fieldName}
            </Typography>
            <Box mt={0.5}>
              <Typography variant="body2">
                { LangFile.SurveyReport.printed + ": " + props.printDate.format('MMMM D, YYYY') }
              </Typography>
            </Box>
          </Box>
          <Box bgcolor={layerColor} pt={1} pb={1} pl={15} pr={2}>
            <Typography variant="h6" style={{color: 'white'}}>
              {title}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box pl={3} pr={3} display={"flex"} flexDirection={"column"}>
        <Box border={1} height={"50vh"}>
          <GoogleMap
            disableInteraction={true}
            mapStyle={mapStyle}
            fitToBounds={props.selectedField.bounds}>
            <DataLayer
              setStyle={(feature) => {
                return {
                  strokeColor: yellow["A400"],
                  fillOpacity: 0,
                  clickable: false,
                };
              }}>
              <FeaturePolygon coords={props.selectedField.polygon.coordinates}/>
            </DataLayer>

            {isSatelliteLayer(layer) && props.imageDate && (
              <MapFloatPanel top={0} right={0}>
                <Box bgcolor={"black"} color={"white"} px={2} py={1}>
                  {LangFile.SurveyReport.imageDate}: {moment(props.imageDate).format('MMMM D, YYYY')}
                </Box>
              </MapFloatPanel>
            )}

            <MapOverlay
              fieldImage={{"url": props.imageURL, "bounds": props.selectedField.bounds}}/>
          </GoogleMap>
        </Box>
        {(props.showSatelliteImagery || props.showSoilSurveys) && (
          <Box mt={3} p={1} border={1}>
            <Box px={1} >
              {props.showSatelliteImagery && (
                <ReportLegendColorScale
                  scale={getImageTypeColorScale(layer)}/>
              )}
              {!props.classificationsEnabled && props.showSoilSurveys && (
                <ReportLegendColorScale
                  minPercent={minPercent}
                  maxPercent={maxPercent}
                  showMinMax={showMinMax}
                  scale={scale}
                  continuous={!props.variationsEnabled}
                  decimals={coloringStrategy.tooltipDecimals}
                  unit={layerConfig.unit}/>
              )}
              {props.classificationsEnabled && (
                <ReportLegendDemandScale
                  showAll={props.viewMode !== ViewModeConstants.OVERVIEW}
                  layer={layer}
                  classificationValues={classificationMappings}/>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
});

ReportToPrint.propTypes = {
  selectedField: PropTypes.object,
  showSatelliteImagery: PropTypes.bool,
  showSoilSurveys: PropTypes.bool,
  printDate: PropTypes.any,
  classificationsEnabled: PropTypes.bool,
  variationsEnabled: PropTypes.bool,
  selectedFieldHasImage: PropTypes.bool,
  selectedFieldHasSurvey: PropTypes.bool,
  toggleMap: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  imageType: PropTypes.string,
  imageURL: PropTypes.string,
  imageDate: PropTypes.string,
  selectedLayer: PropTypes.string,
  viewMode: PropTypes.string,
  classificationValues: PropTypes.object
};

export default memo(
  ReportToPrint
);
