import {loadWMSlayer} from "js/helpers/WMSLoader";
import APIConstants from "../APIConstants";

export const DATAFORDELER_SERVICE_URL = encodeURI("https://services.datafordeler.dk/DHMNedboer/dhm/1.0.0/WMS?" + APIConstants.datafordelerToken);

export const DATAFORDELER_HEIGHTMAP_FINE = "dhm_kurve_0_25_m";
export const DATAFORDELER_HEIGHTMAP_COARSE = "dhm_kurve_0_5_m";
export const DATAFORDELER_HEIGHTMAP_TRADITIONAL = "dhm_kurve_traditionel";

export const loadHeightMap = (googleMap, shown, zoom, overlays) => {
  if (!(googleMap && overlays)) {
    return;
  }

  let overlay = getCurrentHeightOverlay(overlays, zoom);

  overlays.forEach((layer) => {
    let intendedLayer = layer.name === overlay.name;

    if (intendedLayer && shown) {
      loadWMSlayer(googleMap, layer);
    }
    else {
      googleMap.overlayMapTypes.removeAt(`${layer.order}`);
    }
  });
};

export function getCurrentHeightOverlay(overlays, zoom) {
  if (zoom >= 20) { // Max Zoom, show the highest resolution map (edgy)
    return overlays.find((overlay) => overlay.name === DATAFORDELER_HEIGHTMAP_FINE);
  }
  else if (zoom > 17) { // Zoomed enough to use the optimal height map
    return overlays.find((overlay) => overlay.name === DATAFORDELER_HEIGHTMAP_COARSE);
  }
  else { // Not zoomed enough, use the lowest resolution map
    return overlays.find((overlay) => overlay.name === DATAFORDELER_HEIGHTMAP_TRADITIONAL);
  }
}

export function ensureProperZoom(googleMap) {
  return new Promise((resolve) => {
    if (!googleMap) {
      resolve();
    }

    let zoom = googleMap.getZoom() || 0;

    if (zoom < 15) {
      zoom = 15;
    }

    googleMap.setZoom(zoom);
    resolve();
  });
}