import React, {memo} from 'react';

import Dialog from '@material-ui/core/Dialog';
import {
  Box,
  DialogContent,
  CircularProgress
} from '@material-ui/core';

import Style from './ProgressLoadingModal.module.less';
import PropTypes from "prop-types";

const ProgressLoadingModal = ({progress}) => {

  if (!progress || !progress.shown) {
    return null;
  }

  return (
    <Dialog
      open={true}
      fullWidth={true}
      className={Style.Root}
      maxWidth={'sm'}>
      <DialogContent className={Style.Content}>

        <Box p={3} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
          <CircularProgress variant={"indeterminate"} color={"primary"} size={72}/>

          <div className={Style.Message}>
            {progress.message}
          </div>

        </Box>

      </DialogContent>
    </Dialog>
  );
};

ProgressLoadingModal.propTypes = {
  progress: PropTypes.shape({
    shown: PropTypes.bool,
    message: PropTypes.any,
  })
};

export default memo(ProgressLoadingModal);
