import WebAPIUtils from 'js/WebAPIUtils';
import {groupBy} from "js/algorithms/grouping";
import {
  removeBy,
  replaceBy
} from "js/algorithms/arrays";
import {renameProperty} from "js/algorithms/objects";

/* ============== //
 ||     TYPES     ||
 // ============== */

const GET_ALL_NOTES = "fieldsense/Note/GET_ALL_NOTES";
const CREATE_NOTE = "fieldsense/Note/CREATE_NOTE";
const DELETE_NOTE = "fieldsense/Note/DELETE_NOTE";
const UPDATE_NOTE = "fieldsense/Note/UPDATE_NOTE";
const SET_IS_DRAWING = "fieldsense/Note/SET_IS_DRAWING";
const SET_IS_PLACING_MARKER = "fieldsense/Note/SET_IS_PLACING_MARKER";
const SELECT_NOTE = "fieldsense/Note/SELECT_NOTE";
const DESELECT_NOTE = "fieldsense/Note/DESELECT_NOTE";
const SET_MAGIC_WAND_THRESHOLD = "fieldsense/Note/SET_MAGIC_WAND_THRESHOLD";
const SET_IS_MAGIC_SELECTION = "fieldsensae/Note/SET_IS_MAGIC_SELECTION";
const DRAW_MAGIC_SELECTION = "fieldsensae/Note/DRAW_MAGIC_SELECTION";
const SET_EDIT_NOTE_LOCATION = "fieldsense/NoteReducer/SET_EDIT_NOTE_LOCATION";

export function createDefaultNote(polygon) {
  return {
    type: "OTHER",
    date: "",
    text: "",
    polygon: polygon
  };
}

/* ============== //
 ||    ACTIONS    ||
 // ============== */


export function setIsPlacingMarker(isPlacing) {
  return {
    type: SET_IS_PLACING_MARKER,
    payload: isPlacing
  };
}

export function setIsDrawingNote(isDrawing) {
  return {
    type: SET_IS_DRAWING,
    payload: isDrawing
  };
}

export function setIsMagicSelecting(isDrawing) {
  return {
    type: SET_IS_MAGIC_SELECTION,
    payload: isDrawing
  };
}


export function deleteNote(note, fieldId) {
  return {
    type: DELETE_NOTE,
    payload: WebAPIUtils.deleteNote(note.id),
    meta: {noteId: note.id, fieldId: fieldId}
  };
}

export function updateNote(note) {
  console.log("update note");
  console.log(note);

  return {
    type: UPDATE_NOTE,
    payload: WebAPIUtils.putNote(note),
    meta: {noteId: note.id, fieldId: note.fieldId}
  };
}

export function createNote(id, date, poly, text, type) {
  // Create polygon in correct format
  let nodes = [];
  poly.forEach((elem) => {
    let node = [];
    node.push(elem.latitude);
    node.push(elem.longitude);
    nodes.push(node);
  });

  // Create the data to send
  let data = {
    createDate: new Date().toJSON(),
    date: date,
    fieldId: id,
    href: "",
    id: 0,
    polygon: nodes,
    text: text,
    type: type
  };
  return {
    type: CREATE_NOTE,
    payload: WebAPIUtils.postNote(data),
    meta: {fieldId: id}
  };
}

export function getAllNotes(farmId) {

  const request = async () => {
    const response = await WebAPIUtils.getAllNotes(farmId);
    let notes = response.notes;

    if (!Array.isArray(notes)) {
      notes = [];
    }

    // Bridge API inconsistency between GET and PUT
    return notes.map((note) => renameProperty(note, "created", "createDate"));
  };

  return {
    type: GET_ALL_NOTES,
    payload: request,
  };

}

export function selectNote(note) {
  return {
    type: SELECT_NOTE,
    payload: note
  };
}

export function deselectNote() {
  return {
    type: DESELECT_NOTE
  };
}

export function setMagicWandThreshold(val) {
  return {
    type: SET_MAGIC_WAND_THRESHOLD,
    payload: val
  };
}

export function drawMagicSelection(field, targetLatLng) {
  return {
    type: DRAW_MAGIC_SELECTION,
    payload: targetLatLng,
    meta: {
      field: field
    }
  };
}

export function setEditNoteLocation(note) {
  return {
    type: SET_EDIT_NOTE_LOCATION,
    payload: note
  };
}

/* ============== //
 ||    REDUCER    ||
 // ============== */

const initState = {
  notes: {},
  isDrawing: false,
  isPlacingMarker: false,
  showNoteModal: false,
  selectedNote: null,
  editNoteLocation: null,
  magicWand: {
    isSelecting: false,
    threshold: 7,
    field: null,
    targetLatLng: null
  }
};

export default function reducer(state = initState, action) {
  switch (action.type) {

    case GET_ALL_NOTES + "_FULFILLED": {
      let notes = groupBy(action.payload, "fieldId");

      state = {...state, notes: notes};
      break;
    }

    case CREATE_NOTE + "_FULFILLED": {
      let note = action.payload;
      let fieldId = action.meta.fieldId;
      let existingNotes = state.notes[fieldId] || [];

      let notes = {...state.notes, [fieldId]: [...existingNotes, note]};

      state = {...state, notes: notes};
      break;
    }

    case DELETE_NOTE + "_FULFILLED": {
      let fieldId = action.meta.fieldId;
      let noteId = action.meta.noteId;

      let fieldNotes = removeBy(state.notes[fieldId], "id", noteId);
      let notes = {...state.notes, [fieldId]: fieldNotes};

      state = {...state, notes: notes};
      break;
    }

    case UPDATE_NOTE + "_FULFILLED": {
      let fieldId = action.meta.fieldId;
      let noteId = action.payload;

      let fieldNotes = replaceBy(state.notes[fieldId], "id", noteId);
      let notes = {...state.notes, [fieldId]: fieldNotes};

      state = {...state, notes: notes};

      break;
    }

    case SET_IS_DRAWING:
      state = {
        ...state,
        isDrawing: action.payload,
        drawnPolygon: action.payload ? state.drawnPolygon : null
      };
      break;

    case SET_IS_PLACING_MARKER:
      state = {...state, isPlacingMarker: action.payload};
      break;

    case SET_EDIT_NOTE_LOCATION: {
      state = {...state, editNoteLocation: action.payload};
      break;
    }

    case SELECT_NOTE:
      state = {...state, selectedNote: action.payload, showNoteModal: true};
      break;

    case DESELECT_NOTE:
      state = {...state, selectedNote: null, showNoteModal: false};
      break;

    case SET_IS_MAGIC_SELECTION:
      if (action.payload) {
        state = {...state, magicWand: {...state.magicWand, isSelecting: true}};
      }
      else {
        state = {...state, magicWand: {...state.magicWand, isSelecting: false, field: null, targetLatLng: null}};
      }

      break;

    case SET_MAGIC_WAND_THRESHOLD:
      state = {...state, magicWand: {...state.magicWand, threshold: action.payload}};
      break;

    case DRAW_MAGIC_SELECTION:
      state = {...state, magicWand: {...state.magicWand, targetLatLng: action.payload, field: action.meta.field}};
      break;

    default:
      break;
  }
  return state;
}
