import React, {memo, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import {useLangFile} from '../../context/LanguageContext';
import SurveyStatisticsDataTable from './SurveyStatisticsDataTable';
import SurveyStatisticsBarChart from './BarChart/SurveyStatisticsBarChart';
import {
  statisticsDataColumns,
  statisticsDataRows,
  statisticsOverviewColumns,
  statisticsOverviewRows
} from './SurveyStatisticsUtils';
import {ToggleButtonGroup} from "@material-ui/lab";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {BarChart, ViewList} from "@material-ui/icons";

const ViewTypes = {
  TABLE: "TABLE",
  HISTOGRAM: "HISTOGRAM"
};

import Styles from "./SurveyStatistics.module.less";

const SurveyStatistics = ({ data, width }) => {
  const LangFile = useLangFile();
  const [viewType, setViewType] = useState(ViewTypes.HISTOGRAM);

  const onViewTypeChanged = useCallback((event, value) => {
    if (value) {
      setViewType(value);
    }
  }, [viewType]);

  if (data.items.length > 0) {
    return (
      <Box>
        <Box px={1} pb={1}>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <h4>{LangFile.SurveyStatistics.statistics}</h4>

            <ToggleButtonGroup size="small" value={viewType} exclusive onChange={onViewTypeChanged} className={Styles.ButtonGroup}>
              <ToggleButton value={ViewTypes.HISTOGRAM}>
                <BarChart style={{transform: "rotate(90deg)"}} fontSize={"small"}/>
              </ToggleButton>
              <ToggleButton value={ViewTypes.TABLE}>
                <ViewList fontSize={"small"}/>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box>
            <SurveyStatisticsDataTable
              columns={statisticsOverviewColumns(LangFile)}
              items={statisticsOverviewRows([data.min, data.mean, data.max])}/>
          </Box>

          {viewType === ViewTypes.TABLE && (
            <SurveyStatisticsDataTable
              columns={statisticsDataColumns(LangFile)}
              items={statisticsDataRows(data.items)}/>
          )}

          {viewType=== ViewTypes.HISTOGRAM && (
            <SurveyStatisticsBarChart
              items={data.items}
              width={width}/>
          )}
        </Box>
      </Box>
    );
  }
  return null;
};

SurveyStatistics.propTypes = {
  data: PropTypes.object,
  width: PropTypes.number,
};

export default memo(
  SurveyStatistics
);