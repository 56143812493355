import {useEffect} from 'react';

import ManualFeatureConstants from "../../constants/ManualFeatureConstants";
import {getFieldsArray} from "../ManageCrops/ManageCrops";
import {SURVEY_LAYERS} from "../../constants/SurveyLayers";
import {fetchSurveyStatisticsTabulated} from "../SurveyStatistics/SurveyStatisticsUtils";
import {useFarm} from "../../context/AccountContext";
import {useSurveyContext} from "../../context/SurveyContext";
import {useFieldContext} from "../../context/FieldContext";
import {useLangFileRef} from "../../context/LanguageContext";

const SurveyDataDumper = ({}) => {

  const LangFileRef = useLangFileRef();
  const farm = useFarm();
  const {surveys, selectedSurvey} = useSurveyContext();
  const {fields, selectedField} = useFieldContext();

  useEffect(() => {
    if (ManualFeatureConstants.ENABLED_FETCH_SURVEY_STATISTICS) {
      let fieldsArray = getFieldsArray(fields);

      if (farm && selectedField && selectedSurvey && fieldsArray) {

        let lines = [];
        let queue = fieldsArray;
        let layers = [SURVEY_LAYERS.RT, SURVEY_LAYERS.PHOSPHORUS, SURVEY_LAYERS.POTASSIUM, SURVEY_LAYERS.MAGNESIUM];

        // Headers
        lines.push(['Field ID', 'Field Name', 'Field Size (Ha)', ...layers.map((layer) => LangFileRef.current.SurveyLayers[layer].shorthand)].join("\t"));

        let processNext = (remaining) => {
          if (remaining.length <= 0) {
            window.prompt("Headers: Field ID, Field Name, Field Size, " + layers.join(' ,'), lines.join('\n'));
            return;
          }

          let field = remaining.pop();
          let survey = surveys.find((survey) => survey.fieldId === field.fieldId);

          if (survey) {
            fetchSurveyStatisticsTabulated(farm, field, survey, layers, LangFileRef.current).then((text) => {
              console.log(text);
              lines.push(text);
              processNext(remaining);
            });
          }
          else {
            processNext(remaining);
          }
        };

        // start processing
        processNext(queue);

      }
    }
  }, [farm, selectedField, selectedSurvey, surveys, fields]);

  return null;
};

SurveyDataDumper.propTypes = {};

export default SurveyDataDumper;
