import React, {memo, useCallback, useState} from "react";
import {Box, Typography} from "@material-ui/core";
import Styles from "../FieldStatisticsContainer.module.less";
import PropTypes from 'prop-types';
import {voidFunc} from '../../../constants/PropTypeUtils';

const StandardColumnCell = ({primary, secondary, onHoverChildren}) => {
  let [showHoverChildren, setShowHoverChildren] = useState(false);

  let onEnter = useCallback(() => setShowHoverChildren(true));
  let onLeave = useCallback(() => setShowHoverChildren(false));

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'100%'} position={"relative"} onMouseEnter={onEnter} onMouseLeave={onLeave}>
      <Typography className={Styles.title}>{primary}</Typography>
      {secondary && (
        <Typography className={Styles.subtitle}>{secondary}</Typography>
      )}
      {showHoverChildren && onHoverChildren && (
        <Box position={"absolute"} top={0} left={-8} right={-8} bottom={0} bgcolor={'#ffffff'}>
          {onHoverChildren}
        </Box>)}
    </Box>
  );
};

StandardColumnCell.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
  onHoverChildren: PropTypes.func
};

StandardColumnCell.defaultProps = {
  onHoverChildren: voidFunc
};

export default memo(
  StandardColumnCell
);
