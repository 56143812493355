import * as d3 from "d3";
import React, {
  Fragment,
  memo,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import BackdropLines from './BackdropLines';
import {useD3locale} from "js/context/LanguageContext";
import GraphAxis from "js/components/visualization/Graph/GraphAxis";
import {withStyles} from "@material-ui/core";
import {GraphProvider} from "js/context/GraphContext";
import Box from "@material-ui/core/Box";
import {GraphHoverProvider} from "js/context/GraphHoverContext";
import {voidFunc} from "../../../constants/PropTypeUtils";
import useRefSize from '../../../hooks/useRefSize';

const extractAllPoints = (datasets) => {
  const data = {...datasets};
  const sort = (a, b) => a - b;

  // If we have no data all points is no points
  if (!data) {
    return [];
  }

  // Create a sorted array containing each unique x value in the dataset.
  let valsArray = Object.keys(data).reduce((acc, key) => {
    return acc.concat(data[key].points.map((point) => point.x));
  }, []);
  let valsSet = new Set(valsArray);
  let uniqueArray = Array.from(valsSet);

  return uniqueArray.sort(sort);
};

const createMappings = (datasets,
                        allPoints,
                        width,
                        height,
                        domainX,
                        domainLeftY,
                        domainRightY,
                        margin,
                        locale,
                        minExtentLeftY,
                        minExtentRightY,
                        spaceTopLeftY,
                        spaceTopRightY) => {

  // Create the mappings for x and y domain to pixel
  let data = {...datasets};
  let xMap, yLeftMap, yRightMap = null;

  // Pixel Space
  let w = width - margin.left - margin.right;
  let h = height - margin.top - margin.bottom;
  let rangeX = [0, w];
  let rangeY = [h, 0];

  // Find datasets to make mappings from
  let datasetsLeft = [];
  let datasetsRight = [];

  Object.keys(data).forEach((key) => {
    let dataset = data[key];

    if (dataset.axis === "left") {
      datasetsLeft.push(dataset);
    }
    if (dataset.axis === "right") {
      datasetsRight.push(dataset);
    }
  });

  d3.timeFormatDefaultLocale(locale);

  if (datasetsLeft.length > 0) {

    let allPoints = Object.values(datasetsLeft).reduce((acc, cur) => [...acc, ...cur.points], []);

    let xExtent = (domainX && [...domainX]) || d3.extent(allPoints, (d) => d.x);
    let yExtent = (domainLeftY && [...domainLeftY]) || d3.extent(allPoints, (d) => d.y);
    yExtent[1] = Math.max(minExtentLeftY, yExtent[1]);
    yExtent[1] += spaceTopLeftY;

    xMap = d3.scaleTime(locale)
      .domain(xExtent)
      .range(rangeX);

    yLeftMap = d3.scaleLinear()
      .domain(yExtent)
      .range(rangeY);
  }

  if (datasetsRight.length > 0) {
    let allPoints = Object.values(datasetsRight).reduce((acc, cur) => [...acc, ...cur.points], []);

    let yExtent = (domainRightY && [...domainRightY]) || d3.extent(allPoints, (d) => d.y);
    yExtent[1] = Math.max(minExtentRightY, yExtent[1]);
    yExtent[1] += spaceTopRightY;

    yRightMap = d3.scaleLinear()
      .domain(yExtent)
      .range(rangeY);
  }

  return {xMap, yLeftMap, yRightMap, w, h};
};

const styles = (theme) => ({
  autoSizeWrapper: {
    position: 'relative',
    flex: '1 1 auto',
    height: '100%',
    width: '100%'
  },
  content: {
    flex: '1 1 auto',
    '& svg': {
      display: "block",
      overflow: "visible"
    }
  }
});


const GraphSystem = ({classes, children, datasets, domainX, domainLeftY, domainRightY, margin, xTicks, backdropLines, minExtentLeftY, minExtentRightY, spaceTopLeftY, spaceTopRightY, onTickClicked}) => {
  const d3locale = useD3locale();
  const svgRef = useRef(null);

  const [graph, setGraph] = useState(null);
  const [hoveredItems, setHoveredItems] = useState({});
  const ref = useRef(null);
  const size = useRefSize(ref);

  useEffect(() => {

    let svg = d3.select(svgRef.current);

    if (svg && size && datasets) {
      let allPoints = extractAllPoints(datasets);

      let mappings = createMappings(datasets, allPoints, size.width, size.height, domainX, domainLeftY, domainRightY, margin, d3locale, minExtentLeftY, minExtentRightY, spaceTopLeftY, spaceTopRightY);

      let xMap = mappings.xMap;
      let yLeftMap = mappings.yLeftMap;
      let yRightMap = mappings.yRightMap;
      let width = mappings.w;
      let height = mappings.h;

      let g = {
        width,
        height,
        xMap,
        yLeftMap,
        yRightMap,
        svg,
        allPoints,
        margin,
        datasets,
        xTicks,
      };

      setGraph(g);
    }

  }, [datasets, domainX, domainLeftY, domainRightY, margin, d3locale, xTicks, minExtentLeftY, minExtentRightY, spaceTopLeftY, spaceTopRightY, size]);

  const ready = graph && size.width && size.height && size.width > 0 && size.height > 0;

  return (
    <div className={classes.autoSizeWrapper} ref={ref}>
      {ready && (
        <GraphProvider value={graph}>
          <GraphHoverProvider value={{hoveredItems, setHoveredItems}}>
            <Box className={classes.content} width={size.width} height={size.height} pr={2}>
              <svg width={size.width} height={size.height}>
                <g ref={svgRef} transform={`translate(${margin.left} ${margin.top})`}>
                  <Fragment>
                    <GraphAxis onTickClicked={onTickClicked}/>

                    {backdropLines && (
                      <BackdropLines/>
                    )}

                    <g>{children}</g>
                  </Fragment>
                </g>
              </svg>
            </Box>
          </GraphHoverProvider>
        </GraphProvider>)}
    </div>
  );
};

GraphSystem.propTypes = {
  /**
   * The data displayed by the graph.
   * This will be passed down to all children.
   *
   * It is an object with an entry for each point sequence which should be visualized
   * Each point sequnce must be an object in the following format
   */
  datasets: PropTypes.objectOf(PropTypes.shape({
    /** The name of the point sequence, this is the name which will be displayed in for instance the tooltip*/
    name: PropTypes.string.isRequired,
    /** The unit to display as a suffix of the value in for instance the tooltip*/
    unit: PropTypes.string.isRequired,
    /** The unit to display as a suffix of the value in for instance the tooltip*/
    axis: PropTypes.oneOf(["left", "right"]).isRequired,
    /** This is the actual point sequence, which is a (sorted) list containing an object in the format {x, y}*/
    points: PropTypes.arrayOf(PropTypes.object).isRequired,
    /** This is the color which should be used to display this data entry*/
    color: PropTypes.string.isRequired,
    /**
     * For bar charts a specific point width (in domain space) can be provided to tell how wide the bars should be.
     * If this is not provided, the bar width will be calculated as the total graphsystem width divided by the number of data entries
     */
    pointWidth: PropTypes.number,
    /** Renders a circle at the top left corner of the hovered data entry to show hover target. **/
    showCircle: PropTypes.bool,
    /** Whether or not to show a tooltip for this dataset **/
    showTooltip: PropTypes.bool,
    /** The start date of the requested data */
    since: PropTypes.any,
    /** The end date of the requested data */
    until: PropTypes.any,
    /** The time between entries in the 'points' prop (data points), i.e. 10 minutes, 1 hour, 1 day... */
    resolution: PropTypes.any,
  })).isRequired,
  /** The start and stop value on the x axis of the graph. If this is not provided. The domain will be calculated from the data min and max values*/
  domainX: PropTypes.array,
  /** The start and stop value on the LEFT y axis of the graph. If this is not provided. The domain will be calculated from the data min and max values*/
  domainLeftY: PropTypes.array,
  /** The start and stop value on the RIGHT y axis of the graph. If this is not provided. The domain will be calculated from the data min and max values*/
  domainRightY: PropTypes.array,
  /** Display horizontal backdrop lines at each tick on the y axis*/
  backdropLines: PropTypes.bool,
  /** The number of ticks on the x axis.*/
  xTicks: PropTypes.any,
  /** The margin for the GraphSystem */
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
  minExtentLeftY: PropTypes.number,
  minExtentRightY: PropTypes.number,
  spaceTopLeftY: PropTypes.number,
  spaceTopRightY: PropTypes.number,
  onTickClicked: PropTypes.func,
};

GraphSystem.defaultProps = {
  backdropLines: true,
  margin: {left: 30, top: 20, right: 0, bottom: 20},
  minExtentLeftY: 5,
  minExtentRightY: 5,
  spaceTopLeftY: 1,
  spaceTopRightY: 1,
  onTickClicked: voidFunc,
  xTicks: 5
};

export default memo(withStyles(styles)(GraphSystem));

