// @flow

import React, {
  memo,
  useCallback
} from 'react';

import PropTypes from 'prop-types';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {
  CropType,
  CropTypeColor
} from "js/constants/CropConstants";
import MenuItem from "@material-ui/core/MenuItem";
import {
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {useLangFile} from "js/context/LanguageContext";

import Styles from "./SelectCrop.module.less";
import clsx from "clsx";
import {red} from "@material-ui/core/colors";

const SelectCrop = (props: SelectCrop.propTypes) => {

  const LangFile = useLangFile();

  const onCropChanged = useCallback((event) => {
    props.onChanged(event.target.value);
  }, [props.onChanged]);

  return (
    <FormControl fullWidth={true} error={props.error}>
      <InputLabel shrink={true}>{LangFile.SelectCrop.label}</InputLabel>
      <Select
        classes={{root: clsx({[Styles.SelectRoot]: true, [Styles.SelectRootError]: props.error}), icon: clsx({[Styles.IconError]: props.error})}}
        fullWidth={true}
        error={props.error}
        value={props.value}
        renderValue={((value) => {
            return (
              <Box color={value === CropType._SELECT ? red["A700"] : "black"}>
                {LangFile.CropType[value]}
              </Box>
            );
        })}
        onChange={onCropChanged}>

        {Object.keys(CropType).map((crop) => {
          let color = CropTypeColor[crop];

          return (
            <MenuItem
              className={Styles.MenuItem}
              value={crop}
              key={crop}>
              {color && (
                <ListItemIcon className={Styles.ListItemIcon}>
                  <Box
                    width={"12px"}
                    height={"12px"}
                    borderRadius={"50%"}
                    border={2}
                    borderColor={"grey.900"}
                    bgcolor={CropTypeColor[crop]}/>
                </ListItemIcon>
              )}

              <ListItemText className={clsx({[Styles.ListItemText]: true})}>
                {LangFile.CropType[crop]}
              </ListItemText>
            </MenuItem>
          );
        })
        }
      </Select>
    </FormControl>
  );
};

SelectCrop.propTypes = {
  onChanged: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.bool
};

export default memo(SelectCrop);