"use strict";

import React, {
  memo,
  useCallback,
  useRef,
} from 'react';

import {
  Dialog,
  Button,
  TextField,
  DialogContent,
  DialogActions,
  Paper
} from "@material-ui/core";

import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import grey from '@material-ui/core/colors/grey';
import {useLangFile} from "js/context/LanguageContext";
import {withStyles} from "@material-ui/core/styles";

const styles = (theme) => ({
  paper: {
    overflow: 'visible',
  },
  content: {
    minWidth: '400px',
  },
  suggestionPaper: {
    position: 'absolute',
    zIndex: 1,
    width: 'calc(100% - 48px)'
  },
  tip: {
    height: 36,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: grey[500],
    fontStyle: "italic",
    textAlign: "center",
    fontSize: "10pt",
    padding: [[0, 15]],
    marginRight: "auto"
  },
  shortcut: {
    backgroundColor: grey[200],
    boxShadow: `${grey[300]} 0 2px 10px 1px`,
    border: `1px solid ${grey[400]}`,
    borderRadius: 4,
    padding: [[2, 4]],
    margin: [0, 8],
    '&:first-child': {
      marginRight: 8,
    }
  },
  actions: {
    justifyContent: 'space-between',
  }
});

const SearchDialog = ({classes, open, onChange, onSelect, dismissDialog, value, source, activeIndex, googleMap, onEscKeyDown}) => {

  const LangFile = useLangFile();
  const ref = useRef(null);

  const handleOnChange = useCallback((event) => {
    onChange(event.target.value);
    ref.current.focus();
  }, [ref.current]);

  const renderSuggestions = useCallback(() => {
    let suggestions = [];

    if (source) {
      source.forEach((item, index) => {
        suggestions.push(
          <ListItem
            button
            dense
            value={item.value}
            key={index}
            onClick={() => onSelect(index)}
            style={{backgroundColor: activeIndex === index ? grey["200"] : null}}>
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} secondary={item.secondaryText}/>
          </ListItem>
        );
      });
    }

    return suggestions;
  }, [source, activeIndex, onSelect]);

  if (!googleMap) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={"sm"}
      onEscapeKeyDown={onEscKeyDown}
      disableEscapeKeyDown={false}
      onClose={dismissDialog}
      classes={{paper: classes.paper}}>
      <DialogContent className={classes.content}>
        <TextField
          fullWidth
          autoFocus
          helperText={LangFile.SearchDialog.startTyping}
          label={LangFile.SearchDialog.hint}
          value={value}
          onChange={handleOnChange}
          inputRef={ref}/>
        {value ?
          <Paper className={classes.suggestionPaper}>
            <List dense style={{maxHeight: '30vh', overflow: 'auto'}}>
              {renderSuggestions()}
            </List>
          </Paper> : null
        }

      </DialogContent>
      <DialogActions classes={{root: classes.actions}}>
        <div className={classes.tip}>
          <span className={classes.shortcut}>Ctrl + F</span>{LangFile.SearchDialog.or}
          <span className={classes.shortcut}>⌘ + F</span>
        </div>
        <Button color={"primary"} onClick={dismissDialog}>{LangFile.SearchDialog.close}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(withStyles(styles)(SearchDialog));
