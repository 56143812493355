import React, {
  Fragment,
  memo,
  useState,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import MapPolygon from './MapPolygon';
import MapMarker from './MapMarker';
import {
  centerOfMass,
  flip,
  polygon
} from "@turf/turf";

import NoteType from 'js/model/NoteType';
import fungusIcon from 'style/images/markers/location-fungus.png';
import soilIcon from 'style/images/markers/location-soil.png';
import obstacleIcon from 'style/images/markers/location-obstacle.png';
import pestsIcon from 'style/images/markers/location-pests.png';
import weedIcon from 'style/images/markers/location-weed.png';
import windowIcon from 'style/images/markers/location-window.png';
import otherIcon from 'style/images/markers/location-unknown.png';

import {deepPurple} from "@material-ui/core/colors";

const color = deepPurple["500"];

const unhoveredState = {
  fillColor: color,
  fillOpacity: 0.2,
  strokeColor: color,
  strokeWeight: 3,
  iconWidth: 15.44 * 2,
  iconHeight: 21.32 * 2
};

const hoveredState = {
  fillColor: color,
  fillOpacity: 0.6,
  strokeColor: color,
  strokeWeight: 3,
  iconWidth: 15.44 * 2 * 1.5,
  iconHeight: 21.32 * 2 * 1.5
};

const getIconForNoteType = (noteType, hoverState) => {
  let icon = {
    url: otherIcon,
    scaledSize: new google.maps.Size(hoverState.iconWidth, hoverState.iconHeight),
  };

  if (noteType) {
    switch (noteType.toUpperCase()) {
      case NoteType.FUNGUS:
        icon.url = fungusIcon;
        break;
      case NoteType.SOIL:
        icon.url = soilIcon;
        break;
      case NoteType.OBSTACLE:
        icon.url = obstacleIcon;
        break;
      case NoteType.PESTS:
        icon.url = pestsIcon;
        break;
      case NoteType.WEED:
        icon.url = weedIcon;
        break;
      case NoteType.WINDOW:
        icon.url = windowIcon;
        break;
      default:
        icon.url = otherIcon;
    }
  }
  return icon;
};

export const getPolygonAnchor = (poly) => {
  if (!Array.isArray(poly)) {
    return null;
  }

  let center = poly[0];

  if (poly.length > 3) {

    let first = poly[0];
    let last = poly[poly.length - 1];

    let valid = first[0] === last[0] && first[1] === last[1];

    if (valid) {
      poly = flip(polygon([poly]));
      center = flip(centerOfMass(poly)).geometry.coordinates;
    }
  }

  return {lat: center[0], lng: center[1]};
};

const MapNote = (props) => {

  const [hoverState, setHoverState] = useState(unhoveredState);

  const handleSetHoverState = (value) => useCallback(() => setHoverState(value), []);
  const handleOnClick = useCallback(() => props.onClick(props.note), [props.note]);

  const coordinates = props.note.polygon.map((coordinate) => {
    return {lat: coordinate[0], lng: coordinate[1]};
  });

  const anchor = getPolygonAnchor(props.note.polygon);

  return (
    <Fragment>
      <MapPolygon
        coordinates={coordinates}
        strokeColor={hoverState.strokeColor}
        fillColor={hoverState.fillColor}
        fillOpacity={hoverState.fillOpacity}
        strokeWeight={hoverState.strokeWeight}
        zIndex={3000000}
        onMouseEnter={handleSetHoverState(hoveredState)}
        onMouseLeave={handleSetHoverState(unhoveredState)}
        onClick={handleOnClick}/>

      <MapMarker
        key={props.note.id}
        position={anchor}
        onClick={handleOnClick}
        onMouseEnter={handleSetHoverState(hoveredState)}
        onMouseLeave={handleSetHoverState(unhoveredState)}
        animate={false}
        zIndex={props.draggable ? 200 : 195}
        draggable={props.draggable}
        onDragEnd={props.onDragEnd}
        icon={getIconForNoteType(props.note.type, hoverState)}/>
    </Fragment>
  );
};

MapNote.propTypes = {
  note: PropTypes.object.isRequired,
  draggable: PropTypes.bool,
  onClick: PropTypes.func,
  onDragEnd: PropTypes.func,
};

export default memo(MapNote);