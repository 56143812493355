import React, {useContext} from "react";
import {SurveyLayerViewCapabilities, SurveyViewCapabilities} from "../helpers/SurveyUtils";
import type {SurveyLayer} from "../constants/SurveyLayers";
import ViewModeConstants from "../constants/ViewModeConstants";
import {Survey} from "../components/Prescription/PrescriptionJob";

const SurveyContext = React.createContext({});

export const SurveyProvider = SurveyContext.Provider;
export const SurveyConsumer = SurveyContext.Consumer;

export function useSurveyContext() {
  return useContext(SurveyContext);
}

export function useSurveyViewCapabilities(): SurveyViewCapabilities {
  let state = useSurveyContext();
  return state && state.surveyViewCapabilities;
}

export function useSelectedSurvey(): Survey {
  let state = useSurveyContext();
  return state && state.selectedSurvey;
}

export function useSurveyLayerViewCapabilities(layer, viewMode): SurveyLayerViewCapabilities {
  let surveyViewModeCapabilities = useSurveyViewCapabilities();
  let selectedSurvey = useSelectedSurvey();
  let result = new SurveyLayerViewCapabilities();

  if (surveyViewModeCapabilities) {
    if (viewMode === ViewModeConstants.OVERVIEW) {
      let caps = surveyViewModeCapabilities.overview.get(layer);
      if (caps) {
        result = caps;
      }
    }
    else {
      if (selectedSurvey) {
        let caps = surveyViewModeCapabilities.getSurveyLayerViewCapabilities(layer, selectedSurvey.surveyId);
        if (caps) {
          result = caps;
        }
      }
    }
  }

  return result;
}

export function isLayerValid(layer, survey, viewMode, surveyViewCapabilities, classificationsEnabled, variationsEnabled) {
  if (!surveyViewCapabilities) {
    return undefined;
  }

  let capabilities: SurveyLayerViewCapabilities;
  if (viewMode === ViewModeConstants.OVERVIEW) {
    capabilities = surveyViewCapabilities.overview.get(layer);
  }
  else if (viewMode === ViewModeConstants.ANALYSIS || viewMode === ViewModeConstants.PRESCRIPTION) {
    if (survey) {
      capabilities = surveyViewCapabilities.getSurveyLayerViewCapabilities(layer, survey.surveyId);
    }
  }

  if (!capabilities) {
    return undefined;
  }

  if (classificationsEnabled) {
    return capabilities.enableClassifications;
  }
  else if (variationsEnabled) {
    return capabilities.enableVariations;
  }
  else {
    return capabilities.enableRaw;
  }
}

export function canLayerBeEnabled(layer, survey, viewMode, surveyViewCapabilities) {
  if (!surveyViewCapabilities) {
    return undefined;
  }

  let capabilities: SurveyLayerViewCapabilities;
  if (viewMode === ViewModeConstants.OVERVIEW || viewMode === ViewModeConstants.FIELD_STATISTICS) {
    capabilities = surveyViewCapabilities.overview.get(layer);
  }
  else if (viewMode === ViewModeConstants.ANALYSIS || viewMode === ViewModeConstants.PRESCRIPTION) {
    if (survey) {
      capabilities = surveyViewCapabilities.getSurveyLayerViewCapabilities(layer, survey.surveyId);
    }
  }

  if (!capabilities) {
    return false;
  }

  return capabilities.enableRaw || capabilities.enableVariations || capabilities.enableClassifications;
}


export function useCanLayerBeEnabled(layer: SurveyLayer) {
  const {surveyViewCapabilities, viewMode, selectedSurvey} = useSurveyContext();

  return canLayerBeEnabled(layer, selectedSurvey, viewMode, surveyViewCapabilities);
}
