import {useEffect} from "react";
import {goToFields} from "../helpers/MapsUtils";
import {useHookRef} from "./useHookRef";
import {getCountry} from "../helpers/CountryDetection";

const COUNTRY_LOCATIONS = {
  DENMARK: {
    center: {
      lat: 55.832729,
      lng: 10.594330
    },
    zoom: 8,
  },
  FINLAND: {
    center: {
      lat: 65.035060,
      lng: 27.138172
    },
    zoom: 6,
  },
  SWEDEN: {
    center: {
      lat: 62.714462,
      lng: 16.056559
    },
    zoom: 6,
  },
  ESTONIA: {
    center: {
      lat: 58.734005,
      lng: 25.042412
    },
    zoom: 8,
  },
};

const EUROPE_LOCATION = {
  center: {
    lat: 50.007739,
    lng: 10.060102,
  },
  zoom: 6,
};

export const useEffectGoToFieldsOrCountryOnSync = (googleMap, goToFieldsEvent, fields, filteredFields, weatherStations) => {
  const mapRef = useHookRef(googleMap);
  const fieldsRef = (filteredFields.size === 0) ? useHookRef(fields) : useHookRef(filteredFields);
  const stationsRef = useHookRef(weatherStations);
  const mapLoaded = mapRef.current !== null;

  useEffect(() => {
    let country = getCountry();

    if (mapLoaded) {
      if (fieldsRef.current.size > 0) {
        // if we have fields, go there
        goToFields(mapRef.current, fieldsRef.current, stationsRef.current);
      }
      else {
        let countryLocation = country && COUNTRY_LOCATIONS[country.toUpperCase()];

        if (countryLocation) {
          // if we can infer the country, go there
          let {center, zoom} = countryLocation;

          mapRef.current.setCenter(center);
          mapRef.current.setZoom(zoom);
        }
        else {
          // else, use Europe center as default
          let {center, zoom} = EUROPE_LOCATION;

          mapRef.current.setCenter(center);
          mapRef.current.setZoom(zoom);
        }
      }
    }
  }, [goToFieldsEvent, mapLoaded]);

};
