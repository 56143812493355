import React, {
  Fragment,
  memo,
  useCallback,
  useState
} from 'react';

import PropTypes from 'prop-types';

import {
  Menu,
  MenuItem,
  ListItemIcon,
} from "@material-ui/core";

import {
  EditLocationRounded,
  EditRounded,
  FileCopyRounded,
  Link,
  Settings,
} from "@material-ui/icons";

import ReplaceStationMenuItemContainer from "js/components/ReplaceStation/ReplaceStationMenuItemContainer";
import WeatherStationIconButton from "js/components/WeatherDrawer/WeatherStationSelector/WeatherStationIconButton";

import {useLangFile} from "js/context/LanguageContext";
import useLowScreen from "js/hooks/useLowScreen";
import {voidFunc} from "../../../constants/PropTypeUtils";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../../hooks/useFirebaseAnalytics";

const copyCoordinates = (id, location) => {
  window.prompt(id, `${id}, ${location}`);
};

const WeatherStationMenu = ({station, brokenGps, gpsFixLocation, onSelectStation, onRename, onSetLocation, onManageConnections}) => {

  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();

  const low = useLowScreen();
  const [anchorEl, setAnchorEl] = useState(null);

  // LOCATION STRING FOR COPY-PASTE

  const gpsLocation = station.location;

  let locString;
  if (gpsLocation && gpsLocation.latitude && gpsLocation.longitude) {
    locString = `${gpsLocation.latitude}, ${gpsLocation.longitude}`;
  }
  else if (gpsFixLocation) {
    locString = `${gpsFixLocation.lat()}, ${gpsFixLocation.lng()}`;
  }
  else {
    locString = LangFile.WeatherStationMenu.noLocation;
  }

  // HANDLERS

  const handleOnSetLocation = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.WEATHER_STATION_SETTINGS_FIX_GPS);

    onSetLocation(station);
  }, [station, onSetLocation]);

  const handleOpenMenu = useCallback((event) => {
    analytics.logEvent(FIREBASE_EVENTS.WEATHER_STATION_SETTINGS);

    onSelectStation(station.identifier);
    setAnchorEl(event.target);
  }, [onSelectStation]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleRename = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.WEATHER_STATION_SETTINGS_NAME);

    onRename(station);
    handleClose();
  }, [station, onRename]);

  const handleConnectFields = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.WEATHER_STATION_SETTINGS_CONNECT_FIELDS);

    onManageConnections(station.identifier);
    handleClose();
  }, [station, onManageConnections]);

  const handleCopy = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.WEATHER_STATION_SETTINGS_COPY_COORDS);

    copyCoordinates(station.identifier, locString);
    handleClose();
  }, [station.identifier, locString]);

  return (
    <Fragment>

      <WeatherStationIconButton aria-controls="station-menu" aria-haspopup="true" onClick={handleOpenMenu}>
        <Settings fontSize={low ? "small" : "default"}/>
      </WeatherStationIconButton>

      <Menu
        id="station-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem button={true} onClick={handleRename}>
          <ListItemIcon>
            <EditRounded/>
          </ListItemIcon>
          {LangFile.WeatherStationMenu.rename}
        </MenuItem>
        <MenuItem button={true} onClick={handleConnectFields}>
          <ListItemIcon>
            <Link/>
          </ListItemIcon>
          {LangFile.WeatherStationMenu.connectFields}
        </MenuItem>
        <MenuItem button={true} onClick={handleCopy}>
          <ListItemIcon>
            <FileCopyRounded/>
          </ListItemIcon>{LangFile.WeatherStationMenu.copyCoords}
        </MenuItem>

        {brokenGps && (
          <MenuItem button={true} onClick={handleOnSetLocation}>
            <ListItemIcon>
              <EditLocationRounded/>
            </ListItemIcon>
            {LangFile.WeatherStationMenu.setLocation}
          </MenuItem>
        )}
        
        {station.owner && (
          <ReplaceStationMenuItemContainer/>
        )}
      </Menu>
    </Fragment>
  );
};

WeatherStationMenu.propTypes = {
  station: PropTypes.object,
  brokenGps: PropTypes.bool,
  gpsFixLocation: PropTypes.object,
  onSelectStation: PropTypes.func,
  onRename: PropTypes.func,
  onSetLocation: PropTypes.func,
  onManageConnections: PropTypes.func,
};

WeatherStationMenu.defaultProps = {
  onSelectStation: voidFunc,
  onRename: voidFunc,
  onSetLocation: voidFunc,
  onManageConnections: voidFunc,
};

export default memo(WeatherStationMenu);
