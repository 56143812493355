/* ============== //
||      TYPES     ||
// ============== */

import WebAPIUtils from "js/WebAPIUtils";
import {SURVEY_LAYERS} from "js/constants/SurveyLayers";
import {extractSurveyCapabilities} from "../model/surveys/SurveyImageMapping";
import {SELECT_FIELD} from "./FieldReducer";
import {Survey} from "../components/Prescription/PrescriptionJob";

const SET_SHOW_SOIL_SURVEYS = "fieldsense/SurveyReducer/SET_SHOW_SOIL_SURVEYS";
const GET_ALL_SURVEYS = "fieldsense/SurveyReducer/GET_ALL_SURVEYS";
const SET_SELECTED_LAYER = "fieldsense/SurveyReducer/SET_SELECTED_LAYER";
const SET_VARIATIONS_ENABLED = "fieldsense/SurveyReducer/SET_VARIATIONS_ENABLED";
const SET_CLASSIFICATIONS_ENABLED = "fieldsense/SurveyReducer/SET_CLASSIFICATIONS_ENABLED";
const SET_STATISTICS_ENABLED = "fieldsense/SurveyReducer/SET_STATISTICS_ENABLED";

const SAVE_SELECTED_LAYER = "fieldsense/SurveyReducer/SAVE_SELECTED_LAYER";
const RESTORE_SELECTED_LAYER = "fieldsense/SurveyReducer/RESTORE_SELECTED_LAYER";

const GET_REFERENCE_VALUES = "fieldsense/SurveyReducer/GET_REFERENCE_VALUES";
const GET_CLASSIFICATION_VALUES = "fieldsense/SurveyReducer/GET_CLASSIFICATION_VALUES";

/* ============== //
||     ACTIONS    ||
// ============== */


export function getAllSurveys(farmId) {
  return {
    type: GET_ALL_SURVEYS,
    payload: WebAPIUtils.getAllSurveys(farmId)
  };
}

export function setShowSoilSurveys(showSoilSurveys) {
  return {
    type: SET_SHOW_SOIL_SURVEYS,
    payload: showSoilSurveys
  };
}

export function setSelectedLayer(layer) {
  return {
    type: SET_SELECTED_LAYER,
    payload: layer
  };
}

export function setVariationsEnabled(enabled) {
  return {
    type: SET_VARIATIONS_ENABLED,
    payload: enabled
  };
}

export function setClassificationsEnabled(enabled) {
  return {
    type: SET_CLASSIFICATIONS_ENABLED,
    payload: enabled
  };
}

export function setStatisticsEnabled(enabled) {
  return {
    type: SET_STATISTICS_ENABLED,
    payload: enabled
  };
}

export function saveSelectedLayer() {
  return {
    type: SAVE_SELECTED_LAYER,
  };
}

export function restoreSelectedLayer() {
  return {
    type: RESTORE_SELECTED_LAYER,
  };
}

export function getReferenceValues(farmId, fieldId, date, layer, survey, jobId, measureSettings) {
  return {
    type: GET_REFERENCE_VALUES,
    meta: layer,
    payload: WebAPIUtils.getLayerValues(farmId, fieldId, date, layer, survey, jobId, measureSettings)
  };
}

export function getClassificationValues(farmId, fieldId, date, layer, survey, jobId) {
  return {
    type: GET_CLASSIFICATION_VALUES,
    meta: layer,
    payload: WebAPIUtils.getClassificationValues(farmId, fieldId, date, layer, survey, jobId)
  };
}

/* ============== //
||     REDUCER    ||
// ============== */

const initialState = {
  variationsEnabled: false,
  classificationsEnabled: false,
  statisticsEnabled: true,
  surveys: [],
  selectedLayer: SURVEY_LAYERS.PHOSPHORUS,
  selectedSurvey: null,
  savedSelectedSurvey: null,
  savedSelectedLayer: null,
  showSoilSurveys: false,
  surveyViewCapabilities: undefined,
  restoreSavedSelectedSurvey: false,
  selectedSurveyReferenceValues: {},
  selectedSurveyClassificationValues: {}
};

const SET_SELECTED_SURVEY_FORCEFULLY = "fieldsense/SurveyReducer/SET_SELECTED_SURVEY_FORCEFULLY";

export function setSelectedSurveyForcefully(survey) {
  return {
    type: SET_SELECTED_SURVEY_FORCEFULLY,
    payload: survey
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case SET_SHOW_SOIL_SURVEYS: {
      state = {...state, showSoilSurveys: action.payload};
      break;
    }

    case SET_CLASSIFICATIONS_ENABLED: {
      state = {...state, classificationsEnabled: action.payload};
      break;
    }

    case SET_VARIATIONS_ENABLED: {
      state = {...state, variationsEnabled: action.payload};
      break;
    }

    case SET_STATISTICS_ENABLED: {
      state = {...state, statisticsEnabled: action.payload};
      break;
    }

    case GET_ALL_SURVEYS + "_FULFILLED": {
      const payload = action.payload;
      if (payload) {
        const {surveys} = payload;
        let surveyViewCapabilities = extractSurveyCapabilities(surveys);
        state = {...state, surveys: surveys, surveyViewCapabilities};
      }
      break;
    }

    case GET_ALL_SURVEYS + "_REJECTED": {
      state = {...state, surveys: []};
      break;
    }

    case SET_SELECTED_LAYER: {
      state = {...state, selectedLayer: action.payload};
      break;
    }

    case SAVE_SELECTED_LAYER: {
      state = {...state, savedSelectedLayer: state.selectedLayer};
      break;
    }

    case RESTORE_SELECTED_LAYER: {
      if (state.savedSelectedLayer) {
        state = {...state, selectedLayer: state.savedSelectedLayer, savedSelectedLayer: null};
      }
      break;
    }
    case SELECT_FIELD: {
      let newSurvey = null;
      let fieldId = action.payload && action.payload.fieldId;
      if (fieldId) {
        newSurvey = state.surveys.find((s: Survey) => s.fieldId === fieldId);
      }
      state = {...state, selectedSurvey: newSurvey, selectedSurveyReferenceValues: {}, selectedSurveyClassificationValues: {}};
      break;
    }
    case SET_SELECTED_SURVEY_FORCEFULLY: {
      let survey = action.payload;
      if (survey) {
        let surveyViewCapabilities = extractSurveyCapabilities([...state.surveys, survey]);
        state = {...state, savedSelectedSurvey: state.selectedSurvey, selectedSurvey: survey, surveyViewCapabilities, restoreSaved: state.restoreSavedSelectedSurvey != null };
      }
      else {
        let surveyViewCapabilities = extractSurveyCapabilities(state.surveys);
        if (state.restoreSavedSelectedSurvey) {
          state = {...state, savedSelectedSurvey: null, selectedSurvey: state.savedSelectedSurvey, surveyViewCapabilities, restoreSavedSelectedSurvey: false};
        }
      }
      break;
    }
    case GET_REFERENCE_VALUES + "_FULFILLED": {
      state = {...state, selectedSurveyReferenceValues: {...state.selectedSurveyReferenceValues, [action.meta]: action.payload}};
      break;
    }
    case GET_CLASSIFICATION_VALUES + "_FULFILLED": {
        state = {...state, selectedSurveyClassificationValues: {...state.selectedSurveyClassificationValues, [action.meta]: action.payload}};
      break;
    }
    default:
      break;
  }

  return state;
}
