"use strict";

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  return (
    <SvgIcon
      {...props}
      width="73.194"
      height="73.194"
      viewBox="0 0 73.194 73.194">

      <g transform="translate(-0.002)">
        <g transform="translate(6.694)">
          <path
            d="M161.368,79.507l33.125-33.125a7.93,7.93,0,1,0-11.215-11.215L150.153,68.292Z"
            transform="translate(-135.39 -28.154)"/>
          <path
            d="M68.083,24.28l1.628,1.628,3.031-3.031-1.628-1.628,4.244-4.243L58.352,0,46.834,11.518,63.84,28.523Z"
            transform="translate(-46.834)"/>
          <path
            d="M319.153,260.8l-4.244,4.243-1.414-1.414-3.031,3.031,1.414,1.414-4.244,4.243,17.719,17.719,11.518-11.518Z"
            transform="translate(-270.37 -223.537)"/>
        </g>
        <g transform="translate(0.002 39.753)">
          <path
            d="M33.444,311.648C15.472,312.02-.363,296.185.009,278.213H4.3a29.182,29.182,0,0,0,29.149,29.149Z"
            transform="translate(-0.002 -278.213)"/>
        </g>
        <g transform="translate(11.61 39.753)">
          <path
            d="M103.077,300.046a21.858,21.858,0,0,1-21.834-21.833H85.53a17.567,17.567,0,0,0,17.547,17.547Z"
            transform="translate(-81.243 -278.213)"/>
        </g>
      </g>

    </SvgIcon>
  );
};
