// @flow

import React, {useContext} from "react";
import type {PrescriptionJob} from "js/components/Prescription/PrescriptionJob";

const PrescriptionJobContext = React.createContext({});

export const PrescriptionJobProvider = PrescriptionJobContext.Provider;

export function usePrescriptionJob (): {prescriptionJob: PrescriptionJob, setPrescriptionJob: (newJobChanges: PrescriptionJob, recalculate: boolean) => any} {
  let [prescriptionJob, setPrescriptionJob] = useContext(PrescriptionJobContext);
  return {prescriptionJob, setPrescriptionJob};
}