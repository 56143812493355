import React from 'react';
import PropTypes from 'prop-types';
import ListItem from "@material-ui/core/ListItem";
import {
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Tooltip
} from "@material-ui/core";
import {useLangFile} from "js/context/LanguageContext";
import {useHasFeatures} from "js/context/PermissionContext";

import style from './LayerHeaderItem.module.less';

const LayerDrawerHeaderItem = ({requiresFeatures, featureRule, onToggle, tooltip, primaryText, secondaryText, toggled, disabled, divider}) => {

  const LangFile = useLangFile();
  const enabled = useHasFeatures(requiresFeatures, featureRule) && !disabled;

  return (
    <ListItem dense button={enabled} divider={divider} onClick={enabled ? onToggle : undefined} disabled={!enabled}>
      <ListItemText primary={primaryText} secondary={secondaryText} secondaryTypographyProps={{color: "secondary"}}/>
      <ListItemSecondaryAction>
        <Tooltip title={enabled ? tooltip : LangFile.LayerDrawerHeaderItem.disabledTooltip} classes={{tooltip: style.tooltip}} placement={"right"}>
              <span>
                <Switch
                  size={"small"}
                  color={"primary"}
                  onChange={onToggle}
                  checked={toggled}
                  disabled={!enabled}/>
              </span>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

LayerDrawerHeaderItem.propTypes = {
  requiresFeatures: PropTypes.arrayOf(PropTypes.string),
  featureRule: PropTypes.oneOf(['either', 'all']),
  onToggle: PropTypes.func.isRequired,
  primaryText: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  toggled: PropTypes.bool.isRequired,
  secondaryText: PropTypes.string,
  disabled: PropTypes.bool,
  divider: PropTypes.bool,
};

LayerDrawerHeaderItem.defaultProps = {
  requiresFeatures: [],
  featureRule: 'either',
  divider: true,
};

export default LayerDrawerHeaderItem;