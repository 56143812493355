import React, {
  useCallback,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  Grid,
} from "@material-ui/core";

import {
  Lock,
  PermIdentity
} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {useLangFile} from "js/context/LanguageContext";
import StyleConstants from "js/StyleConstants";
import {grey} from "@material-ui/core/colors";
import useEvent from "js/hooks/useEvent";
import {detectBrowser} from "js/helpers/BrowserDetection";

const styles = (theme) => ({
  form: {
    height: "100%",
  },
  slide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  icon: {
    color: StyleConstants.palette.primary.main,
  },
  textField: {
    margin: [[theme.spacing(1), 0]],
    width: '100%',
    color: grey["500"],
  },
  rememberMe: {
    margin: [[theme.spacing(1), 0]],
  },
  rememberMeLabel: {
    maxWidth: "100%",
    marginLeft: 4,
  },
  labelShrink: {
    transform: 'translate(14px, -16px) scale(0.75)'
  },
  slideButton: {
    marginTop: theme.spacing(1),
  }
});

// This fix adds to the workround of properly filling out input values in Chrome when autofill kicks in.
const fixInput = (input) => {
  input.focus();

  let event = document.createEvent('TextEvent');

  if (event.initTextEvent) {
    event.initTextEvent('textInput', true, true, window, '@@@@@');
    input.dispatchEvent(event);
    input.value = input.value.replace('@@@@@', '');
  }

  input.blur();
};

const LoginForm = ({classes, shown, onSlide, onSubmit}) => {
  
  const LangFile = useLangFile();

  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const rememberMeRef = useRef(null);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);

  const handleUsernameChanged = useCallback((e) => {
    let newValue = e.target.value.trim();

    setUsername(newValue);
  }, []);

  const handlePasswordChanged = useCallback((e) => {
    let newValue = e.target.value.trim();

    setPassword(newValue);
  }, []);

  const handleRememberMeChanged = useCallback((e) => {
    setRememberMe(!rememberMe);
  }, [rememberMe]);

  const handleOnSubmit = useCallback((e) => {
    // Here we have to reference the input values directly to work around an autofill bug in Chrome.
    onSubmit(usernameRef.current.value, passwordRef.current.value, rememberMe);
  }, [usernameRef.current, passwordRef.current, rememberMe]);

  const enterHandler = (event) => {
    if (!shown) {
      return;
    }


    if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault();
      handleOnSubmit();
    }
  };

  useEvent("keyup", enterHandler, usernameRef.current);
  useEvent("keyup", enterHandler, passwordRef.current);

  // Workaround for Chrome's autofill not correctly filling out input values.
  useLayoutEffect(() => {
    let timeout;

    if (usernameRef.current && passwordRef.current) {
      let browser = detectBrowser();

      if (browser.isChrome) {
        timeout = setTimeout(() => {
          fixInput(usernameRef.current);
          fixInput(passwordRef.current);

          if (usernameRef.current.value.trim() === "") {
            usernameRef.current.focus();
          }

        }, 500);
      }
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [usernameRef.current, passwordRef.current]);

  return (
    <form className={classes.form}>
      <div className={classes.slide}>
        <div>
          <Grid container direction={"row"} wrap={"nowrap"}>
            <Grid item container xs={1} justify={"center"} alignItems={"center"}>
              <PermIdentity className={classes.icon}/>
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                classes={{root: classes.textField}}
                InputLabelProps={{
                  classes: {
                    root: classes.labelShrink
                  }
                }}
                className={"remove-autofill-color"}
                variant={"outlined"}
                tabIndex={shown ? -1 : 1}
                placeholder={LangFile.LoginForm.email}
                value={username}
                onChange={handleUsernameChanged}
                inputRef={usernameRef}
                InputProps={{
                  autoComplete: "email",
                }}/>
            </Grid>
          </Grid>

          <Grid container direction={"row"} wrap={"nowrap"}>
            <Grid item container xs={1} justify={"center"} alignItems={"center"}>
              <Lock className={classes.icon}/>
            </Grid>
            <Grid item xs={11}>
              <TextField
                classes={{root: classes.textField}}
                className={"remove-autofill-color"}
                variant={"outlined"}
                tabIndex={shown ? -1 : 2}
                type="password"
                InputProps={{
                  autoComplete: "current-password"
                }}
                placeholder={LangFile.LoginForm.password}
                label={""}
                value={password}
                inputRef={passwordRef}
                onChange={handlePasswordChanged}/>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item container xs={12} justify={"flex-start"} alignItems={"center"}>
              <FormControlLabel
                label={LangFile.LoginForm.rememberMe}
                classes={{root: classes.rememberMe, label: classes.rememberMeLabel}}
                control={
                  <Checkbox
                    color={"primary"}
                    tabIndex={shown ? -1 : 3}
                    label={LangFile.LoginForm.rememberMe}
                    inputRef={rememberMeRef}
                    checked={rememberMe}
                    onChange={handleRememberMeChanged}/>
                }/>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container direction={"column"}>
            <Button
              fullWidth
              variant={"contained"}
              color={"primary"}
              tabIndex={shown ? -1 : 4}
              disabled={!shown}
              onClick={handleOnSubmit}>
              {LangFile.LoginForm.login}
            </Button>
            <Button
              fullWidth
              className={classes.slideButton}
              tabIndex={shown ? -1 : 5}
              color={"primary"}
              onClick={onSlide}>
              {LangFile.LoginForm.forgotPassword}
            </Button>
          </Grid>
        </div>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  shown: PropTypes.bool,
  onSlide: PropTypes.func,
  onSubmit: PropTypes.func
};

export default withStyles(styles)(LoginForm);