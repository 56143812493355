import React from 'react';
import AppContainer from './components/AppContainer';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Redirect} from 'react-router';

import MUITheme from './MUITheme';
import DateIntegrationSuccessContainer from './components/DataIntegration/DateIntegrationSuccessContainer';

export default (
  <MUITheme>
    <Router>
        <Switch>
            <Route path="/dashboard" component={AppContainer}/>
          <Route
            path="/claasconnect"
            render={() => (
              <DateIntegrationSuccessContainer/>
            )}/>
            <Route
              exact={true}
              path="/"
              render={() => (
                <Redirect to="/dashboard"/>
            )}/>
            <Route path="*" component={AppContainer}/>
        </Switch>
    </Router>
  </MUITheme>
);
