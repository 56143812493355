import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHookRef} from "../../../hooks/useHookRef";

function useDataLayerEvent(dataLayer, eventType, handler) {

  const savedHandler = useHookRef(handler);

  useEffect(() => {
    if (dataLayer) {
      const listener = dataLayer.addListener(eventType, (event) => {
        let callback = savedHandler.current;

        if (typeof callback === "function") {
          callback(event);
        }
      });

      return () => {
        google.maps.event.removeListener(listener);
      };
    }
  }, [dataLayer]);
}

useDataLayerEvent.PropTypes = {
  dataLayer: PropTypes.object.isRequired,
  eventType: PropTypes.oneOf(['click', 'mouseover', 'mouseout', 'addfeature', 'dblclick', 'mousedown', 'mouseup', 'removefeature', 'removeproperty', 'rightclick', 'setgeometry', 'setproperty']).isRequired,
  handler: PropTypes.func.isRequired,
};

export default useDataLayerEvent;