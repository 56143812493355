export default class FieldSeason {
  fieldId: number;
  crop: string;
  sowing: Date;
  cropVariation: string;

  constructor(fieldId: number, crop: string, sowing: Date, cropVariation: string) {
    this.fieldId = fieldId;
    this.crop = crop;
    this.sowing = sowing;
    this.cropVariation = cropVariation;
  }
}