import {useLangFile} from "../../context/LanguageContext";
import React, {memo, useCallback, useState} from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {Card, ListItem} from "@material-ui/core";
import PropTypes from "prop-types";
import {voidFunc} from "../../constants/PropTypeUtils";
import {getFieldName} from "../../helpers/StateInterpreters";
import Styles from "./PrescriptionArchiveExpandableListItem.module.less";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Table from "@material-ui/core/Table/Table";
import {PrescriptionJob} from "../Prescription/PrescriptionJob";
import TableHead from "@material-ui/core/TableHead/TableHead";
import PrescriptionTableRow from "./PrescriptionTableRow";
import IconButton from "@material-ui/core/IconButton";
import {TableColumns} from "./PrescriptionArchiveUtils";
import moment from "moment";
import {useSeasonContext} from "../../context/SeasonContext";

const PrescriptionArchiveExpandableListItem = (props: PrescriptionArchiveExpandableListItem.propTypes) => {
  const LangFile = useLangFile();
  const {selectedSeason} = useSeasonContext();

  const cols = [
    {id: TableColumns.jobName, label: LangFile.PrescriptionTableHead.name},
    {id: TableColumns.jobType, label: LangFile.PrescriptionTableHead.jobType},
    {id: TableColumns.createdDate, label: LangFile.PrescriptionTableHead.created},
    {id: TableColumns.layer, label: LangFile.PrescriptionTableHead.inputLayer},
  ];

  const [expanded, setExpanded] = useState(false);

  const handleTopClicked = useCallback((event) => {
    setExpanded((currentValue) => !currentValue);
  }, []);

  return (<ListItem className={Styles.listItem}>
    <Card className={Styles.listCard}>
      <Box className={Styles.top} onClick={handleTopClicked} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
          <Box className={Styles.titleTop} flexGrow={expanded ? 1 : 0}>
              <Box className={Styles.header}>
                <Typography variant={"subtitle2"} classes={{root:Styles.fieldName}}>{LangFile.PrescriptionTableHead.field}: {getFieldName(props.field, LangFile)}</Typography>

                {!expanded && props.jobs.length === 0 && (
                  <Typography variant={"subtitle2"} className={Styles.headerEmptyMessage}>{LangFile.PrescriptionArchiveExpandableListItem.emptyMessage}</Typography>
                )}

                {expanded && (
                  <>
                    <Typography variant={"subtitle2"} className={Styles.cropType}>{LangFile.CropType[props.field.crop]}</Typography>
                    <Typography variant={"body2"} className={Styles.sowingDate}>{moment(props.field.sowing || selectedSeason.startDate).format("LL")}</Typography>
                  </>
                )}
              </Box>
          </Box>
        {!expanded && (props.jobs.length > 0 && (
              <Table size={"small"} className={Styles.tableRoot}>
                <TableBody className={Styles.tableBodyTop}>
                  <PrescriptionTableRow
                    alignText={"center"}
                    key={props.jobs[0].jobId}
                    job={props.jobs[0]}
                    showSeasonSelector={false}
                    showDocumentation={false}
                    onSetSeason={props.onSetSeason}
                    onEdit={props.onEdit}
                    onDelete={props.onDelete}
                    onDownload={props.onDownload}/>
                </TableBody>
              </Table>
            )
        )}
        <IconButton size={"small"} className={Styles.collapseButton}>
          {expanded ? <ExpandLess/> : <ExpandMore/>}
        </IconButton>
      </Box>
      {expanded && (
        <Box>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell className={Styles.headCell} align="left" padding="none">{cols[0].label}</TableCell>
                <TableCell className={Styles.headCell} align="left" padding="none">{cols[1].label}</TableCell>
                <TableCell className={Styles.headCell} align="left" padding="none">{cols[2].label}</TableCell>
                <TableCell className={Styles.headCell} align="left" padding="none">{cols[3].label}</TableCell>
                <TableCell className={Styles.headCell} align="right" padding="none">{" "}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={Styles.tableBody}>
              {props.jobs.map((job: PrescriptionJob) => {
                  return <PrescriptionTableRow
                    key={job.jobId}
                    job={job}
                    documentation={props.documentation}
                    showSeasonSelector={true}
                    showDocumentation={true}
                    onDocumentationUploaded={props.onDocumentationUploaded}
                    onDeleteDocumentation={props.onDeleteDocumentation}
                    onSetSeason={props.onSetSeason}
                    onEdit={props.onEdit}
                    onDelete={props.onDelete}
                    onDownload={props.onDownload}/>;
              })}
              {props.jobs.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5} className={Styles.tableRowEmpty}>{LangFile.PrescriptionArchiveExpandableListItem.emptyMessage}</TableCell>
                </TableRow>
              )}
            </TableBody>

            {props.outsideJobs.length > 0 && (
              <TableBody className={Styles.tableBody}>
                <TableRow>
                  <TableCell colSpan={5} className={Styles.outsideSeasonHeader}>
                    <Typography variant={"subtitle2"}>{LangFile.PrescriptionArchiveExpandableListItem.outsideOfSeason}</Typography>
                  </TableCell>
                </TableRow>

                {props.outsideJobs.map((job: PrescriptionJob) => {
                  return <PrescriptionTableRow
                    key={job.jobId}
                    job={job}
                    documentation={props.documentation}
                    showSeasonSelector={true}
                    showDocumentation={true}
                    onDocumentationUploaded={props.onDocumentationUploaded}
                    onDeleteDocumentation={props.onDeleteDocumentation}
                    onSetSeason={props.onSetSeason}
                    onEdit={props.onEdit}
                    onDelete={props.onDelete}
                    onDownload={props.onDownload}/>;
                })}
              </TableBody>
            )}

          </Table>

          <Box className={Styles.bottom}/>
        </Box>
      )}
    </Card>
  </ListItem>);
};

PrescriptionArchiveExpandableListItem.propTypes = {
  field: PropTypes.object.isRequired,
  jobs: PropTypes.arrayOf(PropTypes.instanceOf(PrescriptionJob)),
  outsideJobs: PropTypes.arrayOf(PropTypes.instanceOf(PrescriptionJob)),
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onEdit: PropTypes.func,
  onSetSeason: PropTypes.func,
  onDocumentationUploaded: PropTypes.func,
  onDeleteDocumentation: PropTypes.func,
};

PrescriptionArchiveExpandableListItem.defaultProps = {
  jobs: [],
  outsideJobs: [],
  onDelete: voidFunc,
  onDownload: voidFunc,
  onEdit: voidFunc,
  onDocumentationUploaded: voidFunc,
  onDeleteDocumentation: voidFunc
};

export default memo(PrescriptionArchiveExpandableListItem);