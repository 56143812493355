import React, {memo} from 'react';
import {Box, Tooltip} from "@material-ui/core";
import {
  type SurveyLayer,
  SURVEY_LAYER_CLASSIFICATION_ICONS,
  SURVEY_LAYER_CLASSIFICATION_KEYS,
  SURVEY_LAYER_CLASSIFICATIONS,
  SURVEY_LAYER_CLASSIFICATION_NO_RANGE_KEYS,
  SURVEY_LAYER_CLASSIFICATION_NO_RANGE_ICONS,
} from "../../constants/SurveyLayers";
import type {LanguageFile} from "../../helpers/LanguageUtils";
import PropTypes from 'prop-types';
import {useLangFile} from '../../context/LanguageContext';

const getTooltip = (classification, LangFile: LanguageFile) => {
  switch (classification) {
    case SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_LARGE: return LangFile.DemandScale.tooltips.demandHigh;
    case SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_MEDIUM: return LangFile.DemandScale.tooltips.demandMedium;
    case SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_SMALL: return LangFile.DemandScale.tooltips.demandSmall;
    case SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK: return LangFile.DemandScale.tooltips.demandOk;
    case SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SMALL: return LangFile.DemandScale.tooltips.surplusSmall;
    case SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_LARGE: return LangFile.DemandScale.tooltips.surplusLarge;
    case SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SUSPICIOUS: return LangFile.DemandScale.tooltips.surplusSuspicious;
    default: return null;
  }
};

export const renderStandardDemandLegend = (classification, LangFile: LanguageFile, layer: SurveyLayer) => {
  let icon = SURVEY_LAYER_CLASSIFICATION_ICONS[classification];
  let tooltip = getTooltip(classification, LangFile);
  let rangeText = "";
  let classifications = SURVEY_LAYER_CLASSIFICATIONS[layer];
  if (!classifications) {
    return null;
  }

  let range = classifications[classification];
  if (!range) {
    return null;
  }

  let min = Number(range[0]);
  let max = Number(range[1]);

  if (max === Number.MAX_VALUE) {
    rangeText = `${min}+`;
  }
  else {
    rangeText = `${min} - ${max}`;
  }

  let title = (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Box>{tooltip}</Box>
      <Box>{rangeText}</Box>
    </Box>
  );

  return (
    <Tooltip title={title} placement={"bottom"}>
      <Box display={"inline-flex"} alignItems={"center"} flexDirection={"row"}>
        {React.cloneElement(icon, {
          style: {
            fontSize: "16px"
          }
        })}
      </Box>
    </Tooltip>
  );
};

export const renderCustomDemandLegend = (classification, LangFile: LanguageFile) => {
  let icon = SURVEY_LAYER_CLASSIFICATION_ICONS[classification];
  let tooltip = getTooltip(classification, LangFile);
  let title = (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Box>{tooltip}</Box>
    </Box>
  );

  return (
    <Tooltip title={title} placement={"bottom"} key={classification}>
      <Box display={"inline-flex"} alignItems={"center"} flexDirection={"row"}>
        {React.cloneElement(icon, {
          style: {
            fontSize: "16px"
          }
        })}
      </Box>
    </Tooltip>
  );
};

export const renderStandardDemandLegendWithoutRange = (classification, LangFile: LanguageFile) => {
  let icon = SURVEY_LAYER_CLASSIFICATION_NO_RANGE_ICONS[classification];
  let tooltip;
  switch (classification) {
    case SURVEY_LAYER_CLASSIFICATION_NO_RANGE_KEYS.DEMAND:
      tooltip = LangFile.DemandScale.tooltips.demand;
      break;
    case SURVEY_LAYER_CLASSIFICATION_NO_RANGE_KEYS.DEMAND_OK:
      tooltip = LangFile.DemandScale.tooltips.demandOk;
      break;
    case SURVEY_LAYER_CLASSIFICATION_NO_RANGE_KEYS.SURPLUS:
      tooltip = LangFile.DemandScale.tooltips.surplus;
      break;
    default:
      tooltip = null;
      break;
  }

  let title = (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Box>{tooltip}</Box>
    </Box>
  );

  return (
    <Tooltip title={title} placement={"bottom"}>
      <Box display={"inline-flex"} alignItems={"center"} flexDirection={"row"}>
        {React.cloneElement(icon, {
          style: {
            fontSize: "16px"
          }
        })}
      </Box>
    </Tooltip>
  );
};

const DemandScale = (props: DemandScale.propTypes) => {
  const LangFile = useLangFile();
  if (props.isCustomClassification) {
    if (props.showAll) {
      if (props.surveyClassificationValues && props.surveyClassificationValues[props.layer] && props.surveyClassificationValues[props.layer]['mappings']) {
        const mappings = props.surveyClassificationValues[props.layer]['mappings']['FINE'];
        if (mappings) {
          return (
            <Box display={"inline-flex"} width={"100%"} justifyContent={"space-between"} boxSizing={"border-box"} px={props.showAll ? 0 : 2}>
              {Object.keys(mappings).map((key) => {
                return renderCustomDemandLegend(key, LangFile);
              })}
            </Box>
          );
        }
      }
      return;
    }
    return (
      <Box display={"inline-flex"} width={"100%"} justifyContent={"space-between"} boxSizing={"border-box"} px={props.showAll ? 0 : 2}>
        {renderStandardDemandLegendWithoutRange(SURVEY_LAYER_CLASSIFICATION_NO_RANGE_KEYS.DEMAND, LangFile, props.layer)}
        {renderStandardDemandLegendWithoutRange(SURVEY_LAYER_CLASSIFICATION_NO_RANGE_KEYS.DEMAND_OK, LangFile, props.layer)}
        {renderStandardDemandLegendWithoutRange(SURVEY_LAYER_CLASSIFICATION_NO_RANGE_KEYS.SURPLUS, LangFile, props.layer)}
      </Box>
    );
  }

  return (
    <Box display={"inline-flex"} width={"100%"} justifyContent={"space-between"} boxSizing={"border-box"} px={props.showAll ? 0 : 2}>
      {renderStandardDemandLegend(SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_LARGE, LangFile, props.layer)}
      {props.showAll && renderStandardDemandLegend(SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_MEDIUM, LangFile, props.layer)}
      {props.showAll && renderStandardDemandLegend(SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_SMALL, LangFile, props.layer)}
      {renderStandardDemandLegend(SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK, LangFile, props.layer)}
      {props.showAll && renderStandardDemandLegend(SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SMALL, LangFile, props.layer)}
      {props.showAll && renderStandardDemandLegend(SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_LARGE, LangFile, props.layer)}
      {renderStandardDemandLegend(SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SUSPICIOUS, LangFile, props.layer)}
    </Box>
  );
};

DemandScale.propTypes = {
  showAll: PropTypes.bool,
  isCustomClassification: PropTypes.bool,
  surveyClassificationValues: PropTypes.object,
  layer: PropTypes.string,
};

export default memo(
  DemandScale
);

export const getStandardDemandScales = (classificationsEnabled, layer, showAll) => {
  if (classificationsEnabled) {
    let demandScales = [];
    demandScales.push(getStandardDemandScale(SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_LARGE, layer));
    if (showAll) {
      demandScales.push(getStandardDemandScale(SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_MEDIUM, layer));
      demandScales.push(getStandardDemandScale(SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_SMALL, layer));
    }
    demandScales.push(getStandardDemandScale(SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK, layer));
    if (showAll) {
      demandScales.push(getStandardDemandScale(SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SMALL, layer));
      demandScales.push(getStandardDemandScale(SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_LARGE, layer));
    }
    demandScales.push(getStandardDemandScale(SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SUSPICIOUS, layer));
    return demandScales;
  }
  return null;
};

const getStandardDemandScale = (classification, layer: SurveyLayer) => {
  let classifications = SURVEY_LAYER_CLASSIFICATIONS[layer];

  if (!classifications) {
    return null;
  }

  let range = classifications[classification];

  if (!range) {
    return null;
  }

  return {
    classification: classification,
    min: Number(range[0]),
    max: Number(range[1])
  };
};

export const renderStandardDemandScale = (demandScale, langFile) => {
  if (!demandScale) {
    return null;
  }

  let icon = SURVEY_LAYER_CLASSIFICATION_ICONS[demandScale.classification];
  let tooltip = getTooltip(demandScale.classification, langFile);
  let rangeText;
  if (demandScale.max === Number.MAX_VALUE) {
    rangeText = `${demandScale.min}+`;
  }
  else {
    rangeText = `${demandScale.min} - ${demandScale.max}`;
  }

  let title = (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Box>{tooltip}</Box>
      <Box>{rangeText}</Box>
    </Box>
  );

  return (
    <Tooltip title={title} placement={"bottom"}>
      <Box display={"inline-flex"} alignItems={"center"} flexDirection={"row"}>
        {React.cloneElement(icon, {
          style: {
            fontSize: "16px"
          }
        })}
      </Box>
    </Tooltip>
  );
};

export const getDemandScales = (classificationsEnabled, classificationMapping, langFile) => {
  if (classificationsEnabled) {
    return Object.keys(classificationMapping).map((key) => {
      return {
        item: renderDemandScale(key, langFile),
        values: classificationMapping[key],
        count: 0
      };
    });
  }
  return null;
};

export const renderDemandScale = (classification, langFile) => {
  if (!classification) {
    return null;
  }

  const icon = SURVEY_LAYER_CLASSIFICATION_ICONS[classification];
  const tooltip = getTooltip(classification, langFile);
  const title = (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Box>{tooltip}</Box>
    </Box>
  );

  return (
    <Tooltip title={title} placement={"bottom"}>
      <Box display={"inline-flex"} alignItems={"center"} flexDirection={"row"}>
        {React.cloneElement(icon, {
          style: {
            fontSize: "16px"
          }
        })}
      </Box>
    </Tooltip>
  );
};