"use strict";

import React, {memo} from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from "prop-types";

const DemandMedium = (props: DemandMedium.propTypes) => {
  return (
    <SvgIcon
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12">

      <defs>
        <clipPath id="clip-path">
          <rect width="12" height="12" transform="translate(-0.001)" fill="#fff" stroke="#707070" strokeWidth="1"/>
        </clipPath>
      </defs>
      <g transform="translate(0.471 -996)">
        <g transform="translate(-0.471 996)">
          <g transform="translate(0)" clipPath="url(#clip-path)">
            <g transform="translate(6 14.485) rotate(-135)">
              <g transform="translate(0)" fill={props.htmlColor} stroke={props.htmlColor} strokeWidth="1">
                <ellipse cx="6" cy="6" rx="6" ry="6" stroke="none"/>
                <ellipse cx="6" cy="6" rx="5.5" ry="5.5" fill="none"/>
              </g>
              <g transform="translate(0 5.576)" fill="#fff">
                <path d="M 6 6.173900127410889 C 2.907426834106445 6.173900127410889 0.3772510886192322 3.538848400115967 0.2546518743038177 0.2500000596046448 L 11.74534797668457 0.2500000596046448 C 11.62274932861328 3.538848400115967 9.092573165893555 6.173900127410889 6 6.173900127410889 Z" stroke="none"/>
                <path d="M 0.5194082260131836 0.5 C 0.7559843063354492 3.533237934112549 3.123701095581055 5.923900127410889 6 5.923900127410889 C 8.876298904418945 5.923900127410889 11.24401569366455 3.533237934112549 11.48059177398682 0.5 L 0.5194082260131836 0.5 M 0 0 C 3.534669876098633 0 9.235349655151367 0 12 0 C 12 3.547820091247559 9.31371021270752 6.423900127410889 6 6.423900127410889 C 2.68628978729248 6.423900127410889 0 3.547820091247559 0 0 Z" stroke="none" fill="red"/>
              </g>
            </g>
          </g>
        </g>
      </g>

    </SvgIcon>
  );
};

DemandMedium.propTypes = {
  htmlColor: PropTypes.string,
};

DemandMedium.defaultProps = {
  htmlColor: "#F85F5F",
};

export default memo(
  DemandMedium
);