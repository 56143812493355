import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useGraph} from "../../../context/GraphContext";
import {isBarIncomplete} from "../../../helpers/WeatherUtils";


const GraphBar = (props: GraphBar.propTypes) => {

  const {xMap, yLeftMap, yRightMap, height, width} = useGraph();
  const yAxis = props.axis === "left" ? yLeftMap : yRightMap;

  let {points, pointWidth, alpha, color} = props.dataset;
  let incomplete = isBarIncomplete(props.bar.x, props.dataset.until, props.dataset.resolution);

  let barWidth = pointWidth ? (xMap(xMap.domain()[0].valueOf() + pointWidth)) : (xMap.range()[1] - xMap.range()[0]) / points.length;
  let barX = xMap(props.bar.x);
  let barY = yAxis(props.bar.y);

  // crop the bar to the width of the graph
  barWidth = (barX + barWidth) > width ? width - barX : barWidth;

  let barHeight = height - barY;
  let style = {stroke: color, fill: color, opacity: props.opacity || alpha || 1.0};

  if (props.indicateIncompletes && incomplete) {
   style.opacity = 0.2;
  }

  if (barHeight <= 0) {
    return null;
  }

  return (
    <rect
      x={barX}
      y={barY}
      style={style}
      width={barWidth}
      height={barHeight}/>
  );
};

GraphBar.propTypes = {
  bar: PropTypes.object,
  dataset: PropTypes.object,
  opacity: PropTypes.number,
  axis: PropTypes.string,
  indicateIncompletes: PropTypes.bool,
};

GraphBar.defaultProps = {
  indicateIncompletes: false,
};

export default memo(GraphBar);