import {
  memo, useCallback,
  useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  ensureProperZoom,
  loadHeightMap
} from "js/helpers/HeightMapUtils";
import {useGoogleMap} from "js/context/GoogleMapContext";
import {useHookRef} from "../../../hooks/useHookRef";
import useMapEvent from "../../DataLayer/hooks/useMapEvent";

const HeightMap = (props: HeightMap.propTypes) => {

  const googleMap = useGoogleMap();
  
  const mapRef = useHookRef(googleMap);
  const overlaysRef = useHookRef(props.overlays);
  const zoomRef = useRef();

  const updateHandler = useCallback(() => {
    let zoom = mapRef.current.getZoom();

    if (props.shown && zoom !== zoomRef.current) {
      loadHeightMap(mapRef.current, props.shown, zoom, overlaysRef.current);
      zoomRef.current = zoom;
    }
  }, [props.shown]);

  useMapEvent(googleMap, "zoom_changed", updateHandler);

  useEffect(() => {
    if (props.shown) {
      ensureProperZoom(mapRef.current).then(() => {
        let zoom = mapRef.current.getZoom();
        loadHeightMap(mapRef.current, props.shown, zoom, overlaysRef.current);
      });
    }
    else {
      let zoom = mapRef.current.getZoom();
      loadHeightMap(mapRef.current, props.shown, zoom, overlaysRef.current);
    }
  }, [props.shown]);

  return null;
};

HeightMap.propTypes = {
  shown: PropTypes.bool,
  overlays: PropTypes.array,
};

export default memo(
  HeightMap
);