import React, {
  memo
} from 'react';

import PropTypes from 'prop-types';

import Box from "@material-ui/core/Box";

import {grey} from "@material-ui/core/colors";

const StepQuestionBox = (props) => {
  const {title, label} = props;
  
  return (
    <Box {...props} py={2}>
      {title && <Box fontWeight={800}>{title}</Box>}
      {label && <Box color={grey[700]}>{label}</Box>}
    </Box>
  );
};

StepQuestionBox.propTypes = {
  title: PropTypes.any,
  label: PropTypes.any,
};

export default memo(StepQuestionBox);