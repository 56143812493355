import React, {memo, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from "@material-ui/core/Box";
import {KeyboardDatePicker} from "@material-ui/pickers";
import Styles from "./InlineDatePicker.module.less";
import moment from "moment";
import {voidFunc} from "../../constants/PropTypeUtils";
import {useLangFile} from "../../context/LanguageContext";
import clsx from "clsx";

const InlineDatePicker = (props: InlineDatePicker.propTypes) => {

  const LangFile = useLangFile();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [date, setDate] = useState(props.value);

  useEffect(() => {
    setDate(props.value);
  }, [props.value]);

  const handleSetCalendarOpen = (value) => useCallback(() => {
    if (!props.disabled) {
      setCalendarOpen(value);
    }
  }, [props.disabled]);

  const handleOnChange = useCallback((value) => {
    let d = moment(value);
    setDate(d);
    props.onChange(d);
  }, [props.onChange]);

  return (
    <Box>
      <KeyboardDatePicker
        classes={{root: Styles.DatePicker}}
        error={props.error}
        variant={"inline"}
        format={"LL"}
        open={calendarOpen}
        autoOk={true}
        onOpen={handleSetCalendarOpen(true)}
        onClose={handleSetCalendarOpen(false)}
        inputProps={{readOnly: true}}
        InputProps={{
          onClick: handleSetCalendarOpen(true),
          classes: {
            root: clsx({[Styles.InputError]: props.error})
          }
        }}
        allowKeyboardControl={false}
        emptyLabel={LangFile.InlineDatePicker.selectDate}
        label={props.label}
        disabled={props.disabled}
        value={date}
        onChange={handleOnChange}/>
    </Box>
  );
};

InlineDatePicker.propTypes = {
  value: PropTypes.any,
  label: PropTypes.any,
  format: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool
};

InlineDatePicker.defaultProps = {
  label: PropTypes.any,
  format: "LL",
  onChange: voidFunc,
};

export default memo(
  InlineDatePicker
);
