import {
  memo,
  useEffect,
  useRef
} from 'react';
import { connect } from 'react-redux';
import {fetchCropHealth} from "js/reducers/FieldReducer";

const mapStateToProps = (store) => {
  return {
    farm: store.farm.farm,
    dates: store.field.dates,
    date: store.field.date,
    fields: store.field.fields,
    fieldsLoaded: store.field.fieldsLoaded,
    images: store.field.images,
    syncing: store.sync.syncing,
    loggedIn: store.credential.loggedIn
  };
};

const CrophealthLoaderContainer = ({dispatch, dates, fields, farm, date, images, syncing, loggedIn, fieldsLoaded}) => {

  let currentDates = useRef(dates);
  let farmId = farm.farmId;

  useEffect(() => {
    if (dates && fields && loggedIn && !syncing && fieldsLoaded) {
      let datesChanged = currentDates.current !== dates;
      let dateNotLoaded = !images.get(date);

      if (datesChanged || dateNotLoaded) {
        dispatch(fetchCropHealth(farmId, date, fields));
        currentDates.current = dates;
      }
    }

  }, [currentDates.current, dates, date, fields, farmId, images, syncing, loggedIn, fieldsLoaded]);

  return null;
};

export default memo(
  connect(mapStateToProps)(
    CrophealthLoaderContainer
  )
);