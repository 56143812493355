"use strict";

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 100 100">
      <path d="M95,24.852H5v20.149h44.875H95V24.852z M91,41.001H9V28.852h82V41.001z"/>
      <path d="M49.875,75.251H65l-15.125-30.25L34.75,75.251H49.875z M58.528,71.251h-8.653h-8.653l8.653-17.306L58.528,71.251z"/>
    </SvgIcon>
  );
};
