import React, {memo} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import {useLangFile} from "js/context/LanguageContext";
import {WeatherPeriod} from "js/constants/WeatherConstants";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const styles = (theme) => ({
  button: {
    minWidth: 54,
    padding: [[theme.spacing(1), theme.spacing(1)]],
    margin: 2,
  },

  group: {
    minHeight: 54,
  },

  text: {
    whiteSpace: 'nowrap',
  },

});

const PeriodButton = ({value, period, onSelectPreset, classes}) => {
  const LangFile = useLangFile();
  const selected = period === value;

  return (
    <Button
      onClick={() => {
        if (!selected) {
          onSelectPreset(value);
        }
      }}
      variant={selected ? "contained" : "outlined"}
      color={selected ? "primary" : "default"}
      className={classes.button}>
      <div className={classes.text}>
        {LangFile.WeatherHistoryPeriodSelector.intervalsSelect[value]}
      </div>
    </Button>
  );
};

const WeatherHistoryPeriodSelector = ({classes, period, onSelectPreset}) => {
  return (
    <Box pl={0.5}>
      <ButtonGroup color={"primary"} size={"medium"} className={classes.group}>
        <PeriodButton value={WeatherPeriod.DAY} period={period} onSelectPreset={onSelectPreset} classes={classes}/>
        <PeriodButton value={WeatherPeriod.WEEK} period={period} onSelectPreset={onSelectPreset} classes={classes}/>
        <PeriodButton value={WeatherPeriod.WEEKS4} period={period} onSelectPreset={onSelectPreset} classes={classes}/>
      </ButtonGroup>
    </Box>
  );
};

WeatherHistoryPeriodSelector.propTypes = {
  period: PropTypes.string,
  onSelectPreset: PropTypes.func,
};

export default memo(withStyles(styles)(WeatherHistoryPeriodSelector));