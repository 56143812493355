import {MercatorProjection} from "js/algorithms/MercatorProjection";

export function loadWMS(map, baseURL, customParams, layer) {
  const tileHeight = 256;
  const tileWidth = 256;
  const opacityLevel = layer.opacity;
  const isPng = true;
  const minZoomLevel = 1;
  const maxZoomLevel = 20;

  // noinspection JSUnusedGlobalSymbols
  const overlayOptions =
  {
    name: layer.name,
    getTile: function (coord, zoom, ownerDocument) {
      var div = ownerDocument.createElement('div');

      div.innerHTML = "";
      div.style.width = tileWidth + 'px';
      div.style.height = tileHeight + 'px';
      div.style.opacity = opacityLevel;
      div.style.background = "url(" + getTileUrl(baseURL, customParams, tileWidth, tileHeight, coord, zoom) + ")";
      // TEST div.style.filter = "brightness(1000%)";

      return div;
    },
    getTileUrl: (coord, zoom) => getTileUrl(baseURL, customParams, tileWidth, tileHeight, coord, zoom),
    tileSize: new google.maps.Size(tileHeight, tileWidth),
    minZoom: minZoomLevel,
    maxZoom: maxZoomLevel,
    opacity: opacityLevel,
    isPng: isPng
  };

  map.overlayMapTypes.removeAt(layer.order);
  map.overlayMapTypes.setAt(`${layer.order}`, overlayOptions);
}

function getTileUrl(baseURL, customParams, tileWidth, tileHeight, coord, zoom) {
  let wmsParams = [
    "REQUEST=GetMap",
    "SERVICE=WMS",
    "VERSION=1.1.1",
    "BGCOLOR=0xFFFFFF",
    "TRANSPARENT=TRUE",
    "WIDTH=" + tileWidth,
    "HEIGHT=" + tileHeight
  ].concat(customParams);

  const lULP = new google.maps.Point(coord.x * 256, (coord.y + 1) * 256);
  const lLRP = new google.maps.Point((coord.x + 1) * 256, coord.y * 256);

  const projectionMap = new MercatorProjection();

  const lULg = projectionMap.fromDivPixelToSphericalMercator(lULP, zoom);
  const lLRg = projectionMap.fromDivPixelToSphericalMercator(lLRP, zoom);

  const lulLatitude = lULg.y;
  const lulLongitude = lULg.x;
  const llrLatitude = lLRg.y;
  let llrLongitude = lLRg.x;

  if (llrLongitude < lulLongitude) {
    llrLongitude = Math.abs(llrLongitude);
  }
  return baseURL + "&" + wmsParams.join("&") + "&bbox=" + lulLongitude + "," + lulLatitude + "," + llrLongitude + "," + llrLatitude;
}