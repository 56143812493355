import React, {memo} from 'react';
import {Box, Typography, Checkbox, Radio, ListItem} from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from './FieldOverviewListItem.module.less';
import {useTheme} from '@material-ui/core/styles';

const FieldOverviewListItem = (props: FieldOverviewListItem.prototype) => {
  const {title, subtitle, selected, checked, showCheckbox, showThumbnail, showRadiobutton, onClick, onCheckboxClick} = props;

  const theme = useTheme();
  const color = selected ? theme.palette.primary.light : 'white';

  return (
    <Box bgcolor={color} width={"100%"}>
      <ListItem className={Styles.item} disableGutters={true} onClick={onClick} dense={true} disabled={props.disabled}>
        <Box mx={4} display={'flex'} alignItems={'center'}>
          {showCheckbox && (
            <Checkbox
              className={Styles.checkbox}
              checked={Boolean(checked)}
              color={'primary'}
              onClick={(e) => {
                e.stopPropagation();
                onCheckboxClick(!checked);
              }}
              disableRipple/>
          )}

          {showRadiobutton && (
            <Radio
              checked={Boolean(selected)}
              disableRipple
              disabled/>
          )}

          {showThumbnail && (
            <Box mx={1} width={46} height={46} className={Styles.imageHolder}></Box>
          )}

          <Box mx={1}>
            <Typography className={Styles.text}><b>{title}</b></Typography>
            <Typography className={Styles.text}>{subtitle}</Typography>
          </Box>
        </Box>
      </ListItem>
    </Box>
  );
};

FieldOverviewListItem.prototype = {
  id: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageUrl: PropTypes.string,
  selected: PropTypes.bool,
  checked: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  showRadiobutton: PropTypes.bool,
  showThumbnail: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onCheckboxClick: PropTypes.func
};

FieldOverviewListItem.defaultProps = {
  showThumbnail: false,
  showRadiobutton: true,
  showCheckbox: false,
  disabled: false,
};

export default memo(FieldOverviewListItem);
