import React, {memo, useEffect, useState} from 'react';
import ViewModeConstants from '../../constants/ViewModeConstants';
import connect from 'react-redux/lib/connect/connect';
import {Box, Paper} from '@material-ui/core';
import Styles from './SurveyStatisticsContainer.module.less';
import SurveyStatistics from './SurveyStatistics';
import {useColoringStrategy} from '../../model/surveys/useColoringStrategy';
import {getDemandScales, getStandardDemandScales, renderStandardDemandScale} from '../DemandScale/DemandScale';
import {useLangFile} from '../../context/LanguageContext';
import {
  generateStatisticsData,
  generateStatisticsDataForClassification,
  getSurveyScaleItem
} from './SurveyStatisticsUtils';
import {useSurveyLayerViewCapabilities} from '../../context/SurveyContext';
import {SURVEY_LAYERS} from "../../constants/SurveyLayers";

const mapStateToProps = (store) => {
  return {
    selectedField: store.field.selectedField,
    viewMode: store.control.viewMode,
    showSatelliteImagery: store.field.showSatelliteImagery,
    showSoilSurveys: store.survey.showSoilSurveys,
    variationsEnabled: store.survey.variationsEnabled,
    classificationsEnabled: store.survey.classificationsEnabled,
    selectedLayer: store.survey.selectedLayer,
    statisticsEnabled: store.survey.statisticsEnabled,
    selectedSurveyReferenceValues: store.survey.selectedSurveyReferenceValues,
    selectedSurveyClassificationValues: store.survey.selectedSurveyClassificationValues
  };
};

const SurveyStatisticsContainer = ({
                                     viewMode,
                                     showSatelliteImagery,
                                     selectedLayer,
                                     classificationsEnabled,
                                     variationsEnabled,
                                     selectedField,
                                     statisticsEnabled,
                                     selectedSurveyReferenceValues,
                                     selectedSurveyClassificationValues
                                   }) => {

  const LangFile = useLangFile();
  const [statisticsData, setStatisticsData] = useState(null);
  const [values, setValues] = useState(null);
  const surveyLayerViewCapabilities = useSurveyLayerViewCapabilities(selectedLayer, viewMode);
  const coloringStrategy = useColoringStrategy(selectedLayer, viewMode, classificationsEnabled, variationsEnabled, values);
  const surveyScale = coloringStrategy.scale;


  useEffect(() => {
    if (selectedSurveyReferenceValues && selectedLayer) {
      setValues(selectedSurveyReferenceValues[selectedLayer]);
    }
  }, [selectedLayer, selectedSurveyReferenceValues]);

  useEffect(() => {
    let decimals = selectedLayer === SURVEY_LAYERS.MOLYBDENUM ? 2 : 1;

    if (statisticsEnabled && !showSatelliteImagery && values && selectedField) {
      if (surveyScale) {
        const buckets = surveyScale.map((scale, idx, arr) => {
          return {
            item: getSurveyScaleItem(scale, idx, arr, true, 2, null),
            min: Number(scale.min),
            max: Number(scale.max),
            count: 0
          };
        });
        setStatisticsData(generateStatisticsData(buckets, values, selectedField, decimals));
      }
      else if (classificationsEnabled) {
        if (surveyLayerViewCapabilities.enableCustomClassification) {
          if (selectedSurveyClassificationValues[selectedLayer] && selectedSurveyClassificationValues[selectedLayer]['mappings']) {
            const classificationMappings = selectedSurveyClassificationValues[selectedLayer]['mappings']['FINE'];
            const classificationValues = selectedSurveyClassificationValues[selectedLayer]['values'];
            const buckets = getDemandScales(classificationsEnabled, classificationMappings, LangFile);
            setStatisticsData(generateStatisticsDataForClassification(buckets, classificationValues, values, selectedField, decimals));
          }
          else {
            setStatisticsData(null);
          }
        }
        else {
          const buckets = getStandardDemandScales(classificationsEnabled, selectedLayer, viewMode !== ViewModeConstants.OVERVIEW).map((scale) => {
            return {
              item: renderStandardDemandScale(scale, LangFile),
              min: scale.min,
              max: scale.max,
              count: 0
            };
          });
          setStatisticsData(generateStatisticsData(buckets, values, selectedField, decimals));
        }
      }
      else {
        setStatisticsData(null);
      }
    }
    else {
      setStatisticsData(null);
    }
  }, [statisticsEnabled, showSatelliteImagery, surveyScale, values, selectedField, selectedLayer, viewMode, classificationsEnabled, selectedSurveyClassificationValues]);

  const isSingleField = viewMode === ViewModeConstants.ANALYSIS || viewMode === ViewModeConstants.PRESCRIPTION;

  if (showSatelliteImagery || !statisticsEnabled || !isSingleField) {
    return null;
  }

  return (
    <Box
      position={"relative"}
      minWidth={180}
      maxWidth={180}>
      <Paper
        elevation={2}
        className={Styles.paper}>
        {(statisticsData) && (
          <SurveyStatistics
            data={statisticsData}
            width={130}/>
        )}
      </Paper>
    </Box>
  );
};

export default memo(
  connect(mapStateToProps)(
    SurveyStatisticsContainer
  )
);
