import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useLangFile} from "js/context/LanguageContext";
import type {LAYER_CONFIG} from "js/model/surveys/SurveyLayerConfig";
import ListItem from "@material-ui/core/ListItem";
import {ListItemIcon, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedOutlined from "@material-ui/icons/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlined from "@material-ui/icons/RadioButtonCheckedOutlined";
import {getLayerConfig} from "js/components/Prescription/PrescriptionUtils";
import {CheckBoxOutlineBlankOutlined, CheckBoxOutlined} from "@material-ui/icons";
import {voidFunc} from "js/constants/PropTypeUtils";
import useShiftKeyDown from "js/hooks/useShiftKeyDown";

const SatelliteLayerListItem = (props: SatelliteLayerListItem.propTypes) => {

  const LangFile = useLangFile();
  const config: LAYER_CONFIG = getLayerConfig(props.layer);
  const shiftDown = useShiftKeyDown();

  let handleOnClick = useCallback(() => {
    if (shiftDown && props.enableMultipleSelect) {
      props.onCheckboxClick(props.layer);
    }
    else {
      props.onClick(props.layer);
    }
  }, [props.layer, props.onClick, shiftDown, props.enableMultipleSelect, props.onCheckboxClick]);

  let handleOnCheckboxClicked = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (props.enableMultipleSelect) {
      props.onCheckboxClick(props.layer);
    }
    else {
      props.onClick(props.layer);
    }
  }, [props.layer, props.enableMultipleSelect, props.onClick, props.onCheckboxClick]);

  if (!config) {
    return null;
  }

  let icon = props.enableMultipleSelect ? <CheckBoxOutlineBlankOutlined fontSize="small"/> : <RadioButtonUncheckedOutlined fontSize="small"/>;
  let checkedIcon = props.enableMultipleSelect ? <CheckBoxOutlined fontSize="small"/> : <RadioButtonCheckedOutlined fontSize="small"/>;
  let title = config.getName(LangFile);
  const shortHand = config.getShorthand(LangFile);
  if (shortHand) {
    title = title + ` (${shortHand})`;
  }

  return (
    <ListItem
      dense={true}
      button={true}
      disabled={props.disabled}
      onClick={handleOnClick}
      key={props.layer}>
      {!props.disableCheckbox && (
        <ListItemIcon style={{minWidth: 36}}>
          <Checkbox
            style={{padding: 0}}
            checked={props.isSelected}
            onClick={handleOnCheckboxClicked}
            icon={icon}
            checkedIcon={checkedIcon}
            color="primary"/>
        </ListItemIcon>
      )}
      <ListItemText primary={title}/>
      <ListItemSecondaryAction style={{pointerEvents: "none"}}>
        <img src={config.icon} style={{width: 25, height: 25, marginRight: -8}} alt={""}/>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

SatelliteLayerListItem.propTypes = {
  layer: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onCheckboxClick: PropTypes.func,
  disabled: PropTypes.bool,
  disableCheckbox: PropTypes.bool,
  enableMultipleSelect: PropTypes.bool,
};

SatelliteLayerListItem.defaultProps = {
  onClick: voidFunc,
  onCheckboxClick: voidFunc,
};

export default memo(
  SatelliteLayerListItem
);
