import React, {memo} from 'react';
import {AppSettingsProvider} from "../../context/AppSettings/AppSettingsContext";
import connect from "react-redux/lib/connect/connect";

const mapStateToProps = (store) => {
  return {
    appSettings: store.appSettings
  };
};

const AppSettingsContainer = (props: AppSettingsContainer.propTypes) => {

  return (
    <AppSettingsProvider value={props.appSettings}>
      {props.children}
    </AppSettingsProvider>
  );
};

AppSettingsContainer.propTypes = {
    
};

export default memo(
  connect(mapStateToProps)(
    AppSettingsContainer
  )
);
