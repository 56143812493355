import {createLogger} from "redux-logger";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import PromiseRejectionMiddleware from "js/middleware/PromiseRejectionMiddleware";
import {applyMiddleware, createStore} from "redux";
import {
  blue,
  green,
  red
} from "@material-ui/core/colors";

const InitStore = (reducer, initState=undefined, logState=false) => {

  const middleware = [];

  middleware.push(thunk);
  middleware.push(promise());

  if (logState) {
    // This middleware will log the state in development, and is in charge of resolving promises in payloads.
    middleware.push(createLogger({
      collapsed: (getState, action, logEntry) => !logEntry.error,
      colors: {
        title: (action) => {
          if (action.type && typeof action.type === "string") {
            let isPending = action.type.endsWith("_PENDING");
            let isFulfilled = action.type.endsWith("_FULFILLED");
            let isRejected = action.type.endsWith("_REJECTED");

            if (isPending) {
              return blue["A200"];
            }

            if (isFulfilled) {
              return green["A700"];
            }

            if (isRejected) {
              return red["A400"];
            }
          }

          return 'inherit';
        },
        prevState: () => '#9E9E9E',
        action: () => '#03A9F4',
        nextState: () => '#4CAF50',
        error: () => '#F20404',
      }
    }));

    middleware.push(PromiseRejectionMiddleware);
  }


  // Create the actual store from our reducers using the given middleware
  return createStore(reducer, initState, applyMiddleware(...middleware));
};

export default InitStore;