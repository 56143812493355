// @flow

import React, {
  memo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogContent,
  ButtonBase,
  Box,
  Tooltip, Button,
} from '@material-ui/core';

import style from "./PrescriptionSourceDialog.module.less";
import Paper from "@material-ui/core/Paper";
import {useLangFile} from "js/context/LanguageContext";
import ManualFeatureConstants from "js/constants/ManualFeatureConstants";
import {blueGrey, grey} from "@material-ui/core/colors";
import SoilNutrients from "../../../Icons/SoilNutrients";
import SatelliteImagery from "../../../Icons/SatelliteImagery";
import SoilTexture from "../../../Icons/SoilTexture";
import Topography from "../../../Icons/Topography";
import {SOURCE_CATEGORY, type SourceCategory} from "../../PrescriptionJob";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../../../hooks/useFirebaseAnalytics";

const SourceOption = ({title, tooltip, disabledTooltip, icon, onClick, disabled, banner, subTitle}) => {
  return (
    <Tooltip title={disabled ? disabledTooltip : tooltip}>
      <Box
        height={180}
        width={1}
        m={2}>
        <Paper
          elevation={disabled ? 0 : 4}
          className={style.Paper}>
          <ButtonBase
            classes={{root: style.Button}}
            disabled={disabled}
            onClick={onClick}
            focusRipple>

            <Box
              className={style.Title}
              bgcolor={disabled ? grey[100] : "white"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              height={"100%"}>
              <Box pb={2}>
                {icon}
              </Box>
              <Box>{title}</Box>
              <Box className={style.SubTitle}>{subTitle}</Box>
            </Box>

            {banner && <Box className={style.Banner}>{banner}</Box>}
          </ButtonBase>
        </Paper>
      </Box>
    </Tooltip>
  );
};

const iconSize = "64px";
const iconColor = blueGrey["800"];
const iconProps = {style: {fontSize: iconSize}, htmlColor: iconColor};

const PrescriptionSourceDialog = ({onExit, open, enableSurveys, enableNdvi, onSelectSourceCategory}) => {

  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();

  const handleOnExit = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.VRM_NEW_CATEGORY_CANCEL);
    onExit();
  }, [onExit]);

  const handleOnSelect = (category: SourceCategory) => useCallback(() => {
    onSelectSourceCategory(category);
    
    switch (category.toUpperCase()) {
      case "VITALITY": {
        analytics.logEvent(FIREBASE_EVENTS.VRM_NEW_CATEGORY_SATELLITE);
        break;
      }
      case "NUTRIENT": {
        analytics.logEvent(FIREBASE_EVENTS.VRM_NEW_CATEGORY_NUTRIENTS);
        break;
      }
      case "TEXTURE":{
        analytics.logEvent(FIREBASE_EVENTS.VRM_NEW_CATEGORY_TEXTURE);
        break;
      }
      case "TOPOGRAPHY":{
        analytics.logEvent(FIREBASE_EVENTS.VRM_NEW_CATEGORY_TOPOGRAPHY);
        break;
      }
      default: {
        break;
      }
    }
  }, []);

  if (!ManualFeatureConstants.DA_SOILOPTIX_SURVEYS) {
    let automaticSetter = handleOnSelect(SOURCE_CATEGORY.VITALITY);
    automaticSetter();
    return null;
  }

  let soilOptixBanner = !enableSurveys && "SoilOptix";
  let soilOptixSubtitle = enableSurveys ? "" : LangFile.PrescriptionSourceDialog.purchase;

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={"lg"}>
      <DialogContent
        className={style.Content}
        dividers>

        <Box
          py={2}
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}>
          <Box
            fontWeight={"bold"}
            fontSize={"1.5rem"}>
            {LangFile.PrescriptionSourceDialog.title}
          </Box>

          <Box fontSize={"1.25rem"} pt={1}>
            {LangFile.PrescriptionSourceDialog.subtitle}
          </Box>

          <Box
            py={2}
            width={"100%"}
            display={"inline-flex"}
            justifyContent={"space-between"}
            flexWrap={"no-wrap"}>
            <SourceOption
              title={LangFile.PrescriptionSourceDialog.options.satellite}
              disabled={!enableNdvi}
              subTitle={""}
              tooltip={LangFile.PrescriptionSourceDialog.select}
              disabledTooltip={LangFile.PrescriptionSourceDialog.noImage}
              icon={<SatelliteImagery {...iconProps}/>}
              onClick={handleOnSelect(SOURCE_CATEGORY.VITALITY)}/>

            <SourceOption
              title={LangFile.PrescriptionSourceDialog.options.nutrients}
              subTitle={soilOptixSubtitle}
              banner={soilOptixBanner}
              disabled={!enableSurveys}
              tooltip={LangFile.PrescriptionSourceDialog.select}
              disabledTooltip={LangFile.PrescriptionSourceDialog.purchase}
              icon={<SoilNutrients {...iconProps}/>}
              onClick={handleOnSelect(SOURCE_CATEGORY.NUTRIENT)}/>

            <SourceOption
              title={LangFile.PrescriptionSourceDialog.options.texture}
              subTitle={soilOptixSubtitle}
              banner={soilOptixBanner}
              disabled={!enableSurveys}
              tooltip={LangFile.PrescriptionSourceDialog.select}
              disabledTooltip={LangFile.PrescriptionSourceDialog.purchase}
              icon={<SoilTexture {...iconProps}/>}
              onClick={handleOnSelect(SOURCE_CATEGORY.TEXTURE)}/>

            <SourceOption
              title={LangFile.PrescriptionSourceDialog.options.topography}
              subTitle={soilOptixSubtitle}
              banner={soilOptixBanner}
              disabled={!enableSurveys}
              tooltip={LangFile.PrescriptionSourceDialog.select}
              disabledTooltip={LangFile.PrescriptionSourceDialog.purchase}
              icon={<Topography {...iconProps}/>}
              onClick={handleOnSelect(SOURCE_CATEGORY.TOPOGRAPHY)}/>
          </Box>

          <Button onClick={handleOnExit} variant={"contained"}>
            {LangFile.PrescriptionSourceDialog.cancel}
          </Button>

        </Box>

      </DialogContent>
    </Dialog>
  );
};

PrescriptionSourceDialog.propTypes = {
  onExit: PropTypes.func,
  onSelectSourceCategory: PropTypes.func,
  open: PropTypes.bool,
  enableSurveys: PropTypes.bool,
  enableNdvi: PropTypes.bool,
};

export default memo(PrescriptionSourceDialog);