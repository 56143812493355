import {memo, useEffect} from 'react';

const CLAAS_OAUTH_STATE_KEY = 'claas-oauth2-state-key';
const CLAAS_OAUTH_RESPONSE = 'claas-oauth2-response';

const checkState = (receivedState) => {
  const state = localStorage.getItem(CLAAS_OAUTH_STATE_KEY);
  return state === receivedState;
};

const queryToObject = (query) => {
  const parameters = new URLSearchParams(query);
  return Object.fromEntries(parameters.entries());
};


const DataIntegrationSuccessContainer = () => {
  useEffect(() => {
    const payload = queryToObject(window.location.search.split('?')[1]);
    const state = payload && payload.state;
    const error = payload && payload.error;

    if (!window.opener) {
      throw new Error('No window opener');
    }

    if (error) {
      window.opener.postMessage({
        type: CLAAS_OAUTH_RESPONSE,
        error: decodeURI(error) || 'OAuth error: An error has occured.',
      });
    }
    else if (state && checkState(state)) {
      window.opener.postMessage({
        type: CLAAS_OAUTH_RESPONSE,
        payload,
      });
    }
    else {
      window.opener.postMessage({
        type: CLAAS_OAUTH_RESPONSE,
        error: 'OAuth error: State mismatch.',
      });
    }
  }, []);

  return null;
};

export default memo(DataIntegrationSuccessContainer);
