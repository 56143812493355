import {Box, Typography} from "@material-ui/core";
import Styles from "../FieldStatisticsContainer.module.less";
import React, {memo} from "react";

const EmptyColumnCell = ({}) => {
  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
      <Typography className={Styles.title}>-</Typography>
    </Box>
  );
};

export default memo(
  EmptyColumnCell
);
