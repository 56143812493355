import React from 'react';

import RaisedButton from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import {
  Delete,
  Add,
  Done
} from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  Paper,
  TableCell
} from '@material-ui/core';

export default class FarmUserTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {userToAddName: "", userToAddIsAdmin: false, nameModified: false};
  }

  changeUserToAddName = (event) => {
    this.setState({userToAddName: event.target.value});
  };

  changeUserToAddIsAdmin = (event, isInputChecked) => {
    this.setState({userToAddIsAdmin: isInputChecked});
  };

  addUser = () => {
    this.props.addUser({email: this.state.userToAddName, admin: this.state.userToAddIsAdmin});
    this.setState({userToAddName: "", userToAddIsAdmin: false});
  };

  renderDeleteButton = (userId) => {
    if (!this.props.isAdmin) {
      return null;
    }

    return (
      <div className="button-wrapper">
        <RaisedButton
          className={"delete-button"}
          onClick={() => this.props.removeUser(userId)}
          color={"secondary"}
          variant="contained">
          <Delete style={{marginRight: 10}}/> {this.props.LangFile.FarmUserTable.removeUser}
        </RaisedButton>
      </div>
    );
  };

  renderUserList = () => {
    let users = this.props.farm.users;

    if (!users || users.length === 0) {
      return null;
    }

    let userList = [];


    users.filter((u) => u.role !== "owner").forEach((user) => {
      let admin = user.role === "admin";

      userList.push(
        <TableRow key={user.userId}>
          <TableCell className={"user-email"}>
            {user.email}
          </TableCell>
          <TableCell className={"user-admin"}>
            {admin ? <Done style={{marginLeft: 8}}/> : ""}
          </TableCell>
          <TableCell className={"user-delete"}>
            {this.renderDeleteButton(user.userId)}
          </TableCell>
        </TableRow>
      );
    });
    return userList;
  };

  render() {
    let addUserSection = null;
    if (this.props.isAdmin) {
      addUserSection =
        <TableRow style={{backgroundColor: "rgba(220,220,220,0.1)"}}>
          <TableCell>
            <TextField
              helperText={this.props.LangFile.FarmUserTable.mailPlaceholder}
              value={this.state.userToAddName}
              onChange={this.changeUserToAddName}/>
          </TableCell>
          <TableCell className="add-user-row">
            <Checkbox
              onChange={this.changeUserToAddIsAdmin}
              checked={this.state.userToAddIsAdmin}
              defaultChecked={false}/>
          </TableCell>
          <TableCell>
            <div className="button-wrapper">
              <RaisedButton
                onClick={this.addUser}
                variant="contained"
                color={"primary"}>
                <Add style={{marginRight: 10}}/> {this.props.LangFile.FarmUserTable.addUser}
              </RaisedButton>
            </div>
          </TableCell>
        </TableRow>;
    }

    return (
      <Paper elevation={1} style={{marginTop: 24}}>
        <Table
          className={"farm-user-table"}>
          <TableBody>
            <TableRow>
              <TableCell
                className={"table-header"}>{this.props.LangFile.FarmUserTable.users}</TableCell>
              <TableCell
                className={"table-header center"}>{this.props.LangFile.FarmUserTable.admin}</TableCell>
              <TableCell
                className={"table-header center"}>{this.props.LangFile.FarmUserTable.action}</TableCell>
            </TableRow>

            {this.renderUserList()}

            {addUserSection}

          </TableBody>
        </Table>
      </Paper>
    );
  }
}
