import React from 'react';

import Dialog from "@material-ui/core/Dialog";
import {DialogContent} from '@material-ui/core';
import UploadFile from "js/components/UploadFile/UploadFile";
import {useLangFile} from "../../context/LanguageContext";

const UploadFileDialog = (props) => {
  const LangFile = useLangFile();

  return (
    <Dialog open={props.shown} maxWidth={"md"} fullWidth={true}>
      <DialogContent>
        <UploadFile
          {...props}
          onImportText={LangFile.UploadFileDialog.import}
          onCancelText={LangFile.UploadFileDialog.close}/>
      </DialogContent>
    </Dialog>
  );
};

export default UploadFileDialog;
