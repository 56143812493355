import {
  SURVEY_NUTRIENT_LAYERS,
  SURVEY_TEXTURE_LAYERS,
  SURVEY_TOPOGRAPHY_LAYERS,
  type SurveyLayer,
} from "../constants/SurveyLayers";
import {SOURCE_CATEGORY, type SourceCategory} from "../components/Prescription/PrescriptionJob";
import type {FieldLayer} from "../constants/FieldLayers";

export const getSourceCategory = (layer: FieldLayer): SourceCategory => {
  if (SURVEY_NUTRIENT_LAYERS.includes(layer)) {
    return SOURCE_CATEGORY.NUTRIENT;
  }
  if (SURVEY_TEXTURE_LAYERS.includes(layer)) {
    return SOURCE_CATEGORY.TEXTURE;
  }
  if (SURVEY_TOPOGRAPHY_LAYERS.includes(layer)) {
    return SOURCE_CATEGORY.TOPOGRAPHY;
  }
  return SOURCE_CATEGORY.VITALITY;
};

export const iterateRawValues = (values, callback) => {
  if (!values || typeof callback !== "function") {
    return;
  }

  let height = values.length;
  let width = values[0].length;

  for (let y = 0; y < height; y++) {
    let row = values[y];

    for (let x = 0; x < width; x++) {
      let value = row[x];

      if (value !== null && value !== undefined) {
        callback(x, y, value);
      }
    }
  }
};

export const iterateValidOverrides = (overrides, callback) => {
  return iterateRawValues(overrides, (x, y, value) => {
    // -1 is the "empty" value, and 0 is a valid override value
    if (value > -1) {
      callback(x, y, value);
    }
  });
};

export const iterateValidValues = (values, callback, includeZero = false) => {
  return iterateRawValues(values, (x, y, value) => {
    if (value > 0 || (includeZero && value >= 0)) {
      callback(x, y, value);
    }
  });
};

export const getMinMaxValues = (values, includeZero) => {
  if (!values) {
    return null;
  }

  let min = Number.MAX_VALUE;
  let max = Number.MIN_VALUE;

  iterateValidValues(values, (x, y, value) => {
    if (typeof value === "number") {
      if (value < min) {
        min = value;
      }
      if (value > max) {
        max = value;
      }
    }
  }, includeZero);

  if (max === Number.MIN_VALUE) {
    max = min;
  }

  return {min, max};
};

export class SurveyViewCapabilities {
  overview: Map<SurveyLayer, SurveyLayerViewCapabilities>;
  singleField: Map<number /* Survey ID */, Map<SurveyLayer, SurveyLayerViewCapabilities>>;

  getSurveyLayerViewCapabilities(layer, surveyId): SurveyLayerViewCapabilities {
    if (surveyId) {
      let caps = this.singleField.get(surveyId);
      if (caps) {
        return caps.get(layer);
      }
    }
    else {
      let caps = this.overview.get(layer);
      if (caps) {
        return caps;
      }
    }
    return new SurveyLayerViewCapabilities();
  }

  constructor(overview: Map<SurveyLayer, SurveyLayerViewCapabilities> = new Map(), singleField: Map<number, Map<SurveyLayer, SurveyLayerViewCapabilities>> = new Map()) {
    this.overview = overview;
    this.singleField = singleField;
  }
}

export class SurveyLayerViewCapabilities {
  enableRaw: boolean;
  enableVariations: boolean;
  enableClassifications: boolean;
  enableCustomClassification: boolean;

  constructor(enableRaw: boolean = false, enableDetails: boolean = false, enableClassifications: boolean = false, enableCustomClassification: boolean = false) {
    this.enableRaw = enableRaw;
    this.enableVariations = enableDetails;
    this.enableClassifications = enableClassifications;
    this.enableCustomClassification = enableCustomClassification;
  }
}

