import useEvent from "js/hooks/useEvent";
import {
  useCallback,
  useState
} from "react";

const checkHeight = (heightBreakpoint) => window.innerHeight < heightBreakpoint;

function useLowScreen() {
  const heightBreakpoint = 900;
  const [low, setLow] = useState(checkHeight(heightBreakpoint));

  useEvent('resize', useCallback(() => {
    setLow(checkHeight(heightBreakpoint));
  }, []));

  return low;
}

export default useLowScreen;