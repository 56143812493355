import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import {useLangFile} from "js/context/LanguageContext";

const DialogConfirmArchiveSeason = ({open, onClose, onConfirm}) => {

  let LangFile = useLangFile();

  return (
    <Dialog fullWidth maxWidth={"sm"} open={open} onClose={onClose}>
      <DialogTitle>
        {LangFile.DialogConfirmArchiveSeason.archiveSeasonAction}?
      </DialogTitle>
      <DialogContent>
        <p>{LangFile.DialogConfirmArchiveSeason.archiveSeason}</p>
        <p>{LangFile.DialogConfirmArchiveSeason.accessibleInFuture}</p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}>
          {LangFile.DialogConfirmArchiveSeason.abort}</Button>
        <Button
          color="secondary"
          variant={"contained"}
          onClick={onConfirm}>
          {LangFile.DialogConfirmArchiveSeason.archiveSeasonAction}</Button>
      </DialogActions>
    </Dialog>
  );
};

DialogConfirmArchiveSeason.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default DialogConfirmArchiveSeason;