import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import useGoogleMapCenter from "js/hooks/useGoogleMapCenter";
import TextFormControl from "js/components/UI-Elements/TextFormControl";
import {
  LAT_REGEX,
  LNG_REGEX
} from "js/constants/RegexConstants";
import {useLangFile} from "js/context/LanguageContext";
import Button from "@material-ui/core/Button";
import {useGoogleMap} from "js/context/GoogleMapContext";
import {voidFunc} from "js/constants/PropTypeUtils";

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
    pointerEvents: 'none',
    userSelect: 'none',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  xAxis: {
    position: 'absolute',
    opacity: 0.8,
    left: 0,
    right: 0,
    top: '50%',
    height: 2,
    transform: 'translateY(-50%)',
    backgroundColor: theme.palette.secondary.main,
  },
  yAxis: {
    position: 'absolute',
    opacity: 0.8,
    left: '50%',
    bottom: 0,
    top: 0,
    width: 2,
    transform: 'translateX(-50%)',
    backgroundColor: theme.palette.secondary.main,
  },
  popover: {
    pointerEvents: 'all',
    backgroundColor: theme.palette.common.white,
    opacity: 1,
    borderRadius: 4,
    padding: [[theme.spacing(1), theme.spacing(1)]],
    position: 'absolute',
    left: theme.spacing(2),
    top: 64,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(1),
  }
});

const getLatLng = (target) => {
  if (target) {
    let lat = typeof target.lat === "function" ? target.lat() : target.lat;
    let lng = typeof target.lng === "function" ? target.lng() : target.lng;

    return new google.maps.LatLng(lat, lng);
  }
};

const MapCrosshair = ({classes, onTargetChanged, onSave, initialTarget}) => {

  const googleMap = useGoogleMap();
  const LangFile = useLangFile();
  const mapCenter = useGoogleMapCenter(googleMap);
  const [target, setTarget] = useState(getLatLng(initialTarget || mapCenter));

  // Handle changes to the latitude input field
  const setLatitude = useCallback((value) => {
    let newTarget = new google.maps.LatLng(value, target.lng());
    setTarget(newTarget);
    googleMap.setCenter(newTarget);
  }, [target]);

  // Handle changes to the longitude input field
  const setLongitude = useCallback((value) => {
    let newTarget = new google.maps.LatLng(target.lat(), value);
    setTarget(newTarget);
    googleMap.setCenter(newTarget);
  }, [target]);

  // Handle changes to initialTarget
  useEffect(() => {
    if (initialTarget) {
      let target = getLatLng(initialTarget);
      setTarget(target);
      googleMap.setCenter(target);
    }
  }, [initialTarget]);

  // Handle changes to the mapCenter when user pans around
  useEffect(() => {
    setTarget(mapCenter);
  }, [mapCenter]);

  // Handle changes to target
  useEffect(() => {
    onTargetChanged(target);
  }, [target, onTargetChanged]);

  if (!target) {
    return null;
  }

  let latitude = target.lat().toFixed(6);
  let longitude = target.lng().toFixed(6);

  return (
    <div className={classes.root}>
      <div className={classes.xAxis}/>
      <div className={classes.yAxis}/>
      <div className={classes.popover}>
        <TextFormControl
          title={LangFile.MapCrosshair.latitude}
          disablePadding
          value={latitude}
          regexValidation={LAT_REGEX}
          defaultValue={mapCenter.lat()}
          type={"number"}
          onUpdate={setLatitude}/>

        <TextFormControl
          title={LangFile.MapCrosshair.longitude}
          disablePadding
          value={longitude}
          regexValidation={LNG_REGEX}
          defaultValue={mapCenter.lng()}
          type={"number"}
          onUpdate={setLongitude}/>

        <Button
          color={"primary"}
          variant={"contained"}
          className={classes.button}
          onClick={onSave}>{LangFile.MapCrosshair.save}</Button>
      </div>
    </div>
  );
};

MapCrosshair.propTypes = {
  initialTarget: PropTypes.object,
  onTargetChanged: PropTypes.func,
  onSave: PropTypes.func,
};

MapCrosshair.defaultProps = {
  onTargetChanged: voidFunc,
  onSave: voidFunc,
};

export default withStyles(styles)(MapCrosshair);