import React, {memo} from 'react';
import PropTypes from 'prop-types';
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";

import Styles from "./SubList.module.less";
import {voidFunc} from "../../constants/PropTypeUtils";
import Box from "@material-ui/core/Box";
import grey from "@material-ui/core/colors/grey";
import clsx from "clsx";

const SubList = (props: SubList.propTypes) => {

  let filtered = props.items.map((item) => props.renderItem(item)).filter(Boolean);

  let items = filtered.map((item, idx) => {
    return <span key={idx}>{item}</span>;
  });

  let empty = items.length === 0;

  return (
    <List
      dense
      disablePadding
      subheader={
        <ListSubheader className={clsx({[Styles.checkboxEnabled]: props.subheaderCheckbox})} classes={{sticky: Styles.sticky}}>
          <Box display={"flex"} justifyContent={"flex-start"}>
            {props.subheaderCheckbox && (
              <Box>
                {props.subheaderCheckbox}
              </Box>
            )}
            <Box>
              {props.title}
            </Box>

          </Box>
        </ListSubheader>
    }>
      {empty && <Box fontSize={"0.75rem"} color={grey["400"]} display={"flex"} flexDirection={"column"} pl={3}>{props.renderEmptyState()}</Box>}
      {!empty && items}
    </List>
  );
};

SubList.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  renderItem: PropTypes.func,
  renderEmptyState: PropTypes.func,
  subheaderCheckbox: PropTypes.any,
};

SubList.defaultProps = {
  renderEmptyState: voidFunc
};

export default memo(
    SubList
);
