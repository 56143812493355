import React, {useContext} from "react";

const GraphContext = React.createContext({});

export const GraphProvider = GraphContext.Provider;
export const GraphConsumer = GraphContext.Consumer;

export function useGraph() {
  return useContext(GraphContext);
}

export function useDimensions() {
  let {width, height} = useGraph();

  return {
    width,
    height,
  };
}

export function useSVG() {
  let {svg} = useGraph();
  return svg;
}

export function useMapping() {
  let {xMap, yMap} = useGraph();

  return {
    xMap,
    yMap,
  };
}

export function useDatasets() {
  let {datasets} = useGraph();
  return datasets;
}

export function useAllPoints() {
  let {allPoints} = useGraph();
  return allPoints;
}