import * as d3 from "d3";
import React, {
  memo,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {
  useGraph,
} from "js/context/GraphContext";

const GraphLine = ({overrideDatasets, datasetName, displayDots}) => {

  const {datasets, svg, xMap, yLeftMap, yRightMap} = useGraph();
  const [state, setState] = useState(null);
  const lineRef = useRef(null);

  const data = overrideDatasets || datasets;
  const dataset = data && data[datasetName];

  const yAxis = (dataset && dataset.axis === "right") ? yRightMap : yLeftMap;

  useEffect(() => {
    if (lineRef.current && yAxis) {
      let valueline =
        d3.line()
          .x((d) => xMap(d.x))
          .y((d) => yAxis(d.y));

      let path = d3.select(lineRef.current);

      path
        .style("fill", "none")
        .style("stroke-width", 2)
        .style("stroke", dataset.color)
        .attr("d", valueline(dataset.points));

      if (dataset.dashed) {
        path.style("stroke-dasharray", "5 5");
      }

      if (dataset.opacity) {
        path.style("opacity", dataset.opacity);
      }

      setState({
        dataset
      });
    }
  }, [svg, lineRef.current, yAxis, dataset, xMap]);

  return (
    <g>
      <path className='line' ref={lineRef}/>

      {displayDots && state && state.dataset.points.map((element, idx) => {
        let color = 'rgba(255, 180, 0, 1)';

        if (!element.selected) {
          color = element.overrideColor ? element.overrideColor : state.dataset.color;
        }

        return (
          <circle
            cx={xMap(element.x)}
            cy={yAxis(element.y)}
            r={element.selected ? 5 : 3}
            key={idx + "-" + element.x + "-" + element.y}
            fill={color}>
          </circle>
        );
      })}
    </g>
  );
};

GraphLine.propTypes = {
  /**
   * Select which data entry to display. Simply write the property name of the data entry to display.
   *
   * If this is not provided, the graphline will select a property based on which child index this has.
   */
  datasetName: PropTypes.string,
  /** Should dots be displayed at all points on the graph */
  displayDots: PropTypes.bool,
  /**
   * Override the object of datasets provided by the GraphProvider.
   */
  overrideDatasets: PropTypes.object,
  axis: PropTypes.oneOf(["left", "right"])
};

GraphLine.defaultProps = {
  axis: "left"
};

export default memo(GraphLine);