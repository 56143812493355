"use strict";

import React, {
  Fragment,
  useCallback, useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  CircularProgress,
} from "@material-ui/core";

import {useLangFile} from "js/context/LanguageContext";
import {voidFunc} from "../../constants/PropTypeUtils";

import moment from "moment";
import SelectCrop from "js/components/FormControls/SelectCrop";
import {CropType} from "js/constants/CropConstants";
import {useSelectedSeason} from "js/context/SeasonContext";
import ErrorSnackbar from "js/components/Toasts/ErrorSnackbar";
import InlineDatePicker from "../FormControls/InlineDatePicker";
import IconButton from "@material-ui/core/IconButton";
import {Clear} from "@material-ui/icons";
import TextField from '@material-ui/core/TextField';

const EditCropDialog = ({shown, field, onClose, onSave}) => {
  const LangFile = useLangFile();

  const season = useSelectedSeason();
  const [crop, setCrop] = useState(field.crop || CropType.NONE);
  const [sowingDate, setSowingDate] = useState(field.sowing ? moment(field.sowing) : null);
  const [cropVariation, setCropVariation] = useState(field.cropVariation || undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [cropVariationError, setCropVariationError] = useState(undefined);
  const disableSave = crop !== CropType.NONE && !sowingDate;

  useEffect(() => {
    if (crop === CropType.NONE) {
      setSowingDate(null);
    }
  }, [crop]);

  const onDismissError = useCallback(() => {
    setError(undefined);
  });

  const onClearDate = useCallback(() => {
    setSowingDate(null);
  });

  const handleSave = useCallback(() => {
    setError(undefined);
    setLoading(true);
    onSave({fieldId: field.fieldId, seasonId: season.id, crop: crop, sowing: sowingDate, cropVariation: cropVariation})
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((e) => {
        setLoading(false);
        setError(e);
      });
  }, [onSave, onClose, crop, sowingDate, field, season, cropVariation]);

  const onCropVariationChanged = useCallback((e) => {
    if (e.target.value.length < 128) {
      setCropVariation(e.target.value);
      setCropVariationError(false);
    }
    else {
      setCropVariationError(true);
    }
  });

  let content = (
    <Box
      flex={1}
      p={2}
      maxWidth={"300px"}>
      <Box
        pb={2}
        fontWeight={"bold"}>
        {LangFile.EditCropDialog.title}
      </Box>
      <Box pb={2}>
        <SelectCrop
          value={crop}
          onChanged={setCrop}/>
      </Box>
      <Box pb={2} display={"flex"} flexDirection={"row"}>
        <Box>
          <InlineDatePicker
            error={disableSave}
            label={LangFile.EditCropDialog.sowingDate}
            value={sowingDate}
            disabled={crop === 'NONE'}
            onChange={setSowingDate}/>
        </Box>
        <Box display={"flex"} justifyContent={"center"} alignItems={"flex-end"} pl={1} pb={0.5}>
          <IconButton onClick={onClearDate} size={"small"}>
            <Clear/>
          </IconButton>
        </Box>
      </Box>
      <Box pb={2} display={"flex"} flexDirection={"row"}>
          <TextField
            fullWidth={true}
            value={cropVariation}
            label={LangFile.EditCropDialog.cropVariation}
            onChange={onCropVariationChanged}
            disabled={crop === 'NONE'}
            helperText={cropVariationError ? LangFile.EditCropDialog.errorCropVariation : ""}
            error={cropVariationError}/>
      </Box>
    </Box>
  );

  if (loading) {
    content = (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        minHeight={"209px"}
        height={"100%"}>
        <CircularProgress
          variant={"indeterminate"}
          size={44}
          color={"primary"}/>
      </Box>
    );
  }

  return (
    <Fragment>
      <Dialog
        open={shown}
        fullWidth
        maxWidth={"sm"}
        onClose={onClose}>
        <DialogContent>
          <Box
            display={"flex"}
            flexDirection={"row"}>
            {content}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{LangFile.EditCropDialog.cancel}</Button>
          <Button
            disabled={disableSave}
            variant={"contained"}
            color={"primary"}
            onClick={handleSave}>{LangFile.EditCropDialog.save}</Button>
        </DialogActions>
      </Dialog>

      <ErrorSnackbar
        open={Boolean(error)}
        title={LangFile.EditCropDialog.errorTitle}
        subtitle={LangFile.EditCropDialog.errorSubtitle}
        errorMessage={error ? error.message : undefined}
        onDismiss={onDismissError}/>
    </Fragment>
  );
};

EditCropDialog.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  shown: PropTypes.bool,
  field: PropTypes.object,
};

EditCropDialog.defaultProps = {
  onClose: voidFunc,
  onSave: voidFunc,
  field: {},
};

export default EditCropDialog;