import React, {
  useCallback,
  useEffect,
  useState
} from "react";
import {connect} from "react-redux";
import {
  setIsDrawingField,
  addDrawnField,
} from "js/reducers/AddFieldReducer";
import {
  setIsDrawingNote,
  createDefaultNote,
  selectNote,
  setIsMagicSelecting,
  setMagicWandThreshold, setIsPlacingMarker
} from "js/reducers/NoteReducer";
import PolygonDrawing from "js/components/PolygonDrawing/PolygonDrawing";
import {getSelectedFieldImageForDate} from "js/helpers/StateInterpreters";
import {useLangFile} from "js/context/LanguageContext";
import {useGoogleMap} from "js/context/GoogleMapContext";
import {useSelectedSeason} from "../../context/SeasonContext";

const getCurrentHectares = (fields) => {
  return [...fields.values()].reduce((acc, f) => acc + (f.size || 0), 0).toFixed(1);
};

const mapStateToProps = (state) => {
  return {
    fields: state.field.fields,
    isDrawingField: state.addField.isDrawing,
    isDrawingNote: state.note.isDrawing,
    isPlacingMarker: state.note.isPlacingMarker,
    farm: state.farm.farm,
    magicWand: state.note.magicWand,
    isMagicSelecting: state.note.magicWand.isSelecting,
    imageType: state.field.imageType,
    date: state.field.date,
    images: state.field.images,
    selectedField: state.field.selectedField,
    surveys: state.survey.surveys,
    selectedLayer: state.survey.selectedLayer,
    showSoilSurveys: state.survey.showSoilSurveys,

  };
};

const PolygonDrawingContainer = (props) => {

  const LangFile = useLangFile();
  const googleMap = useGoogleMap();
  const selectedSeason = useSelectedSeason();

  let {dispatch, farm, isDrawingField, isMagicSelecting, isDrawingNote, fields, isPlacingMarker} = props;

  const [currentHectare, setCurrentHectare] = useState(0);

  useEffect(() => {
    if (fields.size > 0) {
      setCurrentHectare(getCurrentHectares(fields));
    }
  }, [fields]);

  const handleSetIsDrawingNote = (value) => {
    dispatch(setIsDrawingNote(value));
  };

  const handleSetIsMagicSelecting = (value) => {
    dispatch(setIsMagicSelecting(value));
  };

  const handleSetIsPlacingMarker = (value) => {
    dispatch(setIsPlacingMarker(value));
  };

  const handleSetIsDrawingField = (value) => {
    dispatch(setIsDrawingField(value));
  };

  const handleAddDrawnField = (drawnPolygon) => {
    // let totalSizeToAdd = areaCalculation({coords: drawnPolygon}).size;

    dispatch(addDrawnField(drawnPolygon, farm.farmId, selectedSeason.id));
  };

  const handleOnMarkerPlaced = useCallback((marker) => {
    let nodes = [];
    let node = {};
    node.latitude = marker.position.lat();
    node.longitude = marker.position.lng();
    nodes.push(node);
    marker.setMap(null);

    const note = createDefaultNote(nodes);

    dispatch(selectNote(note));
  }, []);


  const childProps = {
    ...props,
    map: googleMap,
    LangFile: LangFile,
    currentHectare: currentHectare,
    setIsDrawingField: handleSetIsDrawingField,
    setIsDrawingNote: handleSetIsDrawingNote,
    setIsMagicSelecting: handleSetIsMagicSelecting,
    setIsPlacingMarker: handleSetIsPlacingMarker,
    onMarkerPlaced: handleOnMarkerPlaced,
    addDrawnField: handleAddDrawnField,
    selectAndCreateNote: (outline) => dispatch(selectNote(createDefaultNote(outline))),
    onSetMagicWandThreshold: (threshold) => dispatch(setMagicWandThreshold(threshold)),
    fieldImage: getSelectedFieldImageForDate(props.selectedField, props.date, props.images, props.imageType)
  };

  if (isDrawingNote || isDrawingField || isMagicSelecting || isPlacingMarker) {
    return <PolygonDrawing {...childProps} />;
  }
  else {
    return null;
  }
};

export default connect(mapStateToProps)(PolygonDrawingContainer);