import React from 'react';
import {Landscape} from '@material-ui/icons';
import type {LAYER_CONFIG} from '../model/surveys/SurveyLayerConfig';
import type {LanguageFile} from '../helpers/LanguageUtils';

export const OTHER_LAYERS = {
  HEIGHT_MAP: "HEIGHT_MAP"
};

export const OTHER_LAYER_ICON = {
  [OTHER_LAYERS.HEIGHT_MAP]: <Landscape
    width={24}
    height={24}/>
};

export const getOtherLayerConfig = (layer: OtherLayer): LAYER_CONFIG => {
  let icon = OTHER_LAYER_ICON[layer];

  return {
    getName: (LangFile: LanguageFile) => LangFile.OtherLayers[layer],
    icon: icon
  };
};

export type OtherLayer = $Keys<typeof OTHER_LAYERS>;
