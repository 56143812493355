import React, {Fragment, memo, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import SurveyReport from "./SurveyReport";
import {getLayerConfig} from "../Prescription/PrescriptionUtils";
import {getImagesForDate} from "../../helpers/StateInterpreters";
import WebAPIUtils from "../../WebAPIUtils";
import {useFarm} from "../../context/AccountContext";
import {useLangFile} from "js/context/LanguageContext";
import {Print} from "@material-ui/icons";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../hooks/useFirebaseAnalytics";
import {Fab, Tooltip} from '@material-ui/core';


const mapStateToProps = (store) => {
  return {
    showSatelliteImagery: store.field.showSatelliteImagery,
    date: store.field.date,
    images: store.field.images,
    imageType: store.field.imageType,
    selectedField: store.field.selectedField,
    viewMode: store.control.viewMode,
    showSoilSurveys: store.survey.showSoilSurveys,
    variationsEnabled: store.survey.variationsEnabled,
    classificationsEnabled: store.survey.classificationsEnabled,
    surveys: store.survey.surveys,
    selectedLayer: store.survey.selectedLayer,
    selectedFieldDates: store.field.selectedFieldDates,
    selectedSurveyClassificationValues: store.survey.selectedSurveyClassificationValues
  };
};

const SurveyReportContainer = (props: SurveyReportContainer.propTypes) => {
  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();
  const farm = useFarm();
  const layer = props.showSatelliteImagery ? props.imageType : props.selectedLayer;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [referenceValues, setReferenceValues] = useState({});
  const [values, setValues] = useState(null);
  const [survey, setSurvey] = useState(null);

  const [selectedFieldHasImage, setSelectedFieldHasImage] = useState(false);
  const [selectedFieldHasSurvey, setSelectedFieldHasSurvey] = useState(false);

  const handleDialogOpen = (open) => useCallback(() => {
    if (open) {
      analytics.logEvent(FIREBASE_EVENTS.ANALYSIS_PRINT_OPEN);
    }
    else {
      analytics.logEvent(FIREBASE_EVENTS.ANALYSIS_PRINT_CLOSE);
    }

    setDialogOpen(open);
  }, []);

  useEffect(() => {
    if (!dialogOpen) {
      return;
    }
    if (props.selectedField) {
      WebAPIUtils.getLayerValues(farm.farmId, props.selectedField.fieldId, props.date, layer, survey).then((val) => {
        setReferenceValues((current) => ({...current, [layer]: val}));
      });
    }
  }, [survey, props.date, props.selectedField, layer, dialogOpen]);

  useEffect(() => {
    if (!dialogOpen) {
      return;
    }
    setSelectedFieldHasImage(Boolean(props.selectedFieldDates && props.date && props.selectedFieldDates[props.date]));
  }, [props.selectedFieldDates, props.date, dialogOpen]);

  useEffect(() => {
    if (!dialogOpen) {
      return;
    }
    setSelectedFieldHasSurvey(Boolean(props.surveys && props.selectedField && props.surveys.find((s) => s.fieldId === props.selectedField.fieldId)));
  }, [props.selectedField, props.surveys, dialogOpen]);

  useEffect(() => {
    if (!dialogOpen) {
      return;
    }

    if (referenceValues && props.selectedLayer) {
      setValues(referenceValues[props.selectedLayer]);
    }
  }, [referenceValues, dialogOpen]);

  useEffect(() => {
    if (!dialogOpen) {
      return;
    }

    // Handles initialization and change of satellite imagery.
    if (props.showSatelliteImagery && props.selectedField) {
      let images = getImagesForDate(props.date, props.images, props.imageType);
      let imageMap = new Map(images.map((image) => [image.fieldId, image]));
      let imagePack = imageMap.get(props.selectedField.fieldId);
      if (imagePack) {
        let url = imagePack[props.imageType];
        setImageURL(url);
      }
    }
    // Handles initialization and change of survey based on the available surveys and the selected field.
    else if (props.showSoilSurveys && props.surveys && props.selectedField) {
      let target = props.surveys.find((survey) => survey.fieldId === props.selectedField.fieldId);
      let config = getLayerConfig(props.selectedLayer);
      let images = Object.assign(target.images, target.newImages);
      let url = config.getImageUrl(images, props.classificationsEnabled, props.variationsEnabled, props.viewMode);
      setImageURL(url);
      setSurvey(target);
    }
    else {
      setImageURL(null);
      setSurvey(null);
    }
  }, [props.date, props.images, props.imageType, props.showSatelliteImagery, props.selectedField, props.showSoilSurveys, props.surveys, props.selectedField, props.selectedLayer, props.classificationsEnabled, props.variationsEnabled, props.viewMode, dialogOpen]);

  return (
    <Fragment>
      <Tooltip title={LangFile.SurveyReport.print}>
        <Fab size="small" onClick={handleDialogOpen(true)}>
          <Print />
        </Fab>
      </Tooltip>

      <SurveyReport
        open={dialogOpen}
        onClose={handleDialogOpen(false)}
        selectedField={props.selectedField}
        imageURL={imageURL}
        viewMode={props.viewMode}
        showSatelliteImagery={props.showSatelliteImagery}
        showSoilSurveys={props.showSoilSurveys}
        imageType={props.imageType}
        imageDate={props.date}
        selectedLayer={props.selectedLayer}
        variationsEnabled={props.variationsEnabled}
        selectedFieldHasImage={selectedFieldHasImage}
        selectedFieldHasSurvey={selectedFieldHasSurvey}
        classificationsEnabled={props.classificationsEnabled}
        values={values}
        classificationValues={props.selectedSurveyClassificationValues}/>
    </Fragment>
  );
};

SurveyReportContainer.propTypes = {
  surveys: PropTypes.array,
  selectedLayer: PropTypes.string,
  date: PropTypes.string,
  images: PropTypes.object,
  imageType: PropTypes.string,
  viewMode: PropTypes.string,
  selectedField: PropTypes.object,
  variationsEnabled: PropTypes.bool,
  classificationsEnabled: PropTypes.bool,
  showSatelliteImagery: PropTypes.bool,
  showSoilSurveys: PropTypes.bool,
  selectedFieldDates: PropTypes.array,
  selectedSurveyClassificationValues: PropTypes.object
};

export default memo(
  connect(mapStateToProps)(
    SurveyReportContainer
  )
);
