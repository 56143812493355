import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {useLangFile} from "js/context/LanguageContext";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const PrescriptionDocumentationConfirmDeleteDialog = ({open, file, onClose, onConfirm}) => {

  const LangFile = useLangFile();
  const [confirmed, setConfirmed] = useState(false);

  const handleClose = useCallback(() => {
    onClose();
  });

  useEffect(() => {
    setConfirmed(false);
  }, [file]);

  if (!file || !open) {
    return null;
  }

  return (
    <Dialog fullWidth maxWidth={"sm"} open={true} onClose={handleClose}>
      <DialogTitle>
        {LangFile.PrescriptionDocumentationConfirmDeleteDialog.title}
      </DialogTitle>
      <DialogContent>
        <Box color={"secondary.main"} p={2}>{file.fileName}</Box>

        <FormControlLabel
          labelPlacement={"end"}
          control={
            <Checkbox
              onChange={() => setConfirmed(!confirmed)}
              checked={confirmed}/>
          }
          label={
            <Box>
              {LangFile.PrescriptionDocumentationConfirmDeleteDialog.understand}
            </Box>
          }/>

        <p>{}</p>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          onClick={handleClose}>
          {LangFile.PrescriptionDocumentationConfirmDeleteDialog.keepTheFile}</Button>
        <Button
          color="secondary"
          variant={"contained"}
          disabled={!confirmed}
          onClick={onConfirm}>
          {LangFile.PrescriptionDocumentationConfirmDeleteDialog.deleteFile}</Button>
      </DialogActions>
    </Dialog>
  );
};

PrescriptionDocumentationConfirmDeleteDialog.propTypes = {
  open: PropTypes.bool,
  file: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default PrescriptionDocumentationConfirmDeleteDialog;