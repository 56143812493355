import React, {memo, useCallback, useEffect, useState} from 'react';

import PropTypes from "prop-types";

import {BottomDrawerProvider} from "js/context/BottomDrawerContext";
import Drawer from "@material-ui/core/Drawer";

import Style from "./BottomDrawer.module.less";
import Box from "@material-ui/core/Box";
import BottomDrawerToggle from "./BottomDrawerToggle";
import clsx from "clsx";
import {voidFunc} from '../../constants/PropTypeUtils';

const BottomDrawer = (props: BottomDrawer.propTypes) => {
  const [open, setOpen] = useState(props.initiallyShown);

  useEffect(() => {
    setOpen(props.initiallyShown);
  }, [props.initiallyShown]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    props.onOpen(open);
  }, [open]);

  if (!props.shown) {
    return null;
  }

  return (
    <BottomDrawerProvider value={{open, setOpen}}>
      <Drawer
        anchor={"bottom"}
        variant={"persistent"}
        onClose={onClose}
        open={open}
        ModalProps={{
          hideBackdrop: true,
        }}
        classes={{root: Style.Root, paperAnchorBottom: clsx({[Style.Paper]: true, [Style.PaperClosed]: !open})}}>

        <Box className={clsx({[Style.ActionButtonsLeft]: true, [Style.Closed]: !open && props.shown})}>
          {props.actionButtonsLeft && (
            props.actionButtonsLeft
          )}
        </Box>

        <Box className={clsx({[Style.ActionButtons]: true, [Style.Closed]: !open && props.shown})}>
          {(open || (!open && props.alwaysShowActionButtons)) && (
            props.actionButtons
          )}

          {props.canBeHidden && (
            <BottomDrawerToggle/>
          )}
        </Box>

        {props.children}
      </Drawer>
    </BottomDrawerProvider>
  );
};

BottomDrawer.propTypes = {
  shown: PropTypes.bool,
  initiallyShown: PropTypes.bool,
  actionButtonsLeft: PropTypes.any,
  actionButtons: PropTypes.any,
  children: PropTypes.any,
  canBeHidden: PropTypes.bool,
  alwaysShowActionButtons: PropTypes.bool,
  onOpen: PropTypes.func,
};

BottomDrawer.defaultProps = {
  alwaysShowActionButtons: false,
  canBeHidden: false,
  onOpen: voidFunc,
};

export default memo(BottomDrawer);