// @flow

// export const VITALITY_NDVI = "vitality_ndvi";
// export const VITALITY_NDRE = "vitality_ndre";
// export const VARIATIONS_NDVI = "variations_ndvi";
// export const VARIATIONS_NDRE = "variations_ndre";
// export const VISIBLE = "visible";

export const TIFF_NDVI = "TIFF_NDVI";
export const TIFF_NDRE = "TIFF_NDRE";

export const SATELLITE_LAYERS = Object.freeze({
  VITALITY_NDVI: 'VITALITY_NDVI',
  VITALITY_NDRE: 'VITALITY_NDRE',
  VARIATIONS_NDVI: 'VARIATIONS_NDVI',
  VARIATIONS_NDRE: 'VARIATIONS_NDRE',
  VISIBLE: 'VISIBLE',
});

export type SatelliteLayer = $Keys<typeof SATELLITE_LAYERS>;
