import React, {
  Fragment,
  memo,
  useCallback,
  useState
} from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import NavigationMenu from "js/components/FloatingActionButtons/NavigationMenu";
import {
  goToFieldsEvent,
} from "js/reducers/ControlReducer";
import SearchDialogContainer from "js/components/SearchDialog/SearchDialogContainer";
import ViewModeConstants from "../../constants/ViewModeConstants";
import useEvent from "../../hooks/useEvent";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../hooks/useFirebaseAnalytics";

const mapStateToProps = (store) => {
  return {
    fields: store.field.fields,
    weatherStations: store.weather.stations,
    viewMode: store.control.viewMode,
    keyboardShortcutsEnabled: store.control.keyboardShortcutsEnabled
  };
};

const NavigationMenuContainer = ({dispatch, fields, weatherStations, keyboardShortcutsEnabled, viewMode}) => {

  const analytics = useFirebaseAnalytics();
  const [searchOpen, setSearchOpen] = useState(false);

  const onToggle = (value) => useCallback(() => {
    setSearchOpen(value);
    if (value) {
      analytics.logEvent(FIREBASE_EVENTS.MM_SEARCH_CLICK);
    }
  }, []);

  const onGoToFields = useCallback(() => {
    dispatch(goToFieldsEvent());
  }, []);

  const handleKeyboardShortcut = useCallback((e) => {
    if (viewMode !== ViewModeConstants.OVERVIEW || !keyboardShortcutsEnabled) {
      return;
    }

    if (((e.ctrlKey || e.metaKey) && e.key === "f")) {
      e.preventDefault();
      setSearchOpen(true);
      analytics.logEvent(FIREBASE_EVENTS.MM_SEARCH_SHORTCUT);
    }

  }, [viewMode, keyboardShortcutsEnabled]);

  useEvent("keydown", handleKeyboardShortcut);

  return (
    <Fragment>
      <SearchDialogContainer
        open={searchOpen}
        dismissDialog={onToggle(false)}/>

      <NavigationMenu
        fields={fields}
        showSearch={onToggle(true)}
        onGoToFields={onGoToFields}
        weatherStations={weatherStations}/>
    </Fragment>
  );
};

NavigationMenuContainer.propTypes = {
  menuId: PropTypes.string,
  keyboardShortcutsEnabled: PropTypes.bool,
};

export default memo(
  connect(mapStateToProps)(
    NavigationMenuContainer
  )
);