// @flow

import {
  SURVEY_LAYER_CATEGORIES,
  SURVEY_LAYERS
} from "js/constants/SurveyLayers";
import {
  calculateIntervalAreas, calculateIntervals,
  calculateOverridesArea,
  initializeOverrides
} from "js/components/Prescription/PrescriptionUtils";
import {PRESCRIPTION_METERING} from "js/constants/PrescriptionConstants";
import UUID from "js/algorithms/UUID";
import NumberUtils from "js/helpers/NumberUtils";
import {generateLUTArrayForLayer} from 'js/constants/ColorMaps';
import vitalityIcon from 'style/images/layers/vitality-icon.png';
import variationsIcon from 'style/images/layers/variance-icon.png';
import visibilityIcon from 'style/images/layers/visible-icon.png';
import {PRESCRIPTION_UNIT} from "../../constants/PrescriptionConstants";
import type {LAYER_CONFIG} from "../../model/surveys/SurveyLayerConfig";
import type {LanguageFile} from "../../helpers/LanguageUtils";
import {getSourceCategory} from "../../helpers/SurveyUtils";
import {SATELLITE_LAYERS} from '../../constants/SatelliteLayers';

// export const SATELLITE_LAYERS = {
//   vitality: "vitality",
//   variations: "variations",
//   visible: "visible",
// };

// export const SATELLITE_LAYER_LUT = {
//   [SATELLITE_LAYERS.VITALITY_NDVI]: vitalityNDVILUT,
//   [SATELLITE_LAYERS.VARIATIONS_NDVI]: variationsNDVILUT,
// };

export const SATELLITE_LAYER_ICON = {
  [SATELLITE_LAYERS.VITALITY_NDVI]: vitalityIcon,
  [SATELLITE_LAYERS.VITALITY_NDRE]: vitalityIcon,
  [SATELLITE_LAYERS.VARIATIONS_NDVI]: variationsIcon,
  [SATELLITE_LAYERS.VARIATIONS_NDRE]: variationsIcon,
  [SATELLITE_LAYERS.VISIBLE]: visibilityIcon,
};

export const MetaTypes = {
  LEGACY: "LEGACY",
  FERTILIZING: "FERTILIZING",
  SPRAYING: "SPRAYING",
  SPOT_SPRAYING: "SPOT_SPRAYING",
  SEEDING: "SEEDING",
};

export type MetaType = $Keys<typeof MetaTypes>;
export type SATELLITE_LAYER_TYPE = $Keys<typeof SATELLITE_LAYERS>;
export type Layer = $Keys<typeof SATELLITE_LAYERS> | $Keys<typeof SURVEY_LAYERS>;

export const SOURCE_CATEGORY = {
  ["VITALITY"]: SATELLITE_LAYERS.VITALITY_NDVI,
  ["NUTRIENT"]: SURVEY_LAYER_CATEGORIES.NUTRIENT,
  ["TEXTURE"]: SURVEY_LAYER_CATEGORIES.TEXTURE,
  ["TOPOGRAPHY"]: SURVEY_LAYER_CATEGORIES.TOPOGRAPHY,
};
export type SourceCategory = $Keys<typeof SOURCE_CATEGORY>;


export class Interval {
  key: string;

  constructor(
    min: number,
    max: number,
    prescription: number,
  ) {
    this.min = min;
    this.max = max;
    this.prescription = prescription;
    this.key = UUID.uuidv4();
  }
}

export class ClassificationInterval extends Interval {
  key: string;

  constructor(
    min: number,
    max: number,
    prescription: number,
    classification: String,
  ) {
    super(min, max, prescription);
    this.classification = classification;
    this.key = UUID.uuidv4();
  }
}

export class PrescriptionJob {
  jobId: number;
  jobName: string;
  fieldId: number;
  seasonId: number;
  fieldSize: number;
  bounds: {
    north: number,
    south: number,
    east: number,
    west: number,
  };
  date: Date;
  values: Array<Array<number>>;
  layer: Layer;
  category: SourceCategory;
  metaType: MetaType;
  maxPrescription: number;
  totalPrescription: number;
  intervals: Array<Object>;
  overrides: Array<Array<number>>;
  overrideAreas: Object;
  metering: string;
  unit: string;
  createdDate: Date;
  lastModifiedDate: Date;
  saved: boolean;
  legacy: boolean;
  images: Object;
  classifications: Object;
  layerType: string;

  static fromNdviData(data, fields): PrescriptionJob {
    let {vrmName, date, ndviIntervals, overrides, id, allocationUnit, seasonId, values} = data;
    let {fieldId, metaType, maxAllocation, metering} = data.metaData;
    let field = fields.get(fieldId);
    let intervals = ndviIntervals.map((i) => new Interval(i.min, i.max, i.allocation));

    let tiffs = data.sourceTiffs;
    let savedDate = (Array.isArray(tiffs) && tiffs.length > 0) ? tiffs[0].date : null;
    let assets;
    if (data.assets) {
      assets = {
        [SATELLITE_LAYERS.VITALITY_NDVI]: data.assets["vitality"],
        [SATELLITE_LAYERS.VARIATIONS_NDVI]: data.assets["variations"],
        [SATELLITE_LAYERS.VISIBLE]: data.assets[SATELLITE_LAYERS.VISIBLE.toLowerCase()]
      };
    }

    let newJob = new PrescriptionJob();
    newJob.jobId = id;
    newJob.jobName = vrmName;
    newJob.fieldId = field.fieldId;
    newJob.seasonId = seasonId;
    newJob.fieldSize = field.size;
    newJob.layer = SATELLITE_LAYERS.VITALITY_NDVI;
    newJob.category = getSourceCategory(SATELLITE_LAYERS.VITALITY_NDVI);
    newJob.bounds = field.bounds;
    newJob.date = savedDate ? savedDate : new Date(date);
    newJob.metaType = metaType;
    newJob.metering = metering || PRESCRIPTION_METERING.TOTAL;
    newJob.intervals = intervals;
    newJob.values = values;
    newJob.overrides = overrides;
    newJob.unit = allocationUnit || PRESCRIPTION_UNIT.KILOGRAM;
    newJob.overrides = initializeOverrides(newJob); // make sure it is properly formed
    newJob.maxPrescription = maxAllocation;
    newJob.overrideAreas = calculateOverridesArea(newJob);
    newJob = calculateIntervalAreas(newJob);
    newJob.assets = assets;

    if (!newJob.intervals || (Array.isArray(newJob.intervals) && newJob.intervals.length === 0)) {
      newJob.intervals = calculateIntervals(newJob);
    }

    newJob.saved = false;

    return newJob;
  }

  static fromSurveyData(data, fields): PrescriptionJob {
    let field = fields.get(data.fieldId);
    let newJob = new PrescriptionJob();
    newJob.jobId = data.id;
    newJob.fieldId = field.fieldId;
    newJob.bounds = field.bounds;
    newJob.seasonId = data.seasonId;
    newJob.fieldSize = field.size;
    newJob.jobName = data.name;
    newJob.category = getSourceCategory(data.layer);
    newJob.createdDate = new Date(data.created);
    newJob.lastModifiedDate = data.lastModified ? new Date(data.lastModified) : null;
    newJob.layer = data.layer;
    newJob.unit = data.allocationUnit || PRESCRIPTION_UNIT.KILOGRAM;
    newJob.values = data.layerValues;
    newJob.layerType = data.layerType;
    newJob.metaType = data.metaType;
    newJob.intervals = data.intervals.map((i) => new Interval(i.min, i.max, i.allocation));
    newJob.overrides = data.overrides;
    newJob.overrides = initializeOverrides(newJob); // make sure it is properly formed
    newJob.totalPrescription = data.totalPrescription;
    newJob.maxPrescription = data.maxPrescription;
    newJob.overrideAreas = calculateOverridesArea(newJob);
    newJob = calculateIntervalAreas(newJob);
    newJob.metering = data.metering || PRESCRIPTION_METERING.TOTAL;
    newJob.assets = data.images;
    newJob.saved = false;

    if (newJob.layerType === 'FI_DEMAND') {
      newJob.values = null;
      newJob.classifications = {'values': data.layerValues, 'mappings': data.layerMappings } ;
    }
    return newJob;
  }

  static fromArchive(job, fields, legacy): PrescriptionJob {
    const {id, fieldId, vrmName, metaType, created, seasonId} = job;
    const field = fields.get(fieldId);

    if (!field) {
      return null;
    }

    const newJob = new PrescriptionJob();
    newJob.jobId = id;
    newJob.fieldId = field.fieldId;
    newJob.bounds = field.bounds;
    newJob.fieldSize = field.size;
    newJob.metaType = metaType;
    newJob.legacy = legacy;
    newJob.jobName = vrmName;
    newJob.createdDate = new Date(created);
    newJob.layer = SATELLITE_LAYERS.VITALITY_NDVI;
    newJob.seasonId = seasonId;

    return newJob;
  }

  static fromSurveyArchive(job, fields): PrescriptionJob {
    const {id, fieldId, layer, name, metaType, created, seasonId, type} = job;
    const field = fields.get(fieldId);

    if (!field) {
      return null;
    }

    const newJob = new PrescriptionJob();
    newJob.jobId = id;
    newJob.fieldId = field.fieldId;
    newJob.bounds = field.bounds;
    newJob.fieldSize = field.size;
    newJob.metaType = metaType;
    newJob.jobName = name;
    newJob.createdDate = new Date(created);
    newJob.layer = layer;
    newJob.seasonId = seasonId;
    if (type === 'FI_DEMAND') {
      newJob.layerType = type;
    }
    return newJob;
  }

  static cloneJob(job: PrescriptionJob) {
    return Object.assign(Object.create(Object.getPrototypeOf(job)), job);
  }
}

export class Survey {
  surveyId: string;
  farmId: number;
  fieldId: number;
  images: { key: string };
}

export class PrescriptionJobPostBody {
  name: string;
  surveyId: string;
  fieldId: number;
  layer: Layer;
  metaType: MetaType;
  maxPrescription: number;
  totalPrescription: number;
  unit: string;
  intervals: Array<Interval>;
  overrides: Array<Array<number>>;
  layerType: string;
}

export class PrescriptionJobPutBody {
  name: string;
  layer: Layer;
  metaType: MetaType;
  maxPrescription: number;
  totalPrescription: number;
  seasonId: number;
  unit: string;
  intervals: Array<Interval>;
  overrides: Array<Array<number>>;
}

export const getSatelliteConfig = (layer: SATELLITE_LAYER_TYPE): LAYER_CONFIG => {
  let minValue = 0;
  let maxValue = 1;

  let lut = generateLUTArrayForLayer(layer);
  let icon = SATELLITE_LAYER_ICON[layer];

  let mapValue = (val, outMin, outMax) => {
    return NumberUtils.limitMap(val, minValue, maxValue, outMin, outMax);
  };

  let reverseMapValue = (val, inMin, inMax) => {
    return NumberUtils.limitMap(val, inMin, inMax, minValue, maxValue);
  };

  return {
    minValue: minValue,
    maxValue: maxValue,
    getName: (LangFile: LanguageFile) => LangFile.PrescriptionJob.satelliteLayers[getSatelliteNameKey(layer)],
    getShorthand: (LangFile: LanguageFile) => LangFile.PrescriptionJob.satelliteLayers[getSatelliteShorthandKey(layer)],
    mapValue: mapValue,
    reverseMapValue: reverseMapValue,
    icon: icon,
    getColor: (val) => {
      return lut[Math.floor(val * 255)];
    }
  };
};

const getSatelliteNameKey = (layer) => {
  switch (layer) {
    case SATELLITE_LAYERS.VITALITY_NDVI:
    case SATELLITE_LAYERS.VITALITY_NDRE:
      return 'vitality';
    case SATELLITE_LAYERS.VARIATIONS_NDVI:
    case SATELLITE_LAYERS.VARIATIONS_NDRE:
      return 'variations';
    default:
      return "visible";
  }
};

export const getSatelliteShorthandKey = (layer) => {
  switch (layer) {
    case SATELLITE_LAYERS.VITALITY_NDVI:
    case SATELLITE_LAYERS.VARIATIONS_NDVI:
      return 'ndvi';
    case SATELLITE_LAYERS.VITALITY_NDRE:
    case SATELLITE_LAYERS.VARIATIONS_NDRE:
      return 'ndre';
    default:
      return;
  }
};

export const isSurveyLayer = (layer) => {
  return Object.keys(SURVEY_LAYERS).includes(layer.toUpperCase());
};

export const isSatelliteLayer = (layer) => {
  return Object.keys(SATELLITE_LAYERS).includes(layer);
};

export const parseMetaType = (metaType, LangFile) => {
  switch (metaType) {
    case MetaTypes.FERTILIZING: {
      return LangFile.PrescriptionJob.metaTypes.fertilizing;
    }
    case MetaTypes.SPRAYING: {
      return LangFile.PrescriptionJob.metaTypes.spraying;
    }
    case MetaTypes.SPOT_SPRAYING: {
      return LangFile.PrescriptionJob.metaTypes.spotSpraying;
    }
    case MetaTypes.SEEDING: {
      return LangFile.PrescriptionJob.metaTypes.seeding;
    }
    default: {
      return LangFile.PrescriptionJob.metaTypes.unknown;
    }
  }
};

export const parseLayerName = (layer, LangFile) => {
  if (isSurveyLayer(layer)) {
    let strings = LangFile.SurveyLayers[layer];
    return strings && strings.name;
  }
  else {
    let title = LangFile.PrescriptionJob.satelliteLayers[getSatelliteNameKey(layer)];
    const shorthand = LangFile.PrescriptionJob.satelliteLayers[getSatelliteShorthandKey(layer)];
    if (shorthand) {
      title = title + ` (${shorthand})`;
    }
    return title;
  }
};
