import React, {
  Fragment, useCallback,
  useEffect,
  useState
} from 'react';

import shpjs from 'shpjs';
import Button from '@material-ui/core/Button';
import ConfirmActionPopover from 'js/components/ConfirmPopovers/ConfirmActionPopover';
import UploadFileDialog from "js/components/UploadFile/UploadFileDialog";
import MultipleSelectDialog from "js/components/Modals/MultipleSelectDialog";
import TouchApp from "@material-ui/icons/TouchApp";
import EditLocation from "@material-ui/icons/EditLocation";
import CloudUpload from "@material-ui/icons/CloudUpload";
import {useLangFile} from "js/context/LanguageContext";
import {Business} from "@material-ui/icons";
import ManualFeatureConstants from "js/constants/ManualFeatureConstants";
import {useFarm} from "js/context/AccountContext";
import {useHasFeatures} from "js/context/PermissionContext";
import FeatureConstants from "js/constants/FeatureConstants";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../hooks/useFirebaseAnalytics";
import {formatSeason} from "../../helpers/SeasonUtils";
import {useSelectedSeason} from "../../context/SeasonContext";
import StyleConstants from "../../StyleConstants";

const getCurrentHectares = (fields) => {
  return [...fields.values()].reduce((acc, f) => acc + (f.size || 0), 0).toFixed(1);
};

const AddFields = ({
                     fieldsToAdd,
                     fieldsToConnect,
                     totalSizeToAdd,
                     applyAddFields,
                     outlines,
                     onDismiss,
                     setShowProgress,
                     showShapefileOutlines,
                     showConfirmationPopover,
                     showSpecialText,
                     leaveAddFieldsProcess,
                     selectAllOutlines,
                     showOutlines,
                     loadCVR,
                     loadOutlinesFromWFS,
                     onDrawField,
                     showDialog,
                     fields,
                     language,
                   }) => {

  const analytics = useFirebaseAnalytics();
  const selectedSeason = useSelectedSeason();
  const farm = useFarm();
  const LangFile = useLangFile();
  const [importing, setImporting] = useState(false);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [, setCurrentHectare] = useState(0);

  useEffect(() => {
    if (fields.size > 0) {
      setCurrentHectare(getCurrentHectares(fields));
    }
  }, [fields]);

  const getSelectedFieldsSize = () => {
    let size = 0;
    fieldsToAdd.forEach((element) => {
      size += (element.size - 0);
    });
    return size;
  };

  const shouldSubmitButtonDisable = () => {
    let show = fieldsToAdd.length > 0 || fieldsToConnect.length > 0;

    if (!show) {
      return {disable: true, reason: LangFile.AddFields.noneSelected};
    }

    return {disable: false, reason: ""};
  };

  const addFields = () => {
    if (!shouldSubmitButtonDisable().disable) {
      applyAddFields(getSelectedFieldsSize());
    }
  };

  const onImportFileClicked = () => {
    analytics.logEvent(FIREBASE_EVENTS.MM_ADD_FIELDS_SHAPEFILE);
    setImporting(true);
    onDismiss();
  };

  const onFileChanged = (files) => {
    setFile(files[0]);
  };

  const onFileRejected = () => {
    setFileError(LangFile.AddFields.wrongFormat);
  };

  const onFileAccepted = () => {
    setFileError(null);
  };

  const onImportFileSubmit = () => {
    if (file) {
      analytics.logEvent(FIREBASE_EVENTS.MM_ADD_FIELDS_SHAPEFILE_DIALOG_SUBMIT);

      let type = file.type;
      if (type === "application/zip" || type === "application/x-zip-compressed") {
        setShowProgress(true, LangFile.AddFields.parsingFile);
        parseShapefileZip(file);
      }
    }
  };

  const parseShapefileZip = (file) => {
    let reader = new FileReader();

    reader.onload = () => {

      let buffer = reader.result;
      let result = [];

      shpjs(buffer).then((data) => {
        if (Array.isArray(data)) {
          result = shpjs.parseZip(buffer);
        }
        else {
          result = data;
        }

        result = Array.isArray(result) ? result : [result];

        showShapefileOutlines(result);

        onClose();
      });
    };
    reader.onabort = () => console.error('file reading was aborted');
    reader.onerror = () => console.error('file reading has failed');

    reader.readAsArrayBuffer(file);
  };

  const onClose = () => {
    setImporting(false);
    setFile(null);
    setFileError(null);
    onDismiss();
  };

  const handleCloseImportDialog = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.MM_ADD_FIELDS_SHAPEFILE_DIALOG_CANCEL);
    onClose();
  }, [onClose]);

  let confirmPopover = "";
  if (showConfirmationPopover) {
    let submitButton = shouldSubmitButtonDisable();

    let hectaresToAdd = totalSizeToAdd.toFixed(1);

    let status = (
      <ul>
        <li className={"valid"}>{LangFile.AddFields.add} {LangFile.AddFields.selectedHectares} {hectaresToAdd} ha</li>
        {fieldsToConnect.length > 0 && <li className={"valid"}>{LangFile.AddFields.connect} {fieldsToConnect.length} {LangFile.AddFields.fields} {LangFile.AddFields.to} {formatSeason(selectedSeason, LangFile)}</li> }
      </ul>
    );

    confirmPopover =
      <ConfirmActionPopover
        shown={showConfirmationPopover}
        LangFile={LangFile}
        action={addFields}
        title={LangFile.AddFields.addFields}
        disableContentPadding={true}
        status={status}
        message={LangFile.AddFields.whenDone}
        actionText={LangFile.AddFields.add}
        cancelText={LangFile.AddFields.cancel}
        cancelAction={leaveAddFieldsProcess}
        specialText={fieldsToAdd.length >= outlines.length ? LangFile.AddFields.selectNone : LangFile.AddFields.selectAll}
        specialAction={showSpecialText ? selectAllOutlines : undefined}
        enableSpecialAction={file !== undefined}
        disabled={submitButton.disable}
        disabledReason={submitButton.reason}>
      </ConfirmActionPopover>;
  }

  const actions = [
    <Button onClick={onClose} color="primary" key={'multi-select-dialog-1'}>
      {LangFile.AddFields.close}
    </Button>
  ];

  const options = [];

  if (useHasFeatures(FeatureConstants.ADD_FIELDS_BY_CVR) && farm.cvr) {
    options.push({
      icon: <Business/>,
      cta: LangFile.AddFields.loadCVR,
      title: LangFile.AddFields.loadCVRTitle,
      subtitle: LangFile.AddFields.loadCVRSubtitle,
      onClick: loadCVR,
      color: StyleConstants.palette.primary.main,
      enabled: true
    });
  }

  if (useHasFeatures(FeatureConstants.ADD_FIELDS_BY_OUTLINES)) {
    options.push({
      icon: <TouchApp/>,
      cta: LangFile.AddFields.select,
      title: LangFile.AddFields.selectTitle,
      subtitle: LangFile.AddFields.selectSubtitle,
      onClick: showOutlines,
      color: StyleConstants.palette.primary.main,
      enabled: true
    });
  }

  if (language === "sv") {
    options.push({
      icon: <TouchApp/>,
      cta: LangFile.AddFields.select,
      title: LangFile.AddFields.selectTitle,
      subtitle: LangFile.AddFields.selectSubtitleSwedish,
      onClick: loadOutlinesFromWFS,
      color: StyleConstants.palette.primary.main,
      enabled: true
    });
  }

  if (ManualFeatureConstants.ADD_FIELDS_DRAW) {
    options.push({
      icon: <EditLocation/>,
      cta: LangFile.AddFields.draw,
      title: LangFile.AddFields.drawTitle,
      subtitle: "",
      onClick: onDrawField,
      color: StyleConstants.palette.primary.main,
      enabled: true
    });
  }

  if (ManualFeatureConstants.ADD_FIELDS_SHAPEFILE) {
    options.push({
      icon: <CloudUpload/>,
      cta: LangFile.AddFields.import,
      title: LangFile.AddFields.importTitle,
      subtitle: LangFile.AddFields.importSubtitle,
      onClick: onImportFileClicked,
      color: StyleConstants.palette.primary.main,
      enabled: true
    });
  }

  return (
    <Fragment>
      <MultipleSelectDialog
        open={showDialog}
        options={options}
        actions={actions}
        callToAction={LangFile.AddFields.message}/>

      <UploadFileDialog
        shown={importing}
        onCancel={handleCloseImportDialog}
        file={file}
        fileError={fileError}
        accept={"application/zip, application/x-zip-compressed"}
        onFileChanged={onFileChanged}
        onFileRejected={onFileRejected}
        onFileAccepted={onFileAccepted}
        onImportFileSubmit={onImportFileSubmit}/>

      {confirmPopover}
    </Fragment>
  );
};

export default AddFields;
