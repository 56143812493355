// @flow

import React, {
  memo,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Tooltip
} from "@material-ui/core";
import style from "./HeightMapToggle.module.less";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {Landscape} from "@material-ui/icons";
import {useHasFeatures} from "js/context/PermissionContext";
import FeatureConstants from "js/constants/FeatureConstants";
import {blue} from "@material-ui/core/colors";
import clsx from "clsx";
import {useLangFile} from "js/context/LanguageContext";
import {useFarm} from "../../../context/AccountContext";

const HeightMapToggle = (props: HeightMapToggle.propTypes) => {

  const LangFile = useLangFile();
  const farm = useFarm();
  const enabled = useHasFeatures(FeatureConstants.HEIGHT_MAPS_KORTFORSYNINGEN);

  const onToggle = useCallback(() => {
    if (enabled) {
      props.onToggle(!props.showHeightMap);
    }
  }, [props.showHeightMap, props.onToggle, enabled]);

  if (!farm.cvr) {
    return null;
  }

  return (
    <Box pt={1} className={style.Box}>
      <Paper elevation={2} className={clsx({[style.Paper]: true, [style.Disabled]: !enabled})}>
        <Tooltip title={LangFile.HeightMapToggle.disabledTooltip} disableHoverListener={enabled}>
          <ToggleButton
            value='check'
            disabled={!enabled}
            selected={props.showHeightMap}
            onChange={onToggle}>
            <Landscape
              width={24}
              height={24}
              htmlColor={enabled ? blue["A700"] : "white"}/>
          </ToggleButton>
        </Tooltip>
      </Paper>
    </Box>
  );
};

HeightMapToggle.propTypes = {
  showHeightMap: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default memo(HeightMapToggle);