import {getDurationMillis} from "js/helpers/DateUtils";
import moment from "moment-timezone";
import {blueGrey} from "@material-ui/core/colors";
const tinycolor = require("tinycolor2");


export const WIND_COLOR = "#81d4fa";
export const WIND_GUSTS_COLOR = blueGrey["200"];
export const UV_COLOR = "#ff7043";
export const RAIN_COLOR = "#2979ff";
export const RAIN_COLOR_SECONDARY = tinycolor("#1a237e").setAlpha(0.4).toRgbString();
export const HUMIDITY_COLOR = "#42a5f5";
export const PRESSURE_COLOR = "#78909c";
export const AIR_TEMP_COLOR = "#42a5f5";
export const SOIL_TEMP_COLOR = "#4e342e";
export const LUMINOSITY_COLOR = "#FFC107";
export const SOLAR_RADIATION_COLOR = "#FFC107";
export const GDD_COLOR = tinycolor("#FFC107").setAlpha(0.5).toRgbString();

export const WEATHER_DRAWER_HEIGHT = 450;
export const WEATHER_DRAWER_HEIGHT_SMALL = WEATHER_DRAWER_HEIGHT / 2;

export const NO_WEATHER_STATION_SELECTED = "NO_WEATHER_STATION_SELECTED";

export const WEATHER_START_DATE = moment('01-01-2018', 'DD-MM-YYYY');

export const WeatherPeriod = {
  DAY: '24h',
  WEEK: '8d',
  WEEKS4: '4w',
};

export const WeatherSensor = {
  COMBINED_TEMP: "COMBINED_TEMP",
  AIR_TEMP: "AIR_TEMP",
  SOIL_TEMP: "SOIL_TEMP",
  HUMIDITY: "HUMIDITY",
  LUX: "LUX",
  PRESSURE: "PRESSURE",
  RAIN: "RAIN",
  RAIN_HOURLY: "RAIN_HOURLY",
  RAIN_DAILY: "RAIN_DAILY",
  RAIN_WEEKLY: "RAIN_WEEKLY",
  UV: "UV",
  WIND: "WIND",
  WIND_MAX: "WIND_MAX",
  GDD: "GDD",
  WIND_DIRECTION: "WIND_DIRECTION",
  PRESSURE_TREND: "PRESSURE_TREND",
  SOLAR_RADIATION: "RADIATION"
};

export const WindDirection = {
  E: "E",
  ENE: "ENE",
  ESE: "ESE",
  N: "N",
  NE: "NE",
  NNE: "NNE",
  NNW: "NNW",
  NW: "NW",
  S: "S",
  SE: "SE",
  SSE: "SSE",
  SSW: "SSW",
  SW: "SW",
  W: "W",
  WNW: "WNW",
  WSW: "WSW"
};

export const PressureTrend = {
  RISING: "rising",
  FALLING: "falling"
};

export type WeatherSensorType = $Keys<typeof WeatherSensor>;

// Default Values, will overwritten by AppSettings
export const WeatherSensorUnit = {
  [WeatherSensor.COMBINED_TEMP]: "ºC",
  [WeatherSensor.AIR_TEMP]: "ºC",
  [WeatherSensor.SOIL_TEMP]: "ºC",
  [WeatherSensor.HUMIDITY]: "%",
  [WeatherSensor.LUX]: "lux",
  [WeatherSensor.PRESSURE]: "hPa",
  [WeatherSensor.RAIN]: "mm",
  [WeatherSensor.RAIN_HOURLY]: "mm",
  [WeatherSensor.RAIN_DAILY]: "mm",
  [WeatherSensor.RAIN_WEEKLY]: "mm",
  [WeatherSensor.UV]: "uv",
  [WeatherSensor.WIND]: "m/s",
  [WeatherSensor.WIND_MAX]: "m/s",
  [WeatherSensor.GDD]: "GDD",
  [WeatherSensor.SOLAR_RADIATION]: "w/m^2",
};

export const WeatherSensorColor = {
  [WeatherSensor.AIR_TEMP]: AIR_TEMP_COLOR,
  [WeatherSensor.SOIL_TEMP]: SOIL_TEMP_COLOR,
  [WeatherSensor.HUMIDITY]: HUMIDITY_COLOR,
  [WeatherSensor.LUX]: LUMINOSITY_COLOR,
  [WeatherSensor.PRESSURE]: PRESSURE_COLOR,
  [WeatherSensor.RAIN]: RAIN_COLOR,
  [WeatherSensor.RAIN_HOURLY]: RAIN_COLOR,
  [WeatherSensor.RAIN_DAILY]: RAIN_COLOR,
  [WeatherSensor.RAIN_WEEKLY]: RAIN_COLOR,
  [WeatherSensor.UV]: UV_COLOR,
  [WeatherSensor.WIND]: WIND_COLOR,
  [WeatherSensor.WIND_MAX]: WIND_GUSTS_COLOR,
  [WeatherSensor.GDD]: GDD_COLOR,
  [WeatherSensor.SOLAR_RADIATION]: SOLAR_RADIATION_COLOR,
};

export const WeatherBarWidth = (resolution) => {
  return getDurationMillis(resolution);
};

export const ChartType = {
  BAR: 'bar',
  LINE: 'line',
};

export const WeatherChartType = {
  [WeatherSensor.COMBINED_TEMP]: ChartType.LINE,
  [WeatherSensor.AIR_TEMP]: ChartType.LINE,
  [WeatherSensor.SOIL_TEMP]: ChartType.LINE,
  [WeatherSensor.HUMIDITY]: ChartType.LINE,
  [WeatherSensor.LUX]: ChartType.LINE,
  [WeatherSensor.PRESSURE]: ChartType.LINE,
  [WeatherSensor.RAIN]: ChartType.BAR,
  [WeatherSensor.RAIN_HOURLY]: ChartType.BAR,
  [WeatherSensor.RAIN_DAILY]: ChartType.BAR,
  [WeatherSensor.RAIN_WEEKLY]: ChartType.BAR,
  [WeatherSensor.UV]: ChartType.LINE,
  [WeatherSensor.WIND]: ChartType.LINE,
  [WeatherSensor.WIND_MAX]: ChartType.LINE,
  [WeatherSensor.GDD]: ChartType.LINE,
  [WeatherSensor.SOLAR_RADIATION]: ChartType.LINE,
};

export const WeatherSensorDecimals = {
  [WeatherSensor.AIR_TEMP]: 1,
  [WeatherSensor.SOIL_TEMP]: 1,
  [WeatherSensor.HUMIDITY]: 0,
  [WeatherSensor.LUX]: 0,
  [WeatherSensor.PRESSURE]: 0,
  [WeatherSensor.RAIN]: 2,
  [WeatherSensor.RAIN_HOURLY]: 2,
  [WeatherSensor.RAIN_DAILY]: 2,
  [WeatherSensor.RAIN_WEEKLY]: 2,
  [WeatherSensor.UV]: 1,
  [WeatherSensor.WIND]: 1,
  [WeatherSensor.WIND_MAX]: 1,
  [WeatherSensor.GDD]: 2,
  [WeatherSensor.SOLAR_RADIATION]: 0,
};

export const WeatherSensorName = (LangFile) => ({
  [WeatherSensor.AIR_TEMP]: LangFile.WeatherConstants.sensorNames.airTemp,
  [WeatherSensor.SOIL_TEMP]: LangFile.WeatherConstants.sensorNames.soilTemp,
  [WeatherSensor.HUMIDITY]: LangFile.WeatherConstants.sensorNames.humidity,
  [WeatherSensor.LUX]: LangFile.WeatherConstants.sensorNames.lux,
  [WeatherSensor.PRESSURE]: LangFile.WeatherConstants.sensorNames.pressure,
  [WeatherSensor.RAIN]: LangFile.WeatherConstants.sensorNames.rain,
  [WeatherSensor.RAIN_HOURLY]: LangFile.WeatherConstants.sensorNames.hourlyRain,
  [WeatherSensor.RAIN_DAILY]: LangFile.WeatherConstants.sensorNames.dailyRain,
  [WeatherSensor.RAIN_WEEKLY]: LangFile.WeatherConstants.sensorNames.weeklyRain,
  [WeatherSensor.UV]: LangFile.WeatherConstants.sensorNames.uvIndex,
  [WeatherSensor.WIND]: LangFile.WeatherConstants.sensorNames.wind,
  [WeatherSensor.WIND_MAX]: LangFile.WeatherConstants.sensorNames.windGusts,
  [WeatherSensor.GDD]: LangFile.WeatherConstants.sensorNames.gdd,
  [WeatherSensor.SOLAR_RADIATION]: LangFile.WeatherConstants.sensorNames.solarRadiation,
});

export const RAIN_ACCUMULATION = {
  LAST_24_HOURS: "accumulatedRain",
  SINCE_MIDNIGHT: "accumulatedRainDaily"
};
