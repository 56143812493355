import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import {
  Grid,
  Typography
} from "@material-ui/core";
import {Lock} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {useLangFile} from "js/context/LanguageContext";
import {grey} from "@material-ui/core/colors";
import StyleConstants from "js/StyleConstants";
import {validateEmail} from "js/helpers/EmailUtils";
import useEvent from "js/hooks/useEvent";

const styles = (theme) => ({
  form: {
    height: "100%",
  },
  slide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  textField: {
    margin: [[theme.spacing(1), 0]],
    width: '100%',
    color: grey["500"],
  },
  icon: {
    color: StyleConstants.palette.primary.main,
  },
  slideButton: {
    marginTop: theme.spacing(1),
  }
});

const RestorePasswordForm = ({classes, shown, onSlide, onSubmit}) => {

  const LangFile = useLangFile();
  const emailRef = useRef(null);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handleEmailChanged = useCallback((e) => {
    let newValue = e.target.value.trim();

    setEmail(newValue);
  }, []);

  const handleOnFocus = useCallback((e) => {
    setEmailError(false);
  }, []);

  const handleOnBlur = useCallback((e) => {
    setEmailError(!validateEmail(email));
  }, [email]);

  const handleOnSubmit = useCallback((e) => {
    onSubmit(email);
  }, [email]);

  const enterHandler = (event) => {
    if (!shown) {
      return;
    }

    if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault();
      handleOnSubmit();
    }
  };

  useEvent("keydown", enterHandler, emailRef.current);

  useEffect(() => {
    if (emailRef.current) {
      if (shown) {
        emailRef.current.focus();
      }
      else {
        emailRef.current.blur();
      }
    }
  }, [shown, emailRef.current]);

  return (
    <form className={classes.form}>
      <div className={classes.slide}>
        <div>
          <Grid container direction={"column"}>
            <Typography variant={"h6"} align={"center"}>
              {LangFile.RestorePasswordForm.title}
            </Typography>

            <Grid item container direction={"row"} wrap={"nowrap"}>
              <Grid item container xs={1} justify={"center"} alignItems={"center"}>
                <Lock className={classes.icon}/>
              </Grid>
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  classes={{root: classes.textField}}
                  className={"remove-autofill-color"}
                  variant={"outlined"}
                  tabIndex={shown ? 1 : -1}
                  placeholder={LangFile.RestorePasswordForm.emailHintText}
                  value={email}
                  color={"secondary"}
                  InputProps={{
                    autoComplete: "email"
                  }}
                  inputRef={emailRef}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                  label={emailError ? LangFile.RestorePasswordForm.emailError : ""}
                  error={emailError}
                  onChange={handleEmailChanged}/>
              </Grid>
            </Grid>

            <Grid item container justify={"center"}>
              {LangFile.RestorePasswordForm.description}
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid container direction={"column"}>
            <Button
              fullWidth
              variant={"contained"}
              tabIndex={shown ? 2 : -1}
              color={"primary"}
              disabled={!shown}
              onClick={handleOnSubmit}>{LangFile.RestorePasswordForm.title}</Button>
            <Button
              fullWidth
              className={classes.slideButton}
              tabIndex={shown ? 3 : -1}
              color={"primary"}
              onClick={onSlide}>{LangFile.RestorePasswordForm.back}</Button>
          </Grid>
        </div>
      </div>
    </form>
  );
};

RestorePasswordForm.propTypes = {
  shown: PropTypes.bool,
  onSlide: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default withStyles(styles)(RestorePasswordForm);
