import React, {
  Fragment,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import {useLangFile} from "js/context/LanguageContext";
import CircularProgress from "@material-ui/core/CircularProgress";

const PrescriptionSaveDialog = ({open, savingJob, savingError, onCancel, onExit, onSaveAndExit}) => {
  const LangFile = useLangFile();

  return (
    <Fragment>
      <Dialog open={Boolean(open)} fullWidth maxWidth={"md"}>
        <DialogTitle>
          {savingJob && LangFile.PrescriptionSaveDialog.saving}
          {!savingJob && LangFile.PrescriptionSaveDialog.title}
        </DialogTitle>
        <DialogContent style={{overflow: "hidden"}}>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            {savingJob ? (
              <CircularProgress size={80} thickness={5}/>
            ) : (
              <Fragment>
                {savingError ? (
                  <Box mb={1}>
                    {LangFile.PrescriptionSaveDialog.error}
                  </Box>
                ) : (
                  <Box mb={1}>
                    {LangFile.PrescriptionSaveDialog.info}
                  </Box>
                )}
              </Fragment>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {!savingJob && (
              <Fragment>
                <Button onClick={onCancel} color={"default"} variant={"outlined"}>
                  {LangFile.PrescriptionSaveDialog.cancel}
                </Button>

                {savingError ? (
                  <Button onClick={onExit} color={"secondary"} variant={"contained"}>
                    {LangFile.PrescriptionSaveDialog.onExit}
                  </Button>
                ) : (
                  <Fragment>
                    <Button onClick={onExit} color={"default"} variant={"outlined"}>
                      {LangFile.PrescriptionSaveDialog.onExit}
                    </Button>
                    <Button onClick={onSaveAndExit} color={"primary"} variant={"contained"}>
                      {LangFile.PrescriptionSaveDialog.onSaveAndExit}
                    </Button>
                  </Fragment>
                )}
              </Fragment>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default memo(PrescriptionSaveDialog);

PrescriptionSaveDialog.propTypes = {
  open: PropTypes.bool,
  savingJob: PropTypes.bool,
  savingError: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  onSaveAndExit: PropTypes.func.isRequired,
};