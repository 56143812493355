import React, {Fragment, memo, useCallback, useState} from 'react';
import {
  Box,
  Button,
  Divider,
  List,
  ListSubheader,
  Typography
} from '@material-ui/core';
import Styles from './FieldOverview.module.less';
import {AddCircle, Close, CloudDownload, MoreVert} from '@material-ui/icons';
import PropTypes from 'prop-types';
import {voidFunc} from '../../constants/PropTypeUtils';
import {useLangFile} from '../../context/LanguageContext';
import FieldOverviewListSection from './FieldOverviewListSection';
import FieldOverviewListItem from './FieldOverviewListItem';
import {getFieldName} from '../../helpers/StateInterpreters';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CropsIcon from "../Icons/CropsIcon";
import {useFieldContext} from "../../context/FieldContext";

const FieldOverview = (props: FieldOverview.propTypes) => {
  const {sections, onCloseClicked, onCropsClicked, onAddFieldsClicked, onCropTypeChecked, onClearFilter, selectedField, filteredFields, filteredCropTypes, onFieldClicked, onFieldChecked, onExportFields} = props;

  const LangFile = useLangFile();
  const [overflowMenuAnchor, setOverflowMenuAnchor] = useState(null);
  const {fields} = useFieldContext();

  const handleFieldClick = useCallback((field) => () => {
    onFieldClicked(field);
  });

  const handleFieldChecked = useCallback((field, checked) => () => {
    onFieldChecked(field, checked);
  });

  const handleSectionChecked = useCallback((cropType, checked) => () => {
    onCropTypeChecked(cropType, checked);
  });

  const handleOverflowMenuClick = useCallback((event) => setOverflowMenuAnchor(event.currentTarget), []);
  const handleOverflowMenuClose = useCallback(() => setOverflowMenuAnchor(null), []);

  const handleExportFieldsClick = useCallback(() => {
    onExportFields();
    handleOverflowMenuClose();
  }, [handleOverflowMenuClose, onExportFields]);

  let isEmptyState = sections.length === 0;
  let fieldCount = fields ? fields.size : 0;

  return (
    <Fragment>
      <Box className={Styles.box} pb={5}>
        <Box mx={1.5} mt={1.5} mb={1} display={'flex'}>
          <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Box display={'flex'} alignItems={'center'}>
              <Button
                className={Styles.button}
                onClick={onCloseClicked}>
                <Close/>
              </Button>
              <Box mx={1.5}>
                <Typography>{LangFile.FieldDrawer.title} ({fieldCount})</Typography>
              </Box>
            </Box>
            <Button
              className={Styles.button}
              onClick={onClearFilter}>
              <Typography className={Styles.buttonText}>{LangFile.FieldIntegrationMapping.clearSelection}</Typography>
            </Button>
          </Box>
        </Box>
        <Divider/>
        <List
          className={Styles.list}
          dense={true}
          subheader={<li />}>

          {isEmptyState && (
            <Box px={0.5} py={2}>
              <FieldOverviewListItem
                disabled={true}
                showRadiobutton={false}
                title={LangFile.FieldDrawer.noFieldsAdded}
                subtitle={LangFile.FieldDrawer.clickTheGreenButton}/>
            </Box>
          )}

          {sections.map((section) => (
            <li key={`section-${section.id}`}>
              <ul>
                <ListSubheader classes={{root: Styles.subheader}} disableGutters={true}>
                  <FieldOverviewListSection
                    title={`${section.header} (${section.fields ? section.fields.length : 0})`}
                    checked={filteredCropTypes.includes(section.cropType)}
                    onCheckboxClick={(checked) => {
                      handleSectionChecked(section.cropType, checked)();
                    }}/>
                </ListSubheader>
                {section.fields.map((field) => (
                  <FieldOverviewListItem
                    id={field.fieldId}
                    onCheckboxClick={(checked) => {
                      handleFieldChecked(field, checked)();
                    }}
                    checked={filteredFields.has(field.fieldId)}
                    onClick={handleFieldClick(field)}
                    showRadiobutton={false}
                    showCheckbox={true}
                    key={`item-${section.id}-${field.fieldId}`}
                    selected={selectedField && selectedField.fieldId === field.fieldId}
                    title={getFieldName(field, LangFile)}
                    subtitle={field.size.toFixed(1) + " ha" + (field.cropVariation ? `, ${field.cropVariation}` : "")}/>
                ))}
              </ul>
            </li>
          ))}
        </List>

        <Box display={'flex'} bgcolor={'#E6E7E8'} flexDirection={'row'} className={Styles.buttonRow}>
          <Box flex={1}>
            <Button
              className={Styles.button}
              disabled={isEmptyState}
              fullWidth={true}
              onClick={onCropsClicked}>
              <Box display={'flex'} alignItems={'center'}>
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography className={Styles.buttonText}>{LangFile.FieldSettingsDialog.cropsTitle}</Typography>
                  <Box ml={1} display={'flex'}>
                    <CropsIcon fontSize={"small"} color={isEmptyState ? "disabled" : "inherit"}/>
                  </Box>
                </Box>
              </Box>
            </Button>
          </Box>
          <Box flex={1} bgcolor={'#78A22F'}>
            <Button
              className={Styles.button}
              fullWidth={true}
              onClick={onAddFieldsClicked}>
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Typography
                  className={Styles.buttonText}
                  style={{color: 'white'}}>{LangFile.FieldDrawer.addFields}</Typography>
                <Box ml={1} display={'flex'}>
                  <AddCircle style={{color: 'white', height: 24}}/>
                </Box>
              </Box>
            </Button>
          </Box>
          <Box>
            <Button
              className={Styles.button}
              onClick={handleOverflowMenuClick}>
              <Box my={1} mx={0.5} display={'flex'}>
                <MoreVert/>
              </Box>
            </Button>

            <Menu
              anchorEl={overflowMenuAnchor}
              keepMounted={true}
              open={Boolean(overflowMenuAnchor)}
              onClose={handleOverflowMenuClose}>
              <MenuItem key={"export-fields"} onClick={handleExportFieldsClick}>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                  <CloudDownload/>
                  <Box mr={1}/>
                  {LangFile.FieldDrawer.exportFields}
                </Box>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

FieldOverview.propTypes = {
  sections: PropTypes.array,
  onCloseClicked: PropTypes.func,
  onCropsClicked: PropTypes.func,
  onAddFieldsClicked: PropTypes.func,
  onFieldClicked: PropTypes.func,
  onFieldChecked: PropTypes.func,
  onCropTypeChecked: PropTypes.func,
  onClearFilter: PropTypes.func,
  onExportFields: PropTypes.func,
  selectedField: PropTypes.object,
  filteredFields: PropTypes.object,
  filteredCropTypes: PropTypes.array
};

FieldOverview.defaultProps = {
  onCloseClicked: voidFunc,
  onCropsClicked: voidFunc,
  onAddFieldsClicked: voidFunc,
  onExportFields: voidFunc,
  onFieldChecked: voidFunc,
  onCropTypeChecked: voidFunc,
  onClearFilter: voidFunc
};

export default memo(FieldOverview);
