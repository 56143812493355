import React, {memo} from 'react';

import InputFieldUpdatedOnBlur from 'js/components/UI-Elements/InputFieldUpdatedOnBlur/InputFieldUpdatedOnBlur';

import style from "js/components/Prescription/DraggableIntervals/ValueDisplayBoxes/ValueDisplayBoxes.module.less";
import Box from "@material-ui/core/Box";
import {PRESCRIPTION_UNIT} from "js/constants/PrescriptionConstants";
import NumberUtils from "js/helpers/NumberUtils";
import {usePrescriptionJob} from "js/components/Prescription/PrescriptionJobContext";
import PropTypes from "prop-types";
import {useLangFile} from '../../../../context/LanguageContext';

const ValueDisplayBoxes = ({onDragValue}) => {
  const LangFile = useLangFile();
  const {prescriptionJob} = usePrescriptionJob();
  const {intervals, maxPrescription, fieldSize, unit} = prescriptionJob;

  let intervalRange = [intervals[0].min, intervals[intervals.length - 1].max];

  let xMap = (val) => NumberUtils.map(val, intervalRange[0], intervalRange[1], 0, 100);

  let unitString;
  switch (unit) {
    case PRESCRIPTION_UNIT.LITER:
      unitString = `${LangFile.PrescriptionSettings.unit.liters.short} / ha`;
      break;
    case PRESCRIPTION_UNIT.PIECES_M2:
      unitString = `${LangFile.PrescriptionSettings.unit.pieces.short} / m^2`;
      break;
    default:
      unitString = `${LangFile.PrescriptionSettings.unit.kilogram.short} / ha`;
      break;
  }

  let maxValue = (maxPrescription / fieldSize) * 2;

  return intervals.map((interval, idx) => {
    let roundedEntry = Number(interval.prescription.toFixed(2));
    let nextInterval = intervals[idx + 1];

    let lowerBound = interval.min;
    let upperBound = nextInterval ? nextInterval.min : interval.max;

    let xPos = lowerBound + ((upperBound - lowerBound) / 2);

    return (
      <Box key={idx} className={style.Wrapper} left={`${xMap(xPos)}%`}>
        <InputFieldUpdatedOnBlur
          disabled={false}
          value={roundedEntry}
          placeholder={roundedEntry}
          onUpdate={(value) => onDragValue(idx, NumberUtils.limit(Number(value), 0, maxValue))}/>
        <span className={style.Unit}>{unitString}</span>
      </Box>
    );
  });
};

ValueDisplayBoxes.propTypes = {
  onDragValue: PropTypes.func,
};

export default memo(ValueDisplayBoxes);
