import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';

const styles = (theme) => ({
  root: {
    margin: 2,
    borderRadius: '50%',
    boxShadow: '4px 4px 4px rgba(0,0,0,0.4)',
    transform: 'scale(0.8, 0.8)'
  }
});

const WeatherStationIndicator = ({classes, children, backgroundColor, shown}) => {
  let style = {
    backgroundColor: backgroundColor,
  };

  if (!shown) {
    return null;
  }

  return (
    <div className={classes.root} style={style}>
      {children}
    </div>
  );
};

WeatherStationIndicator.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  shown: PropTypes.bool
};

export default withStyles(styles)(WeatherStationIndicator);