"use strict";

import React, {
  Fragment,
  memo,
  useCallback, useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import ViewModeConstants from 'js/constants/ViewModeConstants';

import MapField from 'js/components/MapObjects/MapField';
import MapAddFieldOutline from 'js/components/MapObjects/MapAddFieldOutline';

import WeatherNetworkMapContainer from "js/components/WeatherNetwork/WeatherNetworkMapContainer";
import {useHasSetting} from "js/context/SettingsContext";
import SettingsConstants from "js/constants/SettingsConstants";
import ToolbarContainer from "js/components/Toolbar/ToolbarContainer";
import GoogleMap from "js/components/MapObjects/GoogleMap/GoogleMap";
import AddFieldsContainer from "js/components/AddFields/AddFieldsContainer";
import MapFloatPanel from "js/components/MapView/MapFloatPanel";
import PolygonDrawingContainer from "js/components/PolygonDrawing/PolygonDrawingContainer";
import AnalysisDrawerContainer from "js/components/AnalysisDrawer/AnalysisDrawerContainer";
import WeatherDrawerContainer from "js/components/WeatherDrawer/WeatherDrawerContainer";
import WeatherNetworkDrawerContainer from "js/components/WeatherNetwork/WeatherNetworkDrawerContainer";
import SoilSampleVisualizerContainer from "../SoilSamples/SoilSampleVisualizerContainer";
import {useEffectGoToFieldsOrCountryOnSync} from "../../hooks/useEffectGoToFieldsOrCountryOnSync";
import {useIsLoggedIn} from "../../context/AccountContext";
import MapMarqueeSelect from "../MapObjects/MapMarqueeSelect";
import useShiftKeyDown from "../../hooks/useShiftKeyDown";
import ManualFeatureConstants from "js/constants/ManualFeatureConstants";
import DateKeyboardHandlerContainer from "js/components/DateHandler/DateKeyboardHandlerContainer";
import ToastContainer from "js/components/Toasts/ToastContainer";
import AnalysisMapViewContainer from "../AnalysisMapView/AnalysisMapViewContainer";
import MapLegendContainer from "../MapLegend/MapLegendContainer";
import {getLayerConfig} from "../Prescription/PrescriptionUtils";
import CreateSeasonContainer from "../Seasons/CreateSeasonContainer";
import FieldTextCanvas from "../ManageCrops/FieldTextCanvas";
import {getFieldName} from "../../helpers/StateInterpreters";
import {useLangFile, useLangFileRef} from "../../context/LanguageContext";
import {getFieldsArray} from "../ManageCrops/ManageCrops";
import {useFieldContext, useUnconnectedFields} from "../../context/FieldContext";
import SurveyStatisticsContainer from '../SurveyStatistics/SurveyStatisticsContainer';
import MenuPopoverButton from '../MenuButton/MenuPopoverButton';
import ListOutlined from '@material-ui/icons/ListOutlined';
import FieldOverviewContainer from '../FieldOverviewList/FieldOverviewContainer';
import CloudIcon from '@material-ui/icons/Cloud';
import WeatherOverviewContainer from '../WeatherOverview/WeatherOverviewContainer';
import MenuButton from '../MenuButton/MenuButton';
import {Equalizer} from "@material-ui/icons";
import ViewCompactIcon from "../Icons/ViewCompactIcon";
import TractorIcon from "../Icons/TractorIcon";
import HeightMap from '../MapObjects/HeightMap/HeightMap';

const MapView = ({
                   viewMode,
                   onClickAway,
                   outlines,
                   onSelectOutline,
                   selectedOutlines,
                   currentFieldImages,
                   imageType,
                   date,
                   onSelectField,
                   onSettingsClicked,
                   openAnalysisModule,
                   openVRMModule,
                   openNotesModule,
                   isMagicSelecting,
                   weatherStations,
                   showSoilSurveys,
                   variationsEnabled,
                   classificationsEnabled,
                   surveys,
                   selectedLayer,
                   showSatelliteImagery,
                   isImportingFields,
                   isAddingFields,
                   goToFieldsEvent,
                   onSelectMultipleOutlines,
                   disableFieldInteraction,
                   onBottomOpen,
                   onPrescriptionArchiveClicked,
                   onFieldStatisticsClicked,
                   showHeightMap,
                   overlays,
                   onMachineDataClicked,
                 }) => {

  const LangFileRef = useLangFileRef();
  const loggedIn = useIsLoggedIn();
  const singleField = ManualFeatureConstants.SINGLE_FIELD_ANALYSIS || useHasSetting(SettingsConstants.SINGLE_FIELD_ANALYSIS);
  const [googleMap, setGoogleMap] = useState(null);

  const shiftDown = useShiftKeyDown();
  const {fields, selectedField, filteredFields} = useFieldContext();
  const unconnectedFields = useUnconnectedFields();

  const fieldPopoverRef = useRef();
  const weatherPopoverRef = useRef();

  const LangFile = useLangFile();

  useEffectGoToFieldsOrCountryOnSync(googleMap, goToFieldsEvent, fields, filteredFields, weatherStations);

  const handleMarqueeSelect = useCallback((selection) => {
    onSelectMultipleOutlines(selection);
  }, [onSelectMultipleOutlines]);

  const renderFieldText = useCallback((field, zoom) => {

    let lines = [];

    // Name
    if (zoom >= 15) {
      lines.push({
        text: getFieldName(field, LangFileRef.current),
        fontWeight: "400",
        fontSize: 14,
        textAlign: "center",
        color: "white",
      });
    }

    // Crop
    if (zoom >= 14) {
      let cropText = LangFileRef.current.CropType[field.crop];

      lines.push({
        text: cropText,
        fontWeight: "900",
        fontSize: 14,
        textAlign: "center",
        color: "white",
      });

      // Crop Variation
      if (field.cropVariation != null && field.cropVariation.length > 0) {
        let cropVariationText = field.cropVariation;

        lines.push({
          text: cropVariationText,
          fontWeight: "900",
          fontSize: 14,
          textAlign: "center",
          color: "white",
        });
      }

    }

    return lines;
  }, [fields]);

  const renderUnconnectedFieldText = useCallback((field, zoom) => {
    let lines = [];

    // Name
    if (zoom >= 15) {
      lines.push({
        text: getFieldName(field, LangFileRef.current),
        fontWeight: "400",
        fontSize: 14,
        textAlign: "center",
        color: "white",
      });
    }

    // Crop
    if (zoom >= 14) {
      lines.push({
        text: LangFileRef.current.MapView.alreadyAdded,
        fontWeight: "900",
        fontSize: 14,
        textAlign: "center",
        color: "white",
      });
    }

    return lines;
  }, [fields]);

  let generateFields = () => {
    // Create a Map of all available images

    let imageMap = new Map(currentFieldImages.map((image) => [image.fieldId, image]));
    let fieldsArray;
    if (filteredFields.size === 0) {
      fieldsArray = getFieldsArray(fields);
    }
    else {
      let filtered = new Map(filteredFields);
      if (selectedField !== null) {
        filtered.set(selectedField.fieldId, selectedField);
      }
      fieldsArray = getFieldsArray(filtered);
    }

    let fieldImageObjects = fieldsArray.map((field, idx) => {
      let fieldImage = null;

      if (showSatelliteImagery) {
        fieldImage = imageMap.get(field.fieldId);
      }

      let survey = surveys && surveys.find((survey) => survey.fieldId === field.fieldId);
      if (showSoilSurveys && survey) {
        let config = getLayerConfig(selectedLayer);
        let images = Object.assign(survey.images, survey.newImages);

        fieldImage = {
          bounds: field.bounds,
          url: config.getImageUrl(images, classificationsEnabled, variationsEnabled, viewMode),
        };
      }

      if (isImportingFields || isAddingFields) {
        fieldImage = null;
      }

      return {field, fieldImage, survey};
    });

    fieldImageObjects.sort((a,b) => a.fieldImage ? 1 : -1);

    let fieldMapObjects = fieldImageObjects.map(({field, fieldImage, survey}, idx) => {
      let selected = selectedField && selectedField.fieldId === field.fieldId;
      let hidden = (!selected && viewMode === ViewModeConstants.ANALYSIS && singleField);

      if (hidden) {
        return null;
      }

      return (
        <MapField
          imageType={imageType}
          date={date}
          field={field}
          selected={selected}
          onlyOutline={!showSatelliteImagery}
          key={`field-${field.fieldId || idx}`}
          disabled={disableFieldInteraction}
          viewMode={viewMode}
          survey={survey}
          onClick={onSelectField}
          onClickAway={onClickAway}
          onSettings={onSettingsClicked}
          onAnalyse={openAnalysisModule}
          onPrescribe={openVRMModule}
          onNotes={openNotesModule}
          fieldImage={fieldImage}/>
      );
    });

    return (
      <>
        {fieldMapObjects}
        <FieldTextCanvas fields={fieldsArray} renderField={renderFieldText}/>
      </>
    );
  };

  const onMapLoaded = useCallback((map) => {
    setGoogleMap(map);
  }, []);

  const onFieldClicked = useCallback((event) => {
    onSelectField(selectedField, event);
  }, [selectedField, onSelectField]);

  return (
    <GoogleMap
      isMagicWandSelecting={isMagicSelecting}
      onMapLoaded={onMapLoaded}>

      {loggedIn && (
        <Fragment>
          <MapFloatPanel zIndex={0} top={0} left={0} right={0} bottom={0}>
            <GoogleMap
              mapType={"transparent"}
              disableInteraction={true}
              syncWithMap={googleMap}>

              <HeightMap
                shown={showHeightMap}
                overlays={overlays}/>

            </GoogleMap>
          </MapFloatPanel>

          <MapFloatPanel zIndex={200} top={0} left={0} right={0}>
            <PolygonDrawingContainer controls={true} popup={true}/>
          </MapFloatPanel>

          <AddFieldsContainer/>

          <CreateSeasonContainer/>

          <ToastContainer />

          <WeatherNetworkMapContainer/>

          {outlines.length > 0 && <FieldTextCanvas fields={unconnectedFields} renderField={renderUnconnectedFieldText}/>}

          {viewMode === ViewModeConstants.ANALYSIS && <AnalysisDrawerContainer googleMap={googleMap} onBottomOpen={onBottomOpen}/>}

          <WeatherDrawerContainer/>

          <WeatherNetworkDrawerContainer/>

          <SoilSampleVisualizerContainer />

          <DateKeyboardHandlerContainer/>

          <ToolbarContainer>

            {viewMode === ViewModeConstants.OVERVIEW && (
              <MenuPopoverButton
                ref={fieldPopoverRef}
                icon={<ListOutlined/>}
                disableClickAway={true}
                buttonText={LangFile.FieldDrawer.buttonText}
                tooltip={LangFile.FieldDrawer.tooltip}
                onClick={() => {
                  if (weatherPopoverRef.current) {
                    weatherPopoverRef.current.closePopover();
                  }
                }}
                renderChildren={(onClose) => (
                  <FieldOverviewContainer
                    onClose={onClose}/>
                )} />
            )}

            {viewMode === ViewModeConstants.OVERVIEW && (
              <MenuPopoverButton
                ref={weatherPopoverRef}
                icon={<CloudIcon/>}
                disableClickAway={true}
                buttonText={LangFile.WeatherLayerDrawerContainer.buttonText}
                tooltip={LangFile.WeatherLayerDrawerContainer.tooltip}
                onClick={() => {
                  if (fieldPopoverRef.current) {
                    fieldPopoverRef.current.closePopover();
                  }
                }}
                renderChildren={(onClose) => (
                  <WeatherOverviewContainer
                    onClose={onClose}/>
                )} />
            )}

            {viewMode === ViewModeConstants.OVERVIEW && (
              <MenuButton
                icon={<Equalizer/>}
                disableClickAway={true}
                buttonText={LangFile.FieldDrawer.prescriptions}
                tooltip={LangFile.FieldDrawer.prescriptionArchiveTooltip}
                onClick={onPrescriptionArchiveClicked}/>
            )}


            {viewMode === ViewModeConstants.OVERVIEW && (
                <MenuButton
                    icon={<TractorIcon/>}
                    disableClickAway={true}
                    buttonText={LangFile.MachineDataContainer.buttonText}
                    tooltip={LangFile.MachineDataContainer.tooltip}
                    onClick={onMachineDataClicked}/>
            )}

            {viewMode === ViewModeConstants.OVERVIEW && (
                <MenuButton
                    icon={<ViewCompactIcon/>}
                    disableClickAway={true}
                    buttonText={LangFile.MapLegend.statistics}
                    tooltip={LangFile.MapLegend.statisticsTooltip}
                    onClick={onFieldStatisticsClicked}/>
            )}

            <MapLegendContainer/>

            <SurveyStatisticsContainer/>
          </ToolbarContainer>

          {viewMode === ViewModeConstants.OVERVIEW && generateFields()}

          {outlines.map((outline, idx) => (
            <MapAddFieldOutline
              key={"outline"+idx}
              outline={outline}
              onClick={onSelectOutline}
              disabled={shiftDown}
              selected={selectedOutlines.indexOf(outline) !== -1}/>
          ))}

          {ManualFeatureConstants.MARQUEE_SELECT && (
            <MapMarqueeSelect
              outlines={outlines}
              onSelect={handleMarqueeSelect}/>
          )}

          {viewMode === ViewModeConstants.ANALYSIS && <AnalysisMapViewContainer onFieldClicked={onFieldClicked} />}
        </Fragment>
      )}
    </GoogleMap>
  );
};

MapView.propTypes = {
  farmFields: PropTypes.array,
  viewMode: PropTypes.string,
  onClickAway: PropTypes.func,
  showWeatherStations: PropTypes.bool,
  showWeatherNetwork: PropTypes.bool,
  networkSensor: PropTypes.string,
  outlines: PropTypes.array,
  onSelectOutline: PropTypes.func,
  onSelectMultipleOutlines: PropTypes.func,
  selectedOutlines: PropTypes.array,
  fieldsLoaded: PropTypes.bool,
  currentFieldImages: PropTypes.array,
  imageType: PropTypes.string,
  date: PropTypes.string,
  onSelectField: PropTypes.func,
  onSettingsClicked: PropTypes.func,
  openAnalysisModule: PropTypes.func,
  openVRMModule: PropTypes.func,
  openNotesModule: PropTypes.func,
  isMagicSelecting: PropTypes.bool,
  showSatelliteImagery: PropTypes.bool,
  syncing: PropTypes.bool,
  showSoilSurveys: PropTypes.bool,
  variationsEnabled: PropTypes.bool,
  classificationsEnabled: PropTypes.bool,
  disableFieldInteraction: PropTypes.bool,
  surveys: PropTypes.array,
  selectedLayer: PropTypes.string,
  onBottomOpen: PropTypes.func,
  onPrescriptionArchiveClicked: PropTypes.func,
  onFieldStatisticsClicked: PropTypes.func,
  showHeightMap: PropTypes.bool,
  overlays: PropTypes.array,
  onMachineDataClicked: PropTypes.func,
};

export default memo(MapView);
