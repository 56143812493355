import {useLangFile} from "../../context/LanguageContext";
import React, {Fragment, memo, useCallback, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import {Edit} from "@material-ui/icons";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import moment from "moment-timezone";
import Styles from './CreateUpdateSeason.module.less';
import EditTextDialog from "../Modals/EditTextDialog";
import PropTypes from "prop-types";
import CreateUpdateSeasonDateInput from "./CreateUpdateSeasonDateInput";
import {Tooltip} from "@material-ui/core";
import DialogConfirmArchiveSeason from "./DialogConfirmArchiveSeason";
import {formatSeason} from "../../helpers/SeasonUtils";

const dialogTitleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: [[theme.spacing(2), theme.spacing(2), 0, theme.spacing(2)]],
  },
  title: {
    marginRight: theme.spacing(2),
  },

  editButton: {
    padding: 0,
    color: theme.palette.grey[1000],
  },
});

const DialogTitle = withStyles(dialogTitleStyles)((props) => {
  const { children, classes, onEdit, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box display="flex">
        <Typography variant="h6" className={classes.title}>{children}</Typography>
        {onEdit ? (
          <IconButton className={classes.editButton} onClick={onEdit}>
            <Edit />
          </IconButton>
        ) : null}
      </Box>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: [[0, theme.spacing(2)]],
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const parseDateFormat = "YYYY-MM-DD";
const displayDateFormat = "MMM DD, YYYY";

const defaultStartDateMonth = "08";
const defaultStartDateDay = "01";

const defaultEndDateMonth = "07";
const defaultEndDateDay = "31";

const CreateUpdateSeason = (props: CreateUpdateSeason.propTypes) => {
  const LangFile = useLangFile();

  const [startDate, setStartDate] = useState(moment(`${moment().year()}-${defaultStartDateMonth}-${defaultStartDateDay}`, parseDateFormat));
  const [endDate, setEndDate] = useState(moment(`${moment().year()+1}-${defaultEndDateMonth}-${defaultEndDateDay}`, parseDateFormat));
  const [name, setName] = useState(`${LangFile.SeasonUtils.season} ${moment(startDate, parseDateFormat).year()}/${moment(endDate, parseDateFormat).year()}`);
  const [renameSeason, setRenameSeason] = useState(false);
  const [showConfirmArchive, setShowConfirmArchive] = useState(false);

  useEffect(() => {
    if (props.seasonToEdit) {
      const seasonStartDate = moment(props.seasonToEdit.startDate, parseDateFormat);
      const seasonEndDate = moment(props.seasonToEdit.endDate, parseDateFormat);

      setStartDate(seasonStartDate);
      setEndDate(seasonEndDate);

      let formattedSeasonName = formatSeason(props.seasonToEdit, LangFile);
      setName(formattedSeasonName);
    }
  }, [props.seasonToEdit]);

  const onStartDateChange = useCallback((date) => {
    setStartDate(date);
  }, []);

  const onEndDateChange = useCallback((date) => {
    setEndDate(date);

  }, []);

  const onPrimaryClick = useCallback(() => {
    props.onPrimary(name, startDate, endDate);
  }, [props.onPrimary, name, startDate, endDate]);

  const onArchiveConfirmed = useCallback(() => {
    props.onArchive(props.seasonToEdit.id);
    setShowConfirmArchive(false);
  }, [props.onArchive, props.seasonToEdit]);

  const onEditClick = useCallback(() => {
    setRenameSeason(true);
  }, []);

  const handleUpdateName = useCallback((value) => {
    if (value && value.length > 0) {
      setName(value);
    }
  }, []);

  const edit = props.seasonToEdit !== null && props.seasonToEdit !== undefined;
  const primaryTitle = edit ? LangFile.AppSettings.save : LangFile.NoteDialog.create;

  return (
    <Fragment>
      <Dialog onClose={props.onDismiss} open={props.showDialog}>
        <DialogTitle onEdit={onEditClick}>
          {name}
        </DialogTitle>
        <DialogContent>
          <p>{LangFile.CreateUpdateSeason.info1}</p>
          <p>{LangFile.CreateUpdateSeason.info2}</p>
          <ul>
            <li>{LangFile.CreateUpdateSeason.crops}</li>
            <li>{LangFile.CreateUpdateSeason.sowingDates}</li>
            <li>{LangFile.CreateUpdateSeason.prescriptionMaps}</li>
            <li>{LangFile.CreateUpdateSeason.soilSurveys}</li>
          </ul>
          <p>{LangFile.CreateUpdateSeason.datesInfo}</p>
          <Box display={"flex"} >
            <Box width={"18px"}/>
            <CreateUpdateSeasonDateInput
              title={LangFile.CreateUpdateSeason.start}
              date={startDate}
              maxDate={endDate}
              onChanged={onStartDateChange}
              format={displayDateFormat}/>
            <Box width={"18px"}/>
            <CreateUpdateSeasonDateInput
              title={LangFile.CreateUpdateSeason.end}
              date={endDate}
              minDate={startDate}
              onChanged={onEndDateChange}
              format={displayDateFormat}/>
          </Box>
        </DialogContent>
        <DialogActions className={Styles.DialogActions}>
          {
            edit && (
              <Tooltip title={props.enableArchiveButton ? LangFile.CreateUpdateSeason.archiveButtonEnabledTooltip : LangFile.CreateUpdateSeason.archiveButtonDisabledTooltip} placement={"bottom-start"}>
                <div>
                  <Button color={"secondary"} onClick={setShowConfirmArchive} disabled={!props.enableArchiveButton}>
                    {LangFile.CreateUpdateSeason.archiveSeason}
                  </Button>
                </div>
              </Tooltip>
            )
          }
          {
            !edit && (
              <Box/>
            )
          }
          <Box display={"flex"} pt={2}>
            <Button onClick={props.onDismiss} color={"default"}>
              {LangFile.DateRangeInput.cancel}
            </Button>
            <Box width={"8px"}/>
            <Button onClick={onPrimaryClick} color={"primary"} variant={"contained"}>
              { primaryTitle }
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <EditTextDialog
        title={LangFile.WeatherStationMenu.rename}
        open={Boolean(renameSeason)}
        value={name}
        defaultValue={name}
        placeholder={LangFile.SeasonUtils.seasonName}
        onUpdate={(newName) => handleUpdateName(newName)}
        onClose={() => setRenameSeason(false)}/>

      <DialogConfirmArchiveSeason
        onConfirm={onArchiveConfirmed}
        onClose={setShowConfirmArchive}
        open={Boolean(showConfirmArchive)}/>
    </Fragment>
  );
};

CreateUpdateSeason.propTypes = {
  showDialog: PropTypes.bool,
  enableArchiveButton: PropTypes.bool,
  seasonToEdit: PropTypes.object,
  onDismiss: PropTypes.func,
  onPrimary: PropTypes.func,
  onArchive: PropTypes.func
};

export default memo(CreateUpdateSeason);