import './MapAddFieldOutline.css';

import React, {
  memo,
  useCallback,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';

import {blue, green, red} from "@material-ui/core/colors";

import MapPolygon from 'js/components/MapObjects/MapPolygon';
import MapPopup from "js/components/MapObjects/MapPopup";

import {
  polygon,
  centerOfMass,
  flip
} from "@turf/turf/turf.es";
import {voidFunc} from "js/constants/PropTypeUtils";

const getCenter = (outline) => {
  let coords = [outline.coordinates.map((p) => [p.lng, p.lat])];

  if (!coords) {
    return undefined;
  }

  let poly = polygon(coords);
  let center = flip(centerOfMass(poly));
  let position = center.geometry.coordinates;

  return {lat: position[0], lng: position[1]};
};

const MapAddFieldOutline = (props: MapAddFieldOutline.propTypes) => {

  const centerPosition = useRef(getCenter(props.outline));
  const mef = useRef(props.outline.mef);

  const [hovered, setHovered] = useState(false);

  const onClick = useCallback(() => {
    props.onClick(props.outline);
  }, [props.onClick, props.outline]);

  const onMouseEnter = useCallback(() => {
    setHovered(true);
    props.onMouseEnter();
  }, [props.onMouseEnter]);

  const onMouseLeave = useCallback(() => {
    setHovered(false);
    props.onMouseLeave();
  }, [props.onMouseLeave]);

  const defaultOutlineStrokeColor = red["500"];
  const selectedOutlineStrokeColor = green["500"];

  let color = (hovered || props.selected) ? selectedOutlineStrokeColor : defaultOutlineStrokeColor;
  let opacity = props.selected ? 0.6 : 0.01;

  if (hovered) {
    opacity = props.selected ? 0.4 : 0.6;
  }

  if (props.highlighted) {
    color = blue["A700"];
  }

  const childProps = {
    ...props,
    coordinates: props.outline.coordinates,
    onClick: onClick,
    onMouseEnter: onMouseEnter,
    onMouseLeave: onMouseLeave,
    strokeColor: color,
    fillColor: color,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillOpacity: opacity
  };

  return (
    <MapPolygon {...childProps}>
      {hovered && centerPosition.current && (
        <MapPopup
          hasPin
          shown={true}
          selected={false}
          position={centerPosition.current}>
          {mef.current && (
            <div className={"map-field-add-outline"}>
              {mef.current}
            </div>
          )}
        </MapPopup>
      )}
    </MapPolygon>
  );
};

MapAddFieldOutline.propTypes = {
  outline: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  selected: PropTypes.bool,
  highlighted: PropTypes.bool
};

MapAddFieldOutline.defaultProps = {
  onMouseEnter: voidFunc,
  onMouseLeave: voidFunc,
  onClick: voidFunc,
};

export default memo(MapAddFieldOutline);
