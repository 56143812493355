import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {useLangFile} from "js/context/LanguageContext";

const styles = (theme) => ({
  root: {
    pointerEvents: 'all',
  },
  title: {
    flexGrow: 1,
  }
});

const GpsFixAppBar = ({classes, onClose}) => {
  const LangFile = useLangFile();

  return (
    <AppBar color={"default"} position="static" className={classes.root}>
      <Toolbar variant="dense">
        <IconButton edge="start" color="inherit" onClick={onClose}>
          <Close />
        </IconButton>

        <Typography variant="h6" className={classes.title}>
          {LangFile.GpsFixAppBar.title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

GpsFixAppBar.propTypes = {
  onClose: PropTypes.func,
};

export default withStyles(styles)(GpsFixAppBar);