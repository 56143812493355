import React, {
  memo
} from 'react';

import {IconButton} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";
import useLowScreen from "js/hooks/useLowScreen";

const WeatherStationIconButton = (props) => {
  const theme = useTheme();

  const low = useLowScreen();

  const style = {
    padding: theme.spacing(low ? 0.25 : 1),
  };

  return (
    <IconButton {...props} style={style}>
      {props.children}
    </IconButton>
  );
};

export default memo(WeatherStationIconButton);