import {SURVEY_LAYERS, SURVEY_NUTRIENT_LAYERS, SURVEY_TEXTURE_LAYERS} from '../../constants/SurveyLayers';


export const FIELD_STATISTICS_COLUMNS = Object.freeze({
  STATUS: "STATUS",
  FIELD: "FIELD",
  AREA: "AREA",
  CROP: "CROP",
  CROP_VARIETY: "CROP_VARIETY",
  SOWING_DATE: "SOWING_DATE",
});

export const FIELD_STATISTICS_CLAAS_COLUMNS = Object.freeze({
  HARVEST_DATE: "HARVEST_DATE",
  YIELD_TOTAL: "YIELD_TOTAL",
  YIELD_AVERAGE: "YIELD_AVERAGE",
  CROP_MOISTURE: "CROP_MOISTURE",
  FUEL_CONSUMPTION: "FUEL_CONSUMPTION"
});

export const FIELD_STATISTICS_FIELD_COLUMNS = Object.freeze([
  FIELD_STATISTICS_COLUMNS.AREA,
]);

export const FIELD_STATISTICS_SEASON_COLUMNS = Object.freeze([
  FIELD_STATISTICS_COLUMNS.CROP,
  FIELD_STATISTICS_COLUMNS.CROP_VARIETY,
  FIELD_STATISTICS_COLUMNS.SOWING_DATE,
  FIELD_STATISTICS_CLAAS_COLUMNS.HARVEST_DATE,
  FIELD_STATISTICS_CLAAS_COLUMNS.YIELD_TOTAL,
  FIELD_STATISTICS_CLAAS_COLUMNS.YIELD_AVERAGE,
  FIELD_STATISTICS_CLAAS_COLUMNS.CROP_MOISTURE,
  FIELD_STATISTICS_CLAAS_COLUMNS.FUEL_CONSUMPTION,
]);

export const FIELD_STATISTICS_SECTIONS = {
  FIELD_STATISTICS_FIELD_COLUMNS,
  FIELD_STATISTICS_SEASON_COLUMNS,
  SURVEY_NUTRIENT_LAYERS,
  SURVEY_TEXTURE_LAYERS
};

export const getColumnName = (column, langFile, ) => {
  if (Object.keys(SURVEY_LAYERS).includes(column)) {
    let text = `${langFile.SurveyLayers[column].name}`;
    let short = langFile.SurveyLayers[column].shorthand;
    if (short) {
      text += ` (${short})`;
    }
    return text;
  }
  else if (Object.keys(FIELD_STATISTICS_COLUMNS).includes(column)) {
    return langFile.FieldStatisticsColumn[column];
  }
  else if (Object.keys(FIELD_STATISTICS_CLAAS_COLUMNS).includes(column)) {
    return langFile.FieldStatisticsColumn[column];
  }
  return null;
};
