// @flow

import {TEMPERATURE, WIND} from "../constants/MeasureConstants";
import {RAIN} from "js/constants/MeasureConstants";

export const KEYS = Object.freeze({
  MEASURE_UNIT_TEMPERATURE: "MEASURE_UNIT_TEMPERATURE",
  MEASURE_UNIT_WIND: "MEASURE_UNIT_WIND",
  MEASURE_UNIT_RAIN: "MEASURE_UNIT_RAIN",
});

type KEY = $Keys<typeof KEYS>;


const SET_KEY_VALUE = "fieldsense/LocalPreferencesReducer/SET_KEY_VALUE";
export function setKeyValue(key: KEY, value) {
  return {
    type: SET_KEY_VALUE,
    payload: {key, value}
  };
}

const initState = {
  measures: {
    [KEYS.MEASURE_UNIT_TEMPERATURE]: TEMPERATURE.CELCIUS,
    [KEYS.MEASURE_UNIT_WIND]: WIND.METERS_PER_SECOND,
    [KEYS.MEASURE_UNIT_RAIN]: RAIN.MILLIMETERS,
  }
};

export default function reducer(state = initState, action) {
  switch (action.type) {

    case SET_KEY_VALUE: {
      let {key, value} = action.payload;
      state = {...state, measures: {...state.measures, [key]: value}};
      break;
    }

    default:
      break;
  }

  return state;
}
