import React, {
  Fragment,
  memo,
  useCallback
} from 'react';

import ManualFeatureConstants from "js/constants/ManualFeatureConstants";
import {useLangFile} from "js/context/LanguageContext";
import { connect } from 'react-redux';

import {
  setShowReplaceDialog,
  setShowSuspendDialog
} from "js/reducers/ReplaceStationReducer";

import {
  DeleteForeverRounded,
  SyncRounded
} from "@material-ui/icons";

import {
  Box,
  ListItemIcon,
  MenuItem
} from "@material-ui/core";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../hooks/useFirebaseAnalytics";

const mapStateToProps = (store) => {
  return {};
};

const ReplaceStationMenuItemContainer = ({dispatch}) => {

  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();

  if (!ManualFeatureConstants.ENABLE_REPLACE_STATION) {
    return null;
  }

  // Opening Dialogs
  const handleReplaceStationClicked = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.WEATHER_STATION_SETTINGS_REPLACE);
    dispatch(setShowReplaceDialog(true));
  }, []);

  const handleSuspendStationClicked = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.WEATHER_STATION_SETTINGS_SUSPEND);
    dispatch(setShowSuspendDialog(true));
  }, []);

  let suspendButton = ManualFeatureConstants.ENABLE_SUSPEND_STATION && (
    <MenuItem button={true} onClick={handleSuspendStationClicked}>
      <ListItemIcon>
        <DeleteForeverRounded color={"secondary"}/>
      </ListItemIcon>
      <Box color={"secondary.main"}>
        {LangFile.ReplaceStationMenuItemContainer.suspendStation}
      </Box>
    </MenuItem>
  );

  return (
    <Fragment>
      <MenuItem button={true} onClick={handleReplaceStationClicked}>
        <ListItemIcon>
          <SyncRounded/>
        </ListItemIcon>
        {LangFile.ReplaceStationMenuItemContainer.replaceStation}
      </MenuItem>
      {suspendButton}
    </Fragment>
  );
};

export default memo(
  connect(mapStateToProps)(
    ReplaceStationMenuItemContainer
  )
);