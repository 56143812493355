import React, {
  Fragment,
  memo,
  useCallback,
  useState
} from 'react';
import PropTypes from "prop-types";

import style from 'js/components/Prescription/DraggableIntervals/VerticalHandle/VerticalHandle.module.less';
import NumberUtils from "js/helpers/NumberUtils";
import {usePrescriptionJob} from "js/components/Prescription/PrescriptionJobContext";
import useEvent from "js/hooks/useEvent";
import {Box} from "@material-ui/core";
import DragHandle from "js/components/Prescription/DragHandle/DragHandle";
import {useHookRef} from "../../../../hooks/useHookRef";

const VerticalHandle = ({index, wrapperRect, interval, onDragInterval}) => {

  let {prescriptionJob} = usePrescriptionJob();
  const {intervals} = prescriptionJob;

  const [prevIntervals, setPrevIntervals] = useState(prescriptionJob.intervals);
  const prevIntervalsRef = useHookRef(prevIntervals);

  const [mouseDown, setMouseDown] = useState(false);

  const onStartDrag = useCallback(() => {
    setPrevIntervals(prescriptionJob.intervals);
    setMouseDown(true);
  }, [prescriptionJob.intervals]);

  const onMousemove = useCallback((event) => {
    if (!wrapperRect || !mouseDown || !onDragInterval) {
      return;
    }

    let pIntervals = prevIntervalsRef.current;

    let min = pIntervals[0].min;
    let max = pIntervals[pIntervals.length - 1].max;
    let span = max - min;
    let minWidth = span / 20; // 5% min width

    let wrapperLeft = wrapperRect.left;
    let wrapperWidth = wrapperRect.width;

    let interval = pIntervals[index];
    let nextInterval = pIntervals[index + 1];

    // map the position to the entire interval range
    let value = NumberUtils.map(event.clientX, wrapperLeft, wrapperLeft + wrapperWidth, min, max);

    // limit the value to avoid overlaps with adjacent intervals
    if (value > interval.min + minWidth && value < nextInterval.max - minWidth) {
      onDragInterval(index, value);
    }

  }, [mouseDown, onDragInterval, wrapperRect]);

  useEvent("mousemove", onMousemove);

  useEvent("mouseup", useCallback(() => {
    setMouseDown(false);
    setPrevIntervals(null);
  }));

  // current position
  let intervalsMin = intervals[0].min;
  let intervalsMax = intervals[intervals.length - 1].max;
  let left = NumberUtils.map(interval.max, intervalsMin, intervalsMax, 0, 100);

  return (
    <Fragment>
      <Box left={`${left}%`} className={style.Line}/>

      <DragHandle
        movementDirection={"horizontal"}
        movementPercent={`${left}%`}
        onStartDrag={onStartDrag}/>

    </Fragment>
  );
};

VerticalHandle.propTypes = {
  onDragInterval: PropTypes.func,
  index: PropTypes.number,
  interval: PropTypes.object,
  wrapperRect: PropTypes.any,
};

export default memo(VerticalHandle);
