import * as d3 from "d3";
import React, {
  memo,
  useLayoutEffect
} from 'react';

import {withStyles} from "@material-ui/core/styles";
import {useGraph} from "js/context/GraphContext";

const styles = (theme) => ({
  gridLines: {
    '& line': {
      stroke: 'rgba(204, 214, 235, 0.5)',
    },
    '& .domain': {
      stroke: 'none',
    }
  }
});

const BackdropLines = ({classes}) => {
  const {width, svg, yLeftMap} = useGraph();

  useLayoutEffect(() => {
    if (yLeftMap) {
      let makeGridlines = () => {
        return d3.axisLeft(yLeftMap)
                 .ticks(5)
                 .tickSize(-width)
                 .tickFormat("");
      };

      svg
        .select(`.${classes.gridLines}`)
        .call(makeGridlines());
    }

  }, [classes, svg, yLeftMap, width]);

  return (
    <g className={classes.gridLines}/>
  );
};

BackdropLines.propTypes = {};

export default memo(withStyles(styles)(BackdropLines));