"use strict";

export default {
  HELP_ENABLED: false,
  ALLOW_DELETE_FIELD: true,
  ALLOW_MAGIC_WAND: true,
  ENABLE_REPLACE_STATION: true,
  ENABLE_SUSPEND_STATION: false,
  FEATURE_NETWORK_ACC_RAIN: true,
  FEATURE_NETWORK_WIND: true,
  FEATURE_NETWORK_AIR_TEMP_24H: true,
  FEATURE_NETWORK_SOIL_TEMP_24H: true,
  FEATURE_WEATHER_HISTORY: false,
  DA_SOILOPTIX_SURVEYS: true,
  LEGACY_SOIL_SAMPLES: false,
  ALLOW_GRAPH_X_AXIS_CLICK: false,
  MARQUEE_SELECT: true,
  ADD_FIELDS_DRAW: true,
  ADD_FIELDS_SHAPEFILE: true,
  SINGLE_FIELD_ANALYSIS: true,
  PIN_LAYER_DRAWERS: true,
  BULK_WEATHER_DOWNLOAD: true,
  OVERRIDE_CLAIMED_SINCE: false,
  PRESCRIPTION_BUFFER_ZONE: true,
  PRESCRIPTION_MINIATURES: true,
  DRAW_IMAGES_MANUALLY: false,
  KORTFORSYNINGEN_HEIGHTMAP: false,
  NOTE_COLLISION_LIST: true,
  ENABLE_WEATHER_MAKEUP: true,
  ENABLED_FETCH_SURVEY_STATISTICS: false,
};
