// @flow

import React, {memo, useCallback, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {useLangFile} from "js/context/LanguageContext";
import Styles from './FieldIntegrationMappingSelector.module.less';
import Typography from "@material-ui/core/Typography";
import {ArrowDropDown, ArrowDropUp} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import {
  ClickAwayListener,
  List,
  Paper,
  Popper,
  Button
} from "@material-ui/core";
import {voidFunc} from '../../constants/PropTypeUtils';
import moment from 'moment';
import FieldIntegrationBrokenLinksSelectorListItem from './FieldIntegrationBrokenLinksSelectorListItem';
import {MAPPING_ACTION} from './FieldIntegrationMappingContainer';

const FieldIntegrationBrokenLinksSelector = (props: FieldIntegrationBrokenLinksSelector.propTypes) => {
  const LangFile = useLangFile();
  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = useRef();
  const open = Boolean(anchorEl);
  const {workbookItems, link, onSelect} = props;

  const handleClick = useCallback(() => {
    setAnchorEl(divRef.current);
  }, [anchorEl]);

  const handleListItemClick = useCallback((workbook) => {
    onSelect(workbook);
    handleClickAway();
  }, [onSelect]);

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const selectedWorkbookItem = link && link.type !== MAPPING_ACTION.UNLINK ? workbookItems.find((item) => {
    return item.workbook.id === link.workbookId;
  }) : null;

  return (
    <>
      <Box ref={divRef} display="flex" justifyContent={'space-between'} alignItems={'center'} width={'100%'} height={'100%'} onClick={handleClick} className={Styles.root}>
        {(!selectedWorkbookItem && link.type === undefined) && (
          <Box pl={1}><Typography style={{color: 'red'}}>{LangFile.FieldIntegrationMapping.select}</Typography></Box>
        )}
        {(!selectedWorkbookItem && link.type && link.type === MAPPING_ACTION.UNLINK) && (
          <Box pl={1}><Typography style={{color: 'black'}}>{LangFile.FieldIntegrationBrokenMapping.removed}</Typography></Box>
        )}
        {(selectedWorkbookItem && link.type && link.type !== MAPPING_ACTION.UNLINK) && (
          <Box display={'flex'} flexDirection={'column'} p={1}>
            <Typography className={Styles.text} style={{fontWeight: 500}}>{selectedWorkbookItem.workbook.location.name}</Typography>
            <Typography className={Styles.text}>{moment(selectedWorkbookItem.workbook.workStart).format("DD-MM-YYYY")}</Typography>
            <Typography className={Styles.text}>{selectedWorkbookItem.workbook.cropType}</Typography>
            <Typography className={Styles.text}>{selectedWorkbookItem.workbook.location.area.toFixed(2) + ' ha'}</Typography>
          </Box>
        )}
        {open ? <ArrowDropUp/> : <ArrowDropDown/>}
      </Box>
      {open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            style={{ zIndex: 1300, minWidth: 220 }}
            open={true}
            anchorEl={anchorEl}
            placement='bottom-start'>
            <Paper>
              <Box className={Styles.PopoverContainer}>
                <Box width={'100%'} height={'30px'} display={'flex'} flexDirection={'row'}>
                  <Box display={'flex'} alignItems={'center'} width={'25%'} height={'100%'} className={Styles.header}>{LangFile.FieldIntegrationMapping.match}</Box>
                  <Box display={'flex'} alignItems={'center'} height={'100%'} style={{marginLeft: '6px'}} className={Styles.header}>Workbook</Box>
                </Box>
                <List
                  className={Styles.list}
                  dense={true}>
                  {workbookItems.map((workbookItem) => {
                    return (<FieldIntegrationBrokenLinksSelectorListItem key={workbookItem.workbook.id} workbookItem={workbookItem} isSelected={selectedWorkbookItem ? workbookItem.workbook.id === selectedWorkbookItem.workbook.id : false} onClick={handleListItemClick}/>);
                  })}
                </List>
                <Box height={'40px'} display={'flex'} justifyContent={'center'}>
                  <Button
                    onClick={() => {
                      handleListItemClick(null);
                    }}>{"Fjern link"}</Button>
                </Box>
              </Box>
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

FieldIntegrationBrokenLinksSelector.propTypes = {
  workbookItems: PropTypes.array,
  link: PropTypes.object,
  onSelect: PropTypes.func
};

FieldIntegrationBrokenLinksSelector.defaultProps = {
  workbookItems: [],
  onSelect: voidFunc
};

export default memo(FieldIntegrationBrokenLinksSelector);
