import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {Box, Typography} from '@material-ui/core';
import Styles from './FieldIntegrationMappingCell.module.less';
import {Forward} from '@material-ui/icons';

const FieldIntegrationMappingHeader = (props: FieldIntegrationMappingHeader.propTypes) => {
  const {lhsTitle, rhsTitle} = props;

  return (
    <Box display={'flex'} flexDirection={'row'} alignContent={'space-between'}>
      <Box className={Styles.box} flex={3} height={'40px'} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
        <Typography>{lhsTitle}</Typography>
      </Box>
      <Box flex={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
        <Forward style={{width: 35, height: 35}}/>
      </Box>
      <Box className={Styles.box} flex={3} height={'40px'} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
        <Typography>{rhsTitle}</Typography>
      </Box>
    </Box>
  );
};

FieldIntegrationMappingHeader.propTypes = {
  lhsTitle: PropTypes.string,
  rhsTitle: PropTypes.string
};

export default memo(FieldIntegrationMappingHeader);
