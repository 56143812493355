import React, {
  memo,
} from 'react';
import {Box} from "@material-ui/core";

const getStyle = () => ({
  position: "absolute",
  height: "25px",
  lineHeight: "25px",
  zIndex: "300",
  color: "white",
  textShadow: "black 0 1px 8px",
  whiteSpace: "nowrap",
  display: "inline-flex",
  flexDirection: "row",
  alignItems: "center"
});

const IntervalLabel = ({labelText, top, left, children}) => {
  return (
    <Box top={top} left={left} style={getStyle()}>
      <Box position={"relative"} width={"100%"} height={"100%"}>
        {children} {labelText}
      </Box>
    </Box>
  );
};

export default memo(IntervalLabel);
