import React, {
  memo,
  useCallback, useEffect, useState
} from 'react';

import PropTypes from 'prop-types';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Styles from './PrescriptionArchiveSelector.module.less';
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SwapVertIcon from '@material-ui/icons/SwapVert';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Popover
} from "@material-ui/core";

import {voidFunc} from "../../constants/PropTypeUtils";
import {useLangFile} from "../../context/LanguageContext";

const PrescriptionArchiveSelector = (props: PrescriptionArchiveSelector.propTypes) => {

  const LangFile = useLangFile();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selections, setSelections] = useState(props.initialSelections);
  const [order, setOrder] = useState("desc");

  const disabled = props.items.length === 0;

  const toggleSelection = useCallback((selectedItem) => (_, value) => {
    setSelections((current) => {
      let result;
      if (!value) {
        result = current.filter((item) => item.item !== selectedItem.item);
      }
      else {
        result = [...current, selectedItem];
      }
      props.onChange(result);
      return result;
    });
  }, [props.onChange]);

  const toggleAll = useCallback((_) => {
    setSelections((current) => {
      let result;
      if (current.length > 1) {
        result = [props.items[0]];
      }
      else {
        result = props.items;
      }
      props.onChange(result);
      return result;
    });
  }, [props.onChange, props.items]);

  useEffect(() => {
    setSelections(props.initialSelections);
  }, [props.initialSelections]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrderByClick = () => {
    let newOrder = order;
    if (props.orderBy === props.selectedOrderBy) {
      newOrder = order === "desc" ? "asc" : "desc";
      setOrder(newOrder);
    }
    else {
      props.onOrderByChange(props.orderBy);
    }
    props.onOrderChange(newOrder);
  };

  const color = props.orderBy === props.selectedOrderBy ? '#2962ff' : 'black';
  return (
    <Box className={Styles.root}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}>
        <Typography className={Styles.title}>{props.title}</Typography>
        <IconButton size={"small"} onClick={handleOrderByClick}><SwapVertIcon style={{transform: 'scale(0.8,0.8)', color: color}}/></IconButton>
      </Box>
      <FormControl style={{display: `flex`}}>
        <Select
          variant={'outlined'}
          value={selections}
          open={false}
          multiple={true}
          className={Styles.selector}
          classes={{
            select: clsx({[Styles.select]: true, [Styles["black"]]: true}),
            icon: clsx({[Styles["black"]]: true, [Styles.disabled]: disabled}),
            disabled: Styles.disabled,
          }}
          disabled={false}
          onClick={handleClick}
          renderValue={(selected) => selected.map((item) => (
            item.title
          )).join(", ")}>
        </Select>
      </FormControl>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Box width={200} maxHeight={"50vh"}>
          <FormControlLabel
            className={Styles.formLabel}
            label={LangFile.PrescriptionArchiveSelector.all}
            control={
              <Checkbox
                disableRipple
                classes={{root: Styles.checkbox}}
                value={true}
                color={"default"}
                disabled={props.items.length <= 1}
                checked={selections.length === props.items.length}
                indeterminate={selections.length > 0 && selections.length !== props.items.length}
                onChange={toggleAll}/>
            }/>
          <Box ml={2} flexDirection={"column"} display={"flex"} width={"100%"}>
            {props.items.map((item) => {
              let checked = selections.length > 0 && selections.findIndex((i) => i.item === item.item) > -1;
              return (
                <FormControlLabel
                  className={Styles.formLabel}
                  control={<Checkbox
                    disableRipple
                    classes={{root: Styles.checkbox}}
                    value={item}
                    color={"default"}
                    disabled={selections.length <= 1 && checked}
                    checked={checked}
                    onChange={toggleSelection(item)}/>}
                  label={item.title}
                  key={item.title}/>
              );
            })}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

PrescriptionArchiveSelector.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array,
  initialSelections: PropTypes.array,
  orderBy: PropTypes.string,
  selectedOrderBy: PropTypes.string,
  onChange: PropTypes.func,
  onOrderByChange: PropTypes.func,
  onOrderChange: PropTypes.func,
};

PrescriptionArchiveSelector.defaultProps = {
  items: [],
  initialSelections: [],
  onChange: voidFunc,
  onOrderByChange: voidFunc,
  onOrderChange: voidFunc
};

export default memo(PrescriptionArchiveSelector);


export class PrescriptionArchiveSelectorItem {
  item: any;
  title: string;

  constructor(item, title) {
    this.item = item;
    this.title = title;
  }
}