import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";

import SoilSampleHeatmap from "js/components/SoilSamples/SoilSampleHeatmap";

const styles = (theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
});

class SoilSampleVisualizer extends Component {

  renderHeatmap = (sample, features) => {
    if (!features || features.length === 0 || !sample) {
      return null;
    }

    return (
      <SoilSampleHeatmap
        key={`sample-${sample.id}-heatmap`}
        map={this.props.map}
        features={features}
        zoomOnClick={true}
        source={sample.source}
        property={this.props.selectedProperty}/>
    );

  };

  render() {
    let {shownSampleIds, samples, features} = this.props;

    if (!shownSampleIds || shownSampleIds.length === 0 || !samples) {
      return null;
    }

    return (
      <Fragment>
        {shownSampleIds.map((sampleId) => samples.filter((sample) => sample && sample.id === sampleId)[0]).filter(Boolean).map((sample) => (
          this.renderHeatmap(sample, features[sample.id])
        ))}
      </Fragment>
    );
  }
}

export default withStyles(styles)(SoilSampleVisualizer);


SoilSampleVisualizer.propTypes = {
  shownSampleIds: PropTypes.array,
  samples: PropTypes.array,
  selectedProperty: PropTypes.string,
  map: PropTypes.object,
};