import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Styles from './SurveyStatisticsBarGroup.module.less';
import StyleConstants from "../../../StyleConstants";

const positionBreakpoint = 38;

const SurveyStatisticsBarGroup = ({ barHeight, entry, width }) => {
  const barColour = StyleConstants.palette.primary.main;

  const widthScale = (d) => {
    if (width === null || width === undefined) {
      return d*5;
    }
    return d * (width/100);
  };

  const scaledWidth = widthScale(entry.percentage);
  return (
    <Box className={Styles.group} height={barHeight} display={'flex'} flexDirection={'row'} alignItems={'center'} mt={1}>
      <Box className={Styles.name}>{entry.item}</Box>
      {(scaledWidth >= positionBreakpoint) && (
        <Box className={Styles.bar} display={'flex'} width={scaledWidth} bgcolor={barColour} alignItems={'center'} justifyContent={'end'}>
          <p className={Styles.inside}>{entry.percentage}%</p>
        </Box>
      )}
      {(scaledWidth < positionBreakpoint) && (
        <Box className={Styles.bar} display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Box className={Styles.bar} width={scaledWidth} bgcolor={barColour}/>
          <p className={Styles.outside}>{entry.percentage}%</p>
        </Box>
      )}
    </Box>
  );
};

SurveyStatisticsBarGroup.propTypes = {
  width: PropTypes.number,
  barHeight: PropTypes.number,
  entry: PropTypes.object,
};

export default memo(
  SurveyStatisticsBarGroup
);