import React, {useContext} from "react";
import {WeatherSensor, WeatherSensorUnit} from "../../constants/WeatherConstants";
import {KEYS} from "../../reducers/AppSettingsReducer";
import {
  RAIN_CONSTANTS,
  TEMPERATURE_CONSTANTS,
  WIND_CONSTANTS
} from "../../constants/MeasureConstants";
import NetworkSensor from "../../model/network/NetworkSensor";

const AppSettingsContext = React.createContext({});

export const AppSettingsProvider = AppSettingsContext.Provider;
export const AppSettingsConsumer = AppSettingsContext.Consumer;

export function useAppSettings() {
  return useContext(AppSettingsContext);
}

export function useMeasureSettings() {
  return useContext(AppSettingsContext).measures;
}

export function useWeatherSensorPreferences(weatherSensor) {
  const appSettings = useAppSettings();
  const measureSettings = appSettings.measures;

  return getWeatherSensorConstants(measureSettings, weatherSensor);
}

export function getWeatherSensorConstants(measureSettings, weatherSensor) {
  if (!weatherSensor) {
    return null;
  }

  let key;
  switch (weatherSensor) {
    case WeatherSensor.WIND:
    case WeatherSensor.WIND_MAX: {
      key = measureSettings[KEYS.MEASURE_UNIT_WIND];
      return WIND_CONSTANTS[key];
    }
    case WeatherSensor.AIR_TEMP:
    case WeatherSensor.SOIL_TEMP:
    case WeatherSensor.COMBINED_TEMP: {
      key = measureSettings[KEYS.MEASURE_UNIT_TEMPERATURE];
      return TEMPERATURE_CONSTANTS[key];
    }
    case WeatherSensor.RAIN:
    case WeatherSensor.RAIN_HOURLY:
    case WeatherSensor.RAIN_DAILY:
    case WeatherSensor.RAIN_WEEKLY: {
      key = measureSettings[KEYS.MEASURE_UNIT_RAIN];
      return RAIN_CONSTANTS[measureSettings[KEYS.MEASURE_UNIT_RAIN]];
    }
    default: return null;
  }
}

export function getWeatherSensorUnit(measureSettings, weatherSensor, exportSpecificUnit) {
  if (!measureSettings) {
    return WeatherSensorUnit[weatherSensor];
  }

  switch (weatherSensor) {
    case WeatherSensor.WIND:
    case WeatherSensor.WIND_MAX: {
      let key = measureSettings[KEYS.MEASURE_UNIT_WIND];
      let value = WIND_CONSTANTS[key];
      if (!value || !value.unit) {
        break;
      }
      if (exportSpecificUnit) {
        return value.exportSpecificUnit || value.unit;
      }
      return value.unit;
    }
    case WeatherSensor.AIR_TEMP:
    case WeatherSensor.SOIL_TEMP:
    case WeatherSensor.COMBINED_TEMP: {
      let key = measureSettings[KEYS.MEASURE_UNIT_TEMPERATURE];
      let value = TEMPERATURE_CONSTANTS[key];
      if (!value || !value.unit) {
        break;
      }
      if (exportSpecificUnit) {
        return value.exportSpecificUnit || value.unit;
      }
      return value.unit;
    }
    case WeatherSensor.RAIN:
    case WeatherSensor.RAIN_HOURLY:
    case WeatherSensor.RAIN_DAILY:
    case WeatherSensor.RAIN_WEEKLY: {
      let key = measureSettings[KEYS.MEASURE_UNIT_RAIN];
      let value = RAIN_CONSTANTS[key];
      if (!value || !value.unit) {
        break;
      }
      if (exportSpecificUnit) {
        return value.exportSpecificUnit || value.unit;
      }
      return value.unit;
    }
    case WeatherSensor.GDD: {
      if (exportSpecificUnit) {
        return "GDD > 5 Celcius";
      }
      return WeatherSensorUnit[WeatherSensor.GDD];
    }
    default:
      return WeatherSensorUnit[weatherSensor];
  }

  return WeatherSensorUnit[weatherSensor];
}

export function getWeatherNetworkSensorUnit(measureSettings, networkSensor): {key: string, unit: string, exportSpecificUnit: string, mapTo: function} {
  switch (networkSensor) {
    case NetworkSensor.WIND_AVG: {
      let key = measureSettings[KEYS.MEASURE_UNIT_WIND];
      return WIND_CONSTANTS[key].unit;
    }
    case NetworkSensor.RAIN_24H:
    case NetworkSensor.RAIN_ACC: {
      let key = measureSettings[KEYS.MEASURE_UNIT_RAIN];
      return RAIN_CONSTANTS[key].unit;
    }
    case NetworkSensor.SOIL_TEMP_24H:
    case NetworkSensor.AIR_TEMP_24H: {
      let key = measureSettings[KEYS.MEASURE_UNIT_TEMPERATURE];
      return TEMPERATURE_CONSTANTS[key].unit;
    }
    default:
      return '';
  }
}