
export const SECONDS_MILLIS = 1000;
export const MINUTE_MILLIS = 60000;
export const HOUR_MILLIS = 3600000;
export const DAY_MILLIS = 86400000;
export const WEEK_MILLIS = 604800000;

export const getDurationMillis = (string) => {
  if (!string) {
    return undefined;
  }

  let match = string.match(/^([1-9][0-9]*)([smhdw])$/);

  if (!match) {
    return undefined;
  }

  let duration = match[1];
  let period = match[2];

  let multiplier;

  switch (period) {
    case 's': {
      multiplier = SECONDS_MILLIS;
      break;
    }
    case 'm': {
      multiplier = MINUTE_MILLIS;
      break;
    }
    case 'h': {
      multiplier = HOUR_MILLIS;
      break;
    }
    case 'd': {
      multiplier = DAY_MILLIS;
      break;
    }
    case 'w': {
      multiplier = WEEK_MILLIS;
      break;
    }
    default: {
      multiplier = undefined;
    }
  }

  if (!multiplier) {
    return undefined;
  }

  return duration * multiplier;
};