import React, {Fragment, memo, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {voidFunc} from '../../constants/PropTypeUtils';
import FieldIntegrationMappingDialog from './FieldIntegrationMappingDialog';
import WebAPIUtils from '../../WebAPIUtils';
import {useSelectedSeason} from '../../context/SeasonContext';
import {Action, useActionSnackbarContext} from '../ActionSnackbarHandler/ActionSnackbarHandler';
import FieldIntegrationBrokenLinksDialog from './FieldIntegrationBrokenLinksDialog';
import {useFarm} from '../../context/AccountContext';

export const MAPPING_ACTION = {
  LINK: "LINK",
  UNLINK: "UNLINK",
  CHANGE: "CHANGE"
};

const WORKBOOKS_PER_PAGE = 20;

const FieldIntegrationMappingContainer = (props: FieldIntegrationMappingContainer.propTypes) => {
  const {open, onBack, onImported} = props;
  if (!open) {
    return null;
  }

  const {addAction} = useActionSnackbarContext();

  const [fields, setFields] = useState([]);
  const [workBookData, setWorkBookData] = useState({});
  const [fetching, setFetching] = useState(true);
  const [links, setLinks] = useState([]);
  const [error, setError] = useState(null);
  const selectedSeason = useSelectedSeason();
  const farm = useFarm();
  const [brokenLinks, setBrokenLinks] = useState([]);
  const [tempBrokenLinks, setTempBrokenLinks] = useState([]);

  const fetchWorkbookData = async (farmId, season, page) => {
    setError(null);
    return await WebAPIUtils.getClaasWorkBooks(farmId, season.startDate, season.endDate, page, WORKBOOKS_PER_PAGE).then((response) => {
      return response;
    }).catch((e) => {
      setError(e);
    });
  };

  const fetchWorkbooks = async (farmId, season) => {
    let workbooks = [];
    let page = 0;
    do {
      const data = await fetchWorkbookData(farmId, season, page);
      page = data['nextPage'];
      workbooks.push(...data['workbooks']);
    } while (page);
    return {
      'workbooks': workbooks
    };
  };

  const fetchCLAASData = async (farmId, season) => {
    return await Promise.all([
      fetchWorkbooks(farmId, season),
      WebAPIUtils.getClaasLinks(farmId, season.id)
    ]).then((response) => {
      return {
        ...response[0],
        ...response[1],
      };
    });
  };

  useEffect(() => {
    // Fetch workbooks & links
    setFetching(true);
    fetchCLAASData(farm.farmId, selectedSeason).then((response) => {
      setWorkBookData(response);
      if (response) {
        setLinks(response['links']);
      }
      setFetching(false);
    }).catch((e) => {
      setError(e);
      setFetching(false);
    });
  }, [farm, selectedSeason]);

  useEffect(() => {
    const fetchFields = async () => {
      return await WebAPIUtils.getSeasonFields(selectedSeason.id);
    };

    fetchFields().then((response) => {
      if (response['fields'] !== undefined) {
        setFields(response['fields']);
      }
    });
  },[selectedSeason]);

  useEffect(() => {
    if (workBookData['links'] && workBookData['workbooks']) {
      const links = workBookData['links'];
      const workbooks = workBookData['workbooks'];
      let brokenLinks = [];
      links.forEach((link) => {
        let linked = workbooks.find((workbook) => link.workbookId === workbook.id);
        if (linked === undefined) {
          brokenLinks.push(link);
        }
      });
      setBrokenLinks(brokenLinks);
      setTempBrokenLinks(brokenLinks);
    }
  }, [workBookData]);

  const handleImport = useCallback(() => {
    let actions = [];
    links.forEach((link) => {
      if (link.type) {
        if (link.type === MAPPING_ACTION.CHANGE) {
          const existing = workBookData['links'].find((field) => field.workbookId === link.workbookId);
          if (existing) {
            actions.push({type: MAPPING_ACTION.UNLINK, fieldId: existing.fieldId, workbookId: link.workbookId});
          }
          actions.push({...link, type: MAPPING_ACTION.LINK});
        }
        else {
          actions.push(link);
        }
      }
    });

    const body = {
      seasonId: selectedSeason.id,
      actions: actions
    };

    WebAPIUtils.createClaasLinks(farm.farmId, body).then(() => {
      onImported();
    }).catch(() => {
      addAction(new Action("claas-links-error", `${'Error'}`,"success", "success", "filled"));
    });
  }, [farm, links]);

  const handleSelectLink = useCallback((workbookId, field) => {
    if (workBookData['links']) {
      if (workbookId) {
        if (field) {
          const link = links.find((link) => link.workbookId === workbookId);
          if (link) {
            const existing = workBookData['links'].find((link) => link.workbookId === workbookId);
            if (existing && existing.fieldId === field.fieldId) {
              setLinks((current) =>
                current.map((link) => {
                  if (link.workbookId === workbookId) {
                    return {
                      workbookId: workbookId,
                      fieldId: field.fieldId
                    };
                  }
                  return link;
                }),
              );
            }
            else {
              setLinks((current) =>
                current.map((link) => {
                  if (link.workbookId === workbookId) {
                    return {
                      type: MAPPING_ACTION.CHANGE,
                      workbookId: workbookId,
                      fieldId: field.fieldId
                    };
                  }
                  return link;
                }),
              );
            }
          }
          else {
            setLinks((current) => [...current, {
              type: MAPPING_ACTION.LINK,
              workbookId: workbookId,
              fieldId: field.fieldId
            }]);
          }
        }
        else {
          const existing = workBookData['links'].find((link) => link.workbookId === workbookId);
          if (existing) {
            setLinks((current) =>
              current.map((link) => {
                if (link.workbookId === workbookId) {
                  return {
                    type: MAPPING_ACTION.UNLINK,
                    workbookId: workbookId,
                    fieldId: existing.fieldId
                  };
                }
                return link;
              }),
            );
          }
          else {
            setLinks((current) =>
              current.filter((link) => {
                return link.workbookId !== workbookId;
              }),
            );
          }
        }
      }
      else {
        setLinks(workBookData['links']);
      }
    }
  }, [workBookData, links]);

  const handleUnlink = useCallback((workbookId) => {
    handleSelectLink(workbookId, null);
  }, [handleSelectLink]);

  const handleSelectBrokenLink = useCallback((link, workbook) => {
    if (workbook !== null) {
      setTempBrokenLinks((current) =>
        current.map((l) => {
          if (l.workbookId === link.workbookId) {
            return {
              type: MAPPING_ACTION.LINK,
              workbookId: workbook.id,
              fieldId: link.fieldId
            };
          }
          return link;
        }),
      );
    }
    else {
      setTempBrokenLinks((current) =>
        current.map((l) => {
          if (l.workbookId === link.workbookId) {
            return {
              type: MAPPING_ACTION.UNLINK,
              workbookId: l.workbookId,
              fieldId: l.fieldId
            };
          }
          return link;
        }),
      );
    }
  }, [tempBrokenLinks]);

  const handleBrokenLinks = useCallback(() => {
    let newLinks = links.map((link) => {
      const existing = brokenLinks.find((brokenLink) => link.workbookId === brokenLink.workbookId);
      if (existing) {
        return {
          type: MAPPING_ACTION.UNLINK,
          workbookId: link.workbookId,
          fieldId: link.fieldId
        };
      }
      return link;
    });

    newLinks.push(...tempBrokenLinks.map((fixedLink) => {
      const existing = newLinks.find((link) => link.workbookId === fixedLink.workbookId);
      if (!existing) {
        return fixedLink;
      }
    }).filter((link) => link));
    setLinks(newLinks);
    setTempBrokenLinks([]);
    setBrokenLinks([]);
  }, [brokenLinks, tempBrokenLinks, links]);

  const handleRemoveAllBrokenLinks = useCallback(() => {
    setTempBrokenLinks((current) =>
      current.map((link) => {
        return {
          type: MAPPING_ACTION.UNLINK,
          workbookId: link.workbookId,
          fieldId: link.fieldId
        };
      }),
    );
  }, [tempBrokenLinks]);

  return (
    <Fragment>
      <FieldIntegrationMappingDialog
        open={open}
        error={error}
        onBack={onBack}
        onImport={handleImport}
        onSelectLink={handleSelectLink}
        onUnlink={handleUnlink}
        workbooks={workBookData['workbooks'] ? workBookData['workbooks'] : []}
        links={links}
        fields={fields}
        fetching={fetching}/>
      <FieldIntegrationBrokenLinksDialog
        open={tempBrokenLinks.length > 0 && fields.length > 0}
        onBack={onBack}
        onApply={handleBrokenLinks}
        onRemoveAllLink={handleRemoveAllBrokenLinks}
        onSelectLink={handleSelectBrokenLink}
        links={tempBrokenLinks}
        fields={fields}
        workbooks={workBookData['workbooks'] ? workBookData['workbooks'] : []}/>
    </Fragment>
  );
};

FieldIntegrationMappingContainer.propTypes = {
  open: PropTypes.bool,
  onBack: PropTypes.func,
  onImported: PropTypes.func,
};

FieldIntegrationMappingContainer.defaultProps = {
  onBack: voidFunc,
  onImported: voidFunc
};

export default memo(FieldIntegrationMappingContainer);
