import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import useBreakpoint from "js/hooks/useBreakpoint";
import TimeWindowSliderTick from "js/components/WeatherNetwork/TimeWindowSlider/TimeWindowSliderTick";
import MouseInterceptor from "js/components/WeatherNetwork/TimeWindowSlider/MouseInterceptor";
import {grey} from "@material-ui/core/colors";
import TimeWindowSliderThumb from "js/components/WeatherNetwork/TimeWindowSlider/TimeWindowSliderThumb";

const styles = (theme) => ({
  tickWrapper: {
    position: 'relative',
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    userSelect: 'none',
    backgroundColor: grey[300]
  },
  thumb: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const isBetween = (value, start, end) => {
  return start <= value && value <= end;
};

const TimeWindowSliderTicks = ({classes, dates, ticksPerHour, temporalStartIndex, temporalEndIndex, selectEndIndex, minIndex, maxIndex}) => {

  let tickRefs = useRef({});
  let wrapperRef = useRef(null);

  let [hoverIndex, setHoverIndex] = useState(null);
  let [mouseDown, setMouseDown] = useState(null);
  let [showThumb, setShowThumb] = useState(false);

  let breakpoint = useBreakpoint();

  let smallScreen = breakpoint === 'sm' || breakpoint === 'xs';
  let labelEveryHour = 1;

  if (breakpoint === 'lg') {
    labelEveryHour = 2;
  }
  if (breakpoint === 'md') {
    labelEveryHour = 4;
  }
  if (breakpoint === 'sm' || breakpoint === 'xs') {
    labelEveryHour = 4;
  }

  const onHover = useCallback((index) => {
    if (!mouseDown) {
      setHoverIndex(Math.max(minIndex - ticksPerHour / 2, Math.min(maxIndex - ticksPerHour / 2, index)));
    }
    else {
      if (hoverIndex !== null) {
        setHoverIndex(null);
      }
    }
  }, [dates, minIndex, maxIndex]);


  const onSetRef = useCallback((r, index) => {
    tickRefs.current[`${index}`] = r;
  }, [tickRefs.current]);

  const onMouseDown = useCallback((e) => {
    e.persist();
    setMouseDown(e);
  }, []);

  const onMouseUp = useCallback((e) => {
    setMouseDown(null);
  }, []);

  const onMouseLeave = useCallback((e) => {
    setHoverIndex(null);
  }, []);

  const onInterceptMouseUp = useCallback((e) => {
    setMouseDown(null);
  }, []);

  const onInterceptMouseMove = useCallback((e) => {
    setMouseDown(e);

    if (hoverIndex) {
      setHoverIndex(null);
    }
  }, [hoverIndex]);


  useEffect(() => {
    if (mouseDown) {
      let x = mouseDown.clientX;

      let indices = Object.keys(tickRefs.current);
      let offset = ticksPerHour / 2;

      indices
        .map((i) => {
          return {
            index: i,
            ref: tickRefs.current[`${i}`]
          };
        })
        .filter(Boolean)
        .forEach((tick) => {
          let idx = parseInt(tick.index);
          let rect = tick.ref.getBoundingClientRect();
          let left = rect.left;
          let right = rect.right;

          if (x >= left && x <= right) {
            if (idx !== temporalEndIndex) {
              let newIndex = Math.min(idx + offset, maxIndex);
              selectEndIndex(newIndex);
            }
          }
          else if (x <= 0) {
            if (temporalEndIndex !== minIndex) {
              selectEndIndex(minIndex);
            }
          }
          else if (x >= window.innerWidth) {
            if (temporalEndIndex !== maxIndex) {
              selectEndIndex(maxIndex);
            }
          }
        });
    }
  }, [tickRefs.current, temporalEndIndex, mouseDown, minIndex, maxIndex]);

  useLayoutEffect(() => {
    let timeout;

    if (wrapperRef.current) {
      timeout = setTimeout(() => {
        setShowThumb(true);
      }, 200);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [wrapperRef.current]);

  if (!dates) {
    return null;
  }

  let length = dates ? dates.length : 0;

  function createTick(i, d) {
    let hovered = hoverIndex !== null && isBetween(i, hoverIndex - ticksPerHour / 2 + 1, hoverIndex + ticksPerHour / 2);

    return (
      <TimeWindowSliderTick
        key={`tick-${i}`}
        date={d}
        onSetRef={onSetRef}
        max={length}
        index={i}
        hovered={hovered}
        onHover={onHover}
        labelEveryHour={labelEveryHour}
        smallScreen={smallScreen}/>
    );
  }

  return (
    <div
      ref={wrapperRef}
      className={`${classes.tickWrapper}`}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}>

      <MouseInterceptor
        intercept={Boolean(mouseDown)}
        onMouseUp={onInterceptMouseUp}
        onMouseMove={onInterceptMouseMove}/>

      {showThumb && (
        <TimeWindowSliderThumb
          wrapper={wrapperRef.current}
          startDate={dates[temporalStartIndex]}
          endDate={dates[temporalEndIndex]}
          startTick={tickRefs.current[`${temporalStartIndex}`]}
          endTick={tickRefs.current[`${temporalEndIndex}`]}/>
      )}

      {dates.map((date, index) => createTick(index, date))}
    </div>
  );
};

TimeWindowSliderTicks.propTypes = {
  dates: PropTypes.array,
  selectEndIndex: PropTypes.func,
  ticksPerHour: PropTypes.number,
  stepWidth: PropTypes.number,
  temporalEndIndex: PropTypes.number,
  minIndex: PropTypes.number,
  maxIndex: PropTypes.number,
};

TimeWindowSliderTicks.defaultProps = {
  dates: [],
  ticksPerHour: 6,
  temporalEndIndex: 0,
};

export default memo(withStyles(styles)(TimeWindowSliderTicks));