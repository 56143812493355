import {useGraph} from '../../../context/GraphContext';
import React, {memo} from 'react';
import Styles from "./GraphVerticalLimitLine.module.less";
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';


const GraphVerticalLimitLine = ({dataset}) => {
  const {svg, xMap, height} = useGraph();

  return (
    <g>
      {svg && xMap && height &&
        <g>
          <foreignObject x={0} y={0} width="100%" height="100%" transform={`translate(${xMap(dataset.x)},${1})`} className={Styles.ForeignObject}>
            <Box display={"inline-block"} position={"relative"} className={Styles.Root}>
              <Box display={'flex'} alignItems={'center'}>
                <img
                  alt={""}
                  src={dataset.icon}
                  className={Styles.TileImage}/>
                <p className={Styles.Title}>{dataset.title}</p>
              </Box>
            </Box>
          </foreignObject>
          <line
            style={{
              stroke: dataset.color,
              opacity: 0.8,
              pointerEvents: 'none',
            }}
            strokeDasharray="4, 5"
            transform={"translate(" + xMap(dataset.x) + ",0)"}
            y1={30}
            y2={height}/>
        </g>
      }
    </g>
  );
};

GraphVerticalLimitLine.propTypes = {
  /**
   * Select which data entry to display. Simply write the property name of the data entry to display.
   *
   * If this is not provided, the graphline will select a property based on which child index this has.
   */
  datasetName: PropTypes.string,
  /** Should dots be displayed at all points on the graph */
  displayDots: PropTypes.bool,
  /**
   * Override the object of datasets provided by the GraphProvider.
   */
  overrideDatasets: PropTypes.object,
  axis: PropTypes.oneOf(["left", "right"])
};

GraphVerticalLimitLine.defaultProps = {
  axis: "left"
};

export default memo(GraphVerticalLimitLine);
