import React, {
  memo,
  useCallback,
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';
import Box from "@material-ui/core/Box";
import DataLayer from "js/components/DataLayer/DataLayer";
import FieldTextCanvas from "js/components/ManageCrops/FieldTextCanvas";
import {getFieldName} from "js/helpers/StateInterpreters";
import {
  useLangFileRef
} from "js/context/LanguageContext";
import moment from "moment";
import {
  blue,
  yellow
} from "@material-ui/core/colors";
import FeaturePolygon from "../DataLayer/FeaturePolygon";
import MapFloatPanel from "../MapView/MapFloatPanel";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {
  Clear,
  Close
} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {voidFunc} from "../../constants/PropTypeUtils";
import {
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper
} from "@material-ui/core";
import {
  CropType,
  CropTypeColor
} from "../../constants/CropConstants";
import Styles from "./ManageCrops.module.less";
import InlineDatePicker from "../FormControls/InlineDatePicker";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import GoogleMap from "js/components/MapObjects/GoogleMap/GoogleMap";
import SelectCrop from "js/components/FormControls/SelectCrop";
import FieldSeason from "js/model/FieldSeason";
import ErrorSnackbar from "js/components/Toasts/ErrorSnackbar";
import TextField from '@material-ui/core/TextField';

export const getFieldsArray = (fields) => Array.from(fields, ([key, value]) => value);

const areChangesEqual = (a: FieldSeason, b: FieldSeason) => {
  return (
    a.fieldId === b.fieldId
    && a.crop === b.crop
    && a.sowing === b.sowing
  );
};

const ManageCrops = (props: ManageCrops.propTypes) => {

  const LangFileRef = useLangFileRef();
  const LangFile = LangFileRef.current;

  const [fields, setFields] = useState(getFieldsArray(props.fields));
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(undefined);
  const [crop, setCrop] = useState(CropType._SELECT);
  const [cropVariation, setCropVariation] = useState(undefined);
  const [sowing, setSowing] = useState(null);
  const [fieldSeasons, setFieldSeasons] = useState([]);
  const [cropVariationError, setCropVariationError] = useState(undefined);

  useEffect(() => {
    if (props.fields) {
      setFields(getFieldsArray(props.fields));
    }
  }, [props.fields]);

  const onDismissError = useCallback(() => {
    setError(undefined);
  });

  const onRemoveChange = (entry) => () => {
    setFieldSeasons((current) => current.filter((season) => season.fieldId !== entry.fieldId));
  };

  const onSaveChanges = useCallback(() => {
    setSaving(true);
    setError(undefined);

    props.onSave(fieldSeasons)
         .then(() => {
           setSaved(true);
           setSaving(false);
           setFieldSeasons([]);
         })
         .catch((e) => {
           setError(e);
           setSaved(false);
           setSaving(false);
         });
  }, [fieldSeasons, props.onSave]);

  const onFieldClicked = useCallback((event) => {
    if (crop && crop !== CropType._SELECT && ((crop !== CropType.NONE && sowing) || crop === CropType.NONE)) {
      let feature = event.feature;
      let fieldId = feature.getProperty("id");
      let _sowing = crop !== CropType.NONE ? sowing : null;
      let _cropVariation = crop !== CropType.NONE ? cropVariation : null;
      let fieldSeason = new FieldSeason(fieldId, crop, _sowing, _cropVariation);

      setFieldSeasons((current) => {
        let existing = current.find((e) => e.fieldId === fieldId);

        if (existing !== undefined) {
          if (areChangesEqual(existing, fieldSeason)) {
            // remove the existing entry, and don't put in the new one
            return [...current].filter((fs: FieldSeason) => fs.fieldId !== fieldId);
          }
          else {
            // Replace the existing entry with the new one
            let index = current.findIndex((fs: FieldSeason) => fs.fieldId === fieldId);
            let result = [...current];
            result[index] = fieldSeason;

            return result;
          }
        }
        else {
          return [...current, fieldSeason];
        }
      });
    }
  }, [crop, sowing, cropVariation]);

  useEffect(() => {
    if (fieldSeasons.length > 0) {
      setSaved(false);
    }
  }, [fieldSeasons]);

  const renderFieldText = useCallback((field, zoom) => {

    let lines = [];
    let fieldSeason: FieldSeason = fieldSeasons.find((fs: FieldSeason) => fs.fieldId === field.fieldId);

    // Name
    if (zoom >= 15) {
      lines.push({
        text: getFieldName(field, LangFileRef.current),
        fontWeight: "400",
        fontSize: 14,
        textAlign: "center",
        color: "white",
      });
    }

    // Crop
    if (zoom >= 14) {
      let crop = fieldSeason ? fieldSeason.crop : field.crop;

      if (!crop) {
        crop = CropType.NONE;
      }

      lines.push({
        text: LangFileRef.current.CropType[crop],
        fontWeight: "900",
        fontSize: 14,
        textAlign: "center",
        color: "white",
      });
    }

    // Sowing Date
    if (zoom >= 15) {
      let date = fieldSeason ? fieldSeason.sowing : field.sowing;
      if (date) {
        lines.push({
          text: moment(date).format("LL"),
          fontWeight: "400",
          fontSize: 14,
          textAlign: "center",
          color: "white",
        });
      }
    }

    return lines;
  }, [fieldSeasons]);

  const onSetStyle = useCallback((feature) => {
    let fieldId = feature.getProperty("id");
    let field = fields.find((field) => field.fieldId === fieldId);

    let change = fieldSeasons.find((fieldSeason) => fieldSeason.fieldId === fieldId);
    let changed = change !== undefined;
    let fillColor = CropTypeColor[changed ? change.crop : field.crop];
    let strokeColor = changed ? blue["A700"] : yellow["A700"];
    let strokeWeight = changed ? 4 : 2;
    let fillOpacity = 0.9;

    return {
      fillColor,
      fillOpacity,
      strokeColor,
      strokeWeight
    };
  }, [fieldSeasons, fields]);

  const onCropVariationChanged = useCallback((e) => {
    if (e.target.value.length < 128) {
      setCropVariation(e.target.value);
      setCropVariationError(false);
    }
    else {
      setCropVariationError(true);
    }
  });

  const onReset = useCallback(() => {
    setSaved(false);
    setCrop(CropType._SELECT);
    setCropVariation(undefined);
    setSowing(null);
    setCropVariationError(undefined);
  }, [fieldSeasons, fields]);

  return (
    <Box
      position={"relative"}
      width={"100%"}
      height={"100%"}>
      <GoogleMap center={props.previousViewport.center} zoom={props.previousViewport.zoom}>
        {(!crop || crop === CropType._SELECT || (crop !== CropType.NONE && !sowing)) && (
          <MapFloatPanel top={0} left={0} right={0} bottom={0} zIndex={0}>
            <Box width={"100%"} height={"100%"} bgcolor={"black"} style={{opacity: 0.8}}/>
          </MapFloatPanel>
        )}

        <MapFloatPanel
          zIndex={1}
          top={0}
          left={0}
          right={0}>
          <AppBar
            color={"primary"}
            position='static'>
            <Toolbar variant='dense'>
              <IconButton
                edge='start'
                color='inherit'
                onClick={props.onClose}>
                <Close/>
              </IconButton>
              <Typography variant='h6'>
                {LangFile.ManageCrops.finish}
              </Typography>
            </Toolbar>
          </AppBar>
        </MapFloatPanel>

        <MapFloatPanel
          zIndex={10}
          top={74}
          left={24}>
          <Paper elevation={2}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              p={2}>
              <Box
                fontWeight={"bold"}
                align={"center"}
                pb={1}>
                {LangFile.ManageCrops.title}
              </Box>
              <Box>
                {LangFile.ManageCrops.step1}
              </Box>
              <Box>
                {LangFile.ManageCrops.step2}
              </Box>

              <Box pt={2}>
                <SelectCrop
                  value={crop}
                  error={!crop || crop === CropType._SELECT}
                  onChanged={setCrop}/>
              </Box>

              <Box pt={2}>
                <InlineDatePicker
                  label={LangFile.ManageCrops.sowingDate}
                  error={crop !== CropType._SELECT && crop !== CropType.NONE && !sowing}
                  value={sowing}
                  disabled={!crop || crop === CropType._SELECT || crop === CropType.NONE}
                  onChange={setSowing}/>
              </Box>

              <Box pt={2}>
                <TextField
                  fullWidth={true}
                  value={cropVariation}
                  label={LangFile.EditCropDialog.cropVariation}
                  onChange={onCropVariationChanged}
                  helperText={cropVariationError ? LangFile.EditCropDialog.errorCropVariation : ""}
                  disabled={!crop || crop === CropType._SELECT || crop === CropType.NONE}
                  error={cropVariationError}/>
              </Box>
              <Box pt={4}>
                {!saving && !saved && (
                  <Box
                    align={"center"}
                    fontWeight={"bold"}>
                    {`${LangFile.ManageCrops.changedFields} (${fieldSeasons.length})`}
                  </Box>
                )}

                {saving && (
                  <Box
                    align={"center"}
                    fontWeight={"bold"}>{LangFile.ManageCrops.saving}</Box>
                )}

                {saved && (
                  <Box
                    align={"center"}
                    fontWeight={"bold"}>{LangFile.ManageCrops.saved}</Box>
                )}

                {saving && (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={"100%"}
                    py={4}>
                    <CircularProgress
                      variant={"indeterminate"}
                      size={30}
                      color={"primary"}/>
                  </Box>
                )}

                {!saving && !saved && (
                  <List
                    dense={true}
                    className={Styles.List}>
                    <Divider/>
                    {fieldSeasons.length === 0 && (
                      <ListItem divider={true}>
                        <ListItemText
                          primary={<b>{LangFile.ManageCrops.noChanges}</b>}
                          secondary={LangFile.ManageCrops.clickMap}/>
                      </ListItem>
                    )}

                    {fieldSeasons.map((entry) => {

                      let {fieldId, sowing, crop} = entry;
                      let field = fields.find((field) => field.fieldId === fieldId);
                      let primary = getFieldName(field, LangFile);
                      let secondary = crop !== CropType.NONE ? `${LangFile.CropType[crop]}, ${moment(sowing).format("LL")}` : `${LangFile.CropType[crop]}`;
                      return (
                        <ListItem
                          key={fieldId}
                          divider={true}
                          className={Styles.ListItem}>
                          <ListItemIcon className={Styles.ListItemIcon}>
                            <Box
                              width={"12px"}
                              height={"12px"}
                              borderRadius={"50%"}
                              border={2}
                              borderColor={"grey.900"}
                              bgcolor={CropTypeColor[crop]}/>
                          </ListItemIcon>
                          <ListItemText
                            primary={<b>{primary}</b>}
                            secondary={secondary}/>
                          <ListItemSecondaryAction className={Styles.ListItemSecondaryAction}>
                            <IconButton
                              onClick={onRemoveChange(entry)}
                              value={entry}>
                              <Clear/>
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                )}

              </Box>

              {
                fieldSeasons.length !== 0 && (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    pt={1}>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      onClick={onSaveChanges}>
                      {LangFile.ManageCrops.save}
                    </Button>
                  </Box>
                )
              }

              {
                saved && (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    pt={1}>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      onClick={onReset}>
                      {LangFile.ManageCrops.ok}
                    </Button>
                  </Box>
                )
              }

            </Box>
          </Paper>
        </MapFloatPanel>

        <DataLayer
          onClick={onFieldClicked}
          setStyle={onSetStyle}>
          {fields.map((field) => {
            return (
              <FeaturePolygon
                id={field.fieldId}
                key={field.fieldId}
                coords={field.polygon.coordinates}/>
            );
          })}
        </DataLayer>

        <FieldTextCanvas
          fields={fields}
          renderField={renderFieldText}/>
      </GoogleMap>

      <ErrorSnackbar
        open={Boolean(error)}
        title={LangFile.ManageCrops.errorTitle}
        subtitle={LangFile.ManageCrops.errorSubtitle}
        errorMessage={error ? error.message : undefined}
        onDismiss={onDismissError}/>

    </Box>
  );
};

ManageCrops.propTypes = {
  fields: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  previousViewport: PropTypes.shape({
    center: PropTypes.object,
    zoom: PropTypes.number,
  })
};

ManageCrops.defaultProps = {
  onClose: voidFunc,
  onSave: voidFunc,
};

export default memo(ManageCrops);
