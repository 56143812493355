import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';

import {
  ClickAwayListener,
  Paper
} from "@material-ui/core";
import StyleConstants from "js/StyleConstants";
import {withStyles} from "@material-ui/core/styles";
import {NoSelect} from "js/StyleTemplates";
import {
  RadioButtonChecked,
  RadioButtonUnchecked
} from "@material-ui/icons";
import {voidFunc} from "js/constants/PropTypeUtils";
import {useGoogleMap} from "js/context/GoogleMapContext";

const styles = (theme) => ({
  root: {
    ...NoSelect,
    position: 'absolute',
    zIndex: 1,
    cursor: 'pointer',
    pointerEvents: 'none',
  },
  content: {
    color: 'black',
    fontWeight: 400,
    textAlign: 'left',
    fontSize: '1em',
    lineHeight: '1em',
    minWidth: 50,
    whiteSpace: 'nowrap',
    zIndex: '10 !important',
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate(24px, -50%)'
  },
  markerPin: {
    top: 0,
    left: 0,
    zIndex: 9,
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  icon: {
    height: 24,
    boxShadow: 'rgba(0, 0, 0, 0.16) 0 3px 10px, rgba(0, 0, 0, 0.23) 0 3px 10px',
    borderRadius: '50%',
    pointerEvents: 'none',
    cursor: 'pointer',
    '& path:first-child': {
      fill: 'white',
    }
  }
});

const MapPopup = ({classes, shown, children, position, style, onMouseEnter, onMouseLeave, onClick, clickable, selected, selectable, backgroundColor, backgroundColorHover, hasPin, onClickAway}) => {

  const googleMap = useGoogleMap();
  const [hovered, setHovered] = useState(false);
  const overlay = useRef(null);
  const [draw, setDraw] = useState({});

  useEffect(() => {
    let newOverlay = new google.maps.OverlayView();
    newOverlay.draw = () => {
      setDraw({draw}); // This forces a re-render of the functional component
    };
    newOverlay.remove = () => {
    };
    newOverlay.setMap(googleMap);

    overlay.current = newOverlay;

    return () => {
      overlay.current.setMap(null);
      overlay.current = null;
    };
  }, []);

  const handleOnMouseEnter = useCallback(() => {
    setHovered(true);
    onMouseEnter();
  }, []);

  const handleOnMouseLeave = useCallback(() => {
    setHovered(false);
    onMouseLeave();
  }, []);

  const handleOnClick = useCallback(() => {
    if (clickable) {
      onClick();
    }
  }, [clickable, onClick]);

  const getScreenPosition = () => {
    if (overlay.current) {
      let proj = overlay.current.getProjection();

      if (proj) {
        let pos = new google.maps.LatLng(position);
        return proj.fromLatLngToContainerPixel(pos);
      }
    }
    return null;
  };

  if (!shown) {
    return null;
  }

  let pos = getScreenPosition();

  let zIndex = 1;

  if (selected) {
    zIndex = 2;
  }

  if (hovered) {
    zIndex = 5;
  }

  let customStyle = {
    display: pos ? 'block' : 'none',
    top: pos ? pos.y : 0,
    left: pos ? pos.x : 0,
    zIndex: zIndex,
    pointerEvents: 'none',
  };

  let paperStyle = {
    backgroundColor: hovered && backgroundColorHover ? backgroundColorHover : backgroundColor,
    cursor: clickable ? 'pointer' : 'default',
    boxShadow: `8px 24px 12px rgba(0,0,0,0.4)`,
    pointerEvents: clickable ? 'all' : 'none',
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={() => selected && onClickAway()} mouseEvent={"onMouseDown"} touchEvent={"onTouchStart"}>
        <div className={classes.root} style={{...style, ...customStyle}}>
          <Paper
            elevation={1}
            className={classes.content}
            style={paperStyle}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            onClick={handleOnClick}>
            {children}
          </Paper>

          {hasPin && (
            <div
              className={classes.markerPin}
              style={{pointerEvents: selectable ? 'all' : 'none'}}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              onClick={handleOnClick}>
              {selected && (
                <RadioButtonChecked
                  className={classes.icon}/>
              )}
              {!selected && (
                <RadioButtonUnchecked
                  className={classes.icon}/>
              )}
            </div>
          )}

        </div>
      </ClickAwayListener>
    </Fragment>
  );
};

MapPopup.propTypes = {
  shown: PropTypes.bool,
  position: PropTypes.object.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClickAway: PropTypes.func,
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  hasPin: PropTypes.bool,
  backgroundColor: PropTypes.string,
  backgroundColorHover: PropTypes.string,
};

MapPopup.defaultProps = {
  clickable: false,
  hasPin: false,
  onClickAway: voidFunc,
  onMouseEnter: voidFunc,
  onMouseLeave: voidFunc,
  onClick: voidFunc,
  backgroundColor: StyleConstants.colors.white,
};

export default memo(withStyles(styles)(MapPopup));
