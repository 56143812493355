import React, {memo, useCallback} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import MapPopup from "js/components/MapObjects/MapPopup";
import {
  blue,
  grey
} from "@material-ui/core/colors";

import {Tooltip} from "@material-ui/core";
import {useLangFile} from "js/context/LanguageContext";
import RainIcon from "js/components/Icons/Weather/RainIcon";
import WeatherStationIndicatorRow from "js/components/WeatherDrawer/WeatherStationIndicatorRow";
import WeatherStationIndicator from "js/components/WeatherDrawer/WeatherStationIndicator";
import {useGpsFixLocation} from "js/context/SettingsContext";
import {
  WeatherSensorColor,
  WeatherSensorDecimals,
} from "js/constants/WeatherConstants";
import {getCurrentSensorReading, stationHasData} from "js/helpers/WeatherUtils";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../hooks/useFirebaseAnalytics";
import {getWeatherSensorUnit, useMeasureSettings} from "../../context/AppSettings/AppSettingsContext";

const styles = (theme) => ({
  label: {
    color: 'white',
    padding: theme.spacing(1) / 2,
    textAlign: 'center',
    position: 'relative',
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    color: 'black',
    '& > *': {
      display: 'flex',
      flexDirection: 'column',
      margin: [[0, theme.spacing(1)]],
    }
  },
  cell: {
    minWidth: 36,
    minHeight: 38,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  value: {
    paddingBottom: 2,
    fontWeight: 'bold',
  },
  unit: {
    display: 'flex',
    textAlign: 'center',
    minHeight: 14,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '9pt',
    lineHeight: '10pt',
    marginTop: 2,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    marginTop: -17,
  },
  popper: {
    opacity: 1,
  },
  tooltipBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tooltipTop: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  icon: {
    position: 'absolute',
    bottom: 0,
    transform: 'translate(0px, 50%) translateY(4px)',
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    '& svg, & svg path': {
      fontSize: '18px',
      color: 'white',
      fill: 'white',
    }
  },
  name: {
    fontSize: 9,
  },
  selectedIndicator: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translate(-50%, -50%)',
  },
});

const WeatherStationMarker = (props) => {

  const {classes, googleMap, displayData, selectedStation, station, onSelectStation} = props;

  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();
  const measureSettings = useMeasureSettings();

  const selected = selectedStation && station.identifier === selectedStation.identifier;
  const hasData = stationHasData(station);

  const indicateRain = hasData && station.current["rain1H"] > 0;
  const hasBody = Object.keys(displayData).reduce((acc, cur) => acc || displayData[cur], false);

  const handleClick = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.WEATHER_STATION_MARKER_CLICK);
    onSelectStation(station.identifier);
  }, [onSelectStation, station]);

  let latitude, longitude;
  let gpsLocation = station.location;
  let gpsFixLocation = useGpsFixLocation(station.label);

  if (hasData && gpsLocation && gpsLocation.latitude && gpsLocation.longitude) {
    latitude = gpsLocation.latitude;
    longitude = gpsLocation.longitude;
  }
  else if (gpsFixLocation) {
    latitude = gpsFixLocation.lat();
    longitude = gpsFixLocation.lng();
  }
  else {
    return <div/>;
  }

  return (
    <MapPopup
      shown={true}
      key={station.identifier}
      clickable
      selectable
      hasPin
      selected={selected}
      backgroundColor={'white'}
      backgroundColorHover={grey[200]}
      onClick={handleClick}
      map={googleMap}
      position={{lat: latitude, lng: longitude}}>

      <Tooltip
        classes={{tooltip: classes.tooltip, tooltipPlacementTop: classes.tooltipTop, popper: classes.popper}}
        placement={"top"}
        title={
          <div className={classes.tooltipBody}>
            {LangFile.WeatherStationMarker.markerTooltip}
          </div>
        }>

        <div className={`${classes.label}`} style={{display: hasBody ? 'inherit' : 'none'}}>
          <WeatherStationIndicatorRow>
            <WeatherStationIndicator backgroundColor={blue["A400"]} shown={indicateRain}>
              <RainIcon pathcolor={"white"}/>
            </WeatherStationIndicator>
          </WeatherStationIndicatorRow>

          <div className={classes.wrapper}>

            {!hasData && (
              <div className={classes.cell}>
                {LangFile.WeatherStationMarker.noRecentData}
              </div>
            )}

            {hasData && Object.keys(displayData).map((sensor) => {
                let show = displayData[sensor];

                if (show) {

                  let value = getCurrentSensorReading(station, sensor);
                  let color = WeatherSensorColor[sensor];
                  let unit = getWeatherSensorUnit(measureSettings, sensor);
                  let decimals = WeatherSensorDecimals[sensor];

                  return (
                    <div className={classes.cell} key={station.identifier + sensor}>
                      <div className={classes.value} style={{color: color}}>{value != null ? Number.parseFloat(value).toFixed(decimals) : '-'}</div>
                      <div className={classes.unit}>{unit}</div>
                    </div>
                  );
                }
              }
            )}
          </div>
        </div>
      </Tooltip>
    </MapPopup>
  );
};

WeatherStationMarker.propTypes = {
  station: PropTypes.object,
  googleMap: PropTypes.object,
  displayData: PropTypes.object,
  selectedStation: PropTypes.object,
  onSelectStation: PropTypes.func,
  getSensorProps: PropTypes.func,
};

export default memo(withStyles(styles)(WeatherStationMarker));