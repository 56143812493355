"use strict";

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M18.5,14h-7v2h7v3l4-4l-4-4V14z M5.5,13v-3h7V8h-7V5l-4,4L5.5,13z"/>
    </SvgIcon>
  );
};
