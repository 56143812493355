"use strict";

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  return (
    <SvgIcon {...props} width="17.994" height="18" viewBox="0 0 17.994 18">
        <g id="_8c1f2888bc0ece4e60a1a765c6109aef" data-name="8c1f2888bc0ece4e60a1a765c6109aef" transform="translate(-10.003 -9.83)">
          <g id="Group_133" data-name="Group 133" transform="translate(10.003 9.83)">
            <path id="Path_435" data-name="Path 435" d="M583.291-502.11a2.7,2.7,0,0,0-.594.671,4.142,4.142,0,0,0-.5,1.125,2.711,2.711,0,0,0-.091.9,2.705,2.705,0,0,0,.091.9,3.743,3.743,0,0,0,.918,1.642c.165.162.211.19.334.193.183,0,.278-.067.573-.436a3.651,3.651,0,0,0,.749-1.614,4.666,4.666,0,0,0-.053-1.638c-.225-.84-.911-1.811-1.273-1.811A.546.546,0,0,0,583.291-502.11Z" transform="translate(-571.605 502.17)"/>
            <path id="Path_436" data-name="Path 436" d="M766.374-497.753a4.318,4.318,0,0,0-1.287.468,3.559,3.559,0,0,0-1.438,1.551,3.388,3.388,0,0,0-.373,1.273c-.049.447-.018.608.155.717.1.063.151.07.464.053a3.688,3.688,0,0,0,3.41-3.041,3.958,3.958,0,0,0,.053-.552c0-.236-.01-.271-.1-.373s-.116-.116-.38-.123A3.76,3.76,0,0,0,766.374-497.753Z" transform="translate(-749.424 497.862)"/>
            <path id="Path_437" data-name="Path 437" d="M437.035-356.245a3.121,3.121,0,0,0-.865,1.139,3.683,3.683,0,0,0,.6,4.043c.218.236.355.3.527.253a3.267,3.267,0,0,0,1.143-1.772,3.7,3.7,0,0,0-.647-3.213C437.456-356.235,437.246-356.358,437.035-356.245Z" transform="translate(-428 358.963)"/>
            <path id="Path_438" data-name="Path 438" d="M693.844-241.436a3.191,3.191,0,0,0-.928.324,2.768,2.768,0,0,0-.837.591c-.221.214-.239.243-.239.38s.021.172.151.313a3.145,3.145,0,0,0,.847.629,3.674,3.674,0,0,0,4.092-.411c.32-.264.4-.39.38-.573-.018-.158-.162-.324-.513-.584a3.776,3.776,0,0,0-1.572-.686A4.228,4.228,0,0,0,693.844-241.436Z" transform="translate(-679.321 246.288)"/>
            <path id="Path_439" data-name="Path 439" d="M291.465-211.047a1.862,1.862,0,0,0-.257.253,3.7,3.7,0,0,0-.615,3.9,4.012,4.012,0,0,0,.7,1.058c.19.2.215.211.369.211a.357.357,0,0,0,.288-.109,4.133,4.133,0,0,0,.963-1.646,3.69,3.69,0,0,0-.868-3.488c-.2-.207-.257-.243-.359-.243A.489.489,0,0,0,291.465-211.047Z" transform="translate(-285.144 216.454)"/>
            <path id="Path_440" data-name="Path 440" d="M548.515-96a3.652,3.652,0,0,0-1.941.925c-.264.253-.274.271-.274.422s.014.169.221.369a3.689,3.689,0,0,0,4.725.267c.411-.313.506-.425.531-.619.018-.162.014-.165-.208-.383a3.638,3.638,0,0,0-1.87-.953A5.367,5.367,0,0,0,548.515-96Z" transform="translate(-536.453 103.482)"/>
            <path id="Path_441" data-name="Path 441" d="M146.168-65.507a3.121,3.121,0,0,0-.552.636,3.713,3.713,0,0,0-.489,3.027,4.107,4.107,0,0,0,.963,1.649.355.355,0,0,0,.281.109c.193,0,.313-.1.619-.5a3.68,3.68,0,0,0-.158-4.666c-.2-.229-.324-.316-.461-.316A.456.456,0,0,0,146.168-65.507Z" transform="translate(-142.519 73.587)"/>
            <path id="Path_442" data-name="Path 442" d="M402.186,49.449a3.581,3.581,0,0,0-1.716.9l-.26.253.021.172c.018.151.039.19.225.359a3.906,3.906,0,0,0,1.663.889,4.352,4.352,0,0,0,1.705,0,3.771,3.771,0,0,0,1.656-.9c.211-.2.225-.225.225-.373s-.014-.169-.253-.4a3.532,3.532,0,0,0-1.733-.9A4.406,4.406,0,0,0,402.186,49.449Z" transform="translate(-393.045 -39.253)"/>
            <path id="Path_443" data-name="Path 443" d="M257.616,194.073a3.629,3.629,0,0,0-2.113.974c-.207.2-.243.257-.243.359,0,.179.1.313.425.577a3.7,3.7,0,0,0,3.477.633,3.4,3.4,0,0,0,1.079-.563c.394-.3.5-.44.5-.633,0-.151-.014-.176-.208-.362A3.72,3.72,0,0,0,257.616,194.073Z" transform="translate(-250.757 -181.272)"/>
            <path id="Path_444" data-name="Path 444" d="M12.836,265.742c-.056.035-.71.671-1.449,1.413-1.449,1.459-1.424,1.427-1.364,1.744a1.368,1.368,0,0,0,.622.647c.309.081.274.109,1.779-1.389.759-.756,1.406-1.417,1.435-1.47a.625.625,0,0,0,0-.478,2.173,2.173,0,0,0-.5-.5A.688.688,0,0,0,12.836,265.742Z" transform="translate(-10.003 -251.576)"/>
          </g>
        </g>
    </SvgIcon>
  );
};
