"use strict";

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  return (
    <SvgIcon
      {...props}
      width="76.448"
      height="76.448"
      viewBox="0 0 76.448 76.448">
      <g transform="translate(27.025 31.505)">
        <path
          d="M187.719,211a6.719,6.719,0,1,0,6.719,6.719A6.727,6.727,0,0,0,187.719,211Zm0,8.959a2.24,2.24,0,1,1,2.24-2.24A2.242,2.242,0,0,1,187.719,219.959Z"
          transform="translate(-181 -211)"/>
      </g>
      <g transform="translate(9.108 13.587)">
        <path
          d="M90.116,91C74.061,91,61,102.052,61,115.636s13.061,24.636,29.116,24.636c16.137,0,29.265-11.052,29.265-24.636S106.253,91,90.116,91Zm0,44.794c-13.585,0-24.636-9.042-24.636-20.157S76.531,95.479,90.116,95.479c13.667,0,24.786,9.042,24.786,20.157S103.783,135.794,90.116,135.794Z"
          transform="translate(-61 -91)"/>
      </g>
      <g transform="translate(18.067 22.546)">
        <path
          d="M138.917,151c-9.88,0-17.917,7.033-17.917,15.678s8.038,15.678,17.917,15.678,17.917-7.033,17.917-15.678S148.8,151,138.917,151Zm0,26.876c-7.41,0-13.438-5.024-13.438-11.2s6.028-11.2,13.438-11.2,13.438,5.024,13.438,11.2S146.327,177.876,138.917,177.876Z"
          transform="translate(-121 -151)"/>
      </g>
      <path
        d="M74.208,0H2.24A2.24,2.24,0,0,0,0,2.24V74.208a2.24,2.24,0,0,0,2.24,2.24H74.208a2.24,2.24,0,0,0,2.24-2.24V2.24A2.24,2.24,0,0,0,74.208,0ZM4.479,4.479h25.3A24.568,24.568,0,0,1,17.958,9.244,20.381,20.381,0,0,0,5.229,15.8q-.389.428-.749.875Zm0,67.489V55.458l.035.056a32.505,32.505,0,0,0,11.636,10.8,23.6,23.6,0,0,1,6.7,5.653Zm67.489,0H45.707c.1-.071.2-.14.284-.2a26.124,26.124,0,0,1,14.244-4.458,2.249,2.249,0,0,0,.385.033,15.854,15.854,0,0,0,11.348-4.761Zm0-20.306a11.283,11.283,0,0,1-11.123,11.2,25.137,25.137,0,0,0-5.7.452c-6.1,1.125-11.058,4.09-11.839,4.872a24.11,24.11,0,0,1-7.028,3.361,16.256,16.256,0,0,1-1.972.425H31a7.49,7.49,0,0,1-4.632-2.777A28.134,28.134,0,0,0,18.322,62.4C9.784,57.664,4.479,49.206,4.479,40.325V29.265a15.74,15.74,0,0,1,14-15.572A29.1,29.1,0,0,0,33.33,7.384a12.057,12.057,0,0,1,7.512-2.9h1.748a11,11,0,0,1,8.218,3.629,28.551,28.551,0,0,0,7.317,5.942c8.539,4.733,13.843,13.191,13.843,22.073Zm0-30.672-.035-.056A32.5,32.5,0,0,0,60.3,10.132a24.033,24.033,0,0,1-6.147-5.006q-.3-.333-.614-.647H71.968Z"/>
    </SvgIcon>
  );
};
