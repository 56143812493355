import {useLangFile} from '../../context/LanguageContext';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  List,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {voidFunc} from '../../constants/PropTypeUtils';
import React, {memo, useState} from 'react';
import Styles from './FieldIntegrationMappingDialog.module.less';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FieldIntegrationMappingHeader from './FieldIntegrationMappingHeader';
import FieldIntegrationMappingCell from './FieldIntegrationMappingCell';
import CircularProgress from '@material-ui/core/CircularProgress';

const FieldIntegrationMappingDialog = (props: FieldIntegrationMappingDialog.propTypes) => {
  const {open, onBack, onImport, onSelectLink, workbooks, fields, links, fetching, error, onUnlink} = props;
  const LangFile = useLangFile();

  const [selectBestMatch, setSelectBestMatch] = useState(false);


  return (
      <Dialog
        PaperProps={{ classes: {root: Styles.paper }}}
        open={open}
        disableEscapeKeyDown={true}
        fullWidth
        disableBackdropClick={true}
        maxWidth={"sm"}
        onClose={onBack}>
        <DialogContent className={Styles.content}>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box>
              <Typography variant={'h5'}>{LangFile.FieldIntegrationMapping.title}</Typography>
              <Divider className={Styles.divider}/>
            </Box>
            {!error && !fetching && <Typography>{LangFile.FieldIntegrationMapping.content}</Typography>}

            <Box style={{width: '100%', height: '60vh'}} display={'flex'} flexDirection={'column'} paddingBottom={2}>
              {!error && !fetching && (
                <>
                  {!error && !fetching && (
                    <Box pb={2} pt={2} display={"flex"} justifyContent={"center"}>
                      <Button onClick={() => setSelectBestMatch(true)} disabled={selectBestMatch} variant={"contained"} color={"primary"}>
                        {LangFile.FieldIntegrationMapping.fillAutomatically}
                      </Button>
                    </Box>
                  )}

                  <List
                    className={Styles.list}
                    subheader={
                      <ListSubheader classes={{sticky: Styles.sticky}}>
                        <FieldIntegrationMappingHeader
                          lhsTitle={LangFile.FieldStatistics.claas}
                          rhsTitle={LangFile.FieldStatistics.cropline}/>
                      </ListSubheader>
                    }>
                    {workbooks.map((workbook) => {
                      let link = links.find((link) => link.workbookId === workbook.id);
                      return <ListItem className={Styles.listItem} key={workbook.id} dense={true} divider={false}>
                        <FieldIntegrationMappingCell
                          workbook={workbook}
                          fields={fields}
                          selectBestMatch={selectBestMatch}
                          onUnlink={onUnlink}
                          link={link}
                          onSelectLink={onSelectLink}/>
                      </ListItem>;
                    })}
                  </List>
                </>
              )}

              {!error && fetching && (
                <Box display={'flex'} flex={1} justifyContent={'center'} alignItems={'center'}>
                  <CircularProgress
                    variant={"indeterminate"}
                    size={30}
                    color={"primary"}/>
                </Box>
              )}

              {error && (
                <Box color={"secondary.main"} display={'flex'} flexDirection={"column"} flex={1} justifyContent={'center'} alignItems={'center'} textAlign={"center"}>
                  <span>{LangFile.FieldIntegrationMapping.error}</span>
                  <span>{LangFile.FieldIntegrationMapping.error2}</span>

                  <Box mt={2}>
                    <Button variant={"outlined"} onClick={() => location.reload()}>{LangFile.FieldIntegrationMapping.reload}</Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box style={{width: '100%'}} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <Button onClick={onBack}>{LangFile.FieldIntegrationMapping.back}</Button>
            <Button
              disabled={error || fetching}
              variant={"contained"}
              color={"primary"}
              onClick={onImport}>{LangFile.FieldIntegrationMapping.importYieldData}</Button>
          </Box>
        </DialogActions>
      </Dialog>
  );
};

FieldIntegrationMappingDialog.propTypes = {
  open: PropTypes.bool,
  onBack: PropTypes.func,
  onImport: PropTypes.func,
  onUnlink: PropTypes.func,
  workbooks: PropTypes.array,
  links: PropTypes.array,
  fields: PropTypes.array,
  onSelectLink: PropTypes.func,
  fetching: PropTypes.bool,
  error: PropTypes.any,
};

FieldIntegrationMappingDialog.defaultProps = {
  onBack: voidFunc,
  onImport: voidFunc,
  onUnlink: voidFunc,
  onSelectLink: voidFunc,
  fetching: true
};

export default memo(FieldIntegrationMappingDialog);
