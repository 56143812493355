import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField/TextField";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormLabel from "@material-ui/core/FormLabel/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";

const styles = (theme) => ({
  formControl: {
    padding: theme.spacing(2),
    position: 'relative',
    marginTop: 0,
    marginBottom: 0,
    boxSizing: 'border-box',
  },
  disablePadding: {
    padding: 0,
  },
  textField: {
    boxSizing: 'border-box',
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: 'bold',
  },
  label: {
    fontSize: "1rem",
  },
  '@media (max-width: 1024px), (max-height: 800px)': {
    title: {
      fontSize: "1rem",
    },
    label: {
      fontSize: "0.75rem",
    },
  }
});

const SelectFormControl = (props) => {

  let {classes, className, disabled, disablePadding} = props;

  let rootClass = {
    [classes.formControl]: true,
    [classes.disablePadding]: disablePadding,
  };

  if (className) {
   rootClass[className] = true;
  }

  return (
    <FormControl component="fieldset" className={clsx(rootClass)} fullWidth={true} margin={"dense"} disabled={disabled}>
      <Typography className={classes.title} variant={"h6"}>{props.title}</Typography>
      <FormLabel component="label" className={classes.label}>{props.label}</FormLabel>
      <FormGroup aria-disabled={disabled}>
        <TextField
          id="outlined-select-metering"
          select={true}
          disabled={disabled}
          classes={{root: classes.textField}}
          value={props.value}
          onChange={(event) => props.onChange(event.target.value)}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
          InputProps={{
            classes: {input: classes.menu}
          }}
          margin="dense"
          variant="outlined">
          {props.options.map((option, idx) => (
            <MenuItem key={`${idx}-${option.value}`} value={option.value} button={true}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </FormGroup>
    </FormControl>
  );
};

SelectFormControl.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
  })),
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  disablePadding: PropTypes.bool,
};

export default withStyles(styles)(SelectFormControl);