import axios from "axios";
import WMSCapabilities from 'wms-capabilities';
import {loadWMS} from './wms';
import {
  DATAFORDELER_HEIGHTMAP_COARSE,
  DATAFORDELER_HEIGHTMAP_FINE,
  DATAFORDELER_HEIGHTMAP_TRADITIONAL, DATAFORDELER_SERVICE_URL
} from "./HeightMapUtils";

export function getLayersFromService(baseUrl) {
  let params = [
    "REQUEST=GetCapabilities",
    "SERVICE=WMS",
  ];

  let url = baseUrl + "&" + params.join("&");

  let overlayConfigs = [
    {
      name: DATAFORDELER_HEIGHTMAP_FINE,
      enabled: true,
      styles: "roed"
    },
    {
      name: DATAFORDELER_HEIGHTMAP_COARSE,
      enabled: true,
      styles: "roed"
    },
    {
      name: DATAFORDELER_HEIGHTMAP_TRADITIONAL,
      enabled: true,
      styles: "roed"
    },
  ];

  return new Promise((resolve, reject) => {
    axios.get(url, {
      timeout: 30000,
    }).then(
      (response) => {
        let resultLayers = [];
        let capabilities = new WMSCapabilities(response.data).toJSON();
        let root = capabilities["Capability"];
        let imageFormats = root["Request"]["GetMap"]["Format"];
        let imageFormat = imageFormats[0];
        let layers = root["Layer"]["Layer"];

        imageFormats.forEach((format) => {
          if (format.includes("png")) {
            imageFormat = format;
          }
        });

        layers.forEach((item) => {
          let overlayConfig = overlayConfigs.find((overlay) => overlay.name === item["Name"]);
          let styles = overlayConfig && overlayConfig.styles;

          let layer = {
            name: item["Name"],
            abstract: item["Abstract"],
            title: item["Title"],
            format: imageFormat,
            enabled: Boolean(overlayConfig && overlayConfig.enabled)
          };

          if (styles) {
            layer.styles = styles;
          }

          resultLayers.push(layer);
        });

        resolve(resultLayers);
      },
      (error) => {
        reject(error);
      }
    );
  });

}

export function loadWMSlayer(googleMap, layer) {
  let customParams = [
    "FORMAT=" + layer.format,
    "LAYERS=" + layer.name,
    "crs=EPSG:3857", // 3857 // WEBM
    "SRS=EPSG:3857", // 3395?
    "DPI=96",
    "MAP_RESOLUTION=96",
    "FORMAT_OPTIONS=dpi:96",
  ];

  if (layer.styles) {
    customParams.push("styles=" + layer.styles);
  }

  loadWMS(googleMap, DATAFORDELER_SERVICE_URL, customParams, layer);
}
