import {
  useCallback,
  useState
} from "react";
import useEvent from "js/hooks/useEvent";

const useShiftKeyDown = () => {
  const [isDown, setIsDown] = useState(false);

  useEvent('keydown', useCallback((event) => {
    setIsDown(event.shiftKey);
  }, []), window);

  useEvent('keyup', useCallback((event) => {
    setIsDown(false);
  }, []), window);

  return isDown;
};

export default useShiftKeyDown;