"use strict";

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {

  let prop = { props, viewBox: "0, 0, 16, 16" };

  return (
    <SvgIcon {...prop}>
      <path fill="#444444" d="M0 5h3v1h-3v-1z" />
      <path fill="#444444" d="M5 0h1v3h-1v-3z" />
      <path fill="#444444" d="M6 11h-1v-2.5l1 1z" />
      <path fill="#444444" d="M11 6h-1.5l-1-1h2.5z" />
      <path fill="#444444" d="M3.131 7.161l0.707 0.707-2.97 2.97-0.707-0.707 2.97-2.97z" />
      <path fill="#444444" d="M10.131 0.161l0.707 0.707-2.97 2.97-0.707-0.707 2.97-2.97z" />
      <path fill="#444444" d="M0.836 0.199l3.465 3.465-0.707 0.707-3.465-3.465 0.707-0.707z" />
      <path fill="#444444" d="M6.1 4.1l-2.1 2 9.8 9.9 2.2-2.1-9.9-9.8zM6.1 5.5l2.4 2.5-0.6 0.6-2.5-2.5 0.7-0.6z" />
    </SvgIcon>
  );
};
