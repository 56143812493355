import {
  memo,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {useGoogleMap} from "js/context/GoogleMapContext";

const MapOverlay = (props: MapOverlay.propTypes) => {
  const {fieldImage, zIndex, imageType} = props;

  const googleMap = useGoogleMap();
  const mapRef = useRef(googleMap);

  useEffect(() => {
    if (fieldImage) {
      let options = {
        clickable: false,
        zIndex: zIndex,
        opacity: 1
      };

      let url = fieldImage[imageType] || fieldImage.url;
      let overlay = new google.maps.GroundOverlay(url, fieldImage.bounds, options);
      overlay.setMap(mapRef.current);

      return () => {
        overlay.setMap(null);
        overlay = null;
      };
    }
  }, [fieldImage, imageType, zIndex]);

  return null;

};

MapOverlay.propTypes = {
  fieldImage: PropTypes.object,
  imageType: PropTypes.string,
  zIndex: PropTypes.number,
};

MapOverlay.defaultProps = {
  zIndex: 1,
};

export default memo(MapOverlay);
