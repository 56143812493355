import React, {
  Fragment,
  useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {
  DialogContent,
  DialogActions,
  DialogTitle, withStyles
} from '@material-ui/core';
import Button from "@material-ui/core/Button/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {useLangFile} from "js/context/LanguageContext";
import Chip from "@material-ui/core/Chip";

import {WarningRounded, Info} from "@material-ui/icons";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import {usePrescriptionJob} from "js/components/Prescription/PrescriptionJobContext";
import {
  calculatePrescriptionLimitAdjustedIntervals,
} from "js/components/Prescription/PrescriptionUtils";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../../../hooks/useFirebaseAnalytics";
import {PRESCRIPTION_UNIT} from '../../../../constants/PrescriptionConstants';

const styles = (theme) => ({
  title: {},
  adjustWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
    flexDirection: 'column'
  },
  divider: {
    margin: [[theme.spacing(2), 0]]
  },
  chip: {
    margin: theme.spacing(1),
    position: 'absolute',
    top: 0,
    right: 0,
  }
});

const PrescriptionAutoAdjustDialog = ({classes, open, onSetOpen}) => {
  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();

  const {prescriptionJob, setPrescriptionJob} = usePrescriptionJob();
  const {unit, maxPrescription, totalPrescription} = prescriptionJob;

  let unitString;
  let limit = Number(maxPrescription).toFixed(2);
  let total = Number(totalPrescription).toFixed(2);
  switch (unit) {
    case PRESCRIPTION_UNIT.LITER:
      unitString = LangFile.PrescriptionSettings.unit.liters.short;
      break;
    case PRESCRIPTION_UNIT.PIECES_M2:
      unitString = `t.${LangFile.PrescriptionSettings.unit.pieces.short}`;
      limit = Number(maxPrescription / 1000).toFixed();
      total = Number(totalPrescription / 1000).toFixed();
      break;
    default:
      unitString = LangFile.PrescriptionSettings.unit.kilogram.short;
      break;
  }

  let enableAdjust = Math.abs(Number(limit - total)) > 0;

  let overLimit = total > limit;
  let underLimit = total < limit;
  
  const handleOnClose = useCallback(() => {
    onSetOpen(false);
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_AUTO_ADJUST_CLOSE);
  }, []);
  
  const handleOnAdjust = useCallback(() => {
    let {adjustedIntervals} = calculatePrescriptionLimitAdjustedIntervals(prescriptionJob);

    let changes = {
      intervals: adjustedIntervals
    };

    setPrescriptionJob(changes, true);
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_AUTO_ADJUST_SUBMIT);
  }, [prescriptionJob]);

  useEffect(() => {
    if (open) {
      analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_AUTO_ADJUST_INIT);
    }
  }, [open]);

  return (
    <Fragment>
      <Dialog open={open} fullWidth maxWidth={"md"}>
        <DialogTitle className={classes.title}>
          {LangFile.PrescriptionAutoAdjustDialog.title}
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            {LangFile.PrescriptionAutoAdjustDialog.info1}
          </Typography>
          <Typography gutterBottom>
            {LangFile.PrescriptionAutoAdjustDialog.info2}
          </Typography>
          <Typography gutterBottom>
            {LangFile.PrescriptionAutoAdjustDialog.info3}
          </Typography>

          <Divider className={classes.divider}/>

          <div className={classes.adjustWrapper}>
            <Typography gutterBottom>
              {LangFile.PrescriptionAutoAdjustDialog.limit} <b>{limit} {unitString}</b>
            </Typography>

            <Typography gutterBottom>
              {LangFile.PrescriptionAutoAdjustDialog.total} <b>{total} {unitString}</b>
            </Typography>

            <Box mt={2}>
              <Button onClick={handleOnAdjust} color="primary" variant="contained" disabled={!enableAdjust}>
                {LangFile.PrescriptionAutoAdjustDialog.adjust}
              </Button>
            </Box>

            {overLimit && (
              <Chip
                avatar={
                  <Avatar>
                    <WarningRounded/>
                  </Avatar>
                }
                className={classes.chip}
                label={LangFile.PrescriptionAutoAdjustDialog.overLimit}
                color="secondary"/>
            )}

            {underLimit && (
              <Chip
                avatar={
                  <Avatar>
                    <Info/>
                  </Avatar>
                }
                className={classes.chip}
                label={LangFile.PrescriptionAutoAdjustDialog.underLimit}
                color="primary"/>
            )}

          </div>

          <Divider className={classes.divider}/>

          <Typography gutterBottom>
            {LangFile.PrescriptionAutoAdjustDialog.info4}
          </Typography>

          <Typography gutterBottom>
            {LangFile.PrescriptionAutoAdjustDialog.info5}
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnClose} color="primary" variant={"outlined"}>
            {LangFile.PrescriptionAutoAdjustDialog.close}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default withStyles(styles)(PrescriptionAutoAdjustDialog);

PrescriptionAutoAdjustDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSetOpen: PropTypes.func.isRequired,
};
