import React, {memo, useCallback, useContext} from 'react';
import {Box, Typography} from '@material-ui/core';
import {Sort} from '@material-ui/icons';
import Styles from './SortableHeaderCell.module.less';
import {useTheme} from '@material-ui/core/styles';
import {FilterContext} from "./FieldStatisticsContainer";

const headerSortCellClassname = `rdg-header-sort-cell`;

const SortableHeaderCell = ({
                              onSort,
                              sortDirection,
                              isCellSelected,
                              column,
                            }) => {

  const inputStopPropagation = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
  });

  const focusStopPropagation = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
      event.stopPropagation();
    }
  });

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === ' ' || event.key === 'Enter') {
      // stop propagation to prevent scrolling
      event.preventDefault();
      onSort(event.ctrlKey || event.metaKey);
    }
  });

  const handleClick = useCallback((event: React.MouseEvent<HTMLSpanElement>) => {
    onSort(event.ctrlKey || event.metaKey);
  });

  const theme = useTheme();
  const {filters, setFilters} = useContext(FilterContext);
  const filter = filters[column.key];

  let iconRotationDegrees, iconColor, bgcolor;
  if (sortDirection === undefined) {
    iconRotationDegrees = 0;
    bgcolor = null;
    iconColor = theme.palette.text.hint;
  }
  else if (sortDirection === "DESC") {
    iconRotationDegrees = 180;
    bgcolor = theme.palette.primary.light;
    iconColor = theme.palette.action.active;
  }
  else if (sortDirection === "ASC") {
    iconRotationDegrees = 0;
    bgcolor = theme.palette.primary.light;
    iconColor = theme.palette.action.active;
  }

  const onChange = useCallback((e) => {
    console.log('onChange: ', onChange);
    if (e.target) {
      let value = e.target.value;
      if (value !== undefined) {
        setFilters((cur) => ({...cur, [column.key]: {...filter, value: value}}));
      }
    }
  }, []);

  return (
    <Box
      height={'100%'}
      className={headerSortCellClassname}
      bgcolor={bgcolor}
      onKeyDown={handleKeyDown}>
      <Box display={"flex"} flexDirection={"column"} width={"100%"} height={"100%"} justifyContent={"space-between"} p={0.5}>
        <Box className={Styles.containerView} display={'flex'} flexDirection={'row'} width={'100%'} height={"100%"} alignItems={filters.enabled ? 'flex-start' : 'center'} onClick={handleClick}>
          <Sort style={{color: iconColor, transform: `rotate(${iconRotationDegrees}deg)`}}/>
          <Box display={'flex'} flexDirection={'column'} ml={1} className={Styles.titleWrapper}>
            <Typography className={Styles.headerTitle}>{column.name}</Typography>
            {column.unit && (
              <Typography className={Styles.headerSubtitle}>{column.unit}</Typography>
            )}
          </Box>
        </Box>

        {filters.enabled && !filter && (
          <input
            className={Styles.inputDisabled}
            disabled={true}/>
        )}

        {filters.enabled && filter && (
          <>
            <input
              className={Styles.inputEnabled}
              type={filter.inputType}
              list={filter.inputType === "list" ? column.key : undefined}
              value={filter.value}
              onChange={onChange}
              onFocus={focusStopPropagation}
              onClick={focusStopPropagation}
              onKeyDown={inputStopPropagation}/>

            <datalist id={column.key}>
              {filter.inputType === "list" && (
                filter.options.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))
              )}
            </datalist>
          </>
        )}
      </Box>
    </Box>
  );
};

export default memo(SortableHeaderCell);
