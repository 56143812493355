import FieldStatisticsShortcutCell from "../FieldStatisticsShortcutCell";
import React, {memo} from "react";
import {getSoilOptixStatsForColumn} from "../Utils/FieldStatisticsUtils";
import EmptyColumnCell from './EmptyColumnCell';
import SoilOptixColumnCell from './SoilOptixColumnCell';
import PropTypes from 'prop-types';
import {voidFunc} from '../../../constants/PropTypeUtils';

const SurveyColumnCell = ({column, stats, onShortcutSoilOptix}) => {
  const soilOptixStats = getSoilOptixStatsForColumn(column, stats.soilOptix);

  let hoverChildren = (
    <FieldStatisticsShortcutCell onClick={() => onShortcutSoilOptix(stats.fieldId, column)}/>
  );

  return soilOptixStats ? <SoilOptixColumnCell stats={soilOptixStats} onHoverChildren={hoverChildren}/> :
    <EmptyColumnCell/>;
};

SurveyColumnCell.propTypes = {
  column: PropTypes.string,
  stats: PropTypes.object,
  onShortcutSoilOptix: PropTypes.func
};

SurveyColumnCell.defaultProps = {
  onShortcutSoilOptix: voidFunc
};

export default memo(
  SurveyColumnCell
);
