import React, {memo, useCallback, useEffect, useState} from 'react';
import ViewModeConstants from 'js/constants/ViewModeConstants';
import NotesMenu from 'js/components/FloatingActionButtons/NotesMenu';
import AnalysisTab from './AnalysisTab';
import BottomDrawer from "js/components/BottomDrawer/BottomDrawer";
import {setViewMode} from 'js/reducers/ControlReducer';
import {setSelectedFieldDates, setDate, setShowSatelliteImagery} from "js/reducers/FieldReducer";
import {
  setIsDrawingNote,
  setIsPlacingMarker,
  createDefaultNote,
  setIsMagicSelecting
} from 'js/reducers/NoteReducer';

import {connect} from 'react-redux';
import ManualFeatureConstants from "js/constants/ManualFeatureConstants";
import NotesContainer from "../Notes/NotesContainer";
import {setShowSoilSurveys} from "../../reducers/SurveyReducer";
import SurveyReportContainer from "../SurveyReport/SurveyReportContainer";
import firebase from "firebase/app";
import {FIREBASE_EVENTS} from "../../hooks/useFirebaseAnalytics";
import SatelliteSource from '../../model/SatelliteSource';
import PropTypes from 'prop-types';
import {useLangFile} from '../../context/LanguageContext';
import moment from 'moment-timezone';
import JSZip from 'jszip';
import {saveData} from '../../DownloadFile';
import ExcelJS from 'exceljs/dist/exceljs';
import {Box, Fab, Tooltip, Typography} from '@material-ui/core';
import {getFieldName} from '../../helpers/StateInterpreters';
import WebAPIUtils from '../../WebAPIUtils';
import {SATELLITE_LAYERS} from '../../constants/SatelliteLayers';
import {getSatelliteShorthandKey} from '../Prescription/PrescriptionJob';

const mapStateToProps = (store) => {
  return {
    field: store.field.selectedField,
    showSatelliteImagery: store.field.showSatelliteImagery,
    isDrawingField: store.addField.isDrawing,
    isAddingFields: store.addField.isAddingFields,
    isImportingFields: store.addField.isImportingFields,
    isDrawingNote: store.note.isDrawing,
    isMagicSelecting: store.note.magicWand.isSelecting,
    isPlacingMarker: store.note.isPlacingMarker,
    editNoteLocation: store.note.editNoteLocation,
    viewMode: store.control.viewMode,
    date: store.field.date,
    dateFormatLong: store.language.dateFormatLong,
    images: store.field.images,
    imageType: store.field.imageType,
    farm: store.farm.farm,
    user: store.user.user,
    drawnPolygon: store.note.drawnPolygon,
    selectedFieldDates: store.field.selectedFieldDates,
    activeTab: store.control.activeTab,
  };
};

const AnalysisDrawerContainer = (props: AnalysisDrawerContainer.propTypes) => {
  const defaultZoom = 16;
  const LangFile = useLangFile();
  const [satelliteSource, setSatelliteSource] = useState(SatelliteSource.BOTH);
  const [showNotes, setShowNotes] = useState(true);
  const [fieldSeasons, setFieldSeasons] = useState([]);
  const [fieldStatsType, setFieldStatsType] = useState(null);
  const [fieldStats, setFieldStats] = useState([]);

  useEffect(() => {
    if (props.googleMap) {
      props.googleMap.addListener('zoom_changed', () => {
        if (props.googleMap && props.googleMap.getZoom() <= defaultZoom - 3) {
          props.dispatch(setViewMode(ViewModeConstants.OVERVIEW));
        }
      });
    }
  }, [props.googleMap]);

  useEffect(() => {
    if (props.field && props.showSatelliteImagery) {
      zoomToField();
      WebAPIUtils.getFieldSeasons(props.field.fieldId).then((result) => {
        setFieldSeasons(result);
      });
    }
  }, [props.field, props.showSatelliteImagery]);

  useEffect(() => {
    if (props.field && props.imageType && props.showSatelliteImagery) {
      const type = props.imageType === SATELLITE_LAYERS.VITALITY_NDRE || props.imageType === SATELLITE_LAYERS.VARIATIONS_NDRE ? "ndre" : "ndvi";
      setFieldStatsType(type);
    }
  }, [props.field, props.imageType, props.showSatelliteImagery]);

  useEffect(() => {
    if (props.field && fieldStatsType && props.showSatelliteImagery) {
      let bundleId = props.field.bundle;
      if (bundleId) {
        // props.dispatch(fetchStatisticsForField(bundleId));
        WebAPIUtils.getBundleStats(bundleId, fieldStatsType).then((result) => {
          setFieldStats(result);
        });
      }
    }
  }, [props.field, fieldStatsType, props.showSatelliteImagery]);

  useEffect(() => {
    if (fieldStats.length > 0) {
      let dates = [];

      fieldStats.forEach((item) => {
        if (dates.indexOf(item.date) === -1) {
          dates.push(item.date);
        }
      });

      dates.sort((a, b) => {
        return moment(a).diff(moment(b));
      });
      props.dispatch(setSelectedFieldDates(dates));
    }
  }, [fieldStats]);

  const zoomToField = useCallback(() => {
    let bounds = new google.maps.LatLngBounds();
    let points = [
      new google.maps.LatLng(props.field.bounds.north, props.field.bounds.west),
      new google.maps.LatLng(props.field.bounds.south, props.field.bounds.east)
    ];
    for (let i = 0; i < points.length; i++) {
      bounds.extend(points[i]);
    }

    // First fit the view to the field
    if (props.googleMap.getProjection()) {
      let offsetx = 0;
      let offsety = 350;

      let point1 = props.googleMap.getProjection().fromLatLngToPoint(bounds.getSouthWest());
      props.googleMap.fitBounds(bounds);

      // Now let us fit the map to the area not covered by the gui.
      let point2 = new google.maps.Point(
        ((typeof (offsetx) === 'number' ? offsetx : 0) / Math.pow(2, props.googleMap.getZoom())) || 0,
        ((typeof (offsety) === 'number' ? offsety : 0) / Math.pow(2, props.googleMap.getZoom())) || 0
      );

      let newPoint = props.googleMap.getProjection().fromPointToLatLng(new google.maps.Point(
        point1.x - point2.x,
        point1.y + point2.y
      ));
      bounds.extend(newPoint);
      props.googleMap.fitBounds(bounds);
    }
  });

  const setYear = useCallback((value) => {
    let target = null;
    props.selectedFieldDates.forEach((date) => {
      const year = date.split('-')[0];
      if (year === value) { // YYYY-MM-DD --> YYYY
        target = date;
      }
    });

    if (target) {
      props.dispatch(setDate(target));
    }
  });

  const onIsDrawingNote = useCallback((value) => {
    props.dispatch(setIsDrawingNote(value));
  });

  const onIsPlacingMarker = useCallback((value) => {
    props.dispatch(setIsPlacingMarker(value));
  });

  const onSelectDate = useCallback((dateString) => {
    if (!props.showSatelliteImagery){
      props.dispatch(setShowSatelliteImagery(true));
      props.dispatch(setShowSoilSurveys(false));
    }
    props.dispatch(setDate(dateString));
  });

  const onIsMagicSelecting = useCallback((value) => {
    props.dispatch(setIsMagicSelecting(value));
  });

  const onCreatePrescription = useCallback(() => {
    props.dispatch(setViewMode(ViewModeConstants.PRESCRIPTION));
    firebase.analytics().logEvent(FIREBASE_EVENTS.ANALYSIS_VRM);
  });

  const onSelectSatelliteSource = useCallback((value) => {
    setSatelliteSource(value);
  });

  const sortByDate = (a, b) => {
    if (a.date > b.date) {
      return 1;
    }
    if (b.date > a.date) {
      return -1;
    }
    return 0;
  };

  const onDownloadCSV = useCallback(() => {
    const year = moment(props.date).format("YYYY");
    const data = generateData(fieldStats, year);
    const zip = new JSZip();

    const satelliteLayerTypeShort = getSatelliteShorthandKey(props.imageType).toLowerCase();
    const columns = [
      'date',
      'source',
      `${satelliteLayerTypeShort}_min`,
      `${satelliteLayerTypeShort}_max`,
      `${satelliteLayerTypeShort}_mean`,
      `${satelliteLayerTypeShort}_median`,
      `${satelliteLayerTypeShort}_stddev`
    ];

    let csv = columns.map((column) => {
      return `${column}`;
    }).join(';') + '\n';
    data.forEach((element) => {
      csv += `${element.date};`;
      csv += `${element.source};`;
      csv += `${Number(element.min).toFixed(2).toString().replace(".", ",")};`;
      csv += `${Number(element.max).toFixed(2).toString().replace(".", ",")};`;
      csv += `${Number(element.mean).toFixed(2).toString().replace(".", ",")};`;
      csv += `${Number(element.median).toFixed(2).toString().replace(".", ",")};`;
      csv += `${Number(element.stddev).toFixed(2).toString().replace(".", ",")}\n`;
    });

    const fieldName = getFieldName(props.field, LangFile);
    const filename =`${fieldName}-${satelliteLayerTypeShort}-${year}.csv`;
    zip.file(filename, csv);
    zip.generateAsync({type: 'blob'})
      .then(function (content) {
        saveData(content, `${fieldName}-${satelliteLayerTypeShort}-${year}.zip`);
      });
  }, [props.field, props.date, fieldStats, satelliteSource]);

  const onDownloadExcel = useCallback(() => {
    const year = moment(props.date).format("YYYY");
    const data = generateData(fieldStats, year);
    const zip = new JSZip();

    const satelliteLayerTypeShort = getSatelliteShorthandKey(props.imageType).toLowerCase();
    const columns = [
      'date',
      'source',
      `${satelliteLayerTypeShort}_min`,
      `${satelliteLayerTypeShort}_max`,
      `${satelliteLayerTypeShort}_mean`,
      `${satelliteLayerTypeShort}_median`,
      `${satelliteLayerTypeShort}_stddev`
    ];

    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet();
    ws.getRow(1).values = columns;
    data.forEach((element, index) => {
      const row = ws.getRow(index+2);
      row.getCell(1).value = element.date;
      row.getCell(2).value = element.source;
      row.getCell(3).value = Number(element.min).toFixed(2);
      row.getCell(4).value = Number(element.max).toFixed(2);
      row.getCell(5).value = Number(element.mean).toFixed(2);
      row.getCell(6).value = Number(element.median).toFixed(2);
      row.getCell(7).value = Number(element.stddev).toFixed(2);
    });

    const excel = wb.xlsx
      .writeBuffer()
      .catch((error) => {
        throw error;
      });

    const fieldName = getFieldName(props.field, LangFile);
    const filename =`${fieldName}-${satelliteLayerTypeShort}-${year}.xlsx`;
    zip.file(filename, excel);
    zip.generateAsync({type: 'blob'})
      .then(function (content) {
        saveData(content, `${fieldName}-${satelliteLayerTypeShort}-${year}.zip`);
      });
  }, [props.field, props.date, props.imageType, fieldStats, satelliteSource]);

  const generateData = (data, year) => {
    data = data.filter((element) => element.source === satelliteSource || satelliteSource === SatelliteSource.BOTH);
    // Sort the data
    data = data.sort(sortByDate);
    // Then remove all dublicate entries
    data = data.filter(
      (element, idx, ary) =>
        moment(element.date).isSame(year + '-01-01', 'year') && (
          idx === 0 ||
          idx === data.length - 1 ||
          element.source === SatelliteSource.SENTINEL ||
          (element.date !== ary[idx - 1].date && element.date !== ary[idx + 1].date))
    );
    return data;
  };

  const hasNDVIimages = props.selectedFieldDates.length > 0;
  const hasNDVIimageForDate = props.selectedFieldDates.indexOf(props.date) !== -1;
  const showDrawer = !(props.isDrawingField || props.isDrawingNote || props.isPlacingMarker || props.isImportingFields || props.isAddingFields || props.isMagicSelecting || props.editNoteLocation);
  const shouldShowNotes = showNotes && (showDrawer || props.editNoteLocation);

  const notesMenu = <NotesMenu
    showNotes={showNotes}
    setShowNotes={setShowNotes}
    enableMagicWand={ManualFeatureConstants.ALLOW_MAGIC_WAND}
    LangFile={LangFile}
    field={props.field}
    date={props.date}
    googleMap={props.googleMap}
    isPlacingMarker={props.isPlacingMarker}
    drawnPolygon={props.drawnPolygon}
    setIsDrawingNote={onIsDrawingNote}
    setIsPlacingMarker={onIsPlacingMarker}
    setIsMagicSelecting={onIsMagicSelecting}
    createDefaultNote={createDefaultNote}
    onCreatePrescription={onCreatePrescription}
    shown={showDrawer}
    renderSurveyReportContainer={() => (
      <SurveyReportContainer />
    )}/>;

  let actionButtons = (
    <>
      {props.showSatelliteImagery && (
        <>
          <Tooltip title={LangFile.WeatherDrawer.downloadCSV}>
            <Fab size="small" onClick={onDownloadCSV}>
              <Box color={'#448E47'}><Typography variant={"caption"}>CSV</Typography></Box>
            </Fab>
          </Tooltip>
          <Box mt={1}/>
          <Tooltip title={LangFile.WeatherDrawer.downloadExcel}>
            <Fab size="small" onClick={onDownloadExcel}>
              <Box color={'#61B565'}><Typography variant={"caption"}>Excel</Typography></Box>
            </Fab>
          </Tooltip>
        </>
      )}
      {notesMenu}
    </>
  );

  return (
    <>
      <BottomDrawer shown={showDrawer} initiallyShown={props.showSatelliteImagery && hasNDVIimageForDate} actionButtons={actionButtons} canBeHidden={hasNDVIimages} alwaysShowActionButtons={true} onOpen={props.onBottomOpen}>
        {showDrawer && (
          <AnalysisTab
            satelliteSource={satelliteSource}
            selectSatelliteSource={onSelectSatelliteSource}
            field={props.field}
            dateFormatLong={props.dateFormatLong}
            setDate={onSelectDate}
            selectedFieldStatistics={fieldStats}
            date={props.date}
            selectedFieldDates={props.selectedFieldDates}
            setYear={setYear}
            fieldSeasons={fieldSeasons}
            imageType={props.imageType}/>
        )}
      </BottomDrawer>
      <NotesContainer showNotes={shouldShowNotes}/>
    </>
  );
};

AnalysisDrawerContainer.propTypes = {
  dispatch: PropTypes.func,
  field: PropTypes.object,
  showSatelliteImagery: PropTypes.bool,
  isDrawingField: PropTypes.bool,
  isAddingFields: PropTypes.bool,
  isImportingFields: PropTypes.bool,
  isDrawingNote: PropTypes.bool,
  isMagicSelecting: PropTypes.bool,
  isPlacingMarker: PropTypes.bool,
  editNoteLocation: PropTypes.bool,
  viewMode: PropTypes.string,
  date: PropTypes.string,
  dateFormatLong: PropTypes.object,
  images: PropTypes.object,
  imageType: PropTypes.string,
  farm: PropTypes.object,
  user: PropTypes.object,
  drawnPolygon: PropTypes.object,
  selectedFieldDates: PropTypes.array,
  activeTab: PropTypes.string,
  fieldSeasons: PropTypes.object
};

export default memo(
  connect(mapStateToProps)(
    AnalysisDrawerContainer
  )
);
