import WebAPIUtils from 'js/WebAPIUtils.js';
import {getOtherFarms} from "js/helpers/FarmUtils";
import {
  UPDATE_GLOBAL_FARM_SETTING,
  UPDATE_PERSONAL_FARM_SETTING,
} from "js/reducers/SettingsReducer";
import SettingsConstants from "js/constants/SettingsConstants";

/* ============== //
||  ACTION TYPES   ||
// ============== */

export const AUTHENTICATE_FARM = "fieldsense/FarmReducer/AUTHENTICATE_FARM";
export const CHANGE_FARM = "fieldsense/Farm/CHANGE_FARM";
const SET_CHANGING_FARM = "fieldsense/Farm/SET_CHANGING_FARM";
const ADD_USER_TO_FARM = "fieldsense/Farm/ADD_USER_TO_FARM";
const REMOVE_USER_FROM_FARM = "fieldsense/Farm/REMOVE_USER_FROM_FARM";
const ADD_HECTARE = "fieldsense/Farm/ADD_HECTARE";
const ACCEPT_PAYMENT = "fieldsense/Farm/ACCEPT_PAYMENT";
const FETCH_FARMS = "fieldsense/FarmReducer/FETCH_FARMS";
const FETCH_FARM_USERS = "fieldsense/FarmReducer/FETCH_FARM_USERS";

/* ============== //
||    HELPERS     ||
// ============== */

async function addUserAndFetchData(farmId, userObject) {
  await WebAPIUtils.addUserToFarm(farmId, userObject);
  return await WebAPIUtils.getFarmUsers(farmId);
}

async function fetchFarmToken(farmId) {
  let farmTokenObject = await WebAPIUtils.getFarmToken(farmId);
  let token = "Bearer " + farmTokenObject.accessToken;

  return {token, refreshToken: farmTokenObject.refreshToken};
}

/* ============== //
||    ACTIONS    ||
// ============== */

export function authenticateFarm(farm) {
  return {
    type: AUTHENTICATE_FARM,
    payload: fetchFarmToken(farm.farmId),
    meta: {
      farm: farm,
    }
  };
}

export function fetchFarms(userId) {
  return {
    type: FETCH_FARMS,
    payload: WebAPIUtils.getFarms(userId),
  };
}

export function fetchFarmUsers(farmId) {
  return {
    type: FETCH_FARM_USERS,
    payload: WebAPIUtils.getFarmUsers(farmId)
  };
}

export function setChangingFarm(changing) {
  return {
    type: SET_CHANGING_FARM,
    payload: changing
  };
}

export function addUserToFarm(farmId, userObject) {

  return {
    type: ADD_USER_TO_FARM,
    payload: addUserAndFetchData(farmId, userObject)
  };
}

export function removeUserFromFarm(userId, farmId) {
  return (dispatch) => {
    return dispatch({
      type: REMOVE_USER_FROM_FARM,
      payload: WebAPIUtils.removeUserFromFarm(userId, farmId),
      meta: userId
    });
  };
}

export function addHectare(hectares) {
  return {
    type: ADD_HECTARE,
    payload: hectares
  };
}

/* ============== //
||     REDUCER    ||
// ============== */

const initState = {
  farm: {},
  farms: [],
  otherFarms: [],
  changingFarm: false
};

export default function reducer(state = initState, action) {
  switch (action.type) {

    case FETCH_FARMS + "_FULFILLED": {
      let farms = action.payload;

      state = {...state, farms: farms};
      break;
    }

    case AUTHENTICATE_FARM + "_FULFILLED": {
      let farm = {...action.meta.farm};

      state = {
        ...state,
        farm: farm,
        farms: [...state.farms],
        otherFarms: getOtherFarms(farm, state.farms),
      };
      break;
    }

    case FETCH_FARM_USERS + "_FULFILLED": {
      let farm = {...state.farm, users: action.payload};

      state = {...state, farm: farm};
      break;
    }

    case SET_CHANGING_FARM:
      state = {...state, changingFarm: action.payload};
      break;

    case CHANGE_FARM + "_FULFILLED":
      state = {
        ...state,
        farm: action.meta.farm,
        otherFarms: action.meta.otherFarms
      };
      break;

    case UPDATE_GLOBAL_FARM_SETTING + "_FULFILLED": {
      if (action.meta.entry === SettingsConstants.FARM_NAME) {
        state = {...state, farm: {...state.farm, name: action.meta.value}};
      }
      break;
    }

    case UPDATE_PERSONAL_FARM_SETTING + "_FULFILLED": {
      if (action.meta.entry === SettingsConstants.FARM_NAME) {
        state = {...state, farm: {...state.farm, name: action.meta.value}};
      }
      break;
    }

    case ADD_USER_TO_FARM + "_FULFILLED":
      state = {...state, farm: {...state.farm}};
      state.farm.users = action.payload;
      break;

    case REMOVE_USER_FROM_FARM + "_FULFILLED":
      state = {...state, farm: {...state.farm}};
      state.farm.users = state.farm.users.filter((user) => user.userId !== action.meta);
      break;

    case ACCEPT_PAYMENT + "_FULFILLED":
      state = {...state, farm: {...state.farm}};
      state.farm.trial = false;
      break;

    case ADD_HECTARE:
      state = {...state, farm: {...state.farm}};
      state.farm.currentHectare += action.payload;
      break;


    default:
      break;
  }

  return state;
}
