import React, {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {connect} from 'react-redux';
import MarkdownArticleDialog from "js/components/MarkdownArticleDialog/MarkdownArticleDialog";
import moment from "moment-timezone";
import {setArticlesSeen} from "js/reducers/NewsReducer";

const mapStateToProps = (store) => {
  return {
    articles: store.news.articles,
    seenArticles: store.news.seenArticles,
  };
};

const NewsContainer = ({dispatch, articles, seenArticles}) => {

  const [currentArticle, setCurrentArticle] = useState(null);

  const onClose = useCallback((article) => {
    let seen = [...seenArticles, article.id];
    dispatch(setArticlesSeen(seen));
  }, [seenArticles]);

  useEffect(() => {
    if (Array.isArray(articles) && articles.length > 0) {
      let now = moment();
      let filteredArticles = articles
        .filter((article) => seenArticles.indexOf(article.id) === -1)
        .filter((article) => moment(article.start).isBefore(now) && moment(article.end).isAfter(now));

      if (filteredArticles.length > 0) {
        setCurrentArticle(filteredArticles[0]);
      }
      else {
        setCurrentArticle(null);
      }

    }
  }, [articles, seenArticles]);


  if (!currentArticle) {
    return null;
  }

  return (
    <MarkdownArticleDialog
      article={currentArticle}
      onClose={onClose}
      key={currentArticle.id}/>
  );
};

export default memo(
  connect(mapStateToProps)(
    NewsContainer
  )
);