/*
  This is used to load the google maps script into the dom.
  It ensures that it is only loaded once!
*/

let loading = false;
let waitingForResolve = [];

const ScriptCache = (src) => {

  return new Promise((resolve, reject) => {

    if (window.google && window.google.maps) {
      waitingForResolve.forEach((awaitingResolve) => awaitingResolve());
      resolve();
      return;
    }

    if (loading) {
      waitingForResolve.push(resolve);
      return;
    }

    loading = true;

    let body = document.getElementsByTagName('body')[0];
    let tag = document.createElement('script');

    tag.onload = () => {
      loading = false;
      waitingForResolve.forEach((waitigResolve) => waitigResolve());
      resolve();
    };

    tag.onerror = () => {
      loading = false;
      reject(new Error('Failed to load google maps'));
    };

    tag.src = src;

    body.appendChild(tag);
  });
};

export default ScriptCache;
