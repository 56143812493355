import React, {memo, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {voidFunc} from '../../constants/PropTypeUtils';
import FieldOverview from './FieldOverview';
import {connect} from 'react-redux';
import {getFieldsArray} from '../ManageCrops/ManageCrops';
import {groupBy} from '../../algorithms/grouping';
import FieldOverviewSection from './FieldOverviewSection';
import {useLangFile, useLangFileRef} from '../../context/LanguageContext';
import {saveViewport, setShowAddFieldsDialog, setViewMode} from '../../reducers/ControlReducer';
import {useGoogleMap} from '../../context/GoogleMapContext';
import ViewModeConstants from '../../constants/ViewModeConstants';
import {setFilteredCropType, setFilteredField, selectField, resetFilters} from "../../reducers/FieldReducer";
import {goToField} from "../../helpers/MapsUtils";
import {useHookRef} from "../../hooks/useHookRef";
import {CropType} from "../../constants/CropConstants";
import WebAPIUtils from "../../WebAPIUtils";
import {useSelectedSeason} from "../../context/SeasonContext";
import ProgressLoadingModal from "../Modals/ProgressLoadingModal";
import {useFarm} from "../../context/AccountContext";

const mapStateToProps = (store) => {
  return {
    fields: store.field.fields,
    selectedField: store.field.selectedField,
    filteredFields: store.field.filteredFields,
    filteredCropTypes: store.field.filteredCropTypes
  };
};

const FieldOverviewContainer = (props: FieldOverviewContainer.propTypes) => {
  const {dispatch, fields, onClose, selectedField, filteredFields, filteredCropTypes} = props;

  const LangFile = useLangFile();
  const LangFileRef = useLangFileRef();
  const farm = useFarm();
  const googleMap = useGoogleMap();
  const googleMapRef = useHookRef(googleMap);
  const selectedSeason = useSelectedSeason();
  const [sections, setSections] = useState([]);
  const [exportFieldsProgress, setExportFieldsProgress] = useState({shown: false});

  const onCropsClicked = useCallback(() => {
    dispatch(saveViewport(googleMapRef.current.getCenter(), googleMap.getZoom()));
    dispatch(setViewMode(ViewModeConstants.MANAGE_CROPS));
  }, []);

  const onAddFieldsClicked = useCallback(() => {
    dispatch(setShowAddFieldsDialog(true));
  });

  const onMoreClicked = useCallback(() => {
    // console.log('onMoreClicked');
  });

  const onFieldClicked = useCallback((field) => {
    goToField(googleMapRef.current, field);
    dispatch(selectField(field));
  }, []);

  const onFieldChecked = useCallback((field, checked) => {
    dispatch(setFilteredField(field, checked));
  }, []);

  const onCropTypeChecked = useCallback((cropType, checked) => {
    dispatch(setFilteredCropType(cropType, checked));
  }, []);

  const onClearFilter = useCallback(() => {
    dispatch(resetFilters());
  }, []);

  const onExportFields = useCallback(() => {
    setExportFieldsProgress({shown: true, message: LangFileRef.current.FieldDrawer.preparingZipFile});

    let fileName = `Cropline-${farm.owner.lastName}-fields`;
    
    WebAPIUtils.downloadFieldsShapefile(selectedSeason.id, fileName, farm.name).then(() => {
      setExportFieldsProgress({shown: false});
    });
  }, [selectedSeason, farm]);

  useEffect(() => {
    const fieldsArray = getFieldsArray(fields);
    if (fieldsArray.length > 0) {
      const grouped = groupBy(fieldsArray, "crop");

      let unsortedSections = Object.keys(grouped).map((cropType, idx) => {
        return new FieldOverviewSection(idx, LangFile.CropType[cropType], grouped[cropType], cropType);
      });

      let noneSectionIdx = unsortedSections.findIndex((item) => item.cropType === CropType.NONE);
      let noneSection = unsortedSections.splice(noneSectionIdx, 1)[0];

      let sortedSections = unsortedSections.sort((a, b) => a.header - b.header);
      sortedSections.push(noneSection);

      setSections(sortedSections);
    }
  }, [fields, LangFile]);

  return (
    <>
      <FieldOverview
        selectedField={selectedField}
        filteredFields={filteredFields}
        filteredCropTypes={filteredCropTypes}
        sections={sections}
        onCloseClicked={onClose}
        onCropsClicked={onCropsClicked}
        onAddFieldsClicked={onAddFieldsClicked}
        onFieldClicked={onFieldClicked}
        onFieldChecked={onFieldChecked}
        onCropTypeChecked={onCropTypeChecked}
        onClearFilter={onClearFilter}
        onExportFields={onExportFields}
        onMoreClicked={onMoreClicked}/>

      <ProgressLoadingModal progress={exportFieldsProgress}/>
    </>
  );
};

FieldOverviewContainer.propTypes = {
  onClose: PropTypes.func
};

FieldOverviewContainer.defaultProps = {
  onClose: voidFunc
};

export default memo(connect(mapStateToProps)(FieldOverviewContainer));
