"use strict";

import ViewModeConstants from 'js/constants/ViewModeConstants';

/* ============== //
 ||     TYPES     ||
 // ============== */

const SET_VIEW_MODE = "fieldsense/Control/SET_VIEW_MODE";
const SET_GOOGLE_MAP = "fieldsense/Control/SET_GOOGLE_MAP";
const SAVE_VIEWPORT = "fieldsense/Control/SAVE_VIEWPORT";
const SET_SHOW_ADD_FIELDS_DIALOG = "fieldsense/Control/SET_SHOW_ADD_FIELDS_DIALOG";
const SET_SHOW_CREATE_SEASON_DIALOG = "fieldsense/Control/SET_SHOW_CREATE_SEASON_DIALOG";
const SET_SHOW_USER_MENU = "fieldsense/Control/SET_SHOW_USER_MENU";
const SET_SHOW_FARM_SETTINGS = "fieldsense/Control/SET_SHOW_FARM_SETTINGS";
const SET_KEYBOARD_SHORTCUTS_ENABLED = "fieldsense/ControlReducer/SET_KEYBOARD_SHORTCUTS_ENABLED";
const SET_SUPPORT_CHAT_SEEN = "fieldsense/ControlReducer/SET_SUPPORT_CHAT_SEEN";
const GO_TO_FIELDS_EVENT = "fieldsense/ControlReducer/GO_TO_FIELDS_EVENT";

/* ============== //
 ||    ACTIONS    ||
 // ============== */


export function goToFieldsEvent() {
  return {
    type: GO_TO_FIELDS_EVENT,
    payload: {} // new object
  };
}

export function setShowUserMenu(show) {
  return {
    type: SET_SHOW_USER_MENU,
    payload: show
  };
}

export function setShowAddFieldsDialog(show) {
  return {
    type: SET_SHOW_ADD_FIELDS_DIALOG,
    payload: show
  };
}

export function setShowCreateSeasonDialog(show) {
  return {
    type: SET_SHOW_CREATE_SEASON_DIALOG,
    payload: show
  };
}

export function saveViewport(center, zoom) {
  let payload = null;

  if (center && zoom) {
    payload = {center, zoom};
  }

  return {
    type: SAVE_VIEWPORT,
    payload: payload
  };
}

export function setViewMode(viewMode, activeTab) {
  return {
    type: SET_VIEW_MODE,
    payload: viewMode,
    meta: {
      activeTab: activeTab
    }
  };
}

export function setGoogleMap(googleMap) {
  return {
    type: SET_GOOGLE_MAP,
    payload: googleMap
  };
}

export function setShowFarmSettings(show) {
  return {
    type: SET_SHOW_FARM_SETTINGS,
    payload: show
  };
}

export function setKeyboardShortcutsEnabled(enabled) {
  return {
    type: SET_KEYBOARD_SHORTCUTS_ENABLED,
    payload: enabled
  };
}

export function setSupportChatSeen(seen) {
  return {
    type: SET_SUPPORT_CHAT_SEEN,
    payload: seen
  };
}

/* ============== //
 ||    REDUCER    ||
 // ============== */

const initState = {
  viewMode: ViewModeConstants.OVERVIEW,
  previousViewMode: ViewModeConstants.OVERVIEW,
  previousViewport: null,
  showAddFieldsDialog: false,
  showCreateSeasonDialog: false,
  showUserMenu: false,
  showFarmSettings: false,
  activeTab: null,
  keyboardShortcutsEnabled: true,
  supportChatSeen: true,
  goToFieldsEvent: null,
};

export default function reducer(state = initState, action) {
  switch (action.type) {

    case SET_VIEW_MODE:
      state = {
        ...state,
        previousViewMode: state.viewMode,
        viewMode: action.payload,
        activeTab: action.meta ? action.meta.activeTab : null
      };
      break;

    case SET_GOOGLE_MAP:
      state = { ...state, googleMap: action.payload };
      break;

    case SAVE_VIEWPORT:
      state = { ...state, previousViewport: action.payload };
      break;

    case SET_SHOW_ADD_FIELDS_DIALOG:
      state = { ...state, showAddFieldsDialog: action.payload };
      break;

    case SET_SHOW_CREATE_SEASON_DIALOG:
      state = { ...state, showCreateSeasonDialog: action.payload };
      break;

    case SET_SHOW_USER_MENU:
      state = { ...state, showUserMenu: action.payload };
      break;

    case SET_SHOW_FARM_SETTINGS:
      state = { ...state, showFarmSettings: action.payload };
      break;

    case SET_KEYBOARD_SHORTCUTS_ENABLED: {
      state = {...state, keyboardShortcutsEnabled: action.payload};
      break;
    }

    case SET_SUPPORT_CHAT_SEEN: {
      state = {...state, supportChatSeen: action.payload};
      break;
    }

    case GO_TO_FIELDS_EVENT: {
      state = {...state, goToFieldsEvent: action.payload};
      break;
    }

    default:
      break;
  }

  return state;
}
