import React, {
  memo,
  useCallback,
  useRef
} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Portal from "@material-ui/core/Portal";
import throttle from 'lodash.throttle';
import useEvent from "js/hooks/useEvent";

const styles = (theme) => ({
  mouseInterceptor: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 5000,
    pointerEvents: 'all',
  },
});

const MouseInterceptor = ({classes, intercept, onMouseUp, onMouseMove}) => {

  const lastX = useRef(0);

  const mouseMoveHandler = useCallback(throttle((e) => {
    if (intercept) {
      e.stopPropagation();

      if (Math.abs(lastX.current - e.x) >= 1) {
        onMouseMove(e);
        lastX.current = e.x;
      }
    }
  }, 1000/60), [intercept]);

  const mouseUpHandler = useCallback((e) => {
    onMouseUp(e);
  }, []);

  useEvent("mousemove", mouseMoveHandler);
  useEvent("mouseup", mouseUpHandler);

  const style = {
    display: intercept ? 'block' : 'none',
    cursor: intercept ? 'grabbing' : 'inherit',
  };

  return (
    <Portal>
      <div className={classes.mouseInterceptor} style={style}/>
    </Portal>
  );
};

MouseInterceptor.propTypes = {
  intercept: PropTypes.bool,
  onMouseUp: PropTypes.func,
  onMouseMove: PropTypes.func,
};

export default memo(withStyles(styles)(MouseInterceptor));