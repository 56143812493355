import React, {useContext} from "react";

const GoogleMapContext = React.createContext({});

export const GoogleMapProvider = GoogleMapContext.Provider;
export const GoogleMapConsumer = GoogleMapContext.Consumer;

export function useGoogleMap() {
  return useContext(GoogleMapContext).googleMap;
}

export function useGoogleMapDOM() {
  return useContext(GoogleMapContext).googleMapDomRef;
}