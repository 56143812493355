export const TEMPERATURE_SCALE = [
  {min: 50, max: 999, color: '#b71c1c', contrast: '#ffffff'},
  {min: 35, max: 50, color: '#c62828', contrast: '#ffffff'},
  {min: 30, max: 35, color: '#d32f2f', contrast: '#ffffff'},
  {min: 25, max: 30, color: '#e53935', contrast: '#ffffff'},
  {min: 20, max: 25, color: '#ef6c00', contrast: '#ffffff'},
  {min: 15, max: 20, color: '#fb8c00', contrast: '#37474f'},
  {min: 10, max: 15, color: '#fdd835', contrast: '#37474f'},
  {min: 5, max: 10, color: '#ffeb3b', contrast: '#37474f'},
  {min: 3, max: 5, color: '#fff176', contrast: '#37474f'},
  {min: 1, max: 3, color: '#fff9c4', contrast: '#37474f'},
  {min: 0, max: 1, color: '#e3f2fd', contrast: '#37474f'},

  {min: -1, max: 0, color: '#c5cae9', contrast: '#37474f'},
  {min: -3, max: -1, color: '#9fa8da', contrast: '#37474f'},
  {min: -15, max: -3, color: '#7986cb', contrast: '#ffffff'},
  {min: -10, max: -5, color: '#5c6bc0', contrast: '#ffffff'},
  {min: -15, max: -10, color: '#3f51b5', contrast: '#ffffff'},
  {min: -20, max: -15, color: '#3949ab', contrast: '#ffffff'},
  {min: -25, max: -20, color: '#303f9f', contrast: '#ffffff'},
  {min: -30, max: -25, color: '#283593', contrast: '#ffffff'},
  {min: -35, max: -30, color: '#1a237e', contrast: '#ffffff'},
  {min: -50, max: -35, color: '#161C67', contrast: '#ffffff'},
  {min: -999, max: -50, color: '#111653', contrast: '#ffffff'},
];

export const RAIN_SCALE = [
  {min: 30, max: 999, color: '#8e24aa', contrast: '#ffffff'},
  {min: 25, max: 30, color: '#7b1fa2', contrast: '#ffffff'},
  {min: 20, max: 25, color: '#6a1b9a', contrast: '#ffffff'},
  {min: 15, max: 20, color: '#4a148c', contrast: '#ffffff'},
  {min: 10, max: 15, color: '#0E3D87', contrast: '#ffffff'},
  {min: 6, max: 10, color: '#0d47a1', contrast: '#ffffff'},
  {min: 4, max: 6, color: '#1565c0', contrast: '#ffffff'},
  {min: 2, max: 4, color: '#1976d2', contrast: '#ffffff'},
  {min: 1, max: 2, color: '#2196f3', contrast: '#ffffff'},
  {min: 0.5, max: 1, color: '#64b5f6', contrast: '#37474f'},
  {min: 0.2, max: 0.5, color: '#90caf9', contrast: '#37474f'},
  {min: 0.1, max: 0.2, color: '#e0f7fa', contrast: '#37474f'},
  {min: 0, max: 0.1, color: '#ffffff', contrast: '#37474f'}
];

export const WIND_SCALE = [
  {min: 30, max: 999, color: '#d500f9', contrast: '#ffffff'},
  {min: 20, max: 30, color: '#aa00ff', contrast: '#ffffff'},
  {min: 18, max: 20, color: '#c62828', contrast: '#ffffff'},
  {min: 16, max: 18, color: '#d32f2f', contrast: '#ffffff'},
  {min: 14, max: 16, color: '#e53935', contrast: '#ffffff'},
  {min: 12, max: 14, color: '#ef6c00', contrast: '#ffffff'},
  {min: 10, max: 12, color: '#fb8c00', contrast: '#37474f'},
  {min: 8, max: 10, color: '#fdd835', contrast: '#37474f'},
  {min: 6, max: 8, color: '#ffeb3b', contrast: '#37474f'},
  {min: 4, max: 6, color: '#fff176', contrast: '#37474f'},
  {min: 2, max: 4, color: '#fff9c4', contrast: '#37474f'},
  {min: 0, max: 2, color: '#ffffff', contrast: '#37474f'},
];
