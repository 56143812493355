import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Showdown from "showdown";

import {
  useLangFile,
} from "js/context/LanguageContext";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
} from "@material-ui/core";

const styles = (theme) => ({
  content: {
    overflowY: 'scroll',
    '& img': {
      maxWidth: '100%',
      maxHeight: '60vh'
    },
    '&::-webkit-scrollbar': {
      "-webkit-appearance": "none",
    },
    '&::-webkit-scrollbar:vertical': {
      width: 11,
    },
    '&::-webkit-scrollbar:horizontal': {
      width: 11,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 1,
      border: '2px solid white',
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    '& img[alt="center"]': {
      display: 'block',
      margin: '0 auto'
    }
  }
});

const MarkdownArticleDialog = ({classes, article, onClose}) => {

  const LangFile = useLangFile();

  let converter = new Showdown.Converter();
  converter.setOption("parseImgDimensions", "true");
  converter.setOption("strikethrough", "true");

  let handleOnClose = () => {
    onClose(article);
  };

  if (!article) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      open={true}
      maxWidth={"sm"}
      className={classes.root}
      onClose={handleOnClose}>

      <DialogTitle>
        {article.title}
      </DialogTitle>

      <DialogContent className={classes.content}>
        <div ref={(r) => {
          if (r) {
            r.innerHTML = converter.makeHtml(article.description);
          }
        }}/>
      </DialogContent>

      <DialogActions>
        <Button variant={"outlined"} color={"primary"} onClick={handleOnClose}>{LangFile.MarkdownArticleDialog.ok}</Button>
      </DialogActions>

    </Dialog>
  );
};

MarkdownArticleDialog.propTypes = {
  article: PropTypes.object,
  onClose: PropTypes.func,
};

MarkdownArticleDialog.defaultProps = {
  onClose: () => {
  },
};

export default withStyles(styles)(MarkdownArticleDialog);