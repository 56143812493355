import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import NetworkSensor from "js/model/network/NetworkSensor";
import {getScale} from "js/helpers/WeatherNetworkUtils";

import style from 'js/components/WeatherNetwork/WeatherNetworkMarkersCanvas.module.less';
import useWindowDimensions from "js/hooks/useWindowDimensions";
import useMapEvent from "js/components/DataLayer/hooks/useMapEvent";
import throttle from 'lodash.throttle';
import clsx from "clsx";
import {getScreenXY} from "../../helpers/MapsUtils";
import {LEVEL_ZOOM_THRESHOLD} from "../../constants/WeatherNetworkConstants";
import {useHookRef} from "../../hooks/useHookRef";
import {
  getWeatherNetworkSensorUnit,
  useMeasureSettings
} from "../../context/AppSettings/AppSettingsContext";

const isIntersect = (point, circle) => {
  return Math.sqrt((point.x - circle.x) ** 2 + (point.y - circle.y) ** 2) < circle.radius;
};

const roundValue = (value) => {
  if (value === null) {
    return null;
  }
  if (value === 0.0) {
    return Number(0.0).toFixed(0);
  }
  else if (Math.abs(value) < 9.5) {
    return Number(value).toFixed(1);
  }
  else {
    return Number(Math.round(value)).toFixed(0);
  }
};

const drawCircularMarker = (ctx, point, radius, value, colorBackground, colorText, unit) => {
  let valMissing = value === null;
  let text = valMissing ? '––' : value;

  ctx.beginPath();
  ctx.save();
  ctx.arc(point.x, point.y, radius, 0, Math.PI * 2, true);
  ctx.fillStyle = colorBackground;
  ctx.shadowOffsetX = 2;
  ctx.shadowOffsetY = 4;
  ctx.shadowColor = 'rgba(0, 0, 0, 0.80)';
  ctx.shadowBlur = 6;
  ctx.fill();
  ctx.restore();

  ctx.font = "bold 16px Roboto";
  ctx.fillStyle = colorText + unit;
  ctx.textAlign = "center";
  ctx.textBaseline = 'middle';
  ctx.fillText(`${text}`, point.x, point.y + 2);
};

const invalidInterval = {color: 'rgba(0,0,0,0.4)', contrast: 'white'};
const radius = 20;

const WeatherNetworkMarkersCanvas = (props) => {
  const canvasRef = useRef(null);
  const {width, height} = useWindowDimensions();
  const measureSettings = useMeasureSettings();
  const {map, region, networkSensor, ticksPerHour, temporalEndIndex, shown, level} = props;

  const mapRef = useHookRef(map);

  let offset = ticksPerHour - 1;
  let dateIndex = temporalEndIndex - offset;

  const handleClick = useCallback((latLng) => {
    mapRef.current.setCenter(latLng);

    if (level === 1) {
      mapRef.current.setZoom(LEVEL_ZOOM_THRESHOLD);
    }
    else {
      mapRef.current.setZoom(LEVEL_ZOOM_THRESHOLD + 2);
    }
  }, [level]);

  const getValue = useCallback((cluster) => {
    switch (networkSensor) {
      case NetworkSensor.RAIN_24H: {
        return {value: roundValue(cluster.max[dateIndex]), unit: getWeatherNetworkSensorUnit(measureSettings, NetworkSensor.RAIN_24H)};
      }
      case NetworkSensor.SOIL_TEMP_24H:
      case NetworkSensor.AIR_TEMP_24H: {
        return {
          value: roundValue(cluster.median[dateIndex]),
          unit: "º" // getWeatherNetworkSensorUnit(measureSettings, NetworkSensor.AIR_TEMP_24H)
        };
      }
      case NetworkSensor.RAIN_ACC: {
        return {value: roundValue(cluster.accumulatedMax), unit: getWeatherNetworkSensorUnit(measureSettings, NetworkSensor.RAIN_24H)};
      }
      case NetworkSensor.WIND_AVG: {
        return {value: roundValue(cluster.value), unit: getWeatherNetworkSensorUnit(measureSettings, NetworkSensor.WIND_AVG)};
      }
      default:
        return roundValue(cluster.max);
    }
  }, [networkSensor, dateIndex, measureSettings]);

  const handler = useCallback(throttle(() => {
    if (!canvasRef.current) {
      return;
    }

    if (map && region && shown) {
      let scale = getScale(networkSensor, measureSettings);

      let ctx = canvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      region.clusters.filter((c) => getValue(c).value !== null).map((cluster) => {
        let lat = cluster.location.latitude;
        let lng = cluster.location.longitude;
        let latLng = new google.maps.LatLng(lat, lng);

        let point = getScreenXY(map, latLng);

        if (point.x >= 0 && point.x <= width && point.y >= 0 && point.y <= height) {
          switch (networkSensor) {
            case NetworkSensor.RAIN_24H:
            case NetworkSensor.RAIN_ACC:
            case NetworkSensor.WIND_AVG:
            case NetworkSensor.AIR_TEMP_24H:
            case NetworkSensor.SOIL_TEMP_24H: {

              let {value, unit} = getValue(cluster);
              let interval = invalidInterval;
              if (value && scale) {
                interval = scale.filter((range) => range.min <= value && value < range.max)[0] || invalidInterval;
              }

              drawCircularMarker(ctx, point, radius, value, interval.color, interval.contrast, unit);
              break;
            }
            default: {
              break;
            }
          }
        }
      });
    }
    else {
      let ctx = canvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  }, 10), [map, region, canvasRef, networkSensor, getValue, shown, measureSettings]);

  useMapEvent(map, "zoom_changed", handler);
  useMapEvent(map, "center_changed", handler);
  useMapEvent(map, "idle", handler);

  useEffect(() => {
    handler();
  }, [handler]);

  const onMapClicked = useCallback((event) => {
    let latLng = new google.maps.LatLng(event.latLng.lat(), event.latLng.lng());
    let point = getScreenXY(map, latLng);

    for (let i = 0; i < region.clusters.length; i++) {
      let cluster = region.clusters[i];
      let lat = cluster.location.latitude;
      let lng = cluster.location.longitude;
      let latLng = new google.maps.LatLng(lat, lng);

      let circle = getScreenXY(map, latLng);

      if (isIntersect(point, {...circle, radius})) {
        handleClick(latLng);
        break;
      }
    }

  }, [region, map, handleClick]);

  useMapEvent(map, "click", onMapClicked);

  return (
    <canvas ref={canvasRef} id={"network-canvas"} className={clsx({[style.Canvas]: true, [style.Hidden]: !shown})} height={height} width={width}/>
  );
};

WeatherNetworkMarkersCanvas.propTypes = {
  shown: PropTypes.bool,
  temporalEndIndex: PropTypes.number,
  region: PropTypes.object,
  networkSensor: PropTypes.string,
  level: PropTypes.number,
};

export default memo(WeatherNetworkMarkersCanvas);