// Get the source of the image for the currently selected field on the currently selected date.
import moment from "moment";

export function getSelectedImageSource(fieldId, images, date) {
  if (!fieldId) {
    return undefined;
  }

  if (images.has(date)) {
    let imagesOnDate = images.get(date);

    if (!imagesOnDate) {
      return undefined;
    }

    let img = imagesOnDate.find((img) => img.field_id === fieldId);
    if (img) {
      return img.source;
    }
  }
  return undefined;
}

// This return images for all fields for the current date
export function getImagesForDate(date, images, imageType) {
  if (images.has(date)) {
    if (images.get(date)) {
      return images.get(date).map((fieldImage) => {
        fieldImage.url = fieldImage[imageType];
        return fieldImage;
      });
    }
  }
  return [];
}


// This returns images for the date for the selected field
export function getSelectedFieldImageForDate(selectedField, date, images, imageType) {
  if (!selectedField) {
    return;
  }

  let currentImages = getImagesForDate(moment(date).format("YYYY-MM-DD"), images, imageType);

  let fieldImage = null;
  for (let i = 0; i < currentImages.length; i++) {
    let image = currentImages[i];
    if (image.fieldId === selectedField.fieldId) {
      fieldImage = image;
      break;
    }
  }
  return fieldImage;
}

export function getFieldByBundle(fields, bundleId) {
  let results = fields.filter((f) => f.bundle === bundleId);
  return results.length > 0 && results[0];
}

export function getDeviceLabel(deviceIdentifier, farmSettings) {
  let result = deviceIdentifier;
  let globals = farmSettings && farmSettings.global;

  if (globals) {
    Object.keys(globals).forEach((setting) => {
      if (setting === "weather.device." + deviceIdentifier + ".alias") {
        result = globals[setting].toString();
      }
    });
  }

  return result;
}

export function getFieldName(field, LangFile) {
  let name = null;
  let mef = null;

  if (field) {
    name = field.name;
    mef = field.mef;
  }

  return name || mef || LangFile.StateInterpreters.unnamedField;
}

export function getFieldSize(field) {
  return field ? field.size : 0;
}
