import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Box from "@material-ui/core/Box";

import Style from "./MapFloatPanel.module.less";

const MapFloatPanel = (props) => {

  const {children, ...rest} = props;

  return (
    <Box className={Style.Root} {...rest}>
      {children}
    </Box>
  );
};

MapFloatPanel.propTypes = {
  zIndex: PropTypes.number,
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
};

export default memo(MapFloatPanel);