"use strict";

import React, {memo} from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from "prop-types";

const SurplusLarge = (props: SurplusLarge.propTypes) => {
  return (
    <SvgIcon
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12">

      <g transform="translate(-0.471 -996)">
        <g transform="translate(0.471 996)">
          <rect width="12" height="12" fill={props.htmlColor}/>
        </g>
      </g>
      
    </SvgIcon>
  );
};

SurplusLarge.propTypes = {
  htmlColor: PropTypes.string,
};

SurplusLarge.defaultProps = {
  htmlColor: "#4CCC47",
};

export default memo(
  SurplusLarge
);