import {Box, Button, Divider, Typography} from '@material-ui/core';
import React, {Fragment, memo} from 'react';
import {useLangFile} from '../../context/LanguageContext';
import PropTypes from 'prop-types';
import {voidFunc} from '../../constants/PropTypeUtils';
import Styles from './DataIntegrationDialogContent.module.less';
import {DeleteForever} from '@material-ui/icons';

const DataIntegrationDialogContent = (props: DataIntegrationDialogContent.propTypes) => {
  const {dataIntegrations, onConnect, onDelete, onClose} = props;

  const LangFile = useLangFile();

  const connected = dataIntegrations.filter((integration) => integration.connected === true);
  const disconnected = dataIntegrations.filter((integration) => integration.connected === false);
  return (
    <Fragment>
      <Box
        pt={3}
        pb={1}
        display={"flex"}
        flexDirection={"column"}>
        <Typography align={'center'} className={Styles.title}>
          {LangFile.DataIntegrationDialog.title}
        </Typography>
        <Box my={1}/>
        <Typography align={'center'} className={Styles.content}>
          {LangFile.DataIntegrationDialog.description}
        </Typography>
      </Box>
      <Box py={1}>
        <Divider/>
        <Box my={1}>
          {connected.length === 0 && (
            <Typography align={'center'} className={Styles.italic}>
              {LangFile.DataIntegrationDialog.noIntegrations}
            </Typography>
          )}
          {connected.map((integration, idx) => {
            return <Box key={integration.name+idx} py={1} px={0.5} display={'flex'} bgcolor={'#ECF1D9'} flexDirection={'row'} justifyContent={'flex-end'}>
              <Box style={{width: '24px'}}/>
              <Box align={'center'} flex={6}>
                <Typography className={Styles.itemSubtitle}>
                  {LangFile.DataIntegrationDialog.connected}
                </Typography>
                <Typography className={Styles.itemTitle}>
                  {integration.name}
                </Typography>
              </Box>
              <Button
                className={Styles.delete}
                onClick={() => {
                  onDelete(integration);
                }}>
                <Box display={'flex'} alignItems={'center'}>
                  <DeleteForever style={{height: 20}}/>
                </Box>
              </Button>
            </Box>;
          })}
        </Box>
        <Divider/>
      </Box>
      <Box mt={0.5} display={'flex'} flexDirection={'column'} justifyItems={'center'}>
        {disconnected.map((integration, idx) => {
          return <Box key={integration.name+idx} py={0.5} display={'flex'} flexDirection={'column'}>
            <Button
              variant={"contained"}
              style={{
                backgroundColor: "#F1F2F2",
                fontSize: "18px"
              }}
              onClick={() => {
                onConnect(integration);
              }}>
              <Box>
                <Typography align={'center'} className={Styles.itemSubtitle}>
                  {LangFile.DataIntegrationDialog.connect}
                </Typography>
                <Typography align={'center'} className={Styles.itemTitle}>
                  {integration.name}
                </Typography>
              </Box>
            </Button>
          </Box>;
        })}
        <Box pt={0.5} align={'center'}>
          <Button
            onClick={onClose}
            disableRipple
            className={Styles.cancel}>
            {LangFile.DataIntegrationDialog.cancel}
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

DataIntegrationDialogContent.propTypes = {
  dataIntegrations: PropTypes.array,
  onConnect: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};

DataIntegrationDialogContent.defaultProps = {
  dataIntegrations: [],
  onConnect: voidFunc,
  onClose: voidFunc,
  onDelete: voidFunc
};

export default memo(DataIntegrationDialogContent);