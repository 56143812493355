import React, {
  memo,
  useCallback, useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';

import MapPolygon from 'js/components/MapObjects/MapPolygon';
import MapPopup from "js/components/MapObjects/MapPopup";

import {
  polygon,
  centerOfMass,
  flip
} from "@turf/turf/turf.es";
import {voidFunc} from "js/constants/PropTypeUtils";
import {blue, green} from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import {useLangFile} from "../../context/LanguageContext";

const getCenter = (coordinates) => {
  let coords = [coordinates.map((p) => [p.lng, p.lat])];
  if (isValidField(coords)) {
    return undefined;
  }

  let poly = polygon(coords);
  let center = flip(centerOfMass(poly));
  let position = center.geometry.coordinates;

  return {lat: position[0], lng: position[1]};
};

const isValidField = (coords) => {
  if (!coords) {
    return false;
  }
  const latLngs = coords[0];
  return latLngs.length < 4 || latLngs[0][0] !== latLngs[latLngs.length-1][0] || latLngs[0][1] !== latLngs[latLngs.length-1][1];
};

const MapFarmFieldConnect = (props: MapFarmFieldConnect.propTypes) => {

  const LangFile = useLangFile();
  const [hovered, setHovered] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const [centerPosition, setCenterPosition] = useState(null);

  useEffect(() => {
    let c = props.field.polygon.coordinates[0].map((coord) => {
      return {
        lng: coord[0],
        lat: coord[1]
      };
    });

    setCoordinates(c);
    setCenterPosition(getCenter(c));
  }, [props.field]);

  const onClick = useCallback(() => {
    props.onClick(props.field);
  }, [props.onClick, props.field]);

  const onMouseEnter = useCallback(() => {
    setHovered(true);
    props.onMouseEnter();
  }, [props.onMouseEnter]);

  const onMouseLeave = useCallback(() => {
    setHovered(false);
    props.onMouseLeave();
  }, [props.onMouseLeave]);

  const defaultOutlineStrokeColor = blue["500"];
  const selectedOutlineStrokeColor = green["500"];

  let color = (hovered || props.selected) ? selectedOutlineStrokeColor : defaultOutlineStrokeColor;
  let opacity = props.selected ? 0.6 : 0.01;

  if (hovered) {
    opacity = props.selected ? 0.4 : 0.6;
  }

  if (props.highlighted) {
    color = blue["A700"];
  }

  const childProps = {
    ...props,
    coordinates: coordinates,
    onClick: onClick,
    onMouseEnter: onMouseEnter,
    onMouseLeave: onMouseLeave,
    strokeColor: color,
    fillColor: color,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillOpacity: opacity
  };

  return coordinates ? (
    <MapPolygon {...childProps}>
      {hovered && centerPosition && (
        <MapPopup
          hasPin
          shown={true}
          selected={false}
          position={centerPosition}>
          <Box p={1}>
            {LangFile.MapFarmFieldConnect.connectToCurrentSeason}
          </Box>
        </MapPopup>
      )}
    </MapPolygon>
  ) : null;
};

MapFarmFieldConnect.propTypes = {
  field: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  selected: PropTypes.bool,
  highlighted: PropTypes.bool,
};

MapFarmFieldConnect.defaultProps = {
  onMouseEnter: voidFunc,
  onMouseLeave: voidFunc,
  onClick: voidFunc,
};

export default memo(MapFarmFieldConnect);