"use strict";

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
    let prop = { props, viewBox: "0 0 24 24" };
    return (
        <SvgIcon {...prop}>
          <path transform="matrix(1,0,0,1,2214.999,3936.088)" d="M -2193.0125 -3924.4896 C -2193.0167 -3924.4896 -2193.166 -3924.4903 -2193.432 -3924.4915 L -2194.2266 -3924.495 C -2194.1497 -3924.7027 -2194.1104 -3924.9207 -2194.1104 -3925.144 C -2194.1104 -3926.1217 -2194.8883 -3926.9473 -2195.809 -3926.9473 L -2197.1558 -3926.9473 C -2197.147 -3927.0413 -2197.1392 -3927.101 -2197.1329 -3927.1495 L -2197.1319 -3927.1568 C -2197.1226 -3927.2274 -2197.1168 -3927.2698 -2197.1168 -3927.3577 C -2197.1124 -3928.6783 -2198.0218 -3929.7947 -2199.3282 -3930.0728 C -2199.55 -3930.1199 -2199.7747 -3930.1439 -2199.996 -3930.1439 C -2200.482 -3930.1439 -2200.963 -3930.0269 -2201.3873 -3929.8062 C -2202.1363 -3930.8824 -2203.336 -3931.545 -2204.6802 -3931.6246 C -2204.8707 -3931.6497 -2205.0469 -3931.6619 -2205.2188 -3931.6619 L -2205.2369 -3931.6619 L -2205.2547 -3931.6617 C -2204.4927 -3932.4029 -2203.4869 -3932.8104 -2202.4229 -3932.8104 C -2202.251 -3932.8104 -2202.0748 -3932.798 -2201.8839 -3932.773 C -2200.54 -3932.6934 -2199.3394 -3932.0306 -2198.5904 -3930.9547 C -2198.1666 -3931.1756 -2197.685 -3931.292 -2197.1995 -3931.292 C -2196.9776 -3931.292 -2196.7528 -3931.268 -2196.5316 -3931.2213 C -2195.225 -3930.944 -2194.316 -3929.8277 -2194.3199 -3928.5064 C -2194.3204 -3928.419 -2194.326 -3928.3763 -2194.3355 -3928.3052 C -2194.3419 -3928.2564 -2194.3497 -3928.1954 -2194.3594 -3928.0955 L -2193.0125 -3928.0955 C -2192.561 -3928.0955 -2192.1343 -3927.905 -2191.811 -3927.5596 C -2191.4903 -3927.216 -2191.3135 -3926.766 -2191.3135 -3926.2928 C -2191.3135 -3925.8189 -2191.4903 -3925.369 -2191.811 -3925.0257 C -2192.134 -3924.68 -2192.5606 -3924.4896 -2193.0125 -3924.4896 Z " fill="#e4e4e4"/>
          <path transform="matrix(1,0,0,1,0,-202.0061)" d="M 19.255082 213.66568 C 19.204482 213.66568 17.328345 213.66568 17.328345 213.66568 C 17.361954 213.30487 17.384362 213.27391 17.385088 213.05809 C 17.390872 211.1334 16.103848 209.45188 14.221207 209.03765 C 13.148143 208.80173 12.123878 208.97595 11.276341 209.4328 C 10.242315 207.89635 8.5291819 206.86 6.565578 206.74025 C 6.3129465 206.70558 6.057059 206.685 5.795029 206.685 C 2.594643 206.685 -.00000022793857 209.35805 -.00000022793857 212.65325 C -.00000022793857 214.37042 .70874676 215.91448 1.8363811 217.00313 C 2.8335424 218.10768 4.2181479 218.82137 5.857555 218.94011 C 5.857555 218.94011 19.204482 219.00608 19.255082 219.00608 C 20.597399 219.00608 21.685278 217.77892 21.685278 216.33557 C 21.685278 214.89295 20.597037 213.66568 19.255082 213.66568 Z " fill="#e4e4e4"/>
        </SvgIcon>
    );
};

