import PropTypes from "prop-types";
import Styles from "./FieldIntegrationMappingSelector.module.less";
import Box from "@material-ui/core/Box";
import {
  ListItem,
  ListItemText,
} from "@material-ui/core";
import {useLangFile} from "../../context/LanguageContext";
import React, {memo, useCallback} from "react";
import {getFieldName} from "js/helpers/StateInterpreters";

const FieldIntegrationMappingSelectorListItem = (props: FieldIntegrationMappingSelectorListItem.propTypes) => {
  const LangFile = useLangFile();

  const {fieldItem, isSelected, onClick} = props;

  const handleListItemClick = useCallback(() => {
    onClick(fieldItem.field);
  }, [onClick, fieldItem]);

  const confidenceColor = (confidence) => {
    switch (true) {
      case confidence > 80: return '#00c853';
      case confidence > 60: return '#aeea00';
      case confidence > 40: return '#ffd740';
      case confidence > 20: return '#ff7043';
      default: return '#c62828';
    }
  };

  return (
    <ListItem
      style={{padding: 0}}
      dense={true}
      button={true}
      onClick={() => handleListItemClick()}
      selected={isSelected}>
      <ListItemText
        classes={{
          root: Styles.ListItemText
        }}
        disableTypography={true}
        primary={
          <Box width={'100%'} height={'30px'} display={'flex'} flexDirection={'row'}>
            <Box display={'flex'} alignItems={'center'} width={'25%'} height={'100%'} style={{color: confidenceColor(fieldItem.confidence)}} className={Styles.item}>{fieldItem.confidence + '%'}</Box>
            <Box height={'100%'} width={'1px'} bgcolor={'#a7a9ac'}/>
            <Box display={'flex'} alignItems={'center'} height={'100%'} style={{marginLeft: '6px'}} className={Styles.item}>{getFieldName(fieldItem.field, LangFile)}</Box>
          </Box>}/>
    </ListItem>
  );
};

FieldIntegrationMappingSelectorListItem.propTypes = {
  fieldItem: PropTypes.object,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default memo(FieldIntegrationMappingSelectorListItem);