import React, {
  memo,
} from 'react';

import {grey} from "@material-ui/core/colors";
import moment from "moment-timezone";
import {useGraph} from "js/context/GraphContext";
import {
  ChartType,
  WeatherSensorDecimals
} from "../../../constants/WeatherConstants";
import {useLanguage} from "../../../context/LanguageContext";
import {
  isBarIncomplete,
  parseResolution
} from "../../../helpers/WeatherUtils";
import Box from "@material-ui/core/Box";
import {Paper} from "@material-ui/core";

const formatName = (point, dataset, language) => {
  if (dataset.chartType === ChartType.BAR) {
    if (!dataset.resolution) {
      return moment(point.x).locale(language).format('LT');
    }

    let {count, unitOfTime} = parseResolution(dataset.resolution);

    if (unitOfTime === "h" || unitOfTime === "m") {
      let start = moment(point.x).format("LLL");
      let end = moment(point.x).add(count, unitOfTime).format("LT");
      return `${start} - ${end}`;
    }
    else if (unitOfTime === "d") {
      return moment(point.x).locale(language).format('LL');
    }
    else if (unitOfTime === "w") {
      let start = moment(point.x).locale(language).format('LL');
      let end = moment(point.x).add(count, unitOfTime).locale(language).format('LL');
      return `${start.substring(0, start.length - 4)} - ${end.substring(0, end.length - 5)}`;
    }
    else {
      return moment(point.x).locale(language).format('LL');
    }
  }
  else {
    return moment(point.x).locale(language).format('LLL');
  }
};

const formatIncompleteName = (point, dataset) => {
  let pattern = "HH:mm";

  if (dataset.resolution) {
    let {unitOfTime} = parseResolution(dataset.resolution);

    if (unitOfTime === "m" || unitOfTime === "h" || unitOfTime === "d") {
      pattern = "HH:mm";
    }
    else if (unitOfTime === "w") {
      pattern = "DD/MM";
    }
  }

  let start = moment(point.x).format(pattern);
  let end = moment(dataset.until).format(pattern);

  return `${start} - ${end}`;
};

import Styles from "./GraphTooltip.module.less";
import {AnalysisDecimals} from "js/constants/NdviAnalysisConstants";

const GraphTooltip = ({focus, getColor}) => {

  const {xMap, datasets, margin} = useGraph();
  const language = useLanguage();

  let rainBarFocus = focus.rain || focus.rainDay || focus.rainWeek;
  let genericFocus = Object.values(focus)[0];
  let xPoint = rainBarFocus || genericFocus;

  let xPos = xPoint.x;
  let offsetX = xMap(xPos);
  let offsetY = margin.top;

  let customColor = typeof getColor === "function";

  return (
    <foreignObject x={0} y={0} width="100%" height="100%" transform={`translate(${offsetX},${offsetY})`} className={Styles.ForeignObject}>
      <Box display={"inline-block"} position={"relative"} className={Styles.Root}>
        <Paper elevation={4}>
          <Box display={"flex"} flexDirection={"column"} p={1}>
            {Object.keys(datasets).map((key, idx) => {

              let dataset = datasets[key];
              let point = focus[key];

              if (!dataset.showTooltip || !point || point.y === null) {
                return null;
              }

              let decimals = WeatherSensorDecimals[key] || AnalysisDecimals[key];
              let value = point.y.toFixed(decimals);

              let name = formatName(point, dataset, language);

              if (dataset.chartType === ChartType.BAR) {
                if (isBarIncomplete(point.x, dataset.until, dataset.resolution)) {
                  name = formatIncompleteName(point, dataset);
                }
              }

              return (
                <Box key={`tooltip-${dataset.sensor}-${idx}`} display={"flex"} className={Styles.Row}>
                  <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    <Box bgcolor={customColor ? getColor(point.y, dataset) : dataset.color} borderRadius={"50%"} width={12} height={12} mr={1}/>

                    <Box pr={2}>
                      {name}:
                    </Box>
                  </Box>

                  <Box color={grey["900"]} display={"inline-flex"}>
                    <Box minWidth={24} mr={0.5} textAlign={"right"}><b>{value}</b></Box> {dataset.unit}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Paper>
      </Box>
    </foreignObject>
  );
};

export default memo(GraphTooltip);