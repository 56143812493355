"use strict";

import areaCalculation from "js/algorithms/geometry/AreaCalculation";

export default class Polygons {

   static getOutline(polygon) {
    let outline = {};
    let coords = polygon;

    if (coords.length === 1) {
      coords = coords[0];
    }

    coords = coords.map((coord) => {
      return {
        lat: coord[1],
        lng: coord[0]
      };
    });

    if (coords.length > 2) {
      outline.mef = "";
      outline.size = areaCalculation({coords: coords}).size;
      outline.id = 0;
      outline.coordinates = coords;
    }

    return outline;
  }

  /**
   * Maps the GoogleMaps zoom-level to a snap-distance in kilometers.
   * These values have been extracted manually through trial-and-error testing with the drawing-manager.
   * @param {number} zoom the Google Maps zoom level.
   * @returns {number} a distance/radius in kilometers used for snapping marker clicks.
   */
  static getSnapDistance(zoom) {
    switch (zoom) {
       case 20: return 0.001;
       case 19: return 0.002;
       case 18: return 0.005;
       case 17: return 0.01;
       case 16: return 0.02;
       case 15: return 0.05;
       case 14: return 0.1;
       case 13: return 0.2;
       case 12: return 0.5;
       case 11: return 1;
       case 10: return 2;
       case 9: return 4;
       case 8: return 7;
       case 7: return 14;
       case 6: return 28;
       case 5: return 56;
       case 4: return 112;
       case 3: return 224;
       case 2: return 500;
       case 1: return 1000;
       default: return 1000;
    }
  }

}
