import React, {useState} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Grid,
  Box,
} from "@material-ui/core";

import {useLangFile} from "js/context/LanguageContext";

import SwipeableViews from "react-swipeable-views";
import TextFormControl from "js/components/UI-Elements/TextFormControl";
import {
  LAT_REGEX,
  LNG_REGEX
} from "js/constants/RegexConstants";

const styles = (theme) => ({
  view: {
    height: "100%",
    width: "100%",
  },
  actions: {
    width: "100%",
    boxSizing: 'border-box',
    '& > div': {
      width: "100%",
    }
  },
  titleRow: {
    justifyContent: 'flex-start',
  },
  actionRow: {
    padding: [[theme.spacing(1), theme.spacing(2)]],
  },
  slider: {
    overflow: "hidden !important",
    boxSizing: "border-box",
    width: "100%",
  },
  proceedButton: {
    marginLeft: theme.spacing(1),
  }
});

const GpsErrorDialog = ({classes, onClose, onFix, open, onSelectOnMap}) => {

  const LangFile = useLangFile();
  const [viewIndex, setViewIndex] = useState(0);

  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [error, setError] = useState(undefined);

  const onProceed = () => setViewIndex(viewIndex + 1);
  const onBack = () => setViewIndex(viewIndex - 1);

  return (
    <Dialog fullWidth maxWidth={'sm'} open={open} onEscapeKeyDown={onClose} onBackdropClick={onClose}>
      <DialogTitle>
        <SwipeableViews
          axis={"x"}
          index={viewIndex}
          slideClassName={classes.slider}>
          <div className={classes.titleRow}>
            {LangFile.GpsErrorDialog.title1}
          </div>
          <div className={classes.titleRow}>
            {LangFile.GpsErrorDialog.title2}
          </div>
          <div className={classes.titleRow}>
            {LangFile.GpsErrorDialog.title3}
          </div>
        </SwipeableViews>
      </DialogTitle>

      <DialogContent>
        <SwipeableViews
          axis={"x"}
          index={viewIndex}
          slideClassName={classes.slider}>
          <div className={classes.view}>
            <Typography>{LangFile.GpsErrorDialog.text1}</Typography>
            <Typography>{LangFile.GpsErrorDialog.text2}</Typography>
            <br/>
            <Typography>{LangFile.GpsErrorDialog.text3}</Typography>
          </div>

          <div className={classes.view}>

            <Box mb={2}>
              <Button
                fullWidth
                variant={"contained"}
                color={"primary"}
                onClick={onProceed}>
                {LangFile.GpsErrorDialog.inputCoordinates}
              </Button>
            </Box>

            <Button
              fullWidth
              variant={"contained"}
              color={"primary"}
              onClick={onSelectOnMap}>
              {LangFile.GpsErrorDialog.selectOnMap}
            </Button>

          </div>
          <div className={classes.view}>

            <TextFormControl
              title={LangFile.GpsErrorDialog.latitude}
              label={LangFile.GpsErrorDialog.latitudeLabel}
              disablePadding
              value={latitude}
              regexValidation={LAT_REGEX}
              placeholder={LangFile.GpsErrorDialog.latitudePlaceholder}
              onError={setError}
              type={"number"}
              onUpdate={setLatitude}/>

            <TextFormControl
              title={LangFile.GpsErrorDialog.longitude}
              label={LangFile.GpsErrorDialog.longitudeLabel}
              disablePadding
              value={longitude}
              regexValidation={LNG_REGEX}
              placeholder={LangFile.GpsErrorDialog.longitudePlaceholder}
              onError={setError}
              type={"number"}
              onUpdate={setLongitude}/>

          </div>
        </SwipeableViews>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <SwipeableViews
          axis={"x"}
          index={viewIndex}
          slideClassName={classes.slider}>
          <Grid container justify={"space-between"} className={classes.actionRow} style={{visibility: viewIndex === 0 ? "visible" : "hidden"}}>
            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={onClose}>
              {LangFile.GpsErrorDialog.close}
            </Button>
            <Button
              variant={"contained"}
              color={"primary"}
              className={classes.proceedButton}
              onClick={onFix}>
              {LangFile.GpsErrorDialog.fixError}
            </Button>
          </Grid>
          <Grid container justify={"space-between"} className={classes.actionRow} style={{visibility: viewIndex === 1 ? "visible" : "hidden"}}>
            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={onBack}>
              {LangFile.GpsErrorDialog.back}
            </Button>
            <div/>
          </Grid>
          <Grid container justify={"space-between"} className={classes.actionRow} style={{visibility: viewIndex === 2 ? "visible" : "hidden"}}>
            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={onBack}>
              {LangFile.GpsErrorDialog.back}
            </Button>
            <Button
              variant={"contained"}
              color={"primary"}
              className={classes.proceedButton}
              disabled={error}
              onClick={onProceed}>
              {LangFile.GpsErrorDialog.save}
            </Button>
          </Grid>
        </SwipeableViews>

      </DialogActions>
    </Dialog>
  );
};

GpsErrorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onFix: PropTypes.func,
};

export default withStyles(styles)(GpsErrorDialog);