// Import the application configuration aliased in webpack config for the given node environment (dev or prod)

class APIConstants {
  constructor() {
    this.baseURL = "/api/v2/";
    this.imagesBaseURL = "/images/rasters/new/";

    this.enableAnalytics = this.getBooleanProperty(process.env.REACT_APP_ENABLE_ANALYTICS);
    this.applyModelStateLogger = this.getBooleanProperty(process.env.REACT_APP_APPLY_MODEL_STATE_LOGGER);

    this.datafordelerToken = process.env.REACT_APP_DATAFORDELER_TOKEN;
    this.mapKey = process.env.REACT_APP_MAPKEY;

    this.firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };
  }

  getBooleanProperty = (key) => {
    if (key === "false") {
      return false;
    }

    if (key === "true") {
      return true;
    }

    else {
      return Boolean(key);
    }
  };
}

export default new APIConstants;
