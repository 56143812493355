import React, {memo, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {voidFunc} from "../../../constants/PropTypeUtils";

const Canvas = (props: Canvas.propTypes) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    if (context && canvas) {
      props.onDraw(context, canvas);
    }
  }, props.drawDependencies);

  return (
    <canvas ref={canvasRef}/>
  );
};

Canvas.propTypes = {
  /**
   * Canvas onDraw callback function
   * @param {CanvasRenderingContext2D} context The context to draw in
   * @param {HTMLCanvasElement} canvas The context to draw in
   */
  onDraw: PropTypes.func,
  drawDependencies: PropTypes.array,
};

Canvas.defaultProps = {
  drawDependencies: [],
  onDraw: voidFunc,
};

export default memo(
  Canvas
);
