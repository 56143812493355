import React, {
  memo,
  useCallback,
  useState,
} from 'react';
import PropTypes from "prop-types";
import PrescriptionDownloader from "js/components/Prescription/Downloading/PrescriptionDownloader";
import debounce from "lodash.debounce";
import {
  downloadISOXMLPromise,
  downloadShapefilePromise
} from "js/reducers/PrescriptionReducer";
import {useFarm} from "js/context/AccountContext";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../hooks/useFirebaseAnalytics";
import ViewModeConstants from "../../constants/ViewModeConstants";
import {useSurveyContext} from "../../context/SurveyContext";

const PrescriptionJobDownloadContainer = ({savingJob, savingError, downloadJob, onBack, viewMode}) => {

  const analytics = useFirebaseAnalytics();
  const farm = useFarm();

  const [downloadLoading, setDownloadLoading] = useState(null);
  const [downloadError, setDownloadError] = useState(null);

  const {surveys} = useSurveyContext();

  const handleDownloadShapefile = useCallback(debounce((format) => {
    if (viewMode === ViewModeConstants.PRESCRIPTION) {
      analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_DOWNLOAD_SHAPEFILE, {format: format});
    }
    else if (viewMode === ViewModeConstants.PRESCRIPTION_ARCHIVE) {
      analytics.logEvent(FIREBASE_EVENTS.VRM_ARCHIVE_DOWNLOAD_SHAPEFILE, {format: format});
    }

    setDownloadLoading(true);

    let survey = surveys.find((s) => s.fieldId === downloadJob.fieldId);
    downloadShapefilePromise(downloadJob, farm, survey, format)
      .then(() => {
        setDownloadLoading(false);
        setDownloadError(null);
      })
      .catch((e) => {
        setDownloadLoading(false);
        setDownloadError(e);
        console.error(e);
      });
  }, 200), [downloadJob, farm, viewMode, surveys]);

  const handleDownloadISOXML = useCallback(debounce((format) => {
    if (viewMode === ViewModeConstants.PRESCRIPTION) {
      analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_DOWNLOAD_ISOXML);
    }
    else if (viewMode === ViewModeConstants.PRESCRIPTION_ARCHIVE) {
      analytics.logEvent(FIREBASE_EVENTS.VRM_ARCHIVE_DOWNLOAD_ISOXML);
    }

    setDownloadLoading(true);

    let survey = surveys.find((s) => s.fieldId === downloadJob.fieldId);

    downloadISOXMLPromise(downloadJob, farm, survey, format)
      .then(() => {
        setDownloadLoading(false);
        setDownloadError(null);
      })
      .catch((e) => {
        setDownloadLoading(false);
        setDownloadError(e);
      });
  }, 200), [downloadJob, viewMode]);

  return (
    <PrescriptionDownloader
      downloadJob={downloadJob}
      downloadError={downloadError}
      loading={downloadLoading}
      savingJob={savingJob}
      savingError={savingError}
      onBack={onBack}
      downloadShapefile={handleDownloadShapefile}
      downloadISOXML={handleDownloadISOXML}/>
  );
};

export default memo(PrescriptionJobDownloadContainer);

PrescriptionJobDownloadContainer.propTypes = {
  savingJob: PropTypes.bool,
  savingError: PropTypes.bool,
  loading: PropTypes.bool,
  downloadJob: PropTypes.object,
  viewMode: PropTypes.string,
};
