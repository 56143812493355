"use strict";

import React, {memo, useCallback} from 'react';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import FarmInfoList from './FarmInfoList';
import FarmSettingsList from './FarmSettingsList';
import FarmUserTable from './FarmUserTable';
import {
  DialogActions,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import {useLangFile} from "js/context/LanguageContext";
import {useIsAdmin} from "js/context/AccountContext";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../hooks/useFirebaseAnalytics";

const styles = (theme) => ({
  paper: {
   width: '100%',
  },
  modalBody: {
    padding: [[theme.spacing(3), theme.spacing(1)]],
  },
});

const FarmSettings = ({classes, shown, farm, fields, language, userFarmSettings, updateFarmSetting, onDismiss, updateFarmName, addUser, removeUser}) => {

  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();
  const isAdmin = useIsAdmin();

  const handleAddUser = useCallback((userObject) => {
    analytics.logEvent(FIREBASE_EVENTS.MM_ACCOUNT_ADD_USER);
    addUser(userObject);
  }, [addUser]);

  const handleRemoveUser = useCallback((id) => {
    analytics.logEvent(FIREBASE_EVENTS.MM_ACCOUNT_REMOVE_USER);
    removeUser(id);
  }, [removeUser]);

  return (
    <Dialog open={Boolean(shown)} maxWidth={'lg'} classes={{paper: classes.paper}}>
      <DialogTitle>
        {LangFile.FarmSettings.title}
      </DialogTitle>
      <DialogContent>

        <Grid container className={classes.modalBody}>
          <Grid item container spacing={2}>
            <Grid item xs={6} className={classes.column}>
              <FarmInfoList
                farm={farm}
                fields={fields}
                language={language}
                LangFile={LangFile}
                updateFarmName={updateFarmName}/>
            </Grid>
            <Grid item xs={6}>
              <FarmSettingsList
                userFarmSettings={userFarmSettings}
                updateFarmSetting={updateFarmSetting}
                LangFile={LangFile}/>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FarmUserTable
              LangFile={LangFile}
              farm={farm}
              onDismiss={onDismiss}
              isAdmin={isAdmin}
              addUser={handleAddUser}
              removeUser={handleRemoveUser}/>
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss}>{LangFile.FarmSettings.close}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(withStyles(styles)(FarmSettings));
