import PropTypes from 'prop-types';
import React, {memo, useCallback, useEffect, useState} from 'react';
import {Box, Fab, Typography} from '@material-ui/core';
import Styles from './FieldIntegrationMappingCell.module.less';
import {Link} from '@material-ui/icons';
import QuestionMark from 'style/images/icons/question_mark_red.svg';
import FieldIntegrationMappingSelector from './FieldIntegrationMappingSelector';
import {voidFunc} from '../../constants/PropTypeUtils';
import {fieldToTurfPolygon} from '../../helpers/MapsUtils';
import * as turf from '@turf/turf';
import {MAPPING_ACTION} from './FieldIntegrationMappingContainer';
import moment from 'moment';

const FieldIntegrationMappingCell = (props: FieldIntegrationMappingCell.propTypes) => {
  const {workbook, fields, link, onSelectLink, onUnlink, selectBestMatch} = props;
  const [fieldItems, setFieldItems] = useState([]);

  const handleUnlink = useCallback(() => {
    onUnlink(workbook.id);
  }, [workbook]);

  const handleOnSelectField = useCallback((field) => {
    onSelectLink(workbook.id, field);
  }, [onSelectLink]);

  const selectedField = link && link.type !== MAPPING_ACTION.UNLINK ? fields.find((field) => {
    return field.fieldId === link.fieldId;
  }) : null;

  const color = selectedField ? '#000' : 'red';

  const calculateConfidence = (workbookPolygon, fieldPolygon) => {
    try {
      const intersection = turf.intersect(workbookPolygon, fieldPolygon);
      if (intersection) {
        const intersectionPolygon = turf.polygon(intersection.geometry.coordinates);
        const intersectionArea = turf.area(intersectionPolygon);
        const workbookPolygonArea = turf.area(workbookPolygon);
        return (intersectionArea / workbookPolygonArea) * 100;
      }
    }
    catch (error) {
      // console.log('Intersect error: ', error);
    }
    return 0;
  };

  useEffect(() => {
    if (workbook.location) {
      let workbookPolygon = turf.polygon(workbook.location.coordinates);
      let items = fields.map((field) => {
        return {
          field: field,
          confidence: calculateConfidence(workbookPolygon, fieldToTurfPolygon(field)).toFixed(0)
        };
      }).sort((lhs, rhs) => {
        return rhs.confidence - lhs.confidence;
      });

      setFieldItems(items);

      if (selectBestMatch) {
        let defaultSelection = items.find(({confidence}) => confidence >= 90);
        if (defaultSelection) {
          onSelectLink(workbook.id, defaultSelection.field);
        }
      }
    }
    else {
      setFieldItems(fields.map((field) => {
        return {
          field: field,
          confidence: 0
        };
      }));
    }
  }, [fields, workbook, selectBestMatch]);

  return (
    <Box flex={1} display={'flex'} flexDirection={'row'}>
      <Box className={Styles.box} flex={3} height={'80px'} display={'flex'} flexDirection={'row'} width={210}>
        <Box display={'flex'} flexDirection={'column'} p={1}>
          <Typography className={Styles.text} style={{fontWeight: 500}}>{workbook.location.name}</Typography>
          <Typography className={Styles.text}>{moment(workbook.workStart).format("DD-MM-YYYY")}</Typography>
          <Typography className={Styles.text}>{workbook.cropType}</Typography>
          <Typography className={Styles.text}>{workbook.location.area.toFixed(2) + ' ha'}</Typography>
        </Box>
      </Box>

      <Box flex={2} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
        <Box bgcolor={color} width={'33%'} height={'1px'}/>

        <Fab variant={"extended"} size={"small"} className={Styles.center} style={{border: `1px solid ${color}`}} onClick={handleUnlink} disabled={!selectedField}>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
            {selectedField && (
              <Link />
            )}
            {!selectedField && (
              <img src={QuestionMark} width={16} height={16} alt={''}/>
            )}
          </Box>
        </Fab>

        <Box bgcolor={color} width={'33%'} height={'1px'}/>
      </Box>

      <Box className={Styles.box} style={{border: `1px solid ${color}`}} flex={3} height={'80px'} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
        <FieldIntegrationMappingSelector fieldItems={fieldItems} selectedField={selectedField} onSelectField={handleOnSelectField}/>
      </Box>
    </Box>
  );
};

FieldIntegrationMappingCell.propTypes = {
  workbook: PropTypes.object,
  fields: PropTypes.array,
  link: PropTypes.object,
  selectBestMatch: PropTypes.bool,
  onSelectLink: PropTypes.func,
  onUnlink: PropTypes.func
};

FieldIntegrationMappingCell.defaultProps = {
  onSelectLink: voidFunc,
  onUnlink: voidFunc
};

export default memo(FieldIntegrationMappingCell);
