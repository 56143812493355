"use strict";

import React, {
  Fragment,
  useCallback,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';

import ManualFeatureConstants from "js/constants/ManualFeatureConstants";
import {
  Button,
  Dialog,
  TextField,
  DialogContent,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";

import {
  getDeviceLabel,
  getFieldName,
  getFieldSize
} from "js/helpers/StateInterpreters";

import {useLangFile} from "js/context/LanguageContext";
import {useIsAdmin} from "js/context/AccountContext";
import {voidFunc} from "../../constants/PropTypeUtils";

import Styles from "./FieldSettingsDialog.module.less";
import {grey} from "@material-ui/core/colors";
import {Edit} from "@material-ui/icons";
import {useFarmSettings} from "../../context/SettingsContext";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {
  formatFieldSeason,
} from "js/helpers/SeasonUtils";
import {NO_WEATHER_STATION_SELECTED} from "../../constants/WeatherConstants";
import {useSeasonContext, useSelectedSeason} from "js/context/SeasonContext";
import ErrorSnackbar from "js/components/Toasts/ErrorSnackbar";
import WebAPIUtils from "../../WebAPIUtils";
import {useHookRef} from "../../hooks/useHookRef";
import Tooltip from "@material-ui/core/Tooltip";

const FieldSettingsDialog = ({field, onUpdateFieldName, onChangeWeatherStation, onEditCrop, onDelete, onClose, shown, weatherStations, onRemoveFromSeason}) => {

  const [fieldName, setFieldName] = useState("");
  const [fieldSize, setFieldSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [fieldSeasons, setFieldSeasons] = useState(undefined);

  const LangFile = useLangFile();
  const isAdmin = useIsAdmin();
  const farmSettings = useFarmSettings();
  const selectedSeason = useSelectedSeason();
  const {seasons} = useSeasonContext();
  const seasonsRef = useHookRef(seasons);

  useEffect(() => {
    if (shown && field) {
      WebAPIUtils.getFieldSeasons(field.fieldId, seasonsRef.current).then((result) => {
        setFieldSeasons(result);
      });
    }
  }, [shown, field]);

  useEffect(() => {
    setFieldName(getFieldName(field, LangFile));
    setFieldSize(getFieldSize(field));
  }, [field]);

  const handleWeatherStationChanged = useCallback((event) => {
    setError(undefined);
    setLoading(true);

    let value = event.target.value;

    if (value === NO_WEATHER_STATION_SELECTED) {
      value = null;
    }

    onChangeWeatherStation(value)
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  }, [onChangeWeatherStation]);

  let handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      submitChange();
    }
  });

  const onFieldNameChanged = useCallback((e) => {
    setFieldName(e.target.value);
  });

  const submitChange = useCallback(() => {
    onUpdateFieldName(fieldName, field.fieldId);
  }, [fieldName]);

  const onDismissError = useCallback(() => {
    setError(undefined);
  });

  const seasonText = formatFieldSeason(field, selectedSeason, LangFile);

  if (!field) {
    return null;
  }

  const disableDelete = fieldSeasons && fieldSeasons.length > 1;
  const disableRemoveFromSeason = !disableDelete;

  return (
    <Fragment>
      <Dialog
        open={shown}
        fullWidth
        maxWidth={"sm"}
        onClose={onClose}>
        <DialogContent className={Styles.ContentRoot}>
          <Box
            display={"flex"}
            flexDirection={"row"}>
            <Box
              flex={1}
              p={2}>
              <Box
                pb={2}
                fontWeight={"bold"}>
                {LangFile.FieldSettingsDialog.title}
              </Box>
              <Box pb={2}>
                <TextField
                  fullWidth={true}
                  value={fieldName}
                  label={LangFile.FieldSettingsDialog.fieldName}
                  onChange={onFieldNameChanged}
                  onBlur={submitChange}
                  onKeyPress={handleKeyPress}/>
              </Box>
              {(<Box pb={2}>
                <FormControl fullWidth={true}>
                  {loading && (
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      height={"48px"}>
                      <CircularProgress
                        variant={"indeterminate"}
                        size={24}
                        color={"primary"}/>
                    </Box>
                  )}

                  {!loading && (
                    <Fragment>
                      <InputLabel className={Styles.Label}>{LangFile.FieldSettingsDialog.connectedTo}</InputLabel>
                      <Select
                        className={Styles.WeatherStationSelect}
                        fullWidth={true}
                        value={field.deviceId || NO_WEATHER_STATION_SELECTED}
                        onChange={handleWeatherStationChanged}
                        disabled={weatherStations.length === 0}>
                        <MenuItem
                          key={NO_WEATHER_STATION_SELECTED}
                          value={NO_WEATHER_STATION_SELECTED}>-</MenuItem>

                        {weatherStations
                          .filter((s) => s.status !== "retired")
                          .map((station) => (
                            <MenuItem
                              value={station.id}
                              key={station.id}>{getDeviceLabel(station.identifier, farmSettings)}</MenuItem>
                          ))}
                      </Select>
                    </Fragment>
                  )}
                </FormControl>
              </Box>)}
              <Box>
                <TextField
                  fullWidth={true}
                  value={fieldSize.toFixed(3) + " ha"}
                  disabled={true}
                  label={LangFile.FieldSettingsDialog.size}/>
              </Box>
              {(ManualFeatureConstants.ALLOW_DELETE_FIELD && isAdmin) && (
                <Box pt={2}>
                  <Tooltip title={disableDelete ? LangFile.FieldSettingsDialog.deleteDisabled : LangFile.FieldSettingsDialog.deleteField} disableHoverListener={!disableDelete}>
                    <div>
                      <Button
                        key={"delete"}
                        disabled={disableDelete}
                        onClick={() => onDelete(field)}
                        variant={"text"}
                        color={"secondary"}>
                        {LangFile.FieldSettingsDialog.deleteField}
                      </Button>
                    </div>
                  </Tooltip>
                </Box>
              )}
              {isAdmin && (
                <Box pt={2}>
                  <Button
                    key={"removeFromSeason"}
                    disabled={disableRemoveFromSeason}
                    onClick={() => onRemoveFromSeason(field)}
                    variant={"text"}
                    color={"secondary"}>
                    {LangFile.FieldSettingsDialog.removeFromSeason}
                  </Button>
                </Box>
              )}
            </Box>
            <Box
              flex={2}
              borderLeft={`1px solid ${grey["200"]}`}>
              <Box
                flex={1}
                p={2}
                className={Styles.RightColumn}>
                <div>
                  <Box
                    pb={2}
                    fontWeight={"bold"}>
                    {LangFile.FieldSettingsDialog.cropsTitle}
                  </Box>
                  <Box pb={2}>
                    <List>
                      <ListItem
                        className={Styles.ListItem}
                        button={true}
                        onClick={onEditCrop}>
                        <ListItemText primary={seasonText}/>
                        <ListItemIcon className={Styles.Icon}>
                          <Edit color={"primary"}/>
                        </ListItemIcon>
                      </ListItem>
                    </List>
                  </Box>
                </div>
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}>
                  <Button
                    key={"close"}
                    color='primary'
                    variant={"contained"}
                    onClick={onClose}>{LangFile.FieldSettingsDialog.ok}</Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <ErrorSnackbar
        open={Boolean(error)}
        title={LangFile.FieldSettingsDialog.connectErrorTitle}
        subtitle={LangFile.FieldSettingsDialog.connectErrorSubtitle}
        errorMessage={error ? error.message : undefined}
        onDismiss={onDismissError}/>

    </Fragment>
  );
};

FieldSettingsDialog.propTypes = {
  onClose: PropTypes.func,
  onUpdateFieldName: PropTypes.func,
  onChangeWeatherStation: PropTypes.func,
  onDelete: PropTypes.func,
  onEditCrop: PropTypes.func,
  onRemoveFromSeason: PropTypes.func,
  shown: PropTypes.bool,
  field: PropTypes.object,
  LangFile: PropTypes.object,
};

FieldSettingsDialog.defaultProps = {
  onClose: voidFunc,
  onUpdateFieldName: voidFunc,
  onChangeWeatherStation: voidFunc,
  onEditCrop: voidFunc,
  onDelete: voidFunc,
  onRemoveFromSeason: voidFunc,
  weatherStations: [],
};

export default FieldSettingsDialog;