import React, {memo, useCallback, useEffect, useLayoutEffect, useRef, useState,} from 'react';
import PropTypes from 'prop-types';
import style from "./GoogleMap.module.less";
import {GoogleMapProvider} from "js/context/GoogleMapContext";
import clsx from "clsx";
import useMapEvent from "js/components/DataLayer/hooks/useMapEvent";
import {voidFunc} from "js/constants/PropTypeUtils";
import {googleMapStyle} from "./GoogleMapStyle";
import {fitMapToBounds} from "../../../helpers/MapsUtils";

const getConfig = (props) => {

  let mapStyling = getMapStyling(props);
  return {
    center: props.center,
    zoom: props.zoom,
    optimized: props.optimized,
    mapTypeId: mapStyling.mapTypeId,
    disableDefaultUI: true,
    scrollwheel: true,
    keyboardShortcuts: false,
    disableDoubleClickZoom: false,
    scaleControl: true,
    zoomControl: false,
    tilt: 0,
    styles: mapStyling.styles,
    backgroundColor: 'rgba(0,0,0,0)'
  };
};

const getMapStyling = (props) => {
  let styles = googleMapStyle["default"];
  let mapTypeId = props.mapType === "transparent" ? null : props.mapType;
  if (props.mapStyle) {
    styles = googleMapStyle[props.mapStyle];
    mapTypeId = "roadmap";
  }

  return {
    mapTypeId: mapTypeId,
    styles: styles
  };
};

const GoogleMap = (props: GoogleMap.propTypes) => {
  const mapConfig = useRef(getConfig(props));
  const googleMapDomRef = useRef(null);
  const initialized = useRef(false);

  const [googleMap, setGoogleMap] = useState(null);

  /*

  CONFIG AND STYLING

  */

  useEffect(() => {
    if (googleMap) {
      let config = getConfig(props);
      config.map = googleMap;

      mapConfig.current = config;
      googleMap.setOptions(config);
    }
  }, [props.center, props.zoom, props.optimized, props.mapType, googleMap]);

  useEffect(() => {
    if (googleMap) {
      let mapStyling = getMapStyling(props);
      googleMap.setOptions({
        mapTypeId: mapStyling.mapTypeId,
        styles: mapStyling.styles
      });
    }
  }, [props.mapStyle]);

  /*

  INITIALIZATION

 */

  useLayoutEffect(() => {
    if (!initialized.current) {
      let map = new google.maps.Map(googleMapDomRef.current, mapConfig.current);
      setGoogleMap(map);
    }
  }, []);

  const initHandler = useCallback(() => {
    if (googleMap && typeof props.onMapLoaded === "function") {
      if (googleMap.getBounds() && !initialized.current) {
        props.onMapLoaded(googleMap);
        if (props.fitToBounds) {
          fitMapToBounds(googleMap, props.fitToBounds);
        }
        initialized.current = true;
      }
    }
  }, [googleMap, props.fitToBounds, props.onMapLoaded]);

  useMapEvent(googleMap, "bounds_changed", initHandler);

  /*

    MAP SYNCING

   */

  const syncHandler = useCallback(() => {
    if (googleMap) {
      googleMap.setCenter(props.syncWithMap.getCenter());
      googleMap.setZoom(props.syncWithMap.getZoom());
    }
  }, [props.syncWithMap, googleMap]);

  useMapEvent(props.syncWithMap, "drag", syncHandler);
  useMapEvent(props.syncWithMap, "idle", syncHandler);
  useMapEvent(props.syncWithMap, "zoom_changed", syncHandler);

  useEffect(() => {
    props.onSyncSetup(syncHandler);
  }, [syncHandler]);

  return (
    <div className={clsx({[style.Root]: true, [style.Disabled]: props.disableInteraction})}>
      <div
        ref={googleMapDomRef}
        className={clsx({[style.GoogleMap]: true, [style.MagicWand]: props.isMagicWandSelecting})}/>

      <GoogleMapProvider value={{googleMap, googleMapDomRef: googleMapDomRef.current}}>
        {googleMap && props.children}
      </GoogleMapProvider>
    </div>
  );
};

GoogleMap.propTypes = {
  children: PropTypes.any,
  center: PropTypes.object,
  zoom: PropTypes.number,
  optimized: PropTypes.bool,
  isMagicWandSelecting: PropTypes.bool,
  disableInteraction: PropTypes.bool,
  syncWithMap: PropTypes.object,
  fitToBounds: PropTypes.object,
  mapType: PropTypes.oneOf(["hybrid", "satellite", "terrain", "roadmap", "transparent"]),
  mapStyle: PropTypes.string,
  onMapLoaded: PropTypes.func,
  onSyncSetup: PropTypes.func,
};


GoogleMap.defaultProps = {
  onMapLoaded: voidFunc,
  mapType: "hybrid",
  onSyncSetup: voidFunc,
  optimized: true,
  center: {lat: 0, lng: 0},
  zoom: 3,
};

export default memo(GoogleMap);