import Field from "../../model/Field";
import {array} from "prop-types";

export default class PrescriptionFieldCardItem {
  field: Field;
  currentMaps: array;
  outsideMaps: array;

  constructor(field: Field, currentMaps: array, outsideMaps: array) {
    this.field = field;
    this.currentMaps = currentMaps;
    this.outsideMaps = outsideMaps;
  }
}