import React, {memo} from 'react';

import style from 'js/components/Prescription/DraggableIntervals/CenterLine/CenterLine.module.less';
import Box from "@material-ui/core/Box";

const CenterLine = () => {
  return (
    <Box className={style.Root}/>
  );
};

export default memo(CenterLine);
