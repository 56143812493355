import React, {createContext, memo, useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import FieldStatistics from './FieldStatistics';
import FullScreenPortalPaper from '../Prescription/FullScreenPortalPaper';
import {setViewMode} from '../../reducers/ControlReducer';
import ViewModeConstants from '../../constants/ViewModeConstants';
import {HeaderRendererProps, SortColumn} from 'react-data-grid';
import DraggableHeaderRenderer from './DraggableHeaderRenderer';
import {useLangFile, useLangFileRef} from '../../context/LanguageContext';
import FieldStatisticsEditContainer from "./FieldStatisticsEditContainer";
import {
  FIELD_STATISTICS_CLAAS_COLUMNS,
  FIELD_STATISTICS_COLUMNS,
  getColumnName
} from './FieldStatisticsColumns';
import HeaderRenderer from './HeaderRenderer';
import FieldIntegrationMappingContainer from '../FieldIntegrationMapping/FieldIntegrationMappingContainer';
import WebAPIUtils from '../../WebAPIUtils';
import moment from 'moment';
import useFirebaseAnalytics, {FIREBASE_EVENTS} from '../../hooks/useFirebaseAnalytics';
import {selectSeason} from '../../reducers/SeasonReducer';
import {useSelectedSeason} from '../../context/SeasonContext';
import {SURVEY_LAYERS} from '../../constants/SurveyLayers';
import {resetFilters, selectFieldById, setShowSatelliteImagery} from "../../reducers/FieldReducer";
import {setClassificationsEnabled, setSelectedLayer, setShowSoilSurveys} from "../../reducers/SurveyReducer";
import {fuzzyFilter} from "../../algorithms/filtering";
import ProgressLoadingModal from "../Modals/ProgressLoadingModal";
import {DEFAULT_COLUMNS, setSavedColumns} from "../../reducers/StatisticsReducer";
import NumberUtils from "../../helpers/NumberUtils";
import {getSurveyUnit, sortByDate, sortedClaasRows, sortedSoilOptixRows} from "./Utils/FieldStatisticsUtils";
import type {ColumnFilters} from "./Model/ColumnFilters";
import type {Row} from "./Model/Row";
import ColumnCell from './Cells/ColumnCell';
import DataIntegrationDialogContainer from '../DataIntegration/DataIntegrationDialogContainer';

const emptyFilters: ColumnFilters = {
  [FIELD_STATISTICS_COLUMNS.CROP]: {
    inputType: "list",
    getFieldStatisticsValues: (fieldStatistics, LangFile) => LangFile.CropType[fieldStatistics.crop],
    value: "",
    options: []
  },
  [FIELD_STATISTICS_COLUMNS.CROP_VARIETY]: {
    inputType: "list",
    getFieldStatisticsValues: (fieldStatistics) => fieldStatistics.cropVariation,
    value: "",
    options: []
  },
  [FIELD_STATISTICS_COLUMNS.FIELD]: {
    inputType: "string",
    getFieldStatisticsValues: (fieldStatistics, LangFile) => ([
      fieldStatistics.fieldName.length > 0 ? fieldStatistics.fieldName : LangFile.StateInterpreters.unnamedField,
      fieldStatistics.fieldMef
    ]),
    value: "",
    options: []
  },
  enabled: false
};

const createColumns = (LangFile, fieldStatistics, includeDefaultColumns: Boolean, savedColumns, onShortcutClicked) => {
  let columns = [FIELD_STATISTICS_COLUMNS.STATUS, FIELD_STATISTICS_COLUMNS.FIELD];

  savedColumns.forEach((column) => {
    columns.push(column);
  });

  // make sure that user preferences overwrite the defaults
  if (includeDefaultColumns) {
    DEFAULT_COLUMNS.filter((col) => !savedColumns.includes(col)).forEach((column) => {
      columns.push(column);
    });
  }

  return columns.map((column) => {
    const sortable = (column !== FIELD_STATISTICS_COLUMNS.STATUS);
    const resizeable = (column !== FIELD_STATISTICS_COLUMNS.STATUS);
    const minWidth = getMinWidthForColumn(column);

    return {
      key: column,
      name: getColumnName(column, LangFile),
      unit: getSurveyUnit(column, fieldStatistics),
      resizable: resizeable,
      sortable: sortable,
      minWidth: minWidth,
      width: 'max-content',
      formatter(props) {
        return <ColumnCell stats={props.row} column={column} onShortcutClicked={onShortcutClicked}/>;
      },
    };
  });
};

const getMinWidthForColumn = (column) => {
  if (column === FIELD_STATISTICS_COLUMNS.STATUS ||
      column === FIELD_STATISTICS_COLUMNS.FIELD ||
      column === FIELD_STATISTICS_COLUMNS.CROP ||
      column === FIELD_STATISTICS_COLUMNS.CROP_VARIETY ||
      column === FIELD_STATISTICS_COLUMNS.SOWING_DATE ||
      column === FIELD_STATISTICS_CLAAS_COLUMNS.HARVEST_DATE ||
      column === FIELD_STATISTICS_CLAAS_COLUMNS.YIELD_AVERAGE) {
    return 150;
  }
  return 100;
};

export const FilterContext = createContext(emptyFilters);

const mapStateToProps = (store) => ({
  savedColumns: store.statistics.savedColumns,
  dataIntegrations: store.integrations.dataIntegrations
});

const FieldStatisticsContainer = ({dispatch, savedColumns, dataIntegrations}) => {
  const LangFile = useLangFile();
  const LangFileRef = useLangFileRef();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openFieldMapping, setOpenFieldMapping] = useState(false);
  const [fieldStatistics, setFieldStatistics] = useState([]);
  const analytics = useFirebaseAnalytics();
  const selectedSeason = useSelectedSeason();
  const [importingProgress, setImportingProgress] = useState({shown: false});
  const [filters, setFilters] = useState(emptyFilters);
  const [columns, setColumns] = useState(createColumns(LangFileRef.current, fieldStatistics, false, savedColumns, onShortcutSoilOptix));
  const [sortColumns, setSortColumns] = useState([]);
  const [direction] = useState('DESC');
  const [filteredRows, setFilteredRows] = useState([]);
  const [sortedRows, setSortedRows] = useState([]);
  const [showDataIntegrationDialog, setShowDataIntegrationDialog] = useState(false);

  const fetchFieldStatistics = useCallback(async () => {
    return await WebAPIUtils.getFieldStatistics(selectedSeason.id);
  }, [selectedSeason]);

  const onBack = useCallback(() => {
    dispatch(setViewMode(ViewModeConstants.OVERVIEW));
  });

  const onEdit = useCallback(() => {
    setEdit(!edit);
  }, [edit]);

  const onGoToCLAAS = useCallback(() => {
    window.open('https://www.claas-telematics.com/Telematics/map.app', '_blank');
  }, []);

  const onCLAASDataIntegration = useCallback(() => {
    setShowDataIntegrationDialog(true);
  }, []);

  const onLink = useCallback(() => {
    setOpenFieldMapping(!openFieldMapping);
  }, [openFieldMapping]);

  const onChangeSeason = useCallback((seasonId) => {
    analytics.logEvent(FIREBASE_EVENTS.OVERVIEW_SEASON_SELECT);
    dispatch(selectSeason(seasonId));
    dispatch(resetFilters());
  }, []);

  const onShortcutSoilOptix = useCallback((fieldId, layer) => {
    analytics.logEvent(FIREBASE_EVENTS.FIELD_STATISTICS_SHORTCUT_TO_SOILOPTIX);
    dispatch(selectFieldById(fieldId));
    dispatch(setShowSatelliteImagery(false));
    dispatch(setShowSoilSurveys(true));
    dispatch(setSelectedLayer(layer));
    dispatch(setClassificationsEnabled(true));
    dispatch(setViewMode(ViewModeConstants.ANALYSIS));
  }, []);

  const onColumnSave = (selectedColumns) => {
    dispatch(setSavedColumns(selectedColumns));
    setEdit(false);
  };

  const onSortColumnsChange = useCallback((sortColumns: SortColumn[]) => {
    setSortColumns(sortColumns.slice(-1));
  }, []);

  const onImported = useCallback(() => {
    setOpenFieldMapping(false);
    setImportingProgress({shown: true, message: LangFileRef.current.FieldStatistics.importingYieldData});

    setTimeout(function() {
      fetchFieldStatistics().then((response) => {
        if (response['fieldStats'] !== undefined) {
          setFieldStatistics(response['fieldStats']);
          setImportingProgress({shown: false});
        }
      });
    }, 5000);
  }, [selectedSeason]);

  useEffect(() => {
    setLoading(true);
    fetchFieldStatistics().then((response) => {
      if (response['fieldStats'] !== undefined) {
        setFieldStatistics(response['fieldStats']);
      }
    });
  }, [fetchFieldStatistics]);

  useEffect(() => {
    if (fieldStatistics.length === 0) return;

    const newColumns = createColumns(LangFileRef.current, fieldStatistics, false, savedColumns, onShortcutSoilOptix);
    setColumns(newColumns);
    setFilters((currentFilters) => {
      let newFilters = {...currentFilters};

      newColumns.forEach(( column) => {
        let filter = newFilters[column.key];

        if (filter) {
          if (filter.inputType === "list") {
            let options = fieldStatistics.map((fieldStat) => {
              switch (column.key) {
                case FIELD_STATISTICS_COLUMNS.CROP:
                  return LangFileRef.current.CropType[fieldStat.crop];
                case FIELD_STATISTICS_COLUMNS.CROP_VARIETY:
                  return fieldStat.cropVariation;
                default:
                  return null;
              }
            }).filter(Boolean);
            filter.options = Array.from(new Set(options));
          }
        }
      });

      return newFilters;
    });
  }, [fieldStatistics, savedColumns, onShortcutSoilOptix]);

  useEffect(() => {
    if (fieldStatistics.length === 0) return;

    let result: Row[] = [...fieldStatistics];

    // This section handles filtering of rows
    if (filters.enabled) {
      let enabledFilters = {...filters};
      delete enabledFilters.enabled;
      let filteredColumns = Object.keys(enabledFilters);

      result = result.filter((row) => {
        return filteredColumns.reduce((acc, column) => {
          let filter = enabledFilters[column];

          let lookingFor = `${filter.value}`;
          let lookingIn = filter.getFieldStatisticsValues(row, LangFileRef.current);

          if (!lookingFor) {
            return acc;
          }

          if (lookingFor.length > 0) {
            if (Array.isArray(lookingIn)) {
              return acc && lookingIn.reduce((acc, cur) => acc || fuzzyFilter(lookingFor, cur), false);
            }
            else {
              if (!lookingIn) {
                return false;
              }
              else if (lookingIn.length > 0) {
                return acc && fuzzyFilter(lookingFor, lookingIn);
              }
            }
          }

          return acc;
        }, true);
      });
    }

    // Handle normalization of yield
    let yieldMin, yieldMax;
    result.forEach((fieldStatistic) => {
      const value = fieldStatistic.claas.averageYield;

      if (value > 0) {
        if (!yieldMin) {
          yieldMin = value;
        }
        if (!yieldMax) {
          yieldMax = value;
        }

        yieldMin = Math.min(yieldMin, value);
        yieldMax = Math.max(yieldMax, value);
      }
    });

    result = result.map((fieldStatistic) => {
      const yieldValue = fieldStatistic.claas.averageYield;
      let yieldNormalized;

      if (yieldValue === undefined || yieldMin === undefined || yieldMax === undefined) {
        yieldNormalized = 0.5;
      }
      else {
        yieldNormalized = NumberUtils.map(yieldValue, yieldMin, yieldMax, 0, 1);
      }

      return {
        ...fieldStatistic, claas: {...fieldStatistic.claas, yieldNormalized}
      };
    });

    setFilteredRows(result);
  }, [fieldStatistics, filters]);

  useEffect(() => {
    if (sortColumns.length === 0) {
      setSortedRows(filteredRows);
      setLoading(false);
      return;
    }

    let result: Row[] = [...filteredRows];

    // This section handles sorting of what remains
    const { columnKey, direction } = sortColumns[0];

    if (columnKey === FIELD_STATISTICS_COLUMNS.FIELD) {
      result = result.sort((lhs, rhs) => {
        const lhsFieldName = lhs.fieldName && lhs.fieldName.length > 0 ? lhs.fieldName : LangFile.StateInterpreters.unnamedField;
        const rhsFieldName = rhs.fieldName && rhs.fieldName.length > 0 ? rhs.fieldName : LangFile.StateInterpreters.unnamedField;
        const compare = lhsFieldName.localeCompare(rhsFieldName);
        if (compare === 0) {
          const lhsFieldMef = lhs.fieldMef ? lhs.fieldMef : '';
          const rhsFieldMef = rhs.fieldMef ? rhs.fieldMef : '';
          return lhsFieldMef.localeCompare(rhsFieldMef);
        }
        return compare;
      });
    }
    else if (columnKey === FIELD_STATISTICS_COLUMNS.AREA) {
      result = result.sort((lhs, rhs) => {
        const lhsFieldSize = lhs.size ? lhs.size : 0;
        const rhsFieldSize = rhs.size ? rhs.size : 0;
        return lhsFieldSize - rhsFieldSize;
      });
    }
    else if (columnKey === FIELD_STATISTICS_COLUMNS.CROP) {
      result = result.sort((lhs, rhs) => {
        const lhsCrop = lhs.crop ? LangFile.CropType[lhs.crop] : '-';
        const rhsCrop = rhs.crop ? LangFile.CropType[rhs.crop] : '-';
        return lhsCrop.localeCompare(rhsCrop);
      });
    }
    else if (columnKey === FIELD_STATISTICS_COLUMNS.SOWING_DATE) {
      result = result.sort((lhs, rhs) => {
        return sortByDate(moment(lhs.sowing), moment(rhs.sowing));
      });
    }
    else if (Object.keys(FIELD_STATISTICS_CLAAS_COLUMNS).includes(columnKey)) {
      result = sortedClaasRows(columnKey, result);
    }
    else if (Object.keys(SURVEY_LAYERS).includes(columnKey)) {
      result = sortedSoilOptixRows(columnKey, result);
    }

    result = direction === 'DESC' ? result.reverse() : result;

    setSortedRows(result);
    setLoading(false);
  }, [filteredRows, sortColumns]);

  const draggableColumns = useMemo(() => {
    const headerRenderer = (props: HeaderRendererProps<Row>) => {
      return <DraggableHeaderRenderer {...props} onColumnsReorder={handleColumnsReorder} />;
    };

    const handleColumnsReorder = (sourceKey: string, targetKey: string) => {
      const sourceColumnIndex = columns.findIndex((c) => c.key === sourceKey);
      const targetColumnIndex = columns.findIndex((c) => c.key === targetKey);
      const reorderedColumns = [...columns];

      reorderedColumns.splice(
        targetColumnIndex,
        0,
        reorderedColumns.splice(sourceColumnIndex, 1)[0]
      );

      const columnKeys = reorderedColumns.map((column) => {
        if (column.key !== FIELD_STATISTICS_COLUMNS.STATUS && column.key !== FIELD_STATISTICS_COLUMNS.FIELD) {
          return column.key;
        }
        return null;
      }).filter(Boolean);

      dispatch(setSavedColumns(columnKeys));
      setColumns(reorderedColumns);
    };

    return columns.map((column) => {
      if (column.key === FIELD_STATISTICS_COLUMNS.STATUS) return column;
      if (column.key === FIELD_STATISTICS_COLUMNS.FIELD) return {...column, headerRenderer: HeaderRenderer};
      return { ...column, headerRenderer };
    });
  }, [columns]);

  return (
      <FullScreenPortalPaper>
        <FilterContext.Provider value={{filters, setFilters}}>
          <FieldStatistics
              loading={loading}
              dataIntegrations={dataIntegrations}
              onBackButtonClicked={onBack}
              onEditButtonClicked={onEdit}
              onGoToClaasButtonClicked={onGoToCLAAS}
              onGoToIntegrationButtonClicked={onCLAASDataIntegration}
              onLinkButtonClicked={onLink}
              onSeasonChange={onChangeSeason}
              columns={draggableColumns}
              rows={sortedRows}
              sortColumns={sortColumns}
              onSortColumnsChange={onSortColumnsChange}
              direction={direction}/>
        </FilterContext.Provider>

        {edit && (
          <FieldStatisticsEditContainer
            onClose={() => {
              setEdit(false);
            }}
            onSave={onColumnSave}/>
        )}

        <FieldIntegrationMappingContainer
            open={openFieldMapping}
            onBack={() => {
              setOpenFieldMapping(false);
            }}
            onImported={onImported}/>

        
        <DataIntegrationDialogContainer
          open={showDataIntegrationDialog}
          onClose={() => {
            setShowDataIntegrationDialog(false);
          }}/>
        

        <ProgressLoadingModal progress={importingProgress} />
      </FullScreenPortalPaper>
  );
};


export default memo(
    connect(mapStateToProps)(
        FieldStatisticsContainer
    )
);
