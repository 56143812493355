import * as turf from "@turf/turf";
import {Outline} from "../model/Outline";
import Field from "../model/Field";

export function goToFields(map, fields, weatherStations) {
  if (!map) {
    return;
  }

  let bounds;
  if (fields) {
    bounds = getFieldBounds(fields);
  }

  if (bounds) {
    map.fitBounds(bounds);
  }
  if (!bounds && weatherStations) {
    goToWeatherStations(map, weatherStations);
  }
}


export function goToField(map, field, offsetBottom, disableZoom) {
  let target = new Map();
  target.set(field.fieldId, field);

  let mapBounds = map.getBounds();

  let ne = mapBounds.getNorthEast();
  let sw = mapBounds.getSouthWest();
  let n = ne.lat(), s = sw.lat();

  let height = n > s ? n - s : s - n;

  let fieldBounds = getFieldBounds(target);

  if (fieldBounds) {
    let c = fieldBounds.getCenter();
    let lat = c.lat();
    let lng = c.lng();

    if (offsetBottom) {
      lat -= height / 5;
    }

    let target = new google.maps.LatLng(lat, lng);

    map.setCenter(target);

    if (!disableZoom && map.getZoom() < 14) {
      map.setZoom(14);
    }
  }
}

export function getFieldBounds(fields) {
  if (!fields) {
    return undefined;
  }

  let bounds = new google.maps.LatLngBounds();
  let points = [];

  for (let entry of fields) {
    let field = entry[1];
    let {north, south, east, west} = field.bounds;

    points.push(new google.maps.LatLng(north, west));
    points.push(new google.maps.LatLng(south, east));
  }

  if (points.length <= 0) {
    return;
  }

  for (let i = 0; i < points.length; i++) {
    bounds.extend(points[i]);
  }

  return bounds;
}


export function goToWeatherStations(map, stations) {
  if (!stations) {
    return;
  }

  let bounds = new google.maps.LatLngBounds();
  let points = [];

  for (let station of stations) {
    let loc = station.location;
    if (loc) {
      points.push(new google.maps.LatLng(loc.latitude, loc.longitude));
    }
  }

  if (points.length <= 0) {
    return;
  }

  for (let i = 0; i < points.length; i++) {
    bounds.extend(points[i]);
  }
  map.fitBounds(bounds);
  map.setZoom(map.getZoom() - 1);
}

export function fitToOutlines(outlines, googleMap) {
  let bounds = new google.maps.LatLngBounds();
  outlines.forEach((entry) => {
    entry.coordinates.forEach((c) => {
      bounds.extend(c);
    });
  });
  googleMap.fitBounds(bounds);

  if (outlines.length > 1) {
    googleMap.setZoom(googleMap.getZoom()+1);
  }
}

export function showFeatureCollection(collection, googleMap) {
  collection.forEach((data) => {
    googleMap.data.addGeoJson(data);
  });

  googleMap.data.setStyle({
    strokeColor: 'yellow',
    strokeWeight: 2
  });
}

export const fitMapToBounds = (googleMap, bounds) => {
  bounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(bounds.south, bounds.west),
    new google.maps.LatLng(bounds.north, bounds.east)
  );

  // Prevents the map from zooming in too much
  googleMap.setOptions({maxZoom: 18});
  googleMap.fitBounds(bounds, {bottom: 5, left: 5, right: 5, top: 5});
  googleMap.setOptions({maxZoom: null});
};

export const getFieldCenter = (field) => {
  let coords = field.polygon.coordinates;
  let p = coords[0];

  let first = turf.flip(turf.point(p[0])), last = turf.flip(turf.point(p[p.length-1]));

  if (!turf.booleanEqual(first, last)) {
    coords[0].push(turf.getCoord(turf.flip(first)));
  }

  let poly = turf.polygon(coords);
  let center = turf.flip(turf.centerOfMass(poly));
  let position = center.geometry.coordinates;
  return {lat: position[0], lng: position[1]};
};

export const getLatLngValues = (latLng) => {
  let lat = latLng && (typeof latLng.lat === "function" ? latLng.lat() : latLng.lat);
  let lng = latLng && (typeof latLng.lng === "function" ? latLng.lng() : latLng.lng);

  return {lat, lng};
};


export const getRect = (map, mapDOM, points) => {
  if (map && points.length === 2) {
    let first = getScreenXY(map, points[0]);
    let last = getScreenXY(map, points[1]);

    // First, we assume that the selection direction is from Left to Right, from Top to Bottom.
    let topLeft = first, bottomRight = last;

    // Check if the direction is in fact Right to Left, if so, flip X-direction
    let rightToLeftSelection = first.x > last.x;
    if (rightToLeftSelection) {
      topLeft = new google.maps.Point(last.x, first.y);
      bottomRight = new google.maps.Point(first.x, last.y);
    }

    // Check if the direction is in fact Bottom to Top, if so, flip Y-direction
    let bottomToTopSelection = first.y > last.y;
    if (bottomToTopSelection) {
      let tmpY = topLeft.y;
      topLeft.y = bottomRight.y;
      bottomRight.y = tmpY;
    }

    return {topLeft, bottomRight};
  }
};

export const getScreenXY = (map, latLng) => {
  let numTiles = 1 << map.getZoom();
  let projection = map.getProjection();
  let worldCoordinate = projection.fromLatLngToPoint(latLng);
  let pixelCoordinate = new google.maps.Point(
    worldCoordinate.x * numTiles,
    worldCoordinate.y * numTiles);

  let topLeft = new google.maps.LatLng(
    map.getBounds().getNorthEast().lat(),
    map.getBounds().getSouthWest().lng()
  );

  let topLeftWorldCoordinate = projection.fromLatLngToPoint(topLeft);
  let topLeftPixelCoordinate = new google.maps.Point(
    topLeftWorldCoordinate.x * numTiles,
    topLeftWorldCoordinate.y * numTiles);

  return new google.maps.Point(
    pixelCoordinate.x - topLeftPixelCoordinate.x,
    pixelCoordinate.y - topLeftPixelCoordinate.y
  );
};

export const getClickedFields = (fields, event) => {
  const point = turf.point([event.latLng.lng(), event.latLng.lat()]);

  return [...fields.values()].filter((f) => {
    const borderPoints = f.polygon.coordinates[0];
    const border = turf.polygon([borderPoints]);
    return turf.booleanContains(border, point);
  });
};

export const latLng2Point = (latLng, map) => {
  let topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast());
  let bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest());
  let scale = Math.pow(2, map.getZoom());
  let worldPoint = map.getProjection().fromLatLngToPoint(latLng);
  return new google.maps.Point((worldPoint.x - bottomLeft.x) * scale, (worldPoint.y - topRight.y) * scale);
};

export const point2LatLng = (point, map) => {
  let topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast());
  let bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest());
  let scale = Math.pow(2, map.getZoom());
  let worldPoint = new google.maps.Point(point.x / scale + bottomLeft.x, point.y / scale + topRight.y);
  return map.getProjection().fromPointToLatLng(worldPoint);
};

export function fieldToTurfPolygon(field: Field) {
  return turf.polygon(field.polygon.coordinates);
}

export function outlineToTurfPolygon(outline: Outline) {
  let coords = [[...outline.coordinates.map((c) => [c.lng, c.lat])]];
  return turf.polygon(coords);
}

export function getFilteredOutlinesStrict(outlines, existingFields, progressCallback = (progress, progressMax, skipped) => {}) {
  let result = [];
  let progress = 0, skipped = 0, progressMax = outlines.length;

  try {
    // Figure out which outlines represents fields which are already added
    let fieldPolygons = existingFields.map(fieldToTurfPolygon);

    outer: for (let outline of outlines) {
      progressCallback(progress, progressMax, skipped);
      progress++;

      let p = outlineToTurfPolygon(outline);
      let skip = false;

      for (let fPoly of fieldPolygons) {
        if (turf.booleanWithin(p, fPoly)) {
          skip = true;
        }

        if (!skip && turf.booleanOverlap(fPoly, p)) {
          skip = true;
        }

        if (!skip && (turf.booleanContains(fPoly, p) || turf.booleanContains(p, fPoly))) {
          skip = true;
        }

        if (skip) {
          skipped++;

          continue outer;
        }
      }

      result.push(outline);
    }

    return result;

  }
  catch (e) {
    console.error(e);
    return outlines;
  }
}

export function getFilteredOutlinesSoft(outlines, existingFields, progressCallback = (progress, progressMax, skipped) => {}) {

  let progress = 0, skipped = 0, progressMax = outlines.length;

  try {
    let result = [];
    // Figure out which outlines represents fields which are already added
    let fieldPolygons = existingFields.map(fieldToTurfPolygon);
    let fieldCenters = fieldPolygons.map((p) => turf.centerOfMass(p));

    outer: for (let outline of outlines) {
      progressCallback(progress, progressMax, skipped);
      progress++;

      let p = outlineToTurfPolygon(outline);
      let pCenter = turf.centerOfMass(p);
      let skip = false;

      for (let fCenter of fieldCenters) {
        let dist = turf.distance(pCenter, fCenter);

        if (dist < 0.05) { // 50m
          skip = true;
        }

        if (skip) {
          skipped++;

          continue outer;
        }
      }

      if (outline.size >= 0.1) {
        result.push(outline);
      }
    }

    return result;
  }
  catch (e) {
    console.error(e);
    let result = [];
    for (let outline of outlines) {
      if (outline.size >= 0.3) {
        result.push(outline);
      }
    }

    return result;
  }

}

export function getFilteredOutlinesSoftWFS(data, existingFields, progressCallback = (progress, progressMax, skipped) => {}) {

  let progress = 0, skipped = 0, progressMax = data.length;
  
  try {
    let result = [];
    // Figure out which outlines represents fields which are already added
    let fieldPolygons = existingFields.map(fieldToTurfPolygon);
    let fieldCenters = fieldPolygons.map((p) => turf.centerOfMass(p));

    outer: for (let entry of data.features) {
      let coordinates = entry.geometry.coordinates[0].map((obj) => {
        return {lat: obj[1], lng: obj[0] };
      });

      let outline = new Outline();
      let properties = entry.properties;
      outline.size = properties.areal;
      outline.coordinates = coordinates;

      progressCallback(progress, progressMax, skipped);
      progress++;

      let p = outlineToTurfPolygon(outline);
      let pCenter = turf.centerOfMass(p);
      let skip = false;

      for (let fCenter of fieldCenters) {
        let dist = turf.distance(pCenter, fCenter);

        if (dist < 0.05) { // 50m
          skip = true;
        }

        if (skip) {
          skipped++;

          continue outer;
        }
      }

      if (outline.size >= 0.3) {
        result.push(outline);
      }
    }

    return result;
  }
  catch (e) {
    console.error(e);
    let result = [];
    for (let outline of outlines) {
      if (outline.size >= 0.3) {
        result.push(outline);
      }
    }

    return result;
  }

}
