import Bounds from "js/model/Bounds";
import Polygon from "js/model/Polygon";

export default class Field {
  fieldId: number;
  mef: string;
  name: string;
  size: number;
  thumbnailUrl: string;
  polygon: Polygon;
  bounds: Bounds;
  deviceId: string;
  status: boolean;
  bundle: number;
  ready: boolean;
  seasonId: number;
  crop: string;
  cropVariation: string;
  sowing: Date;

  constructor(
    fieldId: number,
    mef: string,
    name: string,
    size: number,
    thumbnailUrl: string,
    polygon: Polygon,
    bounds: Bounds,
    deviceId: string,
    status: boolean,
    bundle: number,
    ready: boolean,
    seasonId: number,
    crop: string,
    cropVariation: string,
    sowing: Date,
  ) {
    this.fieldId = fieldId;
    this.mef = mef;
    this.name = name;
    this.size = size;
    this.thumbnailUrl = thumbnailUrl;
    this.polygon = polygon;
    this.bounds = bounds;
    this.deviceId = deviceId;
    this.status = status;
    this.bundle = bundle;
    this.ready = ready;
    this.seasonId = seasonId;
    this.crop = crop;
    this.cropVariation = cropVariation;
    this.sowing = sowing;
  }
}
