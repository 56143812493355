import WebAPIUtils from 'js/WebAPIUtils';
import areaCalculation from 'js/algorithms/geometry/AreaCalculation';
import Polygons from "js/algorithms/geometry/Polygons";
import {mapFvmFieldsToOutlines} from "js/helpers/FvmUtils";
import {Outline} from "js/model/Outline";
import {getFilteredOutlinesSoft, getFilteredOutlinesSoftWFS} from "../helpers/MapsUtils";

/* ============== //
||  ACTIONTYPES   ||
// ============== */

const FETCH_OUTLINES = "fieldsense/AddField/FETCH_OUTLINES";
const SET_OUTLINES = "fieldsense/AddField/SET_OUTLINES";
const SELECT_OUTLINE = "fieldsense/AddField/SELECT_OUTLINE";
const SELECT_ALL_OUTLINES = "fieldsense/AddField/SELECT_ALL_OUTLINES";
const SELECT_NO_OUTLINES = "fieldsense/AddField/SELECT_NO_OUTLINES";
const REMOVE_OUTLINES = "fieldsense/AddField/REMOVE_OUTLINES";
export const ADD_FIELDS = "fieldsense/AddField/ADD_FIELDS";
const SET_IS_DRAWING_FIELD = "fieldsense/AddField/SET_IS_DRAWING_FIELD";
const IMPORT_FIELDS_BY_CVR = "fieldsense/AddField/IMPORT_FIELDS_BY_CVR";
const SELECT_MULTIPLE_OUTLINES = "fieldsense/AddFieldReducer/SELECT_MULTIPLE_OUTLINES";
const SET_IS_ADDING_FIELDS = "fieldsense/AddFieldReducer/SET_IS_ADDING_FIELDS";
const SET_IS_IMPORTING_FIELDS = "fieldsense/AddFieldReducer/SET_IS_IMPORTING_FIELDS";
const FETCH_OUTLINES_BY_WFS = "fieldsense/AddField/FETCH_OUTLINES_BY_WFS";

/* ============== //
||     HELPERS    ||
// ============== */


export async function fetchConvertedOutlines(bounds, existingFields, progressCallback) {
  // Call to get the outlines from backend
  let outlines = await WebAPIUtils.getOutlines(bounds.north, bounds.east, bounds.south, bounds.west);
  return getFilteredOutlinesSoft(outlines, existingFields, progressCallback);
}

export async function fetchConvertedOutlinesWFS(bounds, existingFields, countryCode, progressCallback) {
  let outlines = await WebAPIUtils.getWFSOutlines(bounds.north, bounds.east, bounds.south, bounds.west, countryCode);
  return getFilteredOutlinesSoftWFS(outlines, existingFields, progressCallback);
}

export async function postFields(selectedOutlines, farmId, seasonId) {
  let result = [];

  let toAdd = selectedOutlines.map((entry: Outline) => {
    let {mef, size, cropType, coordinates} = entry;
    let coords = coordinates.map((coordinate) => [coordinate.lng, coordinate.lat]);

    // Construct the postable representation
    return {mef: mef, size: size, cropType: cropType, polygon: {coordinates: [coords]}};
  });

  for (let i = 0; i < toAdd.length; i++) {
    let element = toAdd[i];

    let payload = {userPackageId: farmId, outlines: [element], seasonId: seasonId};
    try {
      let field = await WebAPIUtils.addFields(payload);
      result.push(field[0]);
    }
    catch (error) {
      console.log(error);
    }
  }
  return result;
}

export function convertPolygonToField(drawnPolygon, farmId, seasonId) {
  let lngLat = drawnPolygon.map(({latitude, longitude}) => {
    return [longitude, latitude];
  });

  let field = {
    userPackageId: farmId,
    seasonId: seasonId,
    outlines: [{
      mef: "",
      size: areaCalculation({coords: drawnPolygon}).size,
      id: 0,
      polygon: {
        coordinates: [lngLat]
      }
    }]
  };

  lngLat.reverse();

  return field;
}

export function calculateSizeOfOutlinesToAdd(outlines) {
  let size = 0;
  outlines.forEach((element) => {
    size += (element.size - 0);
  });
  return size;
}

export function featureCollectionsToOutlines(featureCollections) {

  let outlines = [];

  let features = featureCollections.reduce((acc, coll) => {
    return [...acc, ...coll.features];
  }, []);

  features.forEach((feature) => {
    let geo = feature.geometry;
    let type = geo.type;
    let coords = geo.coordinates;

    if (type === 'Polygon') {
      outlines.push(Polygons.getOutline(coords[0]));
    }
    else if (type === 'MultiPolygon') {
      coords.forEach((polygon) => {
        outlines.push(Polygons.getOutline(polygon));
      });
    }
  });

  outlines = outlines.filter((item) => item && item.coordinates && !isNaN(item.size));

  return outlines;
}

/* ============== //
||    ACTIONS    ||
// ============== */

export function setOutlines(outlines) {
  return {
    type: SET_OUTLINES,
    payload: outlines
  };
}

export function setIsDrawingField(isDrawing) {
  return {
    type: SET_IS_DRAWING_FIELD,
    payload: isDrawing
  };
}

export function setIsAddingFields(isAdding) {
  return {
    type: SET_IS_ADDING_FIELDS,
    payload: isAdding
  };
}

export function setIsImportingFields(isImporting) {
  return {
    type: SET_IS_IMPORTING_FIELDS,
    payload: isImporting
  };
}

export function selectAll() {
  return {
    type: SELECT_ALL_OUTLINES,
  };
}

export function selectNone() {
  return {
    type: SELECT_NO_OUTLINES,
  };
}

export function fetchOutlines(bounds, existingFields, progressCallback) {
  return (dispatch) => {
    return dispatch({
      type: FETCH_OUTLINES,
      payload: fetchConvertedOutlines(bounds, existingFields, progressCallback)
    });
  };
}

export function removeOutlines() {
  return {
    type: REMOVE_OUTLINES,
  };
}

export function selectOutline(outline) {
  return {
    type: SELECT_OUTLINE,
    payload: outline
  };
}

export function selectMultipleOutlines(outlines) {
  return {
    type: SELECT_MULTIPLE_OUTLINES,
    payload: outlines
  };
}

export function addFields(selectedOutlines, farmId, seasonId) {
  return (dispatch) => {
    return dispatch({
      type: ADD_FIELDS,
      payload: postFields(selectedOutlines, farmId, seasonId)
    });
  };
}

export function addDrawnField(drawnPolygon, farmId, seasonId) {
  let field = convertPolygonToField(drawnPolygon, farmId, seasonId);

  return (dispatch) => {
    return dispatch({
      type: ADD_FIELDS,
      payload: WebAPIUtils.addFields(field)
    });
  };
}

export function importFieldsByCVR(cvr, existingFields) {
  return {
    type: IMPORT_FIELDS_BY_CVR,
    payload: new Promise((resolve, reject) => {
      WebAPIUtils.getFieldsByCVR(cvr).then((featureCollection) => {
        if (featureCollection) {
          resolve(mapFvmFieldsToOutlines(featureCollection, existingFields));
        }
        else {
          reject(new Error("Did not receive any outlines from server."));
        }
      });
    })
  };
}

export function fetchOutlinesByWFS(bounds, existingFields, countryCode, progressCallback) {
  return (dispatch) => {
    return dispatch({
      type: FETCH_OUTLINES_BY_WFS,
      payload: fetchConvertedOutlinesWFS(bounds, existingFields, countryCode, progressCallback)
    });
  };
}

/* ============== //
||     REDUCER    ||
// ============== */

const initState = {
  outlines: [],
  selectedOutlines: [],
  drawnPolygon: null,
  totalSizeToAdd: 0,
  isDrawing: false,
  isDrawingFields: false,
  isAddingFields: false,
  isImportingFields: false
};

export default function reducer(state=initState, action) {
  switch (action.type) {

    case SET_OUTLINES:
      state = {...state, outlines: action.payload, totalSizeToAdd: 0};
      break;

    case FETCH_OUTLINES + "_FULFILLED":
      state = {...state, outlines: action.payload, totalSizeToAdd: 0};
      break;

    case FETCH_OUTLINES_BY_WFS + "_FULFILLED":
      state = {...state, outlines: action.payload, totalSizeToAdd: 0};
      break;

    case REMOVE_OUTLINES:
      state = {...state, outlines: [], selectedOutlines: [], totalSizeToAdd: 0};
      break;

    case ADD_FIELDS + "_FULFILLED":
      state = {...state, outlines: [], selectedOutlines: [], drawnPolygon: null, totalSizeToAdd: 0};
      break;

    case SELECT_OUTLINE:
      state = {...state, selectedOutlines: [...state.selectedOutlines]};
      let entryIndex = state.selectedOutlines.indexOf(action.payload);

      if (entryIndex < 0) {
        state.selectedOutlines.push(action.payload);
      }
      else {
        state.selectedOutlines.splice(entryIndex, 1);
      }
      state.totalSizeToAdd = calculateSizeOfOutlinesToAdd(state.selectedOutlines);
      break;

    case SELECT_MULTIPLE_OUTLINES: {
      state = {...state, selectedOutlines: [...state.selectedOutlines]};

      action.payload.forEach((outline) => {
        if (!state.selectedOutlines.includes(outline)) {
          state.selectedOutlines.push(outline);
        }
      });

      state.totalSizeToAdd = calculateSizeOfOutlinesToAdd(state.selectedOutlines);

      break;
    }

    case SELECT_ALL_OUTLINES:
      state = {...state, selectedOutlines: []};
      state.selectedOutlines.push(...state.outlines);
      state.totalSizeToAdd = calculateSizeOfOutlinesToAdd(state.selectedOutlines);
      break;

    case SELECT_NO_OUTLINES:
      state = {...state, selectedOutlines: []};
      state.totalSizeToAdd = calculateSizeOfOutlinesToAdd(state.selectedOutlines);
      break;

    case SET_IS_DRAWING_FIELD: {
      state = {...state, isDrawing: action.payload};
      break;
    }

    case SET_IS_ADDING_FIELDS: {
      state = {...state, isAddingFields: action.payload};
      break;
    }

    case SET_IS_IMPORTING_FIELDS: {
      state = {...state, isImportingFields: action.payload};
      break;
    }

    case IMPORT_FIELDS_BY_CVR + "_FULFILLED": {
      state = {...state, outlines: action.payload};

      break;
    }

    default:
      break;
  }

  return state;
}
