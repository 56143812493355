"use strict";

export default {
  LEGACY_SATELLITE: "LEGACY_SATELLITE",
  LEGACY_WEATHER: "LEGACY_WEATHER",
  BULK_DOWNLOAD: "BULK_DOWNLOAD",
  ADD_FIELDS_BY_CVR: "ADD_FIELDS_BY_CVR",
  ADD_FIELDS_BY_OUTLINES: "ADD_FIELDS_BY_OUTLINES",
  MARQUEE_SELECT: "MARQUEE_SELECT",
  HEIGHT_MAPS_KORTFORSYNINGEN: "HEIGHT_MAPS_KORTFORSYNINGEN",
  PRESCRIPTION_BUFFER_ZONE: "PRESCRIPTION_BUFFER_ZONE",
  ISOXML_CCI: "ISOXML_CCI"
};
