import {useState, useCallback, useEffect} from 'react';

const useRefSize = (elementRef) => {

  const [size, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const updateSize = useCallback(() => {
    if (elementRef && elementRef.current) {
      const { width, height } = elementRef.current.getBoundingClientRect();
      setSize({ width, height });
    }
  }, [elementRef]);

  useEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [updateSize]);

  return size;
};

export default useRefSize;