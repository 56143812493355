"use strict";

import React, {
  useRef,
  useCallback,
  useState,
  memo,
  useEffect,
} from 'react';

import Paper from '@material-ui/core/Paper';
import * as d3 from "d3";
import IntervalLabel from 'js/components/Prescription/PrescriptionScale/IntervalLabel';
import lerp from "@sunify/lerp-color";

const tinycolor = require("tinycolor2");

import imageTypes from 'js/constants/ColorMaps';

import useEvent from "js/hooks/useEvent";
import {useLangFile} from "js/context/LanguageContext";
import PropTypes from "prop-types";
import useWindowDimensions from "js/hooks/useWindowDimensions";

import style from "js/components/Prescription/DraggableIntervals/PrescriptionScale/PrescriptionScale.module.less";
import Box from "@material-ui/core/Box";
import {
  isSatelliteLayer,
  isSurveyLayer
} from "js/components/Prescription/PrescriptionJob";
import NumberUtils from "js/helpers/NumberUtils";
import {getLayerConfig} from "../../PrescriptionUtils";
import {renderStandardDemandLegend} from "../../../DemandScale/DemandScale";
import {ClassificationColoringStrategy} from "../../../../model/surveys/SurveyColoringStrategies";
import ViewModeConstants from "../../../../constants/ViewModeConstants";
import {useColoringStrategy} from "../../../../model/surveys/useColoringStrategy";
import {SURVEY_LAYERS} from "../../../../constants/SurveyLayers";
import {SATELLITE_LAYERS} from 'js/constants/SatelliteLayers';

const generateSurveyGradient = (context, width, intervals, coloringStrategy) => {
  let gradient = context.createLinearGradient(0, 0, width, 0);

  let min = intervals[0].min;
  let max = intervals[intervals.length -1].max;

  let color = coloringStrategy.getColor(min);
  if (color) {
    gradient.addColorStop(0.0, color);
  }

  let steps = 10;
  let stepWidth = (max-min)/steps;

  for (let i = 0; i < steps; i++) {
    let pos = i > 0 ? i/steps : 0;
    let next = (i+1)/steps;
    let val = min + i * stepWidth;
    let col = coloringStrategy.getColor(val);
    if (col) {
      gradient.addColorStop(pos, col);
      gradient.addColorStop(next, col);
    }
  }
  color = coloringStrategy.getColor(max);
  if (color) {
    gradient.addColorStop(1.0, color);
  }
  return gradient;
};

// Generate the color bar bar values specific to the selected imageType
const generateNdviGradient = (context, width, intervals) => {
  let colorIntervals = imageTypes[SATELLITE_LAYERS.VITALITY_NDVI].intervals;
  let gradient = context.createLinearGradient(0, 0, width, 0);

  // Define minimum and maximum MEAN values
  let l = intervals[0].min;
  let h = intervals[intervals.length - 1].max;

  let lowIndex = null;
  let highIndex = null;
  let lowColor = null;
  let highColor = null;

  // Find the intervals containing the minimum and maximum MEAN values and define the colors of the values using Lerp.
  for (let i = 0; i < colorIntervals.length - 1; i++) {
    let current = colorIntervals[i], next = colorIntervals[i + 1];

    let currentColor = tinycolor({r: current.r, g: current.g, b: current.b}).toString();
    let nextColor = tinycolor({r: next.r, g: next.g, b: next.b}).toString();

    if (!lowIndex && l <= next.min) {
      // Map the value to a relative position between the current and next interval
      let pos = NumberUtils.map(l, current.min, next.min,0, 1);

      // Find the color of relative position using LERP
      lowColor = lerp(currentColor, nextColor, pos);
      lowIndex = i;
    }

    if (!highIndex && h <= next.min) {
      // Map the value to a relative position between the current and next interval
      let pos = NumberUtils.map(h, current.min, next.min, 0, 1);

      // Find the color of relative position using LERP
      highColor = lerp(currentColor, nextColor, pos);
      highIndex = i;
    }
  }

  // Add the lowest value color at position 0
  gradient.addColorStop(0, lowColor);

  // Add color steps in between 0 and 1
  for (let j = lowIndex + 1; j <= highIndex; j++) {
    let entry = colorIntervals[j];
    let val = entry.min;
    let pos = NumberUtils.map(val, l, h, 0, 1);
    let color = "rgb(" + entry.r + "," + entry.g + "," + entry.b + ")";

    gradient.addColorStop(pos, color);
  }

  // Add the highest value color at position 1
  gradient.addColorStop(1, highColor);

  return gradient;
};

const padding = 12;

const PrescriptionScale = ({layer, intervals, overrideAreas, classificationsEnabled, variationsEnabled, values}) => {

  const LangFile = useLangFile();
  const dimensions = useWindowDimensions();
  const ctxRef = useRef(null);
  const canvasRef = useRef(null);
  const xMap = useRef(null);
  const forceRedraw = useState({});
  const layerConfig = getLayerConfig(layer);
  const classifier = new ClassificationColoringStrategy(layer, ViewModeConstants.PRESCRIPTION).classifier;
  const normalise = variationsEnabled || layer === SURVEY_LAYERS.ALTITUDE;
  const coloringStrategy = useColoringStrategy(layer, ViewModeConstants.PRESCRIPTION, classificationsEnabled, normalise, values);

  // Determine min and max values
  let minValue = Number(intervals[0].min).toFixed(3);
  let maxValue = Number(intervals[intervals.length - 1].max).toFixed(3);

  // Determine overridden area
  let overriddenArea = overrideAreas ?
    Object.values(overrideAreas)
          .reduce((sum, cur) => sum + cur.area, 0) : 0;

  let overriddenAreaString = Number(overriddenArea).toFixed(2);

  // Handlers
  const generateSeparators = useCallback(() => {
    if (!intervals || !xMap.current) {
      return [];
    }

    return intervals
      .filter((interval, idx) => idx !== intervals.length - 1)
      .map((interval, idx) => {
        let nextInterval = intervals[idx + 1];
        let upperBound = nextInterval ? nextInterval.min : interval.max;

        let xPos = xMap.current(upperBound) - 2;

        return [
          <Box left={xPos} key={"handle " + idx + 1} className={style.Handle}/>,
          <Box left={xPos} key={"value " + idx + 1} className={style.Value}>
            {Number(interval.max).toFixed(3)}
          </Box>
        ];
      });
  }, [intervals, xMap]);

  const generateIntervalSizeLabels = useCallback(() => {
    if (!intervals || !xMap.current) {
      return null;
    }

    return intervals.map((interval, idx, array) => {
      let nextInterval = array[idx + 1];
      let lowerBound = interval.min;
      let upperBound = nextInterval ? nextInterval.min : interval.max;

      let xPos = lowerBound + ((upperBound - lowerBound) / 2);
      let area = Number(interval.areaFinal).toFixed(2);

      let icon = null;
      if (classificationsEnabled) {
        let classification = classifier(lowerBound);
        if (classification) {
          icon = renderStandardDemandLegend(classification, LangFile, layer);
        }
      }

      return (
        <IntervalLabel
          key={idx}
          labelText={area + " ha"}
          left={xMap.current(xPos)}
          top={"25px"}>
          {Boolean(icon) && (
            <Box mr={1} className={style.DemandIcon}>
                {icon}
            </Box>
          )}
        </IntervalLabel>
      );
    });
  }, [intervals, layer, xMap, layerConfig, classifier, LangFile, classificationsEnabled]);

  const redrawCanvas = useCallback(() => {
    let context = ctxRef.current;
    let canvas = canvasRef.current;
    let width = canvas.width;
    let height = canvas.height;

    context.clearRect(0, 0, width, height);
    if (isSurveyLayer(layer)) {
      context.fillStyle = generateSurveyGradient(context, width, intervals, coloringStrategy);
    }
    else {
      if (layer === SATELLITE_LAYERS.VITALITY_NDVI) {
        context.fillStyle = generateNdviGradient(context, width, intervals);
      }
      else {
        console.error("Unexpected source layer selected. Raw MEAN should only be possible.", layer);
      }
    }
    context.fillRect(0, 0, width, height);

  }, [ctxRef, layer, intervals, coloringStrategy]);

  const updateDimensions = useCallback(() => {
    if (intervals) {
      let intervalValues = intervals.map((
        interval,
        index
      ) => index === 0 ? interval.min : interval.max);

      // Get the current height and width of the svg parent
      let currentWidth = canvasRef.current.offsetWidth;

      // Calculate the new mappings from domain to pixel space
      xMap.current = d3.scaleLinear()
        .domain(d3.extent(intervalValues, (d) => d))
        .range([0, currentWidth]);

      forceRedraw[1]({});
      redrawCanvas();
    }
  }, [canvasRef, intervals, redrawCanvas]);

  useEvent("resize", updateDimensions);

  useEffect(() => {
    if (canvasRef) {
      ctxRef.current = canvasRef.current.getContext("2d");
    }
  }, [canvasRef]);

  useEffect(() => {
    if (ctxRef.current) {
      updateDimensions();
    }
  }, [updateDimensions]);

  let canvasWidth = dimensions.width - (2 * padding);

  return (
    <Paper className={style.Root} elevation={0}>
      <div className={style.Spacer}/>
      <div className={style.Title}>
        {isSurveyLayer(layer) && (
          LangFile.PrescriptionScale.distributionOfArea + layerConfig.getName(LangFile) + LangFile.PrescriptionScale.distributionOfArea2 + overriddenAreaString + LangFile.PrescriptionScale.distributionOfArea3
        )}
        {isSatelliteLayer(layer) && (
          LangFile.PrescriptionScale.vegetationIndex + overriddenAreaString + LangFile.PrescriptionScale.vegetationIndex2
        )}
      </div>
      <canvas
        width={canvasWidth}
        height={25}
        ref={canvasRef}>
      </canvas>
      <div className={style.LowestValue}>
        {minValue < 0.01 ? Number(minValue).toFixed(0) : minValue}
      </div>
      <div className={style.HighestValue}>
        {maxValue}
      </div>
      {generateSeparators()}
      {generateIntervalSizeLabels()}
    </Paper>
  );
};

PrescriptionScale.propTypes = {
  layer: PropTypes.string,
  intervals: PropTypes.array,
  overrideAreas: PropTypes.object,
  values: PropTypes.array,
  classificationsEnabled: PropTypes.bool,
  variationsEnabled: PropTypes.bool,
};

export default memo(PrescriptionScale);
