import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography/Typography";
import {useLangFile} from "js/context/LanguageContext";
import PrescriptionTableToolbar from "./PrescriptionTableToolbar";
import List from "@material-ui/core/List";
import Styles from "./PrescriptionArchiveTable.module.less";
import PrescriptionArchiveExpandableListItem from "./PrescriptionArchiveExpandableListItem";
import Box from "@material-ui/core/Box";
import {voidFunc} from "../../constants/PropTypeUtils";
import PrescriptionFieldCardItem from "./PrescriptionFieldCardItem";

const PrescriptionArchiveTable = (props: PrescriptionArchiveTable.propTypes) => {
  const LangFile = useLangFile();

  return (
    <Box className={Styles.root}>
      <PrescriptionTableToolbar
        numberOfExcludedFields={props.numberOfExcludedFields}
        onFilterReset={props.onFilterReset}/>
      {props.cards.length === 0 && (
        <Box className={Styles.emptyWrapper}>
          <Typography color="inherit" variant={"h6"}>{LangFile.PrescriptionArchiveTable.empty1}</Typography>
          <Typography color="inherit" variant={"h6"}>{LangFile.PrescriptionArchiveTable.empty2}</Typography>
        </Box>
      )}

      {props.cards.length > 0 && (
        <Box className={Styles.tableWrapper}>
          <List className={Styles.ListItem}>
            {props.cards.map((card: PrescriptionFieldCardItem) => {
              return <PrescriptionArchiveExpandableListItem
                key={card.field.fieldId}
                field={card.field}
                jobs={card.currentMaps}
                outsideJobs={card.outsideMaps}
                documentation={props.documentation}
                onDocumentationUploaded={props.onDocumentationUploaded}
                onDeleteDocumentation={props.onDeleteDocumentation}
                onSetSeason={props.onSetSeason}
                onDownload={props.onDownload}
                onDelete={props.onDelete}
                onEdit={props.onEdit}/>;
            })}
          </List>
        </Box>
      )}
    </Box>
  );
};

PrescriptionArchiveTable.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.instanceOf(PrescriptionFieldCardItem)).isRequired,
  numberOfExcludedFields: PropTypes.number,
  onFilterReset: PropTypes.func,
  onSetSeason: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onDocumentationUploaded: PropTypes.func,
  onDeleteDocumentation: PropTypes.func,
  documentation: PropTypes.object,
};

PrescriptionArchiveTable.defaultProps = {
  cards: [],
  numberOfExcludedFields: 0,
  onFilterReset: voidFunc,
  onSetSeason: voidFunc,
  onEdit: voidFunc,
  onDelete: voidFunc,
  onDownload: voidFunc,
  onDocumentationUploaded: voidFunc,
  onDeleteDocumentation: voidFunc
};

export default memo(PrescriptionArchiveTable);