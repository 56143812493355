import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {voidFunc} from '../../constants/PropTypeUtils';
import {connect} from 'react-redux';
import WeatherOverview from './WeatherOverview';
import {setShowWeatherStations, toggleDisplayDataProperty} from '../../reducers/WeatherReducer';
import {setViewMode} from '../../reducers/ControlReducer';
import ViewModeConstants from '../../constants/ViewModeConstants';
import {goToWeatherStations} from '../../helpers/MapsUtils';
import useFirebaseAnalytics, {FIREBASE_EVENTS} from '../../hooks/useFirebaseAnalytics';
import {setShowWeatherNetwork} from '../../reducers/WeatherNetworkReducer';
import {selectField} from '../../reducers/FieldReducer';
import {useGoogleMap} from '../../context/GoogleMapContext';
import type {WeatherSensorType} from '../../constants/WeatherConstants';

const mapStateToProps = (store) => {
  return {
    showWeatherStations: store.weather.showWeatherStations,
    weatherStations: store.weather.stations,
    displayData: store.weather.displayData,
    showWeatherNetwork: store.weatherNetwork.showWeatherNetwork,
    networkSensor: store.weatherNetwork.networkSensor,
    level: store.weatherNetwork.level
  };
};

const WeatherOverviewContainer = (props: WeatherOverviewContainer.propTypes) => {
  const {dispatch, showWeatherNetwork, weatherStations, networkSensor, level, showWeatherStations, displayData, onClose} = props;

  const analytics = useFirebaseAnalytics();
  const googleMap = useGoogleMap();

  const toggleWeatherStations = useCallback(() => {
    if (googleMap) {
      let show = !showWeatherStations;

      dispatch(setShowWeatherStations(show));

      if (show) {
        dispatch(setViewMode(ViewModeConstants.OVERVIEW));
        goToWeatherStations(googleMap, weatherStations);
        analytics.logEvent(FIREBASE_EVENTS.WEATHER_DRAWER_MY_STATIONS);
      }

      dispatch(setShowWeatherNetwork(false));
      dispatch(selectField(null));
    }
  }, [googleMap, showWeatherStations, weatherStations]);

  const toggleWeatherNetwork = useCallback(() => {
    if (googleMap) {
      let show = !showWeatherNetwork;

      dispatch(setShowWeatherNetwork(show));

      if (show) {
        dispatch(setShowWeatherStations(false));
        analytics.logEvent(FIREBASE_EVENTS.WEATHER_DRAWER_NETWORK);
      }
    }
  }, [googleMap, networkSensor, showWeatherNetwork, level]);

  const handleSetWeatherData = useCallback((sensor: WeatherSensorType, shown) => {
    let firebaseSuffix = "";
    switch (sensor) {
      case "WIND":
        firebaseSuffix = "AvgWind";
        break;
      case "UV":
        firebaseSuffix = "UV";
        break;
      case "HUMIDITY":
        firebaseSuffix = "Humidity";
        break;
      case "AIR_TEMP":
        firebaseSuffix = "AirTemp";
        break;
      case "LUX":
        firebaseSuffix = "Luminosity";
        break;
      case "SOIL_TEMP":
        firebaseSuffix = "SoilTemp";
        break;
      case "PRESSURE":
        firebaseSuffix = "Pressure";
        break;
      case "RAIN":
        firebaseSuffix = "Rain";
        break;
      case "WIND_MAX":
        firebaseSuffix = "WindGusts";
        break;
      default:
        break;
    }
    analytics.logEvent(FIREBASE_EVENTS.WEATHER_STATION_LAYER+firebaseSuffix, {shown: shown});

    dispatch(toggleDisplayDataProperty(sensor, shown));
  }, []);

  const handleOnSensorClick = (sensor) => useCallback(() => {
    handleSetWeatherData(sensor, !displayData[sensor]);
  }, [handleSetWeatherData, displayData]);

  return (
    <WeatherOverview
      showWeatherStations={showWeatherStations}
      showWeatherNetwork={showWeatherNetwork}
      displayData={displayData}
      onCloseClicked={onClose}
      onToggleWeatherStations={toggleWeatherStations}
      onToggleWeatherNetwork={toggleWeatherNetwork}
      onToggleWeatherStationSensor={handleOnSensorClick}/>
  );
};

WeatherOverviewContainer.propTypes = {
  onClose: PropTypes.func
};

WeatherOverviewContainer.defaultProps = {
  onClose: voidFunc
};

export default memo(connect(mapStateToProps)(WeatherOverviewContainer));