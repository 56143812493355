"use strict";

export default class NumberUtils {
  static limit (val, min, max) {
    return Math.min(Math.max(val, min), max);
  }

  static map(val, inMin, inMax, outMin, outMax) {
    return (val - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  }

  static limitMap(val, inMin, inMax, outMin, outMax) {
    return NumberUtils.limit(NumberUtils.map(val, inMin, inMax, outMin, outMax), outMin, outMax);
  }

  static median(args) {
    if (!args.length) {
      return 0;
    }

    let numbers = args.slice(0).sort((a,b) => a - b);
    let middle = Math.floor(numbers.length / 2);
    let isEven = numbers.length % 2 === 0;
    return isEven ? (numbers[middle] + numbers[middle - 1]) / 2 : numbers[middle];
  }


}

export function* range(start, end, stepSize) {
  yield start;
  if (start === end) {
    return;
  }
  yield* range(start + stepSize, end);
}

export function random(min, max) {
  return Math.random() * (max - min + 1) + min;
}

export function getMax(arr) {
  let len = arr.length;
  let max = -Infinity;

  while (len--) {
    max = arr[len] > max ? arr[len] : max;
  }
  return max;
}

export function getMin(arr) {
  let len = arr.length;
  let min = Infinity;

  while (len--) {
    min = arr[len] < min ? arr[len] : min;
  }
  return min;
}

export function truncateNumber(num, decimals) {
  let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (decimals || -1) + '})?');
  let match = num.toString().match(re);

  if (Array.isArray(match) && match.length > 0) {
    return match[0];
  }
  else {
    return num;
  }
}