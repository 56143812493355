import moment from "moment";

import * as d3 from "d3";
import {
  WeatherPeriod,
  WeatherSensor, WindDirection,
} from "js/constants/WeatherConstants";
import ManualFeatureConstants from "../constants/ManualFeatureConstants";

export const getXDomain = (since, until) => {
  return [moment(since).valueOf(), moment(until).valueOf()];
};

export const periodTicks = (since, until) => {
  let a = moment(until);
  let b = moment(since);

  let days = Math.abs(a.diff(b, "days"));
  let hours = Math.abs(a.diff(b, "hours"));

  if (days <= 1) {
    if (hours <= 12) {
      return d3.timeHour.every(2);
    }
    else {
      return d3.timeHour.every(6);
    }
  }
  else if (days <= 2) {
    return d3.timeHour.every(6);
  }
  else if (days <= 7) {
    return d3.timeDay.every(1);
  }
  else if (days <= 14) {
    return d3.timeDay.every(1);
  }
  else if (days <= 100) {
    return d3.timeMonday.every(1);
  }
  else {
    return d3.timeMonth.every(1);
  }
};

export const periodResolution = (periodStart, periodEnd, sensor) => {
  let a = moment(periodEnd);
  let b = moment(periodStart);
  let days = a.diff(b, "days");

  if (sensor === WeatherSensor.LUX || sensor === WeatherSensor.UV || sensor === WeatherSensor.SOLAR_RADIATION) {
    if (days <= 3) {
      return '1h';
    }
    else if (days <= 32) {
      return '1d';
    }
    else {
      return '1w';
    }
  }
  else {
    if (days < 3) {
      return '';
    }
    else if (days < 9) {
      return '30m';
    }
    else if (days < 17) {
      return '1h';
    }
    else if (days < 34) {
      return '2h';
    }
    else if (days < 67) {
      return '4h';
    }
    else if (days < 100) {
      return '6h';
    }
    else if (days < 201) {
      return '12h';
    }
    else {
      return '24h';
    }
  }
};

export const getWeatherDataPeriod = (weatherData) => {
  let dataset = Object.values(weatherData)[0];

  let since = moment(dataset.since);
  let until = moment(dataset.until || moment());

  return {since, until};
};

export const stationHasData = (station) => {
  return station.current && Object.keys(WeatherSensor).reduce((acc, key) => {
    let value = getCurrentSensorReading(station, key);
    return acc || value != null;
  }, false);
};

export const stationHasBrokenGps = (station) => {
  let location = station.location;
  return station.status !== "initializing" && stationHasData(station) && (!location || !location.latitude || !location.longitude);
};

export const getCurrentSensorReading = (station, weatherSensor) => {
  const current = station.current;

  if (!current) return null;
  switch (weatherSensor) {
    case WeatherSensor.AIR_TEMP:
      return current.airTemp;
    case WeatherSensor.SOIL_TEMP:
      return current.soilTemp;
    case WeatherSensor.HUMIDITY:
      return current.humidity;
    case WeatherSensor.RAIN:
      return current.rainAcc;
    case WeatherSensor.UV:
      if (current.uv) {
        return current.uv;
      }
      else if (current.forecast && current.forecast.uv) {
        return current.forecast.uv;
      }
      return null;
    case WeatherSensor.LUX:
      return current.lux;
    case WeatherSensor.PRESSURE:
      return current.pressure;
    case WeatherSensor.WIND:
      return current.wind;
    case WeatherSensor.WIND_MAX:
      return current.windMax;
    case WeatherSensor.PRESSURE_TREND:
      return current.pressureTrend;
    case WeatherSensor.WIND_DIRECTION:
      if (current.windDirection) {
        return current.windDirection;
      }
      else if (current.forecast && current.forecast.windDirection) {
        return current.forecast.windDirection;
      }
      return null;
    default:
      return null;
  }
};

export const getCurrentWindDirectionShort = (windDirection, LangFile) => {
  if (!windDirection) return null;
  switch (windDirection) {
    case WindDirection.E:
      return LangFile.WeatherConstants.windDirection.e;
    case WindDirection.ENE:
      return LangFile.WeatherConstants.windDirection.ene;
    case WindDirection.ESE:
      return LangFile.WeatherConstants.windDirection.ese;
    case WindDirection.N:
      return LangFile.WeatherConstants.windDirection.n;
    case WindDirection.NE:
      return LangFile.WeatherConstants.windDirection.ne;
    case WindDirection.NNE:
      return LangFile.WeatherConstants.windDirection.nne;
    case WindDirection.NNW:
      return LangFile.WeatherConstants.windDirection.nnw;
    case WindDirection.NW:
      return LangFile.WeatherConstants.windDirection.nw;
    case WindDirection.S:
      return LangFile.WeatherConstants.windDirection.s;
    case WindDirection.SE:
      return LangFile.WeatherConstants.windDirection.se;
    case WindDirection.SSE:
      return LangFile.WeatherConstants.windDirection.sse;
    case WindDirection.SSW:
      return LangFile.WeatherConstants.windDirection.ssw;
    case WindDirection.SW:
      return LangFile.WeatherConstants.windDirection.sw;
    case WindDirection.W:
      return LangFile.WeatherConstants.windDirection.w;
    case WindDirection.WNW:
      return LangFile.WeatherConstants.windDirection.wnw;
    case WindDirection.WSW:
      return LangFile.WeatherConstants.windDirection.wsw;
    default:
      return null;
  }
};

export const getCurrentWindDirection = (windDirection, LangFile) => {
  if (!windDirection) return null;
  switch (windDirection) {
    case WindDirection.E:
      return LangFile.WeatherConstants.windDirection.east;
    case WindDirection.ENE:
      return LangFile.WeatherConstants.windDirection.eastNorthEast;
    case WindDirection.ESE:
      return LangFile.WeatherConstants.windDirection.eastSouthEast;
    case WindDirection.N:
      return LangFile.WeatherConstants.windDirection.north;
    case WindDirection.NE:
      return LangFile.WeatherConstants.windDirection.northEast;
    case WindDirection.NNE:
      return LangFile.WeatherConstants.windDirection.northNorthEast;
    case WindDirection.NNW:
      return LangFile.WeatherConstants.windDirection.northNorthWest;
    case WindDirection.NW:
      return LangFile.WeatherConstants.windDirection.northWest;
    case WindDirection.S:
      return LangFile.WeatherConstants.windDirection.south;
    case WindDirection.SE:
      return LangFile.WeatherConstants.windDirection.southEast;
    case WindDirection.SSE:
      return LangFile.WeatherConstants.windDirection.southSouthEast;
    case WindDirection.SSW:
      return LangFile.WeatherConstants.windDirection.southSouthWest;
    case WindDirection.SW:
      return LangFile.WeatherConstants.windDirection.southWest;
    case WindDirection.W:
      return LangFile.WeatherConstants.windDirection.west;
    case WindDirection.WNW:
      return LangFile.WeatherConstants.windDirection.westNorthWest;
    case WindDirection.WSW:
      return LangFile.WeatherConstants.windDirection.westSouthWest;
    default:
      return null;
  }
};

export const getPresetPeriod = (now, preset) => {
  let start, end;

  switch (preset) {
    case WeatherPeriod.DAY: {

      let offset = now.hours() % 2 === 0 ? 2 : 1;

      end = now
        .clone()
        .add(offset, 'hours')
        .startOf('hour');

      start = end
        .clone()
        .subtract(24, 'hours')
        .startOf('hour');

      break;
    }
    case WeatherPeriod.WEEK: {
      end = now.clone().endOf('day');

      start = end
        .clone()
        .subtract(7, 'days')
        .startOf("day");

      break;
    }
    case WeatherPeriod.WEEKS4: {
      end = now
        .clone()
        .add(1, "week")
        .startOf('isoWeek');

      start = end
        .clone()
        .subtract(5, 'weeks')
        .startOf("isoWeek");

      break;
    }
    default: {
      // Invalid
    }
  }

  return {start, end};
};

export const parseResolution = (resolution) => {

  if (!resolution) {
    return {count: null, unitOfTime: null};
  }

  let countExp = /^\d*/;
  let unitOfTimeExp = /[a-z]$/i;

  let count = Number(resolution.match(countExp)[0]);
  let unitOfTime = resolution.match(unitOfTimeExp)[0];

  return {
    count,
    unitOfTime
  };
};

export const isBarIncomplete = (val, until, resolution) => {
  let {count, unitOfTime} = parseResolution(resolution);
  let diff = Math.abs(moment(val).diff(moment(until), unitOfTime));

  return diff < count;
};

const WEATHER_MAXIMUM_TIME_GAP_DEFAULT = 30; // 30 minutes
const WEATHER_MAXIMUM_TIME_GAP_MAKEUP = 4 * 60; // 4 hours

export const getDatasetMaxGapMinutes = (dataset) => {
  if (ManualFeatureConstants.ENABLE_WEATHER_MAKEUP ) {
    let {count, unitOfTime} = parseResolution(dataset.resolution);

    // Use the resolution of the dataset as the basic bucket size in minutes
    let bucketSizeMinutes = moment.duration(count, unitOfTime).asMinutes();

    // Define how many buckets with missing data to hide
    let bucketsToHide = 30000;

    // Calculate the maximum gap of missing data in minutes
    let maximumGapMinutes = bucketSizeMinutes * bucketsToHide;

    // Always close gaps WEATHER_MAXIMUM_TIME_GAP_MAKEUP and below
    return Math.max(WEATHER_MAXIMUM_TIME_GAP_MAKEUP, maximumGapMinutes);
  }
  else {
    // Default size of gaps to hide is WEATHER_MAXIMUM_TIME_GAP_DEFAULT
    return WEATHER_MAXIMUM_TIME_GAP_DEFAULT;
  }
};