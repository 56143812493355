import {KEYS} from "../reducers/AppSettingsReducer";
import {WeatherSensor} from "../constants/WeatherConstants";
import {
  RAIN_CONSTANTS,
  TEMPERATURE,
  TEMPERATURE_CONSTANTS,
  WIND,
  WIND_CONSTANTS
} from "../constants/MeasureConstants";
import NetworkSensor from "../model/network/NetworkSensor";
import {RAIN} from "js/constants/MeasureConstants";

export function mapMeasurements(values, sensor, measureSettings) {
  let temperatureUnit = measureSettings[KEYS.MEASURE_UNIT_TEMPERATURE];
  let windUnit = measureSettings[KEYS.MEASURE_UNIT_WIND];
  let rainUnit = measureSettings[KEYS.MEASURE_UNIT_RAIN];

  switch (sensor) {
    case WeatherSensor.SOIL_TEMP:
    case WeatherSensor.AIR_TEMP:
    case WeatherSensor.COMBINED_TEMP: {
      return values.map((entry) => {
        entry.value = mapTemperatureMeasurement(entry.value, TEMPERATURE.CELCIUS, temperatureUnit);
        return entry;
      });
    }
    case WeatherSensor.WIND:
    case WeatherSensor.WIND_MAX: {
      return values.map((entry) => {
        entry.value = mapWindMeasurement(entry.value, WIND.METERS_PER_SECOND, windUnit);
        return entry;
      });
    }
    case WeatherSensor.RAIN:
    case WeatherSensor.RAIN_HOURLY:
    case WeatherSensor.RAIN_DAILY:
    case WeatherSensor.RAIN_WEEKLY: {
      return values.map((entry) => {
        entry.value = mapRainMeasurement(entry.value, RAIN.MILLIMETERS, rainUnit);
        return entry;
      });
    }
    default:
      return values;
  }
}

export function mapCurrentMeasurements(current, measureSettings) {
  let temperatureUnit = measureSettings[KEYS.MEASURE_UNIT_TEMPERATURE];
  let windUnit = measureSettings[KEYS.MEASURE_UNIT_WIND];
  let rainUnit = measureSettings[KEYS.MEASURE_UNIT_RAIN];

  let result = {...current};
  result.rain1H = mapRainMeasurement(result.rain1H, RAIN.MILLIMETERS, rainUnit);
  result.rainAcc = mapRainMeasurement(result.rainAcc, RAIN.MILLIMETERS, rainUnit);
  result.airTemp = mapTemperatureMeasurement(result.airTemp, TEMPERATURE.CELCIUS, temperatureUnit);
  result.soilTemp = mapTemperatureMeasurement(result.soilTemp, TEMPERATURE.CELCIUS, temperatureUnit);
  result.wind = mapWindMeasurement(result.wind, WIND.METERS_PER_SECOND, windUnit);
  result.windMax = mapWindMeasurement(result.windMax, WIND.METERS_PER_SECOND, windUnit);

  // airTemp: 16.23
  // humidity: 56
  // lux: 9430
  // pressure: 997.483
  // pressureTrend: "rising"
  // rain1H: 0
  // rainAcc: 7
  // soilTemp: 14.37
  // uv: 1.29
  // wind: 5.777698
  // windMax: 8.7823

  return result;
}

export function mapNetworkClusterMeasurement(cluster, sensor, measureSettings) {
  let temperatureUnit = measureSettings[KEYS.MEASURE_UNIT_TEMPERATURE];
  let windUnit = measureSettings[KEYS.MEASURE_UNIT_WIND];
  let rainUnit = measureSettings[KEYS.MEASURE_UNIT_RAIN];

  switch (sensor) {
    case NetworkSensor.WIND_AVG: {
      if (cluster.value) {
        return {...cluster, value: mapWindMeasurement(cluster.value, WIND.METERS_PER_SECOND, windUnit)};
      }
      break;
    }
    case NetworkSensor.AIR_TEMP_24H:
    case NetworkSensor.SOIL_TEMP_24H: {
      if (cluster.min && cluster.max) {
        return {
          ...cluster,
          minValue: mapTemperatureMeasurement(cluster.minValue, TEMPERATURE.CELCIUS, temperatureUnit),
          min: cluster.min.map((value) => {
            return mapTemperatureMeasurement(value, TEMPERATURE.CELCIUS, temperatureUnit);
          }),
          maxValue: mapTemperatureMeasurement(cluster.maxValue, TEMPERATURE.CELCIUS, temperatureUnit),
          max: cluster.max.map((value) => {
            return mapTemperatureMeasurement(value, TEMPERATURE.CELCIUS, temperatureUnit);
          })
        };
      }
      break;
    }
    case NetworkSensor.RAIN_ACC: {
      if (cluster.accumulatedMax) {
        return {...cluster, accumulatedMax: mapRainMeasurement(cluster.accumulatedMax, RAIN.MILLIMETERS, rainUnit)};
      }
      break;
    }
    case NetworkSensor.RAIN_24H: {
      if (cluster.min && cluster.max) {
        return {
          ...cluster,
          minValue: mapRainMeasurement(cluster.minValue, RAIN.MILLIMETERS, rainUnit),
          min: cluster.min.map((value) => {
            return mapRainMeasurement(value, RAIN.MILLIMETERS, rainUnit);
          }),
          maxValue: mapRainMeasurement(cluster.maxValue, RAIN.MILLIMETERS, rainUnit),
          max: cluster.max.map((value) => {
            return mapRainMeasurement(value, RAIN.MILLIMETERS, rainUnit);
          })
        };
      }
      break;
    }
    default:
      return cluster;
  }

  return cluster;

// case NetworkSensor.RAIN_24H:
// case NetworkSensor.SOIL_TEMP_24H:
// case NetworkSensor.AIR_TEMP_24H:
//   return roundValue(cluster.max[dateIndex]);
// case NetworkSensor.RAIN_ACC:
//   return roundValue(cluster.accumulatedMax);
// case NetworkSensor.WIND_AVG:
//   return roundValue(cluster.value);
// default:
//   return roundValue(cluster.max);
}

export function mapTemperatureMeasurement(value, from, to) {
  return TEMPERATURE_CONSTANTS[from].mapTo(value, to);
}

export function mapWindMeasurement(value, from, to) {
  return WIND_CONSTANTS[from].mapTo(value, to);
}

export function mapRainMeasurement(value, from, to) {
  return RAIN_CONSTANTS[from].mapTo(value, to);
}