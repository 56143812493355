"use strict";

import PropTypes from "prop-types";

import React, {memo, useCallback} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  DialogActions,
} from "@material-ui/core";
import SelectFarmMenu from "./SelectFarmMenu";
import {useLangFile} from "../context/LanguageContext";
import Styles from "./SelectFarmDialog.module.less";

const SelectFarmDialog = (props) => {

  const LangFile = useLangFile();

  const handleSelection = useCallback((farm) => {
    props.onFarmSelected(farm);
    props.onClose();
  }, [props.onFarmSelected, props.onClose]);

  if (!props.farm) {
    return (
      <CircularProgress size={60} thickness={5}/>
    );
  }

  return (
    <Dialog
      fullWidth
      disableBackdropClick={props.disableBackdropClick}
      disableEscapeKeyDown={true}
      maxWidth={"md"}
      open={Boolean(props.shown)}
      onClose={props.onClose}>
      <DialogTitle>
        {LangFile.SelectFarmDialog.title}
      </DialogTitle>
      <DialogContent className={Styles.DialogContent}>
        <SelectFarmMenu
          inline={true}
          onClose={props.onClose}
          farm={props.farm}
          otherFarms={props.otherFarms}
          onFarmSelected={handleSelection}/>
      </DialogContent>
      <DialogActions>
        <Button color={"primary"} variant={"outlined"} onClick={props.onClose}>
          {LangFile.SelectFarmDialog.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SelectFarmDialog.propTypes = {
  /**
   * Boolean controlling whether the Dialog is shown.
   */
  shown: PropTypes.bool,
  /**
   * Disable backdrop click to keep the dialog open until an action has been made.
   */
  disableBackdropClick: PropTypes.bool,
  /**
   * The currently selected farm
   */
  farm: PropTypes.object.isRequired,
  /**
   * Farms to select from.
   */
  otherFarms: PropTypes.array.isRequired,
  /**
   * Callback function to set state when the dialog closes.
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Callback function to handle selection of a farm.
   * @param {object} farm The selected farm
   */
  onFarmSelected: PropTypes.func.isRequired
};

export default memo(SelectFarmDialog);
