import React, {
  memo
} from 'react';

import PropTypes from 'prop-types';
import WeatherStationMarker from "js/components/WeatherDrawer/WeatherStationMarker";

const WeatherMarkers = ({shown, stations, googleMap, displayData, selectedStation, selectStation}) => {
  return shown && stations.filter((station) => station.status !== "retired").map((station) =>
    <WeatherStationMarker
      key={station.identifier}
      station={station}
      googleMap={googleMap}
      displayData={displayData}
      selectedStation={selectedStation}
      onSelectStation={selectStation}/>
  );
};

WeatherMarkers.propTypes = {
  shown: PropTypes.bool,
  stations: PropTypes.array,
  googleMap: PropTypes.object,
  displayData: PropTypes.object,
  selectedStation: PropTypes.object,
  selectStation: PropTypes.func,
};

export default memo(WeatherMarkers);