import { useDrag, useDrop } from 'react-dnd';
import React from 'react';
import HeaderRenderer from './HeaderRenderer';
import {Box} from '@material-ui/core';

const DraggableHeaderRenderer = ({
                                   onColumnsReorder,
                                   column,
                                   ...props
                                 }) => {

  const [{ isDragging }, drag] = useDrag({
    type: 'COLUMN_DRAG',
    item: { key: column.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'COLUMN_DRAG',
    drop({ key }: { key: string }) {
      onColumnsReorder(key, column.key);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <Box
      ref={(ref) => {
        drag(ref);
        drop(ref);
      }}
      style={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isOver ? '#ececec' : undefined,
        cursor: 'move',
        height: '100%'
      }}>
      <HeaderRenderer column={column} {...props }/>
    </Box>
  );
};

export default DraggableHeaderRenderer;
