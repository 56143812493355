import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {Box, Tooltip} from "@material-ui/core";
import {useLangFile} from "../../context/LanguageContext";

const styles = (theme) => ({
  wrapper: {
    position: 'relative',
    width: '100%',
    cursor: 'help',
  },
  scale: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: '100%',
    position: "relative"
  },
  scaleStep: {
    lineHeight: '20px',
  },
  minMaxTick: {
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: "none",
    top: "50%",
    transform: "translate(0, -50%)",
    minWidth: "2px",
    height: "100%",
    fontSize: "0.75rem",
    backgroundColor: "white"
  }
});

const getTooltip = (step, idx, arr, continuous, decimals = 2, unitString) => {
  let tooltip = "";
  let unit = "";

  if (unitString !== undefined && unitString !== null) {
    unit = unitString;
  }

  if ((step.min === null || step.min === undefined) && !step.max) {
    return null;
  }

  let min = Number(step.min).toFixed(decimals);
  let max = Number(step.max).toFixed(decimals);

  if (step.max === Number.MAX_VALUE) {
    return `[${min.toString().replace(".00", "") + unit}[`;
  }

  if (min === max) {
    return min.toString().replace(".00", "") + unit; // remove trailing zeros for integer values
  }

  if (idx === 0) {
    if (continuous) {
      tooltip += `< ${max}${unit}`;
    }
    else {
      tooltip += `[${min}${unit}`;

      if (max) {
        tooltip += ` - ${max}${unit}[`;
      }
      else {
        tooltip += `]`;
      }
    }
  }
  else if (idx === arr.length - 1) {
    if (continuous) {
      tooltip += `${min}${unit} <`;
    }
    else {
      tooltip += `[${min}${unit} - ${max}${unit}]`;
    }
  }
  else {
    if (max) {
      tooltip += `[${min}${unit} - ${max}${unit}[`;
    }
    else {
      tooltip += `[${min}${unit}]`;
    }
  }

  return (
    <div style={{display: 'inline-block'}}>
      <div style={{width: '100%', height: 12, backgroundColor: step.color}}/>
      {tooltip}
    </div>
  );
};

const InlineColorScale = (props: InlineColorScale.propTypes) => {

  const classes = props.classes;
  const LangFile = useLangFile();

  if (props.scale == null) {
    return null;
  }

  return (
    <Box width={"100%"}>
      <Tooltip title={`${props.tooltip}`} disableHoverListener={!props.tooltip} placement={"right"}>
        <div className={classes.wrapper}>
          <div className={classes.scale}>
            {[...props.scale].map((step, idx, arr) => {
                let stepTooltip = getTooltip(step, idx, arr, props.continuous, props.decimals, props.unit);

                if (props.showLabel) {
                  stepTooltip = step.label;
                }

                let body = (
                  <Box
                    bgcolor={step.color}
                    color={step.contrast}
                    className={classes.scaleStep}
                    flex={1}
                    height={"16px"}
                    key={idx}/>
                );

                if (stepTooltip) {
                  return (
                    <Tooltip key={idx} title={stepTooltip} placement={"bottom"} disableHoverListener={!stepTooltip || props.disableStepHover}>
                      {body}
                    </Tooltip>
                  );
                }
                else {
                  return body;
                }
              }
            )}

            {props.showMinMax && (
                <Box className={classes.minMaxTick} left={`${props.minPercent}%`}>
                  <Box position={"relative"} height={"100%"}>
                    <Box position={"absolute"} left={"50%"} top={"100%"} style={{transform: `translate(${props.minPercent === 0 ? 0 : -50}%, -2px)`}} color={"black"}>
                      {LangFile.InlineColorScale.low}
                    </Box>
                  </Box>
                </Box>
            )}

            {props.showMinMax && (
              <Box className={classes.minMaxTick} right={`${100-props.maxPercent}%`}>
                <Box position={"relative"} height={"100%"}>
                  <Box position={"absolute"} right={"50%"} top={"100%"} style={{transform: `translate(${props.maxPercent === 100 ? 0 : 50}%, -2px)`}} color={"black"}>
                    {LangFile.InlineColorScale.high}
                  </Box>
                </Box>
              </Box>
            )}
          </div>
        </div>
      </Tooltip>
    </Box>
  );
};

InlineColorScale.propTypes = {
  scale: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    contrast: PropTypes.string.isRequired,
  })),
  continuous: PropTypes.bool,
  showMinMax: PropTypes.bool,
  showLabel: PropTypes.bool,
  tooltip: PropTypes.string,
  minPercent: PropTypes.number,
  maxPercent: PropTypes.number,
  decimals: PropTypes.number,
  unit: PropTypes.string,
  disableStepHover: PropTypes.bool
};

InlineColorScale.defaultProps = {
  continuous: false,
  minPercent: 0,
  maxPercent: 100,
  decimals: 2,
  disableStepHover: false
};

export default withStyles(styles)(InlineColorScale);
