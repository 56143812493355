import {saveData} from "js/DownloadFile";
import {WeatherSensor} from "js/constants/WeatherConstants";
import WebAPIUtils from "js/WebAPIUtils";
import moment from "moment";
import {getWeatherSensorUnit} from "js/context/AppSettings/AppSettingsContext";
import type {LanguageFile} from "./LanguageUtils";
import JSZip from "jszip";
import {getGddData} from "../reducers/WeatherReducer";
import ExcelJS from 'exceljs/dist/exceljs';
import {periodResolution} from './WeatherUtils';

const sensors = [
  WeatherSensor.AIR_TEMP,
  WeatherSensor.GDD,
  WeatherSensor.SOIL_TEMP,
  WeatherSensor.RAIN,
  WeatherSensor.PRESSURE,
  WeatherSensor.HUMIDITY,
  WeatherSensor.UV,
  WeatherSensor.SOLAR_RADIATION,
  WeatherSensor.WIND,
  WeatherSensor.WIND_MAX,
];

export function weatherDataToCSV(measureSettings, weatherData, LangFile: LanguageFile) {
  let zip = new JSZip();

  let resolutionDatasets = {};

  // Find out what datasets we have with different resolutions
  Object.keys(weatherData).forEach((sensor) => {
    let dataset = weatherData[sensor];
    let resolution = dataset.resolution;
    let existing = resolutionDatasets[resolution] || {};

    existing[sensor] = dataset;

    resolutionDatasets[resolution] = existing;
  });

  // Create a csv file for each dataset
  Object.keys(resolutionDatasets).forEach((resolution) => {
    let datasets = resolutionDatasets[resolution];

    let columns = Object.keys(datasets).reduce((acc, key, idx) => {
      return [...acc, {key: key, order: idx + 1, label: LangFile.ExportUtils.label[key] + ` (${getWeatherSensorUnit(measureSettings, key, true)})`}];
    }, [
      {key: "timestamp", order: 0, label: LangFile.ExportUtils.label.timestamp + " (UTC-0)"},
    ]);

    let csvContent = mergeDatasetsToCSV(Object.values(datasets), "timestamp", columns);

    let suffix = "raw";
    if (resolution && resolution !== "undefined") {
      suffix = resolution;
    }

    const filename = `weather-readings-${suffix}.csv`;

    zip.file(filename, csvContent);
  });

  // pack all csv files in a zip
  zip.generateAsync({type: "blob"})
    .then(function (content) {
      saveData(content, `fieldsense-data-${moment().format("YYYY-MM-DD")}.zip`);
    });
}

function mergeDatasetsToCSV(datasets, mergeKey, columns) {
  let data = {};

  let sortedColumns = columns.sort((a,b) => a.order - b.order);

  // Merge readings on mergeKey
  datasets.map((dataset) => {
    let sensor = dataset.sensor;
    let points = dataset.values;

    points.forEach((point) => {
      if (!dataset.incompleteBuckets.includes(point.timestamp)) {
        let value = point.value;
        let existing = data[point[mergeKey]] || {};
        existing[sensor] = value;
        data[point[mergeKey]] = existing;
      }
    });
  });

  // Flatten key-value pairs to an array of objects containing values for each column
  let readings = Object.keys(data).map((key) => {
    return {[mergeKey]: key, ...data[key]};
  });

  // Create an array of rows, where each row contains an array of values sorted by the column order
  let rows = [sortedColumns.map((h) => h.label), ...readings.map((reading) => {
    return sortedColumns.map((column, idx) => {
      if (idx === 0) {
        return `${moment(reading[column.key]).utc(true).format('YYYY-MM-DD HH:mm:ss.000')}`;
      }
      else {
        return Number(reading[column.key]).toFixed(2).toString().replace(".", ",");
      }
    });
  })];
  
  // Join all rows to a CSV string
  return rows.reduce((acc, row) => {
    return acc + `${row.join(';')}\n`;
  }, "");
}

const fetchReadings = (farmId, sensors, stationLabel, since, until) => {
  return sensors.map((sensor) => {
    let requestSince = since;
    let requestUntil = until;
    if (sensor === WeatherSensor.SOLAR_RADIATION || sensor === WeatherSensor.UV) {
      if (requestUntil.isAfter(moment())) {
        requestUntil = moment();
      }
    }

    let resolution = periodResolution(requestSince, requestUntil, sensor);
    if (sensor === WeatherSensor.GDD) {
      return WebAPIUtils.fetchReadings(stationLabel, farmId, requestSince, requestUntil, "1d", WeatherSensor.AIR_TEMP)
        .then((result) => getGddData(result, 5.0, 30.0));
    }
    else if (sensor === WeatherSensor.SOLAR_RADIATION) {
      return WebAPIUtils.fetchForecastReadings(stationLabel, farmId, requestSince, requestUntil, resolution, sensor).then((result) => {
        let values = [];
        if (result.histories.length > 0) {
          values = result.histories[0].values;
        }
        return {values, sensor, resolution, incompleteBuckets: []};
      });
    }
    else if (sensor === WeatherSensor.UV) {
      return WebAPIUtils.fetchReadings(stationLabel, farmId, requestSince, requestUntil, resolution, sensor)
        .then((result) => WebAPIUtils.fetchForecastReadings(stationLabel, farmId, requestSince, requestUntil, resolution, 'UV_INDEX').then((forecastResult) => {
            let values = result.values;
            if (forecastResult.histories.length > 0) {
              const forecastValues = forecastResult.histories[0].values;
              if (values.length === 0) {
                values = forecastValues;
              }
              else {
                values = values.map((hardwareValue) => {
                  if (hardwareValue.value === null) {
                    const forecastValue = forecastValues.find(({timestamp}) => timestamp === hardwareValue.timestamp);
                    if (forecastValue) {
                      return forecastValue;
                    }
                  }
                  return hardwareValue;
                });
              }
            }
            return {...result, values, sensor, resolution, incompleteBuckets: []};
          })
        );
    }
    return WebAPIUtils.fetchReadings(stationLabel, farmId, requestSince, requestUntil, resolution, sensor);
  });
};

export const bulkDownloadCSV = (LangFile: LanguageFile, farmId, measureSettings, stationLabel, stationName, since, until) => {
  let zip = new JSZip();

  const promises = fetchReadings(farmId, sensors, stationLabel, since, until);

  Promise.all(promises).then((datasets) => {
    let columns = sensors.reduce((acc, key, idx) => {
      return [...acc, {key: key, order: idx + 1, label: LangFile.ExportUtils.label[key] + ` (${getWeatherSensorUnit(measureSettings, key)})`}];
    }, [
      {key: "timestamp", order: 0, label: LangFile.ExportUtils.label.timestamp + " (UTC)"}
    ]);

    let csvContent = mergeDatasetsToCSV(datasets, "timestamp", columns);
    let format = "YYYY-MM-DD-HH-mm";
    let filename =`${stationName} ${moment(since).format(format)} - ${moment(until).format(format)}.csv`;

    zip.file(filename, csvContent);
  }).then(() => {
    // pack all csv files in a zip
    zip.generateAsync({type: "blob"})
      .then(function (content) {
        saveData(content, `fieldsense-data-${moment().format("YYYY-MM-DD")}.zip`);
      });
  });
};

export const bulkDownloadExcel = (LangFile: LanguageFile, farmId, measureSettings, stationLabel, stationName, since, until, resolution) => {
  let zip = new JSZip();

  const promises = fetchReadings(farmId, sensors, stationLabel, since, until, resolution);

  Promise.all(promises).then((datasets) => {
    let exportData = [];
    datasets.forEach((dataSet) => {
      const values = dataSet.values.filter((value) => !dataSet.incompleteBuckets.includes(value.timestamp));
      if (values.length) {
        let resolution = "10m";
        if (typeof dataSet.resolution !== 'undefined') {
          resolution = dataSet.resolution;
        }
        exportData.push({ sensor: dataSet.sensor, resolution: resolution, values: values });
      }
    });

    let columns = [];
    const grouped = groupBy(exportData, (exportData) => exportData.resolution);
    for (let data of grouped.values()) {
      columns.push(data.reduce((acc, key, idx) => {
        return [...acc, {key: key.sensor, order: idx + 1, label: LangFile.ExportUtils.label[key.sensor] + ` (${getWeatherSensorUnit(measureSettings, key.sensor)})`, resolution: key.resolution}];
      }, [
        {key: "timestamp", order: 0, label: LangFile.ExportUtils.label.timestamp + " (UTC)"}
      ]).sort((a,b) => a.order - b.order));
    }

    mergeDatasetsToExcel(grouped, "timestamp", columns).then((value) => {
      let format = "YYYY-MM-DD-HH-mm";
      let filename =`${stationName} ${moment(since).format(format)} - ${moment(until).format(format)}.xlsx`;
      zip.file(filename, value);
    }).then(() => {
      // pack all csv files in a zip
      zip.generateAsync({type: "blob"})
        .then(function (content) {
          saveData(content, `fieldsense-data-${moment().format("YYYY-MM-DD")}.zip`);
        });
    });
  });
};

function mergeDatasetsToExcel(datasets, mergeKey, columns) {
  let data = {};
  let sortedColumns = columns.sort((a,b) => b.length - a.length);

  // Merge readings on mergeKey
  datasets.forEach((dataSet) => {
    dataSet.map((dataset) => {
      let sensor = dataset.sensor;
      let resolution = "10m";
      if (typeof dataset.resolution !== 'undefined') {
        resolution = dataset.resolution;
      }

      let points = dataset.values;
      let existing = data[resolution] || {};
      points.forEach((point) => {
        let value = point.value;
        let innerExisting = existing[point[mergeKey]] || {};
        innerExisting[sensor] = value;
        existing[point[mergeKey]] = innerExisting;
      });
      data[resolution] = existing;
    });
  });

  // Create an array of rows, where each row contains an array of values sorted by the column order
  let sheets = sortedColumns.map((columns) => {
    let values = data[columns[1].resolution];
    let readings = Object.keys(values).map((key) => {
      return {[mergeKey]: key, ...values[key]};
    });

    return [columns.map((h) => h.label), ...readings.map((value) => {
      return columns.map((column, columnIndex) => {
        if (columnIndex === 0) {
          return `${moment(value[column.key]).utc(true).format('YYYY-MM-DD HH:mm:ss.000')}`;
        }
        else {
          return Number(value[column.key]).toFixed(2);
        }
      });
    })];
  });

  const wb = new ExcelJS.Workbook();
  sheets.forEach((sheet, sheetIndex) => {
    const ws = wb.addWorksheet();
    sheet.forEach((rowData, rowIndex) => {
      if (rowIndex === 0) {
        ws.getRow(rowIndex+1).values = rowData;
      }
      else {
        rowData.forEach((entry, entryIndex) => {
          let row = ws.getRow(rowIndex+1).getCell(entryIndex+1);
          if (isNaN(entry)) {
            row.value = entry;
          }
          else {
            row.value = Number(entry);
            row.numFmt = '0.00';
          }
        });
      }
    });
  });

  return wb.xlsx
    .writeBuffer()
    .catch((error) => {
      throw error;
    });
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    }
    else {
      collection.push(item);
    }
  });
  return map;
}
