import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useLangFile} from "js/context/LanguageContext";
import type {LAYER_CONFIG} from "js/model/surveys/SurveyLayerConfig";
import ListItem from "@material-ui/core/ListItem";
import {ListItemIcon, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import {getLayerConfig} from "js/components/Prescription/PrescriptionUtils";
import {CheckBoxOutlineBlankOutlined, CheckBoxOutlined} from "@material-ui/icons";
import {voidFunc} from "js/constants/PropTypeUtils";
import {blue} from '@material-ui/core/colors';

const OtherLayerListItem = (props: OtherLayerListItem.propTypes) => {

  const LangFile = useLangFile();
  const config: LAYER_CONFIG = getLayerConfig(props.layer);

  let handleOnClick = useCallback(() => {
    props.onClick(props.layer, !props.isSelected);
  }, [props.layer, props.isSelected, props.onClick]);

  let handleOnCheckboxClicked = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onClick(props.layer, !props.isSelected);
  }, [props.layer, props.isSelected, props.onClick]);

  if (!config) {
    return null;
  }

  return (
    <ListItem
      dense={true}
      button={true}
      disabled={props.disabled}
      onClick={handleOnClick}
      key={props.layer}>
      <ListItemIcon style={{minWidth: 36}}>
        <Checkbox
          style={{padding: 0}}
          checked={props.isSelected}
          disabled={props.disabled}
          onClick={handleOnCheckboxClicked}
          icon={<CheckBoxOutlineBlankOutlined fontSize="small"/>}
          checkedIcon={<CheckBoxOutlined fontSize="small"/>}
          color="primary"/>
      </ListItemIcon>
      <ListItemText primary={config.getName(LangFile)}/>
      <ListItemSecondaryAction style={{pointerEvents: "none"}}>
        {React.cloneElement(config.icon, {
          htmlColor: props.disabled ? "white" : blue["A700"]
        })}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

OtherLayerListItem.propTypes = {
  layer: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

OtherLayerListItem.defaultProps = {
  onClick: voidFunc,
  onCheckboxClick: voidFunc,
};

export default memo(
  OtherLayerListItem
);
