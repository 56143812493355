// @flow

import AppBar from "@material-ui/core/AppBar";
import style from "js/components/Prescription/PrescriptionEditor.module.less";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {
  ArrowBack,
  Block,
  Brush,
  CallSplit,
  Clear,
  CloudDownload,
  Delete,
  Edit,
  MergeType,
  Settings
} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import {
  PRESCRIPTION_OVERRIDES_TOOL,
  PRESCRIPTION_UNIT
} from "js/constants/PrescriptionConstants";
import TextField from "@material-ui/core/TextField";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import React, {
  Fragment,
  memo,
  useCallback
} from "react";
import {useLangFile} from "js/context/LanguageContext";
import {usePrescriptionJob} from "js/components/Prescription/PrescriptionJobContext";
import PropTypes from "prop-types";
import PrescriptionAdjustButton
  from "js/components/Prescription/Dialogs/PrescriptionAutoAdjustDialog/PrescriptionAdjustButton";
import ManualFeatureConstants from "../../../constants/ManualFeatureConstants";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../../hooks/useFirebaseAnalytics";
import {useHasFeatures} from "../../../context/PermissionContext";
import FeatureConstants from "../../../constants/FeatureConstants";
import PolygonBufferIcon from "../../Icons/PolygonBufferIcon";
import {Divider} from "@material-ui/core";

const PrescriptionToolbar = (props: PrescriptionToolbar.propTypes) => {

  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();
  const allowBufferZones = useHasFeatures([FeatureConstants.PRESCRIPTION_BUFFER_ZONE]);

  const {prescriptionJob} = usePrescriptionJob();

  let numberOfOverrides = 0;
  if (prescriptionJob.overrideAreas) {
    numberOfOverrides = Object.keys(prescriptionJob.overrideAreas).length;
  }

  const onKeyUp = useCallback((event) => {
    if (typeof event.persist === "function") {
      event.persist();
    }

    if (event.shiftKey) {
      return;
    }

    if (event.key === "Enter") {
      event.target.blur();
    }

  }, []);

  const selectOnFocus = useCallback((event) => {
    let input = event.target;
    input.setSelectionRange(0, input.value.length);
  }, []);

  const onDownload = useCallback(() => {
    analytics.logEvent(FIREBASE_EVENTS.VRM_EDITOR_DOWNLOAD);
    props.onDownloadJob(prescriptionJob, !prescriptionJob.saved);
  }, [prescriptionJob]);

  const onAutoAdjustClicked = useCallback(() => {
    props.onSetShowAutoAdjustDialog(true);
  }, []);

  let unitString;
  switch (prescriptionJob.unit) {
    case PRESCRIPTION_UNIT.LITER:
      unitString = `${LangFile.PrescriptionSettings.unit.liters.short} / ha`;
      break;
    case PRESCRIPTION_UNIT.PIECES_M2:
      unitString = `${LangFile.PrescriptionSettings.unit.pieces.short} / m^2`;
      break;
    default:
      unitString = `${LangFile.PrescriptionSettings.unit.kilogram.short} / ha`;
      break;
  }

  return (
    <AppBar position="static" color="inherit" className={style.AppBar} elevation={0}>
      <Toolbar className={style.Toolbar}>
        {(props.splitting || props.merging || props.overriding) && (
          <Box className={style.ButtonWrapper}>
            <Tooltip title={LangFile.PrescriptionToolbar.backToZones}>
              <IconButton
                color="inherit"
                onClick={props.onReturnToEditor}>
                <ArrowBack/>
              </IconButton>
            </Tooltip>
          </Box>
        )}

        {!(props.splitting || props.merging || props.overriding) && (
          <Fragment>
            <Box className={style.ButtonWrapper}>
              <Tooltip title={LangFile.PrescriptionToolbar.exit}>
                <IconButton
                  color="inherit"
                  aria-label={LangFile.PrescriptionToolbar.exit}
                  onClick={props.onExitSafe}>
                  <Clear/>
                </IconButton>
              </Tooltip>
              <Tooltip title={LangFile.PrescriptionToolbar.settingsTooltip}>
                <Box mr={1} ml={1}>
                  <Button
                    variant="outlined"
                    aria-label={LangFile.PrescriptionToolbar.settingsTooltip}
                    onClick={props.onShowSettings}>
                    <Settings/>
                    <Box pl={1}>
                      {LangFile.PrescriptionToolbar.settings}
                    </Box>
                  </Button>
                </Box>
              </Tooltip>
              <Tooltip title={LangFile.PrescriptionToolbar.downloadTooltip}>
                <Box mr={1} ml={1}>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    aria-label={LangFile.PrescriptionToolbar.downloadTooltip}
                    onClick={onDownload}>
                    <CloudDownload/>
                    <Box pl={1}>
                      {LangFile.PrescriptionToolbar.download}
                    </Box>
                  </Button>
                </Box>
              </Tooltip>

              {!props.overriding && (
                <PrescriptionAdjustButton onClick={onAutoAdjustClicked}/>
              )}
            </Box>

            <Box>
              <Tooltip title={LangFile.PrescriptionToolbar.splitIntervals}>
                <IconButton
                  color="inherit"
                  aria-label={LangFile.PrescriptionToolbar.splitIntervals}
                  disabled={!props.splittingEnable}
                  onClick={props.onInitiateSplit}>
                  <CallSplit/>
                </IconButton>
              </Tooltip>

              <Tooltip title={LangFile.PrescriptionToolbar.mergeIntervals}>
                <IconButton
                  color="inherit"
                  aria-label={LangFile.PrescriptionToolbar.mergeIntervals}
                  disabled={!props.splittingEnable || prescriptionJob.intervals.length < 2}
                  onClick={props.onInitiateMerge}>
                  <MergeType/>
                </IconButton>
              </Tooltip>

              <Tooltip title={LangFile.PrescriptionToolbar.overrideModeTooltip}>
                <IconButton
                  color="inherit"
                  aria-label={LangFile.PrescriptionToolbar.overrideModeTooltip}
                  onClick={props.onToggleIsOverriding}>
                  <Badge badgeContent={numberOfOverrides} color={"secondary"} overlap={"circle"}>
                    <Edit/>
                  </Badge>
                </IconButton>
              </Tooltip>
            </Box>
          </Fragment>
        )}

        {props.overriding && (
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>

            {(allowBufferZones || ManualFeatureConstants.PRESCRIPTION_BUFFER_ZONE) && (
              <Box minWidth={100} mt={"-4px"} className={style.TextFieldBox}>

                <Box px={3} display={"flex"}>
                  <Divider orientation={"vertical"} flexItem/>
                </Box>

                <Button variant={"outlined"} onClick={props.onEditBuffer} startIcon={<PolygonBufferIcon />} className={style.Button}>
                  {LangFile.PrescriptionToolbar.buffer}
                </Button>

                <Box px={3} display={"flex"}>
                  <Divider orientation={"vertical"} flexItem/>
                </Box>

              </Box>
            )}

            <ToggleButtonGroup size="small" value={props.tool} exclusive onChange={props.onToolChanged}>
              <ToggleButton value={PRESCRIPTION_OVERRIDES_TOOL.OVERRIDE}>
                <Brush/>
              </ToggleButton>
              <ToggleButton value={PRESCRIPTION_OVERRIDES_TOOL.EXCEPTION}>
                <Block/>
              </ToggleButton>
              <ToggleButton value={PRESCRIPTION_OVERRIDES_TOOL.ERASER}>
                <Delete/>
              </ToggleButton>
            </ToggleButtonGroup>

            {props.tool === PRESCRIPTION_OVERRIDES_TOOL.OVERRIDE && (
              <Box width={100} mt={"-4px"} ml={1} className={style.TextFieldBox}>
                <TextField
                  value={props.overrideBrushValue}
                  InputProps={{
                    endAdornment: unitString,
                  }}
                  onChange={props.onOverrideBrushValueChanged}
                  inputProps={{
                    onFocus: selectOnFocus,
                    onKeyUp: onKeyUp
                  }}
                  type={"tel"}
                  margin="dense"
                  variant={"outlined"}
                  label={LangFile.PrescriptionToolbar.value}
                  InputLabelProps={{
                    shrink: true,
                  }}/>
              </Box>
            )}

            <Box width={100} mt={"-4px"} ml={1} className={style.TextFieldBox}>
              <TextField
                value={props.overrideBrushSize}
                InputProps={{
                  endAdornment: "m",
                }}
                inputProps={{
                  onFocus: selectOnFocus,
                  onKeyUp: onKeyUp,
                  onBlur: props.onOverrideBrushSizeBlur
                }}
                onChange={props.onOverrideBrushSizeChanged}
                type={"tel"}
                margin="dense"
                variant={"outlined"}
                label={LangFile.PrescriptionToolbar.size}
                InputLabelProps={{
                  shrink: true,
                }}/>
            </Box>
          </Box>
        )}

      </Toolbar>
    </AppBar>
  );
};

PrescriptionToolbar.propTypes = {
  tool: PropTypes.string,
  merging: PropTypes.bool,
  splitting: PropTypes.bool,
  mergingEnable: PropTypes.bool,
  splittingEnable: PropTypes.bool,
  overriding: PropTypes.bool,
  overrideBrushSize: PropTypes.number,
  overrideBrushValue: PropTypes.number,

  onEditBuffer: PropTypes.any,
  onExitSafe: PropTypes.any,
  onDownloadJob: PropTypes.func,
  onToolChanged: PropTypes.func,
  onShowSettings: PropTypes.func,
  onInitiateSplit: PropTypes.func,
  onInitiateMerge: PropTypes.func,
  onReturnToEditor: PropTypes.func,
  onToggleIsOverriding: PropTypes.func,
  onOverrideBrushSizeBlur: PropTypes.func,
  onOverrideBrushSizeChanged: PropTypes.func,
  onOverrideBrushValueChanged: PropTypes.func,
  onSetShowAutoAdjustDialog: PropTypes.func,
};

export default memo(PrescriptionToolbar);
