import React, {memo} from 'react';
import StyleConstants from 'js/StyleConstants';

import {
  createMuiTheme,
  MuiThemeProvider
} from '@material-ui/core/styles';

import {darken} from "@material-ui/core/styles/colorManipulator";
import {
  grey,
} from "@material-ui/core/colors";

const muiTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      '"Helvetica Neue"',
      // 'Nunito',
      // 'Roboto',
      'Arial',
      'sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  spacing: 8,
  palette: StyleConstants.palette,
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "#ffffff"
      },
      colorPrimary: {
        backgroundColor: StyleConstants.palette.primary.main,
      }
    },
    MuiPickersDay: {
      day: {
        color: "#78a22f",
        '& span p': {
          fontWeight: "800 !important",
        }
      },
      dayDisabled: {
        '& span p': {
          fontWeight: "400 !important",
        }
      },
      current: {
        color: "#78a22f",
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
      },
      colorInherit: {
        backgroundColor: StyleConstants.palette.primary.main,
        color: StyleConstants.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: darken(StyleConstants.palette.primary.main, 0.15),
        }
      },
      contained: {
        backgroundColor: "#fff",
        color: "#000",
        '&:hover': {
          backgroundColor: grey["200"],
        }
      }
    },
    MuiFab: {
      root: {
        '&:hover': {
          backgroundColor: grey["200"],
        }
      }
    },
    MuiIconButton: {
      colorPrimary: {
        '&:hover': {
          backgroundColor: darken(StyleConstants.palette.primary.main, 0.15),
        }
      }
    },
  },
});

const MUITheme = ({children}) => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      {children}
    </MuiThemeProvider>
  );
};

export default memo(MUITheme);
