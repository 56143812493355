"use strict";
import PropTypes from "prop-types";

import React, {memo} from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const DemandHigh = (props: DemandHigh.propTypes) => {
  return (
    <SvgIcon
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12">

      <g transform="translate(0.471 -996)">
        <g transform="translate(-0.471 996)">
          <g fill={props.htmlColor} stroke={props.htmlColor} strokeWidth="1">
            <circle cx="6" cy="6" r="6" stroke="none"/>
            <circle cx="6" cy="6" r="5.5" fill="none"/>
          </g>
        </g>
      </g>

    </SvgIcon>
  );
};

DemandHigh.propTypes = {
  htmlColor: PropTypes.string,
};

DemandHigh.defaultProps = {
  htmlColor: "#FF0000",
};

export default memo(
  DemandHigh
);