import {useLangFile} from '../../context/LanguageContext';
import React, {memo} from 'react';
import {ArrowBack, DeviceHub, FilterList, ImportExport, Launch} from '@material-ui/icons';
import PropTypes from 'prop-types';
import {voidFunc} from '../../constants/PropTypeUtils';
import {AppBar, Button, Box, Typography, Toolbar, ButtonBase, Tooltip} from '@material-ui/core';
import Logo from '../UI-Elements/Logo';
import useBreakpoint from '../../hooks/useBreakpoint';
import Styles from './FieldStatisticsToolbar.module.less';

const FieldStatisticsToolbar = (props: FieldStatisticsToolbar.propTypes) => {
  const {onBackButtonClicked, onEditButtonClicked, onGoToClaasButtonClicked, onGoToIntegrationButtonClicked, onLinkButtonClicked, enableImportYield} = props;

  const LangFile = useLangFile();

  const breakpoint = useBreakpoint();
  const narrow = breakpoint === 'sm' || breakpoint === 'xs';

  return (
    <AppBar position="static" color="inherit" className={Styles.AppBar} elevation={0}>
      <Toolbar className={Styles.Toolbar} disableGutters>
        <Box display={"flex"} flexDirection={'row'} justifyContent={'space-between'} width={"100%"}>
          <Box width={1/3} display={"flex"} alignItems={"center"} pl={2}>
            <Box className={Styles.ButtonWrapper}>
              <ButtonBase
                onClick={onBackButtonClicked}>
                <Box display={'flex'} flexDirection={'column'} mr={1.5} justifyContent={'center'}>
                  <ArrowBack/>
                </Box>
                <Typography>
                  {LangFile.PrescriptionLayerDialog.back}
                </Typography>
              </ButtonBase>
            </Box>
          </Box>
          <Box width={1/3} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Logo variant={narrow ? "short" : "full"} size={24}/>
          </Box>
          <Box width={1/3} display={"flex"} justifyContent={"flex-end"} alignItems={'center'} pr={2}>
            <Box display={"flex"} justifyContent={"flex-end"} alignItems={'center'}>
              {enableImportYield && (
                <div>
                  <Button className={Styles.button} onClick={onGoToClaasButtonClicked} color={"primary"} variant={"outlined"}>
                    <Typography className={Styles.buttonText}>
                      {LangFile.FieldStatistics.claasTelematics}
                    </Typography>
                    <Box display={'flex'} flexDirection={'column'} ml={1} justifyContent={'center'}>
                      <Launch style={{height: 20}}/>
                    </Box>
                  </Button>
                </div>
              )}
              {!enableImportYield && (
                <Button className={Styles.button} onClick={onGoToIntegrationButtonClicked} color={"primary"} variant={"outlined"}>
                  <Typography className={Styles.buttonText}>
                    {LangFile.FieldStatistics.connectToClaas}
                  </Typography>
                  <Box display={'flex'} flexDirection={'column'} ml={1} justifyContent={'center'}>
                    <DeviceHub style={{height: 20}}/>
                  </Box>
                </Button>
              )}
              <Box mr={1}/>
              <Tooltip title={LangFile.FieldStatistics.connectToClaasToImport} disableHoverListener={enableImportYield}>
                <div>
                  <Button className={Styles.button} onClick={onLinkButtonClicked} disabled={!enableImportYield} color={"primary"} variant={"outlined"}>
                    <Typography className={Styles.buttonText}>
                      {LangFile.FieldStatistics.importYieldData}
                    </Typography>
                    <Box display={'flex'} flexDirection={'column'} ml={1} justifyContent={'center'}>
                      <ImportExport style={{height: 20}}/>
                    </Box>
                  </Button>
                </div>
              </Tooltip>
            </Box>
            <Box mr={1}/>
            <Button className={Styles.button} onClick={onEditButtonClicked} variant={"outlined"}>
              <Typography className={Styles.buttonText}>
                {LangFile.FieldStatistics.filterColumns}
              </Typography>
              <Box display={'flex'} flexDirection={'column'} ml={1} justifyContent={'center'}>
                <FilterList style={{height: 20}}/>
              </Box>
            </Button>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

FieldStatisticsToolbar.propTypes = {
  onBackButtonClicked: PropTypes.func,
  onEditButtonClicked: PropTypes.func,
  onGoToClaasButtonClicked: PropTypes.func,
  onGoToIntegrationButtonClicked: PropTypes.func,
  onLinkButtonClicked: PropTypes.func,
  enableImportYield: PropTypes.bool,
};

FieldStatisticsToolbar.defaultProps = {
  onBackButtonClicked: voidFunc,
  onEditButtonClicked: voidFunc,
  onGoToClaasButtonClicked: voidFunc,
  onGoToIntegrationButtonClicked: voidFunc,
  onLinkButtonClicked: voidFunc,
  enableImportYield: false,
};

export default memo(FieldStatisticsToolbar);
