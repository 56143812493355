// @flow

import React, {
  memo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import {
  SURVEY_NUTRIENT_LAYERS, SURVEY_TEXTURE_LAYERS, SURVEY_TOPOGRAPHY_LAYERS
} from "js/constants/SurveyLayers";
import {useLangFile, useLangFileRef} from "js/context/LanguageContext";
import List from "@material-ui/core/List";
import {voidFunc} from "../../constants/PropTypeUtils";
import SurveyLayerListItem from "./SurveyLayerListItem";
import SubList from "../SatelliteLayerList/SubList";
import {useCanLayerBeEnabled} from "../../context/SurveyContext";
import ViewModeConstants from "../../constants/ViewModeConstants";

const SurveyLayerList = ({
                           selectedLayer,
                           onLayerChanged,
                           onReferencesChanged,
                           isSingleField,
                           enableMultipleSelect,
                           multipleSelections,
                           disableAll,
                           viewMode
                         }) => {

  const LangFile = useLangFile();
  const LangFileRef = useLangFileRef();

  const isLayerSelected = useCallback((layerType) => {
    if (layerType === selectedLayer) {
      return true;
    }

    if (enableMultipleSelect) {
      return multipleSelections.includes(layerType);
    }
    else {
      return layerType === selectedLayer;
    }
  }, [selectedLayer, enableMultipleSelect, multipleSelections]);

  const handleOnClick = useCallback((layer) => {
    if (layer !== selectedLayer) {
      onLayerChanged(layer);
    }
  }, [selectedLayer, onLayerChanged]);

  const handleOnCheckboxClick = useCallback((layer) => {
    if (enableMultipleSelect) {
      let layers = [...multipleSelections];

      if (layers.includes(layer)) {
        layers = layers.filter((item) => item !== layer);
      }
      else {
        layers.push(layer);
      }

      onReferencesChanged(layers);
    }
  }, [selectedLayer, onLayerChanged]);

  const renderListItem = (enabled = false) => (layer) => {
    if (useCanLayerBeEnabled(layer) === true) {
      return (
        <SurveyLayerListItem
          layer={layer}
          disabled={!enabled || disableAll}
          enableMultipleSelect={enableMultipleSelect && layer !== selectedLayer}
          isSelected={isLayerSelected(layer)}
          onCheckboxClick={handleOnCheckboxClick}
          onClick={handleOnClick}/>
      );
    }
    return null;
  };

  const renderEmptyState = useCallback(() => {
    if (isSingleField) {
      return LangFileRef.current.SurveyLayerList.noLayersFound;
    }
    else {
      return (
        <>
          <span>{LangFileRef.current.SurveyLayerList.noneApplicable}</span>
          <span>{LangFileRef.current.SurveyLayerList.tryAnalysing}</span>
        </>
      );
    }
  }, [isSingleField]);

  return (
    <List
      dense
      disablePadding>

      <SubList
        title={LangFile.SurveyLayerList.nutrients}
        items={SURVEY_NUTRIENT_LAYERS}
        renderEmptyState={renderEmptyState}
        renderItem={renderListItem(true)}/>

      <SubList
        title={LangFile.SurveyLayerList.texture}
        items={SURVEY_TEXTURE_LAYERS}
        renderEmptyState={renderEmptyState}
        renderItem={renderListItem(true)}/>

      <SubList
        title={LangFile.SurveyLayerList.topography}
        items={SURVEY_TOPOGRAPHY_LAYERS}
        renderEmptyState={renderEmptyState}
        renderItem={renderListItem(viewMode !== ViewModeConstants.OVERVIEW)}/>

    </List>
  );
};

SurveyLayerList.propTypes = {
  selectedLayer: PropTypes.string,
  onLayerChanged: PropTypes.func,
  onReferencesChanged: PropTypes.func,
  isSingleField: PropTypes.bool,
  disableAll: PropTypes.bool,
  enableMultipleSelect: PropTypes.bool,
  multipleSelections: PropTypes.array,
};

SurveyLayerList.defaultProps = {
  onLayerChanged: voidFunc,
  onReferencesChanged: voidFunc,
};

export default memo(SurveyLayerList);