
import React, {
  memo,
  useCallback, useEffect,
  useRef
} from 'react';

import PropTypes from 'prop-types';
import Styles from "js/components/ManageCrops/FieldTextCanvas.module.less";
import {AutoSizer} from "react-virtualized";
import useMapEvent from "js/components/DataLayer/hooks/useMapEvent";
import throttle from "lodash.throttle";
import {getFieldCenter} from "js/helpers/MapsUtils";
import Box from "@material-ui/core/Box";
import {useGoogleMap} from "js/context/GoogleMapContext";

const getTopLeftPixelCoords = (bounds, projection, zoom) => {
  let numTiles = 1 << zoom;

  let topLeft = new google.maps.LatLng(
    bounds.getNorthEast().lat(),
    bounds.getSouthWest().lng()
  );

  let topLeftWorldCoordinate = projection.fromLatLngToPoint(topLeft);

  return new google.maps.Point(
    topLeftWorldCoordinate.x * numTiles,
    topLeftWorldCoordinate.y * numTiles);
};

const mapToScreen = (projection, bounds, zoom, latLng) => {
  let numTiles = 1 << zoom;
  let worldCoordinate = projection.fromLatLngToPoint(latLng);
  let pixelCoordinate = new google.maps.Point(
    worldCoordinate.x * numTiles,
    worldCoordinate.y * numTiles);

  let topLeftPixelCoordinate = getTopLeftPixelCoords(bounds, projection, zoom);

  return new google.maps.Point(
    pixelCoordinate.x - topLeftPixelCoordinate.x,
    pixelCoordinate.y - topLeftPixelCoordinate.y
  );
};

const FieldTextCanvas = (props: FieldTextCanvas.propTypes) => {

  const googleMap = useGoogleMap();
  const canvasRef = useRef(null);

  const handler = useCallback(throttle(() => {
    if (!canvasRef.current) {
      return;
    }

    let ctx = canvasRef.current.getContext("2d");
    let width = canvasRef.current.width;
    let height = canvasRef.current.height;

    if (googleMap) {
      let proj = googleMap.getProjection();
      let bounds = googleMap.getBounds();
      let zoom = googleMap.getZoom();

      if (proj && bounds && zoom) {
        ctx.clearRect(0, 0, width, height);

        props.fields.forEach((field) => {
          let center = getFieldCenter(field);
          let latLng = new google.maps.LatLng(center.lat, center.lng);
          let point = mapToScreen(proj, bounds, zoom, latLng);

          if (point.x >= 0 && point.x <= width && point.y >= 0 && point.y <= height) {

            let lines = props.renderField(field, zoom);

            ctx.beginPath();

            lines.forEach(({text, textAlign, color, fontSize, fontWeight}, idx) => {
              ctx.save();
              ctx.fillStyle = color;
              ctx.textAlign = textAlign;
              ctx.font = `${fontWeight} ${fontSize}px Roboto`;
              ctx.shadowColor = "rgba(0,0,0,1)";
              ctx.shadowBlur = 2;
              ctx.strokeText(text, point.x, point.y + idx * fontSize);
              ctx.shadowColor = "rgba(0,0,0,0)";
              ctx.shadowBlur = 0;
              ctx.fillText(text, point.x, point.y + idx * fontSize);

              ctx.restore();
            });
          }
        });
      }
    }
    else {
      let ctx = canvasRef.current.getContext("2d");
      ctx.clearRect(0, 0, width, height);
    }
  }, 10), [googleMap, props.fields, props.renderField]);

  useMapEvent(googleMap, "zoom_changed", handler);
  useMapEvent(googleMap, "center_changed", handler);
  useMapEvent(googleMap, "idle", handler);

  useEffect(handler, [props.fields]);

  return (
    <AutoSizer>
      {({height, width}) => (
        <Box width={width} height={height} display={"flex"}>
          <canvas
            ref={canvasRef}
            className={Styles.Canvas}
            width={width}
            height={height}/>
        </Box>
      )}
    </AutoSizer>
  );
};

FieldTextCanvas.propTypes = {
  fields: PropTypes.array,
  renderField: PropTypes.func,
};

export default memo(FieldTextCanvas);
