import React, {
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import {
  Dialog,
} from "@material-ui/core";
import MapFloatPanel from "js/components/MapView/MapFloatPanel";
import ToolbarContainer from "js/components/Toolbar/ToolbarContainer";
import {setKeyboardShortcutsEnabled} from "js/reducers/ControlReducer";
import GpsFixAppBar from "js/components/WeatherDrawer/WeatherStationSelector/GpsError/GpsFixAppBar";
import MapCrosshair from "js/components/MapObjects/MapCrosshair";
import {updateGlobalFarmSetting} from "js/reducers/SettingsReducer";
import SettingsConstants from "js/constants/SettingsConstants";
import {useGpsFixLocation} from "js/context/SettingsContext";
import DataLayer from "js/components/DataLayer/DataLayer";
import FeaturePaper from "js/components/DataLayer/FeaturePaper";
import WeatherStationIcon from "js/components/Icons/Weather/WeatherStationIcon";
import {useGoogleMap} from "../../../../context/GoogleMapContext";
import GoogleMap from "js/components/MapObjects/GoogleMap/GoogleMap";

const mapStateToProps = (store) => {
  return {
    farm: store.farm.farm,
  };
};

const GpsFixContainer = ({dispatch, station, onClose, farm}) => {

  const mainGoogleMap = useGoogleMap();
  const existingFixLocation = useGpsFixLocation(station.label);

  const [googleMap, setGoogleMap] = useState(null);
  const [target, setTarget] = useState(existingFixLocation);
  const [oldLocation, setOldLocation] = useState(null);

  useEffect(() => {
    if (target && googleMap) {
      setOldLocation(target);
      googleMap.setZoom(19);
      googleMap.setCenter(target);
    }
  }, [googleMap]);

  useEffect(() => {
    dispatch(setKeyboardShortcutsEnabled(false));

    return () => {
      dispatch(setKeyboardShortcutsEnabled(true));
    };
  }, []);

  const handleOnSave = useCallback(() => {
    if (typeof target.lat === "function") {
      let value = `${target.lat()},${target.lng()}`;

      dispatch(updateGlobalFarmSetting(farm.farmId, SettingsConstants.FARM_WEATHER_STATION_LOCATION(station.label), value));

      onClose();
    }
  }, [target, station, onClose]);

  const handleOnOldLocationClick = useCallback(() => {
    setOldLocation({...oldLocation}); // force a re-render by cloning the object.
  }, [oldLocation]);

  return (
    <Dialog fullScreen open={true}>
      <MapFloatPanel zIndex={200} top={0} left={0} right={0}>

        <GpsFixAppBar onClose={onClose} onSave={handleOnSave}/>

        <ToolbarContainer disableLayers={true}/>
      </MapFloatPanel>

      <GoogleMap
        center={mainGoogleMap.getCenter()}
        zoom={mainGoogleMap.getZoom()}
        onMapLoaded={setGoogleMap}>

        <MapCrosshair onTargetChanged={setTarget} onSave={handleOnSave} initialTarget={oldLocation}/>

        {oldLocation && (
          <DataLayer shown>
            <FeaturePaper round elevation={6} lat={oldLocation.lat()} lng={oldLocation.lng()} onClick={handleOnOldLocationClick} clickable disablePadding>
              <WeatherStationIcon style={{fontSize: '4em'}}/>
            </FeaturePaper>
          </DataLayer>
        )}

      </GoogleMap>
    </Dialog>
  );
};

GpsFixContainer.propTypes = {
  station: PropTypes.object,
};

export default memo(
  connect(mapStateToProps)(
    GpsFixContainer
  )
);