import {useLangFile} from '../../context/LanguageContext';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  List,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {voidFunc} from '../../constants/PropTypeUtils';
import React, {memo, useEffect, useState} from 'react';
import Styles from './FieldIntegrationMappingDialog.module.less';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FieldIntegrationMappingHeader from './FieldIntegrationMappingHeader';
import FieldIntegrationBrokenLinksCell from "./FieldIntegrationBrokenLinksCell";

const FieldIntegrationBrokenLinksDialog = (props: FieldIntegrationBrokenLinksDialog.propTypes) => {
  const {open, onBack, onApply, onSelectLink, onRemoveAllLink, fields, links, workbooks} = props;
  const LangFile = useLangFile();

  const [applyAllowed, setApplyAllowed] = useState(false);
  useEffect(() => {
    const BreakException = {};
    let allow = true;
    try {
      links.forEach((link) => {
        if (!link.type) {
          allow = false;
          throw BreakException;
        }
      });
    }
    catch (e) {
      if (e!==BreakException) throw e;
    }
    setApplyAllowed(allow);
  }, [links, workbooks]);

  return (
    <Dialog
      PaperProps={{ classes: {root: Styles.paper }}}
      open={open}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      maxWidth={"sm"}
      onClose={onBack}>
      <DialogContent className={Styles.content}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Box>
            <Typography variant={'h5'}>{LangFile.FieldIntegrationBrokenMapping.title}</Typography>
            <Divider className={Styles.divider}/>
          </Box>
          <Typography>{LangFile.FieldIntegrationBrokenMapping.content}</Typography>
          <Box style={{width: '100%', height: '40vh'}} display={'flex'} flexDirection={'column'} paddingBottom={2}>
            <Box pb={2} pt={2} display={"flex"} justifyContent={"center"}>
              <Button onClick={onRemoveAllLink} variant={"contained"} color={"primary"}>
                {LangFile.FieldIntegrationBrokenMapping.removeAll}
              </Button>
            </Box>
            <List
              className={Styles.list}
              subheader={
                <ListSubheader classes={{sticky: Styles.sticky}}>
                  <FieldIntegrationMappingHeader
                    lhsTitle={LangFile.FieldStatistics.cropline}
                    rhsTitle={LangFile.FieldStatistics.claas} />
                </ListSubheader>
              }>
              {links.map((link) => {
                let field = fields.find((field) => field.fieldId === link.fieldId);
                return <ListItem className={Styles.listItem} key={field.fieldId} dense={true} divider={false}>
                  <FieldIntegrationBrokenLinksCell
                    onSelectLink={onSelectLink}
                    field={field}
                    workbooks={workbooks}
                    link={link} />
                </ListItem>;
              })}
            </List>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box style={{width: '100%'}} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Button onClick={onBack}>{LangFile.FieldIntegrationMapping.back}</Button>
          <Button
            disabled={!applyAllowed}
            variant={"contained"}
            color={"primary"}
            onClick={onApply}>{LangFile.FieldIntegrationBrokenMapping.apply}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

FieldIntegrationBrokenLinksDialog.propTypes = {
  open: PropTypes.bool,
  onBack: PropTypes.func,
  onApply: PropTypes.func,
  onRemoveAllLink: PropTypes.func,
  onSelectLink: PropTypes.func,
  links: PropTypes.array,
  fields: PropTypes.array,
  workbooks: PropTypes.array
};

FieldIntegrationBrokenLinksDialog.defaultProps = {
  onBack: voidFunc,
  onApply: voidFunc,
  onRemoveAllLink: voidFunc,
  onSelectLink: voidFunc,
  links: [],
  fields: [],
  workbooks: []
};

export default memo(FieldIntegrationBrokenLinksDialog);
