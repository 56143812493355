import React, {
  memo,
} from "react";
import {connect} from "react-redux";
import {FieldProvider} from "../context/FieldContext";

const mapStoreToProps = (store) => ({
  fields: store.field.fields,
  farmFields: store.field.farmFields,
  selectedField: store.field.selectedField,
  filteredFields: store.field.filteredFields
});

const FieldContainer = ({dispatch, ...props}) => {
  return (
    <FieldProvider value={props}>
      {props.children}
    </FieldProvider>
  );
};

export default memo(connect(mapStoreToProps)(FieldContainer));
