import PropTypes from 'prop-types';
import React, {forwardRef, Fragment, memo, useImperativeHandle} from 'react';
import { useTheme } from '@material-ui/core/styles';
import {Box, Button, Paper, Popover, Tooltip, Typography} from '@material-ui/core';
import {ChevronRight} from '@material-ui/icons';

import Styles from './MenuPopoverButton.module.less';
import {voidFunc} from '../../constants/PropTypeUtils';

const MenuPopoverButton = (props: MenuPopoverButton.propTypes, ref) => {
  const {tooltip, icon, buttonText, disabled, children, disableClickAway, onClick, renderChildren} = props;

  const theme = useTheme();
  const divRef = React.useRef();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = () => {
    onClick();
    setAnchorEl(divRef.current);
  };

  const onClose = () => {
    if (!disableClickAway) {
      close();
    }
  };

  const close = () => {
    setAnchorEl(null);
  };

  useImperativeHandle(ref, () => ({
    closePopover: () => close()
  }));

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Fragment>
      <Tooltip title={tooltip || ""}>
        <Box ref={divRef} className={Styles.box}>
          <Paper elevation={2} className={Styles.paper}>
            <Button
              disabled={disabled}
              aria-describedby={id}
              className={Styles.button}
              onClick={handleClick}>
              {
                React.cloneElement(icon, {
                  style: { fill: theme.palette.primary.main }
                })
              }
              <Typography className={Styles.text}>{buttonText}</Typography>
              <Box my={0.5} flex={1} display={"flex"} justifyContent={"flex-end"}>
                <ChevronRight/>
              </Box>
            </Button>
          </Paper>
        </Box>
      </Tooltip>
      <Popover
        id={id}
        classes={{ paper: Styles.paper, root: Styles.popover }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        marginThreshold={0}
        disableEnforceFocus={true}
        hideBackdrop={true}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        {renderChildren(close)}
        {children}
      </Popover>
    </Fragment>
  );
};

MenuPopoverButton.propTypes = {
  tooltip: PropTypes.string,
  buttonText: PropTypes.string,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  disableClickAway: PropTypes.bool,
  onClick: PropTypes.func,
  /**
   *
   */
  renderChildren: PropTypes.func
};

MenuPopoverButton.defaultProps = {
  onClick: voidFunc,
  renderChildren: voidFunc
};

export default memo(forwardRef(MenuPopoverButton));