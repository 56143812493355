import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Typography from "@material-ui/core/Typography/Typography";
import {useLangFile} from "js/context/LanguageContext";
import Box from "@material-ui/core/Box";
import Styles from './PrescriptionTableToolbar.module.less';
import {voidFunc} from "../../constants/PropTypeUtils";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const PrescriptionTableToolbar = (props: PrescriptionTableToolbar.props) => {
  const LangFile = useLangFile();

  const disableGutters = useMediaQuery('(max-width:800px)');

  return (
    <Toolbar disableGutters={disableGutters}>
      <Box className={Styles.titleContainer}>
        <Typography variant="h6" id="tableTitle">
          {LangFile.PrescriptionTableToolbar.tableTitle}
        </Typography>
        {props.numberOfExcludedFields > 0 &&
          <Box className={Styles.filterContainer}>
            <Typography className={Styles.italicTypography} color="inherit" variant="body2">
              {props.numberOfExcludedFields} {LangFile.PrescriptionTableToolbar.fieldsNotShown}
            </Typography>
            <Typography className={Styles.clickableTypography} color="primary" variant="body2" onClick={props.onFilterReset}>
              {LangFile.PrescriptionTableToolbar.resetFilters}
            </Typography>
          </Box>
        }
      </Box>
    </Toolbar>
  );
};

PrescriptionTableToolbar.propTypes = {
  numberOfExcludedFields: PropTypes.number,
  onFilterReset: PropTypes.func
};

PrescriptionTableToolbar.defaultProps = {
  numberOfExcludedFields: 0,
  onFilterReset: voidFunc
};

export default memo(PrescriptionTableToolbar);