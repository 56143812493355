"use strict";

import React, {
  memo
} from 'react';

import List from '@material-ui/core/List';
import ListItem from "@material-ui/core/ListItem";
import SettingsConstants from "js/constants/SettingsConstants";
import moment from "moment-timezone";
import Paper from "@material-ui/core/Paper";
import {ListItemIcon, ListItemText, withStyles} from '@material-ui/core';
import {useLangFile} from "js/context/LanguageContext";
import {useUserSettings} from "js/context/SettingsContext";
import {SecurityRounded} from "@material-ui/icons";

const styles = (theme) => ({
  root: {
    flex: '1 1 auto',
    height: "100%",
  },
  icon: {
    justifyContent: "center"
  }
});

const FarmSettingsList = ({classes, updateFarmSetting}) => {

  const LangFile = useLangFile();
  const userSettings = useUserSettings();

  const gdprString = userSettings[SettingsConstants.USER_GDPR_CONSENT];
  const gdpr = gdprString != null ? JSON.parse(gdprString) : "";

  const policyChangedDate = gdpr && moment(gdpr.lastChanged).format('DD/MM/YYYY');
  const policySecondaryText = gdpr && LangFile.FarmSettingsList.privacyPolicySecondary.replace('%1s', policyChangedDate);

  return (
    <Paper elevation={1} className={classes.root}>
      <List>
        <ListItem disabled>
          <ListItemIcon className={classes.icon}><SecurityRounded/></ListItemIcon>
          <ListItemText primary={LangFile.FarmSettingsList.privacyPolicy} secondary={policySecondaryText}/>
        </ListItem>
      </List>
    </Paper>
  );
};

export default memo(withStyles(styles)(FarmSettingsList));
