import React, {Fragment, memo} from "react";
import * as d3 from "d3";

import Grid from "@material-ui/core/Grid";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from '@material-ui/core/styles';
import {blue, grey, orange} from "@material-ui/core/colors";

const styles = (theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: grey["900"],
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
  tooltipPopper: {
    opacity: 1,
    padding: theme.spacing(1),
  },
  linearBackground: {
    backgroundColor: grey["200"],
  },
  linearBarColorPrimaryNdvi: {
    backgroundColor: blue["A400"],
  },
  linearBarColorPrimarySpan: {
    backgroundColor: blue["200"],
  },
  linearBarColorPrimaryDev: {
    backgroundColor: orange["200"],
  },
});


class DetailsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      textWidth: 115,
      container: null
    };
  }

  componentDidMount() {
    this.svg = d3.select("#details-svg");
    this.updateDimensions();

    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    if (!this.state.container) {
      return;
    }
    let width = this.state.container.offsetWidth;
    let height = this.state.container.offsetHeight;

    this.setState({width: +width, height: +height});
  };

  prepareData = () => {
    let allStatisticsData = this.props.selectedFieldStatistics.map((element) => {
      return {...element};
    });

    let statisticsData = this.getDataFromSelectedDate(allStatisticsData);

    if (statisticsData.status) {
      return {
        error: statisticsData
      };
    }

    return {
      ndvi: Math.max(0, statisticsData.mean * 100),
      span: Math.max(0, (statisticsData.max - Math.max(0, statisticsData.min)) * 100),
      dev: Math.max(0, (statisticsData.stddev) * 100)
    };
  };

  getDataFromSelectedDate = (data) => {
    let result = data.filter((element) => element.date + "" === this.props.date)[0];
    if (!result) {
      result = {
        mean: 0,
        min: 0,
        max: 0,
        stddev: 0,
        date: this.props.date,
        status: this.props.LangFile.DetailsView.unavailable
      };
    }
    return result;
  };

  render() {

    let {classes} = this.props;

    let data = this.prepareData();
    let error = data.error;

    let rootStyle = {
      flex: "1 1 auto"
    };

    return (
      <Grid item container spacing={1} justify={"space-between"} style={rootStyle}>
        <Grid item container xs={12} alignItems={"center"}>
          <Tooltip
            title={
              <Fragment>
                <b>{this.props.LangFile.DetailsView.descriptions.title}</b>
                <Typography color="inherit">{this.props.LangFile.DetailsView.descriptions.biomass}</Typography>
              </Fragment>
            }
            classes={{tooltip: classes.tooltip, popper: classes.tooltipPopper}}>
            <Grid item xs={12}>
              <Typography>{this.props.LangFile.DetailsView.meanNdvi}</Typography>
              <LinearProgress
                variant="determinate"
                value={error ? 0 : data.ndvi}
                style={{height: 10}}
                classes={{
                  colorPrimary: classes.linearBackground,
                  barColorPrimary: error ? grey["400"] : classes.linearBarColorPrimaryNdvi,
                }}/>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item container xs={12} alignItems={"center"}>
          <Tooltip
            title={
              <Fragment>
                <b>{this.props.LangFile.DetailsView.descriptions.title}</b>
                <Typography color="inherit">{this.props.LangFile.DetailsView.descriptions.span}</Typography>
              </Fragment>
            }
            classes={{tooltip: classes.tooltip, popper: classes.tooltipPopper}}>
            <Grid item xs={12}>
              <Typography>{this.props.LangFile.DetailsView.span}</Typography>
              <LinearProgress
                variant="determinate"
                value={error ? 0 : data.span}
                style={{height: 10}}
                classes={{
                  colorPrimary: classes.linearBackground,
                  barColorPrimary: error ? grey["400"] : classes.linearBarColorPrimarySpan,
                }}/>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item container xs={12} alignItems={"center"}>
          <Tooltip
            title={
              <Fragment>
                <b>{this.props.LangFile.DetailsView.descriptions.title}</b>
                <Typography color="inherit">{this.props.LangFile.DetailsView.descriptions.deviation}</Typography>
              </Fragment>
            }
            classes={{tooltip: classes.tooltip, popper: classes.tooltipPopper}}>
            <Grid item xs={12}>
              <Typography>{this.props.LangFile.DetailsView.stddev}</Typography>
              <LinearProgress
                variant="determinate"
                value={error ? 0 : data.dev}
                style={{height: 10}}
                classes={{
                  colorPrimary: classes.linearBackground,
                  barColorPrimary: error ? grey["400"] : classes.linearBarColorPrimaryDev,
                }}/>
            </Grid>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
}

export default memo(withStyles(styles)(DetailsView));