"use strict";

import React, {memo} from 'react';

import Paper from '@material-ui/core/Paper';
import {withStyles} from "@material-ui/core/styles";
import {grey} from "@material-ui/core/colors";
import {useLangFile} from "js/context/LanguageContext";
import {usePrescriptionJob} from "js/components/Prescription/PrescriptionJobContext";
import {PRESCRIPTION_UNIT} from "js/constants/PrescriptionConstants";

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    padding: 8,
    borderBottom: `1px solid ${grey[200]}`,
  },
  items: {
    display: "flex",
    flexDirection: "row",
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    flex: 1,
  },
  label: {
    fontSize: '16px',
    padding: [[0, 8]],
    whiteSpace: 'nowrap',
  },
});

const PrescriptionSummary = ({classes}) => {
  const LangFile = useLangFile();
  const {prescriptionJob} = usePrescriptionJob();
  const {fieldSize, totalPrescription, maxPrescription, unit} = prescriptionJob;

  let totalPrescriptionText;
  let maxPrescriptionText;
  switch (unit) {
    case PRESCRIPTION_UNIT.LITER:
      totalPrescriptionText = `${Number(totalPrescription).toFixed(2)} ${LangFile.PrescriptionSettings.unit.liters.short}`;
      maxPrescriptionText = `${Number(maxPrescription).toFixed(2)} ${LangFile.PrescriptionSettings.unit.liters.short}`;
      break;
    case PRESCRIPTION_UNIT.PIECES_M2:
      totalPrescriptionText = `${Number(totalPrescription / 1000).toFixed()} t.${LangFile.PrescriptionSettings.unit.pieces.short}`;
      maxPrescriptionText = `${Number(maxPrescription / 1000).toFixed()} t.${LangFile.PrescriptionSettings.unit.pieces.short}`;
      break;
    default:
      totalPrescriptionText = `${Number(totalPrescription).toFixed(2)} ${LangFile.PrescriptionSettings.unit.kilogram.short}`;
      maxPrescriptionText = `${Number(maxPrescription).toFixed(2)} ${LangFile.PrescriptionSettings.unit.kilogram.short}`;
      break;
  }

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.items}>
        <div className={classes.item}>
            <span className={classes.label}>
              {LangFile.PrescriptionSummary.arableLand}:
          </span>
          <span className={classes.label}>
              {Number(fieldSize.toFixed(1))} ha
          </span>
        </div>

        <div className={classes.item}>
            <span className={classes.label}>
              {LangFile.PrescriptionSummary.totalAmount}:
          </span>
          <span className={classes.label}>
              {totalPrescriptionText}
            </span>
        </div>

        <div className={classes.item}>
            <span className={classes.label}>
              {LangFile.PrescriptionSummary.allocationMax}:
          </span>
          <span className={classes.label}>
              {maxPrescriptionText}
            </span>
        </div>
      </Paper>
    </div>
  );
};

export default memo(withStyles(styles)(PrescriptionSummary));
