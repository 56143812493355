"use strict";

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => {
  return (
    <SvgIcon
      {...props}
      width="99.458"
      height="82.123"
      viewBox="0 0 99.458 82.123">

        <g transform="translate(-5026 -2803.237)">
          <g transform="translate(5026 2803.237)">
            <g transform="translate(0 0)">
              <path
                style={{fill: "#ffffff"}}
                d="M2.824,75.134l4.168,6.2,56.426.534V17.533l-5.771-4.168H47.708V8.77l-7.267-.534L33.815,2.678,25.266,9.3l-6.84-.534v5.664H6.991l-4.168,4.6Z"
                transform="translate(-0.96 -0.91)"/>
              <g transform="translate(0 0)">
                <path
                  className="b"
                  d="M103.346,10.76h-7.43V7.436c0-1.075-1.173-1.564-2.248-1.564H87.9A8.745,8.745,0,0,0,79.2.007a8.9,8.9,0,0,0-8.7,5.866h-5.67c-1.075,0-2.151.489-2.151,1.564V10.76h-7.43a8.31,8.31,0,0,0-8.212,7.918V74.694c0,4.3,3.91,7.43,8.212,7.43h48.1c4.3,0,8.212-3.128,8.212-7.43V18.678A8.31,8.31,0,0,0,103.346,10.76ZM66.589,9.782h5.377a2.151,2.151,0,0,0,1.857-1.76,5.67,5.67,0,0,1,5.377-4.4,5.572,5.572,0,0,1,5.279,4.4,2.151,2.151,0,0,0,1.955,1.76h5.572V17.6H66.589Zm41.059,64.912c0,2.151-2.151,3.519-4.3,3.519h-48.1c-2.151,0-4.3-1.369-4.3-3.519V18.678a4.4,4.4,0,0,1,4.3-4.008h7.43v4.986a2.053,2.053,0,0,0,2.151,1.858H93.668a2.15,2.15,0,0,0,2.248-1.858V14.67h7.43a4.4,4.4,0,0,1,4.3,4.008V74.694Z"
                  transform="translate(-47.037 0)"/>
                <path
                  className="b"
                  d="M114.319,230.921a1.955,1.955,0,0,0-2.737-.1l-6.257,5.963-2.639-2.737a1.955,1.955,0,0,0-2.737-.1,2.053,2.053,0,0,0,0,2.835l4.008,4.106a1.76,1.76,0,0,0,1.369.587,1.955,1.955,0,0,0,1.369-.587l7.625-7.234a1.857,1.857,0,0,0,.113-2.624Q114.379,230.975,114.319,230.921Z"
                  transform="translate(-89.586 -187.216)"/>
                <path
                  className="b"
                  d="M222.988,256.034H200.5a1.955,1.955,0,0,0,0,3.91h22.484a1.955,1.955,0,0,0,0-3.91Z"
                  transform="translate(-170.198 -208.126)"/>
                <path
                  className="b"
                  d="M114.319,147.329a1.955,1.955,0,0,0-2.737-.1l-6.257,5.963-2.639-2.737a1.955,1.955,0,0,0-2.737-.1,2.053,2.053,0,0,0,0,2.835l4.008,4.106a1.759,1.759,0,0,0,1.369.587,1.955,1.955,0,0,0,1.369-.587l7.625-7.234a1.857,1.857,0,0,0,.113-2.624Q114.379,147.383,114.319,147.329Z"
                  transform="translate(-89.586 -119.266)"/>
                <path
                  className="b"
                  d="M222.988,172.442H200.5a1.955,1.955,0,0,0,0,3.91h22.484a1.955,1.955,0,0,0,0-3.91Z"
                  transform="translate(-170.198 -140.175)"/>
                <path
                  className="b"
                  d="M114.319,314.512a1.955,1.955,0,0,0-2.737-.1l-6.257,5.963-2.639-2.737a1.955,1.955,0,0,0-2.737-.1,2.053,2.053,0,0,0,0,2.835l4.008,4.106a1.76,1.76,0,0,0,1.369.587,1.955,1.955,0,0,0,1.369-.587l7.625-7.234a1.857,1.857,0,0,0,.113-2.624Q114.379,314.566,114.319,314.512Z"
                  transform="translate(-89.586 -255.167)"/>
              </g>
            </g>
          </g>
          <g transform="translate(5047.996 2808.87)">
            <g transform="translate(2.034 2.022)">
              <path
                style={{fill: "#ffffff"}}
                d="M80.37,52.766l6.683,2.89-2.89,5.6L76.938,56.92Z"
                transform="translate(-32.148 -20.618)"/>
              <path
                style={{fill: "#ffffff"}}
                d="M95.332,16.923,83.232,40.4l5.057,1.806,11.739-23.659Z"
                transform="translate(-34.287 -8.434)"/>
              <path
                style={{fill: "#ffffff"}}
                d="M100.546,4.063,99.1,10.023l2.709,4.154H109.4l2.348-4.154Z"
                transform="translate(-39.682 -4.063)"/>
              <path
                style={{fill: "#ffffff"}}
                d="M59.154,67.318l6.5-13.184,19.505,9.572L78.117,77.432Z"
                transform="translate(-26.102 -21.083)"/>
              <path
                style={{fill: "#ffffff"}}
                d="M9.082,101.254s63.747.722,73.687.722c.722-3.07-1.445-23.117-22.214-17.88-4.154-6.321-20.95-17.338-35.4,2.709C8.721,86.986,9.082,101.254,9.082,101.254Z"
                transform="translate(-9.082 -28.289)"/>
            </g>
            <g transform="translate(0 0)">
              <path
                className="b"
                d="M76.883,68.5a18.283,18.283,0,0,0-19.63-13.573L61.52,46.2l.007,0a1.513,1.513,0,1,0,1.328-2.719l-5.7-2.783,1.632-3.338a1.512,1.512,0,0,0-.7-2.024l-.362-.177,10.413-21.3c.252,0,.506-.016.761-.041a7.047,7.047,0,0,0,5.615-3.893l.184-.376a1.484,1.484,0,0,0,.278.026A1.513,1.513,0,0,0,75.65,6.7L62.242.154a1.513,1.513,0,0,0-1.667,2.493l-.183.375a7.041,7.041,0,0,0,.376,6.821q.2.321.431.619l-10.414,21.3-.363-.178a1.514,1.514,0,0,0-2.024.695l-1.633,3.34-5.7-2.783a1.513,1.513,0,1,0-1.328,2.719l0,0-5.88,12.025A21.978,21.978,0,0,0,17.679,58.619c-.433-.035-.865-.052-1.292-.052A16.37,16.37,0,0,0,0,74.947,1.513,1.513,0,0,0,1.513,76.46H75.842a1.512,1.512,0,0,0,1.5-1.345,18.239,18.239,0,0,0-.463-6.617ZM71.8,8.6a4,4,0,0,1-3.188,2.21,5.6,5.6,0,0,1-5.292-2.584,4,4,0,0,1-.214-3.872l.16-.327,8.693,4.247Zm-8.271,3.983a8.608,8.608,0,0,0,1.51.739L55.015,33.832,53.5,33.094Zm-13.08,22.39,4.955,2.421-.969,1.98-4.955-2.421Zm8.349,9.9-5.084,10.4A22.072,22.072,0,0,0,37.33,47.389l5.135-10.5Zm15.63,28.564H3.11a13.2,13.2,0,0,1,.714-3.045H15.585a1.513,1.513,0,0,0,0-3.026H5.388a13.382,13.382,0,0,1,12.93-5.629,1.513,1.513,0,0,0,1.57-.822,18.958,18.958,0,0,1,32.319-2.668,1.513,1.513,0,0,0,1.745.531q.5-.184,1.012-.332L55,58.434a15.264,15.264,0,0,1,18.332,8.929H35.2a1.513,1.513,0,1,0,0,3.026h39a15.31,15.31,0,0,1,.236,2.685q0,.178,0,.36Zm0,0"
                transform="translate(0 0)"/>
              <path
                className="b"
                d="M246.867,361.672a1.51,1.51,0,0,1-1.226-.624,13.038,13.038,0,0,0-10.515-5.37,1.513,1.513,0,1,1,0-3.026,16.072,16.072,0,0,1,12.963,6.618,1.513,1.513,0,0,1-1.223,2.4Zm0,0"
                transform="translate(-202.23 -299.958)"/>
              <path
                className="b"
                d="M190.263,448.276a1.462,1.462,0,0,1-.295-.031,1.519,1.519,0,0,1-.283-.086,1.5,1.5,0,0,1-.262-.139,1.467,1.467,0,0,1-.23-.187,1.505,1.505,0,0,1-.413-1.366,1.576,1.576,0,0,1,.086-.283,1.484,1.484,0,0,1,.139-.26,1.439,1.439,0,0,1,.187-.23,1.585,1.585,0,0,1,.23-.189,1.5,1.5,0,0,1,.262-.139,1.613,1.613,0,0,1,.283-.086,1.53,1.53,0,0,1,1.365.414,1.439,1.439,0,0,1,.187.23,1.484,1.484,0,0,1,.139.26,1.58,1.58,0,0,1,.087.283,1.479,1.479,0,0,1,.029.3,1.513,1.513,0,0,1-1.513,1.513Zm0,0"
                transform="translate(-164.154 -378.547)"/>
            </g>
          </g>
        </g>

    </SvgIcon>
  );
};
