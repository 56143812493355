import React, {
  useCallback
} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonUncheckedOutlined from "@material-ui/icons/RadioButtonUncheckedOutlined";
import RadioButtonCheckedOutlined from "@material-ui/icons/RadioButtonCheckedOutlined";
import ManualFeatureConstants from "js/constants/ManualFeatureConstants";
import ListSubheader from "@material-ui/core/ListSubheader";
import moment from "moment-timezone";
import {
  useLangFile,
  useLanguage
} from "js/context/LanguageContext";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../hooks/useFirebaseAnalytics";
import NetworkSensor, {type NetworkSensorType} from "js/model/network/NetworkSensor";

const styles = (theme) => ({
  toggle: {
    height: 'auto',
  },
  tooltip: {
    fontSize: 14
  },
  iconRoot: {
    minWidth: 36,
  }
});

const WeatherNetworkMenu = ({classes, networkSensor, setNetworkSensor}) => {

  const analytics = useFirebaseAnalytics();
  const language = useLanguage();
  const LangFile = useLangFile();

  const handleSetSensor = (sensor: NetworkSensorType) => useCallback(() => {

    let firebaseSuffix = null;
    switch (sensor) {
      case "RAIN_24H":
        firebaseSuffix = "Rain";
        break;
      case "AIR_TEMP_24H":
        firebaseSuffix = "AirTemp";
        break;
      case "RAIN_ACC":
        firebaseSuffix = "AccRain";
        break;
      case "WIND_AVG":
        firebaseSuffix = "AvgWind";
        break;
      case "SOIL_TEMP_24H":
        firebaseSuffix = "SoilTemp";
        break;
      default: break;
    }

    if (firebaseSuffix) {
        analytics.logEvent(FIREBASE_EVENTS.WEATHER_NETWORK_LAYER + firebaseSuffix);
    }

    setNetworkSensor(sensor);
  }, [networkSensor]);

  let currentSensorsList = [NetworkSensor.RAIN_ACC, NetworkSensor.WIND_AVG];
  let latest24hSensorsList = [NetworkSensor.AIR_TEMP_24H, NetworkSensor.SOIL_TEMP_24H, NetworkSensor.RAIN_24H];

  return (
    <List dense disablePadding>
        <List dense disablePadding subheader={<ListSubheader>{LangFile.WeatherNetworkMenu.last24h}</ListSubheader>}>

          {latest24hSensorsList.map((sensor) => {
            if (sensor === NetworkSensor.AIR_TEMP_24H && !ManualFeatureConstants.FEATURE_NETWORK_AIR_TEMP_24H) {
              return null;
            }

            if (sensor === NetworkSensor.SOIL_TEMP_24H && !ManualFeatureConstants.FEATURE_NETWORK_SOIL_TEMP_24H) {
              return null;
            }

            let secondaryText = LangFile.WeatherNetworkMenu.menuTooltips[sensor];

            return (
              <ListItem dense button onClick={handleSetSensor(sensor)} key={sensor}>
                <ListItemIcon classes={{root: classes.iconRoot}}>
                  <Checkbox
                    style={{padding: 0}}
                    checked={networkSensor === sensor}
                    icon={<RadioButtonUncheckedOutlined fontSize="small"/>}
                    checkedIcon={<RadioButtonCheckedOutlined fontSize="small"/>}
                    color="primary"/>
                </ListItemIcon>
                <ListItemText primary={LangFile.WeatherNetworkMenu.networkSensor[sensor]} secondary={secondaryText}/>
              </ListItem>
            );
          })}
        </List>

        <List dense disablePadding subheader={<ListSubheader>{LangFile.WeatherNetworkMenu.current}</ListSubheader>}>
          {currentSensorsList.map((sensor) => {
            if (sensor === NetworkSensor.RAIN_ACC && !ManualFeatureConstants.FEATURE_NETWORK_ACC_RAIN) {
              return null;
            }
            if (sensor === NetworkSensor.WIND_AVG && !ManualFeatureConstants.FEATURE_NETWORK_WIND) {
              return null;
            }

            let secondaryText = LangFile.WeatherNetworkMenu.menuTooltips[sensor];

            if (sensor === NetworkSensor.RAIN_ACC) {
              let since = moment().locale(language).subtract(24, "h").calendar();
              since = since.charAt(0).toLowerCase() + since.substring(1);
              secondaryText += since;
            }

            return (
              <ListItem dense button onClick={handleSetSensor(sensor)} key={sensor}>
                <ListItemIcon classes={{root: classes.iconRoot}}>
                  <Checkbox
                    style={{padding: 0}}
                    checked={networkSensor === sensor}
                    icon={<RadioButtonUncheckedOutlined fontSize="small"/>}
                    checkedIcon={<RadioButtonCheckedOutlined fontSize="small"/>}
                    color="primary"/>
                </ListItemIcon>
                <ListItemText primary={LangFile.WeatherNetworkMenu.networkSensor[sensor]} secondary={secondaryText}/>
              </ListItem>
            );
          })}
        </List>
    </List>
  );
};

WeatherNetworkMenu.propTypes = {
  setNetworkSensor: PropTypes.func,
  networkSensor: PropTypes.string,
};

export default withStyles(styles)(WeatherNetworkMenu);