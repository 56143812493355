import {useFarm, useIsLoggedIn} from "js/context/AccountContext";
import React, {
  memo,
  useEffect,
  useRef
} from "react";
import {SeasonProvider} from "js/context/SeasonContext";
import {connect} from "react-redux";
import {
  fetchSeasons,
  SeasonState
} from "js/reducers/SeasonReducer";
import {fetchFields} from "js/reducers/FieldReducer";

const mapStoreToProps = (store) => ({
  seasons: store.season.seasons,
  selectedSeason: store.season.selectedSeason,
});

const SeasonContainer = ({dispatch, ...props}) => {
  const state: SeasonState = props;

  const farm = useFarm();
  const farmId = farm && farm.farmId;
  const loadingRef = useRef(false);
  const loggedIn = useIsLoggedIn();

  useEffect(() => {
    if (farmId && loggedIn) {
      dispatch(fetchSeasons(farmId));
    }
  }, [farmId, loggedIn]);

  useEffect(() => {
    if (loadingRef.current || !loggedIn) {
      return;
    }

    if (props.selectedSeason) {

      dispatch(fetchFields(props.selectedSeason.id, false))
        .then(() => {
          loadingRef.current = false;
        })
        .catch(() => {
          loadingRef.current = false;
        });
    }
  }, [props.selectedSeason, loggedIn]);

  return (
    <SeasonProvider value={state}>
      {props.children}
    </SeasonProvider>
  );
};

export default memo(connect(mapStoreToProps)(SeasonContainer));