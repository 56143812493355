import PropTypes from "prop-types";
import React, {memo, useCallback, useEffect, useState} from "react";
import {
  Box,
  Divider,
  ListItemSecondaryAction,
  ListSubheader,
  Paper,
  Switch
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import {CloudDownload, CloudUpload, DeleteForever} from "@material-ui/icons";
import UploadFileDialog from "../UploadFile/UploadFileDialog";
import {useFarm} from "../../context/AccountContext";
import {useHookRef} from "../../hooks/useHookRef";
import WebAPIUtils from "../../WebAPIUtils";
import {isSatelliteLayer, isSurveyLayer, PrescriptionJob} from "../Prescription/PrescriptionJob";
import {Action, useActionSnackbarContext} from "../ActionSnackbarHandler/ActionSnackbarHandler";
import Styles from "./PrescriptionDocumentation.module.less";
import {useLangFile} from "../../context/LanguageContext";
import {lowercaseFirstLetter} from "../../helpers/StringUtils";
import {voidFunc} from "../../constants/PropTypeUtils";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PrescriptionDocumentationConfirmDeleteDialog from "./PrescriptionDocumentationConfirmDeleteDialog";

const acceptFormats = [
  "application/zip",
  "application/x-zip-compressed",
  "image/*",
  ".pdf",
  ".xml",
  "application/xml",
  "text/xml"
].join(", ");

const PrescriptionDocumentation = (props: PrescriptionDocumentation.propTypes) => {
  const LangFile = useLangFile();
  const farm = useFarm();
  const farmId = useHookRef(farm.farmId);
  const {addAction} = useActionSnackbarContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(null);
  const [files, setFiles] = useState(null);
  const [fileProgress, setFileProgress] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [allowDelete, setAllowDelete] = useState(false);
  const [deleteFile, setDeleteFile] = useState(null);

  const [appliedDocumentation, setAppliedDocumentation] = useState(null);
  const hasDocumentation = appliedDocumentation != null && Array.isArray(appliedDocumentation) && appliedDocumentation.length > 0;

  useEffect(() => {
    if (props.prescriptionJob) {
      let jobId = props.prescriptionJob.jobId;

      if (props.documentation && props.documentation.applied) {
        let files = props.documentation.applied.filter((file) => {
          return file.surveyVrmId === jobId || file.ndviVrmId === jobId;
        });

        setAppliedDocumentation(files);
      }
    }
  }, [props.documentation, props.prescriptionJob]);

  const handleClickListItem = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onFileClicked = useCallback((item) => () => {
    if (allowDelete) {
      setDeleteFile(item);
    }
    else {
      setDeleteFile(null);

      WebAPIUtils.downloadAppliedDocumentation(item.id, item.fileName).catch((e) => {
        console.error(e);
      });
    }
  }, [allowDelete]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setLoading(false);
    setShowDialog(false);
    setAllowDelete(false);
    setDeleteFile(null);
  }, []);

  const onToggleAllowDelete = useCallback(() => {
    setAllowDelete((current) => !current);
  }, []);

  const onFileChanged = useCallback((files) => {
    setFiles(files);
  }, []);

  const onFileRejected = useCallback(() => {
    setFileError(LangFile.PrescriptionDocumentation.unsupportedFileFormat);
  }, []);

  const onFileAccepted = useCallback(() => {
    setFileError(null);
  }, []);

  const onShowUpload = useCallback(() => {
    setShowDialog(true);
    setAnchorEl(null);
  }, []);

  const onCloseUploadDialog = useCallback(() => {
    setShowDialog(false);
    setFiles(null);
  }, []);

  const onCancelDeleteFile = useCallback(() => {
    setDeleteFile(null);
  }, []);

  const onConfirmDeleteFile = useCallback(() => {
    props.onDeleteDocumentation(deleteFile)
      .then(() => {
        addAction(new Action("file-deleted-" + deleteFile.fileName, `"${deleteFile.fileName}" ${lowercaseFirstLetter(LangFile.PrescriptionDocumentation.deleted)}`, "success", "filled"));
        setDeleteFile(null);
      })
      .catch((e) => {
        addAction(new Action("file-delete-error-" + deleteFile.fileName, `${LangFile.PrescriptionDocumentation.failedToDeleteFile} "${deleteFile.fileName}"`, "error", "filled"));
      });
  }, [deleteFile]);

  const onImportFileSubmit = useCallback(() => {
    let job: PrescriptionJob = props.prescriptionJob;

    if (files && job) {
      let body = {};
      if (isSatelliteLayer(job.layer)) {
        body.ndviVrmId = job.jobId;
      }
      else if (isSurveyLayer(job.layer)) {
        body.surveyVrmId = job.jobId;
      }

      setFileProgress(files.map((f) => ({[f.preview]: 0})));
      setLoading(true);

      let promises = files.map((file) => {
        const progressCallback = (progress) => {
          setFileProgress((current) => ({...current, [file.preview]: progress}));
        };

        return WebAPIUtils.postPrescriptionDocumentation(file, farmId.current, body, progressCallback)
          .then(() => {
            addAction(new Action("file-uploaded-" + file.name, `"${file.name}" ${lowercaseFirstLetter(LangFile.PrescriptionDocumentation.uploaded)}`, "success", "filled"));
          })
          .catch((e) => {
            console.error(e);
          });
      });

      Promise.all(promises).then(() => {
        setLoading(false);
        handleClose();
        setFiles(null);
        props.onDocumentationUploaded();
      }).catch((e) => {
        setLoading(false);
      });
    }
  }, [props.prescriptionJob, files, LangFile, props.onDocumentationUploaded]);

  let fileCount = (appliedDocumentation && appliedDocumentation.length) || 0;

  return (
    <>
      <List component="div" dense={true} className={Styles.ButtonRoot}>
        <Paper variant={"outlined"}>
          <ListItem
            button={true}
            dense={true}
            aria-haspopup="true"
            aria-controls="documentation-menu"
            onClick={handleClickListItem}>
            <ListItemText primary={LangFile.PrescriptionDocumentation.documentation} primaryTypographyProps={{color: hasDocumentation ? "initial" : "secondary"}}/>
          </ListItem>
        </Paper>
      </List>

      <Menu
        id="documentation-menu"
        classes={{
          paper: Styles.Paper,
          list: Styles.ListRoot
        }}
        transitionDuration={0}
        anchorEl={anchorEl}
        anchorOrigin={{vertical: "top", horizontal: "right"}}
        transformOrigin={{vertical: "top", horizontal: "right"}}
        marginThreshold={48}
        open={Boolean(anchorEl)}
        onClose={handleClose}>

        <ListSubheader disableSticky className={Styles.Subheader}>
          {LangFile.PrescriptionDocumentation.upload}
        </ListSubheader>

        <Paper variant={"outlined"} className={Styles.UploadPaper}>
          <MenuItem button={true} dense={true} key={"upload"} onClick={onShowUpload}>
            <ListItemText primary={LangFile.PrescriptionDocumentation.uploadAsApplied}/>
            <ListItemSecondaryAction className={Styles.ListItemSecondary}>
              <CloudUpload color={"primary"}/>
            </ListItemSecondaryAction>
          </MenuItem>
        </Paper>

        <Divider component={"li"} light className={Styles.Divider}/>

        <ListSubheader disableSticky className={Styles.Subheader}>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <span>{LangFile.PrescriptionDocumentation.files} ({`${fileCount}`})</span>
            {fileCount === 0 && <i>{LangFile.PrescriptionDocumentation.noFiles}</i>}
          </Box>
        </ListSubheader>

        {appliedDocumentation && appliedDocumentation.map((item, idx) => (
          <MenuItem
            key={idx}
            classes={{root: Styles.ListItemRoot}}
            dense={true}
            button={true}
            onClick={onFileClicked(item)}>
            <ListItemText primary={item.fileName} secondary={"As-applied"} className={Styles.ListItemText}/>
            <ListItemSecondaryAction className={Styles.ListItemSecondary}>
              {allowDelete ? <DeleteForever color={"secondary"}/> : <CloudDownload fontSize={"small"}/>}
            </ListItemSecondaryAction>
          </MenuItem>
        ))}


        {appliedDocumentation && appliedDocumentation.length > 0 && [
            <Divider component={"li"} light className={Styles.Divider} key={"divider"}/>,
            <ListSubheader disableSticky className={Styles.Subheader} key={"subheader"}>
              <FormControlLabel
                labelPlacement={"start"}
                classes={{
                  root: Styles.LabelRoot
                }}
                control={
                  <Switch
                    size={"small"}
                    color={"secondary"}
                    onChange={onToggleAllowDelete}
                    checked={allowDelete}/>
                }
                label={<Box fontSize={"0.875rem"}>{LangFile.PrescriptionDocumentation.enableDelete}</Box>}/>
            </ListSubheader>
        ]}

      </Menu>

      <UploadFileDialog
        shown={showDialog}
        onCancel={onCloseUploadDialog}
        multiple={true}
        file={files}
        loading={loading}
        fileProgress={fileProgress}
        fileError={fileError}
        accept={acceptFormats}
        onFileChanged={onFileChanged}
        onFileRejected={onFileRejected}
        onFileAccepted={onFileAccepted}
        onImportFileSubmit={onImportFileSubmit}/>

      <PrescriptionDocumentationConfirmDeleteDialog
        file={deleteFile}
        onConfirm={onConfirmDeleteFile}
        onClose={onCancelDeleteFile}
        open={Boolean(deleteFile != null)}/>
    </>
  );
};

PrescriptionDocumentation.propTypes = {
  prescriptionJob: PropTypes.any,
  documentation: PropTypes.object,
  onDocumentationUploaded: PropTypes.func
};

PrescriptionDocumentation.defaultProps = {
  onFilesUploaded: voidFunc
};

export default memo(PrescriptionDocumentation);