import React, {
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import MapFloatPanel from "js/components/MapView/MapFloatPanel";
import {
  BorderClear,
  Close, History,
  SelectAll
} from "@material-ui/icons";
import {voidFunc} from "js/constants/PropTypeUtils";
import DataLayer from "js/components/DataLayer/DataLayer";
import FeaturePolygon from "js/components/DataLayer/FeaturePolygon";
import FieldTextCanvas from "js/components/ManageCrops/FieldTextCanvas";
import {getFieldName} from "js/helpers/StateInterpreters";
import {
  blue,
  green,
  grey, red,
  yellow
} from "@material-ui/core/colors";
import {
  useLangFileRef
} from "js/context/LanguageContext";
import {
  Paper,
  Button,
  IconButton,
  Toolbar,
  AppBar,
  Typography,
  Box,
  Tooltip,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const getFieldsArray = (fields) => Array.from(fields, ([key, value]) => value);

import Styles from "./ManageWeatherStationFieldConnections.module.less";
import GoogleMap from "js/components/MapObjects/GoogleMap/GoogleMap";
import ErrorSnackbar from "js/components/Toasts/ErrorSnackbar";

const getConnectedFieldIds = (fields, weatherStation) => {
  return fields
    .filter((f) => f.deviceId === weatherStation.id)
    .map((field) => field.fieldId);
};

const ManageWeatherStationFieldConnections = (props: ManageWeatherStationFieldConnections.propTypes) => {

  const LangFileRef = useLangFileRef();
  const LangFile = LangFileRef.current;

  const [fields, setFields] = useState(getFieldsArray(props.fields));
  const [connectedFieldIds, setConnectedFieldIds] = useState(getConnectedFieldIds(fields, props.weatherStation));
  const [dirtyFields, setDirtyFields] = useState([]);

  useEffect(() => {
    if (props.fields) {
      let newFields = getFieldsArray(props.fields);
      setFields(newFields);
      setConnectedFieldIds(getConnectedFieldIds(newFields, props.weatherStation));
    }
  }, [props.fields]);

  useEffect(() => {
    if (props.saved) {
      setDirtyFields([]);
    }
  }, [props.saved]);

  const onSelectAll = useCallback(() => {
    let oldConnected = getConnectedFieldIds(fields, props.weatherStation);
    let newConnected = fields.map((f) => f.fieldId);

    setConnectedFieldIds(newConnected);
    setDirtyFields(newConnected.filter((fieldId) => !oldConnected.includes(fieldId)));

  }, [fields, props.weatherStation]);

  const onReset = useCallback(() => {
    setConnectedFieldIds(getConnectedFieldIds(fields, props.weatherStation));
    setDirtyFields([]);
  }, [fields, props.weatherStation]);

  const onDeselectAll = useCallback(() => {
    // Get the originally connected field IDs and mark them dirty, as they will be disconnected
    setDirtyFields(getConnectedFieldIds(fields, props.weatherStation));

    // Remove all connections
    setConnectedFieldIds([]);
  }, [fields, connectedFieldIds, dirtyFields, props.weatherStatio]);

  const onSaveChanges = useCallback(() => {
    props.onConnectFields(connectedFieldIds);
  }, [connectedFieldIds]);

  const handleDismissSaved = useCallback(() => {
    props.setSaved(false);
  }, [props.setSaved]);

  const onFieldClicked = useCallback((event) => {
    let feature = event.feature;
    let fieldId = feature.getProperty("id");

    // Check if the field is already connected to the weather station
    if (connectedFieldIds.includes(fieldId)) {
      // If so, check if we have to revert a dirty state
      if (dirtyFields.includes(fieldId)) {
        // If so, remove the dirty state and the connected state
        setDirtyFields((c) => [...c].filter((id) => id !== fieldId));
        setConnectedFieldIds((c) => [...c].filter((id) => id !== fieldId));
      }
      else {
        // If not, set the dirty state and remove the connected state
        setDirtyFields((c) => [...c, fieldId]);
        setConnectedFieldIds((c) => [...c].filter((id) => id !== fieldId));
      }
    }
    else {
      // If not, set the dirty state and add the the connected state
      setDirtyFields((c) => [...c, fieldId]);
      setConnectedFieldIds((c) => [...c, fieldId]);
    }
  }, [connectedFieldIds, dirtyFields]);

  const renderFieldText = useCallback((field, zoom) => {
    let lines = [];
    let connected = connectedFieldIds.includes(field.fieldId);
    let connectedElsewhere = field.deviceId && field.deviceId !== props.weatherStation.id;

    // Name
    if (zoom >= 15) {
      lines.push({
        text: getFieldName(field, LangFileRef.current),
        fontWeight: "400",
        fontSize: 14,
        textAlign: "center",
        color: "white",
      });
    }

    // Connected
    if (zoom >= 14) {
      let text = connectedElsewhere ? LangFileRef.current.ManageWeatherStationFieldConnections.connectedElsewhere : LangFileRef.current.ManageWeatherStationFieldConnections.notConnected;

      if (connected) {
        text = LangFileRef.current.ManageWeatherStationFieldConnections.connected;
      }

      lines.push({
        text: text,
        fontWeight: "900",
        fontSize: 14,
        textAlign: "center",
        color: "white",
      });
    }

    return lines;
  }, [connectedFieldIds, props.weatherStation]);

  const onSetStyle = useCallback((feature) => {
    let fieldId = feature.getProperty("id");
    let field = props.fields.get(fieldId);

    if (!field) {
      return null;
    }

    let alreadyConnected = getConnectedFieldIds(getFieldsArray(props.fields), props.weatherStation).includes(fieldId);
    let selected = connectedFieldIds.includes(fieldId);

    if (alreadyConnected) {
      if (!selected) {
        return {
          fillColor: grey["500"],
          fillOpacity: 0.9,
          strokeColor: red["A700"],
          strokeWeight: 4
        };
      }
      else {
        return {
          fillColor: blue["A700"],
          fillOpacity: 0.9,
          strokeColor: yellow["A700"],
          strokeWeight: 2
        };
      }
    }
    else if (selected) {
      return {
        fillColor: green["A700"],
        fillOpacity: 0.9,
        strokeColor: green["A700"],
        strokeWeight: 4
      };
    }
    else {
      let connectedElsewhere = field.deviceId && field.deviceId !== props.weatherStation.id;

      if (connectedElsewhere) {
        return {
          fillColor: blue["200"],
          fillOpacity: 0.9,
          strokeColor: yellow["A700"],
          strokeWeight: 2
        };
      }
      else {
        return {
          fillColor: grey["500"],
          fillOpacity: 0.9,
          strokeColor: yellow["A700"],
          strokeWeight: 2
        };
      }
    }
  }, [connectedFieldIds, props.fields, props.weatherStation]);

  return (
    <Box
      position={"relative"}
      width={"100%"}
      height={"100%"}>
      <GoogleMap center={props.previousViewport.center} zoom={props.previousViewport.zoom}>
        <MapFloatPanel
          zIndex={2}
          top={0}
          left={0}
          right={0}>
          <AppBar
            color={"primary"}
            position='static'>
            <Toolbar variant='dense'>
              <Box
                display={"flex"}
                width={"100%"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}>
                <Box display={"flex"} alignItems={"center"}>
                  <IconButton
                    edge='start'
                    color='inherit'
                    onClick={props.onClose}>
                    <Close/>
                  </IconButton>

                  <Typography variant='h6'>
                    {LangFile.ManageWeatherStationFieldConnections.finish}
                  </Typography>
                </Box>

                <div>
                  <Tooltip title={LangFile.ManageWeatherStationFieldConnections.reset}>
                    <IconButton
                      edge='start'
                      color='inherit'
                      onClick={onReset}>
                      <History/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={LangFile.ManageWeatherStationFieldConnections.selectAll}>
                    <IconButton
                      edge='start'
                      color='inherit'
                      onClick={onSelectAll}>
                      <SelectAll/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={LangFile.ManageWeatherStationFieldConnections.deselectAll}>
                    <IconButton
                      edge='start'
                      color='inherit'
                      onClick={onDeselectAll}>
                      <BorderClear/>
                    </IconButton>
                  </Tooltip>
                </div>
              </Box>
            </Toolbar>
          </AppBar>
        </MapFloatPanel>

        <MapFloatPanel
          zIndex={10}
          top={74}
          left={24}>
          <Paper
            elevation={2}
            className={Styles.Paper}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              p={2}>
              <Box
                fontWeight={"bold"}
                textAlign={"center"}
                pb={1}>
                {LangFile.ManageWeatherStationFieldConnections.title}
              </Box>
              <Box
                mb={2}
                textAlign={"center"}>
                {LangFile.ManageWeatherStationFieldConnections.description}
              </Box>

              {props.saving && (
                <Box
                  align={"center"}
                  fontWeight={"bold"}>{LangFile.ManageWeatherStationFieldConnections.saving}</Box>
              )}

              {props.saved && (
                <Box
                  align={"center"}
                  fontWeight={"bold"}>{LangFile.ManageWeatherStationFieldConnections.saved}</Box>
              )}

              {props.saving && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"100%"}
                  py={4}>
                  <CircularProgress
                    variant={"indeterminate"}
                    size={30}
                    color={"primary"}/>
                </Box>
              )}

              {!props.saved && !props.saving && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  fontWeight={"bold"}
                  pb={1}>
                  {`${LangFile.ManageWeatherStationFieldConnections.connectedFields}: ${connectedFieldIds.length}`}
                </Box>
              )}

              {!props.saved && !props.saving && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  pt={1}>
                  <Button
                    disabled={dirtyFields.length === 0}
                    variant={"contained"}
                    color={"primary"}
                    onClick={onSaveChanges}>{LangFile.ManageWeatherStationFieldConnections.save}</Button>
                </Box>
              )}

              {props.saved && !props.saving && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  pt={1}>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleDismissSaved}>{LangFile.ManageWeatherStationFieldConnections.ok}</Button>
                </Box>
              )}

            </Box>
          </Paper>
        </MapFloatPanel>

        <DataLayer
          onClick={onFieldClicked}
          setStyle={onSetStyle}>
          {fields.map((field) => {
            return (
              <FeaturePolygon
                id={field.fieldId}
                key={field.fieldId}
                coords={field.polygon.coordinates}/>
            );
          })}
        </DataLayer>

        <FieldTextCanvas
          fields={fields}
          renderField={renderFieldText}/>

      </GoogleMap>

      <ErrorSnackbar
        open={props.connectError !== null}
        title={LangFile.ManageWeatherStationFieldConnections.errorTitle}
        subtitle={LangFile.ManageWeatherStationFieldConnections.errorSubtitle}
        errorMessage={props.connectError ? props.connectError.message : undefined}
        onDismiss={props.onDismissError}/>

    </Box>
  );
};

ManageWeatherStationFieldConnections.propTypes = {
  fields: PropTypes.object,
  saving: PropTypes.bool,
  saved: PropTypes.bool,
  setSaved: PropTypes.func,
  weatherStation: PropTypes.object,
  connectError: PropTypes.any,
  onDismissError: PropTypes.func,
  onClose: PropTypes.func,
  onConnectFields: PropTypes.func,
  previousViewport: PropTypes.shape({
    center: PropTypes.object,
    zoom: PropTypes.number,
  })
};

ManageWeatherStationFieldConnections.defaultProps = {
  onClose: voidFunc,
  setSaved: voidFunc,
  onConnectFields: voidFunc,
  onDismissError: voidFunc,
  connectError: null,
  previousViewport: null,
};

export default memo(
  ManageWeatherStationFieldConnections
);
