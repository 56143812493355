"use strict";

import React, {
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';

import PropTypes from "prop-types";

import NumberUtils from "js/helpers/NumberUtils";
import useEvent from "js/hooks/useEvent";
import Box from "@material-ui/core/Box";

import style from './PrescriptionSplitView.module.less';
import useWindowDimensions from "js/hooks/useWindowDimensions";
import DragHandle from "js/components/Prescription/DragHandle/DragHandle";
import GoogleMap from "js/components/MapObjects/GoogleMap/GoogleMap";

const PrescriptionSplitView = ({overrideMode, children, bounds}) => {

  const [splitPos, setSplitPos] = useState(0.5);

  const [leftMap, setLeftMap] = useState(null);
  const [rightMap, setRightMap] = useState(null);
  const [dragging, setDragging] = useState(false);
  const forceRedraw = useState({});

  const dimensions = useWindowDimensions();

  const resizeHandler = useCallback((timeout = 0) => {
    setTimeout(() => {
      forceRedraw[1]({});
    }, timeout);
  }, [forceRedraw]);

  const onStartDrag = useCallback(() => {
    setDragging(true);
  }, []);

  const onEndDrag = useCallback(() => {
    setDragging(false);
  }, []);

  const onDrag = useCallback((event) => {
    if (dragging && dimensions) {
      const padding = 12;

      let {width} = dimensions;

      let left = padding;
      let right = width - 2 * padding;

      let x = event.clientX;
      x = NumberUtils.limit(x, left, right);
      x = Math.abs(NumberUtils.map(x, left, right, 0, 1));

      x = NumberUtils.limit(x, 0.15, 0.85);

      setSplitPos(x);
    }
  }, [dragging, dimensions]);

  useEvent("resize", resizeHandler);
  useEvent("mousemove", onDrag);
  useEvent("mouseup", onEndDrag);

  useEffect(() => {
    resizeHandler(500);
  }, [overrideMode]);

  // Calculate Dimensions and Positions
  let leftWidth = splitPos * 100 + "%";
  let rightWidth = 100 - splitPos * 100 + "%";

  return (
    <Box display={"flex"} flexDirection={"row"} flexWrap={"nowrap"} className={style.Root}>

      <DragHandle
        movementDirection={"horizontal"}
        movementPercent={leftWidth}
        onStartDrag={onStartDrag}/>

      <Box width={leftWidth} className={style.Map} borderRight={"2px solid white"}>
        <GoogleMap
          zoom={18}
          optimized={false}
          onMapLoaded={setLeftMap}
          fitToBounds={bounds}>

          {leftMap && children[0](leftMap)}
        </GoogleMap>
      </Box>
      <Box width={rightWidth} className={style.Map} borderLeft={"2px solid white"}>
        <GoogleMap
          zoom={18}
          optimized={false}
          onMapLoaded={setRightMap}
          fitToBounds={bounds}>

          {rightMap && children[1](rightMap)}

        </GoogleMap>
      </Box>

    </Box>
  );
};

export default memo(PrescriptionSplitView);

PrescriptionSplitView.propTypes = {
  leftSideChildren: PropTypes.object,
  rightSideChildren: PropTypes.object,
  bounds: PropTypes.object,

  /** Two children can be provided; index 0 = Left Map, index 1 = Right Map **/
  children: PropTypes.arrayOf(PropTypes.func),
};

PrescriptionSplitView.defaultProps = {
  children: [(leftMap) => {}, (rightMap) => {}]
};