const NetworkSensor = {
  RAIN_ACC: "rainAcc", // dummy, will actually request RAIN_24H
  RAIN_24H: "RAIN_24H",
  WIND_AVG: "WIND_AVG",
  AIR_TEMP_24H: "AIR_TEMP_24H",
  SOIL_TEMP_24H: "SOIL_TEMP_24H",
};

export type NetworkSensorType = $Keys<typeof NetworkSensor>;

export default NetworkSensor;