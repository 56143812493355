import type {LAYER_CONFIG} from "./SurveyLayerConfig";
import NumberUtils from "js/helpers/NumberUtils";
import type {LanguageFile} from "js/helpers/LanguageUtils";

import {
  SURVEY_LAYER_CATEGORIES,
  SURVEY_LAYER_DATA_RANGES,
  SURVEY_LAYERS,
  type SurveyLayer,
} from "js/constants/SurveyLayers";

import {getSourceCategory} from "../../helpers/SurveyUtils";
import {getImageUrl} from "./SurveyImageMapping";

export const getSurveyConfig = (layer: SurveyLayer): LAYER_CONFIG => {
  if (!SURVEY_LAYERS[layer]) {
    return null;
  }

  // VALUE MAPPING
  let interval = SURVEY_LAYER_DATA_RANGES[layer];
  let minValue = interval ? interval[0] : 0.0;
  let maxValue = interval ? interval[1] : 1.0;

  let mapValue = (val, outMin, outMax) => {
    return NumberUtils.limitMap(val, minValue, maxValue, outMin, outMax);
  };

  let reverseMapValue = (val, inMin, inMax) => {
    return NumberUtils.limitMap(val, inMin, inMax, minValue, maxValue);
  };

  // THE REST
  let category = getSourceCategory(layer);

  let unit = category === SURVEY_LAYER_CATEGORIES.TEXTURE ? "%" : null;
  if (layer === SURVEY_LAYERS.JB || layer === SURVEY_LAYERS.FI_SOIL_CLASS) {
    unit = null;
  }
  if (layer === SURVEY_LAYERS.ALTITUDE) {
    unit = "m";
  }

  return {
    minValue: minValue,
    maxValue: maxValue,
    category: category,
    unit: unit,
    getName: (LangFile: LanguageFile) => LangFile.SurveyLayers[layer].name,
    getShorthand: (LangFile: LanguageFile) => LangFile.SurveyLayers[layer].shorthand,
    mapValue: mapValue,
    reverseMapValue: reverseMapValue,
    getImageUrl: (images, classificationsEnabled: boolean, variationsEnabled: boolean, viewMode: String): string => {
      return getImageUrl(images, layer, classificationsEnabled, variationsEnabled, viewMode);
    },
  };
};