"use strict";

export default class WeatherRainRegion {
  /**
   * @param {String} sensor, any value of {@link NetworkSensor}
     @param {String} windowStart, DateTime as an ISO string that marks the start of data window.
     @param {String} windowEnd, DateTime as an ISO string that marks the end of data window.
     @param {Number} windowSizeMinutes, defines the time between {@param windowStart} and {@param windowEnd}.
     @param {Number} windowStepMinutes, defines the time between each value item in {@param clusters}.
   * @param {Array<ClusterValues>} clusters, the computed values for each cluster.
   */
  constructor(sensor, windowStart, windowEnd, windowSizeMinutes, windowStepMinutes, clusters) {
    this.sensor = sensor;
    this.windowStart = windowStart;
    this.windowEnd = windowEnd;
    this.windowSizeMinutes = windowSizeMinutes;
    this.windowStepMinutes = windowStepMinutes;
    this.clusters = clusters;
  }
}