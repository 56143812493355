import Season from "js/model/Season";
import moment from "moment-timezone";

export const formatSeason = (season: Season, LangFile) => {
  if (!season || !LangFile) {
    return "";
  }

  if (season.name) {
    return season.name;
  }

  return `${LangFile.SeasonUtils.season} ${moment(season.startDate, 'YYYY-MM-DD').year()}/${moment(season.endDate, 'YYYY-MM-DD').year()}`;
};

export const formatFieldSeason = (field, selectedSeason, LangFile) => {
  if (!field || !selectedSeason || !LangFile) {
    return null;
  }

  let text = `${LangFile.SeasonUtils.harvestYear} ${selectedSeason.year}`;

  if (field.crop) {
    let cropText = LangFile.CropType[field.crop];
    text += `, ${cropText}`;
  }

  return text;
};

export const getNewestSeason = (seasons) => {
  let enabledSeasons = seasons.filter((s: Season) => s.enabled);

  return enabledSeasons
    .reduce((prev, curr) => {
      if (curr.year === prev.year) {
        if (curr.id > prev.id) {
          return curr;
        }
        else {
          return prev;
        }
      }
      else if (curr.year > prev.year) {
        return curr;
      }
      else {
        return prev;
      }
    }, enabledSeasons[0]);
};

export const SELECTED_SEASON_LOCAL_STORAGE_KEY = "com/ceptu/fieldsense/web/season/id";

export const saveSelectedSeason = (season) => {
  localStorage.setItem(SELECTED_SEASON_LOCAL_STORAGE_KEY, season.id);
};

export const getSelectedSeason = (seasons) => {
  const selectedSeasonId = localStorage.getItem(SELECTED_SEASON_LOCAL_STORAGE_KEY);
  if (selectedSeasonId) {
    const selectedSeason = seasons.find((s: Season) => s.id === Number(selectedSeasonId));
    if (selectedSeason) {
      return selectedSeason;
    }
  }
  return getNewestSeason(seasons);
};
