import {useLangFile} from "../../../context/LanguageContext";
import {FIELD_STATISTICS_CLAAS_COLUMNS, FIELD_STATISTICS_COLUMNS} from "../FieldStatisticsColumns";
import {Box, Typography} from "@material-ui/core";
import Styles from "../FieldStatisticsContainer.module.less";
import moment from "moment/moment";
import {SURVEY_LAYERS} from "../../../constants/SurveyLayers";
import React, {memo} from "react";
import ClaasColumnCell from './ClaasColumnCell';
import PropTypes from 'prop-types';
import {voidFunc} from '../../../constants/PropTypeUtils';
import EmptyColumnCell from './EmptyColumnCell';
import StandardColumnCell from './StandardColumnCell';
import SurveyColumnCell from './SurveyColumnCell';

const ColumnCell = ({stats, column, onShortcutClicked}) => {
  const LangFile = useLangFile();

  if (column === FIELD_STATISTICS_COLUMNS.STATUS) {
    return (
      <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'} width={'100%'}>
      </Box>
    );
  }
  else if (column === FIELD_STATISTICS_COLUMNS.FIELD) {
    const fieldName = stats.fieldName && stats.fieldName.length > 0 ? stats.fieldName : LangFile.StateInterpreters.unnamedField;
    return (
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} width={'100%'}>
        <Typography className={Styles.title}>{fieldName}</Typography>
        {stats.fieldMef && (
          <Typography className={Styles.subtitle}>{stats.fieldMef}</Typography>
        )}
      </Box>
    );
  }
  else if (column === FIELD_STATISTICS_COLUMNS.AREA && stats.size) {
    return <StandardColumnCell primary={`${Number(stats.size.toFixed(1))} ha`}/>;
  }
  else if (column === FIELD_STATISTICS_COLUMNS.CROP && stats.crop) {
    return <StandardColumnCell primary={LangFile.CropType[stats.crop]}/>;
  }
  else if (column === FIELD_STATISTICS_COLUMNS.CROP_VARIETY && stats.cropVariation) {
    return <StandardColumnCell primary={stats.cropVariation}/>;
  }
  else if (column === FIELD_STATISTICS_COLUMNS.SOWING_DATE && stats.sowing) {
    return <StandardColumnCell
      primary={moment(stats.sowing).format("MMMM DD")}
      secondary={moment(stats.sowing).format("YYYY")}/>;
  }
  else if (Object.keys(FIELD_STATISTICS_CLAAS_COLUMNS).includes(column)) {
    return <ClaasColumnCell column={column} stats={stats.claas}/>;
  }
  else if (Object.keys(SURVEY_LAYERS).includes(column)) {
    return <SurveyColumnCell stats={stats} column={column} onShortcutSoilOptix={onShortcutClicked}/>;
  }
  else {
    return <EmptyColumnCell/>;
  }
};

ColumnCell.propTypes = {
  column: PropTypes.string,
  stats: PropTypes.object,
  onShortcutClicked: PropTypes.func
};

ColumnCell.defaultProps = {
  onShortcutClicked: voidFunc
};

export default memo(
  ColumnCell
);
