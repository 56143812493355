import React, {memo} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import {
  blueGrey,
  grey
} from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";

const styles = (theme) => ({
  root: {
    color: blueGrey["800"],
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: 6,
    overflow: "visible",
    position: "relative",
    border: `${grey[200]} 3px solid`,
    boxSizing: 'border-box',
    '&:hover:not(.selected)': {
      background: grey["200"],
      borderColor: grey["400"],
    },
    '& *': {
      userSelect: 'none',
    },
  },

  icon: {
    '& svg': {
      fontSize: 48,
    },
  },

  settings: {
    position: "absolute",
    top: 0,
    left: "50%",
    transform: 'translate(-50%, -50%)',
  },

  settingsButton: {
    background: 'white',
    '&:hover': {
      backgroundColor: grey["200"]
    }
  },

  value: {
    fontSize: 28,
    minHeight: 28,
    padding: [[theme.spacing(1), 0]],
  },

  label: {
    fontSize: 14,
  },

  '@media (max-width: 1600px), (max-height: 800px)': {
    icon: {
      '& svg': {
        fontSize: 28,
      },
    },

    value: {
      fontSize: 13,
      minHeight: 18,
      padding: [[theme.spacing(1) / 2, 0]],
    },

    label: {
      fontSize: 11,
    },
  },
});

const WeatherInfoBox = (props) => {

  let {classes, selected, color, icon, label, value, decimals, onClick, settings} = props;

  let valueString = '-';
  let labelString = "";
  let valueProvided = value != null;

  if (valueProvided) {
    if (typeof value === "number") {
      valueString = Number.isNaN(value) ? '-' : Number(value).toFixed(decimals);
    }
    else {
      valueString = value;
    }
  }

  if (typeof label === "number") {
    labelString = Number.isNaN(label) ? '-' : Number(label).toFixed(decimals);
  }
  else {
    labelString = label;
  }

  return (
    <Box
      width={1}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      className={`${classes.root} ${selected ? 'selected' : ''}`}
      onClick={onClick}
      style={{borderColor: selected ? color : ''}}>
      <div className={classes.icon}>
        {icon}
      </div>

      {selected && (
        settings
      )}

      <div>
        {<div className={classes.value}>
          {valueString}
        </div>}
        {<div className={classes.label}>
          {labelString}
        </div>}
      </div>
    </Box>
  );
};

WeatherInfoBox.propTypes = {
  icon: PropTypes.node,
  tooltip: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  value: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  decimals: PropTypes.number,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  color: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  settings: PropTypes.node,
};

export default memo(
  withStyles(styles)(WeatherInfoBox)
);