"use strict";

import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import style from 'js/components/Prescription/DraggableIntervals/DraggableIntervals.module.less';

import HorizontalHandle
  from 'js/components/Prescription/DraggableIntervals/HorizontalHandle/HorizontalHandle';
import VerticalHandle
  from 'js/components/Prescription/DraggableIntervals/VerticalHandle/VerticalHandle';
import CenterLine from 'js/components/Prescription/DraggableIntervals/CenterLine/CenterLine';
import IntervalVisualizer from 'js/components/Prescription/IntervalVisualizer/IntervalVisualizer';
import Paper from '@material-ui/core/Paper';
import PrescriptionScaleContainer from "js/components/Prescription/PrescriptionScale/PrescriptionScaleContainer";
import PrescriptionSummary from "js/components/Prescription/PrescriptionSummary";
import ValueDisplayBoxes
  from "js/components/Prescription/DraggableIntervals/ValueDisplayBoxes/ValueDisplayBoxes";
import {usePrescriptionJob} from "js/components/Prescription/PrescriptionJobContext";
import useEvent from "js/hooks/useEvent";
import useLowScreen from "js/hooks/useLowScreen";
import Box from "@material-ui/core/Box";
import {calculateValueStepSize} from "../PrescriptionUtils";

const DraggableIntervals = ({
                              mergingIntervals,
                              splittingIntervals,
                              mergePrimaryIndex,
                              mergeSecondaryIndex,
                              splittingIndex,
                              classificationsEnabled,
                              variationsEnabled,
                              onBarClicked,
                              onDragValue,
                              onDragInterval,
                              isCustomClassification,
                            }) => {

  const wrapperRef = useRef(); // we only reference the Ref inside useEffect, not in Render.
  const [wrapperRect, setWrapperRect] = useState(null);
  const [stepSize, setStepSize] = useState(0);

  const {prescriptionJob} = usePrescriptionJob();
  const {intervals, maxPrescription, fieldSize} = prescriptionJob;

  useEffect(() => {
    setStepSize(calculateValueStepSize(0.05, prescriptionJob));
  }, [prescriptionJob.maxPrescription, prescriptionJob.fieldSize]);

  const onResize = useCallback(() => {
    let wrapper: HTMLElement = wrapperRef.current;
    if (!wrapper) {
      return;
    }

    setWrapperRect(wrapper.getBoundingClientRect());
  }, []);

  const onIncrementInterval = useCallback((interval, idx) => {
    let newValue = interval.prescription + stepSize;
    onDragValue(idx, newValue);
  }, [stepSize, onDragValue]);

  const onDecrementInterval = useCallback((interval, idx) => {
    let newValue = Math.max(interval.prescription - stepSize, 0);
    onDragValue(idx, newValue);
  }, [stepSize, onDragValue]);

  useEffect(onResize, []);
  useEvent("resize", onResize, window);

  const low = useLowScreen();
  const intervalsHeight = low ? 140 : 200;

  return (
    <div className={style.Root}>
      <PrescriptionSummary/>

      <PrescriptionScaleContainer
        classificationsEnabled={classificationsEnabled}
        variationsEnabled={variationsEnabled}
        isCustomClassification={isCustomClassification}/>

      <Paper elevation={0} className={style.Editor}>
        <Box height={intervalsHeight} className={style.CanvasHolder} ref={wrapperRef}>
          {wrapperRect && (
            <Fragment>
              <IntervalVisualizer
                wrapperRect={wrapperRect}
                intervals={intervals}
                maxPrescription={maxPrescription / fieldSize}
                onClick={onBarClicked}
                stepSize={stepSize}
                onIncrementInterval={onIncrementInterval}
                onDecrementInterval={onDecrementInterval}
                mergePrimaryIndex={mergePrimaryIndex}
                mergeSecondaryIndex={mergeSecondaryIndex}
                splittingIndex={splittingIndex}
                mergingIntervals={mergingIntervals}
                splittingIntervals={splittingIntervals}/>

              {!(mergingIntervals || splittingIntervals) && (
                <Fragment>
                  <CenterLine/>

                  {intervals.map((interval, idx) => { // VALUE SLIDERS
                    return (
                      <HorizontalHandle
                        key={`h-handle-${interval.key}`}
                        wrapperRect={wrapperRect}
                        index={idx}
                        interval={interval}
                        onDragValue={onDragValue}/>
                    );
                  })}

                  {!(classificationsEnabled && isCustomClassification) && intervals.length > 1 && intervals
                    .filter((entry, idx) => idx !== intervals.length - 1) // INTERVAL SLIDERS
                    .map((interval, idx) => {
                      return (
                        <VerticalHandle
                          key={`v-handle-${interval.key}`}
                          wrapperRect={wrapperRect}
                          index={idx}
                          interval={interval}
                          onDragInterval={onDragInterval}/>
                      );
                    })
                  }
                </Fragment>
              )}

              <ValueDisplayBoxes onDragValue={onDragValue}/>

            </Fragment>
          )}
        </Box>

        <Box height={"20px"} /* Spacer for ValueDisplayBoxes to avoid overflow and scroll */ />

      </Paper>
    </div>
  );
};

export default memo(DraggableIntervals);

DraggableIntervals.propTypes = {
  mergingIntervals: PropTypes.bool,
  splittingIntervals: PropTypes.bool,
  mergePrimaryIndex: PropTypes.number,
  mergeSecondaryIndex: PropTypes.number,
  splittingIndex: PropTypes.number,
  onBarClicked: PropTypes.func,
  onDragValue: PropTypes.func,
  onDragInterval: PropTypes.func,
  isCustomClassification: PropTypes.bool
};
