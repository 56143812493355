import React, {
  memo,
  useCallback,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import {CloudDownload} from "@material-ui/icons";
import debounce from 'lodash.debounce';

import style from "./PrescriptionDownloadTile.module.less";
import {useLangFile} from "js/context/LanguageContext";

const PrescriptionDownloadTile = ({format, imageSource, onDownload}) => {

  const LangFile = useLangFile();

  const [hovered, setHovered] = useState(false);

  const onHoveredChanged = (value) => useCallback(() => {
    setHovered(value);
  }, []);

  const downloadHandler = useCallback(debounce(() => {
    onDownload(format);
  }, 200), [format, onDownload]);

  return (
    <div className={style.Tile} onClick={downloadHandler}>
      <Paper
        onMouseEnter={onHoveredChanged(true)}
        onMouseLeave={onHoveredChanged(false)}
        onClick={downloadHandler}
        className={style.Paper}
        elevation={hovered ? 4 : 2}
        square={true}>

        <img
          alt={""}
          src={imageSource}
          className={style.TileImage}/>

        <GridListTileBar
          className={style.TileBar}
          title={LangFile.PrescriptionDownloadTile.download}
          onClick={downloadHandler}
          actionIcon={<CloudDownload className={style.Icon}/>}/>
      </Paper>
    </div>
  );
};

PrescriptionDownloadTile.propTypes = {
  format: PropTypes.string,
  imageSource: PropTypes.any,
  onDownload: PropTypes.func,
};

export default memo(PrescriptionDownloadTile);
