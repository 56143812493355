"use strict";

import React, {memo} from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from "prop-types";

const SurplusSmall = (props: SurplusSmall.propTypes) => {
  return (
    <SvgIcon
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12">

      <g transform="translate(-0.074 -996)">
        <g transform="translate(0.074 996)">
          <g fill="#fff" stroke={props.htmlColor} strokeWidth="1">
            <rect width="12" height="12" stroke="none"/>
            <rect x="0.5" y="0.5" width="11" height="11" fill="none"/>
          </g>
          <path d="M771.192,386.129h12V374.208Z" transform="translate(-771.192 -374.208)" fill={props.htmlColor}/>
        </g>
      </g>

    </SvgIcon>
  );
};

SurplusSmall.propTypes = {
  htmlColor: PropTypes.string,
};

SurplusSmall.defaultProps = {
  htmlColor: "#91DB8E",
};

export default memo(
  SurplusSmall
);