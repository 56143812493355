import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Box, Checkbox, Typography} from '@material-ui/core';
import Styles from './FieldOverviewListSection.module.less';
import {voidFunc} from '../../constants/PropTypeUtils';

const FieldOverviewListSection = ({ title, checked, onCheckboxClick }) => {
  return (
    <Box px={1} bgcolor={'white'} display={'flex'} alignItems={'center'}>
      <Checkbox
        className={Styles.checkbox}
        checked={Boolean(checked)}
        color={'primary'}
        onClick={(e) => {
          e.stopPropagation();
          onCheckboxClick(!checked);
        }}
        disableRipple/>
      <Box mx={0.5}>
        <Typography className={Styles.text}>{title}</Typography>
      </Box>
    </Box>
  );
};

FieldOverviewListSection.prototype = {
  title: PropTypes.string,
  checked: PropTypes.bool,
  onCheckboxClick: PropTypes.func
};

FieldOverviewListSection.defaultProps = {
  checked: false,
  onCheckboxClick: voidFunc
};

export default memo(FieldOverviewListSection);
