// @flow

import DemandHigh from "../components/Icons/DemandHigh";
import React from "react";
import DemandMedium from "../components/Icons/DemandMedium";
import DemandSmall from "../components/Icons/DemandSmall";
import DemandOk from "../components/Icons/DemandOk";
import SurplusSmall from "../components/Icons/SurplusSmall";
import SurplusLarge from "../components/Icons/SurplusLarge";
import SurplusSuspicious from "../components/Icons/SurplusSuspicious";
import {
  blue,
  blueGrey,
  brown,
  deepOrange,
  green,
  grey,
  lime,
  orange,
  purple,
  red,
  yellow,
} from "@material-ui/core/colors";

/**
 * Definition of all available Survey Layers.
 * @type {{MANGANESE: string, SODIUM: string, IRON: string, FI_SOIL_CLASS: string, SULFUR: string, HUMUS: string, PHOSPHORUS: string, RT: string, SAND: string, BORON: string, COPPER: string, ALTITUDE: string, ZINC: string, MAGNESIUM: string, CALCIUM: string, JB: string, SILT: string, POTASSIUM: string, FINE_SAND: string, CLAY: string, COARSE_SAND: string}}
 */
export const SURVEY_LAYERS = Object.freeze({
  RT: "RT",
  PH: "PH",
  PHOSPHORUS: "PHOSPHORUS",
  POTASSIUM: "POTASSIUM",
  MAGNESIUM: "MAGNESIUM",
  MANGANESE: "MANGANESE",
  COPPER: "COPPER",
  BORON: "BORON",
  ZINC: "ZINC",
  JB: "JB",
  FI_SOIL_CLASS: "FI_SOIL_CLASS",
  CLAY: "CLAY",
  FINE_SAND: "FINE_SAND",
  COARSE_SAND: "COARSE_SAND",
  SAND: "SAND",
  SILT: "SILT",
  SODIUM: "SODIUM",
  IRON: "IRON",
  HUMUS: "HUMUS",
  ALTITUDE: "ALTITUDE",
  SULFUR: "SULFUR",
  CALCIUM: "CALCIUM",
  MOLYBDENUM: "MOLYBDENUM",
});

export type SurveyLayer = $Keys<typeof SURVEY_LAYERS>;

export const SURVEY_LAYER_CATEGORIES = Object.freeze({
  NUTRIENT: "NUTRIENT",
  TEXTURE: "TEXTURE",
  TOPOGRAPHY: "TOPOGRAPHY",
});
export type SurveyLayerCategory = $Keys<typeof SURVEY_LAYER_CATEGORIES>;

export const SURVEY_LAYER_CLASSIFICATION_KEYS = Object.freeze({
  DEMAND_LARGE: "DEMAND_LARGE",
  DEMAND_MEDIUM: "DEMAND_MEDIUM",
  DEMAND_SMALL: "DEMAND_SMALL",
  DEMAND_OK: "DEMAND_OK",
  SURPLUS_SMALL: "SURPLUS_SMALL",
  SURPLUS_LARGE: "SURPLUS_LARGE",
  SURPLUS_SUSPICIOUS: "SURPLUS_SUSPICIOUS",
});

export const SURVEY_LAYER_CLASSIFICATION_NO_RANGE_KEYS = Object.freeze({
  DEMAND: "DEMAND",
  DEMAND_OK: "DEMAND_OK",
  SURPLUS: "SURPLUS",
});

export const SURVEY_LAYER_COLORS = Object.freeze({
  RT: red["A700"],
  PH: red["A700"],
  PHOSPHORUS: blue["A700"],
  POTASSIUM: green["A700"],
  MAGNESIUM: purple["A700"],
  COPPER: orange["A700"],
  BORON: yellow["A700"],
  ZINC: blueGrey["900"],
  CLAY: brown["900"],
  IRON: "#773C00",
  FINE_SAND: lime["900"],
  COARSE_SAND: lime["900"],
  SAND: lime["900"],
  SODIUM: "#FFE700",
  MANGANESE: "#0097FF",
  SILT: deepOrange["A700"],
  HUMUS: grey["900"],
  SULFUR: "#A8FF00",
  CALCIUM: "#FBBC04",
  MOLYBDENUM: "#FF0082",
});

export const SURVEY_CLAY_COLOR_INTERVALS = {
  "#ece7d7": [0.0, 5.0],
  "#d8cfb0": [5.0, 10.0],
  "#c3b88a": [10.0, 15.0],
  "#aea265": [15.0, 25.0],
  "#988c40": [25.0, 45.0],
  "#827717": [45.0, Number.MAX_VALUE]
};

export const SURVEY_HUMUS_COLOR_INTERVALS = {
  "#ffffff": [0.0, 1.5],
  "#d7d9db": [1.5, 2.5],
  "#b0b5b7": [2.5, 4.0],
  "#8b9295": [4.0, 6.0],
  "#677074": [6.0, 10.0],
  "#465055": [10.0, 30.0],
  "#263238": [30.0, Number.MAX_VALUE]
};

export const SURVEY_LAYER_CLASSIFICATIONS = Object.freeze({
  [SURVEY_LAYERS.RT]: {
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_LARGE]: [0.0, 4.5],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_MEDIUM]: [4.5, 5.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_SMALL]: [5.0, 6.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK]: [6.0, 6.5],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SMALL]: [6.5, 7.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_LARGE]: [7.0, 8.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SUSPICIOUS]: [8.0, Number.MAX_VALUE],
  },
  [SURVEY_LAYERS.PHOSPHORUS]: {
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_LARGE]: [0.0, 1.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_MEDIUM]: [1.0, 1.5],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_SMALL]: [1.5, 2.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK]: [2.0, 4.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SMALL]: [4.0, 5.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_LARGE]: [5.0, 6.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SUSPICIOUS]: [6.0, Number.MAX_VALUE],
  },
  [SURVEY_LAYERS.POTASSIUM]: {
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_LARGE]: [0.0, 4.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_MEDIUM]: [4.0, 5.5],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_SMALL]: [5.5, 7.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK]: [7.0, 10.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SMALL]: [10.0, 12.5],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_LARGE]: [12.5, 15.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SUSPICIOUS]: [15.0, Number.MAX_VALUE],
  },
  [SURVEY_LAYERS.MAGNESIUM]: {
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_LARGE]: [0.0, 2.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_MEDIUM]: [2.0, 3.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_SMALL]: [3.0, 4.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK]: [4.0, 6.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SMALL]: [6.0, 8.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_LARGE]: [8.0, 10.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SUSPICIOUS]: [10.0, Number.MAX_VALUE],
  },
  [SURVEY_LAYERS.COPPER]: {
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_LARGE]: [0.0, 1.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_MEDIUM]: [1.0, 1.5],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_SMALL]: [1.5, 2.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK]: [2.0, 3.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SMALL]: [3.0, 4.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_LARGE]: [4.0, 5.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SUSPICIOUS]: [5.0, Number.MAX_VALUE],
  },
  [SURVEY_LAYERS.BORON]: {
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_LARGE]: [0.0, 1.5],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_MEDIUM]: [1.5, 2.5],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_SMALL]: [2.5, 3.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK]: [3.0, 5.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SMALL]: [5.0, 6.5],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_LARGE]: [6.5, 8.0],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SUSPICIOUS]: [8.0, Number.MAX_VALUE],
  },
  [SURVEY_LAYERS.ZINC]: {
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_LARGE]: [0.0, 1.0],
    // [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_MEDIUM]: [],
    // [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_SMALL]: [],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK]: [1.0, 3.0],
    // [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SMALL]: [],
    // [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_LARGE]: [],
    [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SUSPICIOUS]: [3.0, Number.MAX_VALUE],
  },
});

export const SURVEY_LAYER_CLASSIFICATION_ICONS = Object.freeze({
  [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_LARGE]: <DemandHigh/>,
  [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_MEDIUM]: <DemandMedium/>,
  [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_SMALL]: <DemandSmall/>,
  [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK]: <DemandOk/>,
  [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SMALL]: <SurplusSmall/>,
  [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_LARGE]: <SurplusLarge/>,
  [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SUSPICIOUS]: <SurplusSuspicious/>,
});

export const SURVEY_LAYER_CLASSIFICATION_NO_RANGE_ICONS = Object.freeze({
  [SURVEY_LAYER_CLASSIFICATION_NO_RANGE_KEYS.DEMAND]: <DemandHigh/>,
  [SURVEY_LAYER_CLASSIFICATION_NO_RANGE_KEYS.DEMAND_OK]: <DemandOk/>,
  [SURVEY_LAYER_CLASSIFICATION_NO_RANGE_KEYS.SURPLUS]: <SurplusSuspicious/>,
});

export const SURVEY_LAYER_CLASSIFICATION_COLORS = Object.freeze({
  [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_LARGE]: "#FF0000",
  [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_MEDIUM]: "#F85F5F",
  [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_SMALL]: "#F3A5A5",
  [SURVEY_LAYER_CLASSIFICATION_KEYS.DEMAND_OK]: "#FFFFFF",
  [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SMALL]: "#BDDBC4",
  [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_LARGE]: "#8DC99B",
  [SURVEY_LAYER_CLASSIFICATION_KEYS.SURPLUS_SUSPICIOUS]: "#4EB265",
});

export const SURVEY_LAYER_SOIL_TYPE_COLORS = Object.freeze({
  1: "#FFFDE7",
  2: "#FFF59D",
  3: "#FFEE58",
  4: "#FBC02D",
  5: "#D7CCC8",
  6: "#BCAAA4",
  7: "#A1887F",
  8: "#795548",
  9: "#633C35",
  10: "#BF360C",
  11: "#000000"
});

/**
 * All SURVEY_LAYERS defined as a NUTRIENT layer.
 * @type {(string)[]}
 */
export const SURVEY_NUTRIENT_LAYERS = Object.freeze([
  SURVEY_LAYERS.PHOSPHORUS,
  SURVEY_LAYERS.MAGNESIUM,
  SURVEY_LAYERS.COPPER,
  SURVEY_LAYERS.POTASSIUM,
  SURVEY_LAYERS.RT,
  SURVEY_LAYERS.PH,
  SURVEY_LAYERS.BORON,
  SURVEY_LAYERS.ZINC,
  SURVEY_LAYERS.CALCIUM,
  SURVEY_LAYERS.MOLYBDENUM,
  SURVEY_LAYERS.SULFUR,
  SURVEY_LAYERS.MANGANESE,
  SURVEY_LAYERS.SODIUM,
  SURVEY_LAYERS.IRON,
]);

/**
 * All SURVEY_LAYERS defined as a TEXTURE layer.
 * @type {(string)[]}
 */
export const SURVEY_TEXTURE_LAYERS = Object.freeze([
  SURVEY_LAYERS.CLAY,
  SURVEY_LAYERS.FINE_SAND,
  SURVEY_LAYERS.COARSE_SAND,
  SURVEY_LAYERS.SAND,
  SURVEY_LAYERS.HUMUS,
  SURVEY_LAYERS.SILT,
  SURVEY_LAYERS.JB,
  SURVEY_LAYERS.FI_SOIL_CLASS,
]);

/**
 * All SURVEY_LAYERS defined as a TOPOGRAPHY layer.
 * @type {(string)[]}
 */
export const SURVEY_TOPOGRAPHY_LAYERS = Object.freeze([
  SURVEY_LAYERS.ALTITUDE,
]);

// LEGACY
export const SURVEY_LAYER_DATA_RANGES = {
  [SURVEY_LAYERS.RT]: [0, 8],
  [SURVEY_LAYERS.PH]: [0, 8],
  [SURVEY_LAYERS.PHOSPHORUS]: [0, 8],
  [SURVEY_LAYERS.POTASSIUM]: [0, 15],
  [SURVEY_LAYERS.MAGNESIUM]: [0, 10],
  [SURVEY_LAYERS.COPPER]: [0, 5],
  [SURVEY_LAYERS.BORON]: [0, 8],
  [SURVEY_LAYERS.ZINC]: [0, 3],
  [SURVEY_LAYERS.JB]: [1, 12],
  [SURVEY_LAYERS.FI_SOIL_CLASS]: [0, 6],
  [SURVEY_LAYERS.HUMUS]: [0, 30],
  [SURVEY_LAYERS.CLAY]: [0, 45],
  [SURVEY_LAYERS.SAND]: [0, 100],
  [SURVEY_LAYERS.COARSE_SAND]: [0, 100],
  [SURVEY_LAYERS.FINE_SAND]: [0, 100],
  [SURVEY_LAYERS.SILT]: [0, 100],
};
