import React, {
  memo
} from 'react';

import PropTypes from 'prop-types';
import Box from "@material-ui/core/Box";

import style from "./DragHandle.module.less";
import ButtonBase from "@material-ui/core/ButtonBase";
import clsx from "clsx";

const DragHandle = ({movementDirection, movementPercent, axisPositionPercent, onStartDrag, width}) => {
  if (movementDirection === "vertical") {
    return (
      <Box left={axisPositionPercent} top={movementPercent} width={width || 40} height={12} className={clsx(style.Root, style.Vertical)}>
        <div className={style.RelativeWrapper}>
          <ButtonBase focusRipple={true} onMouseDown={onStartDrag} onTouchStart={onStartDrag}/>
        </div>
      </Box>
    );
  }
  else {
    return (
      <Box left={movementPercent} top={axisPositionPercent} width={12} height={40} className={clsx(style.Root, style.Horizontal)}>
        <div className={style.RelativeWrapper}>
          <ButtonBase focusRipple={true} onMouseDown={onStartDrag} onTouchStart={onStartDrag}/>
        </div>
      </Box>
    );
  }
};

DragHandle.propTypes = {
  /** Indicates the movement direction of the handle.
   * The handle is perpendicular to the movement. **/
  movementDirection: PropTypes.oneOf(["vertical", "horizontal"]),
  /** Sets the movement position in percentage (string e.g. "50%") along the movement direction
   * relative to the first relatively-positioned parent HTML element. **/
  movementPercent: PropTypes.string,
  /** Callback fired when the handle is pressed. **/
  onStartDrag: PropTypes.func,
  /** Sets the position of the handle's axis. Typically, this is 50% between to points. **/
  axisPositionPercent: PropTypes.string
};

DragHandle.defaultProps = {
  axisPositionPercent: "50%",
};

export default memo(DragHandle);