// @flow

import React, {
  memo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import {
  Box, Button,
  Dialog,
  DialogContent,
} from '@material-ui/core';

import style from "./PrescriptionLayerDialog.module.less";
import {useLangFile} from "js/context/LanguageContext";
import {
  SURVEY_LAYERS,
  SURVEY_NUTRIENT_LAYERS,
  SURVEY_TEXTURE_LAYERS, SURVEY_TOPOGRAPHY_LAYERS
} from "../../../../constants/SurveyLayers";
import {
  type SourceCategory,
  SOURCE_CATEGORY,
  isSatelliteLayer
} from "../../PrescriptionJob";
import List from "@material-ui/core/List";
import SurveyLayerListItem from "../../../Surveys/SurveyLayerListItem";
import useFirebaseAnalytics, {FIREBASE_EVENTS} from "../../../../hooks/useFirebaseAnalytics";
import {canLayerBeEnabled, useSurveyContext} from "../../../../context/SurveyContext";
import {SATELLITE_LAYERS} from '../../../../constants/SatelliteLayers';

const getOptions = (category: SourceCategory) => {
  switch (category) {
    case SOURCE_CATEGORY.VITALITY: {
      return [
        SATELLITE_LAYERS.VITALITY_NDVI
      ];
    }
    case SOURCE_CATEGORY.NUTRIENT: {
      return SURVEY_NUTRIENT_LAYERS;
    }
    case SOURCE_CATEGORY.TEXTURE: {
      return SURVEY_TEXTURE_LAYERS.filter((item) => item !== SURVEY_LAYERS.JB && item !== SURVEY_LAYERS.FI_SOIL_CLASS);
    }
    case SOURCE_CATEGORY.TOPOGRAPHY: {
      return SURVEY_TOPOGRAPHY_LAYERS;
    }
    default:
      return [];
  }
};

const PrescriptionLayerDialog = (props: PrescriptionLayerDialog.propTypes) => {

  const analytics = useFirebaseAnalytics();
  const LangFile = useLangFile();

  const {surveyViewCapabilities, viewMode, selectedSurvey} = useSurveyContext();

  const handleOnSelect = useCallback((layer) => {
    props.onSelectLayer(layer);
    analytics.logEvent(FIREBASE_EVENTS.VRM_NEW_LAYER_SELECTED, {layer: layer});
  }, [props.onSelectLayer]);

  const handleOnBack = useCallback(() => {
    props.onBack();
    analytics.logEvent(FIREBASE_EVENTS.VRM_NEW_LAYER_BACK);
  }, [props.onBack]);

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth={"sm"}>
      <DialogContent
        className={style.Content}
        dividers>
        <Box
          py={2}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}>
          <Box
            fontWeight={"bold"}
            fontSize={"1.5rem"}>
            {LangFile.PrescriptionLayerDialog.title}
          </Box>

          <Box width={"100%"} py={2}>
            <List
              dense
              disablePadding>
              {getOptions(props.selectedCategory).map((layer, idx) => {
                  let enabled = isSatelliteLayer(layer) || canLayerBeEnabled(layer, selectedSurvey, viewMode, surveyViewCapabilities);

                  return enabled && (
                    <span key={idx}>
                      <SurveyLayerListItem
                        elevated={true}
                        layer={layer}
                        disableCheckbox={true}
                        onClick={handleOnSelect}/>
                    </span>
                );
              })}
            </List>
          </Box>


          <Button onClick={handleOnBack}>
            {LangFile.PrescriptionLayerDialog.back}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

PrescriptionLayerDialog.propTypes = {
  onBack: PropTypes.func,
  onSelectLayer: PropTypes.func,
  open: PropTypes.bool,
  selectedCategory: PropTypes.string,
};

export default memo(PrescriptionLayerDialog);
