import React, {Fragment, memo, useCallback, useRef, useState} from 'react';
import {useReactToPrint} from "react-to-print";
import PropTypes from 'prop-types';
import {Dialog, DialogActions, Box, Button} from "@material-ui/core";
import Styles from './SurveyReport.module.less';
import ReportToPrint from "./ReportToPrint";
import {useLangFile} from "js/context/LanguageContext";
import {getFieldName} from "../../helpers/StateInterpreters";
import {getLayerConfig} from "../Prescription/PrescriptionUtils";
import moment from "moment-timezone";

const SurveyReport = (props: SurveyReport.propTypes) => {
  const LangFile = useLangFile();
  const componentHook = useRef();
  const [toggleMap, setToggleMap] = useState(true);
  const printDate = moment();

  const layer = props.showSatelliteImagery ? props.imageType : props.selectedLayer;
  const imageDate = props.showSatelliteImagery ? `, ${moment(props.imageDate).format("MMMM D, YYYY")}` : "";
  const layerName = getLayerConfig(layer).getName(LangFile) + imageDate;
  const documentTitle = `${LangFile.SurveyReport.fieldReport}, ${getFieldName(props.selectedField, LangFile)}, ${layerName}`;

  const handlePrint = useReactToPrint({
    content: () => componentHook.current,
    documentTitle: documentTitle
  });

  const handleToggleMap = useCallback(() => {
    setToggleMap((current) => !current);
  }, []);

  return (
    <Fragment>
      <Dialog onClose={props.onClose} open={props.open} maxWidth={"md"} fullWidth={true}>
        <ReportToPrint
          width={"100%"}
          toggleMap={toggleMap}
          selectedField={props.selectedField}
          imageURL={props.imageURL}
          imageDate={props.imageDate}
          viewMode={props.viewMode}
          showSatelliteImagery={props.showSatelliteImagery}
          showSoilSurveys={props.showSoilSurveys}
          selectedLayer={props.selectedLayer}
          imageType={props.imageType}
          variationsEnabled={props.variationsEnabled}
          selectedFieldHasImage={props.selectedFieldHasImage}
          selectedFieldHasSurvey={props.selectedFieldHasSurvey}
          classificationsEnabled={props.classificationsEnabled}
          values={props.values}
          printDate={printDate}
          ref={componentHook}
          classificationValues={props.classificationValues}/>
        <DialogActions>
          <Box p={2} width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
              <Button variant="outlined" className={Styles.closeButton} onClick={props.onClose}>
                {LangFile.AddFields.close}
              </Button>
              <Box p={1} />
              <Button variant="outlined" onClick={handleToggleMap}>
                {toggleMap ? LangFile.SurveyReport.hideMap : LangFile.SurveyReport.showMap}
              </Button>
            </Box>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
             <Button variant="contained" color="primary" onClick={handlePrint}>
               {LangFile.SurveyReport.print}
             </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

SurveyReport.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    selectedField: PropTypes.object,
    imageURL: PropTypes.string,
    imageDate: PropTypes.string,
    viewMode: PropTypes.string,
    showSatelliteImagery: PropTypes.bool,
    showSoilSurveys: PropTypes.bool,
    imageType: PropTypes.string,
    selectedLayer: PropTypes.string,
    variationsEnabled: PropTypes.bool,
    selectedFieldHasImage: PropTypes.bool,
    selectedFieldHasSurvey: PropTypes.bool,
    classificationsEnabled: PropTypes.bool,
    values: PropTypes.array,
    classificationValues: PropTypes.object
};

export default memo(
    SurveyReport
);
