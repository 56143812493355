import React, {memo} from 'react';
import {connect} from 'react-redux';

import SoilSampleVisualizer from "js/components/SoilSamples/SoilSampleVisualizer";
import {useGoogleMap} from "../../context/GoogleMapContext";

const mapStoreToProps = (store) => ({
  shownSampleIds: store.soilSample.shownSampleIds,
  samples: store.soilSample.samples,
  features: store.soilSample.features,
  showSoilSamples: store.soilSample.showSoilSamples,
  selectedProperty: store.soilSample.selectedProperty,
});

const SoilSampleVisualizerContainer = (props) => {

  const googleMap = useGoogleMap();

  if (!props.showSoilSamples) {
    return null;
  }

  return (
    <SoilSampleVisualizer
      map={googleMap}
      samples={props.samples}
      selectedProperty={props.selectedProperty}
      features={props.features}
      shownSampleIds={props.shownSampleIds}/>
  );

};
export default memo(connect(mapStoreToProps)(SoilSampleVisualizerContainer));