import React, {
  memo
} from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import {
  DialogActions,
  DialogContent
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useLangFile} from "js/context/LanguageContext";

const RestorePasswordErrorDialog = ({open, onClose}) => {

  const LangFile = useLangFile();

  return (
    <Dialog
      title={LangFile.RestorePasswordErrorDialog.title}
      onClose={onClose}
      open={open}>
      <DialogContent>
        <h4>{LangFile.RestorePasswordErrorDialog.description}</h4>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{LangFile.RestorePasswordErrorDialog.retry}</Button>
      </DialogActions>
    </Dialog>
  );
};

RestorePasswordErrorDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default memo(RestorePasswordErrorDialog);